import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createSubscriber } from "../../actions/application/create-subscriber/actions";
import {
  appTopicFullListClearAction,
  getApplicationTopicFullList,
} from "../../actions/application/get-topics-full/actions";
import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalAddApplicationSubscriberPage1 } from "./ModalAddApplicationSubscriberPage1";
import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalAddApplicationSubscriber = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const [currentPage, setCurrentPage] = useState(0);

  const changeCommentRequired = false; // adding topic subscriber does not require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);

  const [config, setConfig] = useState({
    app: {
      appClientId: undefined,
    },
    bridge: true,
    brokerType: {
      brokerType: undefined,
      brokerTypeId: undefined,
      lruDescription: "",
    },
    changeComment: "",
    localGroupData: "",
    topic: {
      appClientId: undefined,
      topicId: undefined,
      topicLocalGroupBridge: "",
      topicName: "",
      topicStatus: "",
      ...editModal.topic,
    },
  });

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };
  // initialize the 2nd app topic list at max 500 entries
  useEffect(() => {
    const myConfig = { ...editModal.topicFilter, limit: 500, page: 0 };
    dispatch(getApplicationTopicFullList(myConfig));
  }, []);

  const handleSubmit = () => {
    dispatch(
      createSubscriber(
        {
          ...config,
          appClientId: config.app.appClientId,
        },
        () => {
          if (editModal.onConfirm) {
            editModal.onConfirm();
          }
        }
      )
    );
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkValidity = () => {
    const hasBrokerType = config.brokerType && config.brokerType.brokerTypeId;
    const hasAppClientId = config.app && config.app.appClientId;

    const isFormValid = hasBrokerType && hasAppClientId;

    handleSetValidPage(0, !!isFormValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkValidity();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config]);

  const modalTheme = {
    modal: {
      width: "650px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase
        title="Subscribe application to topic"
        handleCloseButton={() => {
          dispatch(appTopicFullListClearAction());
        }}
      >
        {currentPage === 0 && (
          <ModalAddApplicationSubscriberPage1
            topic={editModal.topic}
            topicClientList={editModal.topicClientList}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              setConfig(updatedConfig);
            }}
          />
        )}

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are subscribing to this topic.`}
            commentRequired={changeCommentRequired}
          />
        )}

        <SSpacer height="50px" />

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage !== 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 0}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage < 1 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Next
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                className="ta-modal-save-button"
                onClick={handleSubmit}
                disabled={!validPage[Number(currentPage)]}
              >
                Save
              </ButtonPrimary>
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
