import {
  IGetTopicDetailsActionType,
  TGetTopicDetails,
} from "../../actions/topic/get-details/types";

interface ITopicDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicDetailsState = {
  content: {},
  error: false,
  loading: true,
};

export const topicDetails = (
  state: ITopicDetailsState = initialState,
  action: IGetTopicDetailsActionType
): ITopicDetailsState => {
  switch (action.type) {
    case TGetTopicDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetTopicDetails.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetTopicDetails.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
