/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum MQTTClientType {
  REQUEST = "GET_MQTT_CLIENT_REQUEST",
  SUCCESS = "GET_MQTT_CLIENT_SUCCESS",
  ERROR = "GET_MQTT_CLIENT_ERROR",
  CLEAR = "GET_MQTT_CLIENT_CLEAR",
}

export interface IMQTTClientRequestAction extends IReduxBaseAction<MQTTClientType> {
  type: MQTTClientType.REQUEST;
}

export interface IMQTTClientClearAction extends IReduxBaseAction<MQTTClientType> {
  type: MQTTClientType.CLEAR;
}

export interface IMQTTClientSuccessAction extends IReduxBaseAction<MQTTClientType> {
  payload: any;
  type: MQTTClientType.SUCCESS;
}

export interface IMQTTClientErrorAction extends IReduxBaseAction<MQTTClientType> {
  payload: string;
  type: MQTTClientType.ERROR;
}

export type TMQTTClientActions =
  | IMQTTClientRequestAction
  | IMQTTClientSuccessAction
  | IMQTTClientErrorAction
  | IMQTTClientClearAction;
