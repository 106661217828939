import { Dispatch } from "redux";

import { IGetApplicationListData } from "../../../models/IAppListingTableConfig";
import { ApplicationService } from "../../../services/ApplicationService";

import {
  IGetApplicationDetailsActionType,
  IGetApplicationDetailsErrorAction,
  IGetApplicationDetailsRequestAction,
  IGetApplicationDetailsSuccessAction,
  TGetApplicationDetails,
} from "./types";

export const getApplicationDetailsRequestAction = (): IGetApplicationDetailsRequestAction => ({
  type: TGetApplicationDetails.REQUEST,
});
export const getApplicationDetailsSuccessAction = (
  data: any
): IGetApplicationDetailsSuccessAction => ({
  payload: data,
  type: TGetApplicationDetails.SUCCESS,
});
export const getApplicationDetailsErrorAction = (
  error: any
): IGetApplicationDetailsErrorAction => ({
  payload: error,
  type: TGetApplicationDetails.ERROR,
});

// GET APPLICATION
export const getApplicationDetails = (appId: any) => (
  dispatch: Dispatch<IGetApplicationDetailsActionType>
) => {
  dispatch(getApplicationDetailsRequestAction());
  ApplicationService.getApplicationDetails(appId).then(
    (data: { data: any }) => {
      if (
        data.data.data.content &&
        data.data.data.content.appOwners &&
        data.data.data.content.appOwners.length > 0
      ) {
        data.data.data.content.appOwners.sort((a: any, b: any) =>
          a.fullName.localeCompare(b.fullName)
        );
      }
      dispatch(getApplicationDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(getApplicationDetailsErrorAction(error))
  );
};
