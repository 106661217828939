/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTopicListData } from "../../../models/ITopicConfig";

export enum AppTopicListType {
  CLEAR = "APP_TOPIC_LIST_CLEAR",
  REQUEST = "APP_TOPIC_LIST_REQUEST",
  SUCCESS = "APP_TOPIC_LIST_SUCCESS",
  ERROR = "APP_TOPIC_LIST_ERROR",
}

export interface IAppTopicClearAction extends IReduxBaseAction<AppTopicListType> {
  type: AppTopicListType.CLEAR;
}

export interface IAppTopicRequestAction extends IReduxBaseAction<AppTopicListType> {
  type: AppTopicListType.REQUEST;
}

export interface IAppTopicSuccessAction extends IReduxBaseAction<AppTopicListType> {
  payload: IGetTopicListData;
  type: AppTopicListType.SUCCESS;
}

export interface IAppTopicErrorAction extends IReduxBaseAction<AppTopicListType> {
  payload: string;
  type: AppTopicListType.ERROR;
}

export type TAppTopicListActions =
  | IAppTopicRequestAction
  | IAppTopicSuccessAction
  | IAppTopicErrorAction
  | IAppTopicClearAction;
