import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CurrentUserTypes {
  SUCCESS = "CURRENT_USER_SUCCESS",
  ERROR = "CURRENT_USER_ERROR",
}

export interface IGetLoggedInUserSuccessAction extends IReduxBaseAction<CurrentUserTypes> {
  payload: any;
  type: CurrentUserTypes.SUCCESS;
}

export interface IGetLoggedInUserErrorAction extends IReduxBaseAction<CurrentUserTypes> {
  payload: any;
  type: CurrentUserTypes.ERROR;
}

export type TGetLoggedInUserType = IGetLoggedInUserSuccessAction | IGetLoggedInUserErrorAction;
