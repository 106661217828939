import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import { getApiUrl, getIdToken } from "./AxiosInstance";

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: await getApiUrl(),
    prepareHeaders: async (headers, { getState }) => {
      const idToken = await getIdToken();
      if (idToken) {
        headers.set("Authorization", `Bearer ${idToken}`);
        headers.set("Content-Type", "application/json");
      }
    },
  });

  return rawBaseQuery(args, api, extraOptions);
};

export default baseQuery;
