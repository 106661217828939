import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditTopicTypes {
  ERROR = "EDIT_TOPIC_ERROR",
  REQUEST = "EDIT_TOPIC_REQUEST",
  SUCCESS = "EDIT_TOPIC_SUCCESS",
}

export interface IEditTopicRequestAction extends IReduxBaseAction<EditTopicTypes> {
  payload: any;
  type: EditTopicTypes.REQUEST;
}

export interface IEditTopicSuccessAction extends IReduxBaseAction<EditTopicTypes> {
  payload: any;
  type: EditTopicTypes.SUCCESS;
}

export interface IEditTopicErrorAction extends IReduxBaseAction<EditTopicTypes> {
  payload: any;
  type: EditTopicTypes.ERROR;
}

export type TSetEditTopicActions =
  | IEditTopicErrorAction
  | IEditTopicRequestAction
  | IEditTopicSuccessAction;
