// General
var SIZE_FACTOR = 4;
export var getSize = function getSize(size) {
  return (size * SIZE_FACTOR).toString() + "px";
};
export var defaultBodyFontSize = "16px";
export var borderRadius = Object.freeze({
  none: "0px",
  xsmall: "2px",
  small: "4px",
  medium: "8px"
});
export var borderShadow = function borderShadow(size) {
  return "-webkit-box-shadow: 0px " + size + "px 8px 0px rgba(0,0,0,0.12);" + "-moz-box-shadow: 0px " + size + "px 8px 0px rgba(0,0,0,0.12);" + "box-shadow: 0px " + size + "px 8px 0px rgba(0,0,0,0.12);";
}; // Components

export var topBarHeight = 60; // in px

export var cloudMenuWidth = 64; // in px

export var navigationMenuWidth = 265; // in px

export var textareaMaxLength = 500;
export var textareaRows = 5;