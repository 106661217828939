import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum AddTopicPermissionAppTypes {
  ERROR = "ADD_TOPIC_PERMISSION_APP_ERROR",
  REQUEST = "ADD_TOPIC_PERMISSION_APP_REQUEST",
  SUCCESS = "ADD_TOPIC_PERMISSION_APP_SUCCESS",
}

export interface IAddTopicPermissionAppRequestAction
  extends IReduxBaseAction<AddTopicPermissionAppTypes> {
  payload: any;
  type: AddTopicPermissionAppTypes.REQUEST;
}

export interface IAddTopicPermissionAppSuccessAction
  extends IReduxBaseAction<AddTopicPermissionAppTypes> {
  payload: any;
  type: AddTopicPermissionAppTypes.SUCCESS;
}

export interface IAddTopicPermissionAppErrorAction
  extends IReduxBaseAction<AddTopicPermissionAppTypes> {
  payload: any;
  type: AddTopicPermissionAppTypes.ERROR;
}

export type TSetAddTopicPermissionAppActions =
  | IAddTopicPermissionAppErrorAction
  | IAddTopicPermissionAppRequestAction
  | IAddTopicPermissionAppSuccessAction;
