/* istanbul ignore file */
import { IGetAircraftGroupListData } from "../../../models/IAircraftGroup";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AircraftGroupSelectListType {
  RESET = "GET_AIRCRAFT_GROUP_SELECT_LIST_RESET",
  REQUEST = "GET_AIRCRAFT_GROUP_SELECT_LIST_REQUEST",
  SUCCESS = "GET_AIRCRAFT_GROUP_SELECT_LIST_SUCCESS",
  ERROR = "GET_AIRCRAFT_GROUP_SELECT_LIST_ERROR",
}

export interface IAircraftGroupSelectListResetAction
  extends IReduxBaseAction<AircraftGroupSelectListType> {
  type: AircraftGroupSelectListType.RESET;
}

export interface IAircraftGroupSelectListRequestAction
  extends IReduxBaseAction<AircraftGroupSelectListType> {
  type: AircraftGroupSelectListType.REQUEST;
}

export interface IAircraftGroupSelectListSuccessAction
  extends IReduxBaseAction<AircraftGroupSelectListType> {
  payload: IGetAircraftGroupListData;
  type: AircraftGroupSelectListType.SUCCESS;
}

export interface IAircraftGroupSelectListErrorAction
  extends IReduxBaseAction<AircraftGroupSelectListType> {
  payload: string;
  type: AircraftGroupSelectListType.ERROR;
}

export type TAircraftGroupSelectListActions =
  | IAircraftGroupSelectListResetAction
  | IAircraftGroupSelectListRequestAction
  | IAircraftGroupSelectListSuccessAction
  | IAircraftGroupSelectListErrorAction;
