import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  ButtonPrimary,
  ButtonSecondary,
  Grid,
  Heading,
  HeadingVariant,
  ModalBase,
  Text,
  TextVariant
} from "next-components";
import colors from "../../colors";
import * as variables from "../../variables";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  button {\n    margin-left: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: 100%;\n  background-color: ", ";\n  margin-bottom: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-right: ", ";\n  color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Container = styled(Grid)(_templateObject(), variables.getSize(136));
var ModalHeader = styled.header(_templateObject2(), variables.getSize(2));
var Icon = styled.div(_templateObject3(), variables.getSize(2), function (props) {
  return props.iconColor;
});
var ModalContent = styled.div(_templateObject4(), colors.general.white, variables.getSize(6));
var ModalFooter = styled.footer(_templateObject5(), variables.getSize(4));
var ModalFooterContent = styled.div(_templateObject6());

var ModalConfirmation = function ModalConfirmation(_ref) {
  var icon = _ref.icon,
      iconColor = _ref.iconColor,
      title = _ref.title,
      text = _ref.text,
      confirmText = _ref.confirmText,
      onConfirm = _ref.onConfirm,
      onCancel = _ref.onCancel,
      secondaryButtonText = _ref.secondaryButtonText;
  return React.createElement(ModalBase, null, React.createElement(Container, {
    rows: "auto auto auto",
    gap: "0px",
    padding: variables.getSize(6)
  }, React.createElement(ModalHeader, null, icon && React.createElement(Icon, {
    iconColor: iconColor
  }, icon), React.createElement(Heading, {
    variant: HeadingVariant.heading2
  }, title)), React.createElement(ModalContent, null, React.createElement(Text, {
    variant: TextVariant.uiText
  }, text)), React.createElement(ModalFooter, {
    "data-test-id": "ModalFooter"
  }, React.createElement(ModalFooterContent, null, React.createElement(ButtonSecondary, {
    onClick: onCancel
  }, secondaryButtonText), React.createElement(ButtonPrimary, {
    onClick: onConfirm
  }, confirmText)))));
};

ModalConfirmation.propTypes = {
  icon: PropTypes.element,
  iconColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  secondaryButtonText: PropTypes.string
};
ModalConfirmation.defaultProps = {
  icon: null,
  iconColor: colors.general.black,
  confirmText: "Confirm",
  secondaryButtonText: "Cancel"
};
ModalConfirmation.displayName = "ModalConfirmation";
export default ModalConfirmation;