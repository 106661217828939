import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IAircraftListTableConfig } from "../models/IAircraft";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IAircraftListTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getAircraftList = async (conf: IAircraftListTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/aircrafts${query}`);
};

const getAircraftDetails = async (aircraftId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/aircrafts/${aircraftId}`);
};

const reGenerateRackCertLoadable = async (aircraftId: string) => {
  const axios = await axiosInstance();
  const payload = { regenerateCertLoadable: true };

  return axios.put(`/aircrafts/${aircraftId}/reGenerateCertLoadable`, payload);
};

const createAircraft = async (config: any) => {
  const postData: any = {
    aircraftType: config.aircraftType,
    changeComment: config.changeComment,
    description: config.description,
    tailNumber: config.tailNumber,
    tenantId: config.tenantId,
  };

  const axios = await axiosInstance();

  return axios.post(`/aircrafts/`, postData);
};

/*
const updateAircraft = async (config: any) => {
  let postData: any = {
    appName: config.appName,
    // appOwner: config.user.userId,
    appVersion: config.appVersion,
    changeComment: config.changeComment,
    // internal: config.internal,
    status: "Active",
    systemName: config.systemName,
  };

  const axios = await axiosInstance();

  return axios.put(`/aircrafts/${config.tenantId}`, postData);
};

const deleteAircraft = async (tenantId: any, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/aircrafts/${tenantId}`, {
    params: { changeComment },
  });
};
*/

export const AircraftService = {
  createAircraft,
  // deleteAircraft,
  getAircraftDetails,
  getAircraftList,
  reGenerateRackCertLoadable,
  // updateAircraft,
};
