import { OrderType } from "./ITableConfig";

export enum tabAircraftGroupDetailsEnum {
  AIRCRAFTS,
  RACKS,
  DETAILS,
  HISTORY,
}

/**
 * Aircraft group types
 */
export enum AircraftGroupTypeEnum {
  /**
   * Tenant/Airline aircraft group
   */
  TENANT = "tenant",
  /**
   * Global aircraft group
   */
  GLOBAL = "global",
}

interface IAircraftGroupListFilters {
  [key: string]: any;
  aircraftGroupName?: string;
  aircraftGroupType?: string;
  aircraftId?: string;
  created_at?: string;
  created_by?: string;
  globalOrTenantId?: string;
  icao?: string;
  modified_at?: string;
  modified_by?: string;
  tenantId?: string;
  tenantName?: string;
}

export interface IAircraftGroupListTableConfig {
  [key: string]: any;
  filter: IAircraftGroupListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetAircraftGroupListData {
  content: any[];
  count: number;
}
