import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetAircraftGroupDetails {
  ERROR = "AIRCRAFT_GROUP_GETDETAILS_ERROR",
  REQUEST = "AIRCRAFT_GROUP_GETDETAILS_REQUEST",
  SUCCESS = "AIRCRAFT_GROUP_GETDETAILS_SUCCESS",
}

export interface IGetAircraftGroupDetailsRequestAction
  extends IReduxBaseAction<TGetAircraftGroupDetails> {
  type: TGetAircraftGroupDetails.REQUEST;
}

export interface IGetAircraftGroupDetailsSuccessAction
  extends IReduxBaseAction<TGetAircraftGroupDetails> {
  payload: any;
  type: TGetAircraftGroupDetails.SUCCESS;
}

export interface IGetAircraftGroupDetailsErrorAction
  extends IReduxBaseAction<TGetAircraftGroupDetails> {
  payload: any;
  type: TGetAircraftGroupDetails.ERROR;
}

export type IGetAircraftGroupDetailsActionType =
  | IGetAircraftGroupDetailsRequestAction
  | IGetAircraftGroupDetailsSuccessAction
  | IGetAircraftGroupDetailsErrorAction;
