import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateApplicationTypes,
  ICreateApplicationErrorAction,
  ICreateApplicationRequestAction,
  ICreateApplicationSuccessAction,
  TSetCreateApplicationActions,
} from "./types";

export const CreateApplicationRequestAction = (data: any): ICreateApplicationRequestAction => ({
  payload: data,
  type: CreateApplicationTypes.REQUEST,
});
export const CreateApplicationSuccessAction = (data: any): ICreateApplicationSuccessAction => ({
  payload: data,
  type: CreateApplicationTypes.SUCCESS,
});
export const CreateApplicationErrorAction = (error: any): ICreateApplicationErrorAction => ({
  payload: error,
  type: CreateApplicationTypes.ERROR,
});

export const createApplication = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetCreateApplicationActions | TEditModalActions>
) => {
  dispatch(CreateApplicationRequestAction(config));
  ApplicationService.createApplication(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateApplicationSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Application created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.appClientId);
    },
    (error: any) => {
      dispatch(CreateApplicationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
