import deepEqual from "deep-equal";
import { ButtonPrimary, GridItem, ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAllBrokerType } from "../actions/broker-type/get-all/actions";
import { editModalCloseAction, editModalOpenAction } from "../actions/modal/edit/actions";
import { deleteAssociationBulk } from "../actions/topic/delete-association-bulk/actions";
import { deleteAssociation } from "../actions/topic/delete-association/actions";
import { appTopicListClearAction, getTopicClientList } from "../actions/topic/get-clients/actions";
import { getTAClass, TA_TYPES } from "../helper/taHelper";
import {
  getAppOwnersCSV,
  getFormattedDate,
  isCurrentUserRole,
  isUserAppOwner,
} from "../helper/util";
import { modalTypeEnum } from "../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../models/IFilterConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../models/ITableConfig";
import { localGroupBridgeTypeEnum, topicStatusEnum, topicTypeEnum } from "../models/ITopicConfig";
import { READ } from "../models/UserTypes";
import { RootState } from "../store";
import { SActiveLink, SOverlay, SSpacer } from "../styles/styles";

import { Filter } from "./Filter";
import { Table } from "./Table";

export const TopicClientList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const getRetainBridge = (row: ITableRow) => {
    if (props.type === "PUBLISHER") {
      return row.retainRequired ? "Yes" : "No";
    }

    if (row.topic.localGroupBridge === localGroupBridgeTypeEnum.ASTROVA) {
      return "Local group bridge";
    }

    return row.needBridge === "1" ? "Yes" : "No";
  };

  const headCells: ITableHeaderCells[] = [
    {
      id: "mqttClientAppName",
      label: "App name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/applicationClients/${row.appClient.appClientId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.appClient.appName}</SActiveLink>,
      width: "2fr",
    },
    {
      id: "mqttClientAppVersion",
      label: "App version",
      value: (row: ITableRow) => row.appClient.appVersion,
    },
    {
      id: "mqttClientBrokerType",
      label: "Broker type",
      value: (row: ITableRow) => row.brokerType.brokerType,
    },
    {
      id: "mqttClientAppOwnerUserFullName",
      label: "Application owner",
      // value: (row: ITableRow) => row.appClient.appOwner.fullName,
      value: (row: ITableRow) => getAppOwnersCSV(row.appClient.appOwners),
    },
    {
      id: props.type === "PUBLISHER" ? "mqttClientRetainRequired" : "mqttClientNeedBridge",
      label: props.type === "PUBLISHER" ? "Retain required" : "Create bridge",
      value: getRetainBridge,
    },
    {
      id: "mqttClientModifiedByFullName",
      label: "Modified by",
      value: (row: ITableRow) => row.modifiedBy.fullName,
    },
    {
      id: "mqttClientModifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
    { id: "delete", label: "Delete" },
  ];

  const headCells_groundside: ITableHeaderCells[] = [
    {
      id: "mqttClientAppName",
      label: "App name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/applicationClients/${row.appClient.appClientId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.appClient.appName}</SActiveLink>,
      width: "2fr",
    },
    {
      id: "mqttClientAppVersion",
      label: "App version",
      value: (row: ITableRow) => row.appClient.appVersion,
    },
    {
      id: "mqttClientBrokerType",
      label: "Broker type",
      value: (row: ITableRow) => row.brokerType.brokerType,
    },
    {
      id: "mqttClientAppOwnerUserFullName",
      label: "Application owner",
      // value: (row: ITableRow) => row.appClient.appOwner.fullName,
      value: (row: ITableRow) => getAppOwnersCSV(row.appClient.appOwners),
    },
    {
      id: "mqttClientModifiedByFullName",
      label: "Modified by",
      value: (row: ITableRow) => row.modifiedBy.fullName,
    },
    {
      id: "mqttClientModifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
    { id: "delete", label: "Delete" },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  const { allBrokerType, topicDetails, topicClientList, currentUser } = useSelector(
    (state: RootState) => ({
      allBrokerType: state.allBrokerType,
      currentUser: state.currentUser,
      topicClientList: state.topicClientList,
      topicDetails:
        state.topicDetails && state.topicDetails.content && state.topicDetails.content.topic,
    })
  );

  const emptyFilterObject = {
    clientType: props.type,
    modifiedDate: { startDate: null, endDate: null },
    mqttClientBrokerType: "",
    mqttConnectedAppClientId: "",
    mqttConnectedAppClientName: "",
    mqttConnectedAppClientOwnerFullName: "",
    mqttConnectedAppClientVersion: "",
    topicId: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "topicName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: {},
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );

    return () => {
      dispatch(appTopicListClearAction());
    };
  }, []);

  useEffect(() => {
    if (config.filter.topicId !== "") {
      dispatch(getTopicClientList(config));
    }
  }, [config]);

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  const askForConfirmation = (topicMqttClientId: string, onlyPublisher: boolean) => {
    const confirmMsg1 =
      "You are trying to remove the association between the Application client and the Topic. Configuration file will be updated accordingly, do you want to continue?";
    const confirmMsg2 =
      "You are trying to remove the last publisher to the topic. Last publisher can only be removed if there are no subscribers to the topic. Do you want to continue?";

    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint:
            "Please enter a comment about why you removed the Application client and Topic association.",
          commentRequired: false,
          contentText: onlyPublisher ? confirmMsg2 : confirmMsg1,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              deleteAssociation(topicMqttClientId, param.changeComment, () => {
                setConfig({ ...config, page: 0 });
              })
            );
          },
          title: `Remove association`,
        },
        type: modalTypeEnum.DELETE_MQTT,
      })
    );
  };

  const askForConfirmationBulk = (mqttIds: string[]) => {
    const confirmMsg1 =
      "You are trying to remove multiple associations between the Application clients and the Topics. Configuration files will be updated accordingly, do you want to continue?";

    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint:
            "Please enter a comment about why you removed multiple Application clients and Topic associations.",
          commentRequired: false,
          contentText: confirmMsg1,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              deleteAssociationBulk({ mqttIds }, param.changeComment, () => {
                setConfig({ ...config, page: 0 });
              })
            );
          },
          title: `Remove multiple associations`,
        },
        type: modalTypeEnum.DELETE_MQTT_BULK,
      })
    );
  };

  const throwConfirmationPopup = (topicMqttClientId: string) => {
    if (props.type === "PUBLISHER") {
      askForConfirmation(topicMqttClientId, topicClientList.count === 1);
    } else {
      askForConfirmation(topicMqttClientId, false);
    }
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const batchDelete = (selRows: ITableRow[]) => {
    const mqttIds = selRows.map((row: ITableRow) => row.topicMqttClientId);
    if (selRows.length === 1) {
      throwConfirmationPopup(mqttIds[0]);
    } else {
      askForConfirmationBulk(mqttIds);
    }
  };

  const isOnlyPublisherWithSubscriber = (selRows: ITableRow[]) => {
    if (
      selRows.length === topicClientList.count &&
      selRows.length !== 1 &&
      props.type === "PUBLISHER" &&
      props.subscriberCount &&
      props.subscriberCount > 0
    ) {
      return true;
    }

    return false;
  };

  const tableProps: ITableConfig = {
    // TODO: UMB-1300 disable bulk delete for non-admin user
    // ...(isAdmin && {
    batchAction: {
      actions: [{ title: "Delete", onClick: batchDelete, disabled: isOnlyPublisherWithSubscriber }],
      label: (selRows: ITableRow[]) => {
        const selectedMsg = `${selRows.length} item${selRows.length > 1 ? "s" : ""} selected`;

        if (isOnlyPublisherWithSubscriber(selRows)) {
          return `${selectedMsg}, at least one publisher MUST exist if there are subscribers`;
        }

        return selectedMsg;
      },
    },
    // }),
    deleteCell: {
      as: "a",
      disabled: (row: ITableRow) =>
        // !isAdmin && currentUser.data.userId !== row.appClient.appOwner.userId,
        !(props.isAdmin || isUserAppOwner(currentUser.data, row.appClient.appOwners)),
      onClick: (row: ITableRow) => {
        // if (isAdmin || currentUser.data.userId === row.appClient.appOwner.userId) {
        if (props.isAdmin || isUserAppOwner(currentUser.data, row.appClient.appOwners)) {
          throwConfirmationPopup(row.topicMqttClientId);
        }
      },
    },
    head: {
      cells: topicDetails.topicType === topicTypeEnum.AIR ? headCells : headCells_groundside,
    },
    list: topicClientList,
    name: "topicClients",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const filterConfig: IFilterConfig = {
    customElements:
      props.type === "PUBLISHER"
        ? undefined
        : [
            {
              element: (
                <GridItem justifySelf="end">
                  <ButtonPrimary
                    className={getTAClass("topicDetails", TA_TYPES.BUTTON, "addSubscriber")}
                    onClick={() =>
                      dispatch(
                        editModalOpenAction({
                          topicClientList,
                          topic: topicDetails,
                          type: modalTypeEnum.SELECT_APP_SUBSCRIPTION,
                          onConfirm: () => {
                            setConfig({ ...config, page: 0 });
                          },
                        })
                      )
                    }
                    disabled={
                      tableProps.list.loading ||
                      ![topicStatusEnum.ACTIVE, topicStatusEnum.DRAFT].includes(
                        topicDetails.status
                      ) ||
                      isCurrentUserRole(currentUser, [READ])
                    }
                  >
                    <>Subscribe</>
                  </ButtonPrimary>
                </GridItem>
              ),
            },
          ],
    items: [
      {
        name: "mqttConnectedAppClientName",
        placeholder: "App name",
        taClass: "appName",
      },
      {
        name: "mqttConnectedAppClientVersion",
        placeholder: "App version",
        taClass: "appVersion",
      },
      {
        data: brokerTypes,
        name: "mqttClientBrokerType",
        placeholder: "Broker type",
        taClass: "brokerType",
        type: FilterType.DROPDOWN,
      },
      {
        name: "mqttConnectedAppClientOwnerFullName",
        placeholder: "Owner",
        taClass: "owner",
      },
      {
        name: "modifiedDate",
        placeholder: "Modified",
        taClass: "modifiedDate",
        type: FilterType.DATEPICKER,
      },
    ],
    pageName: "topicClients",
    returnFilter: (f: typeof emptyFilterObject) => {
      const newFilterObject = {
        ...emptyFilterObject,
        ...f,
        topicId: topicDetails && topicDetails.topicId,
      };

      if (!deepEqual(config.filter, newFilterObject)) {
        setConfig({
          ...config,
          filter: newFilterObject,
          page: 0,
        });
      }
    },
  };

  return (
    <>
      <Filter {...filterConfig} />
      {/* FILTER */}

      <SSpacer />

      {/* LOADING */}
      {topicClientList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {/* TABLE */}
      {!topicClientList.loading && <Table {...tableProps} />}
    </>
  );
};
