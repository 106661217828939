import { Dispatch } from "redux";

import { topicPermissionTypeEnum } from "../../../models/ITopicDetailsConfig";
import { TopicService } from "../../../services/TopicService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import { DeleteTopicPermissionAppTypes, TDeleteTopicPermissionAppActions } from "./types";

export const deleteTopicPermissionAppRequestAction = (): TDeleteTopicPermissionAppActions => ({
  type: DeleteTopicPermissionAppTypes.REQUEST,
});
export const deleteTopicPermissionAppSuccessAction = (
  data: any
): TDeleteTopicPermissionAppActions => ({
  payload: data,
  type: DeleteTopicPermissionAppTypes.SUCCESS,
});
export const deleteTopicPermissionAppErrorAction = (
  error: any
): TDeleteTopicPermissionAppActions => ({
  payload: error,
  type: DeleteTopicPermissionAppTypes.ERROR,
});

export const deleteTopicPermissionApp = (
  topicId: any,
  config: any,
  permType: topicPermissionTypeEnum,
  cb: any
) => (dispatch: Dispatch<TDeleteTopicPermissionAppActions | TPopupActions>) => {
  dispatch(deleteTopicPermissionAppRequestAction());
  TopicService.deleteTopicPermissionApp(topicId, config, permType).then(
    (data: { data: any }) => {
      dispatch(deleteTopicPermissionAppSuccessAction(data.data));
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(
        popupOpenAction({
          content: "Topic permission app removed successfully!",
          title: "Success",
          type: "Info",
        })
      );
      if (cb) {
        cb(topicId, permType);
      }
    },
    (error: any) => {
      dispatch(deleteTopicPermissionAppErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
