import {
  ConfigAppFilterSortType,
  TConfigAppFilterSortActions,
} from "../../actions/configfile/app-filter-sort/types";
import { IFilterSortData as IConfigFileAppFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const configFileAppFilterSortEmptyFilter: any = {
  appName: "",
  appVersion: "",
  createdDate: { startDate: null, endDate: null },
  fileName: "",
  fileVersion: "",
};

export const configFileAppFilterSortInitialState: IConfigFileAppFilterSortState = {
  filter: configFileAppFilterSortEmptyFilter,
  limit: 10,
  orderBy: "createdAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const configFileAppFilterSort = (
  state: IConfigFileAppFilterSortState = configFileAppFilterSortInitialState,
  action: TConfigAppFilterSortActions
): IConfigFileAppFilterSortState => {
  switch (action.type) {
    case ConfigAppFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...configFileAppFilterSortEmptyFilter },
        page: 0,
      };
    case ConfigAppFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...configFileAppFilterSortInitialState,
      };
    case ConfigAppFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
