import moment from "moment-timezone";
import {
  ButtonPrimary,
  colors,
  Grid,
  GridItem,
  Heading,
  ProgressIndicatorLinear,
  Text,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  topicPubPermissionAppsResetAction,
  topicSubPermissionAppsResetAction,
} from "../../../actions/topic/get-permission-apps/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { isUserAppOwner } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { userTenantEnum } from "../../../models/ITenant";
import { topicStatusEnum } from "../../../models/ITopicConfig";
import { topicPermissionTypeEnum } from "../../../models/ITopicDetailsConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import {
  SFlexContainer,
  SFlexItem,
  SModalStackItem,
  SNextFixedHeightContainer,
  SNextFixedWidthContainer,
  SOverlay,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { ModalEditTopicPermission } from "../../modals/ModalEditTopicPermission";

import { TopicDetailsPermissionsAllowedApps } from "./TopicDetailsPermissionsAllowedApps";

export const TopicDetailsPermissionsTab = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { topicPubPermissionApps, topicSubPermissionApps } = useSelector((state: any) => ({
    topicPubPermissionApps: state.topicPubPermissionApps,
    topicSubPermissionApps: state.topicSubPermissionApps,
  }));

  useEffect(() => {
    dispatch(topicPubPermissionAppsResetAction());
    dispatch(topicSubPermissionAppsResetAction());
  }, []);

  return (
    <>
      <TopicDetailsPermissionsAllowedApps
        topicData={props.topicData}
        editModal={props.editModal}
        userData={props.userData}
        permissionType={topicPermissionTypeEnum.PUBLISH}
        topicAllowedApps={topicPubPermissionApps}
      />

      <SSpacer height="32px" />
      <SSpacer height="2px" color={colors.greys.lighten25} />
      <SSpacer />

      <TopicDetailsPermissionsAllowedApps
        topicData={props.topicData}
        editModal={props.editModal}
        userData={props.userData}
        permissionType={topicPermissionTypeEnum.SUBSCRIBE}
        topicAllowedApps={topicSubPermissionApps}
      />

      {props.editModal &&
        props.editModal.open &&
        props.editModal.type === modalTypeEnum.EDIT_TOPIC_PERMISSION && (
          <ModalEditTopicPermission />
        )}
    </>
  );
};
