import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum DeleteTopicPermissionAppTypes {
  ERROR = "DELETE_TOPIC_PERMISSION_APP_ERROR",
  REQUEST = "DELETE_TOPIC_PERMISSION_APP_REQUEST",
  SUCCESS = "DELETE_TOPIC_PERMISSION_APP_SUCCESS",
}

export interface IDeleteTopicPermissionAppRequestAction
  extends IReduxBaseAction<DeleteTopicPermissionAppTypes> {
  type: DeleteTopicPermissionAppTypes.REQUEST;
}

export interface IDeleteTopicPermissionAppSuccessAction
  extends IReduxBaseAction<DeleteTopicPermissionAppTypes> {
  payload: any;
  type: DeleteTopicPermissionAppTypes.SUCCESS;
}

export interface IDeleteTopicPermissionAppErrorAction
  extends IReduxBaseAction<DeleteTopicPermissionAppTypes> {
  payload: any;
  type: DeleteTopicPermissionAppTypes.ERROR;
}

export type TDeleteTopicPermissionAppActions =
  | IDeleteTopicPermissionAppErrorAction
  | IDeleteTopicPermissionAppRequestAction
  | IDeleteTopicPermissionAppSuccessAction;
