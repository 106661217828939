import deepEqual from "deep-equal";
import {
  ButtonGhost,
  ButtonPrimary,
  DateRangePicker,
  Dropdown,
  Grid,
  GridItem,
  Input,
  ProgressIndicatorLinear,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  appFilterSortApplyAction,
  appFilterSortClearAction,
} from "../../actions/application/filter-sort/actions";
import { getApplicationList } from "../../actions/application/get-list/actions";
import { editModalOpenAction } from "../../actions/modal/edit/actions";
import { ModalConfirmation } from "../../components/next-component-fork";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { getAppOwnersCSV, getFormattedDate } from "../../helper/util";
import { useDebounce } from "../../hooks/useDebounce";
import { userListStatusEnum } from "../../models/IAppListingTableConfig";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../../models/UserTypes";
import { appFilterSortEmptyFilter as emptyFilterObject } from "../../reducers/application/ApplicationFilterSortReducer";
import { RootState } from "../../store";
import {
  SButtonIcon,
  SFlexContainer,
  SFlexItem,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ModalCreateApplication } from "../modals/ModalCreateApplication";
import { Table } from "../Table";

export const ApplicationClients = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const history = useHistory();

  const { applicationList } = useSelector((state: RootState) => ({
    applicationList: state.applicationList,
  }));
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));
  const { editModal } = useSelector((state: RootState) => ({
    editModal: state.editModal,
  }));
  const { config } = useSelector((state: RootState) => ({
    config: state.applicationFilterSort,
  }));

  const getAppOwnerNames = (owners: any) =>
    `${owners[0].fullName}${owners.length > 1 ? " ..." : ""}`;

  const headCells: ITableHeaderCells[] = [
    { id: "appName", label: "App name", width: "2fr" },
    { id: "appVersion", label: "Version" },
    {
      id: "appOwner",
      label: "App owners",
      title: (row: ITableRow) => getAppOwnersCSV(row.appOwners),
      // value: (row: ITableRow) => getAppOwnersCSV(row.appOwners),
      value: (row: ITableRow) => getAppOwnerNames(row.appOwners),
    },
    { id: "status", label: "Status" },
    {
      id: "appClientLocation",
      label: "Location",
      value: (row: ITableRow) =>
        row.appClientLocation === brokerLocationEnum.GROUNDSIDE ? "Groundside" : "Airside",
    },
    // { id: "createdBy", label: "Created by", value: (row: ITableRow) => row.createdBy.fullName },
    // {
    //   id: "createdAt",
    //   label: "Created at",
    //   value: (row: ITableRow) => getFormattedDate(row.createdAt),
    // },
    { id: "modifiedBy", label: "Modified by", value: (row: ITableRow) => row.modifiedBy.fullName },
    {
      id: "modifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
    // { id: "numConnRetries", label: "Number of connection retries" },
    // { id: "connRetryDelaySec", label: "Connection retry delay (sec)" },
  ];

  // const emptyFilterObject = {
  //   appBrokerType: "",
  //   appClientId: "",
  //   appName: "",
  //   appOwner: "",
  //   appVersion: "",
  //   createdDate: { startDate: null, endDate: null },
  //   modifiedDate: { startDate: null, endDate: null },
  //   status: userListStatusEnum.EMPTY,
  // };

  // const [config, setConfig] = useState({
  //   filter: { ...emptyFilterObject },
  //   limit: 10,
  //   orderBy: "modifiedAt",
  //   orderType: OrderType.DESC,
  //   page: 0,
  // });

  const setConfig = (newConfig: any) => {
    dispatch(appFilterSortApplyAction(newConfig));
  };

  // const [filter, setFilter] = useState({ ...emptyFilterObject });
  const [filter, setFilter] = useState({ ...config.filter });

  const [needRoUserRequestPermission, setNeedRoUserRequestPermission] = useState(false);

  const [roUserRequestPermissionData, setRoUserRequestPermissionData] = useState({
    confirmTextOptions: {},
    confirmTitle: "Permission required",
  });

  const addFilterToConfig = () => {
    if (!deepEqual(config.filter, filter)) {
      setConfig({ ...config, page: 0, filter: { ...filter } });
    }
  };

  const debounceFilter = useDebounce(filter, 1000);
  useEffect(() => {
    if (debounceFilter && JSON.stringify(filter) !== JSON.stringify(config.filter)) {
      addFilterToConfig();
    }
  }, [debounceFilter]);

  const [filterDirty, setFilterDirty] = useState(false);
  useEffect(() => {
    JSON.stringify(filter) === JSON.stringify(emptyFilterObject)
      ? setFilterDirty(false)
      : setFilterDirty(true);
  }, [filter]);

  useEffect(() => {
    dispatch(getApplicationList(config));
  }, [config]);

  const openDetails = (row: ITableRow): void => {
    router.history.push(`applicationClients/${row.appClientId}`);
  };

  const handleFilterTextInput = (event: any, field: string): void => {
    if (event.target.value === " ") {
      return;
    } // Don't allow empty spaces
    const updatedFilter = { [field]: event.target.value };
    setFilter({ ...filter, ...updatedFilter });
  };

  const handleClearFilter = (): void => {
    setFilter(emptyFilterObject);

    // ** NOTE: logically the above line 'setFilter(emptyFilterObject);' is good enough to clear filter
    // The following line is to make the empty filter applied right away, no waiting for debounce (1 second)

    // setConfig({ ...config, filter: { ...emptyFilterObject } });
    dispatch(appFilterSortClearAction());
  };

  const handleFilterKeyPress = (event: any) => {
    if (event.key === "Enter") {
      addFilterToConfig();
    }
  };

  const handleDatePicker = (date: any, type: string) => {
    if (type === "created") {
      setFilter({ ...filter, createdDate: { ...date } });
    }
    if (type === "modified") {
      setFilter({ ...filter, modifiedDate: { ...date } });
    }
  };

  const handleFilterMyApplications = () => {
    setFilter({ ...filter, appOwner: currentUser.data.fullName });
    addFilterToConfig();
  };

  const handleFilterStatus = (status: userListStatusEnum) => {
    setFilter({ ...filter, status });
    addFilterToConfig();
  };

  const handleFilterLocation = (location: brokerLocationEnum) => {
    setFilter({ ...filter, appClientLocation: location });
    addFilterToConfig();
  };

  const handleOpenModal = () => {
    if (currentUser.data.role === READ) {
      const message =
        'In order to create Application clients, you must request "Application owner" access, would you like to proceed?';
      setRoUserRequestPermissionData({
        ...roUserRequestPermissionData,
        confirmTextOptions: {
          dangerouslySetInnerHTML: { __html: message },
        },
      });
      setNeedRoUserRequestPermission(true);
    } else {
      dispatch(
        editModalOpenAction({
          // appLocation: appLocation,
          type: modalTypeEnum.NEW_APP,
        })
      );
    }
  };

  const handleRoUserRequestPermissionConfirm = () => {
    setNeedRoUserRequestPermission(false);
    history.push(`users/${currentUser.data.userId}`);
  };

  const handleRoUserRequestPermissionCancel = () => {
    setNeedRoUserRequestPermission(false);
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...applicationList,
      onClickRow: openDetails,
    },
    name: "application",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* LOADING */}
      {applicationList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      <SPageContainer>
        <SPageHeading className="ta-application-title">Application clients</SPageHeading>

        {/* FILTER */}
        <Grid columns="1fr 1fr 1fr 1fr 1fr 1fr">
          <GridItem>
            {/* FILTER - App Name */}
            <Input
              className={getTAClass("applicationClients", TA_TYPES.FILTER, "appName")}
              placeholder="App name"
              value={filter.appName}
              onChange={(e: any) => {
                handleFilterTextInput(e, headCells[0].id);
              }}
              onKeyPress={handleFilterKeyPress}
            />
          </GridItem>
          <GridItem>
            {/* FILTER - App Version */}
            <Input
              className={getTAClass("applicationClients", TA_TYPES.FILTER, "version")}
              placeholder="Version"
              value={filter.appVersion}
              onChange={(e: any) => {
                handleFilterTextInput(e, headCells[1].id);
              }}
              onKeyPress={handleFilterKeyPress}
            />
          </GridItem>
          <GridItem>
            {/* FILTER - Owner Name */}
            <Input
              className={getTAClass("applicationClients", TA_TYPES.FILTER, "appOwner")}
              placeholder="App owner"
              value={filter.appOwner}
              onChange={(e: any) => {
                handleFilterTextInput(e, headCells[2].id);
              }}
              onKeyPress={handleFilterKeyPress}
            />
          </GridItem>

          <GridItem className={getTAClass("applicationClients", TA_TYPES.FILTER, "status")}>
            <Dropdown
              options={[
                {
                  label: <span className="ta-dropdown-all">ALL</span>,
                  value: userListStatusEnum.ALL,
                },
                {
                  label: <span className="ta-dropdown-active">ACTIVE</span>,
                  value: userListStatusEnum.ACTIVE,
                },
                {
                  label: <span className="ta-dropdown-deleted">DELETED</span>,
                  value: userListStatusEnum.DELETED,
                },
              ]}
              label="Status"
              onChange={handleFilterStatus}
              selected={filter.status}
            />
          </GridItem>

          <GridItem className={getTAClass("applicationClients", TA_TYPES.FILTER, "location")}>
            <Dropdown
              options={[
                {
                  label: <span className="ta-dropdown-air">Airside</span>,
                  value: brokerLocationEnum.AIRSIDE,
                },
                {
                  label: <span className="ta-dropdown-ground">Groundside</span>,
                  value: brokerLocationEnum.GROUNDSIDE,
                },
              ]}
              label="Location"
              onChange={handleFilterLocation}
              selected={filter.appClientLocation}
            />
          </GridItem>

          {/* <GridItem>
            {/ * FILTER - Created From To * /}
            <DateRangePicker
              className={getTAClass("applicationClients", TA_TYPES.FILTER, "createdDate")}
              startDatePlaceholderText="Created from"
              endDatePlaceholderText="Created to"
              startDate={filter.createdDate.startDate}
              endDate={filter.createdDate.endDate}
              onDatesChange={(date: any) => {
                handleDatePicker(date, "created");
              }}
              enableOutsideDays
              isOutsideRange={() => false}
            />
          </GridItem> */}

          <GridItem>
            {/* FILTER - Modified From To */}
            <DateRangePicker
              className={getTAClass("applicationClients", TA_TYPES.FILTER, "modifiedDate")}
              startDatePlaceholderText="Modified from"
              endDatePlaceholderText="Modified to"
              startDate={filter.modifiedDate.startDate}
              endDate={filter.modifiedDate.endDate}
              onDatesChange={(date: any) => {
                handleDatePicker(date, "modified");
              }}
              enableOutsideDays
              isOutsideRange={() => false}
            />
          </GridItem>
        </Grid>

        <SSpacer />

        <SFlexContainer justifyContent="space-between">
          <SFlexItem>
            <Grid columns="1fr">
              <GridItem>
                <ButtonGhost
                  className={getTAClass("applicationClients", TA_TYPES.BUTTON, "myApplications")}
                  onClick={handleFilterMyApplications}
                >
                  My applications
                </ButtonGhost>
              </GridItem>
            </Grid>
          </SFlexItem>
          <SFlexItem>
            <ButtonPrimary
              className={getTAClass("applicationClients", TA_TYPES.BUTTON, "clearFilter")}
              onClick={handleClearFilter}
              disabled={!filterDirty}
            >
              Clear filter
            </ButtonPrimary>
            <SVerticalSpacer />
            <SButtonIcon
              size="default"
              icon={<SIconAdd32 />}
              onClick={handleOpenModal}
              className={getTAClass("applicationClients", TA_TYPES.BUTTON, "createNew")}
              disabled={
                // ![READ, ADMINISTRATOR, APPLICATION_OWNER, APPROVER].includes(currentUser.data.role)
                false
              }
            />
          </SFlexItem>
        </SFlexContainer>

        <SSpacer />

        <Table {...tableProps}></Table>
      </SPageContainer>

      {/* CREATE APPLICATION MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.NEW_APP ? (
        <ModalCreateApplication />
      ) : (
        ""
      )}

      {/* Request permission modal dialog for read-only user when adding an app client */}
      {needRoUserRequestPermission ? (
        <ModalConfirmation
          title={
            <span className="ta-modal-confirmation-title">
              {roUserRequestPermissionData.confirmTitle}
            </span>
          }
          text={
            <span
              className="ta-modal-confirmation-content"
              {...roUserRequestPermissionData.confirmTextOptions}
            ></span>
          }
          confirmText="Yes"
          secondaryButtonText="No"
          onConfirm={handleRoUserRequestPermissionConfirm}
          onCancel={handleRoUserRequestPermissionCancel}
        />
      ) : (
        ""
      )}
    </>
  );
};
