import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditAppVisibilityTypes {
  ERROR = "EDIT_APP_VISIBILITY_ERROR",
  REQUEST = "EDIT_APP_VISIBILITY_REQUEST",
  SUCCESS = "EDIT_APP_VISIBILITY_SUCCESS",
}

export interface IEditAppVisibilityRequestAction extends IReduxBaseAction<EditAppVisibilityTypes> {
  payload: any;
  type: EditAppVisibilityTypes.REQUEST;
}

export interface IEditAppVisibilitySuccessAction extends IReduxBaseAction<EditAppVisibilityTypes> {
  payload: any;
  type: EditAppVisibilityTypes.SUCCESS;
}

export interface IEditAppVisibilityErrorAction extends IReduxBaseAction<EditAppVisibilityTypes> {
  payload: any;
  type: EditAppVisibilityTypes.ERROR;
}

export type TSetEditAppVisibilityActions =
  | IEditAppVisibilityErrorAction
  | IEditAppVisibilityRequestAction
  | IEditAppVisibilitySuccessAction;
