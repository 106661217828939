import { Grid, GridItem } from "next-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalCloseAction, editModalOpenAction } from "../actions/modal/edit/actions";
import { getTenantLabel } from "../helper/util";
import { appVisibilityEnum } from "../models/IAppDetailsConfig";
import {
  HistoryChangeTypeEnum,
  HistoryDisplayModeEnum,
  HistoryItemFieldEnum,
  IChangeHistoryInfo,
  IChangeHistoryItem,
} from "../models/IChangeHistories";
import { GroundEnvironmentTypeEnum } from "../models/IDestination";
import { AppDestinationRouteTypeEnum } from "../models/IDestinationRoute";
import { modalTypeEnum } from "../models/IEditModalConfig";
import { MqttClientTypesEnum, MqttLocalGroupDataEnum } from "../models/IMqtt";
import { topicStatusEnum } from "../models/ITopicConfig";
import { topicPermissionEnum } from "../models/ITopicDetailsConfig";
import { RootState } from "../store";
import {
  SActiveLink,
  SMediumText,
  STextDataTitle,
  STextDataValue,
  STextDataValue2,
} from "../styles/styles";

export const ChangeHistoryItem = (props: IChangeHistoryInfo) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const handleDisplayItems = (info: IChangeHistoryInfo, items: IChangeHistoryItem[]) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          items,
          title: `Payload change details`,
          topicName: info.topic
            ? info.topic.topicName +
              (info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT ? " (DRAFT)" : "")
            : "",
        },
        type: modalTypeEnum.DISPLAY_HISTORY_ITEMS,
      })
    );
  };

  /* tslint:disable:cyclomatic-complexity */
  const getItemFieldText = (value: IChangeHistoryItem) => {
    switch (value.itemField) {
      // app client fields
      case HistoryItemFieldEnum.APP_NAME:
        return "App name";

      case HistoryItemFieldEnum.APP_VERSION:
        return "App version";

      case HistoryItemFieldEnum.APP_OWNER:
        return "Application owners";

      case HistoryItemFieldEnum.APP_OWNER_ADDED:
        return "Application owners added";

      case HistoryItemFieldEnum.APP_OWNER_REMOVED:
        return "Application owners removed";

      case HistoryItemFieldEnum.USER_NAME:
        return "Application user name";

      case HistoryItemFieldEnum.PASSWORD:
        return "Application password";

      case HistoryItemFieldEnum.STATUS:
        return "Status";

      case HistoryItemFieldEnum.NUM_CONN_RETRIES:
        return "Number of connection retries";

      case HistoryItemFieldEnum.CONN_RETRY_DELAY_SECS:
        return "Connection retry delay (sec)";

      // case HistoryItemFieldEnum.INTERNAL:
      //   return "Internal";

      case HistoryItemFieldEnum.VISIBILITY:
        return "Group visibility";

      case HistoryItemFieldEnum.IS_VISIBLE_FOR_PAC:
        return "Allow PAC users visibility";

      case HistoryItemFieldEnum.VISIBILITY_USER_ADDED:
        return "Additional visibility user added";

      case HistoryItemFieldEnum.VISIBILITY_USER_REMOVED:
        return "Additional visibility user removed";

      case HistoryItemFieldEnum.DESTINATION_TYPE:
        return "Destination type";

      case HistoryItemFieldEnum.DESTINATION_ENDPOINT:
        return "Destination endpoint";

      // topic fields
      case HistoryItemFieldEnum.TOPIC_NAME:
        return "Topic name";

      case HistoryItemFieldEnum.APP_CLIENT:
        return "Application";

      case HistoryItemFieldEnum.DESCRIPTION:
        return "Description";

      case HistoryItemFieldEnum.IS_SECURE:
        return "Secure";

      case HistoryItemFieldEnum.ACCESS_ANONYMOUS:
        return "Allow anonymous access";

      case HistoryItemFieldEnum.PAYLOAD:
        return "Payload";

      case HistoryItemFieldEnum.QOS_LEVEL:
        return "QoS level";

      case HistoryItemFieldEnum.TTL:
        return "TTL";

      case HistoryItemFieldEnum.OFFLOAD_TYPE:
        return "Offload type";

      case HistoryItemFieldEnum.PRIORITY:
        return "Priority";

      case HistoryItemFieldEnum.MULTICAST:
        return "Multicast";

      case HistoryItemFieldEnum.MAX_PAYLOAD_SIZE:
        return "Max payload size (KB)";

      case HistoryItemFieldEnum.THROTTLE_THRESHOLD_FACTOR:
        return "Number of messages published per minute";

      case HistoryItemFieldEnum.DEFAULT_RETAIN_REQUIRED:
        return "Default retain required";

      case HistoryItemFieldEnum.LOCAL_GROUP_BRIDGE:
        return "Local group bridge";

      // topic permission
      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION:
        return "Topic publish permission";

      case HistoryItemFieldEnum.TOPIC_IS_PUBLISHABLE_FOR_PAC:
        return "Allow PAC application to publish";

      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION_APP_ADDED:
        return "Additional publishable application added";

      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION_APP_REMOVED:
        return "Additional publishable application removed";

      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION:
        return "Topic subscribe permission";

      case HistoryItemFieldEnum.TOPIC_IS_SUBSCRIBABLE_FOR_PAC:
        return "Allow PAC application to subscribe";

      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION_APP_ADDED:
        return "Additional subscribable application added";

      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION_APP_REMOVED:
        return "Additional subscribable application removed";

      // pub/sub fields
      case HistoryItemFieldEnum.RETAIN_REQUIRED:
        return "Retain required";

      case HistoryItemFieldEnum.LOCAL_GROUP_DATA:
        return "Local group data";

      case HistoryItemFieldEnum.NEED_BRIDGE:
        return "I want to create a bridge";

      case HistoryItemFieldEnum.AIRCRAFT_ADDED_TO_GROUP:
        return "Aircraft / rack added to group";

      case HistoryItemFieldEnum.AIRCRAFT_REMOVED_FROM_GROUP:
        return "Aircraft / rack removed from group";

      // No need to display anything for topic status change
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_REQUESTED_STATUS:
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_FINISHED_ACTION:
      //   break;

      case HistoryItemFieldEnum.AIRCRAFT_PROVISION_STATUS:
        return "Provision status";

      case HistoryItemFieldEnum.AIRCRAFT_ACTIVE_STATE:
        return "Aircraft status";

      case HistoryItemFieldEnum.AIRCRAFT_IOT_NAME:
      case HistoryItemFieldEnum.AIRCRAFT_IOT_ARN:
        break;

      case HistoryItemFieldEnum.AIRCRAFT_IOT_CERTIFICATE_INFO:
        return "Certificate info";

      case HistoryItemFieldEnum.USER_ROLE:
        return "User role";

      case HistoryItemFieldEnum.USER_TENANT:
        return "User tenant";

      default:
    }

    return "";
  };

  const getItemBefore = (info: IChangeHistoryInfo, value: IChangeHistoryItem) => {
    switch (value.itemField) {
      case HistoryItemFieldEnum.APP_NAME:
      case HistoryItemFieldEnum.APP_VERSION:
      case HistoryItemFieldEnum.USER_NAME:
      case HistoryItemFieldEnum.STATUS:
      case HistoryItemFieldEnum.NUM_CONN_RETRIES:
      case HistoryItemFieldEnum.CONN_RETRY_DELAY_SECS:
      // case HistoryItemFieldEnum.VISIBILITY:
      case HistoryItemFieldEnum.DESTINATION_TYPE:
      case HistoryItemFieldEnum.DESTINATION_ENDPOINT:
      case HistoryItemFieldEnum.TOPIC_NAME:
      case HistoryItemFieldEnum.DESCRIPTION:
      case HistoryItemFieldEnum.QOS_LEVEL:
      case HistoryItemFieldEnum.TTL:
      case HistoryItemFieldEnum.OFFLOAD_TYPE:
      case HistoryItemFieldEnum.PRIORITY:
      case HistoryItemFieldEnum.MAX_PAYLOAD_SIZE:
      case HistoryItemFieldEnum.THROTTLE_THRESHOLD_FACTOR:
      case HistoryItemFieldEnum.DEFAULT_RETAIN_REQUIRED:
      case HistoryItemFieldEnum.LOCAL_GROUP_BRIDGE:
      case HistoryItemFieldEnum.AIRCRAFT_PROVISION_STATUS:
      case HistoryItemFieldEnum.AIRCRAFT_ACTIVE_STATE:
      case HistoryItemFieldEnum.AIRCRAFT_IOT_CERTIFICATE_INFO:
      case HistoryItemFieldEnum.USER_ROLE:
      case HistoryItemFieldEnum.USER_TENANT:
        return <STextDataValue>{value.valueBefore ? value.valueBefore : ""}</STextDataValue>;

      case HistoryItemFieldEnum.IS_VISIBLE_FOR_PAC:
      case HistoryItemFieldEnum.IS_SECURE:
      case HistoryItemFieldEnum.ACCESS_ANONYMOUS:
      case HistoryItemFieldEnum.MULTICAST:
      // case HistoryItemFieldEnum.INTERNAL:
      case HistoryItemFieldEnum.TOPIC_IS_PUBLISHABLE_FOR_PAC:
      case HistoryItemFieldEnum.TOPIC_IS_SUBSCRIBABLE_FOR_PAC:
        return <STextDataValue>{value.valueBefore === "true" ? "Yes" : "No"}</STextDataValue>;

      case HistoryItemFieldEnum.APP_OWNER:
      case HistoryItemFieldEnum.APP_OWNER_REMOVED:
      case HistoryItemFieldEnum.VISIBILITY_USER_REMOVED:
        // return <STextDataValue>{value.userBefore ? value.userBefore.fullName : ""}</STextDataValue>;
        return value.userBefore ? (
          <STextDataValue>
            {info.isAdmin || info.curUserId === value.userBefore.userId ? (
              <SActiveLink>
                <Link to={`/users/${value.userBefore.userId}`}>{value.userBefore.fullName}</Link>
              </SActiveLink>
            ) : (
              value.userBefore.fullName
            )}
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      case HistoryItemFieldEnum.PASSWORD:
        return <STextDataValue>************</STextDataValue>;

      case HistoryItemFieldEnum.APP_CLIENT:
      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION_APP_REMOVED:
      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION_APP_REMOVED:
        return value.appClientBefore ? (
          <STextDataValue>
            <SActiveLink>
              <Link to={`/applicationClients/${value.appClientBefore.appClientId}`}>
                {`${value.appClientBefore.appName} ${value.appClientBefore.appVersion}`}
              </Link>
            </SActiveLink>
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      case HistoryItemFieldEnum.PAYLOAD:
        return (
          <STextDataValue>
            <SActiveLink
              onClick={() => {
                handleDisplayItems(info, [value]);
              }}
            >
              Show payload change details...
            </SActiveLink>
          </STextDataValue>
        );

      case HistoryItemFieldEnum.VISIBILITY:
        return (
          <STextDataValue>
            {value.valueBefore
              ? value.valueBefore === appVisibilityEnum.ALL_USERS
                ? "All users"
                : `${getTenantLabel(info.appClient)} users`
              : ""}
          </STextDataValue>
        );

      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION:
      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION:
        return (
          <STextDataValue>
            {value.valueBefore
              ? value.valueBefore === topicPermissionEnum.OWNER_APPS
                ? "Owner applications"
                : value.valueBefore === topicPermissionEnum.TENANT_APPS
                  ? info.topic && info.topic.appClient
                    ? `${getTenantLabel(info.topic.appClient)} applications`
                    : `Tenant applications`
                  : "All applications"
              : ""}
          </STextDataValue>
        );

      case HistoryItemFieldEnum.AIRCRAFT_REMOVED_FROM_GROUP:
        return value.aircraftBefore ? (
          <STextDataValue>
            <SActiveLink>
              <Link to={`/aircrafts/${value.aircraftBefore.aircraftId}`}>
                {`${value.aircraftBefore.tailNumber}`}
              </Link>
            </SActiveLink>
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      // case HistoryItemFieldEnum.RETAIN_REQUIRED:
      // case HistoryItemFieldEnum.NEED_BRIDGE:

      // // No need to display anything for topic status change
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_REQUESTED_STATUS:
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_FINISHED_ACTION:

      default:
        return <STextDataValue />;
    }
  };

  const getItemAfter = (info: IChangeHistoryInfo, value: IChangeHistoryItem) => {
    switch (value.itemField) {
      case HistoryItemFieldEnum.APP_NAME:
      case HistoryItemFieldEnum.APP_VERSION:
      case HistoryItemFieldEnum.USER_NAME:
      case HistoryItemFieldEnum.STATUS:
      case HistoryItemFieldEnum.NUM_CONN_RETRIES:
      case HistoryItemFieldEnum.CONN_RETRY_DELAY_SECS:
      // case HistoryItemFieldEnum.VISIBILITY:
      case HistoryItemFieldEnum.DESTINATION_TYPE:
      case HistoryItemFieldEnum.DESTINATION_ENDPOINT:
      case HistoryItemFieldEnum.TOPIC_NAME:
      case HistoryItemFieldEnum.DESCRIPTION:
      case HistoryItemFieldEnum.QOS_LEVEL:
      case HistoryItemFieldEnum.TTL:
      case HistoryItemFieldEnum.OFFLOAD_TYPE:
      case HistoryItemFieldEnum.PRIORITY:
      case HistoryItemFieldEnum.MAX_PAYLOAD_SIZE:
      case HistoryItemFieldEnum.THROTTLE_THRESHOLD_FACTOR:
      case HistoryItemFieldEnum.DEFAULT_RETAIN_REQUIRED:
      case HistoryItemFieldEnum.LOCAL_GROUP_BRIDGE:
      case HistoryItemFieldEnum.AIRCRAFT_PROVISION_STATUS:
      case HistoryItemFieldEnum.AIRCRAFT_ACTIVE_STATE:
      case HistoryItemFieldEnum.AIRCRAFT_IOT_CERTIFICATE_INFO:
      case HistoryItemFieldEnum.USER_ROLE:
      case HistoryItemFieldEnum.USER_TENANT:
        return <STextDataValue>{value.valueAfter ? value.valueAfter : ""}</STextDataValue>;

      case HistoryItemFieldEnum.IS_VISIBLE_FOR_PAC:
      case HistoryItemFieldEnum.IS_SECURE:
      case HistoryItemFieldEnum.ACCESS_ANONYMOUS:
      case HistoryItemFieldEnum.MULTICAST:
      // case HistoryItemFieldEnum.INTERNAL:
      case HistoryItemFieldEnum.TOPIC_IS_PUBLISHABLE_FOR_PAC:
      case HistoryItemFieldEnum.TOPIC_IS_SUBSCRIBABLE_FOR_PAC:
        return <STextDataValue>{value.valueAfter === "true" ? "Yes" : "No"}</STextDataValue>;

      case HistoryItemFieldEnum.APP_OWNER:
      case HistoryItemFieldEnum.APP_OWNER_ADDED:
      case HistoryItemFieldEnum.VISIBILITY_USER_ADDED:
        // return <STextDataValue>{value.userAfter ? value.userAfter.fullName : ""}</STextDataValue>;
        return value.userAfter ? (
          <STextDataValue>
            {info.isAdmin || info.curUserId === value.userAfter.userId ? (
              <SActiveLink>
                <Link to={`/users/${value.userAfter.userId}`}>{value.userAfter.fullName}</Link>
              </SActiveLink>
            ) : (
              value.userAfter.fullName
            )}
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      case HistoryItemFieldEnum.PASSWORD:
        return <STextDataValue>************</STextDataValue>;

      case HistoryItemFieldEnum.APP_CLIENT:
      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION_APP_ADDED:
      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION_APP_ADDED:
        return value.appClientAfter ? (
          <STextDataValue>
            <SActiveLink>
              <Link to={`/applicationClients/${value.appClientAfter.appClientId}`}>
                {`${value.appClientAfter.appName} ${value.appClientAfter.appVersion}`}
              </Link>
            </SActiveLink>
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      case HistoryItemFieldEnum.PAYLOAD:
        return (
          <STextDataValue>
            {/* {value.topicPayloadDetailAfter ? value.topicPayloadDetailAfter.payloadMasterId : ""} */}
          </STextDataValue>
        );

      case HistoryItemFieldEnum.VISIBILITY:
        return (
          <STextDataValue>
            {value.valueAfter
              ? value.valueAfter === appVisibilityEnum.ALL_USERS
                ? "All users"
                : `${getTenantLabel(info.appClient)} users`
              : ""}
          </STextDataValue>
        );

      case HistoryItemFieldEnum.TOPIC_PUB_PERMISSION:
      case HistoryItemFieldEnum.TOPIC_SUB_PERMISSION:
        return (
          <STextDataValue>
            {value.valueAfter
              ? value.valueAfter === topicPermissionEnum.OWNER_APPS
                ? "Owner applications"
                : value.valueAfter === topicPermissionEnum.TENANT_APPS
                  ? info.topic && info.topic.appClient
                    ? `${getTenantLabel(info.topic.appClient)} applications`
                    : `Tenant applications`
                  : "All applications"
              : ""}
          </STextDataValue>
        );

      case HistoryItemFieldEnum.RETAIN_REQUIRED:
      case HistoryItemFieldEnum.NEED_BRIDGE:
        return (
          <STextDataValue>{`${getItemFieldText(value)}: ${
            value.valueAfter === "true" ? "Yes" : "No"
          }`}</STextDataValue>
        );

      case HistoryItemFieldEnum.LOCAL_GROUP_DATA:
        return (
          <STextDataValue>{`${getItemFieldText(value)}: ${
            value.valueAfter === MqttLocalGroupDataEnum.ASTROVA_PUB_PRIMARY
              ? "Publish unit: Primary"
              : "Publish unit: Secondary"
          }`}</STextDataValue>
        );

      case HistoryItemFieldEnum.AIRCRAFT_ADDED_TO_GROUP:
        return value.aircraftAfter ? (
          <STextDataValue>
            <SActiveLink>
              <Link to={`/aircrafts/${value.aircraftAfter.aircraftId}`}>
                {`${value.aircraftAfter.tailNumber}`}
              </Link>
            </SActiveLink>
          </STextDataValue>
        ) : (
          <STextDataValue />
        );

      // // No need to display anything for topic status change
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_REQUESTED_STATUS:
      // case HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_FINISHED_ACTION:

      default:
        return <STextDataValue />;
    }
  };

  const getHistoryItemValue = (info: IChangeHistoryInfo, value: IChangeHistoryItem) => {
    switch (info.changeType) {
      // case HistoryChangeTypeEnum.APP_CLIENT_CREATED:
      //   break;

      case HistoryChangeTypeEnum.APP_CLIENT_MODIFIED:
      case HistoryChangeTypeEnum.TOPIC_MODIFIED:
      case HistoryChangeTypeEnum.AIRCRAFT_MODIFIED:
      case HistoryChangeTypeEnum.AIRCRAFT_GROUP_MODIFIED:
      case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_MODIFIED:
      case HistoryChangeTypeEnum.USER_MODIFIED:
        return (
          <Grid columns={info.columns ? info.columns : "2fr 1fr 1fr"} paddingLeft="30px">
            <GridItem>
              <SMediumText variant="uiText" color="#404040">
                <STextDataValue>{getItemFieldText(value)}</STextDataValue>
              </SMediumText>
            </GridItem>
            <GridItem>
              <SMediumText variant="uiText">
                {/* <STextDataValue>{value.valueBefore ? value.valueBefore : ""}</STextDataValue> */}
                {getItemBefore(info, value)}
              </SMediumText>
            </GridItem>
            <GridItem>
              <SMediumText variant="uiText">
                {/* <STextDataValue>{value.valueAfter ? value.valueAfter : ""}</STextDataValue> */}
                {getItemAfter(info, value)}
              </SMediumText>
            </GridItem>
          </Grid>
        );

      // case HistoryChangeTypeEnum.APP_CLIENT_DELETED:
      //   break;

      // case HistoryChangeTypeEnum.TOPIC_CREATED:
      //   break;

      // case HistoryChangeTypeEnum.TOPIC_DELETED:
      //   break;

      case HistoryChangeTypeEnum.PUBLISHER_CREATED:
      case HistoryChangeTypeEnum.SUBSCRIBER_CREATED:
        switch (info.displayMode) {
          case HistoryDisplayModeEnum.APP_CLIENT_HISTORY:
            return (
              // <Grid columns={info.columns ? info.columns : "3fr 1fr"} paddingLeft="30px">
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  {info.index === 0 ? (
                    <SMediumText variant="uiText">
                      <STextDataValue2>
                        {info.changeType === HistoryChangeTypeEnum.PUBLISHER_CREATED
                          ? "Published topic"
                          : "Subscribed topic"}
                      </STextDataValue2>
                      {info.topic && (
                        <STextDataValue2>
                          <SActiveLink>
                            <Link to={`/topics/${info.topic.topicId}`}>
                              {info.topic.topicName}{" "}
                              {info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT
                                ? "(DRAFT)"
                                : ""}
                            </Link>
                          </SActiveLink>
                        </STextDataValue2>
                      )}
                      {info.brokerType && (
                        <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                      )}
                    </SMediumText>
                  ) : (
                    <div />
                  )}
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          case HistoryDisplayModeEnum.TOPIC_HISTORY:
            return (
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  {info.index === 0 ? (
                    <SMediumText variant="uiText">
                      <STextDataValue2>
                        {info.changeType === HistoryChangeTypeEnum.PUBLISHER_CREATED
                          ? "Published by app"
                          : "Subscribed by app"}
                      </STextDataValue2>
                      {info.appClient && (
                        <STextDataValue2>
                          <SActiveLink>
                            <Link to={`/applicationClients/${info.appClient.appClientId}`}>
                              {`${info.appClient.appName} ${info.appClient.appVersion}`}
                            </Link>
                          </SActiveLink>
                        </STextDataValue2>
                      )}
                      {info.brokerType && (
                        <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                      )}
                    </SMediumText>
                  ) : (
                    <div />
                  )}
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          case HistoryDisplayModeEnum.GLOBAL_HISTORY:
            return (
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  {info.index === 0 ? (
                    <SMediumText variant="uiText">
                      <STextDataValue2>{"App"}</STextDataValue2>
                      {info.appClient && (
                        <STextDataValue2>
                          <SActiveLink>
                            <Link to={`/applicationClients/${info.appClient.appClientId}`}>
                              {`${info.appClient.appName} ${info.appClient.appVersion}`}
                            </Link>
                          </SActiveLink>
                        </STextDataValue2>
                      )}
                      <STextDataValue2>
                        {info.changeType === HistoryChangeTypeEnum.PUBLISHER_CREATED
                          ? "published topic"
                          : "subscribed topic"}
                      </STextDataValue2>
                      {info.topic && (
                        <STextDataValue2>
                          <SActiveLink>
                            <Link to={`/topics/${info.topic.topicId}`}>
                              {info.topic.topicName}{" "}
                              {info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT
                                ? "(DRAFT)"
                                : ""}
                            </Link>
                          </SActiveLink>
                        </STextDataValue2>
                      )}
                      {info.brokerType && (
                        <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                      )}
                    </SMediumText>
                  ) : (
                    <div />
                  )}
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          default:
            return <SMediumText variant="uiText" />;
        }

      case HistoryChangeTypeEnum.PUBLISHER_DELETED:
      case HistoryChangeTypeEnum.SUBSCRIBER_DELETED:
        switch (info.displayMode) {
          case HistoryDisplayModeEnum.APP_CLIENT_HISTORY:
            return (
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  <SMediumText variant="uiText">
                    <STextDataValue2>
                      {info.changeType === HistoryChangeTypeEnum.PUBLISHER_DELETED
                        ? "Deleted topic publication"
                        : "Deleted topic subscription"}
                    </STextDataValue2>
                    {info.topic && (
                      <STextDataValue2>
                        <SActiveLink>
                          <Link to={`/topics/${info.topic.topicId}`}>
                            {info.topic.topicName}{" "}
                            {info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT
                              ? "(DRAFT)"
                              : ""}
                          </Link>
                        </SActiveLink>
                      </STextDataValue2>
                    )}
                    {info.brokerType && (
                      <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                    )}
                  </SMediumText>
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          case HistoryDisplayModeEnum.TOPIC_HISTORY:
            return (
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  <SMediumText variant="uiText">
                    <STextDataValue2>
                      {info.changeType === HistoryChangeTypeEnum.PUBLISHER_DELETED
                        ? "Deleted app publisher"
                        : "Deleted app subscriber"}
                    </STextDataValue2>
                    {info.appClient && (
                      <STextDataValue2>
                        <SActiveLink>
                          <Link to={`/applicationClients/${info.appClient.appClientId}`}>
                            {`${info.appClient.appName} ${info.appClient.appVersion}`}
                          </Link>
                        </SActiveLink>
                      </STextDataValue2>
                    )}
                    {info.brokerType && (
                      <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                    )}
                  </SMediumText>
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          case HistoryDisplayModeEnum.GLOBAL_HISTORY:
            return (
              <Grid columns="3fr 1fr" paddingLeft="30px">
                <GridItem>
                  <SMediumText variant="uiText">
                    <STextDataValue2>{"Deleted app"}</STextDataValue2>
                    {info.appClient && (
                      <STextDataValue2>
                        <SActiveLink>
                          <Link to={`/applicationClients/${info.appClient.appClientId}`}>
                            {`${info.appClient.appName} ${info.appClient.appVersion}`}
                          </Link>
                        </SActiveLink>
                      </STextDataValue2>
                    )}
                    <STextDataValue2>
                      {info.changeType === HistoryChangeTypeEnum.PUBLISHER_DELETED
                        ? "published topic"
                        : "subscribed topic"}
                    </STextDataValue2>
                    {info.topic && (
                      <STextDataValue2>
                        <SActiveLink>
                          <Link to={`/topics/${info.topic.topicId}`}>
                            {info.topic.topicName}{" "}
                            {info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT
                              ? "(DRAFT)"
                              : ""}
                          </Link>
                        </SActiveLink>
                      </STextDataValue2>
                    )}
                    {info.brokerType && (
                      <STextDataValue2>{`from ${info.brokerType.brokerTypeName}`}</STextDataValue2>
                    )}
                  </SMediumText>
                </GridItem>
                <GridItem>
                  <SMediumText variant="uiText">
                    {/* <STextDataValue>{`${getItemFieldText(value)}: ${value.valueAfter}`}</STextDataValue> */}
                    {getItemAfter(info, value)}
                  </SMediumText>
                </GridItem>
              </Grid>
            );

          default:
            return <SMediumText variant="uiText" />;
        }

      case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_ADDED:
      case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_DELETED:
        return (
          <Grid paddingLeft="30px">
            <GridItem>
              <SMediumText variant="uiText">
                <STextDataValue2>
                  {info.changeType === HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_ADDED
                    ? "Added source"
                    : "Removed source"}
                </STextDataValue2>
                <STextDataValue2>
                  {info.appClientDestinationRouteType === AppDestinationRouteTypeEnum.GROUP
                    ? "group"
                    : "tenant"}
                </STextDataValue2>
                {info.appClientDestinationRouteType === AppDestinationRouteTypeEnum.GROUP &&
                info.aircraftGroup ? (
                  <STextDataValue2>
                    <SActiveLink>
                      <Link to={`/aircraftGroups/${info.aircraftGroup.aircraftGroupId}`}>
                        {`${info.aircraftGroup.aircraftGroupName}`}
                      </Link>
                    </SActiveLink>
                  </STextDataValue2>
                ) : info.appClientDestinationRouteType === AppDestinationRouteTypeEnum.TENANT &&
                  info.tenant ? (
                  <STextDataValue2>
                    <SActiveLink>
                      <Link to={`/airlines/${info.tenant.tenantId}`}>
                        {`All aircrafts (${info.tenant.icao.toUpperCase()})`}
                      </Link>
                    </SActiveLink>
                  </STextDataValue2>
                ) : (
                  <STextDataValue />
                )}
                <STextDataValue2>{"for app"}</STextDataValue2>
                {info.appClient && (
                  <STextDataValue2>
                    <SActiveLink>
                      <Link to={`/applicationClients/${info.appClient.appClientId}`}>
                        {`${info.appClient.appName} ${info.appClient.appVersion}`}
                      </Link>
                    </SActiveLink>
                  </STextDataValue2>
                )}
                <STextDataValue2>{"destination"}</STextDataValue2>
                {info.appClientDestination && info.appClient && (
                  <STextDataValue2>
                    <SActiveLink>
                      <Link
                        to={`/destination/${info.appClientDestination.appClientDestinationId}?appId=${info.appClient.appClientId}`}
                      >
                        {info.appClientDestination.destinationEnvironment ===
                        GroundEnvironmentTypeEnum.PRODUCTION
                          ? `Production`
                          : `Integration ${Number(info.appClientDestination.index) + 1}`}
                      </Link>
                    </SActiveLink>
                  </STextDataValue2>
                )}
              </SMediumText>
            </GridItem>
          </Grid>
        );

      case HistoryChangeTypeEnum.GROUND_CONFIG_VERSION_DEPLOYED:
        return (
          <Grid paddingLeft="30px">
            <GridItem>
              <SMediumText variant="uiText">
                <STextDataValue2>Deployed groundside configuration version</STextDataValue2>
                {info.groundConfig && (
                  <STextDataValue2>{`${info.groundConfig.version} for ${
                    info.groundConfig.isIntegration ? "integration" : "production"
                  }`}</STextDataValue2>
                )}
              </SMediumText>
            </GridItem>
          </Grid>
        );

      case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_CREATED:
        return (
          <Grid paddingLeft="30px" columns="1fr 1fr">
            <GridItem>
              <SMediumText variant="uiText">
                <STextDataValue2>Destination</STextDataValue2>
              </SMediumText>
            </GridItem>
            <GridItem>
              <SMediumText variant="uiText">
                {info.appClientDestination && (
                  <STextDataValue2>
                    {`${info.appClientDestination.destinationType} : ${info.appClientDestination.destinationEndpoint}`}
                  </STextDataValue2>
                )}
              </SMediumText>
            </GridItem>
          </Grid>
        );

      //
      // nothing to be displayed for now...
      //
      // case HistoryChangeTypeEnum.CONFIG_VERSION_CREATED:
      //   return (
      //     <>
      //     {info.configVersion && (
      //       <Grid columns={info.columns ? info.columns : "3fr 1fr"} paddingLeft="30px">
      //         <GridItem>
      //             <SMediumText variant="uiText">
      //               {info.configVersion.isRelease ? (
      //                 <STextDataValue2 font-weight="bold">Release</STextDataValue2>
      //               ) : (
      //                 <STextDataValue2>Test</STextDataValue2>
      //               )}
      //               <STextDataValue>{`loadable version: ${info.configVersion.version}`}</STextDataValue>
      //             </SMediumText>
      //         </GridItem>
      //         <GridItem>
      //             <SMediumText variant="uiText">
      //                 <STextDataValue>{`change_log_${info.configVersion.version}.txt`}</STextDataValue>
      //             </SMediumText>
      //         </GridItem>
      //       </Grid>
      //     )}
      //     </>
      //   );

      // // No need to display anything for topic status change
      // case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_REQUESTED:
      // case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_FINISHED:

      default:
        return <></>;
    }
  };
  /* tslint:enable:cyclomatic-complexity */

  return <>{props.items && props.items.length && getHistoryItemValue(props, props.items[0])}</>;
};
