import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditModalActions {
  OPEN = "EDITMODAL_OPEN",
  CLOSE = "EDITMODAL_CLOSE",
}

export interface IEditModalOpenAction extends IReduxBaseAction<EditModalActions> {
  payload: any;
  type: EditModalActions.OPEN;
}

export interface IEditModalCloseAction extends IReduxBaseAction<EditModalActions> {
  type: EditModalActions.CLOSE;
}

export type TEditModalActions = IEditModalCloseAction | IEditModalOpenAction;
