/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum GetConfigApplicationType {
  REQUEST = "GET_CONFIG_APPLICATION_REQUEST",
  SUCCESS = "GET_CONFIG_APPLICATION_SUCCESS",
  ERROR = "GET_CONFIG_APPLICATION_ERROR",
}

export interface IConfigApplicationRequestAction
  extends IReduxBaseAction<GetConfigApplicationType> {
  type: GetConfigApplicationType.REQUEST;
}

export interface IConfigApplicationSuccessAction
  extends IReduxBaseAction<GetConfigApplicationType> {
  payload: any;
  type: GetConfigApplicationType.SUCCESS;
}

export interface IConfigApplicationErrorAction extends IReduxBaseAction<GetConfigApplicationType> {
  payload: string;
  type: GetConfigApplicationType.ERROR;
}

export type TGetConfigApplicationActions =
  | IConfigApplicationRequestAction
  | IConfigApplicationSuccessAction
  | IConfigApplicationErrorAction;
