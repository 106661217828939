import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getTopicList } from "../actions/topic/get-list/actions";
import { isUserAppOwner } from "../helper/util";
import { topicStatusEnum } from "../models/ITopicConfig";
import { RootState } from "../store";

import { Typeahead } from "./Typeahead";

export const TypeaheadTopics = (props: any) => {
  const { applicationTopicList, currentUser, topicList } = useSelector((state: RootState) => ({
    applicationTopicList: state.applicationTopicList,
    currentUser: state.currentUser,
    topicList: state.topicList,
  }));

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const handleCreateChipLabel = ({ value }: { value: any }) => {
    let label = "";

    if (value && value.topicName) {
      label = value.topicName;
    }

    return label;
  };

  const transformToNestedList = () => {
    const myTopicsContent: any[] = [];
    const otherTopicsContent: any[] = [];
    if (topicList.content) {
      topicList.content.forEach((topic: any) => {
        // if (currentUser.data.userId === topic.appClient.appOwner.userId) {
        if (isUserAppOwner(currentUser.data, topic.appClient.appOwners)) {
          myTopicsContent.push(topic);
        } else {
          otherTopicsContent.push(topic);
        }
      });
    }

    return [
      {
        content: myTopicsContent,
        header: "My topics",
      },
      {
        content: otherTopicsContent,
        header: "Other topics",
      },
    ];
  };

  return (
    <Typeahead
      pageSelector={props.pageSelector}
      autoFillOnExactMatchOnly={{
        key: "topicName",
        rule: props.autoFillOnExactMatchOnly,
      }}
      disabled={props.disabled}
      config={{
        default: {
          filter: {
            appClientId: undefined,
            publishableBy: !!props.publishableBy ? props.publishableBy : undefined,
            topicId: undefined,
            topicName: "",
            topicStatus: "",
          },
          orderBy: "topicName",
          statuses: [topicStatusEnum.ACTIVE, topicStatusEnum.ONHOLD, topicStatusEnum.DRAFT],
        },
        setFilter: (topicName: string) => ({
          topicName,
        }),
      }}
      suggestion={{
        getList: (config: any) => dispatch(getTopicList(config)),
        isItemDisabled: (topic: any) =>
          props.isSuggestedItemDisabled ? props.isSuggestedItemDisabled(topic) : false,
        list: transformToNestedList(),
        select: (topic: any) => `${topic.topicName}`,
      }}
      name="topic"
      chipLabel={handleCreateChipLabel}
      value={props.value}
      hasSelected={(p: any) => p && p.topicId === undefined}
      valueToSetOnLoad="topicName"
      handleTypeahead={(topic) => {
        props.onChange({
          appClientId:
            topic.appClient && topic.appClient.appClientId
              ? topic.appClient.appClientId
              : topic.appClientId,
          retainRequired: topic.retainRequired,
          topicId: topic.topicId,
          topicLocalGroupBridge: topic.localGroupBridge,
          topicName: topic.topicName,
          topicStatus: topic.status,
        });
      }}
      handleCreate={(topic) => {
        props.onChange({
          appClientId: undefined,
          retainRequired: undefined,
          topicId: undefined,
          topicName: topic,
          topicStatus: undefined,
        });
      }}
      pattern="{topicName} - {status}"
    />
  );
};
