import deepEqual from "deep-equal";
import moment from "moment-timezone";
import {
  DataCell,
  Grid,
  GridItem,
  HeaderCell,
  IconDelete16,
  Link as NextLink,
  //   Row,
  TablePagination,
  Text,
  TextCell,
} from "next-components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BatchActionActive, Row } from "../components/next-component-fork";
import { getTAClass, TA_TYPES } from "../helper/taHelper";
import {
  HistoryChangeTypeEnum,
  HistoryDisplayModeEnum,
  HistoryItemFieldEnum,
  IChangeHistoryInfo,
  IChangeHistoryItem,
} from "../models/IChangeHistories";
import {
  DEFAULT_BATCH_WIDTH,
  DEFAULT_WIDTH,
  IBatchAction,
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../models/ITableConfig";
import {
  SActiveLink,
  SDeleteIconContainer,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextDataValue2,
} from "../styles/styles";

import { ChangeHistoryInfo } from "./ChangeHistoryInfo";
import { ChangeHistoryItem } from "./ChangeHistoryItem";
import { Checkbox, CheckboxOptions } from "./Checkbox";

export const HistoryTable = (props: ITableConfig) => {
  const [columnsRatio, setColumnsRatio] = useState("");
  const [selectedRows, setSelectedRows] = useState(Array<ITableRow>());
  const [batchLabel, setBatchLabel] = useState("");

  useEffect(() => {
    const ratio = props.head.cells.map(
      (headCell: ITableHeaderCells) => headCell.width || DEFAULT_WIDTH
    );
    if (props.batchAction) {
      ratio.splice(0, 0, props.batchAction.width || DEFAULT_BATCH_WIDTH);
    }
    setColumnsRatio(ratio.join(" "));
  }, [props.head]);

  useEffect(() => {
    setSelectedRows([]);
  }, [props.list]);

  const handleChangePage = (page: number): void => {
    props.paginationConfig.onPageChange(page);
  };

  const handleSort = (column: string): void => {
    switch (props.sortConfig.orderBy) {
      case column:
        props.sortConfig.onSort(
          column,
          props.sortConfig.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC
        );
        break;
      default:
        props.sortConfig.onSort(column, OrderType.ASC);
    }
  };

  const onCancel = () => {
    setSelectedRows([]);
  };

  const getRowHeight = (row: IChangeHistoryInfo) => {
    let ht = 40;
    if (row.changeType === HistoryChangeTypeEnum.CONFIG_VERSION_CREATED) {
      if (row.configVersion) {
        ht += 32;
        if (row.configVersion.comment && row.configVersion.comment.length > 0) {
          ht += 32;
        } else if (row.comment && row.comment.length > 0) {
          ht += 32;
        }
      }
    } else {
      if (row.comment && row.comment.length > 0) {
        ht += 32;
      }

      switch (row.changeType) {
        case HistoryChangeTypeEnum.APP_CLIENT_MODIFIED:
        case HistoryChangeTypeEnum.TOPIC_MODIFIED:
        case HistoryChangeTypeEnum.PUBLISHER_CREATED:
        case HistoryChangeTypeEnum.SUBSCRIBER_CREATED:
        case HistoryChangeTypeEnum.AIRCRAFT_MODIFIED:
        case HistoryChangeTypeEnum.AIRCRAFT_GROUP_MODIFIED:
          if (row.items && row.items.length > 0) {
            ht += row.items.length * 32;
          }
          break;

        default:
      }
    }

    return `${ht}px`;
  };

  /* tslint:disable:prefer-switch */
  const addHistoryItem = (info: IChangeHistoryInfo) => {
    if (
      info.changeType === HistoryChangeTypeEnum.PUBLISHER_DELETED ||
      info.changeType === HistoryChangeTypeEnum.SUBSCRIBER_DELETED ||
      info.changeType === HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_ADDED ||
      info.changeType === HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_DELETED ||
      info.changeType === HistoryChangeTypeEnum.GROUND_CONFIG_VERSION_DEPLOYED ||
      info.changeType === HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_CREATED
    ) {
      return true;
    }

    return false;
  };
  /* tslint:enable:prefer-switch */

  return (
    <>
      {!props.list.error && (
        <>
          {/* Top PAGINATION */}
          {props.topPagination && (
            <div className={`ta-${props.name}-pagination`}>
              <TablePagination
                itemCount={props.list.count || 0}
                onChangePage={handleChangePage}
                paging={{
                  count: props.paginationConfig.limit,
                  page: props.paginationConfig.page,
                }}
              />
            </div>
          )}
        </>
      )}

      {/* HEADER */}
      <Row
        columns={columnsRatio}
        paddingLeft="40px"
        className={`ta-${props.name}-row-header`}
        height="48px"
      >
        {props.head.cells.map((headCell: ITableHeaderCells) =>
          headCell.id !== "delete" ? (
            <HeaderCell
              key={headCell.label}
              value={headCell.label}
              className={`ta-${props.name}-header-${headCell.id}`}
            />
          ) : (
            ""
          )
        )}
      </Row>

      {/* ERROR */}
      {props.list.error && (
        <Row paddingLeft="10px" height="48px">
          <TextCell
            value="Error retrieving data."
            className={getTAClass(props.name, TA_TYPES.TABLE, "error")}
          />
        </Row>
      )}

      {props.list.loading && (
        <Row paddingLeft="10px" height="48px">
          <TextCell
            value="Loading..."
            className={getTAClass(props.name, TA_TYPES.TABLE, "loading")}
          />
        </Row>
      )}

      {!props.list.loading && !props.list.error && (
        <>
          {/* BODY */}
          {props.list.content &&
            props.list.content.map((row: IChangeHistoryInfo, index: number) => (
              <Row
                // columns={columnsRatio}
                // height={getRowHeight(row)}
                key={`${props.name}-${index}`}
                striped="none" // "odd"
                paddingLeft="10px"
                className={`ta-${props.name}-row`}
                onClick={() => {
                  if (props.list.onClickRow) {
                    props.list.onClickRow(row);
                  }
                }}
              >
                <GridItem>
                  <ChangeHistoryInfo
                    {...row}
                    columns={columnsRatio}
                    displayMode={props.displayMode}
                    isAdmin={props.isAdmin}
                    curUserId={props.curUserId}
                  />
                  {row.items &&
                    row.items.map((value: IChangeHistoryItem, idx: number) => (
                      <ChangeHistoryItem
                        {...row}
                        columns={columnsRatio}
                        displayMode={props.displayMode}
                        items={[value]}
                        isAdmin={props.isAdmin}
                        curUserId={props.curUserId}
                        index={idx}
                      />
                    ))}
                  {(!row.items || row.items.length === 0) && addHistoryItem({ ...row }) && (
                    <ChangeHistoryItem
                      {...row}
                      columns={columnsRatio}
                      displayMode={props.displayMode}
                      items={[{ itemField: HistoryItemFieldEnum.NONE }]}
                      isAdmin={props.isAdmin}
                      curUserId={props.curUserId}
                      index={0}
                    />
                  )}
                </GridItem>
              </Row>
            ))}
        </>
      )}

      {!props.list.error && (
        <>
          {/* Bottom PAGINATION / total count */}
          {props.bottomPagination ? (
            <div className={`ta-${props.name}-pagination`}>
              <TablePagination
                itemCount={props.list.count || 0}
                onChangePage={handleChangePage}
                paging={{
                  count: props.paginationConfig.limit,
                  page: props.paginationConfig.page,
                }}
              />
            </div>
          ) : (
            <Grid className={`ta-${props.name}-total-items`} paddingLeft="10px">
              <SSpacer height="4px" />
              <Text variant="uiText">{`Total ${props.list.count} items`}</Text>
              <SSpacer height="20px" />
            </Grid>
          )}
        </>
      )}
    </>
  );
};
