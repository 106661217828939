import {
  IGetAppDestinationDetailsActionType,
  TGetAppDestinationDetails,
} from "../../actions/destination/get-details/types";

interface IAppDestinationDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: IAppDestinationDetailsState = {
  content: [],
  error: false,
  loading: true,
};

export const appDestinationDetails = (
  state: IAppDestinationDetailsState = initialState,
  action: IGetAppDestinationDetailsActionType
): IAppDestinationDetailsState => {
  switch (action.type) {
    case TGetAppDestinationDetails.APPLY:
      return {
        ...state,
        content: action.payload,
        error: false,
        loading: false,
      };
    case TGetAppDestinationDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetAppDestinationDetails.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetAppDestinationDetails.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
