import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TSearchTenant {
  ERROR = "SEARCH_TENANT_ERROR",
  REQUEST = "SEARCH_TENANT_REQUEST",
  SUCCESS = "SEARCH_TENANT_SUCCESS",
}

export interface ISearchTenantRequestAction extends IReduxBaseAction<TSearchTenant> {
  type: TSearchTenant.REQUEST;
}

export interface ISearchTenantSuccessAction extends IReduxBaseAction<TSearchTenant> {
  payload: any;
  type: TSearchTenant.SUCCESS;
}

export interface ISearchTenantErrorAction extends IReduxBaseAction<TSearchTenant> {
  payload: any;
  type: TSearchTenant.ERROR;
}

export type ISearchTenantActionType =
  | ISearchTenantRequestAction
  | ISearchTenantSuccessAction
  | ISearchTenantErrorAction;
