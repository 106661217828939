/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ConfigAppFilterSortType {
  CLEAR = "CONFIG_APP_FILTER_SORT_CLEAR",
  RESET = "CONFIG_APP_FILTER_SORT_RESET",
  APPLY = "CONFIG_APP_FILTER_SORT_APPLY",
}

export interface IConfigAppFilterSortClearAction extends IReduxBaseAction<ConfigAppFilterSortType> {
  type: ConfigAppFilterSortType.CLEAR;
}

export interface IConfigAppFilterSortResetAction extends IReduxBaseAction<ConfigAppFilterSortType> {
  type: ConfigAppFilterSortType.RESET;
}

export interface IConfigAppFilterSortApplyAction extends IReduxBaseAction<ConfigAppFilterSortType> {
  payload: IFilterSortData;
  type: ConfigAppFilterSortType.APPLY;
}

export type TConfigAppFilterSortActions =
  | IConfigAppFilterSortClearAction
  | IConfigAppFilterSortResetAction
  | IConfigAppFilterSortApplyAction;
