import { IDestinationDetailsData } from "../../../models/IDestination";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetAppDestinationDetails {
  APPLY = "GET_APP_DESTINATION_DETAILS_APPLY",
  ERROR = "GET_APP_DESTINATION_DETAILS_ERROR",
  REQUEST = "GET_APP_DESTINATION_DETAILS_REQUEST",
  SUCCESS = "GET_APP_DESTINATION_DETAILS_SUCCESS",
}

export interface IGetAppDestinationDetailsApplyAction
  extends IReduxBaseAction<TGetAppDestinationDetails> {
  payload: IDestinationDetailsData;
  type: TGetAppDestinationDetails.APPLY;
}

export interface IGetAppDestinationDetailsRequestAction
  extends IReduxBaseAction<TGetAppDestinationDetails> {
  type: TGetAppDestinationDetails.REQUEST;
}

export interface IGetAppDestinationDetailsSuccessAction
  extends IReduxBaseAction<TGetAppDestinationDetails> {
  payload: any;
  type: TGetAppDestinationDetails.SUCCESS;
}

export interface IGetAppDestinationDetailsErrorAction
  extends IReduxBaseAction<TGetAppDestinationDetails> {
  payload: any;
  type: TGetAppDestinationDetails.ERROR;
}

export type IGetAppDestinationDetailsActionType =
  | IGetAppDestinationDetailsApplyAction
  | IGetAppDestinationDetailsRequestAction
  | IGetAppDestinationDetailsSuccessAction
  | IGetAppDestinationDetailsErrorAction;
