import { colors, Grid, GridItem, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAircraftGroupSelectList } from "../../actions/aircraftgroup/get-select-list/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SModalStackItem,
  SNextFixedHeightContainer,
  SShortText,
  SSpacer,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../styles/styles";
import {
  ClientPasswordValidationErrorCodeEnum as ClientPasswordError,
  ClientPasswordValidator,
} from "../ClientPasswordValidator";
import { ClientUsernameValidator } from "../ClientUsernameValidator";
import { StatusIcon } from "../StatusIcon";
import { Table } from "../Table";
import { Typeahead } from "../Typeahead";

import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";

export const ModalSelectAircraftGroupsPage1 = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { aircraftGroupSelectList } = useSelector((state: any) => ({
    aircraftGroupSelectList: state.aircraftGroupSelectList,
  }));

  const maxGroupsAllowed = 5; // select up to 5 aircraft groups
  const [updatedConfig, setUpdatedConfig] = useState();

  useEffect(() => {
    dispatch(
      getAircraftGroupSelectList({
        filter: {
          aircraftGroupEnvironment: props.groupEnv,
          globalOrTenantId: props.tenantId,
          // tenantId: props.tenantId,
        },
        limit: 100,
        orderBy: "",
        orderType: OrderType.ASC,
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  const handleTypeaheadInput = (selGroup: any) => {
    if (!!selGroup.aircraftGroupId) {
      let isSelected = false;
      if (props.config.groups.length > 0) {
        isSelected = props.config.groups.some(
          (group: any) => group.aircraftGroupId === selGroup.aircraftGroupId
        );
      }

      if (!isSelected) {
        const myGroups = props.config.groups;
        myGroups.push(selGroup);
        setUpdatedConfig({
          ...props.config,
          groups: myGroups,
        });
      }
    }
  };

  const isRowDisabled = (row: ITableRow) => false;
  // (props.userData.role !== ADMINISTRATOR && row.userId === props.userData.userId) ||
  // props.config.appOwners.length <= 1;

  const headCells: ITableHeaderCells[] = [
    {
      id: "groupName",
      label: "Group name",
      value: (row: ITableRow) => `${row.aircraftGroupName}`,
      width: "3fr",
    },
    { id: "delete", label: "Delete" },
  ];

  const tableProps: any = {
    componentName: "selected-aircraft-groups",
    deleteCell: {
      as: "a",
      disabled: isRowDisabled,
      onClick: (row: ITableRow) => {
        if (!isRowDisabled(row)) {
          const myGroups = props.config.groups;
          const idx = myGroups.findIndex(
            (group: any) => group.aircraftGroupId === row.aircraftGroupId
          );
          myGroups.splice(idx, 1);
          setUpdatedConfig({
            ...props.config,
            appOwners: myGroups,
          });
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: {
      //   content: props.config.appOwners,
      //   count: props.config.appOwners.length,
      content: props.config.groups,
      count: props.config.groups.length,
    },
    name: "selected-aircraft-groups",
    noHeader: true,
    noPagination: true,
    rowStriped: "none",
  };

  return (
    <>
      <SShortText
        variant="uiText"
        className={getTAClass("aircraft-group", TA_TYPES.TEXT, "tail-numbers")}
      >
        <STextDataTitle>{"Tail numbers"}:</STextDataTitle>
        <STextDataValue>{props.tailNumbers}</STextDataValue>
      </SShortText>

      <SSpacer />

      <Grid columns="1fr 1fr">
        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-select-aircraft-groups">
            {`Select aircraft groups (up to ${maxGroupsAllowed} groups)`}
          </Text>

          <SSpacer height="8px" />

          <div className="ta-modal-input-select-aircraft-groups">
            <Typeahead
              pageSelector="modal-select-aircraft-groups"
              disabled={props.config.groups.length >= maxGroupsAllowed}
              config={{
                default: {
                  filter: {
                    // tenantId: props.tenantId,
                    globalOrTenantId: props.tenantId,
                  },
                  orderBy: "",
                },
                setFilter: (value: string | any) =>
                  typeof value === "string"
                    ? { aircraftGroupName: value }
                    : { aircraftGroupName: value.aircraftGroupName },
              }}
              suggestion={{
                getList: (config: any) => dispatch(getAircraftGroupSelectList(config)),
                list: aircraftGroupSelectList,
                select: (group: any) => `${group.aircraftGroupName}`,
              }}
              placeholder="Group name"
              name="aircraftGroupName"
              value={""}
              hasSelected={(p: any) => true}
              handleTypeahead={handleTypeaheadInput}
              pattern=""
              getListLabel={(row: any) =>
                `${row.aircraftGroupName}${
                  row.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL ? " (Global)" : ""
                }`
              }
              minInputLen={0}
              showListOnFocus={true}
              clearOnSelect={true}
              keepInputOnSelect={true}
            />
          </div>

          <SSpacer height="200px" />
        </GridItem>

        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-app-owner">
            {`Selected aircraft groups (total: ${props.config.groups.length})`}
          </Text>

          <SSpacer height="8px" />

          <SSpacer height="2px" color={colors.greys.lighten25} />
          <SNextFixedHeightContainer maxHeight="calc(5 * 48px)" overflow="auto">
            <Table {...tableProps} />
          </SNextFixedHeightContainer>
        </GridItem>
      </Grid>
    </>
  );
};
