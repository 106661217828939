import { ModalConfirmation, ModalInfo } from "next-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { popupCloseAction } from "../actions/popup/actions";
import { RootState } from "../store";

export const Popup = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { popupData } = useSelector((state: RootState) => ({
    popupData: state.popupData,
  }));

  const closePopup = () => {
    dispatch(popupCloseAction());
  };

  const getTextOptions = () => {
    const options: any = {};
    if (popupData.isContentHtml) {
      options.dangerouslySetInnerHTML = { __html: popupData.content };
    } else {
      options.children = [popupData.content];
    }

    return options;
  };

  return (
    <>
      {popupData.open && (
        <div className="ta-modal-info">
          {popupData.type === "Confirmation" ? (
            <ModalConfirmation
              title={<span className="ta-modal-confirmation-title">{popupData.title}</span>}
              text={<span className="ta-modal-confirmation-content" {...getTextOptions()}></span>}
              onCancel={closePopup}
              onConfirm={popupData.onConfirm}
            />
          ) : (
            <ModalInfo
              title={<span className="ta-modal-info-title">{popupData.title}</span>}
              text={<span className="ta-modal-info-content" {...getTextOptions()}></span>}
              onClose={closePopup}
            />
          )}
        </div>
      )}
    </>
  );
};
