import { Dispatch } from "redux";

import { IDestinationListData, IDestinationListTableConfig } from "../../../models/IDestination";
import { ApplicationService } from "../../../services/ApplicationService";

import { DestinationListType, TDestinationListActions } from "./types";

export const GetDestinationListResetAction = (): TDestinationListActions => ({
  type: DestinationListType.RESET,
});

export const GetDestinationListRequestAction = (): TDestinationListActions => ({
  type: DestinationListType.REQUEST,
});

export const GetDestinationListSuccessAction = (data: any): TDestinationListActions => ({
  payload: data,
  type: DestinationListType.SUCCESS,
});

export const GetDestinationListErrorAction = (error: string): TDestinationListActions => ({
  payload: error,
  type: DestinationListType.ERROR,
});

export const getDestinationList = (appId: string, conf: IDestinationListTableConfig) => (
  dispatch: Dispatch<TDestinationListActions>
) => {
  dispatch(GetDestinationListRequestAction());
  ApplicationService.getAppDestinations(appId, conf).then(
    (response: { data: { data: IDestinationListData } }) => {
      dispatch(GetDestinationListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetDestinationListErrorAction(error))
  );
};
