import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetChangeLogTypes {
  ERROR = "GET_CHANGE_LOG_ERROR",
  REQUEST = "GET_CHANGE_LOG_REQUEST",
  SUCCESS = "GET_CHANGE_LOG_SUCCESS",
}

export interface IGetChangeLogRequestAction extends IReduxBaseAction<GetChangeLogTypes> {
  payload: any;
  type: GetChangeLogTypes.REQUEST;
}

export interface IGetChangeLogSuccessAction extends IReduxBaseAction<GetChangeLogTypes> {
  payload: any;
  type: GetChangeLogTypes.SUCCESS;
}

export interface IGetChangeLogErrorAction extends IReduxBaseAction<GetChangeLogTypes> {
  payload: any;
  type: GetChangeLogTypes.ERROR;
}

export type TGetChangeLogActions =
  | IGetChangeLogErrorAction
  | IGetChangeLogRequestAction
  | IGetChangeLogSuccessAction;
