import {
  GetConfigBrokerType,
  TGetConfigBrokerActions,
} from "../../actions/configfile/broker/types";

interface IConfigFileBrokerState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigFileBrokerState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const configFileBroker = (
  state: IConfigFileBrokerState = initialState,
  action: TGetConfigBrokerActions
): IConfigFileBrokerState => {
  switch (action.type) {
    case GetConfigBrokerType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetConfigBrokerType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetConfigBrokerType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
