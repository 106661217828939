/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ConfigBrokerFilterSortType {
  CLEAR = "CONFIG_BROKER_FILTER_SORT_CLEAR",
  RESET = "CONFIG_BROKER_FILTER_SORT_RESET",
  APPLY = "CONFIG_BROKER_FILTER_SORT_APPLY",
}

export interface IConfigBrokerFilterSortClearAction
  extends IReduxBaseAction<ConfigBrokerFilterSortType> {
  type: ConfigBrokerFilterSortType.CLEAR;
}

export interface IConfigBrokerFilterSortResetAction
  extends IReduxBaseAction<ConfigBrokerFilterSortType> {
  type: ConfigBrokerFilterSortType.RESET;
}

export interface IConfigBrokerFilterSortApplyAction
  extends IReduxBaseAction<ConfigBrokerFilterSortType> {
  payload: IFilterSortData;
  type: ConfigBrokerFilterSortType.APPLY;
}

export type TConfigBrokerFilterSortActions =
  | IConfigBrokerFilterSortClearAction
  | IConfigBrokerFilterSortResetAction
  | IConfigBrokerFilterSortApplyAction;
