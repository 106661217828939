import { Dispatch } from "redux";

import { IGetGroundConfigListData } from "../../../models/IGroundsideConfiguration";
import { GroundConfigService } from "../../../services/GroundConfigService";

import { GroundConfigListType, TGroundConfigListActions } from "./types";

export const GetGroundConfigListResetAction = (): TGroundConfigListActions => ({
  type: GroundConfigListType.RESET,
});

export const GetGroundConfigListRequestAction = (): TGroundConfigListActions => ({
  type: GroundConfigListType.REQUEST,
});

export const GetGroundConfigListSuccessAction = (data: any): TGroundConfigListActions => ({
  payload: data,
  type: GroundConfigListType.SUCCESS,
});

export const GetGroundConfigListErrorAction = (error: string): TGroundConfigListActions => ({
  payload: error,
  type: GroundConfigListType.ERROR,
});

export const getGroundConfigList = (conf: any) => (
  dispatch: Dispatch<TGroundConfigListActions>
) => {
  dispatch(GetGroundConfigListRequestAction());
  GroundConfigService.getGroundConfigList(conf).then(
    (response: { data: { data: IGetGroundConfigListData } }) => {
      dispatch(GetGroundConfigListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetGroundConfigListErrorAction(error))
  );
};
