import { TUploadPayloadActions, UploadPayloadTypes } from "../../actions/payload/upload/types";

interface IUploadPayloadState {
  content: any;
  error: boolean;
  loading: boolean;
}

const initialState: IUploadPayloadState = {
  content: [],
  error: false,
  loading: false,
};

export const payloadUpload = (
  state: IUploadPayloadState = initialState,
  action: TUploadPayloadActions
): IUploadPayloadState => {
  switch (action.type) {
    case UploadPayloadTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UploadPayloadTypes.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case UploadPayloadTypes.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
