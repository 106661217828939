import React, { useEffect, useState } from "react";

import { useDebounce } from "../hooks/useDebounce";
import useOutsideClickDetector from "../hooks/useOutsideClickDetector";
import { ITypeahead } from "../models/ITypeahead";
import {
  STypeaheadChip,
  STypeaheadContainer,
  STypeaheadInput,
  STypeaheadListContainer,
  STypeaheadListItem,
  STypeaheadNestedHeader,
} from "../styles/styles";

export const Typeahead = (props: ITypeahead) => {
  const [dirty, setDirty] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [showListOnFocus, setShowListOnFocus] = useState(false);
  const [clearOnSelect, setClearOnSelect] = useState(false);
  const [keepInputOnSelect, setKeepInputOnSelect] = useState(false);
  const [minInputLen, setMinInputLen] = useState(2);
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // Create config from provided config object
  const [config, setConfig] = useState({
    ...props.config.default,
    limit: 100,
    orderType: "ASC",
    page: 0,
  });

  const debounceFilter = useDebounce(config, 150);
  useEffect(() => {
    if (dirty) {
      props.suggestion.getList(config);
    }
  }, [debounceFilter]);

  useEffect(() => {
    if (props.minInputLen !== undefined && props.minInputLen >= 0) {
      setMinInputLen(props.minInputLen);
      if (props.minInputLen === 0) {
        props.suggestion.getList(config);
      }
    }

    if (props.showListOnFocus !== undefined) {
      setShowListOnFocus(props.showListOnFocus);
    }

    if (props.clearOnSelect !== undefined) {
      setClearOnSelect(props.clearOnSelect);
    }

    if (props.keepInputOnSelect !== undefined) {
      setKeepInputOnSelect(props.keepInputOnSelect);
    }
  }, []);

  useEffect(() => {
    if (props.value) {
      if (typeof props.value === "string") {
        setInputValue(props.value);
      } else {
        if (!!props.valueToSetOnLoad) {
          setInputValue((props.value as any)[props.valueToSetOnLoad]);
        }
      }

      // don't set inputValue in any other cases!!!
    }

    // if (props.value && typeof props.value !== "string" && !props.valueToSetOnLoad) {
    //   throw new Error("Please set 'valueToSetOnLoad' property if 'value' is an object.");
    // }

    // setInputValue(
    //   props.valueToSetOnLoad
    //     ? props.value
    //       ? (props.value as any)[props.valueToSetOnLoad]
    //       : props.value
    //     : props.value
    // );
  }, [props.value]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDirty(true);
    setInputValue(e.target.value);

    // On clear button
    if (e.target.value === "") {
      props.config.setFilter(props.config.default.filter);
      setInputValue("");
    }

    if (e.target.value.length >= minInputLen) {
      setConfig({
        ...config,
        filter: {
          ...config.filter,
          ...props.config.setFilter(e.target.value),
        },
      });
      setSuggestionOpen(true);
    }

    if (e.target.value.length < minInputLen) {
      // setSuggestionOpen(false);
      setSuggestionOpen(hasFocus && showListOnFocus && minInputLen <= 0);
    }

    if (props.handleCreate) {
      props.handleCreate(e.target.value);
    }
  };

  const handleSelectSuggestion = (value: any, disabled?: boolean): void => {
    if (disabled) {
      return;
    }

    setDirty(true);

    if (!keepInputOnSelect) {
      setInputValue(props.suggestion.select(value));

      setConfig({
        ...config,
        filter: {
          ...config.filter,
          ...props.config.setFilter(value),
        },
      });
    }

    // setSuggestionOpen(false);
    setSuggestionOpen(hasFocus && showListOnFocus);
    props.handleTypeahead(value);

    if (clearOnSelect) {
      setDirty(true);
      // props.handleTypeahead(props.config.default.filter);
      setInputValue("");
      setSuggestionOpen(false);
    }
  };

  const autoFill = () => {
    setSuggestionOpen(false);

    let numberOfNestedListItem = 0;
    let firstNestedListItem: any = null;
    if (Array.isArray(props.suggestion.list)) {
      props.suggestion.list.forEach((item: any) => {
        numberOfNestedListItem += item.content.length;
        if (item.content.length && !firstNestedListItem) {
          firstNestedListItem = item.content[0];
        }
      });
    }

    if (
      props.autoFillOnExactMatchOnly &&
      inputValue !== "" &&
      ((!Array.isArray(props.suggestion.list) && props.suggestion.list.content.length === 1) ||
        numberOfNestedListItem === 1)
    ) {
      const suggestedItem = Array.isArray(props.suggestion.list)
        ? firstNestedListItem
        : props.suggestion.list.content[0];
      if (props.autoFillOnExactMatchOnly.rule) {
        if (suggestedItem[props.autoFillOnExactMatchOnly.key] === inputValue) {
          handleSelectSuggestion(
            suggestedItem,
            props.suggestion.isItemDisabled ? props.suggestion.isItemDisabled(suggestedItem) : false
          );
        }
      } else {
        handleSelectSuggestion(
          suggestedItem,
          props.suggestion.isItemDisabled ? props.suggestion.isItemDisabled(suggestedItem) : false
        );
      }
    }
  };

  const handleClear = () => {
    setDirty(true);
    props.handleTypeahead(props.config.default.filter);
    setInputValue("");
    setSuggestionOpen(false);

    // clear list fitler if no typing required
    if (minInputLen <= 0) {
      setConfig({
        ...config,
        filter: {
          ...config.filter,
          ...props.config.setFilter(""),
        },
      });
    }
  };

  const handleBackspace = () => {
    setDirty(true);
    // Backspace
    const tmpSubStringLength = inputValue && inputValue.length ? inputValue.length - 1 : 0;
    const tmpSubString = inputValue ? inputValue.substr(0, tmpSubStringLength) : "";
    if (tmpSubStringLength < minInputLen) {
      // setSuggestionOpen(false);
      setSuggestionOpen(hasFocus && showListOnFocus);
    } else {
      props.handleTypeahead(tmpSubString);
      setInputValue(tmpSubString);
      setConfig({
        ...config,
        filter: {
          ...config.filter,
          ...props.config.setFilter(tmpSubString),
        },
      });
      setSuggestionOpen(true);
    }

    if (props.handleCreate) {
      props.handleCreate(tmpSubString);
    }
  };

  const handleKeyDown = (e: any) => {
    if (!showListOnFocus) {
      // work around to fix backspace deleting 2 chars issue
      if (e.keyCode === 8) {
        handleBackspace();
      }
    }

    if (e.keyCode === 9 || e.keyCode === 13) {
      autoFill();
    }
  };

  const handleFocus = () => {
    setHasFocus(true);
    setSuggestionOpen(inputValue.length >= minInputLen || (showListOnFocus && minInputLen <= 0));
  };

  const handleBlur = () => {
    setHasFocus(false);
    autoFill();
  };

  // const outsideClickRef = useOutsideClickDetector(autoFill);
  const outsideClickRef = useOutsideClickDetector(handleBlur);

  const getLabel = (data: any) => {
    const keys = props.pattern.match(/[^{\}]+(?=})/g); // Keys to replace is {elem}
    let label = props.pattern;
    if (keys) {
      keys.forEach((key: string) => {
        const re = new RegExp(`{${key}}`, "gi");
        label = label.replace(re, data[key]);
      });
    }

    return label;
  };

  return (
    // tslint:disable-next-line: ban-ts-ignore
    // @ts-ignore: Unreachable code error
    <STypeaheadContainer ref={outsideClickRef}>
      {props.value === "" ||
      props.value === " " ||
      (props.hasSelected && props.hasSelected(props.value)) ? (
        <STypeaheadInput
          className={`ta-${props.pageSelector}-typeahead-${props.name}`}
          placeholder={
            props.placeholder && props.placeholder !== ""
              ? props.placeholder
              : props.name.charAt(0).toUpperCase() + props.name.slice(1)
          }
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          // onBlur={handleBlur}
          disabled={props.disabled || false}
        />
      ) : (
        <STypeaheadChip
          className={`ta-${props.pageSelector}-typeahead-${props.name}-chip`}
          tabIndex="0"
          label={props.chipLabel ? props.chipLabel(props) : props.value}
          disabled={props.disabled || false}
          size="default"
          onClick={!props.disabled && handleClear}
        />
      )}
      {suggestionOpen && (
        <STypeaheadListContainer
          className={`ta-${props.pageSelector}-typeahead-${props.name}-list`}
        >
          {Array.isArray(props.suggestion.list) ? (
            <>
              {props.suggestion.list.map((currentNestedList: any, nestedIndex: number) => (
                <React.Fragment key={nestedIndex}>
                  <STypeaheadNestedHeader
                    className={`ta-${props.pageSelector}-typeahead-${props.name}-header-${nestedIndex}`}
                  >
                    {`${currentNestedList.header} (${currentNestedList.content.length})`}
                  </STypeaheadNestedHeader>
                  {currentNestedList.content.map((data: any, index: number) => (
                    <STypeaheadListItem
                      className={`ta-${props.pageSelector}-typeahead-${props.name}-list-elem-${nestedIndex}-${index}`}
                      key={index}
                      onClick={() => {
                        handleSelectSuggestion(
                          data,
                          props.suggestion.isItemDisabled
                            ? props.suggestion.isItemDisabled(data)
                            : false
                        );
                      }}
                      disabled={
                        props.suggestion.isItemDisabled
                          ? props.suggestion.isItemDisabled(data)
                          : false
                      }
                    >
                      {props.getListLabel ? props.getListLabel(data) : getLabel(data)}
                    </STypeaheadListItem>
                  ))}
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              {props.suggestion.list.content.map((data: any, index: number) => (
                <STypeaheadListItem
                  className={`ta-${props.pageSelector}-typeahead-${props.name}-list-elem-${index}`}
                  key={index}
                  onClick={() => {
                    handleSelectSuggestion(
                      data,
                      props.suggestion.isItemDisabled
                        ? props.suggestion.isItemDisabled(data)
                        : false
                    );
                  }}
                  disabled={
                    props.suggestion.isItemDisabled ? props.suggestion.isItemDisabled(data) : false
                  }
                >
                  {props.getListLabel ? props.getListLabel(data) : getLabel(data)}
                </STypeaheadListItem>
              ))}
              {props.suggestion.list.content.length === 0 && (
                <STypeaheadListItem className={`ta-typeahead-${props.name}-list-elem-0`}>
                  No match.
                </STypeaheadListItem>
              )}
            </>
          )}
        </STypeaheadListContainer>
      )}
    </STypeaheadContainer>
  );
};
