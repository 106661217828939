import {
  AppTopicFullListType,
  TAppTopicFullListActions,
} from "../../actions/application/get-topics-full/types";
import { IGetTopicListContent } from "../../models/ITopicConfig";

interface ITopicFullListState {
  content: IGetTopicListContent[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicFullListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const applicationTopicFullList = (
  state: ITopicFullListState = initialState,
  action: TAppTopicFullListActions
): ITopicFullListState => {
  switch (action.type) {
    case AppTopicFullListType.CLEAR:
      return initialState;
    case AppTopicFullListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case AppTopicFullListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case AppTopicFullListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
