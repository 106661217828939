import { queryFilterHelper } from "../helper/queryFilterHelper";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: any) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const createSubscriber = async (config: any) => {
  const postData = {
    appClientId: config.appClientId,
    bridge: config.bridge,
    brokerTypeId: config.brokerType.brokerTypeId,
    changeComment: config.changeComment,
    clientType: "Subscriber",
    // localGroupData: config.localGroupData,
    topicId: config.topic.topicId,
  };

  const axios = await axiosInstance();

  return axios.post(`/mqtt/`, postData);
};

const createPublisher = async (config: any) => {
  const postData = {
    appClientId: config.appClientId,
    brokerTypeId: config.brokerType.brokerTypeId,
    changeComment: config.changeComment,
    clientType: "Publisher",
    localGroupData: config.localGroupData,
    retainRequired: config.retainRequired,
    topicId: config.topic.topicId,
  };

  const axios = await axiosInstance();

  return axios.post(`/mqtt/`, postData);
};

const getMqttClients = async (conf: any) => {
  const filter = queryFilterHelper(conf);

  const axios = await axiosInstance();

  return axios.get(`/mqtt/?${filter}`);
};

const deleteAssociation = async (topicMqttClientId: string, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/mqtt/${topicMqttClientId}`, {
    params: { changeComment },
  });
};

const deleteAssociationBulk = async (data: { mqttIds: string[] }, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.put(`/mqttbulkdelete`, data, { params: { changeComment } });
};

const getMqttClientDetails = async (mqttId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/mqtt/${mqttId}`);
};

// const getMqttClientRoutes = async (mqttId: string, conf: any) => {
//   const query = getQueryString(conf);
//   const axios = await axiosInstance();

//   return axios.get(`/mqtt/${mqttId}/routes${query}`);
// };

// const addMqttClientRoute = async (mqttId: string, config: any) => {
//   const postData = {
//     ...config,
//     topicMqttClientId: mqttId,
//   };

//   const axios = await axiosInstance();

//   return axios.post(`/mqtt/${mqttId}/routes`, postData);
// };

// const removeMqttClientRoute = async (mqttId: string, routeId: string, changeComment: string) => {
//   const axios = await axiosInstance();

//   return axios.delete(`/mqtt/${mqttId}/routes/${routeId}`, {
//     params: { changeComment },
//   });
// };

export const MQTTService = {
  // addMqttClientRoute,
  createPublisher,
  createSubscriber,
  deleteAssociation,
  deleteAssociationBulk,
  getMqttClientDetails,
  // getMqttClientRoutes,
  getMqttClients,
  // removeMqttClientRoute,
};
