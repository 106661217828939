import React, { useState } from "react";

import { SFileInputLabel, SHiddenInput } from "../styles/styles";

export const FileInput = (props: any) => {
  const [selectedFile, setSelectedFile] = useState("");
  const handleOnChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0].name);
      props.onChange(e.target.files[0]);
    }
  };

  return (
    <>
      <SHiddenInput
        type="file"
        name="file"
        id="file"
        onChange={handleOnChange}
        disabled={props.disabled}
      />
      <SFileInputLabel htmlFor="file">Upload file</SFileInputLabel>
    </>
  );
};
