// tslint:disable:no-import-side-effect
import {
  Badge,
  BadgeVariant,
  IconAppSwitcher32,
  IconAvatar24,
  IconClose32,
  IconNotification24,
  IconSettings24,
} from "next-components";
import React from "react";
import "simplebar";
import "simplebar/dist/simplebar.css";

import { ISideBar } from "./ICloudBar";
import {
  CloseButton,
  Container,
  SideBarItems,
  SideBarUserItem,
  SideBarUserItemNode,
  ToolsContainer,
  ToolsHeading,
} from "./SideBar.style";
import SideBarCloudHomeItem from "./SideBarCloudHomeItem";
import SideBarItem from "./SideBarItem";

const SideBarComp = (props: ISideBar, ref?: any) => {
  const { onShowAllToggle, cloudHomeUrl } = props;

  return (
    <Container data-simplebar {...props} ref={ref}>
      <SideBarCloudHomeItem href={cloudHomeUrl} isLarge={props.isLarge} />

      <ToolsContainer>
        {props.lastUsedTools && props.lastUsedTools.length > 0 && (
          <SideBarItems>
            <ToolsHeading isVisible={props.isLarge}>Last used tools</ToolsHeading>
            {props.lastUsedTools.map((tool, index) => (
              <SideBarItem
                key={index}
                href={tool.path}
                icon={<img src={tool.icon} alt={tool.name} />}
                label={tool.name}
                showLabel={props.isLarge}
                selected={tool.selected}
              />
            ))}
            {!props.isLarge && (
              <SideBarItem as="div" onClick={onShowAllToggle} icon={<IconAppSwitcher32 />} />
            )}
          </SideBarItems>
        )}

        {props.otherTools && props.otherTools.length > 0 && (
          <SideBarItems>
            <ToolsHeading isVisible={props.isLarge}>Other tools A-Z</ToolsHeading>
            {props.otherTools.map((tool, index) => (
              <SideBarItem
                key={index}
                href={tool.path}
                icon={<img src={tool.icon} alt={tool.name} />}
                label={tool.name}
                showLabel={props.isLarge}
                selected={tool.selected}
              />
            ))}
          </SideBarItems>
        )}
      </ToolsContainer>

      {!props.isLarge && (
        <SideBarItems>
          {typeof props.user.profileLink === "string" ? (
            <SideBarUserItem
              href={props.user.profileLink}
              icon={<IconAvatar24 />}
              label={props.user.name}
              showLabel={props.isLarge}
              target="_self"
            />
          ) : (
            <props.user.profileLink>
              <SideBarUserItemNode
                icon={<IconAvatar24 />}
                label={props.user.name}
                showLabel={props.isLarge}
                target="_self"
              />
            </props.user.profileLink>
          )}

          {typeof props.user.notificationsLink === "string" ? (
            <SideBarUserItem
              href={props.user.notificationsLink}
              icon={<IconNotification24 />}
              label="Notifications"
              showLabel={props.isLarge}
              badge={
                props.notificationCount > 0 && (
                  <Badge variant={BadgeVariant.notification}>{props.notificationCount}</Badge>
                )
              }
              target="_self"
            />
          ) : (
            <props.user.notificationsLink>
              <SideBarUserItemNode
                icon={<IconNotification24 />}
                label="Notifications"
                showLabel={props.isLarge}
                badge={
                  props.notificationCount > 0 && (
                    <Badge variant={BadgeVariant.notification}>{props.notificationCount}</Badge>
                  )
                }
                target="_self"
              />
            </props.user.notificationsLink>
          )}

          {typeof props.user.settingsLink === "string" ? (
            <SideBarUserItem
              href={props.user && props.user.settingsLink}
              icon={<IconSettings24 />}
              label="Settings"
              showLabel={props.isLarge}
              target="_self"
            />
          ) : (
            <props.user.settingsLink>
              <SideBarUserItemNode
                icon={<IconSettings24 />}
                label="Settings"
                showLabel={props.isLarge}
                target="_self"
              />
            </props.user.settingsLink>
          )}
        </SideBarItems>
      )}

      {props.isLarge && (
        <CloseButton onClick={onShowAllToggle}>
          <IconClose32 />
        </CloseButton>
      )}
    </Container>
  );
};

const SideBar = React.forwardRef(SideBarComp);

export default SideBar;
