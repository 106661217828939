import {
  ConfigLoadableListType,
  TConfigLoadableListActions,
} from "../../actions/configfile/loadable/types";

interface IConfigLoadableListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigLoadableListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const configLoadableList = (
  state: IConfigLoadableListState = initialState,
  action: TConfigLoadableListActions
): IConfigLoadableListState => {
  switch (action.type) {
    case ConfigLoadableListType.RESET:
      return {
        ...state,
        ...initialState,
      };
    case ConfigLoadableListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ConfigLoadableListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case ConfigLoadableListType.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
