import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IChangeHistoryListingTableConfig } from "../models/IChangeHistoryConfig";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IChangeHistoryListingTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getChangeHistoryList = async (conf: IChangeHistoryListingTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/history${query}`);
};

const getChangeLogFile = async (isRelease: boolean = false) => {
  const query = `?isRel=${isRelease ? 1 : 0}`;

  const axios = await axiosInstance();

  return axios.get(`/changeLog${query}`);
};

const getGndChangeLogFile = async (isInt: boolean) => {
  const query = `?isInt=${isInt ? 1 : 0}`;

  const axios = await axiosInstance();

  return axios.get(`/gndChangeLog${query}`);
};

export const ChangeHistoryService = {
  getChangeHistoryList,
  getChangeLogFile,
  getGndChangeLogFile,
};
