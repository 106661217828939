import styled from "styled-components";

export const SHiddenInput = styled.input`
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
`;

export const SFileInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #ffffff;
  background-color: #2a7fc9;
  cursor: pointer;

  &:hover {
    background-color: #4c98da;
  }
  &:focus {
    background-color: #4c98da;
    box-shadow: inset 0 0 0 2px #184a75, inset 0 0 0 4px #ffffff;
  }
  &:active {
    background-color: #184a75;
  }
`;
