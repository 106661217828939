import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { tabBrokerTypesDetailsEnum } from "../../../models/IBrokerTypesDetailsConfig";

export const BrokerTypesDetailsTabs = (props: { activeTab: any; onChange: any }) => (
  <Grid columns="0.6fr">
    <GridItem>
      <TabContainer>
        <Tab
          className="ta-broker-types-details-tab-app-clients"
          onClick={() => {
            props.onChange(tabBrokerTypesDetailsEnum.APPCLIENTS);
          }}
          active={props.activeTab === tabBrokerTypesDetailsEnum.APPCLIENTS}
        >
          Application clients
        </Tab>
        <Tab
          className="ta-broker-types-details-tab-topics-published"
          onClick={() => {
            props.onChange(tabBrokerTypesDetailsEnum.PUBLISHED);
          }}
          active={props.activeTab === tabBrokerTypesDetailsEnum.PUBLISHED}
        >
          Topics published
        </Tab>
        <Tab
          className="ta-broker-types-details-tab-topics-subscribed"
          onClick={() => {
            props.onChange(tabBrokerTypesDetailsEnum.SUBSCRIBED);
          }}
          active={props.activeTab === tabBrokerTypesDetailsEnum.SUBSCRIBED}
        >
          Topics subscribed
        </Tab>
        <Tab
          className="ta-broker-types-details-tab-details"
          onClick={() => {
            props.onChange(tabBrokerTypesDetailsEnum.DETAILS);
          }}
          active={props.activeTab === tabBrokerTypesDetailsEnum.DETAILS}
        >
          Details
        </Tab>
      </TabContainer>
    </GridItem>
  </Grid>
);
