import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IAircraftGroupListTableConfig } from "../models/IAircraftGroup";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IAircraftGroupListTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getAircraftGroupList = async (conf: IAircraftGroupListTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/aircraftGroups${query}`);
};

const getAircraftGroupDetails = async (acGroupId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/aircraftGroups/${acGroupId}`);
};

const createAircraftGroup = async (config: any) => {
  const postData: any = {
    aircraftGroupEnvironment: config.aircraftGroupEnvironment,
    aircraftGroupName: config.aircraftGroupName,
    aircraftGroupType: config.aircraftGroupType,
    changeComment: config.changeComment,
    description: config.description,
    tenantId: config.tenantId,
  };

  const axios = await axiosInstance();

  return axios.post(`/aircraftGroups/`, postData);
};

const addAircraftsToGroups = async (config: any) => {
  const postData: any = {
    aircraftGroups: config.aircraftGroups,
    aircrafts: config.aircrafts,
    changeComment: config.changeComment,
  };

  const axios = await axiosInstance();

  return axios.post(`/aircraftGroupMembership/`, postData);
};

const removeAircraftsFromGroups = async (config: any) => {
  const axios = await axiosInstance();

  return axios.delete(`/aircraftGroupMembership/`, {
    data: {
      aircraftGroups: config.aircraftGroups,
      aircrafts: config.aircrafts,
      changeComment: config.changeComment,
    },
  });
};

export const AircraftGroupService = {
  addAircraftsToGroups,
  createAircraftGroup,
  getAircraftGroupDetails,
  getAircraftGroupList,
  removeAircraftsFromGroups,
};
