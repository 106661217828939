import { ButtonIcon, IconAdd32, TextCell } from "next-components";
import styled from "styled-components";

export const SIconAdd32 = styled(IconAdd32)`
  & path {
    fill: #ffffff;
  }
`;

export const SButtonIcon = styled(ButtonIcon)`
  background-color: ${(props: any) => (props.disabled ? "#e0e0e0" : "#2a7fc9")};
  border-radius: 20px;

  &:hover {
    background-color: ${(props: any) => (props.disabled ? "#e0e0e0" : "#4c98da")};
  }
`;

export const SDeleteIconContainer = styled(TextCell)`
  justify-content: center;
`;

export const SCloseButton = styled(ButtonIcon)`
  position: absolute;
  top: 0px;
  right: 0px;
`;
