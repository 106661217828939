import { IconCheckmark16, IconWarning16 } from "next-components";
import React from "react";

import { SColor } from "../styles/styles";

export const StatusIcon = (props: { check: boolean | ""; className?: any }) => (
  <>
    {props.check ? (
      <SColor success className={props.className}>
        <IconCheckmark16 />
      </SColor>
    ) : (
      <SColor warning className={props.className}>
        <IconWarning16 />
      </SColor>
    )}
  </>
);
