/* istanbul ignore file */
import { IGetBrokerTypeListData } from "../../../models/IBrokerTypeListingTableConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum BrokerTypeListType {
  REQUEST = "GET_BROKERTYPE_LIST_REQUEST",
  SUCCESS = "GET_BROKERTYPE_LIST_SUCCESS",
  ERROR = "GET_BROKERTYPE_LIST_ERROR",
}

export interface IBrokerTypeRequestAction extends IReduxBaseAction<BrokerTypeListType> {
  type: BrokerTypeListType.REQUEST;
}

export interface IBrokerTypeSuccessAction extends IReduxBaseAction<BrokerTypeListType> {
  payload: IGetBrokerTypeListData;
  type: BrokerTypeListType.SUCCESS;
}

export interface IBrokerTypeErrorAction extends IReduxBaseAction<BrokerTypeListType> {
  payload: string;
  type: BrokerTypeListType.ERROR;
}

export type TBrokerTypeActions =
  | IBrokerTypeRequestAction
  | IBrokerTypeSuccessAction
  | IBrokerTypeErrorAction;
