import { PopupActions, TPopupActions } from "../../actions/popup/types";

export interface IPopupState {
  content: string;
  isContentHtml: boolean;
  onConfirm: any;
  open: boolean;
  title: string;
  type: string;
}

const initialState: IPopupState = {
  content: "",
  isContentHtml: false,
  onConfirm: undefined,
  open: false,
  title: "",
  type: "",
};

export const popupData = (
  state: IPopupState = initialState,
  action: TPopupActions
): IPopupState => {
  switch (action.type) {
    case PopupActions.OPEN:
      return {
        ...state,
        ...action.payload,
        open: true,
      };
    case PopupActions.CLOSE:
      return {
        ...state,
        open: false,
        type: "",
      };
    default:
      return state;
  }
};
