import { IconInfo16, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAircraftGroupSelectList } from "../../actions/aircraftgroup/get-select-list/actions";
import { getDestinationRouteListSelect } from "../../actions/destination/get-destination-routes-select/actions";
import { popupOpenAction } from "../../actions/popup/actions";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import { AppDestinationRouteTypeEnum } from "../../models/IDestinationRoute";
import { OrderType } from "../../models/ITableConfig";
import { INormalList } from "../../models/ITypeahead";
import { RootState } from "../../store";
import {
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { BrokerTypeDropdown } from "../BrokerTypeDropdown";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";
import { Typeahead } from "../Typeahead";

// tslint:disable-next-line: cyclomatic-complexity
export const ModalSelectRouteGroupPage1 = (props: any) => {
  const { aircraftGroupSelectList, appDestinationRouteSelectList } = useSelector(
    (state: RootState) => ({
      aircraftGroupSelectList: state.aircraftGroupSelectList,
      appDestinationRouteSelectList: state.appDestinationRouteSelectList,
    })
  );

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [routeList, setRouteList] = useState({
    content: [{}],
    count: 0,
  });
  const [groupIsNotSelected, setGroupIsNotSelected] = useState(true);
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    dispatch(
      getAircraftGroupSelectList({
        filter: {
          aircraftGroupEnvironment: props.destData.destinationEnvironment,
          // tenantId: props.tenantId,
          globalOrTenantId: props.appData.tenant.tenantId,
        },
        limit: 100,
        orderBy: "",
        orderType: OrderType.ASC,
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    const rtList: INormalList = {
      content: [...aircraftGroupSelectList.content],
      count: aircraftGroupSelectList.count,
    };

    rtList.content.unshift({
      aircraftGroupId: "",
      aircraftGroupName: "",
      icao: props.appData.tenant.icao,
      routeType: AppDestinationRouteTypeEnum.TENANT,
      tenantId: props.appData.tenant.tenantId,
    });
    rtList.count = rtList.content.length;
    setRouteList(rtList);
  }, [aircraftGroupSelectList]);

  useEffect(() => {
    if (props.config && updatedConfig && props.onChange) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    setGroupIsNotSelected(true);
    if (appDestinationRouteSelectList.content.length) {
      appDestinationRouteSelectList.content.forEach((row: any) => {
        if (
          (row.aircraftGroup &&
            row.aircraftGroup.aircraftGroupId === props.config.aircraftGroupId) ||
          (row.tenant && row.tenant.tenantId === props.config.tenantId)
        ) {
          setGroupIsNotSelected(false);
        }
      });
    }
    if (!!props.config.aircraftGroupId && !!props.config.tenantId) {
      setGroupIsNotSelected(true);
      setAlertText("");
    }
  }, [props.config.aircraftGroupId, props.config.tenantId]);

  const handleCreateListLabel = (row: any) => {
    let listLabel = "";

    if (!!row.tenantId && row.routeType === AppDestinationRouteTypeEnum.TENANT) {
      listLabel = `All aircrafts (${row.icao.toUpperCase()})`;
    } else if (!!row.aircraftGroupName) {
      listLabel = `${row.aircraftGroupName}${
        row.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL ? " (Global)" : ""
      }`;
    }

    return listLabel;
  };

  const handleCreateChipLabel = ({ value }: { value: any }) => {
    let label = "";

    setAlertText("");

    if (value) {
      label = handleCreateListLabel(value);

      if (!groupIsNotSelected) {
        setAlertText("Route is already selected as source ⚠️");
      }
    }

    return label;
  };

  const handleTypeaheadInput = (value: any) => {
    if (!!value.tenantId && value.routeType === AppDestinationRouteTypeEnum.TENANT) {
      setUpdatedConfig({
        ...props.config,
        aircraftGroupId: "",
        aircraftGroupName: "",
        icao: value.icao,
        routeType: AppDestinationRouteTypeEnum.TENANT,
        tenantId: value.tenantId,
      });
    } else if (!!value.aircraftGroupId && !!value.aircraftGroupName) {
      setUpdatedConfig({
        ...props.config,
        aircraftGroupId: value.aircraftGroupId,
        aircraftGroupName: value.aircraftGroupName,
        icao: "",
        routeType: AppDestinationRouteTypeEnum.GROUP,
        tenantId: "",
      });
    } else {
      setUpdatedConfig({
        ...props.config,
        aircraftGroupId: "",
        aircraftGroupName: "",
        icao: "",
        routeType: "",
        tenantId: "",
      });
    }
  };

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-source-group">
        Source group{" "}
        <StatusIcon
          className={
            /* tslint:disable:prefer-template */
            "ta-status-icon-source-group " +
            (props.config &&
            (!!props.config.aircraftGroupId || !!props.config.tenantId) &&
            groupIsNotSelected
              ? "ta-status-icon-success"
              : "ta-status-icon-fail")
          }
          check={
            props.config &&
            (!!props.config.aircraftGroupId || !!props.config.tenantId) &&
            groupIsNotSelected
          }
        />
      </Text>

      <div className="ta-modal-input-source-group-name">
        <Typeahead
          pageSelector="addSourceGroup"
          // autoFillOnExactMatchOnly={{
          //   key: "aircraftGroupName",
          //   rule: props.autoFillOnExactMatchOnly,
          // }}
          disabled={props.disabled}
          config={{
            default: {
              filter: {
                aircraftGroupEnvironment: props.destData.destinationEnvironment,
                // tenantId: props.tenantId,
                globalOrTenantId: props.appData.tenant.tenantId,
              },
              orderBy: "",
            },
            setFilter: (value: string | any) =>
              typeof value === "string"
                ? { aircraftGroupName: value }
                : { aircraftGroupName: value.aircraftGroupName },
          }}
          suggestion={{
            getList: (config: any) => dispatch(getAircraftGroupSelectList(config)),
            isItemDisabled: (route) => {
              let isDisabled = false;
              if (appDestinationRouteSelectList.content.length) {
                isDisabled = appDestinationRouteSelectList.content.some(
                  (row: any) =>
                    (row.aircraftGroup &&
                      row.aircraftGroup.aircraftGroupId === route.aircraftGroupId) ||
                    (row.tenant && row.tenant.tenantId === route.tenantId)
                );
              }

              return isDisabled;
            },
            // list: aircraftGroupSelectList,
            list: routeList,
            select: (route: any) => `${route.aircraftGroupName}`,
          }}
          placeholder="Source group"
          name="sourceGroupName"
          chipLabel={handleCreateChipLabel}
          // value={props.config && props.config.aircraftGroupId}
          value={props.config}
          hasSelected={(p: any) => p && !p.aircraftGroupId && !p.tenantId}
          // valueToSetOnLoad="aircraftGroupName"
          valueToSetOnLoad=""
          handleTypeahead={handleTypeaheadInput}
          pattern=""
          getListLabel={handleCreateListLabel}
          minInputLen={0}
          // showListOnFocus={true}
        />
      </div>

      <Text className="ta-modal-alert-text" color="red">
        {alertText}
      </Text>
      <SSpacer height="46px" />
    </>
  );
};
