import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IApplicationListingTableConfig } from "../models/IAppListingTableConfig";
import { brokerLocationEnum } from "../models/IBrokerTypeListingTableConfig";
import { IDestinationListTableConfig } from "../models/IDestination";
import { IDestinationRouteListTableConfig } from "../models/IDestinationRoute";
import { IUserListingTableConfig } from "../models/IUserListingTableConfig";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IApplicationListingTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getApplicationList = async (conf: IApplicationListingTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/applications${query}`);
};

const getApplicationDetails = async (appId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/applications/${appId}`);
};

const createApplication = async (config: any) => {
  let postData: any = {
    appClientLocation: config.appClientLocation,
    appName: config.appName,
    appOwner: config.user.userId,
    appVersion: config.appVersion,
    changeComment: config.changeComment,
    // internal: config.internal,
    status: "Active",
    systemName: config.systemName,
  };

  if (!!config.appClientLocation && config.appClientLocation === brokerLocationEnum.AIRSIDE) {
    postData = {
      ...postData,
      authenticationType: config.authenticationType,
      connRetryDelaySec: config.connRetryDelaySec,
      numConnRetries: config.numConnRetries,
      password: config.password,
      pathClientCa: config.pathClientCa,
      pathToCa: config.pathToCa,
      userName: config.userName,
    };
  }
  // else {
  //   postData = {
  //     ...postData,
  //     groundDestinationEndpoint: config.groundDestinationEndpoint,
  //     groundDestinationType: config.groundDestinationType,
  //     intGroundDestinationEndpoint: config.intGroundDestinationEndpoint,
  //     intGroundDestinationType: config.intGroundDestinationType,
  //   };
  // }

  const axios = await axiosInstance();

  return axios.post(`/applications/`, postData);
};

const updateApplication = async (config: any) => {
  let postData: any = {
    appName: config.appName,
    // appOwner: config.user.userId,
    appVersion: config.appVersion,
    changeComment: config.changeComment,
    // internal: config.internal,
    status: !!config.status ? config.status : "ACTIVE",
    systemName: config.systemName,
  };

  if (!!config.appClientLocation && config.appClientLocation === brokerLocationEnum.AIRSIDE) {
    postData = {
      ...postData,
      authenticationType: config.authenticationType,
      connRetryDelaySec: config.connRetryDelaySec,
      numConnRetries: config.numConnRetries,
      password: config.password,
      pathClientCa: config.pathClientCa,
      pathToCa: config.pathToCa,
      userName: config.userName,
    };
  }
  // else {
  //   postData = {
  //     ...postData,
  //     groundDestinationEndpoint: config.groundDestinationEndpoint,
  //     groundDestinationType: config.groundDestinationType,
  //     intGroundDestinationEndpoint: config.intGroundDestinationEndpoint,
  //     intGroundDestinationType: config.intGroundDestinationType,
  //   };
  // }

  const axios = await axiosInstance();

  return axios.put(`/applications/${config.appClientId}`, postData);
};

const updateAppOwners = async (config: any) => {
  const postData = {
    changeComment: config.changeComment,
    owners: config.owners,
  };

  const axios = await axiosInstance();

  return axios.put(`/applications/${config.appClientId}/owners`, postData);
};

const updateAppVisibility = async (config: any) => {
  const postData = {
    changeComment: config.changeComment,
    isVisibleForPac: config.isVisibleForPac,
    visibility: config.visibility,
  };

  const axios = await axiosInstance();

  return axios.put(`/applications/${config.appClientId}/visibility`, postData);
};

const getAppVisibilityUsers = async (appId: string, conf: IUserListingTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/applications/${appId}/visibility/users${query}`);
};

const addAppVisibilityUser = async (appId: string, config: any) => {
  const postData = {
    changeComment: config.changeComment,
    user: config.userId,
  };

  const axios = await axiosInstance();

  return axios.post(`/applications/${appId}/visibility/users`, postData);
};

const deleteAppVisibilityUser = async (appId: any, config: any) => {
  const axios = await axiosInstance();

  return axios.delete(`/applications/${appId}/visibility/users/${config.userId}`, {
    params: { changeComment: config.changeComment },
  });
};

const deleteApplication = async (applicationId: any, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/applications/${applicationId}`, {
    params: { changeComment },
  });
};

const getApplicationTopicList = async (conf: any) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/mqtt${query}`);
};

const getAppDestinations = async (appId: string, conf: IDestinationListTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/applications/${appId}/destinations${query}`);
};

const getAppDestinationDetails = async (appId: string, destId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/applications/${appId}/destinations/${destId}`);
};

const createAppDestination = async (appId: string, config: any) => {
  const postData = {
    changeComment: config.changeComment,
    destinationEndpoint: config.destinationEndpoint,
    // destinationEnvironment: "",
    destinationType: config.destinationType,
    // index: 2,
  };

  const axios = await axiosInstance();

  return axios.post(
    `/applications/${appId}/destinations?env=${config.destinationEnvironment}&index=${config.index}`,
    postData
  );
};

const updateAppDestination = async (appId: string, destId: string, config: any) => {
  const postData = {
    changeComment: config.changeComment,
    destinationEndpoint: config.destinationEndpoint,
    destinationType: config.destinationType,
  };

  const axios = await axiosInstance();

  return axios.put(`/applications/${appId}/destinations/${destId}`, postData);
};

const getAppDestinationRoutes = async (
  appId: string,
  destId: string,
  conf: IDestinationRouteListTableConfig
) => {
  const query = getQueryString(conf);
  const axios = await axiosInstance();

  return axios.get(`/applications/${appId}/destinations/${destId}/routes${query}`);
};

const addAppDestinationRoute = async (appId: string, destId: string, config: any) => {
  const postData = {
    ...config,
    // aircraftGroupId: config.aircraftGroupId,
    // changeComment: config.changeComment,
    // routeType: config.routeType,
  };

  const axios = await axiosInstance();

  return axios.post(`/applications/${appId}/destinations/${destId}/routes`, postData);
};

const removeAppDestinationRoute = async (
  appId: string,
  destId: string,
  routeId: string,
  changeComment: string
) => {
  const axios = await axiosInstance();

  return axios.delete(`/applications/${appId}/destinations/${destId}/routes/${routeId}`, {
    params: { changeComment },
  });
};

export const ApplicationService = {
  addAppDestinationRoute,
  addAppVisibilityUser,
  createAppDestination,
  createApplication,
  deleteAppVisibilityUser,
  deleteApplication,
  getAppDestinationDetails,
  getAppDestinationRoutes,
  getAppDestinations,
  getAppVisibilityUsers,
  getApplicationDetails,
  getApplicationList,
  getApplicationTopicList,
  removeAppDestinationRoute,
  updateAppDestination,
  updateAppOwners,
  updateAppVisibility,
  updateApplication,
};
