import { Dispatch } from "redux";

import { UserService } from "../../../services/UserService";

import {
  IGetUserDetailsErrorAction,
  IGetUserDetailsRequestAction,
  IGetUserDetailsSuccessAction,
  UserGetDetailTypes,
} from "./types";

export const getUsersDatilsRequestAction = (): IGetUserDetailsRequestAction => ({
  type: UserGetDetailTypes.REQUEST,
});
export const getUsersDatilsSuccessAction = (data: any): IGetUserDetailsSuccessAction => ({
  payload: data,
  type: UserGetDetailTypes.SUCCESS,
});
export const getUsersDatilsErrorAction = (error: any): IGetUserDetailsErrorAction => ({
  payload: error,
  type: UserGetDetailTypes.ERROR,
});

export const getUserDetails = (userId: string, successCallback?: any) => (dispatch: Dispatch) => {
  dispatch(getUsersDatilsRequestAction() as any);
  UserService.getUserDetails(userId).then(
    (response: { data: any }) => {
      dispatch(getUsersDatilsSuccessAction(response.data.data) as any);
      if (successCallback) {
        successCallback(response.data.data);
      }
    },
    (error: any) => dispatch(getUsersDatilsErrorAction(error) as any)
  );
};
