/* tslint:disable:no-null-undefined-union */
import { colors } from "next-components";
import styled, { css } from "styled-components";

const EllipsizedContent = styled.div<{ isLarge?: boolean; isVisible?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LinkContainer = styled.a<{ selected?: boolean; showLabel?: boolean }>`
  position: relative;
  color: ${colors.general.white};
  display: grid;
  align-items: center;
  cursor: pointer;
  height: 64px;

  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;

  ${(props) =>
    props.showLabel
      ? css`
          grid-template-columns: 64px 1fr;
        `
      : css`
          grid-template-columns: 1fr;
          justify-content: center;
        `};

  ${(props) =>
    props.selected
      ? css`
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.2);
        `
      : css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
`;

export const NodeContainer = styled.div<{ selected?: boolean; showLabel?: boolean }>`
  position: relative;
  color: ${colors.general.white};
  display: grid;
  align-items: center;
  cursor: pointer;
  height: 64px;

  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;

  ${(props) =>
    props.showLabel
      ? css`
          grid-template-columns: 64px 1fr;
        `
      : css`
          grid-template-columns: 1fr;
          justify-content: center;
        `};

  ${(props) =>
    props.selected
      ? css`
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.2);
        `
      : css`
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        `}
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled(EllipsizedContent)`
  padding-left: 4px;
  padding-right: 16px;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
`;
