import { Dispatch } from "redux";

import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import { AppDestinationRouteTypeEnum } from "../../../models/IDestinationRoute";
import { ApplicationService } from "../../../services/ApplicationService";

import { DestinationRouteListSelectType, TDestinationRouteListSelectActions } from "./types";

export const DestinationRouteListSelectRequestAction = (): TDestinationRouteListSelectActions => ({
  type: DestinationRouteListSelectType.REQUEST,
});

export const DestinationRouteListSelectSuccessAction = (
  data: any
): TDestinationRouteListSelectActions => ({
  payload: data,
  type: DestinationRouteListSelectType.SUCCESS,
});

export const DestinationRouteListSelectErrorAction = (
  error: string
): TDestinationRouteListSelectActions => ({
  payload: error,
  type: DestinationRouteListSelectType.ERROR,
});

export const DestinationRouteListSelectClearAction = (): TDestinationRouteListSelectActions => ({
  type: DestinationRouteListSelectType.CLEAR,
});

export const getDestinationRouteListSelect = (appId: string, destId: string, conf: any) => (
  dispatch: Dispatch<TDestinationRouteListSelectActions>
) => {
  dispatch(DestinationRouteListSelectRequestAction());
  ApplicationService.getAppDestinationRoutes(appId, destId, conf).then(
    (data: any) => {
      if (data.data && data.data.data && data.data.data.content) {
        data.data.data.content = data.data.data.content.map((routeData: any) => ({
          ...routeData,
          aircraftGroupEnvironment: routeData.aircraftGroup
            ? routeData.aircraftGroup.aircraftGroupEnvironment
            : "",
          aircraftGroupId: routeData.aircraftGroup ? routeData.aircraftGroup.aircraftGroupId : "",
          aircraftGroupName: routeData.aircraftGroup
            ? routeData.aircraftGroup.aircraftGroupName
            : "",
          aircraftGroupType:
            routeData.routeType === AppDestinationRouteTypeEnum.GROUP
              ? routeData.aircraftGroup.aircraftGroupType
              : AircraftGroupTypeEnum.TENANT,
          icao: routeData.tenant ? routeData.tenant.icao : "",
          tenantId: routeData.tenant ? routeData.tenant.tenantId : "",
          tenantName: routeData.tenant ? routeData.tenant.tenantName : "",
        }));
      }
      dispatch(DestinationRouteListSelectSuccessAction(data.data.data));
    },
    (error: any) => dispatch(DestinationRouteListSelectErrorAction(error))
  );
};
