import { Dispatch } from "redux";

import {
  IBrokerTypeListingTableConfig,
  IGetBrokerTypeListData,
} from "../../../models/IBrokerTypeListingTableConfig";
import { BrokerTypeService } from "../../../services/BrokerTypeService";

import { AllBrokerTypeListType, TAllBrokerTypeActions } from "./types";

export const allBrokerTypeListRequestAction = (): TAllBrokerTypeActions => ({
  type: AllBrokerTypeListType.REQUEST,
});

export const allBrokerTypeListSuccessAction = (data: any): TAllBrokerTypeActions => ({
  payload: data,
  type: AllBrokerTypeListType.SUCCESS,
});

export const allBrokerTypeListErrorAction = (error: string): TAllBrokerTypeActions => ({
  payload: error,
  type: AllBrokerTypeListType.ERROR,
});

export const getAllBrokerType = (conf: IBrokerTypeListingTableConfig) => (
  dispatch: Dispatch<TAllBrokerTypeActions>
) => {
  dispatch(allBrokerTypeListRequestAction());
  BrokerTypeService.getBrokerTypeList(conf).then(
    (response: { data: { data: IGetBrokerTypeListData } }) => {
      dispatch(allBrokerTypeListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(allBrokerTypeListErrorAction(error))
  );
};
