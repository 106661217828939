import { ButtonPrimary, ProgressIndicatorCircular, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { generateConfig } from "../../actions/configfile/generate/actions";
import { editModalCloseAction } from "../../actions/modal/edit/actions";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalEditGenerateConfigComment = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const { currentUser, configFileGenerate, editModal } = useSelector((state: any) => ({
    configFileGenerate: state.configFileGenerate,
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const [isAdmin, setIsAdmin] = useState(false);
  const [isStatusWindow, setIsStatusWindow] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [configVersionId, setConfigVersionId] = useState("");

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);
    }
  }, [currentUser]);

  const handleGenerateConfigs = () => {
    dispatch(
      generateConfig(
        {
          comment: userComment,
          isRelease: isAdmin && editModal.isRelease,
        },
        (response: any) => {
          if (response) {
            const versionId = response.content.versionId ? response.content.versionId : "";
            setConfigVersionId(versionId);
            setIsStatusWindow(true);
          }
        }
      )
    );
  };

  const handleCloseDialog = () => {
    dispatch(editModalCloseAction());
    if (configVersionId.length > 0) {
      navigateToPage(`/configVersion/${configVersionId}`);
    }
  };

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (!validateTextInput(e, maxChar)) {
      return;
    }
    setUserComment(e.target.value);
  };

  const BrokerSecretsStatus = () => {
    const status = configFileGenerate.content["broker-secrets" as any].status;

    return (
      <Text>
        Broker secrets config status:{" "}
        <span style={{ color: status === "success" ? "green" : "red" }}>{status}</span>
      </Text>
    );
  };

  const AppClientStatus = () => {
    const status = configFileGenerate.content["app-client-configs" as any].status;

    return (
      <Text>
        App client config status:{" "}
        <span style={{ color: status === "success" ? "green" : "red" }}>{status}</span>
      </Text>
    );
  };

  const BrokerConfigStatus = () => {
    const status = configFileGenerate.content["broker-configs" as any].status;

    return (
      <Text>
        Broker type config status:{" "}
        <span style={{ color: status === "success" ? "green" : "red" }}>{status}</span>
      </Text>
    );
  };

  const LoadableKitStatus = () => {
    const status = configFileGenerate.content["loadable-kit" as any].status;

    return (
      <Text>
        Loadable kit trigger status:{" "}
        <span style={{ color: status === "success" ? "green" : "red" }}>{status}</span>
      </Text>
    );
  };

  if (!isStatusWindow) {
    return (
      <ModalCreateBase
        title={
          configFileGenerate.loading
            ? isAdmin && editModal.isRelease
              ? "Generating release loadable..."
              : "Generating test loadable..."
            : isAdmin && editModal.isRelease
              ? "Generate release loadable"
              : "Generate test loadable"
        }
      >
        <Textarea
          className="ta-modal-input-comment"
          placeholder="Comment (optional)"
          value={userComment}
          onChange={(e: any) => {
            handleTextInput(e, "comment", 2048);
          }}
        />
        <SSpacer height={isAdmin && editModal.isRelease ? "0px" : "5px"} />
        <Text>
          {isAdmin && editModal.isRelease ? " " : "Generated test loadable includes draft topics."}
        </Text>
        <SSpacer height="5px" />
        <Text>
          On success, you will receive an email that describes how to download the loadable.
        </Text>
        <SSpacer height="20px" />
        <SFlexContainer justifyContent="flex-end">
          <ButtonPrimary
            className="ta-modal-config-generate-button"
            onClick={handleGenerateConfigs}
            disabled={configFileGenerate.loading}
          >
            {!configFileGenerate.loading && <>Generate</>}
            {configFileGenerate.loading && <ProgressIndicatorCircular size="small" />}
          </ButtonPrimary>
        </SFlexContainer>
      </ModalCreateBase>
    );
  }

  return (
    <ModalCreateBase title=" ">
      <SSpacer height="15px" />
      <AppClientStatus />
      <SSpacer height="5px" />
      <BrokerConfigStatus />
      <SSpacer height="5px" />
      <BrokerSecretsStatus />
      <SSpacer height="10px" />
      <LoadableKitStatus />
      <SSpacer height="10px" />
      <SFlexContainer justifyContent="flex-end">
        <ButtonPrimary className="ta-modal-close-button" onClick={handleCloseDialog}>
          Close
        </ButtonPrimary>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
