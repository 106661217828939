import deepEqual from "deep-equal";
import { ButtonPrimary, GridItem, ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getTopicsForPublisher } from "../actions/application/get-topics-for-publisher/actions";
import {
  appTopicListClearAction,
  getApplicationTopicList,
} from "../actions/application/get-topics/actions";
import { getAllBrokerType } from "../actions/broker-type/get-all/actions";
import { editModalCloseAction, editModalOpenAction } from "../actions/modal/edit/actions";
import { popupOpenAction } from "../actions/popup/actions";
import { deleteAssociationBulk } from "../actions/topic/delete-association-bulk/actions";
import { deleteAssociation } from "../actions/topic/delete-association/actions";
import { getTAClass, TA_TYPES } from "../helper/taHelper";
import { getFormattedDate, getTenantLabel } from "../helper/util";
import { brokerLocationEnum } from "../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../models/IFilterConfig";
import { MqttLocalGroupDataEnum } from "../models/IMqtt";
import { ITableConfig, ITableRow, OrderType } from "../models/ITableConfig";
import { localGroupBridgeTypeEnum, topicStatusEnum } from "../models/ITopicConfig";
import { ApplicationService } from "../services/ApplicationService";
import { RootState } from "../store";
import { SActiveLink, SOverlay, SSpacer } from "../styles/styles";

import { Filter } from "./Filter";
import { Table } from "./Table";

export const AppTopicsList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const statusOptions = [
    {
      label: <span className="ta-dropdown-draft">Draft</span>,
      value: topicStatusEnum.DRAFT,
    },
    {
      label: <span className="ta-dropdown-active">Active</span>,
      value: topicStatusEnum.ACTIVE,
    },
    {
      label: <span className="ta-dropdown-onhold">On hold</span>,
      value: topicStatusEnum.ONHOLD,
    },
    {
      label: <span className="ta-dropdown-deleted">Deleted</span>,
      value: topicStatusEnum.DELETED,
    },
  ];

  const getBridgeFrom = (row: ITableRow) => {
    let bridgeBroker: string = row.brokerTopology
      ? row.brokerTopology.parentBrokerType.brokerType
      : "-";

    if (
      props.type === "PUBLISHER" &&
      row.topic.localGroupBridge === localGroupBridgeTypeEnum.ASTROVA
    ) {
      bridgeBroker =
        row.localGroupData === MqttLocalGroupDataEnum.ASTROVA_PUB_PRIMARY
          ? "SEB/Primary"
          : "SEB/Secondary";
    }

    return bridgeBroker;
  };

  const getBridgeTo = (row: ITableRow) => {
    let bridgeBroker: string = row.brokerTopology
      ? row.brokerTopology.remoteBrokerType.brokerType
      : "-";

    if (
      props.type === "PUBLISHER" &&
      row.topic.localGroupBridge === localGroupBridgeTypeEnum.ASTROVA
    ) {
      bridgeBroker =
        row.localGroupData === MqttLocalGroupDataEnum.ASTROVA_PUB_PRIMARY
          ? "SEB/Secondary"
          : "SEB/Primary";
    }

    return bridgeBroker;
  };

  // const [headCells, setHeadCells] = useState([
  const headCells = [
    {
      id: "topicName",
      label: "Topic name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/topics/${row.topic.topicId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.topic.topicName}</SActiveLink>,
      width: "3fr",
    },
    {
      id: "status",
      label: "Topic status",
      value: (row: ITableRow) => row.topic.status,
    },
    {
      id: "topicOwnerAppClientName",
      label: "Topic owner",
      onClickCell: (row: ITableRow) => {
        if (
          row.topic.appClient.appClientId &&
          row.topic.appClient.appName &&
          row.topic.appClient.appVersion
        ) {
          navigateToPage(`/applicationClients/${row.topic.appClient.appClientId}`);
        }
      },
      value: (row: ITableRow) =>
        row.topic.appClient.appName && row.topic.appClient.appVersion ? (
          <SActiveLink>{`${row.topic.appClient.appName} ${row.topic.appClient.appVersion}`}</SActiveLink>
        ) : row.topic.appClient.tenant && row.topic.appClient.tenant.tenantName ? (
          getTenantLabel(row.topic.appClient)
        ) : (
          "* * * * * *"
        ),
    },
    {
      id: "topicOwnerAppClientVersion",
      label: "Topic owner version",
      value: (row: ITableRow) => row.topic.appClient.appVersion,
    },
    {
      id: "mqttClientBrokerType",
      label: props.type === "PUBLISHER" ? "Publishing from" : "Subscribing from",
      value: (row: ITableRow) => row.brokerType && row.brokerType.brokerType,
    },
    {
      id: "mqttClientBridgeFrom",
      label: "Bridged from",
      value: getBridgeFrom,
    },
    {
      id: "mqttClientBridgeTo",
      label: "Bridged to",
      value: getBridgeTo,
    },
    {
      id: props.type === "PUBLISHER" ? "mqttClientRetainRequired" : "mqttClientNeedBridge",
      label: props.type === "PUBLISHER" ? "Retain required" : "Create bridge",
      value: (row: ITableRow) =>
        (props.type === "PUBLISHER" ? row.retainRequired : row.needBridge === "1") ? "Yes" : "No",
    },
    // {
    //   id: "mqttClientCreatedAt",
    //   label: "Created at",
    //   value: (row: ITableRow) => getFormattedDate(row.createdAt),
    // },
    {
      id: "mqttClientModifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
    { id: "delete", label: "Delete" },
  ];

  const headCells_groundside = [
    {
      id: "topicName",
      label: "Topic name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/topics/${row.topic.topicId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.topic.topicName}</SActiveLink>,
      width: "3fr",
    },
    {
      id: "status",
      label: "Topic status",
      value: (row: ITableRow) => row.topic.status,
    },
    {
      id: "topicOwnerAppClientName",
      label: "Topic owner",
      onClickCell: (row: ITableRow) => {
        if (
          row.topic.appClient.appClientId &&
          row.topic.appClient.appName &&
          row.topic.appClient.appVersion
        ) {
          navigateToPage(`/applicationClients/${row.topic.appClient.appClientId}`);
        }
      },
      value: (row: ITableRow) =>
        row.topic.appClient.appName && row.topic.appClient.appVersion ? (
          <SActiveLink>{`${row.topic.appClient.appName} ${row.topic.appClient.appVersion}`}</SActiveLink>
        ) : row.topic.appClient.tenant && row.topic.appClient.tenant.tenantName ? (
          getTenantLabel(row.topic.appClient)
        ) : (
          "* * * * * *"
        ),
    },
    {
      id: "topicOwnerAppClientVersion",
      label: "Topic owner version",
      value: (row: ITableRow) => row.topic.appClient.appVersion,
    },
    {
      id: "mqttClientBrokerType",
      label: props.type === "PUBLISHER" ? "Publishing from" : "Subscribing from",
      value: (row: ITableRow) => row.brokerType && row.brokerType.brokerType,
    },
    // {
    //   id: "mqttClientCreatedAt",
    //   label: "Created at",
    //   value: (row: ITableRow) => getFormattedDate(row.createdAt),
    // },
    {
      id: "mqttClientModifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
    { id: "delete", label: "Delete" },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: {},
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  const { allBrokerType, applicationTopicList } = useSelector((state: RootState) => ({
    allBrokerType: state.allBrokerType,
    applicationTopicList: state.applicationTopicList,
  }));

  const emptyFilterObject = {
    clientType: props.type,
    modifiedDate: { startDate: undefined, endDate: undefined },
    mqttClientBrokerType: "",
    mqttConnectedAppClientId: "",
    topicAppOwnerName: "",
    topicAppOwnerVersion: "",
    topicName: "",
    topicStatus: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "topicName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  // Wait for the first filter set
  useEffect(() => {
    if (config.filter.mqttConnectedAppClientId !== "") {
      dispatch(getApplicationTopicList(config));
    }
  }, [config]);

  useEffect(
    () => () => {
      dispatch(appTopicListClearAction());
    },
    []
  );

  const deleteMqttAssociation = (topicMqttClientId: string, changeComment: string) => {
    dispatch(
      deleteAssociation(topicMqttClientId, changeComment, () => {
        setConfig({ ...config, page: 0 });
      })
    );
  };

  const notifyAboutLastPublisher = () => {
    dispatch(
      popupOpenAction({
        content:
          "You are trying to remove the last publisher to the topic. Please remove all the subscribers or assign another publisher first.",
        title: "Error",
        type: "Info",
      })
    );
  };

  const notifyAboutLastPublisherBulkDelete = (
    numberOfCase: number,
    numberOfselected: number,
    isSuccessful: boolean
  ) => {
    if (isSuccessful) {
      dispatch(
        popupOpenAction({
          content: `${numberOfCase}/${numberOfselected} associations deleted successfully`,
          title: "Success",
          type: "Info",
        })
      );
    } else {
      dispatch(
        popupOpenAction({
          content: `You have tried to remove the last publisher for ${numberOfCase} out of ${numberOfselected} topics selected. Please remove all the subscriptions or assign another publisher first.`,
          title: "Error",
          type: "Info",
        })
      );
    }
  };

  const askForConfirmation = (
    topicMqttClientId: string,
    isLastPublisher: boolean = false,
    hasSubscribers: boolean = false
  ) => {
    const confirmMsg1 =
      "You are trying to remove the association between the Application client and the Topic. Configuration file will be updated accordingly, do you want to continue?";
    const confirmMsg2 =
      "You are trying to remove the last publisher to the topic. Last publisher can only be removed if there are no subscribers to the topic. Do you want to continue?";

    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint:
            "Please enter a comment about why you removed the Application client and Topic association.",
          commentRequired: false,
          contentText: isLastPublisher ? confirmMsg2 : confirmMsg1,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            if (isLastPublisher && hasSubscribers) {
              notifyAboutLastPublisher();
            } else {
              deleteMqttAssociation(topicMqttClientId, param.changeComment);
            }
          },
          title: `Remove association`,
        },
        type: modalTypeEnum.DELETE_MQTT,
      })
    );
  };

  const getPromiseOfApplicationTopicList = (
    currentClientType: string,
    currentTopicId: string
  ): Promise<any> =>
    ApplicationService.getApplicationTopicList({
      filter: {
        clientType: currentClientType,
        topicId: currentTopicId,
      },
      limit: 100,
      orderBy: "topicName",
      orderType: OrderType.ASC,
      page: 0,
    });

  const getErrorMessageAndThrowPopup = (row: ITableRow, isConfirmNeeded: boolean) => {
    const topicMqttClientId: string = row.topicMqttClientId;
    const selectedTopicId: string = row.topic.topicId;

    if (props.type === "PUBLISHER") {
      // Get the publisher list
      dispatch(
        getTopicsForPublisher(
          {
            filter: {
              clientType: "PUBLISHER",
              topicId: selectedTopicId,
              topicStatus: "ACTIVE",
            },
            limit: 100,
            orderBy: "topicName",
            orderType: OrderType.ASC,
            page: 0,
          },
          (isLastPublisher: boolean) => {
            // if (!(isLastPublisher || isConfirmNeeded)) {
            //   deleteMqttAssociation(topicMqttClientId);
            // } else {
            getPromiseOfApplicationTopicList("SUBSCRIBER", selectedTopicId)
              .then((response: any) => {
                askForConfirmation(
                  topicMqttClientId,
                  isLastPublisher,
                  response.data.data.count > 0
                );
              })
              .catch((e: any) => {
                console.error(e);
              });
            // }
          }
        )
      );
    } else {
      // if (isConfirmNeeded) {
      askForConfirmation(topicMqttClientId);
      // } else {
      //   deleteMqttAssociation(topicMqttClientId);
      // }
    }
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const askForConfirmationBulk = (mqttIds: string[], successCb?: any) => {
    const confirmMsg1 =
      "You are trying to remove multiple associations between the Application clients and the Topics. Configuration files will be updated accordingly, do you want to continue?";

    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint:
            "Please enter a comment about why you removed multiple Application clients and Topic associations.",
          commentRequired: false,
          contentText: confirmMsg1,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(deleteAssociationBulk({ mqttIds }, param.changeComment, successCb));
          },
          title: `Remove multiple associations`,
        },
        type: modalTypeEnum.DELETE_MQTT_BULK,
      })
    );
  };

  const batchDelete = (selRows: ITableRow[]) => {
    if (selRows.length === 1) {
      getErrorMessageAndThrowPopup(selRows[0], false);
    } else {
      const mqttIds = selRows.map((row: ITableRow) => row.topicMqttClientId);

      if (props.type === "PUBLISHER") {
        const topicIds = selRows.map((row: ITableRow) => row.topic.topicId);
        // tslint:disable-next-line: array-type
        const promises: Promise<any>[] = [];
        topicIds.forEach((currentTopicId: string) =>
          promises.push(getPromiseOfApplicationTopicList("PUBLISHER", currentTopicId))
        );
        Promise.all(promises)
          .then((responsesOfLastPublishers) => {
            const mqttIdsOfLastPublishers: string[] = [];
            const mqttIdsOfNotLastPublishers: string[] = [];
            const topicIdsOfLastPublishers: string[] = [];
            const mqttIdsOfLastPublishersWithSubscribers: string[] = [];
            const mqttIdsOfLastPublishersWithoutSubscribers: string[] = [];
            const mqttIdsOfAlreadyDeleted: string[] = [];

            responsesOfLastPublishers.forEach((response, index) => {
              const isExistingMqttAppClient = response.data.data.content.filter(
                (mqtt: any) => mqtt.appClient.appClientId === props.appContent.appClientId
              ).length;
              if (isExistingMqttAppClient) {
                if (response.data.data.count === 1) {
                  mqttIdsOfLastPublishers.push(mqttIds[Number(index)]);
                  topicIdsOfLastPublishers.push(topicIds[Number(index)]);
                } else if (response.data.data.count > 1) {
                  mqttIdsOfNotLastPublishers.push(mqttIds[Number(index)]);
                }
              } else {
                mqttIdsOfAlreadyDeleted.push(mqttIds[Number(index)]);
              }
            });

            // tslint:disable-next-line: array-type
            const promisesForPotentialSubscibersOfLastPublishers: Promise<any>[] = [];
            topicIdsOfLastPublishers.forEach((topicId: string) => {
              promisesForPotentialSubscibersOfLastPublishers.push(
                getPromiseOfApplicationTopicList("SUBSCRIBER", topicId)
              );
            });
            Promise.all(promisesForPotentialSubscibersOfLastPublishers)
              .then((responsesOfExistingSubscribers) => {
                responsesOfExistingSubscribers.forEach((response, index) => {
                  if (response.data.data.count > 0) {
                    mqttIdsOfLastPublishersWithSubscribers.push(
                      mqttIdsOfLastPublishers[Number(index)]
                    );
                  } else {
                    mqttIdsOfLastPublishersWithoutSubscribers.push(
                      mqttIdsOfLastPublishers[Number(index)]
                    );
                  }
                });

                const deletableAssociations = mqttIdsOfNotLastPublishers.concat(
                  mqttIdsOfLastPublishersWithoutSubscribers
                );
                const notDeletableAssociations = mqttIdsOfAlreadyDeleted.concat(
                  mqttIdsOfLastPublishersWithSubscribers
                );

                if (
                  !deletableAssociations.length &&
                  mqttIdsOfAlreadyDeleted.length !== selRows.length
                ) {
                  notifyAboutLastPublisherBulkDelete(
                    mqttIdsOfLastPublishersWithSubscribers.length,
                    selRows.length,
                    false
                  );
                } else {
                  // dispatch(
                  //   deleteAssociationBulk({ changeComment:"dummy comment", mqttIds: deletableAssociations }, () => {
                  askForConfirmationBulk(deletableAssociations, () => {
                    if (
                      mqttIdsOfAlreadyDeleted.length &&
                      mqttIdsOfAlreadyDeleted.length + deletableAssociations.length ===
                        selRows.length
                    ) {
                      notifyAboutLastPublisherBulkDelete(
                        deletableAssociations.length,
                        selRows.length,
                        true
                      );
                    } else if (deletableAssociations.length !== selRows.length) {
                      notifyAboutLastPublisherBulkDelete(
                        mqttIdsOfLastPublishersWithSubscribers.length,
                        selRows.length,
                        false
                      );
                    }
                    setConfig({ ...config, page: 0 });
                  });
                }
              })
              .catch((e) => {
                console.error(e);
              });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        // dispatch(
        //   deleteAssociationBulk({ mqttIds, changeComment:"dummy comment" }, () => {
        askForConfirmationBulk(mqttIds, () => {
          setConfig({ ...config, page: 0 });
        });
      }
    }
  };

  const tableProps: ITableConfig = {
    ...(props.isAdmin && {
      batchAction: {
        actions: [{ title: "Delete", onClick: batchDelete }],
        label: (selRows: ITableRow[]) =>
          `${selRows.length} item${selRows.length > 1 ? "s" : " is "} selected`,
      },
    }),
    deleteCell: {
      as: "a",
      disabled: (row: ITableRow) => !(props.isAdmin || props.isOwner),
      onClick: (row: ITableRow) => {
        if (props.isAdmin || props.isOwner) {
          getErrorMessageAndThrowPopup(row, true);
        }
      },
    },
    head: {
      cells:
        props.appContent && props.appContent.appClientLocation === brokerLocationEnum.GROUNDSIDE
          ? headCells_groundside
          : headCells,
    },
    list: {
      ...applicationTopicList,
    },
    name: "application-details",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const filterItems = [
    {
      name: "topicName",
      placeholder: "Topic name",
      taClass: "topicName",
    },
    {
      data: statusOptions,
      name: "topicStatus",
      placeholder: "Topic status",
      taClass: "topicStatus",
      type: FilterType.DROPDOWN,
    },
    {
      name: "topicAppOwnerName",
      placeholder: "Topic owner",
      taClass: "topicOwner",
    },
    {
      name: "topicAppOwnerVersion",
      placeholder: "Topic owner version",
      taClass: "topicOwnerVersion",
    },
    {
      data: brokerTypes,
      name: "mqttClientBrokerType",
      placeholder: props.type === "PUBLISHER" ? "Publishing from" : "Subscribing from",
      taClass: "brokerType",
      type: FilterType.DROPDOWN,
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ];

  const filterItems_groundside = [
    {
      name: "topicName",
      placeholder: "Topic name",
      taClass: "topicName",
    },
    {
      data: statusOptions,
      name: "topicStatus",
      placeholder: "Topic status",
      taClass: "topicStatus",
      type: FilterType.DROPDOWN,
    },
    {
      name: "topicAppOwnerName",
      placeholder: "Topic owner",
      taClass: "topicOwner",
    },
    {
      name: "topicAppOwnerVersion",
      placeholder: "Topic owner version",
      taClass: "topicOwnerVersion",
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ];

  const filterConfig: IFilterConfig = {
    customElements: [
      {
        element: (
          <GridItem justifySelf="end">
            <ButtonPrimary
              className={
                props.type === "PUBLISHER"
                  ? getTAClass("applicationDetails", TA_TYPES.BUTTON, "addPublisher")
                  : getTAClass("applicationDetails", TA_TYPES.BUTTON, "addSubscriber")
              }
              onClick={() => {
                props.btnClick(config);

                return;
              }}
              disabled={props.btnDisabled || tableProps.list.loading}
            >
              {props.type === "PUBLISHER" ? <>Add publisher</> : <>Add subscription</>}
            </ButtonPrimary>
          </GridItem>
        ),
      },
    ],
    items:
      props.appContent && props.appContent.appClientLocation === brokerLocationEnum.GROUNDSIDE
        ? filterItems_groundside
        : filterItems,
    pageName: "applicationDetails",
    returnFilter: (filter: any) => {
      const newFilterObject = {
        ...emptyFilterObject,
        clientType: props.type,
        mqttConnectedAppClientId: props.appContent ? props.appContent.appClientId : "",
        ...filter,
      };

      if (!deepEqual(config.filter, newFilterObject)) {
        setConfig({
          ...config,
          filter: newFilterObject,
          page: 0,
        });
      }
    },
  };

  return (
    <>
      <Filter {...filterConfig} />
      {/* FILTER */}

      <SSpacer />

      {/* LOADING */}
      {applicationTopicList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {/* TABLE */}
      {!applicationTopicList.loading && <Table {...tableProps} />}
    </>
  );
};
