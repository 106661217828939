import React from "react";

import { SOverlayCenterAlign } from "../styles/styles";

import { ProgressIndicatorCircular } from "./next-component-fork";

export const PageLoading = () => (
  <SOverlayCenterAlign className="ta-page-loading">
    <ProgressIndicatorCircular />
  </SOverlayCenterAlign>
);

export const PageError = () => <p className="ta-page-error">Error retrieving data.</p>;
