import moment from "moment-timezone";
import { Grid, GridItem, IconForum16, IconForum20, IconForum24 } from "next-components";
import React from "react";
import { Link } from "react-router-dom";

import {
  HistoryChangeTypeEnum,
  HistoryDisplayModeEnum,
  HistoryItemFieldEnum,
  IChangeHistoryConfigVersion,
  IChangeHistoryInfo,
} from "../models/IChangeHistories";
import { GroundEnvironmentTypeEnum } from "../models/IDestination";
import { topicStatusEnum } from "../models/ITopicConfig";
import { ConfigService } from "../services/ConfigService";
import {
  SActiveLink,
  SMediumText,
  STallText,
  STextDataTitle,
  STextDataValue2,
  SVerticalSpacer,
} from "../styles/styles";

const getHistoryTimestamp = (datetime: string) => {
  const timestamp = moment(datetime)
    .tz(moment.tz.guess())
    .format("MMM/DD/YYYY HH:mm");

  return timestamp;
};

/* tslint:disable:cyclomatic-complexity */
const getHistoryWhatHappened = (info: IChangeHistoryInfo) => {
  let whatHappened: string | JSX.Element = "";
  switch (info.changeType) {
    case HistoryChangeTypeEnum.APP_CLIENT_CREATED:
      whatHappened = "created app client";
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_MODIFIED:
      whatHappened = "made changes";
      if (info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " in app client";
      }
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_DELETED:
      whatHappened = "deleted app client";
      break;

    case HistoryChangeTypeEnum.TOPIC_CREATED:
      whatHappened = "created topic";
      break;

    case HistoryChangeTypeEnum.TOPIC_MODIFIED:
      whatHappened = "made changes";
      if (info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " in topic";
      }
      break;

    case HistoryChangeTypeEnum.TOPIC_DELETED:
      whatHappened = "deleted topic";
      break;

    case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_REQUESTED:
      const requestedStatus =
        info.items &&
        info.items.length > 0 &&
        info.items[0].itemField === HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_REQUESTED_STATUS
          ? info.items[0].valueAfter
          : "-";
      whatHappened = `requested status ${requestedStatus}`;
      if (info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " for topic";
      }
      break;

    case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_FINISHED:
      const [finishedAction, finishedStatus] =
        info.items &&
        info.items.length > 0 &&
        info.items[0].itemField === HistoryItemFieldEnum.TOPIC_STATUS_CHANGE_FINISHED_ACTION
          ? [info.items[0].valueAfter, info.items[0].valueBefore]
          : [null, "-"];
      if (finishedAction === "APPROVE") {
        whatHappened = `approved status ${finishedStatus}`;
      } else if (finishedAction === "DISAPPROVE") {
        whatHappened = `disapproved status ${finishedStatus}`;
      }
      if (whatHappened && info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " for topic";
      }
      break;

    case HistoryChangeTypeEnum.PUBLISHER_CREATED:
      whatHappened =
        info.displayMode === HistoryDisplayModeEnum.APP_CLIENT_HISTORY
          ? "added publication"
          : "added publisher";
      break;

    case HistoryChangeTypeEnum.PUBLISHER_DELETED:
      whatHappened =
        info.displayMode === HistoryDisplayModeEnum.APP_CLIENT_HISTORY
          ? "deleted publication"
          : "deleted publisher";
      break;

    case HistoryChangeTypeEnum.SUBSCRIBER_CREATED:
      whatHappened =
        info.displayMode === HistoryDisplayModeEnum.APP_CLIENT_HISTORY
          ? "added subscription"
          : "added subscriber";
      break;

    case HistoryChangeTypeEnum.SUBSCRIBER_DELETED:
      whatHappened =
        info.displayMode === HistoryDisplayModeEnum.APP_CLIENT_HISTORY
          ? "deleted subscription"
          : "deleted subscriber";
      break;

    case HistoryChangeTypeEnum.CONFIG_VERSION_CREATED:
      whatHappened = "created loadable";
      break;

    case HistoryChangeTypeEnum.TENANT_CREATED:
      whatHappened = "provisioned airline";
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_CREATED:
      whatHappened = "provisioned aircraft / rack";
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_MODIFIED:
      whatHappened = "Updated aircraft / rack";
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_GROUP_CREATED:
      whatHappened = "created airline group";
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_GROUP_MODIFIED:
      whatHappened = "modified airline group";
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_ADDED:
      whatHappened = "added route";
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_ROUTE_DELETED:
      whatHappened = "removed route";
      break;

    case HistoryChangeTypeEnum.GROUND_CONFIG_VERSION_DEPLOYED:
      whatHappened = "deployed ground configurations";
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_CREATED:
    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_MODIFIED:
      whatHappened = (
        <>
          {info.changeType === HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_CREATED
            ? `created destination`
            : `modified destination`}
          <SVerticalSpacer width="6px" />
          {info.appClientDestination && info.appClient && (
            <SActiveLink>
              <Link
                to={`/destination/${info.appClientDestination.appClientDestinationId}?appId=${info.appClient.appClientId}`}
              >
                {info.appClientDestination.destinationEnvironment ===
                GroundEnvironmentTypeEnum.PRODUCTION
                  ? `Production`
                  : `Integration ${Number(info.appClientDestination.index) + 1}`}
              </Link>
            </SActiveLink>
          )}
          <SVerticalSpacer width="6px" />
          {info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY && ` for application client`}
        </>
      );
      break;

    case HistoryChangeTypeEnum.USER_CREATED:
      whatHappened = "created user";
      break;

    case HistoryChangeTypeEnum.USER_MODIFIED:
      whatHappened = "made changes";
      if (info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " to user";
      }
      break;

    case HistoryChangeTypeEnum.USER_ROLE_CHANGE_REQUESTED:
      const requestedRole =
        info.items &&
        info.items.length > 0 &&
        info.items[0].itemField === HistoryItemFieldEnum.USER_ROLE_CHANGE_REQUESTED_ROLE
          ? info.items[0].valueAfter
          : "-";
      whatHappened = `requested role ${requestedRole}`;
      if (info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " for user";
      }
      break;

    case HistoryChangeTypeEnum.USER_ROLE_CHANGE_FINISHED:
      const [finishedDecision, finishedRole] =
        info.items &&
        info.items.length > 0 &&
        info.items[0].itemField === HistoryItemFieldEnum.USER_ROLE_CHANGE_FINISHED_ACTION
          ? [info.items[0].valueAfter, info.items[0].valueBefore]
          : [null, "-"];
      if (finishedDecision === "ACTIVE") {
        whatHappened = `granted role ${finishedRole}`;
      } else if (finishedDecision === "DENIED") {
        whatHappened = `denied role ${finishedRole}`;
      }
      if (whatHappened && info.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY) {
        whatHappened += " for user";
      }
      break;

    default:
  }

  return whatHappened;
};

const getHistoryWhatHappenedTo = (info: IChangeHistoryInfo) => {
  switch (info.changeType) {
    case HistoryChangeTypeEnum.APP_CLIENT_CREATED:
    case HistoryChangeTypeEnum.APP_CLIENT_DELETED:
    case HistoryChangeTypeEnum.APP_CLIENT_MODIFIED:
      if (info.appClient) {
        return (
          <SActiveLink>
            <Link to={`/applicationClients/${info.appClient.appClientId}`}>
              {`${info.appClient.appName} ${info.appClient.appVersion}`}
            </Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.TOPIC_CREATED:
    case HistoryChangeTypeEnum.TOPIC_DELETED:
    case HistoryChangeTypeEnum.TOPIC_MODIFIED:
    case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_REQUESTED:
    case HistoryChangeTypeEnum.TOPIC_STATUS_CHANGE_FINISHED:
      if (info.topic) {
        return (
          <SActiveLink>
            <Link to={`/topics/${info.topic.topicId}`}>
              {info.topic.topicName}{" "}
              {info.topicStatus && info.topicStatus === topicStatusEnum.DRAFT ? "(DRAFT)" : ""}
            </Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.TENANT_CREATED:
      if (info.tenant) {
        return (
          <SActiveLink>
            <Link to={`/airlines/${info.tenant.tenantId}`}>{`${info.tenant.tenantName}`}</Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_CREATED:
    case HistoryChangeTypeEnum.AIRCRAFT_MODIFIED:
      if (info.aircraft) {
        return (
          <SActiveLink>
            <Link
              to={`/aircrafts/${info.aircraft.aircraftId}`}
            >{`${info.aircraft.tailNumber}`}</Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.AIRCRAFT_GROUP_CREATED:
    case HistoryChangeTypeEnum.AIRCRAFT_GROUP_MODIFIED:
      if (info.aircraftGroup) {
        return (
          <SActiveLink>
            <Link
              to={`/aircraftGroups/${info.aircraftGroup.aircraftGroupId}`}
            >{`${info.aircraftGroup.aircraftGroupName}`}</Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_CREATED:
    case HistoryChangeTypeEnum.APP_CLIENT_DESTINATION_MODIFIED:
      if (info.appClient) {
        return (
          <SActiveLink>
            <Link to={`/applicationClients/${info.appClient.appClientId}`}>
              {`${info.appClient.appName} ${info.appClient.appVersion}`}
            </Link>
          </SActiveLink>
        );
      }
      break;

    case HistoryChangeTypeEnum.USER_CREATED:
    case HistoryChangeTypeEnum.USER_MODIFIED:
    case HistoryChangeTypeEnum.USER_ROLE_CHANGE_FINISHED:
    case HistoryChangeTypeEnum.USER_ROLE_CHANGE_REQUESTED:
      if (info.user) {
        return (info.isAdmin &&
          info.user.fullName !== "System user" &&
          info.user.fullName !== "Provision Service") ||
          info.curUserId === info.user.userId ? (
          <SActiveLink>
            <Link to={`/users/${info.user.userId}`}>{info.user.fullName}</Link>
          </SActiveLink>
        ) : (
          info.user.fullName
        );
      }
      break;

    default:
  }

  return <></>;
};
/* tslint:enable:cyclomatic-complexity */

const getHistoryComment = (info: IChangeHistoryInfo) => {
  const commentStr =
    info.changeType !== HistoryChangeTypeEnum.CONFIG_VERSION_CREATED
      ? info.comment
        ? info.comment
        : ""
      : info.configVersion
      ? info.configVersion.comment
      : info.comment
      ? info.comment
      : "";

  if (commentStr.length > 0) {
    return (
      // <SMediumText variant="uiText" className={`${props.className}-comment`} color="#808080">
      <SMediumText variant="uiText" className={`${info.className}-comment`}>
        <STextDataValue2>
          <IconForum20 />
        </STextDataValue2>
        <STextDataValue2>
          <i>{commentStr}</i>
        </STextDataValue2>
      </SMediumText>
    );
  }

  return <></>;
};

export const ChangeHistoryInfo = (props: IChangeHistoryInfo) => (
  <>
    <Grid columns="4fr">
      {/* <Grid columns="4fr 1fr"> */}
      <GridItem>
        <STallText variant="uiText" className={`${props.className}-info`}>
          <STextDataValue2>{`${getHistoryTimestamp(props.modifiedAt)} - `}</STextDataValue2>
          <STextDataValue2>
            {(props.isAdmin &&
              props.modifiedBy.fullName !== "System user" &&
              props.modifiedBy.fullName !== "Provision Service") ||
            props.curUserId === props.modifiedBy.userId ? (
              <SActiveLink>
                <Link to={`/users/${props.modifiedBy.userId}`}>{props.modifiedBy.fullName}</Link>
              </SActiveLink>
            ) : (
              props.modifiedBy.fullName
            )}
          </STextDataValue2>
          <STextDataValue2>{getHistoryWhatHappened(props)}</STextDataValue2>
          {props.displayMode === HistoryDisplayModeEnum.GLOBAL_HISTORY &&
            getHistoryWhatHappenedTo(props)}
        </STallText>
        {/* </GridItem>
      <GridItem> */}
        {getHistoryComment(props)}
      </GridItem>
    </Grid>

    {props.changeType === HistoryChangeTypeEnum.CONFIG_VERSION_CREATED && props.configVersion && (
      <Grid columns={props.columns ? props.columns : "3fr 1fr"} paddingLeft="30px">
        <GridItem>
          <SMediumText variant="uiText">
            {props.configVersion.isRelease ? (
              <STextDataTitle>Release</STextDataTitle>
            ) : (
              <STextDataValue2>Test</STextDataValue2>
            )}
            {props.displayMode !== HistoryDisplayModeEnum.CONFIG_VERSION_HISTORY && (
              <STextDataValue2>{`version: ${props.configVersion.version}`}</STextDataValue2>
            )}
          </SMediumText>
        </GridItem>
        <GridItem>
          {props.displayMode === HistoryDisplayModeEnum.CONFIG_VERSION_HISTORY && (
            // <STextDataValue2>{`version: ${props.configVersion.version}`}</STextDataValue2>
            <STextDataValue2>{props.configVersion.version}</STextDataValue2>
          )}
        </GridItem>
        <GridItem>
          <SMediumText variant="uiText">
            <STextDataValue2>
              {props.configVersion.isChangelogAvailable ? (
                <SActiveLink
                  onClick={() =>
                    ConfigService.s3ChangeLogUrl(
                      props.configVersion ? props.configVersion.configVersionId : ""
                    )
                  }
                >
                  {`${props.configVersion.version}_changelog.txt`}
                </SActiveLink>
              ) : (
                "Change log not available"
              )}
            </STextDataValue2>
          </SMediumText>
        </GridItem>
      </Grid>
    )}
  </>
);
