import deepEqual from "deep-equal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationList } from "../../../actions/application/get-list/actions";
import { getAppOwnersCSV } from "../../../helper/util";
import { userListStatusEnum } from "../../../models/IAppListingTableConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { RootState } from "../../../store";
import { SSpacer } from "../../../styles/helper";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

interface IBrokerTypesDetailsTabAppClient {
  content: any;
}

export const BrokerTypesDetailsTabAppClient = ({ content }: IBrokerTypesDetailsTabAppClient) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const history = useHistory();

  const emptyFilterObject = {
    appNameAndVersion: "",
    appOwner: "",
    mqttClientBrokerType: content.brokerType,
    status: userListStatusEnum.EMPTY,
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "appNameAndVersion",
    orderType: OrderType.ASC,
    page: 0,
  });

  const { applicationList } = useSelector((state: RootState) => ({
    applicationList: state.applicationList,
  }));

  useEffect(() => {
    dispatch(getApplicationList(config));
  }, [config]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    if (column === "appName") {
      // tslint:disable-next-line:no-parameter-reassignment
      column = "appNameAndVersion";
    }
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const openDetails = (row: ITableRow): void => {
    history.push(`/applicationClients/${row.appClientId}`);
  };

  const headCells: ITableHeaderCells[] = [
    { id: "appNameAndVersion", label: "App name", width: "2fr" },
    // { id: "appOwner", label: "App owner", value: (row: ITableRow) => row.appOwner.fullName },
    {
      id: "appOwner",
      label: "App owners",
      value: (row: ITableRow) => getAppOwnersCSV(row.appOwners),
    },
    { id: "status", label: "Status" },
  ];

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...applicationList,
      content: [
        ...applicationList.content.map((row: any) => ({
          ...row,
          appNameAndVersion: `${row.appName} ${row.appVersion}`,
        })),
      ],
      onClickRow: openDetails,
    },
    name: "brokerTypesDetailsAppClients",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const statusOptions = [
    {
      label: <span className="ta-dropdown-active">Active</span>,
      value: userListStatusEnum.ACTIVE,
    },
    {
      label: <span className="ta-dropdown-deleted">Deleted</span>,
      value: userListStatusEnum.DELETED,
    },
  ];

  const filterConfig: IFilterConfig = {
    items: [
      {
        name: "appNameAndVersion",
        placeholder: "Application name",
        taClass: "appNameAndVersion",
        type: FilterType.TEXT,
      },
      {
        name: "appOwner",
        placeholder: "Application owner",
        taClass: "appOwner",
        type: FilterType.TEXT,
      },
      {
        data: statusOptions,
        name: "status",
        placeholder: "Status",
        taClass: "status",
        type: FilterType.DROPDOWN,
      },
    ],
    pageName: "brokerTypesDetailsAppClients",
    returnFilter: (f: any) => {
      if (!deepEqual(config.filter, { ...emptyFilterObject, ...f })) {
        setConfig({ ...config, page: 0, filter: { ...emptyFilterObject, ...f } });
      }
    },
  };

  return (
    <>
      {/* FILTER */}
      <Filter {...filterConfig} />

      <SSpacer />

      {/* TABLE */}
      <Table {...tableProps} />
    </>
  );
};
