import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createTopic } from "../../actions/topic/create/actions";
import { isValidValue } from "../../helper/util";
import {
  maxPayloadSizeValues,
  offloadType,
  payloadFormatEnum,
  qosEnum,
  throttleThresholdFactorValues,
  topicStatusEnum,
  topicTypeEnum,
} from "../../models/ITopicConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer } from "../../styles/styles";
import { TopicValidationErrorCodeEnum as TopicNameError } from "../TopicValidatorUtil";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";
import { ModalCreateTopicPage1 } from "./ModalCreateTopicPage1";
import { ModalCreateTopicPage2 } from "./ModalCreateTopicPage2";
import { ModalCreateTopicTypeSelection } from "./ModalCreateTopicTypeSelection";

export const ModalCreateTopic = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);
  const { currentUser, editModal, payloadUpload } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    payloadUpload: state.payloadUpload,
  }));

  const [dialogTitle, setDialogTitle] = useState("Create new topic");
  const [currentPage, setCurrentPage] = useState(0);

  const [config, setConfig] = useState({
    accessAnonymous: false,
    appData: {
      appClientId: "",
      appName: "",
      appVersion: "",
    },
    changeComment: "",
    description: "",
    featureName: "",
    isSecure: false,
    localGroupBridge: "",
    maxPayloadSize: maxPayloadSizeValues.default,
    multicast: false,
    offloadType: offloadType.DEFERRED,
    payloadConfig: {
      payloadFile: File,
      payloadFileName: "",
      payloadFormat: payloadFormatEnum.JSON,
      payloadType: "",
      topicPayloadId: "",
    },
    priority: "1",
    qosLevel: qosEnum.ATLEAST_ONCE,
    retainRequired: false,
    schema: "",
    throttleThresholdFactor: throttleThresholdFactorValues.default,
    topic: {
      topicId: undefined,
      topicName: "",
      topicStatus: "",
    },
    topicType: topicTypeEnum.AIR,
    ttl: 0,
  });

  const changeCommentRequired = true; // topic create does require comment
  const [validPage, setValidPage] = useState([true, false, false, !changeCommentRequired]);
  const [isTopicNameValid, setIsTopicNameValid] = useState(TopicNameError.NO_ERROR);

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkValidity = () => {
    let topicSettingsValid = true; // no additional checking for airside only topic

    if (config.topicType === topicTypeEnum.A2G) {
      topicSettingsValid =
        isValidValue(config.maxPayloadSize, maxPayloadSizeValues) &&
        isValidValue(config.throttleThresholdFactor, throttleThresholdFactorValues);
    }

    const isFormValid =
      !!config.topic.topicName &&
      isTopicNameValid === TopicNameError.NO_ERROR &&
      !!config.appData.appClientId &&
      topicSettingsValid;

    handleSetValidPage(1, isFormValid);

    /*
    const isTopicOnHold = config.topic.topicStatus === topicStatusEnum.ONHOLD;
    if (
      config.retainRequired &&
      config.topic.topicName &&
      !isTopicOnHold &&
      isTopicNameValid === TopicNameError.NO_ERROR &&
      config.appData.appClientId
    ) {
      handleSetValidPage(1, true);
    } else {
      handleSetValidPage(1, false);
    }
*/
  };

  const checkPayloadInput = () => {
    const isPayloadPageValid =
      (config.payloadConfig.topicPayloadId && config.payloadConfig.topicPayloadId.length > 0) ||
      config.payloadConfig.payloadFileName.length > 0;

    handleSetValidPage(2, isPayloadPageValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(3, isCommentValid);
  };

  const handleSubmitNewTopic = () => {
    const submitData: any = {
      accessAnonymous: config.accessAnonymous,
      appClientId: config.appData.appClientId,
      changeComment: config.changeComment,
      dependencies: "",
      description: config.description,
      featureName: config.featureName,
      isSecure: config.isSecure,
      localGroupBridge: config.localGroupBridge,
      maxPayloadSize: config.maxPayloadSize,
      multicast: config.multicast,
      obfuscationRequirement: "",
      offloadType: config.offloadType,
      priority: config.priority,
      qosLevel: config.qosLevel,
      retainRequired: config.retainRequired,
      serviceName: "",
      throttleThresholdFactor: config.throttleThresholdFactor,
      topicCategory: "",
      // topicMqttClient: {
      //   accessLevel: "",
      //   frequency: "",
      //   retainRequired: config.retainRequired === retainRequiredEnum.YES,
      // },
      // make sure post topic name contains no uppercase chars
      topicName: config.topic && config.topic.topicName,
      topicPayloadId: config.payloadConfig.topicPayloadId,
      topicType: config.topicType,
      ttl: config.ttl,
      versionId: "1.0",
    };

    if (currentUser.data.role !== ADMINISTRATOR) {
      delete submitData.accessAnonymous;
    }

    dispatch(
      createTopic(submitData, (topicId: any) => {
        history.push(`/topics/${topicId}`);
      })
    );
  };

  /* tslint:disable:prefer-switch */
  useEffect(() => {
    if (currentPage === 1) {
      checkValidity();
    } else if (currentPage === 2) {
      checkPayloadInput();
    } else if (currentPage === 3) {
      checkCommentInput();
    }
  }, [config, isTopicNameValid]);
  /* tslint:enable:prefer-switch */

  useEffect(() => {
    if (currentPage === 0) {
      setDialogTitle("New topic");
    } else {
      const appLocation =
        config.topicType === topicTypeEnum.AIR ? "airside only" : "airside to groundside";
      setDialogTitle(`New ${appLocation} topic`);
    }
  }, [currentPage]);

  const modalTheme = {
    modal: {
      width: "640px", // Increase the width of the modal dialog
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {currentPage === 0 && (
          <ModalCreateTopicTypeSelection
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            nextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        )}

        {currentPage === 1 && (
          <ModalCreateTopicPage1
            appData={editModal.appData}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            setIsTopicNameValid={setIsTopicNameValid}
            isTopicNameValid={isTopicNameValid}
          />
        )}
        {currentPage === 2 && (
          <ModalCreateTopicPage2
            appData={editModal.appData}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            isEditing={isEditing}
            onToggleEditing={setIsEditing}
          />
        )}
        {currentPage === 3 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are creating this topic.`}
            commentRequired={changeCommentRequired}
          />
        )}
        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage > 1 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 0 || isEditing}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage === 1 || currentPage === 2 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)] || isEditing || payloadUpload.loading}
              >
                Next
              </ButtonPrimary>
            ) : currentPage === 3 ? (
              <ButtonPrimary
                className="ta-modal-save-button"
                onClick={handleSubmitNewTopic}
                disabled={!validPage[Number(currentPage)]}
              >
                Save
              </ButtonPrimary>
            ) : (
              ""
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
