import { Dispatch } from "redux";

import {
  IAircraftGroupListTableConfig,
  IGetAircraftGroupListData,
} from "../../../models/IAircraftGroup";
import { AircraftGroupService } from "../../../services/AircraftGroupService";

import { AircraftGroupListType, TAircraftGroupListActions } from "./types";

export const GetAircraftGroupListResetAction = (): TAircraftGroupListActions => ({
  type: AircraftGroupListType.RESET,
});

export const GetAircraftGroupListRequestAction = (): TAircraftGroupListActions => ({
  type: AircraftGroupListType.REQUEST,
});

export const GetAircraftGroupListSuccessAction = (data: any): TAircraftGroupListActions => ({
  payload: data,
  type: AircraftGroupListType.SUCCESS,
});

export const GetAircraftGroupListErrorAction = (error: string): TAircraftGroupListActions => ({
  payload: error,
  type: AircraftGroupListType.ERROR,
});

export const getAircraftGroupList = (conf: IAircraftGroupListTableConfig) => (
  dispatch: Dispatch<TAircraftGroupListActions>
) => {
  dispatch(GetAircraftGroupListRequestAction());
  AircraftGroupService.getAircraftGroupList(conf).then(
    (response: { data: { data: IGetAircraftGroupListData } }) => {
      dispatch(GetAircraftGroupListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetAircraftGroupListErrorAction(error))
  );
};
