export enum tabTenantDetailsEnum {
  AIRCRAFTS = "aircrafts",
  RACKS = "racks",
  GROUPS = "groups",
  GROUPS_INT = "groups_int",
  HISTORY = "history",
}

/*
export interface ITenantDetailsContent {
  accessAnonymous: boolean;
  appClient: IAppClient;
  createdAt: string;
  createdBy: IGenericSimpleUser;
  dependencies: string;
  description: string;
  featureName: string;
  isSecure: boolean;
  maxPayloadSize?: number;
  modifiedAt: string;
  modifiedBy: IGenericSimpleUser;
  multicast: boolean;
  obfuscationRequirement: number;
  offloadType: string;
  priority: number;
  qosLevel: number;
  retainRequired: boolean;
  serviceName: string;
  status: string;
  throttleThresholdFactor?: number;
  topicCategory: string;
  topicId: string;
  topicName: string;
  topicType: string;
  ttl: number;
  versionId: string;
}
*/
