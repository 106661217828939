enum AircraftTailNumberValidationErrorCodeEnum {
  // no Error
  NO_ERROR = 0,

  // length < 2
  LENGTH_LT_TWO = -21,

  // length too long, more than 10 or 11 characters
  LENGTH_TOO_LONG = -22,

  // invalid character(s)
  INVALID_CHARACTERS = -23,

  // === other error ===
  // TBD
}

/**
 * AircraftTailNumberValidatorUtil
 *
 * Validation rules are mostly based on information from:
 * https://confluence.panasonic.aero/pages/viewpage.action?spaceKey=ALST&title=Tails+-+Test+Aircraft+Registrations
 *
 */
// tslint:disable-next-line:no-unnecessary-class
class AircraftTailNumberValidatorUtil {
  /**
   * Validate tail number
   * @param tailNumber string
   */
  public static validateTailNumber(tailNumber: string): AircraftTailNumberValidationErrorCodeEnum {
    let errCode: AircraftTailNumberValidationErrorCodeEnum =
      AircraftTailNumberValidationErrorCodeEnum.NO_ERROR; // no error

    /**
     * Minimum tail number
     *
     * I cannot find any specification about minimum length, but RMT seems to have 2 character tail numbers.
     */
    if (!tailNumber || tailNumber.length < 2) {
      // throw new Error('Tail number must be at least 2 characters');
      errCode = AircraftTailNumberValidationErrorCodeEnum.LENGTH_LT_TWO;

      return errCode;
    }

    /**
     * Check if there is a dash
     */
    if (tailNumber.indexOf("-") === -1) {
      /* Without dash */

      /**
       * Maximum length
       */
      if (tailNumber.length > 10) {
        // throw new Error('Tail number too long');
        errCode = AircraftTailNumberValidationErrorCodeEnum.LENGTH_TOO_LONG;

        return errCode;
      }

      /**
       * Valid characters
       *
       * Alphanumberic characters
       */
      const validCharactersTest = /^[a-zA-Z0-9]+$/;
      if (!validCharactersTest.test(tailNumber)) {
        // throw new Error('Invalid tail number format or characters');
        errCode = AircraftTailNumberValidationErrorCodeEnum.INVALID_CHARACTERS;

        return errCode;
      }
    } else {
      /* With dash */

      /**
       * Maximum length
       */
      if (tailNumber.length > 11) {
        // throw new Error('Tail number too long');
        errCode = AircraftTailNumberValidationErrorCodeEnum.LENGTH_TOO_LONG;

        return errCode;
      }

      /**
       * Valid characters
       *
       * 1. Alphanumberic characters
       *
       * 2. Two components separated by a single dash
       *
       * 3. First component is up to 4 characters
       *
       * 4. Second component is up to 6 characters
       */
      const validCharactersTest = /^[a-zA-Z0-9]{1,4}-[a-zA-Z0-9]{1,6}$/;
      if (!validCharactersTest.test(tailNumber)) {
        // throw new Error('Invalid tail number format or characters');
        errCode = AircraftTailNumberValidationErrorCodeEnum.INVALID_CHARACTERS;

        return errCode;
      }
    }

    return errCode;
  }
}

export { AircraftTailNumberValidationErrorCodeEnum, AircraftTailNumberValidatorUtil };
