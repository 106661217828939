/* istanbul ignore file */
import { useCallback, useEffect, useRef } from "react";

/**
 * Hook that calls callback function if you click outside of an element.
 *
 * Usage:
 *
 * const ref = useOutsideClickDetector(() => ...);
 *
 * <MyElement ref={ref} />
 */

const useOutsideClickDetector = (cb: any) => {
  const nodeRef = useRef(null);

  const handleClick = useCallback(
    e => {
      if (!nodeRef.current) {
        return;
      }

      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore: Unreachable code error
      if (nodeRef.current.contains(e.target)) {
        return;
      }
      cb();
    },
    [nodeRef, cb]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);

    return () => {
      document.removeEventListener("mouseup", handleClick);
    };
  }, [handleClick]);

  return nodeRef;
};

export default useOutsideClickDetector;
