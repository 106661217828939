import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React from "react";

import { STallText, STextDataTitle, STextDataValue } from "../../../styles/styles";

export const BrokerTypesDetailsTabDetails = ({ content }: { content: any }) => (
  <>
    <Grid columns="repeat(3, auto)">
      <GridItem>
        <STallText variant="uiText" className="ta-broker-type-details-broker-type">
          <STextDataTitle>Broker type:</STextDataTitle>
          <STextDataValue>{content.brokerType}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-system-type">
          <STextDataTitle>System type:</STextDataTitle>
          <STextDataValue>{content.systemType}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-max-in-flight-messages">
          <STextDataTitle>Inflight messages (maximum):</STextDataTitle>
          <STextDataValue>{content.maxInFlightMessages}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-disconnect-delay">
          <STextDataTitle>Disconnect delay (seconds):</STextDataTitle>
          <STextDataValue>{content.disconnectDelay}</STextDataValue>
        </STallText>
      </GridItem>
      <GridItem>
        <STallText variant="uiText" className="ta-broker-type-details-path-to-ca">
          <STextDataTitle>Path to CA:</STextDataTitle>
          <STextDataValue>{content.pathToCa}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-path-to-private">
          <STextDataTitle>Path to private:</STextDataTitle>
          <STextDataValue>{content.pathToPrivate}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-path-to-cba-ica">
          <STextDataTitle>Path to CBA ICA:</STextDataTitle>
          <STextDataValue>{content.pathToCbaIca}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-lrus">
          <STextDataTitle>LRU Description:</STextDataTitle>
          <STextDataValue>{content.lruDescription}</STextDataValue>
        </STallText>
      </GridItem>
      <GridItem>
        <STallText variant="uiText" className="ta-broker-type-details-created-by">
          <STextDataTitle>Created by:</STextDataTitle>
          <STextDataValue>{content.createdBy && content.createdBy.fullName}</STextDataValue>
        </STallText>
        <STallText variant="uiText" className="ta-broker-type-details-created-at">
          <STextDataTitle>Created at:</STextDataTitle>
          <STextDataValue>
            {content.createdAt &&
              moment(content.createdAt)
                // .tz("America/Los_Angeles")
                .tz(moment.tz.guess())
                .format("MMM/DD/YYYY HH:mm")}
          </STextDataValue>
        </STallText>
      </GridItem>
    </Grid>
  </>
);
