import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateTopicTypes {
  ERROR = "CREATE_TOPIC_ERROR",
  REQUEST = "CREATE_TOPIC_REQUEST",
  SUCCESS = "CREATE_TOPIC_SUCCESS",
}

export interface ICreateTopicRequestAction extends IReduxBaseAction<CreateTopicTypes> {
  payload: any;
  type: CreateTopicTypes.REQUEST;
}

export interface ICreateTopicSuccessAction extends IReduxBaseAction<CreateTopicTypes> {
  payload: any;
  type: CreateTopicTypes.SUCCESS;
}

export interface ICreateTopicErrorAction extends IReduxBaseAction<CreateTopicTypes> {
  payload: any;
  type: CreateTopicTypes.ERROR;
}

export type TCreateTopicActions =
  | ICreateTopicErrorAction
  | ICreateTopicRequestAction
  | ICreateTopicSuccessAction;
