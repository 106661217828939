import { ButtonPrimary, Grid, GridItem, Heading } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { deleteApplication } from "../../../actions/application/delete/actions";
import { editAppOwners } from "../../../actions/application/edit-owners/actions";
import { EditApplication } from "../../../actions/application/edit/actions";
import { getApplicationDetails } from "../../../actions/application/get-details/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getAppOwnersCSV, isUserAppOwner } from "../../../helper/util";
import { authTypeEnum } from "../../../models/IAppDetailsConfig";
import { brokerLocationEnum } from "../../../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import {
  SActiveLink,
  SFlexContainer,
  SFlexItem,
  SSpacer,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { ModalEditAppOwners } from "../../modals/ModalEditAppOwners";

export const ApplicationDetailsTopSection = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [isActive, setIsActive] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (props.userData && props.appContent) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      setIsOwner(
        // props.appContent.appOwner && props.appContent.appOwner.userId === props.userData.userId
        props.appContent.appOwners && isUserAppOwner(props.userData, props.appContent.appOwners)
      );
      setIsActive(props.appContent.status === "ACTIVE");
    }
  }, [props]);

  const handleOpenEditModal = () => {
    dispatch(
      editModalOpenAction({ type: modalTypeEnum.EDIT_APP, appData: { ...props.appContent } })
    );
  };

  const handleDeleteApplication = (param: any) => {
    dispatch(editModalCloseAction());
    dispatch(deleteApplication(props.appContent.appClientId, param.changeComment));
  };

  const handleActivateApplication = (param: any) => {
    const appConfig = {
      appClientId: props.appContent.appClientId,
      appClientLocation: props.appContent.appClientLocation,
      appClientsSharedUsername: props.appContent.appClientsSharedUsername,
      appName: props.appContent.appName,
      appVersion: props.appContent.appVersion,
      authenticationType: authTypeEnum.CREDENTIALS,
      changeComment: param.changeComment,
      connRetryDelaySec: props.appContent.connRetryDelaySec,
      internal: props.appContent.internal,
      numConnRetries: props.appContent.numConnRetries,
      password: "",
      pathClientCa: "",
      pathToCa: "",
      status: "ACTIVE",
      systemName: "",
      userName: !!props.appContent.userName ? props.appContent.userName : "",
    };

    dispatch(
      EditApplication(appConfig, (appClientId: any) => {
        dispatch(editModalCloseAction());
        dispatch(getApplicationDetails(appClientId));
      })
    );
  };

  const askForConfirmation = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are deleting this application.",
          commentRequired: true,
          contentText: "Are you sure you want to delete this application?",
          // onCancel: handleCancel,
          onConfirm: handleDeleteApplication,
          title: `Delete application`,
        },
        type: modalTypeEnum.DELETE_APP,
      })
    );
  };

  const askForConfirmToRestore = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are restoring this application.",
          commentRequired: true,
          contentText:
            "Are you sure you want to restore this application? After restore, verify no conflict of application name/version with existing ones.",
          // onCancel: handleCancel,
          onConfirm: handleActivateApplication,
          title: `Restore application`,
        },
        type: modalTypeEnum.DELETE_APP, // It's OK to share the same dialog Id with 'Delete'
      })
    );
  };

  const submitSuccessCallback = (appClientId: any) => {
    dispatch(editModalCloseAction());
    dispatch(getApplicationDetails(appClientId));
  };

  const handleEditAppOwners = (param: any) => {
    dispatch(
      editAppOwners({ ...param, appClientId: props.appContent.appClientId }, (appClientId: any) => {
        submitSuccessCallback(appClientId);
      })
    );
  };

  const handleOpenEditAppOwners = () => {
    dispatch(
      editModalOpenAction({
        appData: { ...props.appContent },
        popupData: {
          //   commentHint: "Please enter a meaningful comment about why you delete this application.",
          //   commentRequired: true,
          //   contentText: "Are you sure you want to delete this application?",
          onConfirm: handleEditAppOwners,
          //   title: `Edit app owners`,
        },
        type: modalTypeEnum.EDIT_APP_OWNERS,
        userData: { ...props.userData },
      })
    );
  };

  const getAppOwnersValue = () =>
    props.appContent.appOwners ? (
      <>
        <SActiveLink title="Click to edit app owners" onClick={handleOpenEditAppOwners}>
          Edit
        </SActiveLink>
        <SVerticalSpacer />
        {getAppOwnersCSV(props.appContent.appOwners)}
        {/* <SVerticalSpacer />
        <SActiveLink title="Click to edit app owners" onClick={handleOpenEditAppOwners}>
          Edit owners
        </SActiveLink> */}
      </>
    ) : (
      <>
        <SActiveLink title="Click to edit app owners" onClick={handleOpenEditAppOwners}>
          Edit
        </SActiveLink>
      </>
    );

  return (
    <>
      <Grid>
        <GridItem>
          <SFlexContainer justifyContent="space-between">
            <SFlexItem>
              <Heading variant="heading2">
                <span className={getTAClass("applicationDetails", TA_TYPES.TITLE, "appName")}>
                  {props.appContent.appName}
                </span>{" "}
                <span className={getTAClass("applicationDetails", TA_TYPES.TITLE, "version")}>
                  {props.appContent.appVersion}
                </span>
              </Heading>
            </SFlexItem>
            <SFlexItem>
              <ButtonPrimary
                disabled={!(isActive && (isOwner || isAdmin))}
                className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "edit")}
                onClick={handleOpenEditModal}
              >
                Edit
              </ButtonPrimary>
              <SVerticalSpacer />
              <ButtonPrimary
                disabled
                className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "generate")}
              >
                Generate Config
              </ButtonPrimary>
              <SVerticalSpacer />
              <ButtonPrimary
                disabled={!(isActive && (isOwner || isAdmin))}
                className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "delete")}
                onClick={askForConfirmation}
              >
                Delete
              </ButtonPrimary>
              {!isActive && isAdmin && (
                <div>
                  <SVerticalSpacer />
                  <ButtonPrimary
                    className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "restore")}
                    onClick={askForConfirmToRestore}
                  >
                    Restore
                  </ButtonPrimary>
                </div>
              )}
            </SFlexItem>
          </SFlexContainer>
        </GridItem>
      </Grid>

      <SSpacer />

      <Grid>
        <GridItem>
          <SFlexContainer justifyContent="space-between">
            <SFlexItem>
              <DataDetailLabel
                title="Owners"
                value={props.appContent.appOwners && getAppOwnersCSV(props.appContent.appOwners)}
                // value={getAppOwnersValue()}
                className={getTAClass("applicationDetails", TA_TYPES.TEXT, "owner")}
              ></DataDetailLabel>
            </SFlexItem>
            <SFlexItem>
              <ButtonPrimary
                disabled={!(isActive && (isOwner || isAdmin))}
                className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "edit_owners")}
                onClick={handleOpenEditAppOwners}
              >
                Edit owners
              </ButtonPrimary>
            </SFlexItem>
          </SFlexContainer>
        </GridItem>
      </Grid>

      <Grid columns="repeat(2, auto)">
        <GridItem>
          <DataDetailLabel
            title="Status"
            value={props.appContent && props.appContent.status}
            className={getTAClass("applicationDetails", TA_TYPES.TEXT, "status")}
          ></DataDetailLabel>
          <DataDetailLabel
            title="Location"
            value={
              props.appContent &&
              props.appContent.appClientLocation &&
              props.appContent.appClientLocation === brokerLocationEnum.GROUNDSIDE
                ? "Groundside"
                : "Airside"
            }
            className={getTAClass("applicationDetails", TA_TYPES.TEXT, "location")}
          />
        </GridItem>
        <GridItem>
          <DataDetailLabel
            title="Application ID"
            value={props.appContent && props.appContent.appClientId}
            className={getTAClass("applicationDetails", TA_TYPES.TEXT, "appClientId")}
          ></DataDetailLabel>
        </GridItem>
      </Grid>

      {props.editModal &&
        props.editModal.open &&
        props.editModal.type === modalTypeEnum.EDIT_APP_OWNERS && <ModalEditAppOwners />}
    </>
  );
};
