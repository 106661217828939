import { Tooltip, withStyles } from "@material-ui/core";

export const STooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    margin: "0 4px", // Adjust the margin to move the tooltip closer to the target
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(Tooltip);
