import {
  IGetTenantDetailsActionType,
  TGetTenantDetails,
} from "../../actions/tenant/get-details/types";

interface ITenantDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: ITenantDetailsState = {
  content: [],
  error: false,
  loading: true,
};

export const tenantDetails = (
  state: ITenantDetailsState = initialState,
  action: IGetTenantDetailsActionType
): ITenantDetailsState => {
  switch (action.type) {
    case TGetTenantDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetTenantDetails.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetTenantDetails.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
