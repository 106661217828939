import { Dispatch } from "redux";

import { AircraftService } from "../../../services/AircraftService";

import {
  IGetAircraftDetailsActionType,
  IGetAircraftDetailsErrorAction,
  IGetAircraftDetailsRequestAction,
  IGetAircraftDetailsSuccessAction,
  TGetAircraftDetails,
} from "./types";

export const GetAircraftDetailsRequestAction = (): IGetAircraftDetailsRequestAction => ({
  type: TGetAircraftDetails.REQUEST,
});
export const GetAircraftDetailsSuccessAction = (data: any): IGetAircraftDetailsSuccessAction => ({
  payload: data,
  type: TGetAircraftDetails.SUCCESS,
});
export const GetAircraftDetailsErrorAction = (error: any): IGetAircraftDetailsErrorAction => ({
  payload: error,
  type: TGetAircraftDetails.ERROR,
});

// GET Aaircraft details
export const getAircraftDetails = (aircraftId: any) => (
  dispatch: Dispatch<IGetAircraftDetailsActionType>
) => {
  dispatch(GetAircraftDetailsRequestAction());
  AircraftService.getAircraftDetails(aircraftId).then(
    (data: { data: any }) => {
      dispatch(GetAircraftDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(GetAircraftDetailsErrorAction(error))
  );
};
