import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { tabAircraftDetailsEnum } from "../../../models/IAircraftDetails";

export const AircraftDetailsTabs = (props: { activeTab: any; onChange: any }) => (
  <Grid columns="0.6fr">
    <GridItem>
      <TabContainer>
        <Tab
          className="ta-aircraft-details-tab-groups"
          onClick={() => {
            props.onChange(tabAircraftDetailsEnum.GROUPS);
          }}
          active={props.activeTab === tabAircraftDetailsEnum.GROUPS}
        >
          Groups
        </Tab>
        <Tab
          className="ta-aircraft-details-tab-details"
          onClick={() => {
            props.onChange(tabAircraftDetailsEnum.DETAILS);
          }}
          active={props.activeTab === tabAircraftDetailsEnum.DETAILS}
        >
          Details
        </Tab>
        {/* <Tab
          className="ta-aircraft-details-tab-history"
          onClick={() => {
            props.onChange(tabAircraftDetailsEnum.HISTORY);
          }}
          active={props.activeTab === tabAircraftDetailsEnum.HISTORY}
        >
          Change history
        </Tab> */}
      </TabContainer>
    </GridItem>
  </Grid>
);
