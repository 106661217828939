import moment from "moment-timezone";
import {
  ButtonPrimary,
  colors,
  Grid,
  GridItem,
  Heading,
  ProgressIndicatorLinear,
  Text,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationList } from "../../../actions/application/get-list/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { addTopicPermissionApp } from "../../../actions/topic/add-permission-app/actions";
import { deleteTopicPermissionApp } from "../../../actions/topic/delete-permission-app/actions";
import { editTopicPermission } from "../../../actions/topic/edit-permission/actions";
import { getTopicDetails } from "../../../actions/topic/get-details/actions";
import { getTopicPermissionApps } from "../../../actions/topic/get-permission-apps/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getTenantLabel, isUserAppOwner } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { userTenantEnum } from "../../../models/ITenant";
import { topicStatusEnum } from "../../../models/ITopicConfig";
import { topicPermissionEnum, topicPermissionTypeEnum } from "../../../models/ITopicDetailsConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import {
  SFlexContainer,
  SFlexItem,
  SModalStackItem,
  SNextFixedHeightContainer,
  SNextFixedWidthContainer,
  SOverlay,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { Table } from "../../Table";
import { Typeahead } from "../../Typeahead";

export const TopicDetailsPermissionsAllowedApps = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { applicationList } = useSelector((state: any) => ({
    applicationList: state.applicationList,
  }));

  const [isEditable, setIsEditable] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [classType, setClassType] = useState("");

  const [config, setConfig] = useState({
    filter: { appName: "" },
    limit: 5,
    orderBy: "",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      if (props.topicData && props.topicData.appClient) {
        setIsOwner(
          props.topicData.appClient.appOwners &&
            isUserAppOwner(props.userData, props.topicData.appClient.appOwners)
        );
        setIsEditable(
          props.topicData.status === topicStatusEnum.DRAFT ||
            props.topicData.status === topicStatusEnum.ACTIVE
        );
      }
    }
    setClassType(props.permissionType);
  }, [props]);

  const processGetTopicPermissionApps = (topicId: any, permType: topicPermissionTypeEnum) => {
    dispatch(getTopicPermissionApps(topicId, config, permType));
  };

  useEffect(() => {
    if (isEditable && (isOwner || isAdmin) && props.topicData) {
      processGetTopicPermissionApps(props.topicData.topicId, props.permissionType);
    }
  }, [config, isEditable, isAdmin, isOwner]);

  const submitSuccessCallback = (topicId: any) => {
    dispatch(editModalCloseAction());
    dispatch(getTopicDetails(topicId));
  };

  const handleEditTopicPermission = (param: any) => {
    if (props.topicData) {
      dispatch(
        editTopicPermission(
          { ...param },
          props.topicData.topicId,
          props.permissionType,
          submitSuccessCallback
        )
      );
    }
  };

  const handleOpenEditTopicPermission = () => {
    dispatch(
      editModalOpenAction({
        permissionType: props.permissionType,
        popupData: {
          //   commentHint: "Please enter a meaningful comment about why you delete this application.",
          //   commentRequired: true,
          //   contentText: "Are you sure you want to delete this application?",
          onConfirm: handleEditTopicPermission,
          //   title: `Edit app owners`,
        },
        topicData: { ...props.topicData },
        type: modalTypeEnum.EDIT_TOPIC_PERMISSION,
        userData: { ...props.userData },
      })
    );
  };

  const handleAddTopicAllowedApp = (appId: string) => {
    if (!!appId) {
      dispatch(
        addTopicPermissionApp(
          props.topicData.topicId,
          { appId, changeComment: "" },
          props.permissionType,
          processGetTopicPermissionApps
        )
      );
    }
  };

  const handleTopicPermissionsTypeaheadInput = (app: any) => {
    if (!!app.appClientId) {
      let isSelected = false;
      if (props.topicAllowedApps.content.length > 0) {
        isSelected = props.topicAllowedApps.content.some(
          (whiteApp: any) => whiteApp.appClientId === app.appClientId
        );
      }

      if (!isSelected) {
        handleAddTopicAllowedApp(app.appClientId);
      }
    }
  };

  const handleDeleteTopicAllowedApp = (appId: string) => {
    if (!!appId) {
      dispatch(
        deleteTopicPermissionApp(
          props.topicData.topicId,
          { appId, changeComment: "" },
          props.permissionType,
          processGetTopicPermissionApps
        )
      );
    }
  };

  const getTopicPermissionValue = (topicData: any, permType: topicPermissionTypeEnum) => {
    const permission =
      permType === topicPermissionTypeEnum.PUBLISH
        ? topicData.publishPermission
        : topicData.subscribePermission;
    switch (permission) {
      case topicPermissionEnum.OWNER_APPS:
        return "Owner applications";

      case topicPermissionEnum.TENANT_APPS:
        return `${getTenantLabel(topicData.appClient)} applications`;

      case topicPermissionEnum.ALL_APPS:
        return "All applications";

      default:
        return "";
    }
  };

  const getIncludePacAppsValue = (topicData: any, permType: topicPermissionTypeEnum) => {
    const permission =
      permType === topicPermissionTypeEnum.PUBLISH
        ? topicData.publishPermission
        : topicData.subscribePermission;
    const isAllowedForPac =
      permType === topicPermissionTypeEnum.PUBLISH
        ? topicData.isPublishableForPac
        : topicData.isSubscribableForPac;
    if (
      permission === topicPermissionEnum.OWNER_APPS ||
      permission === topicPermissionEnum.TENANT_APPS
    ) {
      if (isAllowedForPac && topicData.appClient.tenant && !!topicData.appClient.tenant.tenantId) {
        if (topicData.appClient.tenant.tenantId !== userTenantEnum.PAC) {
          return ", PAC applications";
        }
      }
    }

    return "";
  };

  const isRowDisabled = (row: ITableRow) => !(isEditable && (isOwner || isAdmin));

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  useEffect(() => {
    if (
      props.topicAllowedApps.count > 0 &&
      props.topicAllowedApps.count <= config.page * config.limit
    ) {
      paginationCallback(config.page - 1);
    }
  }, [props.topicAllowedApps]);

  const headCells: ITableHeaderCells[] = [
    {
      id: "appName",
      label: "App name",
      value: (row: ITableRow) => `${row.appName} ${row.appVersion}`,
      width: "3fr",
    },
    { id: "delete", label: "Delete" },
  ];

  const tableProps: any = {
    componentName: `selected-addl-${classType}-apps`,
    deleteCell: {
      as: "a",
      disabled: isRowDisabled,
      onClick: (row: ITableRow) => {
        if (!isRowDisabled(row)) {
          handleDeleteTopicAllowedApp(row.appClientId);
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: {
      ...props.topicAllowedApps,
    },
    name: `selected-addl-${classType}-apps`,
    noHeader: true,
    noPagination: props.topicAllowedApps.count <= config.limit,
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    rowStriped: "none",
  };

  return (
    <>
      {/* <SFlexContainer justifyContent="space-between"> */}
      <SFlexItem>
        <Heading variant="heading3">
          <span className={getTAClass("topic_details_permissions_", TA_TYPES.TITLE, classType)}>
            {props.permissionType === topicPermissionTypeEnum.PUBLISH
              ? `Publish permission:`
              : `Subscribe permission:`}
          </span>
        </Heading>
        <SVerticalSpacer />
        <STextDataValue>
          {getTopicPermissionValue(props.topicData, props.permissionType)}
          {getIncludePacAppsValue(props.topicData, props.permissionType)}
        </STextDataValue>
      </SFlexItem>

      <SSpacer height="8px" />

      <SFlexItem>
        <ButtonPrimary
          disabled={!(isEditable && (isOwner || isAdmin))}
          className={getTAClass("topic_details_permissions_", TA_TYPES.BUTTON, `edit_${classType}`)}
          onClick={handleOpenEditTopicPermission}
        >
          {props.permissionType === topicPermissionTypeEnum.PUBLISH
            ? `Edit publish permission`
            : `Edit subscribe permission`}
        </ButtonPrimary>
      </SFlexItem>
      {/* </SFlexContainer> */}

      <SSpacer height="24px" />

      <SFlexContainer justifyContent="flex-start">
        <SVerticalSpacer width="24px" />
        <STextDataTitle>Additional applications</STextDataTitle>
      </SFlexContainer>

      <SSpacer height="8px" />

      <SNextFixedWidthContainer paddingLeft="24px" minWidth="320px" maxWidth="800px">
        {/* <SVerticalSpacer width="24px" /> */}
        {/* <SModalStackItem> */}
        {/* <Grid padding="4px" columns="1fr 1fr"> */}
        <Grid columns="2fr 3fr">
          <GridItem>
            <Text
              variant="captionText"
              className={getTAClass(
                "topic_details_permissions_",
                TA_TYPES.TITLE,
                `select-addl-${classType}-apps`
              )}
            >
              Select additional application:
            </Text>

            <SSpacer height="8px" />

            <div
              className={getTAClass(
                "topic_details_permissions_",
                TA_TYPES.INPUT,
                `addl-${classType}-apps`
              )}
            >
              <Typeahead
                pageSelector="topic-permissions-addl-apps"
                disabled={!(isEditable && (isOwner || isAdmin))}
                config={{
                  default: {
                    filter: {
                      //   email: "",
                      //   firstName: "",
                      appName: "",
                      //   lastName: "",
                      //   role: "",
                      //   userId: "",
                    },
                    orderBy: "appName",
                  },
                  setFilter: (value: string | any) =>
                    typeof value === "string" ? { appName: value } : { appName: value.appName },
                }}
                suggestion={{
                  getList: (conf: any) => dispatch(getApplicationList(conf)),
                  list: applicationList,
                  select: (app: any) => `${app.appName}`,
                }}
                placeholder="Additional application name"
                name="additionalApp"
                value={""}
                hasSelected={(p: any) => true}
                handleTypeahead={handleTopicPermissionsTypeaheadInput}
                pattern="{appName} {appVersion}"
                //   minInputLen={2}
                showListOnFocus={true}
                clearOnSelect={true}
              />
            </div>

            <SSpacer height="200px" />
          </GridItem>

          <GridItem>
            <Text
              variant="captionText"
              className={getTAClass(
                "topic_details_permissions_",
                TA_TYPES.TITLE,
                `selected-addl-${classType}-apps`
              )}
            >
              {`Selected additional applications (total: ${props.topicAllowedApps.count})`}
              {/* Selected additional apps: */}
            </Text>

            <SSpacer height="8px" />

            {/* LOADING */}
            {props.topicAllowedApps.loading ? (
              <SOverlay>
                <ProgressIndicatorLinear />
              </SOverlay>
            ) : (
              <SSpacer height="2px" color={colors.greys.lighten25} />
            )}
            {/* <SNextFixedHeightContainer maxHeight="calc(6.5 * 48px)" overflow="auto"> */}
            <Table {...tableProps} />
            {/* </SNextFixedHeightContainer> */}
          </GridItem>
        </Grid>
        {/* </SModalStackItem> */}
      </SNextFixedWidthContainer>
    </>
  );
};
