import { Dispatch } from "redux";

import { AircraftGroupService } from "../../../services/AircraftGroupService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateAircraftGroupTypes,
  ICreateAircraftGroupErrorAction,
  ICreateAircraftGroupRequestAction,
  ICreateAircraftGroupSuccessAction,
  TCreateAircraftGroupActions,
} from "./types";

export const CreateAircraftGroupRequestAction = (data: any): ICreateAircraftGroupRequestAction => ({
  payload: data,
  type: CreateAircraftGroupTypes.REQUEST,
});
export const CreateAircraftGroupSuccessAction = (data: any): ICreateAircraftGroupSuccessAction => ({
  payload: data,
  type: CreateAircraftGroupTypes.SUCCESS,
});
export const CreateAircraftGroupErrorAction = (error: any): ICreateAircraftGroupErrorAction => ({
  payload: error,
  type: CreateAircraftGroupTypes.ERROR,
});

export const createAircraftGroup = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreateAircraftGroupActions | TEditModalActions>
) => {
  dispatch(CreateAircraftGroupRequestAction(config));
  AircraftGroupService.createAircraftGroup(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateAircraftGroupSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Aircraft group created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.aircraftGroupId);
    },
    (error: any) => {
      dispatch(CreateAircraftGroupErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
