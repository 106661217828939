/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum DestinationRouteListSelectType {
  REQUEST = "GET_DESTINATION_ROUTES_SELECT_REQUEST",
  SUCCESS = "GET_DESTINATION_ROUTES_SELECT_SUCCESS",
  ERROR = "GET_DESTINATION_ROUTES_SELECT_ERROR",
  CLEAR = "GET_DESTINATION_ROUTES_SELECT_CLEAR",
}

export interface IDestinationRouteListSelectRequestAction
  extends IReduxBaseAction<DestinationRouteListSelectType> {
  type: DestinationRouteListSelectType.REQUEST;
}

export interface IDestinationRouteListSelectClearAction
  extends IReduxBaseAction<DestinationRouteListSelectType> {
  type: DestinationRouteListSelectType.CLEAR;
}

export interface IDestinationRouteListSelectSuccessAction
  extends IReduxBaseAction<DestinationRouteListSelectType> {
  payload: any;
  type: DestinationRouteListSelectType.SUCCESS;
}

export interface IDestinationRouteListSelectErrorAction
  extends IReduxBaseAction<DestinationRouteListSelectType> {
  payload: string;
  type: DestinationRouteListSelectType.ERROR;
}

export type TDestinationRouteListSelectActions =
  | IDestinationRouteListSelectRequestAction
  | IDestinationRouteListSelectSuccessAction
  | IDestinationRouteListSelectErrorAction
  | IDestinationRouteListSelectClearAction;
