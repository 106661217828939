import {
  DestinationRouteListType,
  TDestinationRouteListActions,
} from "../../actions/destination/get-destination-routes/types";

interface IDestRouteListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IDestRouteListState = {
  content: [],
  count: 0,
  error: false,
  loading: false,
};

export const appDestinationRouteList = (
  state: IDestRouteListState = initialState,
  action: TDestinationRouteListActions
): IDestRouteListState => {
  switch (action.type) {
    case DestinationRouteListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case DestinationRouteListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case DestinationRouteListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case DestinationRouteListType.CLEAR:
      return {
        ...state,
        content: [],
        error: false,
        loading: false,
      };
    default:
      return state;
  }
};
