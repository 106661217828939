/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTopicListData } from "../../../models/ITopicConfig";

export enum AppTopicFullListType {
  CLEAR = "APP_TOPIC_FULL_LIST_CLEAR",
  REQUEST = "APP_TOPIC_FULL_LIST_REQUEST",
  SUCCESS = "APP_TOPIC_FULL_LIST_SUCCESS",
  ERROR = "APP_TOPIC_FULL_LIST_ERROR",
}

export interface IAppTopicFullClearAction extends IReduxBaseAction<AppTopicFullListType> {
  type: AppTopicFullListType.CLEAR;
}

export interface IAppTopicFullRequestAction extends IReduxBaseAction<AppTopicFullListType> {
  type: AppTopicFullListType.REQUEST;
}

export interface IAppTopicFullSuccessAction extends IReduxBaseAction<AppTopicFullListType> {
  payload: IGetTopicListData;
  type: AppTopicFullListType.SUCCESS;
}

export interface IAppTopicFullErrorAction extends IReduxBaseAction<AppTopicFullListType> {
  payload: string;
  type: AppTopicFullListType.ERROR;
}

export type TAppTopicFullListActions =
  | IAppTopicFullRequestAction
  | IAppTopicFullSuccessAction
  | IAppTopicFullErrorAction
  | IAppTopicFullClearAction;
