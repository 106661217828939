import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetTenantDetails {
  ERROR = "TENANT_GETDETAILS_ERROR",
  REQUEST = "TENANT_GETDETAILS_REQUEST",
  SUCCESS = "TENANT_GETDETAILS_SUCCESS",
}

export interface IGetTenantDetailsRequestAction extends IReduxBaseAction<TGetTenantDetails> {
  type: TGetTenantDetails.REQUEST;
}

export interface IGetTenantDetailsSuccessAction extends IReduxBaseAction<TGetTenantDetails> {
  payload: any;
  type: TGetTenantDetails.SUCCESS;
}

export interface IGetTenantDetailsErrorAction extends IReduxBaseAction<TGetTenantDetails> {
  payload: any;
  type: TGetTenantDetails.ERROR;
}

export type IGetTenantDetailsActionType =
  | IGetTenantDetailsRequestAction
  | IGetTenantDetailsSuccessAction
  | IGetTenantDetailsErrorAction;
