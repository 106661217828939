import { Grid, GridItem, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import {
  SFlexContainer,
  SFlexItem,
  SModalStackItem,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ButtonPrimary } from "../next-component-fork";

export const ModalCreateApplicationLocationSelection = (props: any) => {
  const [updatedConfig, setUpdatedConfig] = useState();

  const handleAppLocationChange = (value: any) => {
    setUpdatedConfig({ ...props.config, appClientLocation: value });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
      props.nextPage();
    }
  }, [updatedConfig]);

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-choose-location">
        Choose application location:
      </Text>

      <SSpacer height="6px" />

      <Grid columns="1fr 1fr">
        <GridItem>
          <SFlexContainer justifyContent="center">
            <ButtonPrimary
              buttonWidth="96%"
              className={getTAClass("createApp", TA_TYPES.BUTTON, "location-air")}
              onClick={() => {
                handleAppLocationChange(brokerLocationEnum.AIRSIDE);
              }}
            >
              Airside application client
            </ButtonPrimary>
          </SFlexContainer>
        </GridItem>

        {/* <GridItem>
            <SVerticalSpacer />
        </GridItem> */}

        <GridItem>
          <SFlexContainer justifyContent="center">
            <ButtonPrimary
              buttonWidth="96%"
              className={getTAClass("createApp", TA_TYPES.BUTTON, "location-ground")}
              onClick={() => {
                handleAppLocationChange(brokerLocationEnum.GROUNDSIDE);
              }}
            >
              Groundside application client
            </ButtonPrimary>
          </SFlexContainer>
        </GridItem>
      </Grid>

      <SSpacer height="12px" />
    </>
  );
};
