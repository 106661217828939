import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getTopologies } from "../actions/topology/actions";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../models/ITableConfig";
import { RootState } from "../store";

import { Table } from "./Table";

export const TopologyList = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { topologyList } = useSelector((state: any) => ({
    topologyList: state.topologyList,
  }));

  const [config, setConfig] = useState({
    limit: 10,
    orderBy: "brokerTopologyName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    dispatch(getTopologies(config));
  }, [config]);

  const headCells: ITableHeaderCells[] = [
    {
      id: "brokerTopologyId",
      label: "Topology ID",
    },
    {
      id: "brokerTopologyName",
      label: "Topology name",
    },
    {
      id: "parentBrokerType",
      label: "From parent",
      value: (row: ITableRow) => row.parentBrokerType.brokerType,
    },
    {
      id: "remoteBrokerType",
      label: "To remote",
      value: (row: ITableRow) => row.remoteBrokerType.brokerType,
    },
  ];

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...topologyList,
    },
    name: "topology",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return <Table {...tableProps}></Table>;
};
