import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";
import { RootState } from "../../../store";

interface IAircraftEnvState {
  active: string | undefined;
}

const initialState: IAircraftEnvState = {
  active: GroundEnvironmentTypeEnum.PRODUCTION,
};

const aircraftEnvSlice = createSlice({
  initialState,
  name: "aircraftEnv",
  reducers: {
    updateAircraftEnv(state: IAircraftEnvState, action: PayloadAction<string | undefined>): void {
      state.active = action.payload;
    },
  },
});

export const selectAircraftEnv = (state: RootState): any => state.aircraftEnv.active;

export const { updateAircraftEnv } = aircraftEnvSlice.actions;

export default aircraftEnvSlice;
