import {
  IDeleteApplicationActionTypes,
  TDeleteApplication,
} from "../../actions/application/delete/types";

interface IApplicationDeleteState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: IApplicationDeleteState = {
  content: [],
  error: false,
  loading: true,
};

export const applicationDelete = (
  state: IApplicationDeleteState = initialState,
  action: IDeleteApplicationActionTypes
): IApplicationDeleteState => {
  switch (action.type) {
    // DELETE
    case TDeleteApplication.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TDeleteApplication.SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case TDeleteApplication.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
