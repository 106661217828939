import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { SPageContainer, SPageHeading } from "../../styles/styles";

export const Error = () => {
  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  return (
    <SPageContainer>
      <SPageHeading>Error</SPageHeading>
      <p>{currentUser.error ? currentUser.data && currentUser.data.message : ""}</p>
      <Link to="/">Try again...</Link>
    </SPageContainer>
  );
};
