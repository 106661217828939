import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetPayloadTypes {
  ERROR = "GET_PAYLOAD_ERROR",
  REQUEST = "GET_PAYLOAD_REQUEST",
  SUCCESS = "GET_PAYLOAD_SUCCESS",
}

export interface IGetPayloadRequestAction extends IReduxBaseAction<GetPayloadTypes> {
  payload: any;
  type: GetPayloadTypes.REQUEST;
}

export interface IGetPayloadSuccessAction extends IReduxBaseAction<GetPayloadTypes> {
  payload: any;
  type: GetPayloadTypes.SUCCESS;
}

export interface IGetPayloadErrorAction extends IReduxBaseAction<GetPayloadTypes> {
  payload: any;
  type: GetPayloadTypes.ERROR;
}

export type TGetPayloadActions =
  | IGetPayloadErrorAction
  | IGetPayloadRequestAction
  | IGetPayloadSuccessAction;
