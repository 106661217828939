import { Dispatch } from "redux";

import { UserService } from "../../../services/UserService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";
import { getUserDetails } from "../get-details/actions";

import {
  IUserSetDetailsErrorAction,
  IUserSetDetailsRequestAction,
  IUserSetDetailsSuccessAction,
  TSetUsetDetailsActions,
  UserSetDetailsTypes,
} from "./types";

export const userSetDetailsRequestAction = (data: any): IUserSetDetailsRequestAction => ({
  payload: data,
  type: UserSetDetailsTypes.REQUEST,
});
export const userSetDetailsSuccessAction = (data: any): IUserSetDetailsSuccessAction => ({
  payload: data,
  type: UserSetDetailsTypes.SUCCESS,
});
export const userSetDetailsErrorAction = (error: any): IUserSetDetailsErrorAction => ({
  payload: error,
  type: UserSetDetailsTypes.ERROR,
});

export const setUserDetails = (userData: any, successMessage?: string) => (
  dispatch: Dispatch<TPopupActions | TSetUsetDetailsActions | TEditModalActions>
) => {
  dispatch(userSetDetailsRequestAction(userData));
  UserService.setUserDetails(userData).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(getUserDetails(userData.userId));
      dispatch(userSetDetailsSuccessAction(data.data));
      dispatch(editModalCloseAction());
      dispatch(
        popupOpenAction({
          content: successMessage ? successMessage : "User updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
    },
    (error: any) => {
      dispatch(userSetDetailsErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
