import {
  ButtonIcon,
  ButtonPrimary,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
  ProgressIndicatorLinear,
  Tab,
  TabContainer,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { addAircraftsToGroups } from "../../actions/aircraftgroup/add-aircrafts-to-groups/actions";
import { removeAircraftsFromGroups } from "../../actions/aircraftgroup/remove-aircrafts-from-groups/actions";
import { getApplicationDetails } from "../../actions/application/get-details/actions";
import { createAppDestination } from "../../actions/destination/create/actions";
import { editAppDestination } from "../../actions/destination/edit/actions";
import { getAppDestinationDetails } from "../../actions/destination/get-details/actions";
import { editModalCloseAction, editModalOpenAction } from "../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { isUserAppOwner } from "../../helper/util";
import { AircraftTypeEnum } from "../../models/IAircraft";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import {
  GroundDestinationTypeEnum,
  GroundEnvironmentTypeEnum,
  newDestinationId,
  tabDestinationDetailsEnum,
} from "../../models/IDestination";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SActiveLink,
  SFlexContainer,
  SFlexItem,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextDataValue2,
  SVerticalSpacer,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { ModalDisplaySQSConfigInfo } from "../modals/ModalDisplaySQSConfigInfo";
import { ModalEditAppDestination } from "../modals/ModalEditAppDestination";
import { ModalEditComment } from "../modals/ModalEditComment";
import { ModalSelectRouteGroup } from "../modals/ModalSelectRouteGroup";
import { PageError, PageLoading } from "../PageState";

import { AppDestinationSources } from "./ApplicationClientDetails/AppDestinationSources";

/* tslint:disable:cyclomatic-complexity */
export const DestinationDetails = ({ history, match }: { history: any; match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const { appDestinationDetails, applicationDetails, currentUser, editModal } = useSelector(
    (state: RootState) => ({
      appDestinationDetails: state.appDestinationDetails,
      applicationDetails: state.applicationDetails,
      currentUser: state.currentUser,
      editModal: state.editModal,
    })
  );

  const [needRefresh, setNeedRefresh] = useState(0);
  const [detailsDestId, setDetailsDestId] = useState("");
  const [detailsAppId, setDetailsAppId] = useState("");
  const [newDestEnv, setNewDestEnv] = useState("");
  const [newDestIndex, setNewDestIndex] = useState(0);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [activeTab, setActiveTab] = useState(tabDestinationDetailsEnum.SOURCE_GROUPS);

  useEffect(() => {
    if (!!match.params.destId && match.params.destId !== newDestinationId) {
      setDetailsDestId(match.params.destId);
    }

    // find appId if specified
    const queryParams = new URLSearchParams(history.location.search);
    let paramVal = queryParams.get("appId");
    if (!!paramVal) {
      setDetailsAppId(paramVal);
    }
    paramVal = queryParams.get("env");
    if (!!paramVal) {
      setNewDestEnv(paramVal);
    }
    paramVal = queryParams.get("idx");
    if (!!paramVal) {
      setNewDestIndex(Number(paramVal));
    }
  }, [match.params.destId]);

  useEffect(() => {
    if (
      !!detailsAppId &&
      match.params.destId === newDestinationId &&
      appDestinationDetails.content.appClient === undefined
    ) {
      // return to app details page due to reload
      navigateToPage(`/applicationClients/${detailsAppId}`);
    }

    if (!!detailsAppId) {
      dispatch(getApplicationDetails(detailsAppId));

      if (!!detailsDestId) {
        dispatch(getAppDestinationDetails(detailsAppId, detailsDestId));
      }
    }
  }, [detailsAppId, detailsDestId, needRefresh]);

  useEffect(() => {
    if (currentUser.data) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);

      if (applicationDetails.content && applicationDetails.content.appOwners) {
        setIsOwner(isUserAppOwner(currentUser.data, applicationDetails.content.appOwners));
      }
    }
  }, [applicationDetails, currentUser]);

  const handleHowToConfigure = (isIntergration: boolean) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          isSqsIntegration: isIntergration,
        },
        type: modalTypeEnum.DISPLAY_SQS_CONFIG_INFO,
      })
    );
  };

  const handleEditDestination = (param: any) => {
    if (
      appDestinationDetails.content &&
      !!appDestinationDetails.content.appClientDestinationId &&
      appDestinationDetails.content.appClientDestinationId !== newDestinationId
    ) {
      // has real destId, it's edit
      dispatch(
        editAppDestination(
          detailsAppId,
          appDestinationDetails.content.appClientDestinationId,
          {
            ...appDestinationDetails.content,
            changeComment: param.changeComment,
            destinationEndpoint: param.destinationEndpoint,
            destinationType: param.destinationType,
          },
          (destId: any) => {
            dispatch(editModalCloseAction());
            setNeedRefresh(needRefresh + 1);
            // setDetailsDestId(destId);
            // dispatch(getAppDestinationDetails(detailsAppId, destId));
          }
        )
      );
    } else {
      // no destId, it's create
      dispatch(
        createAppDestination(
          detailsAppId,
          {
            // ...appDestinationDetails.content,
            changeComment: param.changeComment,
            destinationEndpoint: param.destinationEndpoint,
            destinationEnvironment: newDestEnv,
            destinationType: param.destinationType,
            index: newDestIndex,
          },
          (destId: any) => {
            dispatch(editModalCloseAction());
            setDetailsDestId(destId);
            // dispatch(getAppDestinationDetails(detailsAppId, destId));
          }
        )
      );
    }
  };

  const handleOpenEditDestination = () => {
    if (isAdmin || isOwner) {
      // if (isAdmin) {
      dispatch(
        editModalOpenAction({
          destData: {
            ...appDestinationDetails.content,
            destinationEnvironment: !!newDestEnv
              ? newDestEnv
              : appDestinationDetails.content.destinationEnvironment,
            index: !!newDestIndex ? newDestIndex : appDestinationDetails.content.index,
          },
          popupData: {
            onConfirm: handleEditDestination,
          },
          type: modalTypeEnum.EDIT_APP_DESTINATION,
        })
      );
    }
  };

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link
              to={
                !appDestinationDetails.loading &&
                appDestinationDetails.content.appClient &&
                `/applicationClients/${appDestinationDetails.content.appClient.appClientId}`
              }
              className="ta-dest-details-button-back"
            >
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-dest-details-title-sub">Destination details</SPageHeading>
          </GridItem>
        </Grid>

        {appDestinationDetails.loading && <PageLoading />}

        {appDestinationDetails.error && <PageError />}

        {/* App Destination DETAILS */}
        <Grid>
          <GridItem>
            <SFlexContainer justifyContent="space-between">
              <SFlexItem>
                <Heading variant="heading2">
                  <span className={getTAClass("app-dest-details", TA_TYPES.TITLE, "app-name")}>
                    {"Application"}
                    {": "}
                    {!appDestinationDetails.loading &&
                      appDestinationDetails.content.appClient &&
                      `${appDestinationDetails.content.appClient.appName} ${appDestinationDetails.content.appClient.appVersion}`}
                  </span>
                </Heading>
              </SFlexItem>

              {/* <SFlexItem>
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "edit")}
                // onClick={handleOpenEditModal}
                >
                  Edit
                </ButtonPrimary>
                <SVerticalSpacer />
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "delete")}
                // onClick={askForConfirmation}
                >
                  Disable
                </ButtonPrimary>
              </SFlexItem> */}
            </SFlexContainer>
          </GridItem>
        </Grid>

        <SSpacer />

        {!appDestinationDetails.loading && appDestinationDetails.content && (
          <Grid columns="0.6fr">
            <GridItem>
              <STallText variant="uiText" className="ta-dest-details-env-index">
                <STextDataTitle>
                  {appDestinationDetails.content.destinationEnvironment ===
                  GroundEnvironmentTypeEnum.PRODUCTION
                    ? `Production`
                    : `Integration ${Number(appDestinationDetails.content.index) + 1}`}
                </STextDataTitle>
                <SVerticalSpacer width="32px" />
                <SActiveLink
                  onClick={() => {
                    handleHowToConfigure(
                      appDestinationDetails.content.destinationEnvironment ===
                        GroundEnvironmentTypeEnum.INTEGRATION
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`(How to configure destination)`}
                </SActiveLink>
              </STallText>

              <STallText variant="uiText" className="ta-dest-details-type-url">
                <STextDataTitle>
                  {!!appDestinationDetails.content.destinationEndpoint
                    ? `${appDestinationDetails.content.destinationType}  :  ${appDestinationDetails.content.destinationEndpoint}`
                    : `Destination not set...`}
                </STextDataTitle>
              </STallText>

              <ButtonPrimary
                disabled={!(isAdmin || isOwner)}
                // disabled={!isAdmin}
                className={getTAClass("app-dest-details", TA_TYPES.BUTTON, "edit-destination")}
                onClick={handleOpenEditDestination}
              >
                Edit destination
              </ButtonPrimary>

              {/* <SFlexContainer justifyContent="space-between">
                <SFlexItem>
                  <DataDetailLabel
                    title="Destination type"
                    value={GroundDestinationTypeEnum.AWS_SQS_URL}
                    className={getTAClass("mqtt-client-details", TA_TYPES.TEXT, "broker-type")}
                  />
                </SFlexItem>
                <SFlexItem>
                  <ButtonPrimary
                    // disabled={!(isActive && (isOwner || isAdmin))}
                    disabled={false}
                    className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "edit_owners")}
                    onClick={handleOpenEditDestination}
                  >
                    Edit destination
                  </ButtonPrimary>
                </SFlexItem>
              </SFlexContainer>

              <DataDetailLabel
                title="Destination endpoint URL"
                value={`http://sqs-local-dev-int-1`}
                className={getTAClass("mqtt-client-details", TA_TYPES.TEXT, "broker-type")}
              />

              <DataDetailLabel
                title={`Route groups count`}
                value={appDestinationDetails.content.routeCount}
                className={getTAClass("mqtt-client-details", TA_TYPES.TEXT, "source-count")}
              /> */}
            </GridItem>
          </Grid>
        )}

        <SSpacer />

        {/* TABS */}
        <Grid columns="0.6fr">
          <GridItem>
            <TabContainer>
              <Tab
                className="ta-app-dest-details-tab-groups"
                onClick={() => {
                  setActiveTab(tabDestinationDetailsEnum.SOURCE_GROUPS);
                }}
                active={activeTab === tabDestinationDetailsEnum.SOURCE_GROUPS}
              >
                Sources
              </Tab>
            </TabContainer>
          </GridItem>
        </Grid>

        <SSpacer />

        {/* TABLE & DATA TAB */}
        {!applicationDetails.loading &&
          applicationDetails.content &&
          !appDestinationDetails.loading &&
          appDestinationDetails.content && (
            <>
              {activeTab === tabDestinationDetailsEnum.SOURCE_GROUPS && (
                <AppDestinationSources
                  appData={applicationDetails.content}
                  destData={appDestinationDetails.content}
                  userData={currentUser.data}
                />
              )}

              {/* {activeTab === tabAircraftDetailsEnum.DETAILS && (
              <AircraftDetailsDetailsTab acContent={appDestinationDetails.content} />
            )}
            {activeTab === tabAircraftDetailsEnum.HISTORY && (
              <AircraftChangeHistory
                acContent={appDestinationDetails.content}
                userData={currentUser.data}
              />
            )} */}
            </>
          )}

        {/* SQS CONFIG INFO MODAL */}
        {editModal &&
          editModal.open &&
          editModal.type === modalTypeEnum.DISPLAY_SQS_CONFIG_INFO && <ModalDisplaySQSConfigInfo />}

        {/* EDIT DESTINATION MODAL */}
        {editModal && editModal.open && editModal.type === modalTypeEnum.EDIT_APP_DESTINATION && (
          <ModalEditAppDestination />
        )}

        {/* ADD ROUTE GROUP MODAL */}
        {editModal && editModal.open && editModal.type === modalTypeEnum.REMOVE_ROUTE_GROUP && (
          <ModalEditComment />
        )}
        {editModal && editModal.open && editModal.type === modalTypeEnum.SELECT_ROUTE_GROUP && (
          <ModalSelectRouteGroup />
        )}
      </SPageContainer>
    </>
  );
};
/* tslint:enable:cyclomatic-complexity */
