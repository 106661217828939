import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditAppOwnersTypes {
  ERROR = "EDIT_APP_OWNER_ERROR",
  REQUEST = "EDIT_APP_OWNER_REQUEST",
  SUCCESS = "EDIT_APP_OWNER_SUCCESS",
}

export interface IEditAppOwnersRequestAction extends IReduxBaseAction<EditAppOwnersTypes> {
  payload: any;
  type: EditAppOwnersTypes.REQUEST;
}

export interface IEditAppOwnersSuccessAction extends IReduxBaseAction<EditAppOwnersTypes> {
  payload: any;
  type: EditAppOwnersTypes.SUCCESS;
}

export interface IEditAppOwnersErrorAction extends IReduxBaseAction<EditAppOwnersTypes> {
  payload: any;
  type: EditAppOwnersTypes.ERROR;
}

export type TSetEditAppOwnersActions =
  | IEditAppOwnersErrorAction
  | IEditAppOwnersRequestAction
  | IEditAppOwnersSuccessAction;
