import {
  ButtonIcon,
  ButtonPrimary,
  ContentSwitcher,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
  ProgressIndicatorLinear,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";
import {
  SFlexContainer,
  SFlexItem,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
  STallText,
  STextDataTitle,
  SVerticalSpacer,
} from "../../../styles/styles";
import { AircraftGroupList } from "../AircraftDetails/AircraftGroupList";

export const GlobalGroupList = (props: any) => {
  const [activeEnv, setActiveEnv]: any = useState(GroundEnvironmentTypeEnum.PRODUCTION);

  const envItems = [
    {
      action: () => setActiveEnv(GroundEnvironmentTypeEnum.PRODUCTION),
      title: "Production",
    },
    {
      action: () => setActiveEnv(GroundEnvironmentTypeEnum.INTEGRATION),
      title: "Integration",
    },
    // {
    //   title: "All",
    //   action: () => setActiveEnv(""),
    // },
  ];

  return (
    <>
      <SFlexContainer justifyContent="flex-start">
        {/* <STallText variant="uiText">
          <STextDataTitle>Environment:</STextDataTitle>
        </STallText> */}
        <ContentSwitcher items={envItems} />
      </SFlexContainer>

      <SSpacer />

      <AircraftGroupList
        groupEnv={activeEnv}
        groupType={AircraftGroupTypeEnum.GLOBAL}
        refresh={activeEnv}
      />
    </>
  );
};
