import { CurrentUserTypes, TGetLoggedInUserType } from "../../actions/user/current/types";

interface IUserState {
  authenticated: boolean;
  data: {};
  error: boolean;
  loading: boolean;
}

const initialState: IUserState = {
  authenticated: false,
  data: {},
  error: false,
  loading: true,
};

export const currentUser = (state: IUserState = initialState, action: TGetLoggedInUserType) => {
  switch (action.type) {
    case CurrentUserTypes.SUCCESS:
      return {
        authenticated: true,
        data: {
          ...action.payload.data.data.content,
          jwt: action.payload.headers["access-token"],
        },
        error: false,
        loading: false,
      };
    case CurrentUserTypes.ERROR:
      return {
        authenticated: false,
        data: action.payload,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
