import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { getRedirectUrl } from "../helper/redirectHelper";
import {
  ADMINISTRATOR,
  APPLICATION_OWNER,
  APPROVER,
  READ,
} from "../models/UserTypes";
import { RootState } from "../store";

import { AircraftDetails } from "./pages/AircraftDetails";
import { AircraftGroupDetails } from "./pages/AircraftGroupDetails";
import { AirlineDetails } from "./pages/AirlineDetails";
import { ApplicationClients } from "./pages/ApplicationClients";
import { ApplicationClientsDetails } from "./pages/ApplicationClientsDetails";
import { BrokerTypes } from "./pages/BrokerTypes";
import { BrokerTypesDetails } from "./pages/BrokerTypesDetails";
import { ChangeHistories } from "./pages/ChangeHistories";
import { ConfigurationFiles } from "./pages/ConfigurationFiles";
import { ConfigurationVersionDetails } from "./pages/ConfigurationFiles/ConfigurationVersionDetails";
import { DestinationDetails } from "./pages/DestinationDetails";
import { Error } from "./pages/Error";
import { GroundsideConfiguration } from "./pages/GroundsideConfiguration";
import { TopicDetails } from "./pages/TopicDetails";
import { Topics } from "./pages/Topics";
import { UserDetails } from "./pages/Users/UserDetails";
import { Users } from "./pages/Users/Users";
import PrivateRoute from "./PrivateRoute";
import { Topology } from "./Topology";

export const RouteAuthenticated = () => {
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/applicationClients"
        component={ApplicationClients}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/applicationClients/:appId"
        component={ApplicationClientsDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/topics"
        component={Topics}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/topics/:topicId"
        component={TopicDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/brokerTypes"
        component={BrokerTypes}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/brokerTypes/:brokerTypeId"
        component={BrokerTypesDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <PrivateRoute exact path="/users" component={Users} userLevelAccess={[ADMINISTRATOR]} />
      <PrivateRoute
        exact
        path="/users/:userId"
        component={UserDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/configurationFiles"
        component={ConfigurationFiles}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/configVersion/:configVersionId"
        component={ConfigurationVersionDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
          READ,
        ]}
      />
      <PrivateRoute
        exact
        path="/topology"
        component={Topology}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <PrivateRoute
        exact
        path="/changeHistory"
        component={ChangeHistories}
        userLevelAccess={[ADMINISTRATOR]}
      />
      {/* <PrivateRoute exact path="/airlines" component={Airlines} userLevelAccess={[ADMINISTRATOR]} /> */}
      <PrivateRoute
        exact
        path="/airlines/:tenantId"
        component={AirlineDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      {/* <PrivateRoute
        exact
        path="/aircrafts"
        component={Aircrafts}
        userLevelAccess={[ADMINISTRATOR]}
      /> */}
      <PrivateRoute
        exact
        path="/aircrafts/:aircraftId"
        component={AircraftDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <PrivateRoute
        exact
        path="/aircraftGroups/:aircraftGroupId"
        component={AircraftGroupDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <PrivateRoute
        exact
        path="/groundsideConfiguration"
        component={GroundsideConfiguration}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <PrivateRoute
        exact
        path="/destination/:destId"
        component={DestinationDetails}
        userLevelAccess={[
          ADMINISTRATOR,
          APPLICATION_OWNER,
          APPROVER,
        ]}
      />
      <Route exact path="/error" component={Error} />

      <Redirect from="/" to={getRedirectUrl(currentUser)} />
    </Switch>
  );
};
