import { brokerLocationEnum } from "./IBrokerTypeListingTableConfig";
import { OrderType } from "./ITableConfig";

/* istanbul ignore file */

export enum userListStatusEnum {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  EMPTY = "",
}

interface IApplicationListingFilters {
  [key: string]: any;
  appClientId?: string;
  appClientLocation?: brokerLocationEnum | string;
  applicationOwner?: string;
  appName?: string;
  appVersion?: string;
  brokerType?: string;
  created_at?: string;
  created_by?: string;
  modified_at?: string;
  modified_by?: string;
  ownerUserId?: string;
  status?: userListStatusEnum;
}

export interface IApplicationListingTableConfig {
  [key: string]: any;
  filter: IApplicationListingFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetApplicationListData {
  content: any[];
  count: number;
}
