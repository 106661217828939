import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAllBrokerType } from "../../actions/broker-type/get-all/actions";
import { getBrokerTypeList } from "../../actions/broker-type/get-filtered-list/actions";
import { isCurrentUserRole } from "../../helper/util";
import { FilterType, IFilterConfig } from "../../models/IFilterConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SOverlay, SPageContainer, SPageHeading, SSpacer } from "../../styles/styles";
import { Filter } from "../Filter";
import { Table } from "../Table";

export const BrokerTypes = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  // BROKER TYPE DROPDOWN
  const { allBrokerType, brokerTypeList, currentUser } = useSelector((state: RootState) => ({
    allBrokerType: state.allBrokerType,
    brokerTypeList: state.brokerTypeList,
    currentUser: state.currentUser,
  }));

  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: {},
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  const emptyFilterObject = {
    brokerTypeName: "",
    systemType: "",
  };

  const headCells: ITableHeaderCells[] = [
    { id: "brokerTypeName", label: "Broker type name", width: "0.6fr" },
    { id: "brokerType", label: "Broker type", width: "0.2fr" },
    // { id: "systemType", label: "System type", width: "0.2fr" },
    { id: "lruDescription", label: "LRU description" },
  ];

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "brokerTypeName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    dispatch(getBrokerTypeList(config));
  }, [config]);

  const openDetails = (row: ITableRow): void => {
    router.history.push(`brokerTypes/${row.brokerTypeId}`);
  };

  const filterConfig: IFilterConfig = {
    items: [
      {
        name: "brokerTypeName",
        placeholder: "Broker type name",
        taClass: "brokerTypeName",
        type: FilterType.TEXT,
        width: "0.2fr",
      },
      // {
      //   name: "systemType",
      //   placeholder: "System type",
      //   taClass: "systemType",
      //   type: FilterType.TEXT,
      //   width: "0.2fr",
      // },
    ],
    pageName: "brokerTypes",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...brokerTypeList,
      onClickRow: isCurrentUserRole(currentUser, [ADMINISTRATOR, APPLICATION_OWNER, APPROVER])
        ? openDetails
        : undefined,
    },
    name: "brokerTypes",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* LOADING */}
      {brokerTypeList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      <SPageContainer>
        <SPageHeading className="ta-brokerTypes-title">Broker types</SPageHeading>

        <Filter {...filterConfig} />
        <SSpacer />
        <Table {...tableProps}></Table>
      </SPageContainer>
    </>
  );
};
