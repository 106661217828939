import { ConfigAppFilterSortType, TConfigAppFilterSortActions } from "./types";

export const configAppFilterSortClearAction = (): TConfigAppFilterSortActions => ({
  type: ConfigAppFilterSortType.CLEAR,
});

export const configAppFilterSortResetAction = (): TConfigAppFilterSortActions => ({
  type: ConfigAppFilterSortType.RESET,
});

export const configAppFilterSortApplyAction = (data: any): TConfigAppFilterSortActions => ({
  payload: data,
  type: ConfigAppFilterSortType.APPLY,
});
