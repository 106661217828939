import {
  AllBrokerTypeListType,
  TAllBrokerTypeActions,
} from "../../actions/broker-type/get-all/types";
import { IGetBrokerTypeListContent } from "../../models/IBrokerTypeListingTableConfig";

interface IAllBrokerTypeListState {
  content: IGetBrokerTypeListContent[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IAllBrokerTypeListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const allBrokerType = (
  state: IAllBrokerTypeListState = initialState,
  action: TAllBrokerTypeActions
): IAllBrokerTypeListState => {
  switch (action.type) {
    case AllBrokerTypeListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case AllBrokerTypeListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case AllBrokerTypeListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
