import { Grid, GridItem, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { topicTypeEnum } from "../../models/ITopicConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import {
  SFlexContainer,
  SFlexItem,
  SModalStackItem,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ButtonPrimary } from "../next-component-fork";

export const ModalCreateTopicTypeSelection = (props: any) => {
  const [updatedConfig, setUpdatedConfig] = useState();

  const handleTopicTypeChange = (value: any) => {
    setUpdatedConfig({
      ...props.config,
      isSecure: value === topicTypeEnum.A2G ? true : props.config.isSecure,
      topicType: value,
    });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
      props.nextPage();
    }
  }, [updatedConfig]);

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-choose-type">
        Choose topic type:
      </Text>

      <SSpacer height="6px" />

      <Grid columns="1fr 1fr">
        <GridItem>
          <SFlexContainer justifyContent="center">
            <ButtonPrimary
              buttonWidth="96%"
              className={getTAClass("create-topic", TA_TYPES.BUTTON, "type-air")}
              onClick={() => {
                handleTopicTypeChange(topicTypeEnum.AIR);
              }}
            >
              Airside only
            </ButtonPrimary>
          </SFlexContainer>
        </GridItem>

        {/* <GridItem>
            <SVerticalSpacer />
        </GridItem> */}

        <GridItem>
          <SFlexContainer justifyContent="center">
            <ButtonPrimary
              buttonWidth="96%"
              className={getTAClass("create-topic", TA_TYPES.BUTTON, "type-a2g")}
              onClick={() => {
                handleTopicTypeChange(topicTypeEnum.A2G);
              }}
            >
              Airside to groundside
            </ButtonPrimary>
          </SFlexContainer>
        </GridItem>
      </Grid>

      <SSpacer height="12px" />
    </>
  );
};
