import { TSetUsetDetailsActions, UserSetDetailsTypes } from "../../actions/user/set-details/types";

interface IUserSetDetailsState {
  data: any;
  error: boolean;
  loading: boolean;
}

const initialState: IUserSetDetailsState = {
  data: [],
  error: false,
  loading: false,
};

export const userSetDetails = (
  state: IUserSetDetailsState = initialState,
  action: TSetUsetDetailsActions
): IUserSetDetailsState => {
  switch (action.type) {
    case UserSetDetailsTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case UserSetDetailsTypes.SUCCESS:
      return {
        ...action.payload,
        error: false,
        loading: false,
      };
    case UserSetDetailsTypes.ERROR:
      return {
        ...action.payload,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
