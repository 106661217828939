import { ButtonPrimary, ProgressIndicatorCircular, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createTenant } from "../../actions/tenant/create/actions";
import { searchTenant } from "../../actions/tenant/search/actions";
import { TenantTypeEnum } from "../../models/ITenant";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateAircraftEnvSelection } from "./ModalCreateAircraftEnvSelection";
import { ModalCreateAirlinePage0 } from "./ModalCreateAirlinePage0";
import { ModalCreateAirlinePage1 } from "./ModalCreateAirlinePage1";
import { ModalCreateAirlinePage2 } from "./ModalCreateAirlinePage2";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalCreateAirline = () => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser, editModal, tenantSearchResult } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    tenantSearchResult: state.tenantSearchResult,
  }));

  const changeCommentRequired = true; // airline create does require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState("New airline");

  const [config, setConfig] = useState({
    changeComment: "",
    // description: "",
    icao: "",
    tenantId: "",
    // tenantName: "",
    // tenantType: TenantTypeEnum.AIRLINE,
  });

  // reset icao input
  useEffect(() => {
    setConfig({
      ...config,
      icao: "",
    });
  }, []);

  // useEffect(() => {
  //   if (!tenantSearchResult.error && tenantSearchResult.content && !!tenantSearchResult.content.tenantId) {
  //     setConfig({
  //       ...config,
  //       // tenantSearchResult.content,
  //       description: tenantSearchResult.content.description,
  //       icao: tenantSearchResult.content.icao.toUpperCase(),
  //       tenantId: tenantSearchResult.content.tenantId,
  //       tenantName: tenantSearchResult.content.tenantName,
  //       tenantType: tenantSearchResult.content.tenantType,
  //     });
  //   }
  // }, [tenantSearchResult]);

  //   useEffect(() => {
  //     setConfig({
  //       ...config,
  //     });
  //   }, [editModal]);

  const handleSubmit = () => {
    dispatch(
      createTenant(
        { ...config, tenantId: tenantSearchResult.content.tenantId },
        (tenantId: any) => {
          history.push(`/airlines/${tenantId}`);
        }
      )
    );
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkFormFill = () => {
    /* TODO */
    const isFormValid = config.icao.length >= 3 && config.icao.length <= 8;

    handleSetValidPage(0, isFormValid);
  };

  const checkCommentInput = () => {
    // const isSearchResultValid = !!config.tenantId && !!config.icao && !!config.tenantName;
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    // handleSetValidPage(1, isSearchResultValid && isCommentValid);
    handleSetValidPage(1, isCommentValid);
  };

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  const handleSearchTenantSuccess = () => {
    // setConfig({
    //   ...config,
    //   // tenantSearchResult.content,
    //   description: tenantSearchResult.content.description,
    //   icao: tenantSearchResult.content.icao.toUpperCase(),
    //   tenantId: tenantSearchResult.content.tenantId,
    //   tenantName: tenantSearchResult.content.tenantName,
    //   tenantType: tenantSearchResult.content.tenantType,
    // });
    setCurrentPage(currentPage + 1);
  };

  const handleSearchTenantError = () => {
    // reset icao
    setConfig({
      ...config,
      icao: "",
    });
  };

  const handleSearchTenantByIcao = () => {
    dispatch(searchTenant(config.icao, handleSearchTenantSuccess, handleSearchTenantError));
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkFormFill();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config]);

  // useEffect(() => {
  //   if (currentPage === 0) {
  //     setDialogTitle("New aircraft / rack");
  //   } else {
  //     const provisionType =
  //       config.provisionEnv === "production" ? "aircraft" : "rack";
  //     setDialogTitle(`New ${provisionType}`);
  //   }
  // }, [currentPage]);

  const modalTheme = {
    modal: {
      width: "650px", // Increase the width of the modal dialog since we increased password label
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {currentPage === 0 && (
          // <ModalCreateAirlinePage0
          <ModalCreateAirlinePage1
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
          />
        )}

        {tenantSearchResult.loading && (
          <SFlexContainer justifyContent="center">
            {/* <ProgressIndicatorCircular size="small" /> */}
            <ProgressIndicatorCircular />
          </SFlexContainer>
        )}

        {currentPage === 1 && (
          // <ModalCommentInputPage
          <ModalCreateAirlinePage2
            config={{ ...config }}
            commentHint={`Please enter a comment about why you are creating this airline.`}
            commentRequired={changeCommentRequired}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            tenantInfo={tenantSearchResult.content}
          />
        )}

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage > 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage < 1}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage === 0 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  // setCurrentPage(currentPage + 1);
                  handleSearchTenantByIcao();
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Search
                {/* Next */}
              </ButtonPrimary>
            ) : currentPage === 1 ? (
              <ButtonPrimary
                onClick={handleSubmit}
                disabled={!validPage[Number(currentPage)]}
                className="ta-modal-submit-button"
              >
                Save
              </ButtonPrimary>
            ) : (
              ""
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
