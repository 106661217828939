import { Dispatch } from "redux";

import { IAircraftListTableConfig, IGetAircraftListData } from "../../../models/IAircraft";
import { AircraftService } from "../../../services/AircraftService";

import { AircraftListType, TAircraftListActions } from "./types";

export const GetAircraftListResetAction = (): TAircraftListActions => ({
  type: AircraftListType.RESET,
});

export const GetAircraftListRequestAction = (): TAircraftListActions => ({
  type: AircraftListType.REQUEST,
});

export const GetAircraftListSuccessAction = (data: any): TAircraftListActions => ({
  payload: data,
  type: AircraftListType.SUCCESS,
});

export const GetAircraftListErrorAction = (error: string): TAircraftListActions => ({
  payload: error,
  type: AircraftListType.ERROR,
});

export const getAircraftList = (conf: IAircraftListTableConfig) => (
  dispatch: Dispatch<TAircraftListActions>
) => {
  dispatch(GetAircraftListRequestAction());
  AircraftService.getAircraftList(conf).then(
    (response: { data: { data: IGetAircraftListData } }) => {
      dispatch(GetAircraftListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetAircraftListErrorAction(error))
  );
};
