import React from "react";

import { IDropdownOption } from "../models/IDropdownOption";

const format = (str: string) =>
  str
    .toLowerCase()
    .replace(/[,;:]/g, "")
    .replace(/\s+/g, "-");

export const getTaClassedOptions = (options: IDropdownOption[]) =>
  options.map((opt: IDropdownOption) => ({
    label: <span className={`ta-dropdown-${format(opt.label.toString())}`}>{opt.label}</span>,
    value: opt.value,
  }));
