import React from "react";

import CloudLogo from "./CloudLogo";
import CloudLogoLarge from "./CloudLogoLarge";
import { ISideBarCloudHomeItem } from "./ICloudBar";
import { CloudLogoSubtitle, CloudLogoTitle, StyledSideBarItem } from "./SideBar.style";

const SideBarCloudHomeItem = (props: ISideBarCloudHomeItem) => (
  <StyledSideBarItem
    {...props}
    icon={props.isLarge ? <CloudLogoLarge /> : <CloudLogo />}
    label={
      <div>
        <CloudLogoTitle>Panasonic</CloudLogoTitle>
        <CloudLogoSubtitle>Cloud Tools</CloudLogoSubtitle>
      </div>
    }
  />
);

export default SideBarCloudHomeItem;
