import deepEqual from "deep-equal";
import { ButtonPrimary, ModalConfirmation, ProgressIndicatorCircular, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { EditApplication } from "../../actions/application/edit/actions";
import { getApplicationDetails } from "../../actions/application/get-details/actions";
import { editModalCloseAction } from "../../actions/modal/edit/actions";
import { getUserDetails } from "../../actions/user/get-details/actions";
import { authTypeEnum } from "../../models/IAppDetailsConfig";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";
import {
  ClientPasswordValidationErrorCodeEnum as ClientPasswordError,
  ClientPasswordValidator,
} from "../ClientPasswordValidator";
import { ClientUsernameValidator } from "../ClientUsernameValidator";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";
import {
  connRetryDelaySecValues,
  isValidConnRetryDelaySec,
  isValidNumConnRetries,
  ModalEditApplicationPage1,
  numConnRetriesValues,
} from "./ModalEditApplicationPage1";
import { ModalSQSInfoPage } from "./ModalSQSInfoPage";

interface IEditUserConfig {
  appClientId: string;
  appClientLocation: brokerLocationEnum | string;
  appClientsSharedUsername: any[];
  appName: string;
  appVersion: string;
  changeComment: string;
  connRetryDelaySec: number;
  // fullName: string;
  internal: boolean;
  numConnRetries: number;
  password: string;
  pathClientCa: string;
  pathToCa: string;
  systemName: string;
  // user: {
  //   email: string;
  //   firstName: string;
  //   lastName: string;
  //   role: string;
  //   userId: string;
  // };
  userName: string;
}

export const ModalEditApplication = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));
  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));
  const { applicationEdit } = useSelector((state: any) => ({
    applicationEdit: state.applicationEdit,
  }));

  const changeCommentRequired = false; // app edit does not require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSqsIntegration, setIsSqsIntegration] = useState(false);

  const [dirty, setDirty] = useState(false);

  const emptyConfig = {
    appClientId: "",
    appClientLocation: brokerLocationEnum.AIRSIDE,
    appClientsSharedUsername: [],
    appName: "",
    appVersion: "",
    changeComment: "",
    connRetryDelaySec: connRetryDelaySecValues.default,
    // fullName: "",
    internal: false,
    numConnRetries: numConnRetriesValues.default,
    password: "",
    pathClientCa: "",
    pathToCa: "",
    systemName: "",
    // user: {
    //   email: "",
    //   firstName: "",
    //   lastName: "",
    //   role: "",
    //   userId: "",
    // },
    userName: "",
  };

  const [authType, setAuthType] = useState(authTypeEnum.CREDENTIALS);

  const [config, setConfig] = useState<IEditUserConfig>(emptyConfig);
  const [originalConfig, setOriginalConfig] = useState<IEditUserConfig>(emptyConfig);

  const [requireConfirmation, setRequireConfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    confirmTextOptions: {},
    confirmTitle: "Confirm edit application",
  });

  const fillForm = (userData: any) => {
    let newConfig: IEditUserConfig = { ...emptyConfig };

    if (editModal.appData) {
      newConfig = {
        ...config,
        appClientId: editModal.appData.appClientId,
        appClientLocation: editModal.appData.appClientLocation,
        appClientsSharedUsername: editModal.appData.appClientsSharedUsername,
        appName: editModal.appData.appName,
        appVersion: editModal.appData.appVersion,
        connRetryDelaySec: editModal.appData.connRetryDelaySec,
        // fullName: editModal.appData.appOwner.fullName,
        internal: editModal.appData.internal,
        numConnRetries: editModal.appData.numConnRetries,
        pathClientCa: editModal.appData.pathClientCa,
        pathToCa: editModal.appData.pathToCa,
        systemName: editModal.appData.systemName,
        // appData.userName could be null, verify here to prevent GUI crash
        userName: !!editModal.appData.userName ? editModal.appData.userName : "",
      };
    }
    setConfig({ ...newConfig });
    setOriginalConfig({ ...newConfig });
    setDirty(false);
  };

  useEffect(() => {
    fillForm({}); // don't care who's userData now
  }, [editModal]);

  const submitSuccessCallback = (appClientId: any) => {
    dispatch(editModalCloseAction());
    dispatch(getApplicationDetails(appClientId));
  };

  const handleSubmit = () => {
    // const password = config.password ? config.password : editModal.appData.password;

    // [UMB-474] send back empty password string to BE if there is no change during app edit
    // do not send back the same password string from BE if there is no password change
    const password = config.password ? config.password : "";

    dispatch(
      EditApplication({ ...config, password, authenticationType: authType }, (appClientId: any) => {
        submitSuccessCallback(appClientId);
      })
    );
  };

  const handleConfirm = () => {
    setRequireConfirmation(false);
    // handleSubmit();
    setCurrentPage(currentPage + 1);
  };

  const handleCancel = () => {
    setRequireConfirmation(false);
  };

  const maybeHandleSubmit = () => {
    if (
      editModal.appData.appClientLocation === brokerLocationEnum.AIRSIDE &&
      authType === authTypeEnum.CREDENTIALS &&
      config.appClientsSharedUsername &&
      config.appClientsSharedUsername.length > 0 &&
      (originalConfig.userName !== config.userName || originalConfig.password !== config.password)
    ) {
      const message =
        `Updating this application client's credentials would also update the credentials of <b>${config.appClientsSharedUsername.length}</b>` +
        ` other application clients that share the same credentials.` +
        `<br><br>For details on these application clients, visit this application client's Details tab.` +
        `<br><br>Are you sure you want to continue?`;
      setConfirmationData({
        ...confirmationData,
        confirmTextOptions: {
          dangerouslySetInnerHTML: { __html: message },
        },
      });
      setRequireConfirmation(true);
    } else {
      // handleSubmit();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkFormFill = () => {
    let appSettingsValid = false;

    if (editModal.appData.appClientLocation === brokerLocationEnum.AIRSIDE) {
      let authValid = false;

      if (authType === authTypeEnum.CREDENTIALS) {
        // authValid = !!config.userName && config.password.length <= 100;

        // [UMB-474] if empty/null password received from BE, it means new password is required during app edit, it can not be empty
        authValid =
          (editModal.appData.password && !!editModal.appData.password && !config.password
            ? true
            : ClientPasswordError.ERROR_NONE ===
              ClientPasswordValidator.validatePassword(config.password)) &&
          !!config.userName &&
          (originalConfig.userName !== config.userName
            ? ClientUsernameValidator.validateUsername(config.userName)
            : true);
      }

      const numConnRetriesValid = isValidNumConnRetries(config.numConnRetries);
      const connRetryDelaySecValid = isValidConnRetryDelaySec(config.connRetryDelaySec);

      appSettingsValid = authValid && numConnRetriesValid && connRetryDelaySecValid;
    } else {
      // no more endpoint settings here
      appSettingsValid = true;
    }

    const isFormValid = !!config.appName && !!config.appVersion && appSettingsValid;

    handleSetValidPage(0, isFormValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkFormFill();
      if (!dirty) {
        const isEqual = deepEqual(config, originalConfig);
        setDirty(!isEqual);
      }
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config]);

  const modalTheme = {
    modal: {
      width: "650px", // Increase the width of the modal dialog since we increased password label
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase
        title={
          currentPage !== 2
            ? `Edit application`
            : `How to configure ${isSqsIntegration ? "integration" : "production"} destination`
        }
      >
        {currentPage === 0 && (
          <ModalEditApplicationPage1
            appData={{ ...editModal.appData }}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            onSqsInfo={(isIntegration: boolean) => {
              setIsSqsIntegration(isIntegration);
              setCurrentPage(2);
            }}
          />
        )}

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are making changes to this application.`}
            commentRequired={changeCommentRequired}
          />
        )}

        {/* {currentPage === 2 && (
          <ModalSQSInfoPage
            isIntegration={isSqsIntegration}
            sqsUrl={
              isSqsIntegration
                ? config.intGroundDestinationEndpoint
                : config.groundDestinationEndpoint
            }
          />
        )} */}

        {/* <SFlexContainer justifyContent="space-around">
          <SFlexItem>
            {applicationEdit.loading ? (
              <ProgressIndicatorCircular size="small" />
            ) : (
              <ButtonPrimary
                onClick={maybeHandleSubmit}
                disabled={!formValid || !dirty}
                className="ta-modal-submit-button"
              >
                Save
              </ButtonPrimary>
            )}
          </SFlexItem>
        </SFlexContainer> */}

        {!applicationEdit.loading ? (
          <SFlexContainer justifyContent="space-between">
            <SFlexContainer justifyContent="flex-start">
              {currentPage === 1 && (
                <ButtonPrimary
                  className="ta-modal-previous-button"
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  // disabled={currentPage === 0}
                >
                  Previous
                </ButtonPrimary>
              )}
            </SFlexContainer>
            <SFlexContainer justifyContent="flex-end">
              {currentPage === 0 ? (
                <ButtonPrimary
                  className="ta-modal-next-button"
                  onClick={() => {
                    // setCurrentPage(currentPage + 1);
                    maybeHandleSubmit();
                  }}
                  disabled={!validPage[Number(currentPage)] || !dirty}
                >
                  Next
                </ButtonPrimary>
              ) : currentPage === 1 ? (
                <ButtonPrimary
                  // onClick={maybeHandleSubmit}
                  onClick={handleSubmit}
                  disabled={!validPage[Number(currentPage)]}
                  className="ta-modal-submit-button"
                >
                  Save
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  className="ta-modal-back-button"
                  onClick={() => {
                    setCurrentPage(0);
                  }}
                >
                  Back
                </ButtonPrimary>
              )}
            </SFlexContainer>
          </SFlexContainer>
        ) : (
          <SFlexContainer justifyContent="space-around">
            <ProgressIndicatorCircular size="small" />
          </SFlexContainer>
        )}

        {editModal && editModal.open && requireConfirmation ? (
          <ModalConfirmation
            title={
              <span className="ta-modal-confirmation-title">{confirmationData.confirmTitle}</span>
            }
            text={
              <span
                className="ta-modal-confirmation-content"
                {...confirmationData.confirmTextOptions}
              ></span>
            }
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        ) : (
          ""
        )}
      </ModalCreateBase>
    </ThemeProvider>
  );
};
