import {
  GetConfigVersionTypes,
  TGetConfigVersionActions,
} from "../../actions/configversion/get/types";

interface IConfigVersionGetState {
  content: any;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigVersionGetState = {
  content: [],
  error: false,
  loading: true,
};

export const configVersionDetails = (
  state: IConfigVersionGetState = initialState,
  action: TGetConfigVersionActions
): IConfigVersionGetState => {
  switch (action.type) {
    case GetConfigVersionTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetConfigVersionTypes.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case GetConfigVersionTypes.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
