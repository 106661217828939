import deepEqual from "deep-equal";
import moment from "moment-timezone";
import { ButtonPrimary, Grid, GridItem } from "next-components";
import React from "react";

import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getAppOwnersCSV, getTenantLabel, getTopicTypeLabel } from "../../../helper/util";
import { getChangeRequestStatusByEnum } from "../../../models/IChangeRequest";
import { SFlexContainer, SVerticalSpacer } from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";

/* tslint:disable:cyclomatic-complexity */
export const TopicDetailsMainDetails = (props: any) => (
  <>
    <Grid columns="1fr 2fr">
      <GridItem>
        <DataDetailLabel
          title="Status"
          value={props.data && props.data.status}
          className={getTAClass("topicDetails", TA_TYPES.TEXT, "status")}
        ></DataDetailLabel>
        <DataDetailLabel
          title="Topic owner"
          value={
            props.data &&
            props.data.appClient &&
            props.data.appClient.appName &&
            props.data.appClient.appVersion
              ? `${props.data.appClient.appName} ${props.data.appClient.appVersion}`
              : props.data &&
                props.data.appClient &&
                props.data.appClient.tenant &&
                props.data.appClient.tenant.tenantName
              ? getTenantLabel(props.data.appClient)
              : "* * * * * *"
          }
          className={getTAClass("topicDetails", TA_TYPES.TEXT, "owner")}
          link={
            props.data &&
            props.data.appClient.appName &&
            props.data.appClient.appVersion &&
            `/applicationClients/${props.data && props.data.appClient.appClientId}`
          }
        ></DataDetailLabel>
        <DataDetailLabel
          title="Type"
          value={getTopicTypeLabel(props.data.topicType)}
          className={getTAClass("topicDetails", TA_TYPES.TEXT, "type")}
        />
      </GridItem>

      <GridItem>
        <SFlexContainer justifyContent="space-between">
          <DataDetailLabel
            title="Last status change requested"
            value={
              !props.data.latestStatusChangeRequest ||
              !props.data.latestStatusChangeRequest.requestedStatus ||
              !props.data.latestStatusChangeRequest.status
                ? " - "
                : `${
                    props.data.latestStatusChangeRequest.requestedStatus
                  } (${getChangeRequestStatusByEnum(props.data.latestStatusChangeRequest.status)})`
            }
            className={getTAClass("topicDetails", TA_TYPES.TEXT, "last-status-change-requested")}
          ></DataDetailLabel>
          {props.isNeedGrantDenyButtons && (
            <SFlexContainer justifyContent="space-between">
              <ButtonPrimary
                className={getTAClass("topicDetails", TA_TYPES.BUTTON, "approve")}
                onClick={() => {
                  props.handleChangeRequest(true);
                }}
              >
                Approve
              </ButtonPrimary>
              <SVerticalSpacer />
              <ButtonPrimary
                className={getTAClass("topicDetails", TA_TYPES.BUTTON, "disapprove")}
                onClick={() => {
                  props.handleChangeRequest(false);
                }}
              >
                Disapprove
              </ButtonPrimary>
            </SFlexContainer>
          )}
        </SFlexContainer>
        <DataDetailLabel
          title="Last status change requested date"
          value={
            !props.data.latestStatusChangeRequest || !props.data.latestStatusChangeRequest.createdAt
              ? " - "
              : moment(props.data.latestStatusChangeRequest.createdAt)
                  .tz(moment.tz.guess())
                  .format("MMM/DD/YYYY HH:mm")
          }
          className={getTAClass("topicDetails", TA_TYPES.TEXT, "last-status-change-requested-date")}
        ></DataDetailLabel>
      </GridItem>
    </Grid>

    <DataDetailLabel
      title="Topic owner admins"
      value={
        props.data &&
        props.data.appClient &&
        // props.data.appClient.appOwner &&
        // props.data.appClient.appOwner.fullName
        props.data.appClient.appOwners &&
        getAppOwnersCSV(props.data.appClient.appOwners)
      }
      className={getTAClass("topicDetails", TA_TYPES.TEXT, "ownerAdmin")}
    />
  </>
);
/* tslint:enable:cyclomatic-complexity */
