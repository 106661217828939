/* istanbul ignore file */
import { IGetAircraftListData } from "../../../models/IAircraft";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AircraftListType {
  RESET = "GET_AIRCRAFT_LIST_RESET",
  REQUEST = "GET_AIRCRAFT_LIST_REQUEST",
  SUCCESS = "GET_AIRCRAFT_LIST_SUCCESS",
  ERROR = "GET_AIRCRAFT_LIST_ERROR",
}

export interface IAircraftListResetAction extends IReduxBaseAction<AircraftListType> {
  type: AircraftListType.RESET;
}

export interface IAircraftListRequestAction extends IReduxBaseAction<AircraftListType> {
  type: AircraftListType.REQUEST;
}

export interface IAircraftListSuccessAction extends IReduxBaseAction<AircraftListType> {
  payload: IGetAircraftListData;
  type: AircraftListType.SUCCESS;
}

export interface IAircraftListErrorAction extends IReduxBaseAction<AircraftListType> {
  payload: string;
  type: AircraftListType.ERROR;
}

export type TAircraftListActions =
  | IAircraftListResetAction
  | IAircraftListRequestAction
  | IAircraftListSuccessAction
  | IAircraftListErrorAction;
