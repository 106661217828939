import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createSubscriber } from "../../actions/application/create-subscriber/actions";
import {
  appTopicFullListClearAction,
  getApplicationTopicFullList,
} from "../../actions/application/get-topics-full/actions";
import { getApplicationTopicList } from "../../actions/application/get-topics/actions";
import { topicStatusEnum } from "../../models/ITopicConfig";
import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalAddTopicSubscriberPage1 } from "./ModalAddTopicSubscriberPage1";
import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalAddTopicSubscriber = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { applicationTopicFullList, editModal } = useSelector((state: any) => ({
    applicationTopicFullList: state.applicationTopicFullList,
    editModal: state.editModal,
  }));

  const [currentPage, setCurrentPage] = useState(0);

  const changeCommentRequired = false; // adding topic subscriber does not require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);

  const [config, setConfig] = useState({
    bridge: true,
    brokerType: {
      brokerType: undefined,
      brokerTypeId: undefined,
      lruDescription: "",
    },
    changeComment: "",
    localGroupData: "",
    topic: {
      appClientId: undefined,
      topicId: undefined,
      topicLocalGroupBridge: "",
      topicName: "",
      topicStatus: "",
    },
  });

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  // initialize the 2nd app topic list at max 500 entries
  useEffect(() => {
    const myConfig = { ...editModal.topicFilter, limit: 500, page: 0 };
    dispatch(getApplicationTopicFullList(myConfig));
  }, []);

  const handleSubmit = () => {
    dispatch(
      createSubscriber(
        {
          ...config,
          appClientId: editModal.appData.appClientId,
        },
        () => {
          dispatch(appTopicFullListClearAction());
          dispatch(getApplicationTopicList(editModal.topicFilter));
        }
      )
    );

    // setConfig({ ...config });  // not sure why this is needed here... comment out for now!
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkValidity = () => {
    const hasBrokerType = config.brokerType && config.brokerType.brokerTypeId;
    const topicExistsAndSuitableToSubscribe =
      config.topic.topicName !== "" &&
      !!config.topic.topicStatus &&
      (config.topic.topicStatus === topicStatusEnum.DRAFT ||
        config.topic.topicStatus === topicStatusEnum.ACTIVE);

    let topicIsNotSubscribed = true;
    if (applicationTopicFullList.content.length) {
      applicationTopicFullList.content.forEach((row: any) => {
        if (
          row.brokerType &&
          config.brokerType.brokerTypeId &&
          (row.brokerType.brokerTypeId as string) === (config.brokerType.brokerTypeId as string) &&
          row.topic.topicId === config.topic.topicId
        ) {
          topicIsNotSubscribed = false;
        }
      });
    }

    const isFormValid =
      !!config.topic.topicId &&
      hasBrokerType &&
      topicExistsAndSuitableToSubscribe &&
      topicIsNotSubscribed;

    handleSetValidPage(0, isFormValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkValidity();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config]);

  const modalTheme = {
    modal: {
      width: "650px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase
        title="Add new topic to subscribe"
        handleCloseButton={() => {
          dispatch(appTopicFullListClearAction());
        }}
      >
        {currentPage === 0 && (
          <ModalAddTopicSubscriberPage1
            appData={editModal.appData}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              setConfig(updatedConfig);
            }}
          />
        )}

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are subscribing to this topic.`}
            commentRequired={changeCommentRequired}
          />
        )}

        <SSpacer height="50px" />

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage !== 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 0}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage < 1 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Next
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                className="ta-modal-save-button"
                onClick={handleSubmit}
                disabled={!validPage[Number(currentPage)]}
              >
                Save
              </ButtonPrimary>
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
