import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateAppDestinationTypes {
  ERROR = "CREATE_APP_DESTINATION_ERROR",
  REQUEST = "CREATE_APP_DESTINATION_REQUEST",
  SUCCESS = "CREATE_APP_DESTINATION_SUCCESS",
}

export interface ICreateAppDestinationRequestAction
  extends IReduxBaseAction<CreateAppDestinationTypes> {
  payload: any;
  type: CreateAppDestinationTypes.REQUEST;
}

export interface ICreateAppDestinationSuccessAction
  extends IReduxBaseAction<CreateAppDestinationTypes> {
  payload: any;
  type: CreateAppDestinationTypes.SUCCESS;
}

export interface ICreateAppDestinationErrorAction
  extends IReduxBaseAction<CreateAppDestinationTypes> {
  payload: any;
  type: CreateAppDestinationTypes.ERROR;
}

export type TCreateAppDestinationActions =
  | ICreateAppDestinationErrorAction
  | ICreateAppDestinationRequestAction
  | ICreateAppDestinationSuccessAction;
