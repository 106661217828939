import { ButtonPrimary, ProgressIndicatorCircular, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createAircraft } from "../../actions/aircraft/create/actions";
import { AircraftTypeEnum } from "../../models/IAircraft";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";
import { AircraftTailNumberValidationErrorCodeEnum } from "../AircraftTailNumberValidatorUtil";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateAircraftEnvSelection } from "./ModalCreateAircraftEnvSelection";
import { ModalCreateAircraftPage1 } from "./ModalCreateAircraftPage1";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalCreateAircraft = () => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser, editModal } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const changeCommentRequired = true; // app create does require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState("New application");

  const [config, setConfig] = useState({
    aircraftType: "",
    changeComment: "",
    description: "",
    tailNumber: "",
    tenantId: "",
  });

  const [aircraftTailNumberValidCode, setAircraftTailNumberValidCode] = useState(
    AircraftTailNumberValidationErrorCodeEnum.NO_ERROR
  );

  // useEffect(() => {
  //   setConfig({
  //     ...config,
  //     //   fullName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
  //     //   internal: currentUser.data.internal,
  //     //   user: {
  //     //     email: currentUser.data.email,
  //     //     firstName: currentUser.data.firstName,
  //     //     lastName: currentUser.data.lastName,
  //     //     role: currentUser.data.role,
  //     //     userId: currentUser.data.userId,
  //     //   },
  //   });
  // }, [currentUser]);

  useEffect(() => {
    setConfig({
      ...config,
      aircraftType: editModal.aircraftType,
      tenantId: editModal.tenant.tenantId,
    });
  }, [editModal]);

  const handleSubmit = () => {
    dispatch(
      createAircraft({ ...config }, (aircraftId: any) => {
        history.push(`/aircrafts/${aircraftId}`);
      })
    );
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkFormFill = () => {
    handleSetValidPage(
      0,
      aircraftTailNumberValidCode === AircraftTailNumberValidationErrorCodeEnum.NO_ERROR
    );
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkFormFill();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
    const provisionType = config.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack";
    setDialogTitle(`New ${provisionType}`);
  }, [config, aircraftTailNumberValidCode]);

  // useEffect(() => {
  //   if (currentPage === 0) {
  //     setDialogTitle("New aircraft / rack");
  //   } else {
  //     const provisionType =
  //       config.provisionEnv === "production" ? "aircraft" : "rack";
  //     setDialogTitle(`New ${provisionType}`);
  //   }
  // }, [currentPage]);

  const modalTheme = {
    modal: {
      width: "650px", // Increase the width of the modal dialog since we increased password label
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {/* {currentPage === 0 && (
          <ModalCreateAircraftEnvSelection
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            nextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        )} */}

        {currentPage === 0 && (
          <ModalCreateAircraftPage1
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            tenant={editModal.tenant}
            setAircraftTailNumberValidCode={setAircraftTailNumberValidCode}
            aircraftTailNumberValidCode={aircraftTailNumberValidCode}
          />
        )}

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are provisioning this ${
              config.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack"
            }.`}
            commentRequired={changeCommentRequired}
          />
        )}

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage > 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage < 1}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage === 0 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Next
              </ButtonPrimary>
            ) : currentPage === 1 ? (
              <ButtonPrimary
                onClick={handleSubmit}
                disabled={!validPage[Number(currentPage)]}
                className="ta-modal-submit-button"
              >
                Provision
              </ButtonPrimary>
            ) : (
              ""
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
