import { Dispatch } from "redux";

import { ConfigService } from "../../../services/ConfigService";

import { GetConfigApplicationType, TGetConfigApplicationActions } from "./types";

export const getConfigApplicationRequestAction = (): TGetConfigApplicationActions => ({
  type: GetConfigApplicationType.REQUEST,
});

export const getConfigApplicationSuccessAction = (data: any): TGetConfigApplicationActions => ({
  payload: data,
  type: GetConfigApplicationType.SUCCESS,
});

export const getConfigApplicationErrorAction = (error: string): TGetConfigApplicationActions => ({
  payload: error,
  type: GetConfigApplicationType.ERROR,
});

export const getConfigApplication = (config: any) => (
  dispatch: Dispatch<TGetConfigApplicationActions>
) => {
  dispatch(getConfigApplicationRequestAction());
  ConfigService.getConfigApplication(config).then(
    (response: { data: { data: any } }) => {
      dispatch(getConfigApplicationSuccessAction(response.data.data));
    },
    (error: any) => dispatch(getConfigApplicationErrorAction(error))
  );
};
