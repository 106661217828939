import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { getAllBrokerType } from "../../actions/broker-type/get-all/actions";
import { changeHistoryFilterSortApplyAction } from "../../actions/changehistory/filter-sort/actions";
import { getChangeHistoryList } from "../../actions/changehistory/get-list/actions";
import { editModalCloseAction, editModalOpenAction } from "../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { getFormattedDate } from "../../helper/util";
import { HistoryChangeTypeEnum, HistoryDisplayModeEnum } from "../../models/IChangeHistories";
import { IChangeHistoryListingTableConfig } from "../../models/IChangeHistoryConfig";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { FilterType, ICustomElement, IFilterConfig } from "../../models/IFilterConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { topicStatusEnum } from "../../models/ITopicConfig";
import { ADMINISTRATOR, READ } from "../../models/UserTypes";
import { changeHistoryFilterSortEmptyFilter as emptyFilterObject } from "../../reducers/changehistory/ChangeHistoryFilterSortReducer";
import { RootState } from "../../store";
import {
  SActiveLink,
  SButtonIcon,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../styles/styles";
import { Filter } from "../Filter";
import { HistoryTable } from "../HistoryTable";
import { ModalDisplayHistoryItems } from "../modals/ModalDisplayHistoryItems";

export const ChangeHistories = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { allBrokerType, changeHistoryList, currentUser, editModal } = useSelector(
    (state: RootState) => ({
      allBrokerType: state.allBrokerType,
      changeHistoryList: state.changeHistoryList,
      currentUser: state.currentUser,
      editModal: state.editModal,
    })
  );
  const { config } = useSelector((state: RootState) => ({
    config: state.changeHistoryFilterSort,
  }));

  const [isAdmin, setIsAdmin] = useState(false);
  const [curUserId, setCurUserId] = useState("");
  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  useEffect(() => {
    if (currentUser.data) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);
      setCurUserId(currentUser.data.userId);
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: {},
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  const changeTypeOptions = [
    {
      label: <span className="ta-dropdown-app-created">App created</span>,
      value: HistoryChangeTypeEnum.APP_CLIENT_CREATED,
    },
    {
      label: <span className="ta-dropdown-app-modified">App modified</span>,
      value: HistoryChangeTypeEnum.APP_CLIENT_MODIFIED,
    },
    {
      label: <span className="ta-dropdown-app-deleted">App deleted</span>,
      value: HistoryChangeTypeEnum.APP_CLIENT_DELETED,
    },
    {
      label: <span className="ta-dropdown-topic-created">Topic created</span>,
      value: HistoryChangeTypeEnum.TOPIC_CREATED,
    },
    {
      label: <span className="ta-dropdown-topic-modified">Topic modified</span>,
      value: HistoryChangeTypeEnum.TOPIC_MODIFIED,
    },
    {
      label: <span className="ta-dropdown-topic-deleted">Topic deleted</span>,
      value: HistoryChangeTypeEnum.TOPIC_DELETED,
    },
    {
      label: <span className="ta-dropdown-pub-created">Publisher created</span>,
      value: HistoryChangeTypeEnum.PUBLISHER_CREATED,
    },
    {
      label: <span className="ta-dropdown-pub-deleted">Publisher deleted</span>,
      value: HistoryChangeTypeEnum.PUBLISHER_DELETED,
    },
    {
      label: <span className="ta-dropdown-sub-created">Subscriber created</span>,
      value: HistoryChangeTypeEnum.SUBSCRIBER_CREATED,
    },
    {
      label: <span className="ta-dropdown-sub-deleted">Subscriber deleted</span>,
      value: HistoryChangeTypeEnum.SUBSCRIBER_DELETED,
    },
    {
      label: <span className="ta-dropdown-config-version-created">Config version created</span>,
      value: HistoryChangeTypeEnum.CONFIG_VERSION_CREATED,
    },
    {
      label: <span className="ta-dropdown-airline-created">Airline created</span>,
      value: HistoryChangeTypeEnum.TENANT_CREATED,
    },
    {
      label: <span className="ta-dropdown-aircraft-created">Aircraft/Rack provisioned</span>,
      value: HistoryChangeTypeEnum.AIRCRAFT_CREATED,
    },
    {
      label: <span className="ta-dropdown-aircraft-modified">Aircraft/Rack modified</span>,
      value: HistoryChangeTypeEnum.AIRCRAFT_MODIFIED,
    },
    {
      label: <span className="ta-dropdown-aircraft-group-created">Aircraft group created</span>,
      value: HistoryChangeTypeEnum.AIRCRAFT_GROUP_CREATED,
    },
    {
      label: <span className="ta-dropdown-aircraft-group-modified">Aircraft group modified</span>,
      value: HistoryChangeTypeEnum.AIRCRAFT_GROUP_MODIFIED,
    },
    {
      label: <span className="ta-dropdown-user-created">User created</span>,
      value: HistoryChangeTypeEnum.USER_CREATED,
    },
    {
      label: <span className="ta-dropdown-user-modified">User modified</span>,
      value: HistoryChangeTypeEnum.USER_MODIFIED,
    },
  ];

  const topicStatusOptions = [
    {
      label: <span className="ta-dropdown-draft-yes">Yes</span>,
      value: "true",
    },
    {
      label: <span className="ta-dropdown-draft-no">No</span>,
      value: "false",
    },
    /*
    {
      label: <span className="ta-dropdown-draft">DRAFT</span>,
      value: topicStatusEnum.DRAFT,
    },
    {
      label: <span className="ta-dropdown-active">ACTIVE</span>,
      value: topicStatusEnum.ACTIVE,
    },
    {
      label: <span className="ta-dropdown-onhold">ON HOLD</span>,
      value: topicStatusEnum.ONHOLD,
    },
    {
      label: <span className="ta-dropdown-deleted">DELETED</span>,
      value: topicStatusEnum.DELETED,
    },
*/
  ];

  const setConfig = (newConfig: any) => {
    dispatch(changeHistoryFilterSortApplyAction(newConfig));
  };

  useEffect(() => {
    dispatch(getChangeHistoryList(config));
  }, [config]);

  const navigateToPage = (url: string): void => {
    router.history.push(url);
  };

  // const headCells: ITableHeaderCells[] = [
  //   {
  //     id: "topicName",
  //     label: "Topic name",
  //     onClickCell: (row: ITableRow) => {
  //       navigateToPage(`topics/${row.topicId}`);
  //     },
  //     value: (row: ITableRow) => <SActiveLink>{row.topicName}</SActiveLink>,
  //   },
  //   {
  //     id: "topicOwner",
  //     label: "Topic owner",
  //     onClickCell: (row: ITableRow) => {
  //       navigateToPage(`applicationClients/${row.appClient.appClientId}`);
  //     },
  //     value: (row: ITableRow) => (
  //       <SActiveLink>{`${row.appClient && row.appClient.appName} ${row.appClient &&
  //         row.appClient.appVersion}`}</SActiveLink>
  //     ),
  //   },
  //   { id: "status", label: "Status" },
  //   { id: "modifiedBy", label: "Modified by", value: (row: ITableRow) => row.modifiedBy.fullName },
  //   {
  //     id: "modifiedAt",
  //     label: "Modified at",
  //     value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
  //   },
  // ];

  const headCells: ITableHeaderCells[] = [
    { id: "field", label: "Field", width: "2fr" },
    { id: "beforeValue", label: "Previous value" },
    { id: "afterValue", label: "Current value" },
  ];

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    componentName: "changeHistory",
    displayMode: HistoryDisplayModeEnum.GLOBAL_HISTORY,
    head: {
      cells: headCells,
    },
    list: {
      ...changeHistoryList,
    },
    name: "changeHistory",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "modifiedDate",
        placeholder: "Modified",
        taClass: "modifiedDate",
        type: FilterType.DATEPICKER,
      },
      {
        name: "modifiedBy",
        placeholder: "Modified by",
        taClass: "modifiedBy",
        type: FilterType.TEXT,
        width: "2fr",
      },
      {
        data: changeTypeOptions,
        name: "changeType",
        placeholder: "Change type",
        taClass: "changeType",
        type: FilterType.DROPDOWN,
        width: "3fr",
      },
      {
        name: "topicName",
        placeholder: "Topic name",
        taClass: "topicName",
        type: FilterType.TEXT,
        width: "2fr",
      },
      {
        data: topicStatusOptions,
        name: "isTopicStatusDraft",
        placeholder: "DRAFT topic",
        taClass: "topicStatusDraft",
        type: FilterType.DROPDOWN,
        width: "2fr",
      },
    ],
    items2: [
      {
        name: "appName",
        placeholder: "App name",
        taClass: "appName",
        type: FilterType.TEXT,
        // width: "2fr",
      },
      {
        data: brokerTypes,
        name: "brokerType",
        placeholder: "Broker type",
        taClass: "brokerType",
        type: FilterType.DROPDOWN,
        // width: "2fr",
      },
      {
        name: "configVersion",
        placeholder: "Config version",
        taClass: "configVersion",
        type: FilterType.TEXT,
        // width: "2fr",
      },
      {
        name: "userName",
        placeholder: "User name",
        taClass: "userName",
        type: FilterType.TEXT,
        // width: "2fr",
      },
      {
        name: "comment",
        placeholder: "Comment",
        taClass: "comment",
        type: FilterType.TEXT,
        // width: "2fr",
      },
      // {
      //   name: "space1",
      //   taClass: "space1",
      //   type: FilterType.SPACE,
      // },
    ],
    pageName: "changeHistory",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  return (
    <>
      {/* LOADING */}
      {changeHistoryList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      <SPageContainer>
        <SPageHeading className="ta-change-history-title">Change history</SPageHeading>
        {/* FILTER */}
        <Filter {...filterConfig} />

        <SSpacer />
        {/* TABLE */}
        <HistoryTable
          {...tableProps}
          isAdmin={isAdmin}
          curUserId={curUserId}
          topPagination
          bottomPagination
        />
      </SPageContainer>

      {editModal.open && editModal.type === modalTypeEnum.DISPLAY_HISTORY_ITEMS && (
        <ModalDisplayHistoryItems />
      )}
    </>
  );
};
