import { EditModalActions, IEditModalOpenAction, TEditModalActions } from "./types";

export const editModalOpenAction = (data: any): IEditModalOpenAction => ({
  payload: data,
  type: EditModalActions.OPEN,
});

export const editModalCloseAction = (): TEditModalActions => ({
  type: EditModalActions.CLOSE,
});
