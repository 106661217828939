import { Dropdown, IconInfo16, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getBrokerTypeList } from "../../actions/broker-type/get-filtered-list/actions";
import { popupOpenAction } from "../../actions/popup/actions";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { MqttLocalGroupDataEnum } from "../../models/IMqtt";
import { localGroupBridgeTypeEnum, topicStatusEnum } from "../../models/ITopicConfig";
import { APPLICATION_OWNER } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { BrokerTypeDropdown } from "../BrokerTypeDropdown";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";
import { TypeaheadTopics } from "../TypeaheadTopics";

/* tslint:disable:cyclomatic-complexity */
export const ModalAddTopicPublisherPage1 = (props: any) => {
  const { applicationTopicFullList, brokerTypeList, currentUser } = useSelector(
    (state: RootState) => ({
      applicationTopicFullList: state.applicationTopicFullList,
      brokerTypeList: state.brokerTypeList,
      currentUser: state.currentUser,
    })
  );

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [topicIsNotPublished, setTopicIsNotPublished] = useState(true);
  const [topicIsDraft, setTopicIsDraft] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertMultiplePublishText, setAlertMultiplePublishText] = useState("");
  const [topicIsLocalGroup, setTopicIsLocalGroup] = useState(false);
  const [alertText2, setAlertText2] = useState("");

  const [sebBrokerType, setSebBrokerType]: any = useState({});

  const astrovaSOMOptions: any[] = [
    {
      label: <span className="ta-dropdown-astrova-som-option-primary">Primary unit</span>,
      value: MqttLocalGroupDataEnum.ASTROVA_PUB_PRIMARY,
    },
    {
      label: <span className="ta-dropdown-astrova-som-option-secondary">Secondary unit</span>,
      value: MqttLocalGroupDataEnum.ASTROVA_PUB_SECONDARY,
    },
  ];

  const handleRetainCheckbox = () => {
    setUpdatedConfig({ ...props.config, retainRequired: !props.config.retainRequired });
  };

  const handleTypeaheadInput = (topicData: any) => {
    setUpdatedConfig({
      ...props.config,
      retainRequired: topicData.retainRequired,
      topic: { ...topicData },
    });
    if (props.config && props.config.topic && props.config.topic.topicId === undefined) {
      setTopicIsNotPublished(true);
      setAlertMultiplePublishText("");
      setAlertText("");
      setTopicIsDraft(false);
      setTopicIsLocalGroup(false);
      setAlertText2("");
    }
  };

  const handleBrokerTypeDropdown = (value: any): any => {
    setUpdatedConfig({
      ...props.config,
      brokerType: value,
      retainRequired: false,
      topic: {
        appClientId: undefined,
        topicId: undefined,
        topicLocalGroupBridge: "",
        topicName: "",
        topicStatus: "",
      },
    });
  };

  const handleAstrovaSOMTypeDropdown = (value: any): any => {
    setUpdatedConfig({
      ...props.config,
      localGroupData: value,
    });
  };

  const isSuggestedItemDisabled = (topic: any) => {
    let publishedStatus = false;
    if (applicationTopicFullList.content.length) {
      publishedStatus = applicationTopicFullList.content.some(
        (row: any) =>
          row.brokerType &&
          props.config.brokerType.brokerTypeId &&
          row.brokerType.brokerTypeId === props.config.brokerType.brokerTypeId &&
          row.topic.topicId === topic.topicId
      );
    }
    if (
      // [UMB-1416] Allow topics to be pub/sub by any app client
      // (currentUser.data.role === APPLICATION_OWNER &&
      //   topic.status === topicStatusEnum.ACTIVE &&
      //   topic.appClient.appOwner.userId !== currentUser.data.userId) ||
      topic.status === topicStatusEnum.ONHOLD ||
      publishedStatus
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch(
      getBrokerTypeList({
        filter: { brokerLocation: brokerLocationEnum.AIRSIDE },
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setSebBrokerType(brokerTypeList.content.filter((broker) => broker.brokerType === "SEB")[0]);
  }, [brokerTypeList]);

  // Initialize the brokerType with the last used brokerType
  useEffect(() => {
    if (applicationTopicFullList.content.length) {
      const mqttClient = applicationTopicFullList.content.reduce((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? a : b
      );
      setUpdatedConfig({ ...props.config, brokerType: mqttClient.brokerType });
    }
  }, [applicationTopicFullList]);

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    if (topicIsLocalGroup) {
      setUpdatedConfig({
        ...props.config,
        brokerType: sebBrokerType,
        localGroupData:
          props.config && !!props.config.localGroupData
            ? props.config.localGroupData
            : astrovaSOMOptions[0].value,
      });
    }
  }, [topicIsLocalGroup]);

  useEffect(() => {
    setTopicIsNotPublished(true);
    setAlertMultiplePublishText("");
    setAlertText("");
    setTopicIsDraft(false);
    setTopicIsLocalGroup(false);
    setAlertText2("");

    if (applicationTopicFullList.content.length) {
      applicationTopicFullList.content.forEach((row: any) => {
        if (
          row.brokerType &&
          props.config.brokerType.brokerTypeId &&
          row.brokerType.brokerTypeId === props.config.brokerType.brokerTypeId &&
          row.topic.topicId === props.config.topic.topicId
        ) {
          setTopicIsNotPublished(false);
        }
      });
    }

    if (!topicIsNotPublished) {
      setAlertMultiplePublishText("⚠️ Topic is already published.");
    }

    if (props.config && props.config.topic && props.config.topic.topicId) {
      if (
        // props.config.topic.topicStatus === topicStatusEnum.DRAFT &&
        // props.appData.appClientId === props.config.topic.appClientId
        props.config.topic.topicStatus === topicStatusEnum.DRAFT
      ) {
        setTopicIsDraft(true);
        setAlertText("⚠️ Topic is a draft.");
      } else if (props.config.topic.topicStatus === topicStatusEnum.ONHOLD) {
        setAlertText("⚠️ Topic is on hold.");
      }
      if (props.config.topic.topicLocalGroupBridge === localGroupBridgeTypeEnum.ASTROVA) {
        setTopicIsLocalGroup(true);
        setAlertText2("⚠️ Topic is for local group bridging on Astrova units only.");
      }
    }
  }, [props.config]);

  return (
    <>
      <Text>
        My application: <span>{props.appData && props.appData.appName}</span>
      </Text>

      <div>
        <Text variant="captionText" className="ta-modal-input-caption-broker-type">
          Publishing from{" "}
          <StatusIcon
            className={
              /* tslint:disable:prefer-template */
              "ta-status-icon-broker-type " +
              (props.config && props.config.brokerType && props.config.brokerType.brokerTypeId
                ? "ta-status-icon-success"
                : "ta-status-icon-fail")
            }
            check={props.config && props.config.brokerType && props.config.brokerType.brokerTypeId}
          />
        </Text>
        <div className="ta-modal-input-broker-type">
          <BrokerTypeDropdown
            location={
              (props.appData && props.appData.appClientLocation) || brokerLocationEnum.AIRSIDE
            }
            onChange={handleBrokerTypeDropdown}
            selected={props.config.brokerType}
            useLruDescriptionForLabel
          />
        </div>
      </div>

      <Text variant="captionText" className="ta-modal-input-caption-topic-name">
        Topic name{" "}
        <StatusIcon
          className={
            /* tslint:disable:prefer-template */
            "ta-status-icon-topic " +
            (props.config &&
            props.config.topic &&
            props.config.topic.topicId &&
            topicIsNotPublished &&
            ((props.config.topic.topicStatus === topicStatusEnum.DRAFT &&
              props.appData.appClientId === props.config.topic.appClientId) ||
              props.config.topic.topicStatus === topicStatusEnum.ACTIVE)
              ? "ta-status-icon-success"
              : "ta-status-icon-fail")
          }
          check={
            props.config &&
            props.config.topic &&
            props.config.topic.topicId &&
            topicIsNotPublished &&
            (props.config.topic.topicStatus === topicStatusEnum.DRAFT ||
              props.config.topic.topicStatus === topicStatusEnum.ACTIVE)
          }
        />
      </Text>
      <TypeaheadTopics
        pageSelector="addTopicPublisher"
        className="ta-modal-input-topic-name"
        value={props.config && props.config.topic}
        onChange={handleTypeaheadInput}
        autoFillOnExactMatchOnly
        isSuggestedItemDisabled={isSuggestedItemDisabled}
        publishableBy={
          props.appData && props.appData.appClientId ? props.appData.appClientId : undefined
        }
      />

      <SFlexItem>
        <div className="ta-modal-input-retainRequired">
          <Checkbox
            onClick={handleRetainCheckbox}
            marked={
              props.config.retainRequired ? CheckboxOptions.selected : CheckboxOptions.unselected
            }
          />
        </div>
        <SVerticalSpacer />
        <Text variant="captionText" className="ta-modal-input-caption-retainRequired">
          Retain required
        </Text>
      </SFlexItem>

      {!!alertMultiplePublishText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-published-alert-text">
            <span
              style={{
                color: "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertMultiplePublishText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text">
            <span
              style={{
                color: topicIsDraft ? "black" : "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText2 && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text2">
            <span
              style={{
                color: topicIsLocalGroup ? "black" : "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText2}
            </span>
          </Text>
        </div>
      )}

      {topicIsLocalGroup && (
        <div>
          <Text variant="captionText" className="ta-modal-input-caption-som-type">
            Set publishing unit as{" "}
            <StatusIcon
              className={
                /* tslint:disable:prefer-template */
                "ta-status-icon-som-type " +
                (props.config && !!props.config.localGroupData
                  ? "ta-status-icon-success"
                  : "ta-status-icon-fail")
              }
              check={props.config && !!props.config.localGroupData}
            />
          </Text>
          <div className="ta-modal-input-som-type">
            <Dropdown
              options={astrovaSOMOptions}
              label="Select unit type"
              onChange={handleAstrovaSOMTypeDropdown}
              selected={props.config.localGroupData}
            />
          </div>
        </div>
      )}

      <SSpacer height="40px" />
    </>
  );
};
