import { GetChangeLogTypes, TGetChangeLogActions } from "../../actions/changelog/get/types";
import { IChangeHistoryConfigVersion } from "../../models/IChangeHistories";

interface IChangeLogState {
  content: string;
  error: boolean;
  fromConfigVersion?: IChangeHistoryConfigVersion;
  loading: boolean;
}

const initialState: IChangeLogState = {
  content: "",
  error: false,
  loading: true,
};

export const changeLogGet = (
  state: IChangeLogState = initialState,
  action: TGetChangeLogActions
): IChangeLogState => {
  switch (action.type) {
    case GetChangeLogTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetChangeLogTypes.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetChangeLogTypes.ERROR:
      return {
        ...state,
        content: "",
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
