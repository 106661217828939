import { configureStore } from "@reduxjs/toolkit";

import { middleware, reducer } from "./reducers";

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat(middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
