import moment from "moment-timezone";

import { appVisibilityEnum } from "../models/IAppDetailsConfig";
import { topicTypeEnum } from "../models/ITopicConfig";

export interface IValidValueRange {
  default?: number;
  max: number;
  min: number;
  warning: number;
}

export const getFormattedDate = (value: string) =>
  moment(value)
    // .tz("America/Los_Angeles")
    .tz(moment.tz.guess())
    .format("MMM/DD/YYYY HH:mm");

export const isCurrentUserRole = (currentUser: any, roles: any[]) => {
  if (currentUser && currentUser.data) {
    return roles.includes(currentUser.data.role);
  }

  return false;
};

export const isUserAppOwner = (user: any, owners: any[]) => {
  if (user && user.userId) {
    return owners.some((owner: any) => owner.userId === user.userId);
  }

  return false;
};

export const getListDataCSV = (listData: any[], field: string) => {
  let csvStr = "";
  if (listData && listData.length > 0) {
    listData.forEach((data: any, idx) => {
      if (!!data[`${field}`]) {
        if (idx > 0) {
          csvStr += ", ";
        }
        csvStr += data[`${field}`];
      }
    });
  }

  return csvStr;
};

export const getAppOwnersCSV = (owners: any[]) => getListDataCSV(owners, "fullName");

// let nameListStr = "";
// if (owners && owners.length > 0) {
//   owners.forEach((owner: any, idx) => {
//     if (!!owner.fullName) {
//       if (idx > 0) {
//         nameListStr += ", ";
//       }
//       nameListStr += owner.fullName;
//     }
//   });
// }

// return nameListStr;
// };

export const getTenantLabel = (data: any) => {
  // some default name other than 'Tenant' ?
  // 'Own group"?
  let tenantLabel = "Tenant";

  if (data.tenant && !!data.tenant.tenantName) {
    tenantLabel = data.tenant.tenantName;
    if (!!data.tenant.icao) {
      tenantLabel += ` (${data.tenant.icao.toUpperCase()})`;
    }
  }

  return tenantLabel;
};

export const getTopicTypeLabel = (topicType: string) => {
  switch (topicType) {
    case topicTypeEnum.AIR:
      return "Airside";

    case topicTypeEnum.A2G:
      return "Airside to groundside";

    default:
      return "";
  }
};

export const isValidValue = (value: number, valueRange: IValidValueRange) =>
  !isNaN(value) && value >= valueRange.min && value <= valueRange.max;

export const isValidValueWithoutWarning = (value: number, valueRange: IValidValueRange) =>
  !isNaN(value) && value >= valueRange.min && value <= valueRange.warning;
