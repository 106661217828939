/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTopicListData } from "../../../models/ITopicConfig";

export enum TopicClientListType {
  CLEAR = "TOPIC_CLIENT_LIST_CLEAR",
  REQUEST = "TOPIC_CLIENT_LIST_REQUEST",
  SUCCESS = "TOPIC_CLIENT_LIST_SUCCESS",
  ERROR = "TOPIC_CLIENT_LIST_ERROR",
}

export interface ITopicClientClearAction extends IReduxBaseAction<TopicClientListType> {
  type: TopicClientListType.CLEAR;
}

export interface ITopicClientRequestAction extends IReduxBaseAction<TopicClientListType> {
  type: TopicClientListType.REQUEST;
}

export interface ITopicClientSuccessAction extends IReduxBaseAction<TopicClientListType> {
  payload: IGetTopicListData;
  type: TopicClientListType.SUCCESS;
}

export interface ITopicClientErrorAction extends IReduxBaseAction<TopicClientListType> {
  payload: string;
  type: TopicClientListType.ERROR;
}

export type TTopicClientListActions =
  | ITopicClientClearAction
  | ITopicClientRequestAction
  | ITopicClientSuccessAction
  | ITopicClientErrorAction;
