import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  appTopicListClearAction,
  getApplicationTopicList,
} from "../../../actions/application/get-topics/actions";
import { getTenantLabel } from "../../../helper/util";
import { IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { RootState } from "../../../store";
import { SActiveLink, SOverlay, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

export const BrokerTypesDetailsTabsTopicList = (props: {
  brokerType: string;
  type: "PUBLISHER" | "SUBSCRIBER";
}) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const headCells: ITableHeaderCells[] = [
    {
      id: "topicName",
      label: "Topic name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/topics/${row.topic.topicId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.topic.topicName}</SActiveLink>,
    },
    {
      id: "topicOwnerAppClientName",
      label: "App name",
      value: (row: ITableRow) =>
        row.topic.appClient.appName
          ? row.topic.appClient.appName
          : row.topic.appClient.tenant && row.topic.appClient.tenant.tenantName
            ? getTenantLabel(row.topic.appClient)
            : "* * * * * *",
    },
    {
      id: "topicOwnerAppClientVersion",
      label: "App version",
      value: (row: ITableRow) =>
        row.topic.appClient.appVersion ? row.topic.appClient.appVersion : "",
    },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { applicationTopicList } = useSelector((state: RootState) => ({
    applicationTopicList: state.applicationTopicList,
  }));

  const emptyFilterObject = {
    clientType: props.type,
    mqttClientBrokerType: props.brokerType,
    topicAppOwnerName: "",
    topicAppOwnerVersion: "",
    topicName: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "topicName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    setConfig({
      filter: { ...emptyFilterObject },
      limit: 10,
      orderBy: "topicName",
      orderType: OrderType.ASC,
      page: 0,
    });
  }, [props.type]);

  useEffect(
    () => () => {
      dispatch(appTopicListClearAction());
    },
    []
  );

  useEffect(() => {
    if (config.filter.clientType && config.filter.mqttClientBrokerType) {
      dispatch(getApplicationTopicList(config));
    }
  }, [config]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...applicationTopicList,
    },
    name: "broker-type-details",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const filterConfig: IFilterConfig = {
    items: [
      {
        name: "topicName",
        placeholder: "Topic name",
        taClass: "topicName",
        width: "0.3fr",
      },
      {
        name: "topicAppOwnerName",
        placeholder: "App name",
        taClass: "appName",
        width: "0.3fr",
      },
      {
        name: "topicAppOwnerVersion",
        placeholder: "App version",
        taClass: "appVersion",
        width: "0.3fr",
      },
    ],
    pageName: "brokerTypeDetails",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, { ...emptyFilterObject, ...filter })) {
        setConfig({
          ...config,
          filter: {
            ...emptyFilterObject,
            ...filter,
          },
          page: 0,
        });
      }
    },
  };

  return (
    <>
      <Filter {...filterConfig} />
      {/* FILTER */}

      <SSpacer />

      {/* LOADING */}
      {applicationTopicList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {/* TABLE */}
      <Table {...tableProps} />
    </>
  );
};
