import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetTopicPayloadTypes {
  ERROR = "GET_TOPICPAYLOAD_ERROR",
  REQUEST = "GET_TOPICPAYLOAD_REQUEST",
  SUCCESS = "GET_TOPICPAYLOAD_SUCCESS",
}

export interface IGetTopicPayloadRequestAction extends IReduxBaseAction<GetTopicPayloadTypes> {
  type: GetTopicPayloadTypes.REQUEST;
}

export interface IGetTopicPayloadSuccessAction extends IReduxBaseAction<GetTopicPayloadTypes> {
  payload: any;
  type: GetTopicPayloadTypes.SUCCESS;
}

export interface IGetTopicPayloadErrorAction extends IReduxBaseAction<GetTopicPayloadTypes> {
  payload: any;
  type: GetTopicPayloadTypes.ERROR;
}

export type TGetTopicPayloadActions =
  | IGetTopicPayloadErrorAction
  | IGetTopicPayloadRequestAction
  | IGetTopicPayloadSuccessAction;
