import { Dispatch } from "redux";

import {
  IChangeHistoryListingTableConfig,
  IGetChangeHistoryListData,
} from "../../../models/IChangeHistoryConfig";
import { ChangeHistoryService } from "../../../services/ChangeHistoryService";

import { ChangeHistoryListType, TChangeHistoryActions } from "./types";

export const changeHistoryRequestAction = (): TChangeHistoryActions => ({
  type: ChangeHistoryListType.REQUEST,
});

export const changeHistorySuccessAction = (data: any): TChangeHistoryActions => ({
  payload: data,
  type: ChangeHistoryListType.SUCCESS,
});

export const changeHistoryErrorAction = (error: string): TChangeHistoryActions => ({
  payload: error,
  type: ChangeHistoryListType.ERROR,
});

export const getChangeHistoryList = (conf: IChangeHistoryListingTableConfig) => (
  dispatch: Dispatch<TChangeHistoryActions>
) => {
  dispatch(changeHistoryRequestAction());
  ChangeHistoryService.getChangeHistoryList(conf).then(
    (response: { data: { data: IGetChangeHistoryListData } }) => {
      dispatch(changeHistorySuccessAction(response.data.data));
    },
    (error: any) => dispatch(changeHistoryErrorAction(error))
  );
};
