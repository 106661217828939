import { Dispatch } from "redux";

import { MQTTService } from "../../../services/MQTTService";
import { TopicService } from "../../../services/TopicService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IDeleteAssociationActionTypes,
  IDeleteAssociationErrorAction,
  IDeleteAssociationRequestAction,
  IDeleteAssociationSuccessAction,
  TDeleteAssociation,
} from "./types";

// DELETE FUNCTIONALITY
export const deleteAssociationRequestAction = (): IDeleteAssociationRequestAction => ({
  type: TDeleteAssociation.REQUEST,
});
export const deleteAssociationSuccessAction = (data: any): IDeleteAssociationSuccessAction => ({
  payload: data,
  type: TDeleteAssociation.SUCCESS,
});
export const deleteAssociationErrorAction = (error: any): IDeleteAssociationErrorAction => ({
  payload: error,
  type: TDeleteAssociation.ERROR,
});

export const deleteAssociation = (
  topicMqttClientId: any,
  changeComment: string,
  successCb?: any
) => (dispatch: Dispatch<IDeleteAssociationActionTypes | TPopupActions>) => {
  dispatch(deleteAssociationRequestAction());
  MQTTService.deleteAssociation(topicMqttClientId, changeComment).then(
    (data: { data: any }) => {
      dispatch(deleteAssociationSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Association deleted successfully!",
          title: "Success",
          type: "Info",
        })
      );
      successCb(topicMqttClientId);
    },
    (error: any) => {
      dispatch(deleteAssociationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
