import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { authTypeEnum } from "../../../models/IAppDetailsConfig";
import { brokerLocationEnum } from "../../../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { RootState } from "../../../store";
import {
  SActiveLink,
  SFlexContainer,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { ModalDisplaySQSConfigInfo } from "../../modals/ModalDisplaySQSConfigInfo";
import { SimpleApplicationList } from "../../SimpleApplicationList";

export const ApplicationDetailsDetailsTab = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const onApplicationOpened = (row: any) => {
    if (props.onApplicationOpened) {
      props.onApplicationOpened();
    }
  };

  const handleHowToConfigure = (isIntergration: boolean) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          isSqsIntegration: isIntergration,
        },
        type: modalTypeEnum.DISPLAY_SQS_CONFIG_INFO,
      })
    );
  };

  return (
    <>
      <Grid columns="repeat(3, auto)">
        <GridItem>
          {/* <STallText variant="uiText" className="ta-application-details-system-name">
          <STextDataTitle>System Name:</STextDataTitle>
          <STextDataValue>{props.appContent.systemName}</STextDataValue>
          </STallText> */}
          {props.appContent.appClientLocation === brokerLocationEnum.AIRSIDE ? (
            <>
              <STallText variant="uiText" className="ta-application-details-authentication-type">
                <STextDataTitle>Authentication type:</STextDataTitle>
                <STextDataValue>{props.appContent.authenticationType}</STextDataValue>
              </STallText>
              <STallText variant="uiText" className="ta-application-details-num-conn-retries">
                <STextDataTitle>Number of connection retries:</STextDataTitle>
                <STextDataValue>{`${props.appContent.numConnRetries}${
                  props.appContent.numConnRetries < 0 ? " (infinite retries)" : ""
                }`}</STextDataValue>
              </STallText>
              <STallText variant="uiText" className="ta-application-details-conn-retry-delay">
                <STextDataTitle>Connection retry delay (sec):</STextDataTitle>
                <STextDataValue>{props.appContent.connRetryDelaySec}</STextDataValue>
              </STallText>
            </>
          ) : (
            <>
              {/* <STallText variant="uiText" className="ta-application-details-dispatch-type-int">
                <STextDataTitle>Destination type - integration:</STextDataTitle>
                <STextDataValue>{props.appContent.intGroundDestinationType || ""}</STextDataValue>
              </STallText>

              <STallText variant="uiText" className="ta-application-details-dispatch-url-int">
                <STextDataTitle>Destination endpoint URL - integration:</STextDataTitle>
                <SActiveLink
                  onClick={() => {
                    handleHowToConfigure(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`(How to configure integration destination)`}
                </SActiveLink>
                <SVerticalSpacer width="6px" />
                <STextDataValue>
                  {props.appContent.intGroundDestinationEndpoint || ""}
                </STextDataValue>
              </STallText>

              <STallText variant="uiText" className="ta-application-details-dispatch-type-prd">
                <STextDataTitle>Destination type - production:</STextDataTitle>
                <STextDataValue>{props.appContent.groundDestinationType || ""}</STextDataValue>
              </STallText>

              <STallText variant="uiText" className="ta-application-details-dispatch-url-prd">
                <STextDataTitle>Destination endpoint URL - production:</STextDataTitle>
                <SActiveLink
                  onClick={() => {
                    handleHowToConfigure(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`(How to configure production destination)`}
                </SActiveLink>
                <SVerticalSpacer width="6px" />
                <STextDataValue>{props.appContent.groundDestinationEndpoint || ""}</STextDataValue>
              </STallText> */}
            </>
          )}

          <STallText variant="uiText" className="ta-application-details-internal">
            <STextDataTitle>Internal:</STextDataTitle>
            <STextDataValue>{props.appContent.internal ? "Yes" : "No"}</STextDataValue>
          </STallText>
        </GridItem>

        {props.appContent.appClientLocation === brokerLocationEnum.AIRSIDE ? (
          <GridItem>
            {props.appContent.authenticationType === authTypeEnum.CREDENTIALS && (
              <>
                <STallText variant="uiText" className="ta-application-details-path-to-ca">
                  <STextDataTitle>User name:</STextDataTitle>
                  <STextDataValue>{props.appContent.userName}</STextDataValue>
                </STallText>
                <STallText variant="uiText" className="ta-application-details-path-to-client-ca">
                  <STextDataTitle>Password:</STextDataTitle>
                  <STextDataValue title={props.appContent.password}>****</STextDataValue>
                </STallText>
              </>
            )}
            {props.appContent.authenticationType === authTypeEnum.CERTIFICATE && (
              <>
                <STallText variant="uiText" className="ta-application-details-path-to-ca">
                  <STextDataTitle>Path to CA:</STextDataTitle>
                  <STextDataValue>{props.appContent.pathToCa}</STextDataValue>
                </STallText>
                <STallText variant="uiText" className="ta-application-details-path-to-client-ca">
                  <STextDataTitle>Path to client CA:</STextDataTitle>
                  <STextDataValue>{props.appContent.pathClientCa}</STextDataValue>
                </STallText>
              </>
            )}
          </GridItem>
        ) : (
          <GridItem />
        )}

        <GridItem>
          <STallText variant="uiText" className="ta-application-details-created-by">
            <STextDataTitle>Created by:</STextDataTitle>
            <STextDataValue>
              {props.appContent.createdBy && props.appContent.createdBy.fullName}
            </STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-application-details-created-at">
            <STextDataTitle>Created at:</STextDataTitle>
            <STextDataValue>
              {props.appContent.createdAt &&
                moment(props.appContent.createdAt)
                  // .tz("America/Los_Angeles")
                  .tz(moment.tz.guess())
                  .format("MMM/DD/YYYY HH:mm")}
            </STextDataValue>
          </STallText>
          <STallText
            variant="uiText"
            className={getTAClass("applicationDetails", TA_TYPES.INPUT, "modifiedBy")}
          >
            <STextDataTitle>Modified by:</STextDataTitle>
            <STextDataValue>
              {props.appContent.modifiedBy && props.appContent.modifiedBy.fullName}
            </STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-application-details-modified-at">
            <STextDataTitle>Modified at:</STextDataTitle>
            <STextDataValue>
              {props.appContent.modifiedAt &&
                moment(props.appContent.modifiedAt)
                  // .tz("America/Los_Angeles")
                  .tz(moment.tz.guess())
                  .format("MMM/DD/YYYY HH:mm")}
            </STextDataValue>
          </STallText>
        </GridItem>
      </Grid>

      <SSpacer />

      {props.appContent.appClientLocation === brokerLocationEnum.AIRSIDE && (
        <>
          <STallText variant="uiText" className="ta-application-details-same-credentials">
            <STextDataTitle>Application clients with the same credentials</STextDataTitle>
          </STallText>
          <SimpleApplicationList
            history={props.history}
            onOpened={onApplicationOpened}
            userNameAndAppClientId={
              props.appContent && `${props.appContent.userName}:${props.appContent.appClientId}`
            }
          />
        </>
      )}

      <SSpacer />

      {props.editModal &&
        props.editModal.open &&
        props.editModal.type === modalTypeEnum.DISPLAY_SQS_CONFIG_INFO && (
          <ModalDisplaySQSConfigInfo />
        )}
    </>
  );
};
