import { Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { topicStatusEnum } from "../../models/ITopicConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { StatusIcon } from "../StatusIcon";
import {
  TopicValidationErrorCodeEnum as TopicNameError,
  TopicValidatorUtil,
} from "../TopicValidatorUtil";
import { TypeaheadApplication } from "../TypeaheadApplication";

import { TextInput } from "./TextInput";

export const ModalEditTopicPage1 = (props: any) => {
  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [errorString, setErrorSting] = useState(" ");

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return;
    }
    if (e.target.value.length > (maxChar || 250)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleTypeaheadInput = (app: any) => {
    setUpdatedConfig({ ...props.config, appData: app });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    let errorCode: TopicNameError;
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }

    errorCode = TopicValidatorUtil.validateTopicName(
      props.config.topicName,
      currentUser.data.role === ADMINISTRATOR,
      props.config.topicType
    );
    props.setIsTopicNameValid(errorCode);

    switch (errorCode) {
      case TopicNameError.NO_ERROR:
        setErrorSting(" ");
        break;
      case TopicNameError.UPPERCASE:
        setErrorSting("Entered Topic name contains uppercase character(s)");
        break;
      case TopicNameError.LENGTH_LT_THREE:
      case TopicNameError.LENGTH_TOO_LONG:
        setErrorSting("Topic name should be a minimum of 3 and maximum of 199 characters");
        break;
      case TopicNameError.INVALID_CHARACTERS:
        setErrorSting("Entered Topic name contains invalid character(s)");
        break;
      case TopicNameError.LEADING_SLASH:
        setErrorSting("Entered Topic name contains leading slash");
        break;
      case TopicNameError.EMPTY_LEVEL_NAME:
        setErrorSting("Entered Topic name contains empty level name");
        break;
      case TopicNameError.TAILING_SLASH:
        setErrorSting("Entered Topic name contains trailing slash");
        break;
      case TopicNameError.INVALID_WILDCARD_M:
        setErrorSting("Entered Topic name contains invalid wildcard #");
        break;
      case TopicNameError.WILDCARD_S_IN_LEVEL:
        setErrorSting("Entered Topic name contains wildcard + in level name");
        break;
      case TopicNameError.MULTIPLE_WILDCARD_M:
        setErrorSting("Entered Topic name contains multiple wildcard #");
        break;
      case TopicNameError.WILDCARD_M_IN_LEVEL:
        setErrorSting("Entered Topic name contains wildcard # in level name");
        break;
      case TopicNameError.INVALID_CHARACTER_$:
        setErrorSting("Entered Topic name contains invalid character $");
        break;
      case TopicNameError.INVALID_WILDCARD_S:
        setErrorSting("Entered Topic name contains invalid wildcard +");
        break;
      case TopicNameError.MORE_THAN_5_SLASHES:
        setErrorSting("Entered Topic name contains more than 5 slashes");
        break;
      default:
        setErrorSting(" ");
    }
  }, [props.config]);

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-topic-name">
        Application{" "}
        <StatusIcon
          className={
            props.config && props.config.appData.appName
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={props.config && props.config.appData && props.config.appData.appName}
        />
      </Text>

      <TypeaheadApplication
        pageSelector="editTopic"
        // value={`${props.config.appData.appName}${props.config.appData.appVersion ? " " : ""}${
        //   props.config.appData.appVersion
        // }`}
        value={
          props.config.appData && props.config.appData.appName
            ? `${props.config.appData.appName} ${props.config.appData.appVersion}`
            : ""
        }
        onChange={handleTypeaheadInput}
        disabled={currentUser.data.role !== ADMINISTRATOR}
        appLocation={brokerLocationEnum.AIRSIDE}
      />

      <TextInput
        label={`Topic name: ${errorString} `}
        name="topic-name"
        placeholder="Topic name"
        valid={
          props.config &&
          props.config.topicName &&
          props.isTopicNameValid === TopicNameError.NO_ERROR
        }
        value={props.config && props.config.topicName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "topicName", 250);
        }}
        disabled={
          // currentUser.data.role === APPLICATION_OWNER &&
          // (currentUser.data.role === APPLICATION_OWNER || currentUser.data.role === APPROVER) &&
          currentUser.data.role !== ADMINISTRATOR && props.config.status !== topicStatusEnum.DRAFT
        }
      />
    </>
  );
};
