import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum UserSetDetailsTypes {
  ERROR = "USER_SET_DETAILS_ERROR",
  REQUEST = "USER_SET_DETAILS_REQUEST",
  SUCCESS = "USER_SET_DETAILS_SUCCESS",
}

export interface IUserSetDetailsRequestAction extends IReduxBaseAction<UserSetDetailsTypes> {
  payload: any;
  type: UserSetDetailsTypes.REQUEST;
}

export interface IUserSetDetailsSuccessAction extends IReduxBaseAction<UserSetDetailsTypes> {
  payload: any;
  type: UserSetDetailsTypes.SUCCESS;
}

export interface IUserSetDetailsErrorAction extends IReduxBaseAction<UserSetDetailsTypes> {
  payload: any;
  type: UserSetDetailsTypes.ERROR;
}

export type TSetUsetDetailsActions =
  | IUserSetDetailsErrorAction
  | IUserSetDetailsRequestAction
  | IUserSetDetailsSuccessAction;
