import { IconInfo16, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationList } from "../../actions/application/get-list/actions";
import { getBrokerTypeList } from "../../actions/broker-type/get-filtered-list/actions";
import { popupOpenAction } from "../../actions/popup/actions";
import { isCurrentUserRole, isUserAppOwner } from "../../helper/util";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { userTenantEnum } from "../../models/ITenant";
import { localGroupBridgeTypeEnum, topicTypeEnum } from "../../models/ITopicConfig";
import { topicPermissionEnum } from "../../models/ITopicDetailsConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { BrokerTypeDropdown } from "../BrokerTypeDropdown";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";
import { Typeahead } from "../Typeahead";

// tslint:disable-next-line: cyclomatic-complexity
export const ModalAddApplicationSubscriberPage1 = (props: any) => {
  const { applicationTopicFullList, brokerTypeList, applicationList, currentUser } = useSelector(
    (state: RootState) => ({
      applicationTopicFullList: state.applicationTopicFullList,
      brokerTypeList: state.brokerTypeList,
      applicationList: state.applicationList,
      currentUser: state.currentUser,
    })
  );

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [alertText, setAlertText] = useState("");
  const [alertMultipleSubscribeText, setAlertMultipleSubscribeText] = useState("");
  const [alertText2, setAlertText2] = useState("");
  const [sebBrokerType, setSebBrokerType]: any = useState({});

  const topicIsLocalGroup = props.topic.localGroupBridge === localGroupBridgeTypeEnum.ASTROVA;

  useEffect(() => {
    dispatch(
      getBrokerTypeList({
        filter: { brokerLocation: brokerLocationEnum.AIRSIDE },
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setSebBrokerType(brokerTypeList.content.filter((broker) => broker.brokerType === "SEB")[0]);
  }, [brokerTypeList]);

  // Initialize the brokerType with the last used brokerType
  useEffect(() => {
    if (applicationTopicFullList.content.length) {
      const mqttClient = applicationTopicFullList.content.reduce((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? a : b
      );
      setUpdatedConfig({ ...props.config, brokerType: mqttClient.brokerType });
    }
  }, [applicationTopicFullList]);

  useEffect(() => {
    if (props.config && updatedConfig && props.onChange) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    if (topicIsLocalGroup) {
      setUpdatedConfig({
        ...props.config,
        bridge: true,
        brokerType: sebBrokerType,
        localGroupData: "",
      });
    }
  }, [topicIsLocalGroup]);

  const handleCreateChipLabel = ({ value }: { value: any }) => {
    const label = "";
    setAlertText("");
    setAlertText2("");
    setAlertMultipleSubscribeText("");

    if (topicIsLocalGroup) {
      setAlertText2("⚠️ Topic is for local group bridging on Astrova units only.");
    }

    return label;
  };

  const handleCreateBridgeInfo = () => {
    dispatch(
      popupOpenAction({
        content: "Bridge allows you to receive messages from topics published from a remote LRU.",
        title: "Create bridge",
        type: "Info",
      })
    );
  };

  const handleTypeaheadInput = (app: any) => {
    setUpdatedConfig({
      ...props.config,
      app,
    });
  };

  const handleBrokerTypeDropdown = (value: any): any => {
    setUpdatedConfig({
      ...props.config,
      brokerType: value,
    });
  };

  return (
    <>
      <div>
        <Text variant="captionText" className="ta-modal-input-caption-broker-type">
          Subscribing from{" "}
          <StatusIcon
            className={
              /* tslint:disable:prefer-template */
              "ta-status-icon-broker-type " +
              (props.config && props.config.brokerType && props.config.brokerType.brokerTypeId
                ? "ta-status-icon-success"
                : "ta-status-icon-fail")
            }
            check={props.config && props.config.brokerType && props.config.brokerType.brokerTypeId}
          />
        </Text>
        <div className="ta-modal-input-broker-type">
          <BrokerTypeDropdown
            location={
              props.topic.topicType === topicTypeEnum.A2G
                ? brokerLocationEnum.GROUNDSIDE
                : brokerLocationEnum.AIRSIDE
            }
            onChange={handleBrokerTypeDropdown}
            selected={props.config.brokerType}
            useLruDescriptionForLabel
          />
        </div>
      </div>

      <br />
      <Text variant="captionText" className="ta-modal-input-caption-application">
        Application{" "}
        <StatusIcon
          className={
            /* tslint:disable:prefer-template */
            "ta-status-icon-topic " +
            (props.config && props.config.app && props.config.app.appClientId
              ? "ta-status-icon-success"
              : "ta-status-icon-fail")
          }
          check={props.config && props.config.app && props.config.app.appClientId}
        />
      </Text>

      <div className="ta-modal-input-topic-name">
        <Typeahead
          pageSelector="addApplicationSubscriber"
          autoFillOnExactMatchOnly={{
            key: "topicName",
            rule: props.autoFillOnExactMatchOnly,
          }}
          disabled={props.disabled}
          config={{
            default: {
              filter: {},
              orderBy: "appName",
            },
            setFilter: (appName: string) => ({
              appName,
            }),
          }}
          suggestion={{
            getList: (config: any) => dispatch(getApplicationList(config)),
            isItemDisabled: (app) =>
              props.topicClientList.content.some(
                (row: any) =>
                  row.brokerType &&
                  props.config.brokerType.brokerTypeId &&
                  row.brokerType.brokerTypeId === props.config.brokerType.brokerTypeId &&
                  row.appClient.appClientId === app.appClientId
              ),
            list: {
              ...applicationList,
              content: applicationList.content.filter((app) => {
                if (app.status !== "ACTIVE") {
                  return false;
                }

                if (
                  (props.topic.topicType === topicTypeEnum.A2G &&
                    app.appClientLocation !== brokerLocationEnum.GROUNDSIDE) ||
                  (props.topic.topicType === topicTypeEnum.AIR &&
                    app.appClientLocation !== brokerLocationEnum.AIRSIDE)
                ) {
                  return false;
                }

                if (
                  !isUserAppOwner(currentUser.data, app.appOwners) &&
                  !isCurrentUserRole(currentUser, [ADMINISTRATOR])
                ) {
                  return false;
                }

                if (props.topic.subscribePermission === topicPermissionEnum.ALL_APPS) {
                  return true;
                }

                if (
                  props.topic.subscribePermission === topicPermissionEnum.TENANT_APPS &&
                  props.topic.appClient.tenant &&
                  app.tenant &&
                  props.topic.appClient.tenant.tenantId === app.tenant.tenantId
                ) {
                  return true;
                }
                if (props.topic.subscribePermission === topicPermissionEnum.OWNER_APPS) {
                  return true;
                }
                if (
                  props.topic.isPublishableForPac &&
                  app.tenant &&
                  userTenantEnum.PAC === app.tenant.tenantId
                ) {
                  return true;
                }
                if (
                  props.topic.isSubscribableForWhitelist &&
                  props.topic.subscribePermissions &&
                  props.topic.subscribePermissions.some(
                    (subscribableApp: any) => subscribableApp.appClientId === app.appClientId
                  )
                ) {
                  return true;
                }

                return false;
              }),
            },
            select: (app: any) => `${app.appName}`,
          }}
          name="app"
          chipLabel={handleCreateChipLabel}
          value={(props.config && props.config.app.appName) || ""}
          hasSelected={(p: any) => p && p.appName === undefined}
          valueToSetOnLoad="appName"
          handleTypeahead={handleTypeaheadInput}
          pattern="{appName} {appVersion} - {status}"
          minInputLen={0}
        />
      </div>

      {!topicIsLocalGroup && (
        <SFlexItem>
          <div className="ta-modal-input-createBridge">
            <Checkbox
              onClick={() => {
                setUpdatedConfig({ ...props.config, bridge: !props.config.bridge });

                return;
              }}
              marked={props.config.bridge ? CheckboxOptions.selected : CheckboxOptions.unselected}
              disabled={
                (props.config.app &&
                  props.config.app.appClientLocation === brokerLocationEnum.GROUNDSIDE) ||
                topicIsLocalGroup
              }
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-createBridge">
            I want to create a bridge
          </Text>
          <SVerticalSpacer />
          <SInfoLabelContainer className="ta-modal-info-createBridge">
            <SInfoLabel>
              <IconInfo16 onClick={handleCreateBridgeInfo} />
            </SInfoLabel>{" "}
          </SInfoLabelContainer>
        </SFlexItem>
      )}

      {!!alertMultipleSubscribeText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-subscribed-alert-text">
            <span
              style={{
                color: "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertMultipleSubscribeText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text">
            <span
              style={{
                color: "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText2 && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text2">
            <span
              style={{
                color: topicIsLocalGroup ? "black" : "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText2}
            </span>
          </Text>
        </div>
      )}

      <SSpacer height="40px" />
    </>
  );
};
