import { colors, Grid, GridItem, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getUserList } from "../../actions/user/get-list/actions";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SNextFixedHeightContainer, SSpacer } from "../../styles/styles";
import { Table } from "../Table";
import { Typeahead } from "../Typeahead";

export const ModalEditAppOwnersPage1 = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { userList } = useSelector((state: any) => ({
    userList: state.userList,
  }));

  const maxAppOwnersAllowed = 10; // select up to 10 app owners
  const [updatedConfig, setUpdatedConfig] = useState();

  useEffect(() => {
    // if (props.list && props.list.length > 0) {
    //   setApproverList(props.list);
    // }
    // setFilteredList({
    //   content: props.list.content,
    //   count: props.list.count,
    // });
  }, []);

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  const usersConfig = {
    filter: {
      email: "",
      firstName: "",
      fullName: "",
      lastName: "",
      role: "",
      userId: "",
    },
    limit: 50,
    orderBy: "firstName",
    orderType: OrderType.ASC,
    page: 0,
    roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER],
  };

  const handleTypeaheadInput = (user: any) => {
    if (!!user.userId) {
      let isSelected = false;
      if (props.config.appOwners.length > 0) {
        isSelected = props.config.appOwners.some((owner: any) => owner.userId === user.userId);
      }

      if (!isSelected) {
        const ownerList = props.config.appOwners;
        ownerList.push(user);
        setUpdatedConfig({
          ...props.config,
          appOwners: ownerList,
        });
      }
    }
  };

  const isRowDisabled = (row: ITableRow) =>
    (props.userData.role !== ADMINISTRATOR && row.userId === props.userData.userId) ||
    props.config.appOwners.length <= 1;

  const headCells: ITableHeaderCells[] = [
    {
      id: "fullName",
      label: "Full name",
      value: (row: ITableRow) =>
        `${row.fullName} ${row.userId === props.userData.userId ? "(self)" : ""}`,
      width: "3fr",
    },
    { id: "delete", label: "Delete" },
  ];

  const tableProps: any = {
    componentName: "selected-app-owners",
    deleteCell: {
      as: "a",
      disabled: isRowDisabled,
      onClick: (row: ITableRow) => {
        if (!isRowDisabled(row)) {
          const ownerList = props.config.appOwners;
          const idx = ownerList.findIndex((owner: any) => owner.userId === row.userId);
          ownerList.splice(idx, 1);
          setUpdatedConfig({
            ...props.config,
            appOwners: ownerList,
          });
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: {
      content: props.config.appOwners,
      count: props.config.appOwners.length,
    },
    name: "selected-app-owners",
    noHeader: true,
    noPagination: true,
    rowStriped: "none",
  };

  return (
    <>
      <Grid columns="1fr 1fr">
        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-select-app-owners">
            {`Select app owners (up to ${maxAppOwnersAllowed} app owners)`}
          </Text>

          <SSpacer height="8px" />

          <div className="ta-modal-input-app-owners-name">
            <Typeahead
              pageSelector="modal-edit-app-owners"
              disabled={props.config.appOwners.length >= maxAppOwnersAllowed}
              config={{
                default: {
                  filter: {
                    email: "",
                    firstName: "",
                    fullName: "",
                    lastName: "",
                    role: "",
                    userId: "",
                  },
                  orderBy: "fullName",
                  roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER],
                },
                setFilter: (value: string | any) =>
                  typeof value === "string" ? { fullName: value } : { fullName: value.fullName },
              }}
              suggestion={{
                getList: (config: any) => dispatch(getUserList(config)),
                list: userList,
                select: (user: any) => `${user.fullName}`,
              }}
              placeholder="App owner name"
              name="appOwner"
              value={""}
              hasSelected={(p: any) => true}
              handleTypeahead={handleTypeaheadInput}
              pattern="{fullName}"
              //   minInputLen={2}
              showListOnFocus={true}
              // clearOnSelect={true}
              keepInputOnSelect={true}
            />
          </div>

          <SSpacer height="200px" />
        </GridItem>

        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-app-owner">
            {`Selected app owners (total: ${props.config.appOwners.length})`}
          </Text>

          <SSpacer height="8px" />

          <SSpacer height="2px" color={colors.greys.lighten25} />
          <SNextFixedHeightContainer maxHeight="calc(5 * 48px)" overflow="auto">
            <Table {...tableProps} />
          </SNextFixedHeightContainer>
        </GridItem>
      </Grid>
    </>
  );
};
