/* istanbul ignore file */
import { IGetApplicationListData } from "../../../models/IAppListingTableConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ApplicationListType {
  REQUEST = "GET_APPLICATION_LIST_REQUEST",
  SUCCESS = "GET_APPLICATION_LIST_SUCCESS",
  ERROR = "GET_APPLICATION_LIST_ERROR",
}

export interface IApplicationRequestAction extends IReduxBaseAction<ApplicationListType> {
  type: ApplicationListType.REQUEST;
}

export interface IApplicationSuccessAction extends IReduxBaseAction<ApplicationListType> {
  payload: IGetApplicationListData;
  type: ApplicationListType.SUCCESS;
}

export interface IApplicationErrorAction extends IReduxBaseAction<ApplicationListType> {
  payload: string;
  type: ApplicationListType.ERROR;
}

export type TApplicationActions =
  | IApplicationRequestAction
  | IApplicationSuccessAction
  | IApplicationErrorAction;
