import { Dispatch } from "redux";

import { MQTTService } from "../../../services/MQTTService";
import { TopicService } from "../../../services/TopicService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IDeleteAssociationBulkActionTypes,
  IDeleteAssociationBulkErrorAction,
  IDeleteAssociationBulkRequestAction,
  IDeleteAssociationBulkSuccessAction,
  TDeleteAssociationBulk,
} from "./types";

// DELETE FUNCTIONALITY
export const deleteAssociationBulkRequestAction = (): IDeleteAssociationBulkRequestAction => ({
  type: TDeleteAssociationBulk.REQUEST,
});
export const deleteAssociationBulkSuccessAction = (
  data: any
): IDeleteAssociationBulkSuccessAction => ({
  payload: data,
  type: TDeleteAssociationBulk.SUCCESS,
});
export const deleteAssociationBulkErrorAction = (
  error: any
): IDeleteAssociationBulkErrorAction => ({
  payload: error,
  type: TDeleteAssociationBulk.ERROR,
});

export const deleteAssociationBulk = (
  deleteItems: { mqttIds: string[] },
  changeComment: string,
  successCb?: any
) => (dispatch: Dispatch<IDeleteAssociationBulkActionTypes | TPopupActions>) => {
  dispatch(deleteAssociationBulkRequestAction());
  MQTTService.deleteAssociationBulk(deleteItems, changeComment).then(
    (data: { data: any }) => {
      dispatch(deleteAssociationBulkSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: data.data.message,
          title: "Success",
          type: "Info",
        })
      );
      successCb(deleteItems);
    },
    (error: any) => {
      dispatch(deleteAssociationBulkErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
