import { Dispatch } from "redux";

import { AircraftGroupService } from "../../../services/AircraftGroupService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import { RemoveAircraftsFromGroupsTypes, TRemoveAircraftsFromGroupsActions } from "./types";

// DELETE FUNCTIONALITY
export const RemoveAircraftsFromGroupsRequestAction = (): TRemoveAircraftsFromGroupsActions => ({
  type: RemoveAircraftsFromGroupsTypes.REQUEST,
});
export const RemoveAircraftsFromGroupsSuccessAction = (
  data: any
): TRemoveAircraftsFromGroupsActions => ({
  payload: data,
  type: RemoveAircraftsFromGroupsTypes.SUCCESS,
});
export const RemoveAircraftsFromGroupsErrorAction = (
  error: any
): TRemoveAircraftsFromGroupsActions => ({
  payload: error,
  type: RemoveAircraftsFromGroupsTypes.ERROR,
});

export const removeAircraftsFromGroups = (config: any, cb?: any) => (
  dispatch: Dispatch<TRemoveAircraftsFromGroupsActions | TPopupActions>
) => {
  dispatch(RemoveAircraftsFromGroupsRequestAction());
  AircraftGroupService.removeAircraftsFromGroups(config).then(
    (data: { data: any }) => {
      dispatch(RemoveAircraftsFromGroupsSuccessAction(data.data));
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      // dispatch(getApplicationDetails(appId));
      dispatch(
        popupOpenAction({
          content: "Aircrafts removed from groups successfully!",
          title: "Success",
          type: "Info",
        })
      );
      if (cb) {
        cb(data.data.data.content);
      }
    },
    (error: any) => {
      dispatch(RemoveAircraftsFromGroupsErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
