import { Dropdown, Text } from "next-components";
import React from "react";

import { IDropdownInput, IStatusAndValid } from "../../models/IDropdownInput";
import { StatusIcon } from "../StatusIcon";

export const DropdownInput = (props: IDropdownInput | IStatusAndValid) => (
  <>
    <Text variant="captionText" className={`ta-modal-input-caption-${props.name}`}>
      {props.label}{" "}
      {(props as IStatusAndValid).statusIcon ? (
        <StatusIcon
          className={`ta-status-icon-${(props as IStatusAndValid).valid ? "success" : "fail"}`}
          check={(props as IStatusAndValid).valid}
        />
      ) : (
        ""
      )}
    </Text>
    <div className={`ta-modal-input-${props.name}`}>
      <Dropdown
        options={props.options}
        label={props.label}
        selected={props.selected}
        onChange={props.onChange.bind(props)}
        disabled={props.disabled}
      />
    </div>
  </>
);
