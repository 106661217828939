import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { SOverlay, SPageContainer, SPageHeading, STopologyContainerNarrow } from "../styles/styles";

import { PageError } from "./PageState";
import { MemoizedTopologyGraphVis } from "./TopologyGraphVis";
import { TopologyList } from "./TopologyList";

export const Topology = (props: any) => {
  const rawData: any[] = [];
  const [data, setData]: any[] = useState([]);

  const { topologyList } = useSelector((state: any) => ({
    topologyList: state.topologyList,
  }));

  useEffect(() => {
    if (topologyList.content.length) {
      topologyList.content.forEach((row: any) => {
        if (
          !rawData.filter(
            (raw: any) =>
              raw.from === row.parentBrokerType.brokerType &&
              raw.to === row.remoteBrokerType.brokerType
          ).length
        ) {
          rawData.push({
            from: row.parentBrokerType.brokerType,
            to: row.remoteBrokerType.brokerType,
          });
        }
      });
      setData(rawData);
    }
  }, [topologyList.content]);

  return (
    <>
      {/* LOADING */}
      {topologyList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      <SPageContainer>
        <SPageHeading className="ta-topology-title">Topology</SPageHeading>

        {/* ERROR */}
        {topologyList.error && <PageError />}

        <TopologyList></TopologyList>

        <STopologyContainerNarrow>
          <MemoizedTopologyGraphVis rawData={data} />
        </STopologyContainerNarrow>
      </SPageContainer>
    </>
  );
};
