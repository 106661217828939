import { CreateTenantTypes, TCreateTenantActions } from "../../actions/tenant/create/types";

interface ITenantCreationState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: ITenantCreationState = {
  content: [],
  error: false,
  loading: false,
};

export const tenantCreation = (
  state: ITenantCreationState = initialState,
  action: TCreateTenantActions
): ITenantCreationState => {
  switch (action.type) {
    case CreateTenantTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case CreateTenantTypes.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case CreateTenantTypes.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
