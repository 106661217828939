import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { getConfigLoadableList } from "../../../actions/configfile/loadable/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate } from "../../../helper/util";
import { ConfigLoadableStatusEnum } from "../../../models/IConfigVersion";
import {
  FilterType,
  ICustomElement,
  IFilterConfig,
  IFilterItem,
} from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { ConfigService, DownloadTypeEnum } from "../../../services/ConfigService";
import { RootState } from "../../../store";
import {
  SActiveLink,
  SButtonIcon,
  SIconAdd32,
  SimpleLabel,
  SimpleText,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../../styles/styles";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

export const ConfigLoadableList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { configLoadableList } = useSelector((state: RootState) => ({
    configLoadableList: state.configLoadableList,
  }));

  const defaultFilterObject = {
    configVersionId: props.configVersionId,
    isSupplemental: props.isSupplemental,
  };

  const [config, setConfig] = useState({
    filter: { ...defaultFilterObject },
    limit: 10,
    orderBy: "loadableType",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    dispatch(getConfigLoadableList(config));
  }, [config]);

  const [headCells, setHeadCells] = useState([
    {
      id: "",
      label: "Loadable type",
      value: (row: ITableRow) => row.loadableTypeName,
      width: "3fr",
    },
    {
      id: "",
      label: "Part number",
      value: (row: ITableRow) => row.partNumber,
      width: "1.5fr",
    },
    {
      id: "",
      label: "File name",
      value: (row: ITableRow) =>
        row.loadableStatus === ConfigLoadableStatusEnum.SUCCESS ? (
          row.isRelease ? (
            <SActiveLink>
              <a
                // href={`http://scmdb.mascorp.com/py/release/${row.scmdbReleaseId}`}
                href={`http://scmdb.mascorp.com/py/component/${row.partNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`Component-${row.loadableTypeName} (${row.partNumber}) `}
              </a>
            </SActiveLink>
          ) : (
            <SActiveLink
              onClick={() =>
                ConfigService.downloadS3File(row.loadableId, DownloadTypeEnum.LOADABLE)
              }
            >
              {row.fileName}
            </SActiveLink>
          )
        ) : (
          ""
        ),
      width: "5fr",
    },
    {
      id: "",
      label: "Build status",
      value: (row: ITableRow) => row.loadableStatus,
      width: "1.5fr",
    },
    {
      id: "",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
      width: "2fr",
    },
  ]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...configLoadableList,
    },
    name: "loadables",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* <div className={getTAClass("loadable-list", TA_TYPES.TEXT, "note")}>
        <SimpleLabel>Note:</SimpleLabel>
        <SimpleText>
          {props.isSupplemental > 0
            ? "Supplemental loadables are for ...."
            : "Standard loadables are for normal testing..."}
        </SimpleText>
      </div> */}

      <SSpacer />

      {/* LOADING */}
      {configLoadableList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      <Table {...tableProps}></Table>
    </>
  );
};
