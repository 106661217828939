import { Dispatch } from "redux";

import {
  IAircraftGroupListTableConfig,
  IGetAircraftGroupListData,
} from "../../../models/IAircraftGroup";
import { AircraftGroupService } from "../../../services/AircraftGroupService";

import { AircraftGroupSelectListType, TAircraftGroupSelectListActions } from "./types";

export const GetAircraftGroupSelectListResetAction = (): TAircraftGroupSelectListActions => ({
  type: AircraftGroupSelectListType.RESET,
});

export const GetAircraftGroupSelectListRequestAction = (): TAircraftGroupSelectListActions => ({
  type: AircraftGroupSelectListType.REQUEST,
});

export const GetAircraftGroupSelectListSuccessAction = (
  data: any
): TAircraftGroupSelectListActions => ({
  payload: data,
  type: AircraftGroupSelectListType.SUCCESS,
});

export const GetAircraftGroupSelectListErrorAction = (
  error: string
): TAircraftGroupSelectListActions => ({
  payload: error,
  type: AircraftGroupSelectListType.ERROR,
});

export const getAircraftGroupSelectList = (conf: IAircraftGroupListTableConfig) => (
  dispatch: Dispatch<TAircraftGroupSelectListActions>
) => {
  dispatch(GetAircraftGroupSelectListRequestAction());
  AircraftGroupService.getAircraftGroupList(conf).then(
    (response: { data: { data: IGetAircraftGroupListData } }) => {
      dispatch(GetAircraftGroupSelectListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetAircraftGroupSelectListErrorAction(error))
  );
};
