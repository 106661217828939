export enum tabEnum {
  BRIDGES,
  DETAILS,
  HISTORY,
  PAYLOAD,
  PERMISSIONS,
  PUBLISHERS,
  SUBSCRIBERS,
  TOPOLOGY,
}

export enum topicPermissionEnum {
  OWNER_APPS = "OWNER APPS",
  TENANT_APPS = "TENANT APPS",
  ALL_APPS = "ALL APPS",
}

export enum topicPermissionTypeEnum {
  PUBLISH = "pub",
  SUBSCRIBE = "sub",
}

export interface IAppBrokerType {
  brokerType: string;
  brokerTypeId: string;
}

export interface IGenericSimpleUser {
  fullName: string;
  userId: string;
}

export interface IAppClient {
  // appBrokerType: IAppBrokerType;
  appClientId: string;
  appName: string;
  appOwner: IGenericSimpleUser;
  appVersion: string;
  modifiedAt: string;
}

export interface ITopicDetailsContent {
  accessAnonymous: boolean;
  appClient: IAppClient;
  createdAt: string;
  createdBy: IGenericSimpleUser;
  dependencies: string;
  description: string;
  featureName: string;
  isSecure: boolean;
  localGroupBridge: string;
  maxPayloadSize?: number;
  modifiedAt: string;
  modifiedBy: IGenericSimpleUser;
  multicast: boolean;
  obfuscationRequirement: number;
  offloadType: string;
  priority: number;
  qosLevel: number;
  retainRequired: boolean;
  serviceName: string;
  status: string;
  throttleThresholdFactor?: number;
  topicCategory: string;
  topicId: string;
  topicName: string;
  topicType: string;
  ttl: number;
  versionId: string;
}

export interface ITopicPermissionAppsListData {
  content: any[];
  count: number;
  perm: string;
}
