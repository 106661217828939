import {
  ConfigVersionListType,
  TConfigVersionListActions,
} from "../../actions/configversion/get-list/types";

interface IConfigVersionListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigVersionListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const configVersionList = (
  state: IConfigVersionListState = initialState,
  action: TConfigVersionListActions
): IConfigVersionListState => {
  switch (action.type) {
    case ConfigVersionListType.RESET:
      return {
        ...state,
        ...initialState,
      };
    case ConfigVersionListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ConfigVersionListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case ConfigVersionListType.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
