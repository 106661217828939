import { IPopupOpenAction, PopupActions, TPopupActions } from "./types";

export const popupOpenAction = (data: any): IPopupOpenAction => ({
  payload: data,
  type: PopupActions.OPEN,
});

export const popupCloseAction = (): TPopupActions => ({
  type: PopupActions.CLOSE,
});
