import { Dispatch } from "redux";

import {
  IConfigVersionListTableConfig,
  IGetConfigVersionListData,
} from "../../../models/IConfigVersion";
import { ConfigService } from "../../../services/ConfigService";

import { ConfigVersionListType, TConfigVersionListActions } from "./types";

export const GetConfigVersionListResetAction = (): TConfigVersionListActions => ({
  type: ConfigVersionListType.RESET,
});

export const GetConfigVersionListRequestAction = (): TConfigVersionListActions => ({
  type: ConfigVersionListType.REQUEST,
});

export const GetConfigVersionListSuccessAction = (data: any): TConfigVersionListActions => ({
  payload: data,
  type: ConfigVersionListType.SUCCESS,
});

export const GetConfigVersionListErrorAction = (error: string): TConfigVersionListActions => ({
  payload: error,
  type: ConfigVersionListType.ERROR,
});

export const getConfigVersionList = (conf: IConfigVersionListTableConfig) => (
  dispatch: Dispatch<TConfigVersionListActions>
) => {
  dispatch(GetConfigVersionListRequestAction());
  ConfigService.getConfigVersionList(conf).then(
    (response: { data: { data: IGetConfigVersionListData } }) => {
      dispatch(GetConfigVersionListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetConfigVersionListErrorAction(error))
  );
};
