import { Dispatch } from "redux";

import { GroundConfigService } from "../../../services/GroundConfigService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";

import { GetGroundConfigDeployType, TGetGroundConfigDeployActions } from "./types";

export const getGroundConfigDeployRequestAction = (): TGetGroundConfigDeployActions => ({
  type: GetGroundConfigDeployType.REQUEST,
});

export const getGroundConfigDeploySuccessAction = (data: any): TGetGroundConfigDeployActions => ({
  payload: data,
  type: GetGroundConfigDeployType.SUCCESS,
});

export const getGroundConfigDeployErrorAction = (error: string): TGetGroundConfigDeployActions => ({
  payload: error,
  type: GetGroundConfigDeployType.ERROR,
});

export const deployGroundConfig = (isIntegration: boolean, changeComment: string, cb?: any) => (
  dispatch: Dispatch<TGetGroundConfigDeployActions | TEditModalActions>
) => {
  dispatch(getGroundConfigDeployRequestAction());
  GroundConfigService.deployGroundConfigs(isIntegration, changeComment).then(
    (response: { data: { data: any } }) => {
      dispatch(getGroundConfigDeploySuccessAction(response.data.data));
      if (cb) {
        cb(response.data.data);
      }
    },
    (error: any) => {
      dispatch(editModalCloseAction());
      dispatch(getGroundConfigDeployErrorAction(error));
    }
  );
};
