import { Dispatch } from "redux";

import { IFilterSortData } from "../../../models/IFilterSort";

import { AppFilterSortType, TAppFilterSortActions } from "./types";

export const appFilterSortClearAction = (): TAppFilterSortActions => ({
  type: AppFilterSortType.CLEAR,
});

export const appFilterSortResetAction = (): TAppFilterSortActions => ({
  type: AppFilterSortType.RESET,
});

export const appFilterSortApplyAction = (data: any): TAppFilterSortActions => ({
// export const appFilterSortApplyAction = (data: IFilterSortData): TAppFilterSortActions => ({
  payload: data,
  type: AppFilterSortType.APPLY,
});

