import {
  GetGndChangeLogTypes,
  TGetGndChangeLogActions,
} from "../../actions/changelog/get-gnd/types";
import { IChangeHistoryConfigVersion } from "../../models/IChangeHistories";

interface IGndChangeLogState {
  content: string;
  error: boolean;
  fromConfigVersion?: IChangeHistoryConfigVersion;
  loading: boolean;
}

const initialState: IGndChangeLogState = {
  content: "",
  error: false,
  loading: true,
};

export const gndChangeLogGet = (
  state: IGndChangeLogState = initialState,
  action: TGetGndChangeLogActions
): IGndChangeLogState => {
  switch (action.type) {
    case GetGndChangeLogTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetGndChangeLogTypes.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetGndChangeLogTypes.ERROR:
      return {
        ...state,
        content: "",
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
