import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetAircraftDetails {
  ERROR = "AIRCRAFT_GETDETAILS_ERROR",
  REQUEST = "AIRCRAFT_GETDETAILS_REQUEST",
  SUCCESS = "AIRCRAFT_GETDETAILS_SUCCESS",
}

export interface IGetAircraftDetailsRequestAction extends IReduxBaseAction<TGetAircraftDetails> {
  type: TGetAircraftDetails.REQUEST;
}

export interface IGetAircraftDetailsSuccessAction extends IReduxBaseAction<TGetAircraftDetails> {
  payload: any;
  type: TGetAircraftDetails.SUCCESS;
}

export interface IGetAircraftDetailsErrorAction extends IReduxBaseAction<TGetAircraftDetails> {
  payload: any;
  type: TGetAircraftDetails.ERROR;
}

export type IGetAircraftDetailsActionType =
  | IGetAircraftDetailsRequestAction
  | IGetAircraftDetailsSuccessAction
  | IGetAircraftDetailsErrorAction;
