import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditTopicPermissionTypes {
  ERROR = "EDIT_TOPIC_PERMISSION_ERROR",
  REQUEST = "EDIT_TOPIC_PERMISSION_REQUEST",
  SUCCESS = "EDIT_TOPIC_PERMISSION_SUCCESS",
}

export interface IEditTopicPermissionRequestAction
  extends IReduxBaseAction<EditTopicPermissionTypes> {
  payload: any;
  type: EditTopicPermissionTypes.REQUEST;
}

export interface IEditTopicPermissionSuccessAction
  extends IReduxBaseAction<EditTopicPermissionTypes> {
  payload: any;
  type: EditTopicPermissionTypes.SUCCESS;
}

export interface IEditTopicPermissionErrorAction
  extends IReduxBaseAction<EditTopicPermissionTypes> {
  payload: any;
  type: EditTopicPermissionTypes.ERROR;
}

export type TSetEditTopicPermissionActions =
  | IEditTopicPermissionErrorAction
  | IEditTopicPermissionRequestAction
  | IEditTopicPermissionSuccessAction;
