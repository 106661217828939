import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import packageJson from "../../package.json";
import { AircraftGroupFilterSortResetAction } from "../actions/aircraftgroup/filter-sort/actions";
import { appFilterSortResetAction } from "../actions/application/filter-sort/actions";
import { changeHistoryFilterSortResetAction } from "../actions/changehistory/filter-sort/actions";
import { configAppFilterSortResetAction } from "../actions/configfile/app-filter-sort/actions";
import { configBrokerFilterSortResetAction } from "../actions/configfile/broker-filter-sort/actions";
import { tenantFilterSortResetAction } from "../actions/tenant/filter-sort/actions";
import { topicFilterSortResetAction } from "../actions/topic/filter-sort/actions";
import { userFilterSortResetAction } from "../actions/user/filter-sort/actions";
import { isCurrentUserRole } from "../helper/util";
import { ReactComponent as AppsImg } from "../img/AppsImg.svg";
import { ReactComponent as BrokerImg } from "../img/BrokerImg.svg";
import { ReactComponent as ChangesImg } from "../img/ChangesImg.svg";
import { ReactComponent as CollapseImg } from "../img/CollapseImg.svg";
import { ReactComponent as ConfigurationImg } from "../img/ConfigurationImg.svg";
import { ReactComponent as ExpandImg } from "../img/ExpandImg.svg";
import { ReactComponent as GroundsideImg } from "../img/GroundsideImg.svg";
import { ReactComponent as NextCloudLogo } from "../img/NextCloudLogo.svg";
import { ReactComponent as ProfileImg } from "../img/ProfileImg.svg";
import { ReactComponent as SignoutImg } from "../img/SignoutImg.svg";
import { ReactComponent as TopicsImg } from "../img/TopicsImg.svg";
import { ReactComponent as TopologyImg } from "../img/TopologyImg.svg";
import { ReactComponent as UmbProfileImg } from "../img/UmbProfileImg.svg";
import { ReactComponent as UsersImg } from "../img/UsersImg.svg";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../models/UserTypes";
import { RootState } from "../store";
import {
  HeaderContainer,
  HeaderPageSpacer,
  HeaderTitle,
  MenuContainer,
  MenuItem,
  SectionSeparator,
  Separator,
  StyledNavLink,
  VersionContainer,
} from "../styles/header";
import { STooltip } from "../styles/tooltip";

const MENU_COLLAPSE_STATE_KEY = "menuCollapseState";

export const Header = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { currentUser, editModal } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  // Load the initial state from local storage
  const [isCollapsed, setIsCollapsed] = React.useState(() => {
    const savedState = localStorage.getItem(MENU_COLLAPSE_STATE_KEY);

    return savedState ? JSON.parse(savedState) : false;
  });

  const handleClick = (e: React.MouseEvent<HTMLElement>, target: string) => {
    if (!editModal || !editModal.open) {
      e.preventDefault();
      router.history.push(target);
    }
  };

  const isMenuActive = (menu: any) => {
    const routeMatch = router.location.pathname.indexOf(menu.pathname) >= 0;

    if (menu.title === "Users" && currentUser.data) {
      return routeMatch && router.location.pathname !== `/users/${currentUser.data.userId}`;
    }

    if (menu.title === "Groundside configuration") {
      return (
        routeMatch ||
        router.location.pathname.indexOf("aircrafts") >= 0 ||
        router.location.pathname.indexOf("aircraftGroups") >= 0 ||
        router.location.pathname.indexOf("airlines") >= 0
      );
    }

    if (menu.title === "Application clients") {
      return routeMatch || router.location.pathname.indexOf("mqttSub") >= 0;
    }

    return routeMatch;
  };

  const menuElements = [
    {
      className: "ta-menu-item-application_clients",
      pathname: "/applicationClients",
      icon: AppsImg,
      resetActions: [appFilterSortResetAction()],
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
      title: "Application clients",
    },
    {
      className: "ta-menu-item-topics",
      pathname: "/topics",
      icon: TopicsImg,
      resetActions: [topicFilterSortResetAction()],
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
      title: "Topics",
    },
    {
      className: "ta-menu-item-broker_types",
      pathname: "/brokerTypes",
      icon: BrokerImg,
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
      title: "Broker types",
    },
    {
      className: "ta-menu-item-configuration_files",
      pathname: "/configurationFiles",
      icon: ConfigurationImg,
      resetActions: [configAppFilterSortResetAction(), configBrokerFilterSortResetAction()],
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
      title: "Configuration files",
    },
    {
      className: "ta-menu-item-users",
      pathname: "/users",
      icon: UsersImg,
      resetActions: [userFilterSortResetAction()],
      roles: [ADMINISTRATOR],
      title: "Users",
    },
    {
      className: "ta-menu-item-change_history",
      pathname: "/changeHistory",
      icon: ChangesImg,
      resetActions: [changeHistoryFilterSortResetAction()],
      roles: [ADMINISTRATOR],
      title: "Change history",
    },
    {
      className: "ta-menu-item-topology",
      pathname: "/topology",
      icon: TopologyImg,
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER],
      title: "Topology",
    },
    {
      className: "ta-menu-item-groundside",
      pathname: "/groundsideConfiguration",
      icon: GroundsideImg,
      resetActions: [tenantFilterSortResetAction(), AircraftGroupFilterSortResetAction()],
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER],
      title: "Groundside configuration",
    },
    {
      className: "ta-menu-item-my_profile",
      pathname: `/users/${currentUser.data ? currentUser.data.userId : ""}`,
      icon: UmbProfileImg,
      roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
      title: "My profile",
    },
  ];

  const toggleMenu = () => {
    const newState = !isCollapsed;
    setIsCollapsed(newState);
    localStorage.setItem(MENU_COLLAPSE_STATE_KEY, JSON.stringify(newState));
  };

  return (
    <>
      <HeaderPageSpacer className={isCollapsed ? "collapsed" : ""} />
      <HeaderContainer className={isCollapsed ? "collapsed" : ""}>
        <MenuContainer>
          <MenuItem>
            <HeaderTitle to={"/"}>
              <NextCloudLogo />
              <Separator />
              <span className="menu-item-label">UMB Config Tool</span>
            </HeaderTitle>
          </MenuItem>
          <SectionSeparator />

          {menuElements.map(
            (menu: any) =>
              isCurrentUserRole(currentUser, menu.roles) && (
                <MenuItem key={menu.pathname}>
                  <STooltip
                    title={menu.title}
                    placement="right"
                    disableHoverListener={!isCollapsed}
                  >
                    <StyledNavLink
                      className={menu.className}
                      to={menu.href ? menu.href : "#"}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (menu.resetActions && menu.resetActions.length > 0) {
                          menu.resetActions.forEach((action: any) => {
                            dispatch(action);
                          });
                        }
                        handleClick(e, menu.pathname);
                      }}
                      isActive={() => isMenuActive(menu)}
                    >
                      {menu.icon && <menu.icon />}
                      <span className="menu-item-label">{menu.title}</span>
                    </StyledNavLink>
                  </STooltip>
                </MenuItem>
              )
          )}
        </MenuContainer>

        <MenuContainer className="menu-container-bottom">
          <MenuItem>
            <STooltip title="Account" placement="right" disableHoverListener={!isCollapsed}>
              <StyledNavLink
                to={{ pathname: "https://portal.nextcloud.aero/app/profile" }}
                target="_top"
              >
                <ProfileImg />
                <span className="menu-item-label">Account</span>
              </StyledNavLink>
            </STooltip>
          </MenuItem>
          <MenuItem>
            <STooltip title="NEXT Cloud" placement="right" disableHoverListener={!isCollapsed}>
              <StyledNavLink to={{ pathname: "https://portal.nextcloud.aero/" }} target="_top">
                <SignoutImg />
                <span className="menu-item-label">NEXT Cloud</span>
              </StyledNavLink>
            </STooltip>
          </MenuItem>
        </MenuContainer>

        <MenuItem>
          <VersionContainer>Version {packageJson.version}</VersionContainer>

          <STooltip title={isCollapsed ? "Expand menu" : "Collapse menu"} placement="right">
            <StyledNavLink className="mini" onClick={toggleMenu} to="#" isActive={() => false}>
              {isCollapsed ? <ExpandImg /> : <CollapseImg />}
            </StyledNavLink>
          </STooltip>
        </MenuItem>
      </HeaderContainer>
    </>
  );
};
