import deepEqual from "deep-equal";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAllBrokerType } from "../../../actions/broker-type/get-all/actions";
import { getConfigVersionList } from "../../../actions/configversion/get-list/actions";
import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getFormattedDate } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { ConfigService } from "../../../services/ConfigService";
import { RootState } from "../../../store";
import { SActiveLink, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalDisplayConfigVersion } from "../../modals/ModalDisplayConfigVersion";
import { Table } from "../../Table";

export const ConfigurationVersionList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { configFileGenerate, currentUser } = useSelector((state: RootState) => ({
    configFileGenerate: state.configFileGenerate,
    currentUser: state.currentUser,
  }));

  const { configVersionList } = useSelector((state: RootState) => ({
    configVersionList: state.configVersionList,
  }));

  const { editModal } = useSelector((state: RootState) => ({
    editModal: state.editModal,
  }));

  const emptyFilterObject = {
    createdDate: { startDate: moment("2024-03-24T11:30:00Z"), endDate: undefined },
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "createdAt",
    orderType: OrderType.DESC,
    page: 0,
  });

  const configVersionTypeOptions = [
    {
      label: <span className="ta-dropdown-release">Release</span>,
      value: 1,
    },
    {
      label: <span className="ta-dropdown-test">Test</span>,
      value: 0,
    },
  ];

  // Get configs after "generate config"
  useEffect(() => {
    if (!configFileGenerate.loading && !configFileGenerate.error && !currentUser.loading) {
      if (!currentUser.data.internal) {
        const myFilter: any = { ...config.filter, isRelease: 0 };
        dispatch(getConfigVersionList({ ...config, filter: { ...myFilter } }));
      } else {
        dispatch(getConfigVersionList(config));
      }
    }
  }, [config, configFileGenerate, currentUser]);

  // useEffect(() => {
  //   dispatch(getConfigVersionList(config));
  // }, [config]);

  const openDetails = (row: ITableRow): void => {
    navigateToPage(`/configVersion/${row.configVersionId}`);
  };

  const headCells: ITableHeaderCells[] = [
    { id: "version", label: "Version" },
    {
      id: "isRelease",
      label: "Type",
      value: (row: ITableRow) => (row.isRelease ? "Release" : "Test"),
    },
    { id: "createdBy", label: "Created by" },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
    },
  ];

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "version",
        placeholder: "Version",
        taClass: "version",
      },
      {
        data: configVersionTypeOptions,
        name: "isRelease",
        placeholder: "Type",
        taClass: "type",
        type: FilterType.DROPDOWN,
      },
      {
        name: "createdBy",
        placeholder: "Created by",
        taClass: "createdBy",
        type: FilterType.TEXT,
      },
      {
        name: "createdDate",
        placeholder: "Created",
        taClass: "createdDate",
        type: FilterType.DATEPICKER,
      },
    ],
    pageName: "configurationAppList",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...configVersionList,
      onClickRow: openDetails,
    },
    name: "configVersion",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      <SSpacer />
      <Filter {...filterConfig} />
      {/* FILTER */}
      <SSpacer />

      {/* TABLE */}
      <Table {...tableProps}></Table>
    </>
  );
};
