import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum TGetMQTTClientDetails {
  ERROR = "GET_MQTT_CLIENT_DETAILS_ERROR",
  REQUEST = "GET_MQTT_CLIENT_DETAILS_REQUEST",
  SUCCESS = "GET_MQTT_CLIENT_DETAILS_SUCCESS",
}

export interface IGetMQTTClientDetailsRequestAction
  extends IReduxBaseAction<TGetMQTTClientDetails> {
  type: TGetMQTTClientDetails.REQUEST;
}

export interface IGetMQTTClientDetailsSuccessAction
  extends IReduxBaseAction<TGetMQTTClientDetails> {
  payload: any;
  type: TGetMQTTClientDetails.SUCCESS;
}

export interface IGetMQTTClientDetailsErrorAction extends IReduxBaseAction<TGetMQTTClientDetails> {
  payload: any;
  type: TGetMQTTClientDetails.ERROR;
}

export type IGetMQTTClientDetailsActionType =
  | IGetMQTTClientDetailsRequestAction
  | IGetMQTTClientDetailsSuccessAction
  | IGetMQTTClientDetailsErrorAction;
