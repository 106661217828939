import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditTopicTypes,
  IEditTopicErrorAction,
  IEditTopicRequestAction,
  IEditTopicSuccessAction,
  TSetEditTopicActions,
} from "./types";

export const EditTopicRequestAction = (data: any): IEditTopicRequestAction => ({
  payload: data,
  type: EditTopicTypes.REQUEST,
});
export const EditTopicSuccessAction = (data: any): IEditTopicSuccessAction => ({
  payload: data,
  type: EditTopicTypes.SUCCESS,
});
export const EditTopicErrorAction = (error: any): IEditTopicErrorAction => ({
  payload: error,
  type: EditTopicTypes.ERROR,
});

export const editTopic = (config: any, id: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetEditTopicActions | TEditModalActions>
) => {
  dispatch(EditTopicRequestAction(config));
  TopicService.updateTopic(config, id).then(
    (data: { data: any }) => {
      dispatch(EditTopicSuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: "Topic updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data && data.data.data && data.data.data.content && data.data.data.content.topicId);
    },
    (error: any) => {
      dispatch(EditTopicErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
