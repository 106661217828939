import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum DeleteAppVisibilityUserTypes {
  ERROR = "DELETE_APP_VISIBILITY_USER_ERROR",
  REQUEST = "DELETE_APP_VISIBILITY_USER_REQUEST",
  SUCCESS = "DELETE_APP_VISIBILITY_USER_SUCCESS",
}

export interface IDeleteAppVisibilityUserRequestAction
  extends IReduxBaseAction<DeleteAppVisibilityUserTypes> {
  type: DeleteAppVisibilityUserTypes.REQUEST;
}

export interface IDeleteAppVisibilityUserSuccessAction
  extends IReduxBaseAction<DeleteAppVisibilityUserTypes> {
  payload: any;
  type: DeleteAppVisibilityUserTypes.SUCCESS;
}

export interface IDeleteAppVisibilityUserErrorAction
  extends IReduxBaseAction<DeleteAppVisibilityUserTypes> {
  payload: any;
  type: DeleteAppVisibilityUserTypes.ERROR;
}

export type TDeleteAppVisibilityUserActions =
  | IDeleteAppVisibilityUserErrorAction
  | IDeleteAppVisibilityUserRequestAction
  | IDeleteAppVisibilityUserSuccessAction;
