import {
  TopicFilterSortType,
  TTopicFilterSortActions,
} from "../../actions/topic/filter-sort/types";
import { IFilterSortData as ITopicFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const topicFilterSortEmptyFilter: any = {
  modifiedBy: "",
  modifiedDate: { startDate: undefined, endDate: undefined },
  multicast: "",
  status: "",
  topicName: "",
  topicOwner: "",
  type: "",
};

export const topicFilterSortInitialState: ITopicFilterSortState = {
  filter: topicFilterSortEmptyFilter,
  limit: 10,
  orderBy: "topicName",
  orderType: OrderType.ASC,
  page: 0,
};

export const topicFilterSort = (
  state: ITopicFilterSortState = topicFilterSortInitialState,
  action: TTopicFilterSortActions
): ITopicFilterSortState => {
  switch (action.type) {
    case TopicFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...topicFilterSortEmptyFilter },
        page: 0,
      };
    case TopicFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...topicFilterSortInitialState,
      };
    case TopicFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
