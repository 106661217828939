import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { getTenantLabel } from "../../helper/util";
import { GroundDestinationTypeEnum, GroundEnvironmentTypeEnum } from "../../models/IDestination";
import { userTenantEnum } from "../../models/ITenant";
import { SFlexContainer, SFlexItem, SSpacer, SVerticalSpacer } from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";

import { DropdownInput } from "./Dropdownlnput";
import { ModalCreateBase } from "./ModalCreateBase";
import { TextInput } from "./TextInput";

export const ModalEditAppDestination = () => {
  //   const dispatch = useDispatch();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = true; // comment required?
  const [dialogTitle, setDialogTitle] = useState("Edit destination");
  const [isCommentValid, setIsCommentValid] = useState(!changeCommentRequired);
  const [isFormValid, setIsFormValid] = useState(false);

  const [config, setConfig] = useState({
    changeComment: "",
    destinationEndpoint: "",
    destinationType: "",
  });

  const gndDestinationTypeList: any[] = [
    { label: "AWS SQS URL", value: GroundDestinationTypeEnum.AWS_SQS_URL },
  ];

  useEffect(() => {
    if (editModal.destData) {
      setConfig({
        ...config,
        destinationEndpoint: editModal.destData.destinationEndpoint,
        destinationType: editModal.destData.destinationType,
      });
    }
  }, [editModal]);

  useEffect(() => {
    if (editModal.destData) {
      if (
        !!config.destinationEndpoint &&
        config.destinationEndpoint !== editModal.destData.destinationEndpoint &&
        config.destinationEndpoint.length > 5 &&
        !!config.destinationType
      ) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }

    if (changeCommentRequired) {
      setIsCommentValid(config.changeComment.length > 0);
    } else {
      setIsCommentValid(true);
    }
  }, [config]);

  const setChangeComment = (comment: any) => {
    setConfig({ ...config, changeComment: comment });
  };

  const handleDestinationTypeChange = (value: any) => {
    setConfig({ ...config, destinationType: value });
  };

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setConfig({ ...config, [field]: e.target.value });
    }
  };

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      editModal.popupData.onConfirm({ ...config });
    }
  };

  return (
    <ModalCreateBase title={dialogTitle}>
      <Text>
        Application:{" "}
        <span>
          {editModal.destData.appClient &&
            `${editModal.destData.appClient.appName} ${editModal.destData.appClient.appVersion}`}
        </span>
      </Text>

      <Text>
        {/* Environment: <span> */}
        Destination:{" "}
        <span>
          {!!editModal.destData.destinationEnvironment &&
            (editModal.destData.destinationEnvironment === GroundEnvironmentTypeEnum.PRODUCTION
              ? `Production`
              : `Integration ${Number(editModal.destData.index) + 1}`)}
        </span>
      </Text>

      <SSpacer height="10px" />

      <DropdownInput
        label="Destination type"
        name="dest-type"
        onChange={handleDestinationTypeChange}
        options={getTaClassedOptions(gndDestinationTypeList)}
        selected={config.destinationType}
      />

      <TextInput
        label={`Destination endpoint URL`}
        name="dest-url"
        placeholder={`AWS SQS URL`}
        value={config.destinationEndpoint}
        valid={
          !!config.destinationEndpoint &&
          config.destinationEndpoint.length > 5 &&
          config.destinationEndpoint.length < 1024
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "destinationEndpoint", 1024);
        }}
      />

      <SSpacer height="40px" />

      <Text variant="captionText" className="ta-modal-input-caption-comment">
        Comment:
      </Text>
      <Textarea
        className="ta-modal-input-comment"
        placeholder={``}
        value={config.changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "changeComment", 2048);
        }}
        // handleCloseButton={editModal.popupData.onCancel}
      />

      <SSpacer height="20px" />

      <SFlexContainer justifyContent="flex-end">
        <ButtonPrimary
          className="ta-modal-button-Save"
          onClick={handleConfirm}
          disabled={!isFormValid || !isCommentValid}
        >
          {`Save`}
        </ButtonPrimary>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
