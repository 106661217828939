import moment from "moment";
import { Grid, GridItem, ProgressIndicatorLinear, Textarea } from "next-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getTopicPayload } from "../../../actions/topic/get-payload/actions";
import { RootState } from "../../../store";
import {
  SOverlay,
  SPayloadSchema,
  STextDataTitle,
  STextDataValue,
  STextWrapper,
} from "../../../styles/styles";

export const TopicDetailsPayload = (props: { payloadId: string }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  enum fieldTypeEnum {
    DATE,
    STRING,
    TEXTAREA,
  }

  const { topicDetailsContent, topicPayload, topicPayloadContent } = useSelector(
    (state: RootState) => ({
      topicDetailsContent: state.topicDetails.content,
      topicPayload: state.topicPayload,
      topicPayloadContent: state.topicPayload.content,
    })
  );

  useEffect(() => {
    dispatch(getTopicPayload(topicDetailsContent.topic.topicPayloadId));
  }, [topicDetailsContent]);

  const topicPayloadColumns = [
    [
      {
        class: "ta-topic-payload-format",
        title: "Format",
        type: fieldTypeEnum.STRING,
        value: "payloadFormat",
      },
      {
        class: "ta-topic-payload-type",
        title: "Type",
        type: fieldTypeEnum.STRING,
        value: "payloadType",
      },
      {
        class: "ta-topic-payload-schema",
        title: "Schema",
        type: fieldTypeEnum.TEXTAREA,
        value: "payloadSchema",
      },
    ],
    [
      {
        class: "ta-topic-payload-createdat",
        title: "Created at",
        type: fieldTypeEnum.DATE,
        value: "createdAt",
      },
      {
        class: "ta-topic-payload-modifiedat",
        title: "Modified at",
        type: fieldTypeEnum.DATE,
        value: "modifiedAt",
      },
    ],
  ];

  return (
    <>
      {topicPayload.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {topicPayload.error && (
        <span className="ta-topic-payload-error">There is no payload for this topic.</span>
      )}
      {!topicPayload.loading && !topicPayload.error && (
        <Grid columns="2fr 1fr">
          {topicPayloadColumns.map((column: any, colIndex: any) => (
            <GridItem key={colIndex}>
              {column.map((field: any, index: any) => (
                <STextWrapper className={field.class} key={index}>
                  <STextDataTitle>{field.title}:</STextDataTitle>
                  {field.type === fieldTypeEnum.STRING ? (
                    <STextDataValue>{topicPayloadContent[field.value]}</STextDataValue>
                  ) : (
                    ""
                  )}
                  {field.type === fieldTypeEnum.DATE ? (
                    <STextDataValue>
                      {moment(topicPayloadContent[field.value])
                        // .tz("America/Los_Angeles")
                        .tz(moment.tz.guess())
                        .format("MMM/DD/YYYY HH:mm")}
                    </STextDataValue>
                  ) : (
                    ""
                  )}
                  {field.type === fieldTypeEnum.TEXTAREA ? (
                    <SPayloadSchema
                      placeholder=""
                      readOnly={true}
                      value={topicPayloadContent[field.value]}
                      height={500}
                      onChange={() => {
                        return;
                      }}
                    />
                  ) : (
                    ""
                  )}
                </STextWrapper>
              ))}
            </GridItem>
          ))}
        </Grid>
      )}
    </>
  );
};
