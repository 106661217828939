export enum userRoleStatusEnum {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  DENIED = "DENIED",
}

export const getUserRoleStatusByEnum = (enumValue: userRoleStatusEnum): string => {
  let statusEnum = "";
  switch (enumValue) {
    case userRoleStatusEnum.ACTIVE:
      statusEnum = "Active";
      break;
    case userRoleStatusEnum.PENDING:
      statusEnum = "Pending";
      break;
    case userRoleStatusEnum.DENIED:
      statusEnum = "Denied";
      break;
    default:
      statusEnum = enumValue;
  }

  return statusEnum;
};
