import {
  ChangeHistoryFilterSortType,
  TChangeHistoryFilterSortActions,
} from "../../actions/changehistory/filter-sort/types";
import { IFilterSortData as IChangeHistoryFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const changeHistoryFilterSortEmptyFilter: any = {
  appName: "",
  brokerType: "",
  changeType: "",
  comment: "",
  configVersion: "",
  modifiedBy: "",
  modifiedDate: { startDate: undefined, endDate: undefined },
  topicName: "",
  topicStatus: "",
};

export const changeHistoryFilterSortInitialState: IChangeHistoryFilterSortState = {
  filter: changeHistoryFilterSortEmptyFilter,
  limit: 20,
  orderBy: "modifiedAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const changeHistoryFilterSort = (
  state: IChangeHistoryFilterSortState = changeHistoryFilterSortInitialState,
  action: TChangeHistoryFilterSortActions
): IChangeHistoryFilterSortState => {
  switch (action.type) {
    case ChangeHistoryFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...changeHistoryFilterSortEmptyFilter },
        page: 0,
      };
    case ChangeHistoryFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...changeHistoryFilterSortInitialState,
      };
    case ChangeHistoryFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
