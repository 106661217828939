import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateTopicTypes,
  ICreateTopicErrorAction,
  ICreateTopicRequestAction,
  ICreateTopicSuccessAction,
  TCreateTopicActions,
} from "./types";

export const createTopicRequestAction = (data: any): ICreateTopicRequestAction => ({
  payload: data,
  type: CreateTopicTypes.REQUEST,
});
export const createTopicSuccessAction = (data: any): ICreateTopicSuccessAction => ({
  payload: data,
  type: CreateTopicTypes.SUCCESS,
});
export const createTopicErrorAction = (error: any): ICreateTopicErrorAction => ({
  payload: error,
  type: CreateTopicTypes.ERROR,
});

// ACTION
export const createTopic = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TCreateTopicActions | TEditModalActions>
) => {
  dispatch(createTopicRequestAction(config));
  TopicService.createTopic(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(createTopicSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Topic created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.topicId);
    },
    (error: any) => {
      dispatch(createTopicErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
