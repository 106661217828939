import { ButtonPrimary, ProgressIndicatorCircular, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { uploadPayload } from "../../actions/payload/upload/actions";
import { getTaClassedOptions } from "../../helper/dropdown";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { payloadFormatEnum, topicStatusEnum } from "../../models/ITopicConfig";
import { RootState } from "../../store";
import { SChip, SFlexContainer } from "../../styles/styles";
import { FileInput } from "../FileInput";
import { StatusIcon } from "../StatusIcon";

import { DropdownInput } from "./Dropdownlnput";
import { PayloadSchemaEditor } from "./PayloadSchemaEditor";

export const ModalCreateTopicPage2 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { payloadGet, payloadUpload } = useSelector((state: any) => ({
    payloadGet: state.payloadGet,
    payloadUpload: state.payloadUpload,
  }));

  const payloadFormatOptions: any[] = [
    { label: "JSON", value: payloadFormatEnum.JSON },
    { label: "PROTOBUF", value: payloadFormatEnum.PROTOBUF },
  ];

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  const handlePayloadFormatDropdown = (value: payloadFormatEnum) => {
    setUpdatedConfig({
      ...props.config,
      payloadConfig: { ...props.config.payloadConfig, payloadFormat: value },
    });
  };

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [props.config]);

  const handleClearFileName = () => {
    setUpdatedConfig({
      ...props.config,
      payloadConfig: {
        ...props.config.payloadConfig,
        payloadFile: File,
        payloadFileName: "",
        payloadFormat: "",
        payloadSchema: "",
        topicPayloadId: "",
      },
    });
  };

  const handleUpload = (payloadFile: File) => {
    setUpdatedConfig({
      ...props.config,
      payloadConfig: { ...props.config.payloadConfig, payloadFileName: payloadFile.name },
    });
    dispatch(
      uploadPayload({ ...props.config.payloadConfig, payloadFile }, (response: any) => {
        if (response && response.payloadSchema && response.payloadId) {
          setUpdatedConfig({
            ...props.config,
            payloadConfig: {
              ...props.config.payloadConfig,
              payloadFileName: payloadFile.name,
              payloadSchema:
                props.config.payloadConfig.payloadFormat === payloadFormatEnum.JSON
                  ? JSON.stringify(response.payloadSchema, undefined, 4)
                  : response.payloadSchema,
              topicPayloadId: response.payloadId,
            },
          });
        }
      })
    );
  };

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-topic-name">
        Topic name{" "}
        <StatusIcon
          className={
            props.config.topic &&
            props.config.topic.topicName &&
            props.config.topic.topicStatus !== topicStatusEnum.ONHOLD
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={
            props.config.topic &&
            props.config.topic.topicName &&
            props.config.topic.topicStatus !== topicStatusEnum.ONHOLD
          }
        />
      </Text>

      <Text className="ta-modal-text-topic">
        Topic:{" "}
        <span className="ta-modal-text-topic-value">
          {props.config.topic && props.config.topic.topicName}
        </span>
      </Text>

      {(payloadGet.loading || payloadUpload.loading) && <ProgressIndicatorCircular size="small" />}

      {props.config.payloadConfig.topicPayloadId === "" &&
        props.config.payloadConfig.payloadFileName === "" && (
          <DropdownInput
            label="Payload format:"
            name="payloadFormat"
            onChange={handlePayloadFormatDropdown}
            options={getTaClassedOptions(payloadFormatOptions)}
            selected={
              props.config && props.config.payloadConfig && props.config.payloadConfig.payloadFormat
            }
          />
        )}
      {props.config.payloadConfig.topicPayloadId === "" &&
        props.config.payloadConfig.payloadFormat !== "" &&
        props.config.payloadConfig.payloadFileName === "" && (
          <FileInput
            onChange={(e: any) => {
              handleUpload(e);
            }}
            disabled={props.config.payloadConfig.payloadFormat === ""}
          />
        )}
      {props.config.topicPayloadId !== "" && props.config.payloadConfig.payloadFileName !== "" && (
        <SChip
          className="ta-chip-payloadFile"
          tabIndex="0"
          label={props.config.payloadConfig.payloadFileName}
          size="default"
          onClick={() => {
            if (!props.isEditing) {
              handleClearFileName();
            }
          }}
          disabled={props.isEditing}
        />
      )}

      <Text variant="captionText" className="ta-modal-input-caption-schema">
        Schema:
      </Text>
      {!props.isEditing ? (
        <>
          <Textarea
            placeholder="Schema"
            readOnly={true}
            value={props.config.payloadConfig.payloadSchema}
          />
          {props.config.payloadConfig.payloadSchema && (
            <SFlexContainer justifyContent="flex-start">
              <ButtonPrimary
                className={getTAClass("addTopic", TA_TYPES.BUTTON, "editSchema")}
                onClick={() => {
                  props.onToggleEditing(true);
                }}
              >
                Edit schema
              </ButtonPrimary>
            </SFlexContainer>
          )}
        </>
      ) : (
        <PayloadSchemaEditor
          payloadData={props.config.payloadConfig}
          callback={(response: any) => {
            setUpdatedConfig({
              ...props.config,
              payloadConfig: {
                ...props.config.payloadConfig,
                payloadSchema: response.payloadSchema,
                topicPayloadId: response.payloadId,
              },
            });
            props.onToggleEditing(false);
          }}
          cancel={() => {
            props.onToggleEditing(false);
          }}
        />
      )}
    </>
  );
};
