import {
  ButtonIcon,
  ButtonPrimary,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalCloseAction, editModalOpenAction } from "../../actions/modal/edit/actions";
import { popupOpenAction } from "../../actions/popup/actions";
import { deleteTopic } from "../../actions/topic/delete/actions";
import { editTopic } from "../../actions/topic/edit/actions";
import { getTopicDetails } from "../../actions/topic/get-details/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { isUserAppOwner } from "../../helper/util";
import { changeRequestActionEnum, changeRequestStatusEnum } from "../../models/IChangeRequest";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { OrderType } from "../../models/ITableConfig";
import { IGetTopicListData, topicStatusEnum } from "../../models/ITopicConfig";
import { tabEnum } from "../../models/ITopicDetailsConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { TopicService } from "../../services/TopicService";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ModalAddApplicationSubscriber } from "../modals/ModalAddApplicationSubscriber";
import { ModalDisplayHistoryItems } from "../modals/ModalDisplayHistoryItems";
import { ModalEditComment } from "../modals/ModalEditComment";
import { ModalEditTopic } from "../modals/ModalEditTopic";
import { ModalRequestTopicActivation } from "../modals/ModalRequestTopicActivation";
import { PageError, PageLoading } from "../PageState";

import { TopicBridgesGraph } from "./TopicDetails/TopicBridgesGraph";
import { TopicDetailsBridgesList } from "./TopicDetails/TopicBridgesList";
import { TopicChangeHistories } from "./TopicDetails/TopicChangeHistories";
import { TopicDetailsMainDetails } from "./TopicDetails/TopicDetailsMainDetails";
import { TopicDetailsPayload } from "./TopicDetails/TopicDetailsPayload";
import { TopicDetailsPermissionsTab } from "./TopicDetails/TopicDetailsPermissionsTab";
import { TopicDetailsPublishList } from "./TopicDetails/TopicDetailsPublishList";
import { TopicDetailsSubscriberList } from "./TopicDetails/TopicDetailsSubscribeList";
import { TopicDetailsTabDetails } from "./TopicDetails/TopicDetailsTabDetails";
import { TopicDetailsTabs } from "./TopicDetails/TopicDetailsTabs";

// tslint:disable-next-line: cyclomatic-complexity
export const TopicDetails = ({ history, match }: { history: any; match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [topicId, setTopicId] = useState("");
  const [isTopicAdmin, setIsTopicAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAppOwner, setIsAppOwner] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isNeedGrantDenyButtons, setIsNeedGrantDenyButtons] = useState(false);
  const [activeTab, setActiveTab] = useState(tabEnum.DETAILS);
  const [requestParamAction, setRequestParamAction] = useState("");
  const [requestParamRequestId, setRequestParamRequestId] = useState("");
  const [showPermissionsTab, setShowPermissionsTab] = useState(false);

  const { currentUser, editModal, topicDetails } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    topicDetails: state.topicDetails,
  }));

  const actionQueryParamKey = "action";
  const requestIdQueryParamKey = "requestId";

  useEffect(() => {
    if (match.params.topicId !== "" && typeof match.params.topicId !== "undefined") {
      setTopicId(match.params.topicId);
      dispatch(getTopicDetails(match.params.topicId));
    }
  }, [match.params.topicId]);

  const changeStateSuccessCallback = () => {
    dispatch(getTopicDetails(topicId));
  };

  const handleActivationRequestApproval = (param: any): void => {
    if (isAdmin || isApprover) {
      dispatch(editModalCloseAction());
      dispatch(
        editTopic(
          {
            changeComment: param.changeComment,
            topicStatusChangeRequestAction: param.isApproved
              ? changeRequestActionEnum.APPROVE
              : changeRequestActionEnum.DISAPPROVE,
            topicStatusChangeRequestId:
              topicDetails.content.topic.latestStatusChangeRequest.requestId,
          },
          topicId,
          changeStateSuccessCallback
        )
      );

      history.push(history.location.pathname);
    }
  };

  const askActivationRequestApproval = (isApproved: boolean): void => {
    if (isAdmin || isApprover) {
      dispatch(
        editModalOpenAction({
          popupData: {
            commentHint: `Please enter a comment about why you are ${
              isApproved ? "approving" : "disapproving"
            } this request.`,
            commentRequired: true,
            contentText: `Are you sure you want to ${
              isApproved ? "approve" : "disapprove"
            } this topic status change request?`,
            onConfirm: (param: any) => {
              handleActivationRequestApproval({ isApproved, changeComment: param.changeComment });
            },
            title: `${isApproved ? "Approve" : "Disapprove"} change request`,
          },
          type: isApproved ? modalTypeEnum.APPROVE_REQUEST : modalTypeEnum.DISAPPROVE_REQUEST,
        })
      );
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      topicDetails &&
      topicDetails.content &&
      topicDetails.content.topic &&
      !topicDetails.loading
    ) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);
      setIsAppOwner(currentUser.data.role === APPLICATION_OWNER);
      setIsApprover(currentUser.data.role === APPROVER);
      setIsTopicAdmin(
        topicDetails.content.topic.appClient &&
          // topicDetails.content.topic.appClient.appOwner &&
          // topicDetails.content.topic.appClient.appOwner.userId === currentUser.data.userId
          topicDetails.content.topic.appClient.appOwners &&
          isUserAppOwner(currentUser.data, topicDetails.content.topic.appClient.appOwners)
      );
      setIsDeleted(topicDetails.content.topic.status.toUpperCase() === topicStatusEnum.DELETED);
      setIsOnHold(topicDetails.content.topic.status.toUpperCase() === topicStatusEnum.ONHOLD);
      setIsDraft(topicDetails.content.topic.status.toUpperCase() === topicStatusEnum.DRAFT);

      setIsNeedGrantDenyButtons(
        topicDetails.content.topic.status.toUpperCase() === topicStatusEnum.DRAFT &&
          !!topicDetails.content.topic.latestStatusChangeRequest &&
          topicDetails.content.topic.latestStatusChangeRequest.status ===
            changeRequestStatusEnum.PENDING &&
          (currentUser.data.role === ADMINISTRATOR || currentUser.data.role === APPROVER)
      );

      // handle email 'Approve/Disapprove' link click
      const queryParams = new URLSearchParams(history.location.search);
      let paramKey = queryParams.get(actionQueryParamKey);
      if (paramKey) {
        setRequestParamAction(paramKey);
      }
      paramKey = queryParams.get(requestIdQueryParamKey);
      if (paramKey) {
        setRequestParamRequestId(paramKey);
      }
    }
  }, [topicDetails, currentUser]);

  useEffect(() => {
    // process email 'Approve/Disapprove' link click
    if (!!requestParamAction && !!requestParamRequestId) {
      if (
        requestParamRequestId === topicDetails.content.topic.latestStatusChangeRequest.requestId &&
        topicDetails.content.topic.latestStatusChangeRequest.status ===
          changeRequestStatusEnum.PENDING
      ) {
        if (requestParamAction.toUpperCase() === changeRequestActionEnum.APPROVE) {
          askActivationRequestApproval(true);
        } else if (requestParamAction.toUpperCase() === changeRequestActionEnum.DISAPPROVE) {
          askActivationRequestApproval(false);
        }
      } else {
        dispatch(
          popupOpenAction({
            content:
              "The topic status change request might have been processed or updated in a newer request.",
            title: "Invalid topic status change request",
            type: "Info",
          })
        );
      }
    }
  }, [requestParamAction, requestParamRequestId]);

  useEffect(() => {
    setShowPermissionsTab(isAdmin || isTopicAdmin);
  }, [isAdmin, isTopicAdmin]);

  const handleTabChange = (id: tabEnum) => {
    setActiveTab(id);
  };

  const handleDeleteSuccessCb = (topicIdUpdated: any) => {
    dispatch(getTopicDetails(topicIdUpdated));
  };

  const handleDeleteTopic = (param: any) => {
    dispatch(editModalCloseAction());
    dispatch(deleteTopic(topicId, param.changeComment, handleDeleteSuccessCb));
  };

  const askConfirmationTopicDelete = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are deleting this topic.",
          commentRequired: true,
          contentText: "Are you sure you want to delete this topic?",
          onConfirm: handleDeleteTopic,
          title: `Delete topic`,
        },
        type: modalTypeEnum.DELETE_TOPIC,
      })
    );
  };

  const handlePutOnHold = (param: any) => {
    dispatch(editModalCloseAction());
    dispatch(
      editTopic(
        {
          changeComment: param.changeComment,
          status: topicStatusEnum.ONHOLD,
        },
        topicId,
        changeStateSuccessCallback
      )
    );
  };

  const askConfirmationTopicPutOnHold = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are putting this topic on hold.",
          commentRequired: true,
          contentText: "Are you sure you want to put this topic on hold?",
          onConfirm: handlePutOnHold,
          title: `Put topic on hold`,
        },
        type: modalTypeEnum.PUT_ON_HOLD_TOPIC,
      })
    );
  };

  const handleActivate = (param: any) => {
    dispatch(editModalCloseAction());
    dispatch(
      editTopic(
        {
          changeComment: param.changeComment,
          status: topicStatusEnum.ACTIVE,
        },
        topicId,
        changeStateSuccessCallback
      )
    );
  };

  const askConfirmationTopicActivate = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are activating this topic.",
          commentRequired: true,
          contentText: "Are you sure you want to activate this topic?",
          onConfirm: handleActivate,
          title: `Activate topic`,
        },
        type: modalTypeEnum.ACTIVATE_TOPIC,
      })
    );
  };

  const handleRequestActivation = (param: any) => {
    dispatch(editModalCloseAction());
    dispatch(
      editTopic(
        {
          changeComment: param.changeComment,
          status: topicStatusEnum.ACTIVE,
        },
        topicId,
        changeStateSuccessCallback
      )
    );
  };

  const askRequestTopicActivation = () => {
    const config = {
      filter: {
        topicId: match.params.topicId,
      },
      limit: 10000,
      orderBy: "topicName",
      orderType: OrderType.ASC,
      page: 0,
    };

    TopicService.getTopicClientList(config).then(
      (response: { data: { data: IGetTopicListData } }) => {
        const topicClientList = response.data.data;
        const canActivate =
          topicClientList &&
          topicClientList.content &&
          topicClientList.content.some((client: any) => client.clientType === "SUBSCRIBER") &&
          topicClientList.content.some((client: any) => client.clientType === "PUBLISHER");

        if (!canActivate) {
          dispatch(
            popupOpenAction({
              content: "A topic must have at least one subscriber and one publisher to activate",
              title: "Topic not ready",
              type: "Info",
            })
          );
        } else {
          dispatch(
            editModalOpenAction({
              popupData: {
                onConfirm: handleRequestActivation,
              },
              topicData: { ...topicDetails.content },
              type: modalTypeEnum.REQUEST_TOPIC_ACTIVATION,
            })
          );
        }
      },
      (error: any) =>
        dispatch(
          popupOpenAction({
            content: error,
            title: "Network error. Please try again.",
            type: "Info",
          })
        )
    );
  };

  const handleEditTopic = () => {
    dispatch(
      editModalOpenAction({
        appData: { ...topicDetails.content.topic.appClient },
        topicData: {
          ...topicDetails.content.topic,
          topicPayloadId: topicDetails.content.topicPayloadId,
        },
        type: modalTypeEnum.EDIT_TOPIC,
      })
    );
  };

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link to="/topics">
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading>Topic details</SPageHeading>
          </GridItem>
        </Grid>

        {topicDetails.loading && <PageLoading />}

        {topicDetails.error && <PageError />}

        {!topicDetails.loading && !topicDetails.error && (
          <>
            {/* CONTENT */}
            {/* PAGE TITLE AND BUTTONS */}
            <Grid>
              <GridItem>
                <SFlexContainer justifyContent="space-between">
                  <SFlexItem>
                    <Heading
                      variant="heading2"
                      className={getTAClass("topicDetails", TA_TYPES.TITLE, "topicName")}
                    >
                      {topicDetails &&
                        topicDetails.content &&
                        topicDetails.content.topic &&
                        topicDetails.content.topic.topicName}
                    </Heading>
                  </SFlexItem>
                  <SFlexItem>
                    <ButtonPrimary
                      className={getTAClass("topicDetails", TA_TYPES.BUTTON, "edit")}
                      disabled={!(isAdmin || isTopicAdmin) || isDeleted}
                      onClick={handleEditTopic}
                    >
                      Edit
                    </ButtonPrimary>
                    <SVerticalSpacer />
                    {(isDraft && (isAdmin || (isApprover && !isTopicAdmin))) || isOnHold ? (
                      <ButtonPrimary
                        className={getTAClass("topicDetails", TA_TYPES.BUTTON, "activate")}
                        onClick={askConfirmationTopicActivate}
                        disabled={
                          !(isAdmin || isTopicAdmin) ||
                          (isApprover && !isTopicAdmin) ||
                          isDeleted ||
                          isNeedGrantDenyButtons
                        }
                      >
                        Activate
                      </ButtonPrimary>
                    ) : isDraft ? (
                      <ButtonPrimary
                        className={getTAClass(
                          "topicDetails",
                          TA_TYPES.BUTTON,
                          "request-activation"
                        )}
                        onClick={askRequestTopicActivation}
                        disabled={!isTopicAdmin}
                      >
                        Request activation
                      </ButtonPrimary>
                    ) : (
                      <ButtonPrimary
                        className={getTAClass("topicDetails", TA_TYPES.BUTTON, "onhold")}
                        onClick={askConfirmationTopicPutOnHold}
                        disabled={!(isAdmin || isTopicAdmin) || isDeleted}
                      >
                        Put on hold
                      </ButtonPrimary>
                    )}
                    <SVerticalSpacer />
                    <ButtonPrimary
                      className={getTAClass("topicDetails", TA_TYPES.BUTTON, "delete")}
                      onClick={askConfirmationTopicDelete}
                      disabled={!(isAdmin || isTopicAdmin) || isDeleted}
                    >
                      Delete
                    </ButtonPrimary>
                  </SFlexItem>
                </SFlexContainer>
              </GridItem>
            </Grid>
            <SSpacer />

            {/* TOPIC DETAILS */}
            <TopicDetailsMainDetails
              data={topicDetails && topicDetails.content.topic}
              isNeedGrantDenyButtons={isNeedGrantDenyButtons}
              handleChangeRequest={askActivationRequestApproval}
            />

            <SSpacer />

            {/* TABS */}
            <TopicDetailsTabs
              activeTab={activeTab}
              onChange={handleTabChange}
              showPermissionsTab={showPermissionsTab}
            />
            <SSpacer />

            {/* TAB CONTENTS */}
            {activeTab === tabEnum.SUBSCRIBERS && (
              <TopicDetailsSubscriberList userData={currentUser.data} topicDetails={topicDetails} />
            )}
            {activeTab === tabEnum.PUBLISHERS && (
              <TopicDetailsPublishList userData={currentUser.data} />
            )}
            {activeTab === tabEnum.BRIDGES && (
              <TopicDetailsBridgesList data={topicDetails && topicDetails.content.topic} />
            )}
            {activeTab === tabEnum.DETAILS && (
              <TopicDetailsTabDetails data={topicDetails && topicDetails.content.topic} />
            )}
            {activeTab === tabEnum.PAYLOAD && (
              <TopicDetailsPayload
                payloadId={
                  topicDetails &&
                  topicDetails.content.topic &&
                  topicDetails.content.topic.topicPayloadId
                }
              />
            )}
            {activeTab === tabEnum.TOPOLOGY && (
              <TopicBridgesGraph data={topicDetails && topicDetails.content.topic} />
            )}
            {showPermissionsTab && activeTab === tabEnum.PERMISSIONS && (
              <TopicDetailsPermissionsTab
                topicData={topicDetails && topicDetails.content.topic}
                editModal={editModal}
                userData={currentUser.data}
              />
            )}
            {activeTab === tabEnum.HISTORY && (
              <TopicChangeHistories
                data={topicDetails && topicDetails.content.topic}
                userData={currentUser.data}
              />
            )}
          </>
        )}
      </SPageContainer>

      {editModal.open && editModal.type === modalTypeEnum.EDIT_TOPIC && <ModalEditTopic />}
      {editModal.open &&
        (editModal.type === modalTypeEnum.ACTIVATE_TOPIC ||
          editModal.type === modalTypeEnum.APPROVE_REQUEST ||
          editModal.type === modalTypeEnum.DELETE_MQTT ||
          editModal.type === modalTypeEnum.DELETE_MQTT_BULK ||
          editModal.type === modalTypeEnum.DELETE_TOPIC ||
          editModal.type === modalTypeEnum.DISAPPROVE_REQUEST ||
          editModal.type === modalTypeEnum.PUT_ON_HOLD_TOPIC) && <ModalEditComment />}
      {editModal.open && editModal.type === modalTypeEnum.DISPLAY_HISTORY_ITEMS && (
        <ModalDisplayHistoryItems />
      )}
      {editModal.open && editModal.type === modalTypeEnum.REQUEST_TOPIC_ACTIVATION && (
        <ModalRequestTopicActivation />
      )}
      {editModal.open && editModal.type === modalTypeEnum.SELECT_APP_SUBSCRIPTION && (
        <ModalAddApplicationSubscriber />
      )}
    </>
  );
};
