import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { SFlexItem, SModalStackItem, SSpacer, SVerticalSpacer } from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import {
  ClientPasswordValidationErrorCodeEnum as ClientPasswordError,
  ClientPasswordValidator,
} from "../ClientPasswordValidator";
import { ClientUsernameValidator } from "../ClientUsernameValidator";
import { StatusIcon } from "../StatusIcon";
import { TypeaheadUser } from "../TypeaheadUser";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";

export const ModalCreateAirlinePage1 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();

  const MAX_CHARACTER_LIMIT = 100;

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [updatedConfig]);

  const validateTextInput = (e: any) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > MAX_CHARACTER_LIMIT) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (e.target.value === " " || !validateTextInput(e)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleNumberInput = (value: number, field: string) => {
    if (isNaN(value)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: value });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  return (
    <>
      {/* <Text variant="captionText" className="ta-modal-input-caption-app-location">
        App location:{" "}
        {props.config.appClientLocation === brokerLocationEnum.GROUNDSIDE
          ? "Groundside"
          : "Airside"}
      </Text> */}

      <TextInput
        label="Search airline by ICAO"
        name="airline-icao"
        placeholder="ICAO"
        valid={
          /* TODO */
          props.config.icao.length >= 3 && props.config.icao.length <= 8
        }
        value={props.config.icao}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "icao");
        }}
      />
    </>
  );
};
