import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IBrokerTypeListingTableConfig } from "../models/IBrokerTypeListingTableConfig";

import { axiosInstance } from "./AxiosInstance";

const getBrokerTypeList = async (conf: IBrokerTypeListingTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;
  const query = `?${pagination}${queryFilterHelper(conf)}`;
  const axios = await axiosInstance();

  return axios.get(`/brokers${query}`);
};

const getBrokerTypeDetails = async (brokerTypeId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/brokers/${brokerTypeId}`);
};

export const BrokerTypeService = {
  getBrokerTypeDetails,
  getBrokerTypeList,
};
