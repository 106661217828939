import { css } from "styled-components";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      ", ": ", ";\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}

export default function(cssProperty, propValue) {
  if (cssProperty) {
    return css(_templateObject(), cssProperty, propValue);
  }
}
