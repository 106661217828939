import {
  TenantFilterSortType,
  TTenantFilterSortActions,
} from "../../actions/tenant/filter-sort/types";
import { IFilterSortData as ITenantFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";
import { TenantTypeEnum } from "../../models/ITenant";

export const tenantFilterSortEmptyFilter: any = {
  icao: "",
  modifiedBy: "",
  modifiedDate: { startDate: undefined, endDate: undefined },
  status: "",
  tenantId: "",
  tenantName: "",
  tenantType: TenantTypeEnum.AIRLINE,
};

export const tenantFilterSortInitialState: ITenantFilterSortState = {
  filter: tenantFilterSortEmptyFilter,
  limit: 10,
  orderBy: "modifiedAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const tenantFilterSort = (
  state: ITenantFilterSortState = tenantFilterSortInitialState,
  action: TTenantFilterSortActions
): ITenantFilterSortState => {
  switch (action.type) {
    case TenantFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...tenantFilterSortEmptyFilter },
        page: 0,
      };
    case TenantFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...tenantFilterSortInitialState,
      };
    case TenantFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
