import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { tabAircraftGroupDetailsEnum } from "../../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";

export const AircraftGroupDetailsTabs = (props: {
  activeTab: any;
  groupEnv: any;
  onChange: any;
}) => (
  <Grid columns="0.6fr">
    <GridItem>
      <TabContainer>
        {props.groupEnv === GroundEnvironmentTypeEnum.PRODUCTION && (
          <Tab
            className="ta-aircraft-group-details-tab-aircrafts"
            onClick={() => {
              props.onChange(tabAircraftGroupDetailsEnum.AIRCRAFTS);
            }}
            active={props.activeTab === tabAircraftGroupDetailsEnum.AIRCRAFTS}
          >
            Aircrafts
          </Tab>
        )}
        {props.groupEnv === GroundEnvironmentTypeEnum.INTEGRATION && (
          <Tab
            className="ta-aircraft-group-details-tab-racks"
            onClick={() => {
              props.onChange(tabAircraftGroupDetailsEnum.RACKS);
            }}
            active={props.activeTab === tabAircraftGroupDetailsEnum.RACKS}
          >
            Racks
          </Tab>
        )}
        <Tab
          className="ta-aircraft-group-details-tab-details"
          onClick={() => {
            props.onChange(tabAircraftGroupDetailsEnum.DETAILS);
          }}
          active={props.activeTab === tabAircraftGroupDetailsEnum.DETAILS}
        >
          Details
        </Tab>
        {/* <Tab
          className="ta-aircraft-group-details-tab-history"
          onClick={() => {
            props.onChange(tabAircraftGroupDetailsEnum.HISTORY);
          }}
          active={props.activeTab === tabAircraftGroupDetailsEnum.HISTORY}
        >
          Change history
        </Tab> */}
      </TabContainer>
    </GridItem>
  </Grid>
);
