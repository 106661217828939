import { Dispatch } from "redux";

import { IUserListingTableConfig } from "../../../models/IUserListingTableConfig";
import { UserService } from "../../../services/UserService";

import {
  GetUserListType,
  IGetUserListErrorAction,
  IGetUserListRequestAction,
  IGetUserListSuccessAction,
  TUserListActions,
} from "./types";

export const userListRequestAction = (): IGetUserListRequestAction => ({
  type: GetUserListType.REQUEST,
});

export const userListSuccessAction = (data: any): IGetUserListSuccessAction => ({
  payload: data,
  type: GetUserListType.SUCCESS,
});

export const userListErrorAction = (error: any): IGetUserListErrorAction => ({
  payload: error,
  type: GetUserListType.ERROR,
});

export const getUserList = (conf: IUserListingTableConfig) => (
  dispatch: Dispatch<TUserListActions>
) => {
  dispatch(userListRequestAction());
  UserService.getUserList(conf).then(
    (data: { data: any }) => {
      if (
        conf &&
        conf.roles &&
        data.data &&
        data.data.data &&
        data.data.data.content &&
        conf.roles.length > 0
      ) {
        data.data.data.content = data.data.data.content.filter(
          (user: any) => conf.roles.indexOf(user.role) !== -1
        );
      }
      dispatch(userListSuccessAction(data.data));
    },
    (error: any) => dispatch(userListErrorAction(error))
  );
};
