import { OrderType } from "./ITableConfig";

export enum AppDestinationRouteTypeEnum {
  /**
   * Aircraft group
   */
  GROUP = "group",
  /**
   * Tenant
   */
  TENANT = "tenant",
}

export interface IDestinationRouteDetailsData {
  aircraftGroup?: any;
  appClientDestination?: any;
  appClientDestinationRouteId?: string;
  // created_at?: string;
  // created_by?: string;
  modified_at?: string;
  modified_by?: string;
  routeType?: string;
  tenant?: any;
}

interface IDestinationRouteListFilters extends IDestinationRouteDetailsData {
  [key: string]: any;
}

export interface IDestinationRouteListTableConfig {
  [key: string]: any;
  filter: IDestinationRouteListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IDestinationRouteListData {
  content: any[];
  count: number;
}
