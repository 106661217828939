import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getUserList } from "../actions/user/get-list/actions";
import { RootState } from "../store";

import { Typeahead } from "./Typeahead";

export const TypeaheadUser = (props: any) => {
  const { userList } = useSelector((state: RootState) => ({
    userList: state.userList,
  }));

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  return (
    <Typeahead
      pageSelector={props.pageSelector}
      disabled={props.disabled}
      config={{
        default: {
          filter: {
            email: "",
            firstName: "",
            fullName: "",
            lastName: "",
            role: "",
            userId: "",
          },
          orderBy: "firstName",
          roles: [
            "ADMINISTRATOR",
            "APPLICATION_OWNER",
            "APPROVER",
          ],
        },
        setFilter: (fullName: string) => ({
          fullName,
        }),
      }}
      suggestion={{
        getList: (config: any) => dispatch(getUserList(config)),
        list: userList,
        select: (user: any) => `${user.firstName} ${user.lastName}`,
      }}
      placeholder="Application owner"
      name="username"
      value={props.value}
      handleTypeahead={props.onChange}
      pattern="{firstName} {lastName}"
    />
  );
};
