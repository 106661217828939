import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditAppDestinationTypes {
  ERROR = "EDIT_APP_DESTINATION_ERROR",
  REQUEST = "EDIT_APP_DESTINATION_REQUEST",
  SUCCESS = "EDIT_APP_DESTINATION_SUCCESS",
}

export interface IEditAppDestinationRequestAction
  extends IReduxBaseAction<EditAppDestinationTypes> {
  payload: any;
  type: EditAppDestinationTypes.REQUEST;
}

export interface IEditAppDestinationSuccessAction
  extends IReduxBaseAction<EditAppDestinationTypes> {
  payload: any;
  type: EditAppDestinationTypes.SUCCESS;
}

export interface IEditAppDestinationErrorAction extends IReduxBaseAction<EditAppDestinationTypes> {
  payload: any;
  type: EditAppDestinationTypes.ERROR;
}

export type TSetEditAppDestinationActions =
  | IEditAppDestinationErrorAction
  | IEditAppDestinationRequestAction
  | IEditAppDestinationSuccessAction;
