import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IRemoveDestinationRouteActionTypes,
  IRemoveDestinationRouteErrorAction,
  IRemoveDestinationRouteRequestAction,
  IRemoveDestinationRouteSuccessAction,
  TRemoveDestinationRoute,
} from "./types";

// DELETE FUNCTIONALITY
export const RemoveDestinationRouteRequestAction = (): IRemoveDestinationRouteRequestAction => ({
  type: TRemoveDestinationRoute.REQUEST,
});
export const RemoveDestinationRouteSuccessAction = (
  data: any
): IRemoveDestinationRouteSuccessAction => ({
  payload: data,
  type: TRemoveDestinationRoute.SUCCESS,
});
export const RemoveDestinationRouteErrorAction = (
  error: any
): IRemoveDestinationRouteErrorAction => ({
  payload: error,
  type: TRemoveDestinationRoute.ERROR,
});

export const removeDestinationRoute = (
  appId: string,
  destId: string,
  routeId: string,
  changeComment: string,
  successCb?: any
) => (dispatch: Dispatch<IRemoveDestinationRouteActionTypes | TPopupActions>) => {
  dispatch(RemoveDestinationRouteRequestAction());
  ApplicationService.removeAppDestinationRoute(appId, destId, routeId, changeComment).then(
    (data: { data: any }) => {
      dispatch(RemoveDestinationRouteSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Application destination route group removed successfully!",
          title: "Success",
          type: "Info",
        })
      );
      successCb(destId);
    },
    (error: any) => {
      dispatch(RemoveDestinationRouteErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
