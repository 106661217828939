import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IGroundConfigListTableConfig } from "../models/IGroundsideConfiguration";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IGroundConfigListTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getGroundConfigList = async (conf: IGroundConfigListTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/groundConfigs${query}`);
};

// const getAircraftDetails = async (aircraftId: string) => {
//   const axios = await axiosInstance();

//   return axios.get(`/aircrafts/${aircraftId}`);
// };

const deployGroundConfigs = async (isInt: boolean, changeComment: string) => {
  const postData: any = {
    comment: changeComment,
    isIntegration: isInt,
  };

  const axios = await axiosInstance();

  return axios.post(`/groundConfigs/`, postData);
};

/*
const updateAircraft = async (config: any) => {
  let postData: any = {
    appName: config.appName,
    // appOwner: config.user.userId,
    appVersion: config.appVersion,
    changeComment: config.changeComment,
    // internal: config.internal,
    status: "Active",
    systemName: config.systemName,
  };

  if (!!config.appClientLocation && config.appClientLocation === brokerLocationEnum.GROUNDSIDE) {
    postData = {
      ...postData,
      groundDestinationEndpoint: config.groundDestinationEndpoint,
      groundDestinationType: config.groundDestinationType,
    };
  } else {
    postData = {
      ...postData,
      authenticationType: config.authenticationType,
      connRetryDelaySec: config.connRetryDelaySec,
      numConnRetries: config.numConnRetries,
      password: config.password,
      pathClientCa: config.pathClientCa,
      pathToCa: config.pathToCa,
      userName: config.userName,
    };
  }

  const axios = await axiosInstance();

  return axios.put(`/aircrafts/${config.tenantId}`, postData);
};

const deleteAircraft = async (tenantId: any, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/aircrafts/${tenantId}`, {
    params: { changeComment },
  });
};
*/

export const GroundConfigService = {
  deployGroundConfigs,
  // deleteAircraft,
  // getAircraftDetails,
  getGroundConfigList,
  // updateAircraft,
};
