import { MQTTClientType, TMQTTClientActions } from "../../actions/topic/get-mqtt-clients/types";

interface ITopicListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const topicMQTTClients = (
  state: ITopicListState = initialState,
  action: TMQTTClientActions
): ITopicListState => {
  switch (action.type) {
    case MQTTClientType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case MQTTClientType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case MQTTClientType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case MQTTClientType.CLEAR:
      return {
        ...state,
        content: [],
        error: false,
        loading: false,
      };
    default:
      return state;
  }
};
