import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React from "react";

import { DataDetailLabel } from "../../DataDetailLabel";

export const UserDetailsDetails = (props: any) => (
  <>
    {/* LOADED */}
    {props.userDetails.content && (
      <Grid columns="repeat(3, auto)" rows="repeat(1, minmax(150px,auto))">
        <GridItem>
          <DataDetailLabel
            title="Created by"
            value={
              props.userDetails.content.createdBy && props.userDetails.content.createdBy.fullName
            }
            className="ta-user-details-created-by"
          ></DataDetailLabel>
          <DataDetailLabel
            title="Created at"
            value={moment(props.userDetails.content.createdAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
            className="ta-user-details-created-at"
          ></DataDetailLabel>
        </GridItem>
        <GridItem>
          <DataDetailLabel
            title="Modified by"
            value={
              props.userDetails.content.modifiedBy && props.userDetails.content.modifiedBy.fullName
            }
            className="ta-user-details-modified-by"
          ></DataDetailLabel>
          <DataDetailLabel
            title="Modified at"
            value={moment(props.userDetails.content.modifiedAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
            className="ta-user-details-modified-at"
          ></DataDetailLabel>
        </GridItem>
        <GridItem>
          <DataDetailLabel
            title="User ID"
            value={props.userDetails.content.userId}
            className="ta-user-details-user-id"
          ></DataDetailLabel>
          {props.isCurrentUser && (
            <DataDetailLabel
              title="Time zone"
              value={moment.tz.guess() + moment.tz(moment.tz.guess()).format(' (UTCZ)')}
              className="ta-user-details-detected-time-zone"
            ></DataDetailLabel>
          )}
        </GridItem>
      </Grid>
    )}
  </>
);
