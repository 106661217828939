import React from "react";

import { ISideBarItemNode } from "./ICloudBar";
import { BadgeContainer, Icon, Label, NodeContainer } from "./ItemNode.style";

const SideBarItemNode = (props: ISideBarItemNode) => (
  <NodeContainer {...props}>
    <Icon>{props.icon}</Icon>
    {props.showLabel && <Label>{props.label}</Label>}
    {props.badge && <BadgeContainer>{props.badge}</BadgeContainer>}
  </NodeContainer>
);

export default SideBarItemNode;
