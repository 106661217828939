import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../models/IDestination";
import { ADMINISTRATOR } from "../../models/UserTypes";
import {
  SFlexItem,
  SShortText,
  SSpacer,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../styles/styles";
import {
  AircraftGroupValidationErrorCodeEnum,
  AircraftGroupValidatorUtil,
} from "../AircraftGroupValidatorUtil";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";

export const ModalCreateAircraftGroupPage0 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [errorString, setErrorSting] = useState(" ");

  const MAX_CHARACTER_LIMIT = 100;

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (activeInput && cursorLocation) {
      (activeInput as any).setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [updatedConfig]);

  const validateTextInput = (e: any) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > MAX_CHARACTER_LIMIT) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (e.target.value === " " || !validateTextInput(e)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleGlobalCheckboxClick = (e: any) => {
    setUpdatedConfig({
      ...props.config,
      isGlobal: !props.config.isGlobal,
    });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    const errorCode = AircraftGroupValidatorUtil.validateAircraftGroupName(
      props.config.aircraftGroupName
    );
    props.setAircraftGroupNameValidCode(errorCode);

    switch (errorCode) {
      case AircraftGroupValidationErrorCodeEnum.NO_ERROR:
        setErrorSting(" ");
        break;
      case AircraftGroupValidationErrorCodeEnum.LENGTH_LT_THREE:
      case AircraftGroupValidationErrorCodeEnum.LENGTH_TOO_LONG:
        setErrorSting("Group name should be a minimum of 3 and maximum of 100 characters");
        break;
      case AircraftGroupValidationErrorCodeEnum.INVALID_CHARACTERS:
        setErrorSting("Entered group name contains invalid character(s)");
        break;
      default:
        setErrorSting(" ");
    }
  }, [props.config.aircraftGroupName]);

  return (
    <>
      <SShortText
        variant="uiText"
        className={getTAClass("aircraft-group", TA_TYPES.TEXT, "airline-name")}
      >
        <STextDataTitle>{"Airline name"}:</STextDataTitle>
        <STextDataValue>
          {props.groupType === AircraftGroupTypeEnum.GLOBAL
            ? "Any"
            : props.tenant
              ? props.tenant.tenantName
              : ""}
        </STextDataValue>
      </SShortText>

      <SShortText variant="uiText" className={getTAClass("aircraft-group", TA_TYPES.TEXT, "icao")}>
        <STextDataTitle>{"ICAO"}:</STextDataTitle>
        <STextDataValue>
          {props.groupType === AircraftGroupTypeEnum.GLOBAL
            ? "Any"
            : props.tenant
              ? props.tenant.icao.toUpperCase()
              : ""}
        </STextDataValue>
      </SShortText>

      <SShortText
        variant="uiText"
        className={getTAClass("aircraft-group", TA_TYPES.TEXT, "environment")}
      >
        <STextDataTitle>{"Environment"}:</STextDataTitle>
        <STextDataValue>
          {props.groupEnv === GroundEnvironmentTypeEnum.PRODUCTION ? "Production" : "Integration"}
        </STextDataValue>
      </SShortText>

      <SSpacer height="12px" />

      <TextInput
        label={`Group name: ${errorString}`}
        name="group-name"
        placeholder="Group name"
        statusIcon={true}
        valid={props.aircraftGroupNameValidCode === AircraftGroupValidationErrorCodeEnum.NO_ERROR}
        value={props.config.aircraftGroupName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "aircraftGroupName");
        }}
      />

      {/* {currentUser.data.role === ADMINISTRATOR && (
        <SFlexItem>
          <div className="ta-modal-input-multicast">
            <Checkbox
              onClick={handleGlobalCheckboxClick}
              marked={
                props.config.isGlobal ? CheckboxOptions.selected : CheckboxOptions.unselected
              }
              disabled={false}
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-multicast">
            Global (may contain aircrafts from multiple airlines)
          </Text>
        </SFlexItem>
      )} */}

      <SSpacer />

      <Text variant="captionText" className="ta-modal-input-caption-description">
        Description:
      </Text>
      <Textarea
        className="ta-modal-input-description"
        placeholder="Description"
        value={props.config.description}
        onChange={(e: any) => {
          handleTextInput(e, "description");
        }}
      />

      <SSpacer height="12px" />
    </>
  );
};
