import { Dispatch } from "redux";

import { MQTTService } from "../../../services/MQTTService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreatePublisherTypes,
  ICreatePublisherErrorAction,
  ICreatePublisherRequestAction,
  ICreatePublisherSuccessAction,
  TCreatePublisherActions,
} from "./types";

export const CreatePublisherRequestAction = (data: any): ICreatePublisherRequestAction => ({
  payload: data,
  type: CreatePublisherTypes.REQUEST,
});
export const CreatePublisherSuccessAction = (data: any): ICreatePublisherSuccessAction => ({
  payload: data,
  type: CreatePublisherTypes.SUCCESS,
});
export const CreatePublisherErrorAction = (error: any): ICreatePublisherErrorAction => ({
  payload: error,
  type: CreatePublisherTypes.ERROR,
});

export const createPublisher = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreatePublisherActions | TEditModalActions>
) => {
  dispatch(CreatePublisherRequestAction(config));
  MQTTService.createPublisher(config).then(
    (data: { config: any; data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreatePublisherSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Publisher created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.topicMqttClientId);
    },
    (error: any) => {
      dispatch(CreatePublisherErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
