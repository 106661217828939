/* istanbul ignore file */
import { IGetConfigListData } from "../../../models/IConfigurationFilesConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ConfigLoadableListType {
  RESET = "GET_CONFIG_LOADABLE_LIST_RESET",
  REQUEST = "GET_CONFIG_LOADABLE_LIST_REQUEST",
  SUCCESS = "GET_CONFIG_LOADABLE_LIST_SUCCESS",
  ERROR = "GET_CONFIG_LOADABLE_LIST_ERROR",
}

export interface IConfigLoadableListResetAction extends IReduxBaseAction<ConfigLoadableListType> {
  type: ConfigLoadableListType.RESET;
}

export interface IConfigLoadableListRequestAction extends IReduxBaseAction<ConfigLoadableListType> {
  type: ConfigLoadableListType.REQUEST;
}

export interface IConfigLoadableListSuccessAction extends IReduxBaseAction<ConfigLoadableListType> {
  payload: IGetConfigListData;
  type: ConfigLoadableListType.SUCCESS;
}

export interface IConfigLoadableListErrorAction extends IReduxBaseAction<ConfigLoadableListType> {
  payload: string;
  type: ConfigLoadableListType.ERROR;
}

export type TConfigLoadableListActions =
  | IConfigLoadableListResetAction
  | IConfigLoadableListRequestAction
  | IConfigLoadableListSuccessAction
  | IConfigLoadableListErrorAction;
