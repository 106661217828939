import {
  ConfigBrokerFilterSortType,
  TConfigBrokerFilterSortActions,
} from "../../actions/configfile/broker-filter-sort/types";
import { IFilterSortData as IConfigFileBrokerFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const configFileBrokerFilterSortEmptyFilter: any = {
  brokerType: "",
  createdDate: { startDate: undefined, endDate: undefined },
  fileName: "",
  fileVersion: "",
};

export const configFileBrokerFilterSortInitialState: IConfigFileBrokerFilterSortState = {
  filter: configFileBrokerFilterSortEmptyFilter,
  limit: 10,
  orderBy: "createdAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const configFileBrokerFilterSort = (
  state: IConfigFileBrokerFilterSortState = configFileBrokerFilterSortInitialState,
  action: TConfigBrokerFilterSortActions
): IConfigFileBrokerFilterSortState => {
  switch (action.type) {
    case ConfigBrokerFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...configFileBrokerFilterSortEmptyFilter },
        page: 0,
      };
    case ConfigBrokerFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...configFileBrokerFilterSortInitialState,
      };
    case ConfigBrokerFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
