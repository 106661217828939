import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum EditApplicationTypes {
  ERROR = "EDIT_APPLICATION_ERROR",
  REQUEST = "EDIT_APPLICATION_REQUEST",
  SUCCESS = "EDIT_APPLICATION_SUCCESS",
}

export interface IEditApplicationRequestAction extends IReduxBaseAction<EditApplicationTypes> {
  payload: any;
  type: EditApplicationTypes.REQUEST;
}

export interface IEditApplicationSuccessAction extends IReduxBaseAction<EditApplicationTypes> {
  payload: any;
  type: EditApplicationTypes.SUCCESS;
}

export interface IEditApplicationErrorAction extends IReduxBaseAction<EditApplicationTypes> {
  payload: any;
  type: EditApplicationTypes.ERROR;
}

export type TSetEditApplicationActions =
  | IEditApplicationErrorAction
  | IEditApplicationRequestAction
  | IEditApplicationSuccessAction;
