import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateSubscriberTypes {
  ERROR = "CREATE_APPLICATION_ERROR",
  REQUEST = "CREATE_APPLICATION_REQUEST",
  SUCCESS = "CREATE_APPLICATION_SUCCESS",
}

export interface ICreateSubscriberRequestAction extends IReduxBaseAction<CreateSubscriberTypes> {
  payload: any;
  type: CreateSubscriberTypes.REQUEST;
}

export interface ICreateSubscriberSuccessAction extends IReduxBaseAction<CreateSubscriberTypes> {
  payload: any;
  type: CreateSubscriberTypes.SUCCESS;
}

export interface ICreateSubscriberErrorAction extends IReduxBaseAction<CreateSubscriberTypes> {
  payload: any;
  type: CreateSubscriberTypes.ERROR;
}

export type TCreateSubscriberActions =
  | ICreateSubscriberErrorAction
  | ICreateSubscriberRequestAction
  | ICreateSubscriberSuccessAction;
