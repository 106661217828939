import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { tabEnum } from "../../../models/ITopicDetailsConfig";

export const TopicDetailsTabs = (props: {
  activeTab: tabEnum;
  onChange: any;
  showPermissionsTab: boolean;
}) => {
  const tabList = [
    { class: "ta-topic-tab-subsc", id: tabEnum.SUBSCRIBERS, title: "Subscribers" },
    { class: "ta-topic-tab-publ", id: tabEnum.PUBLISHERS, title: "Publishers" },
    { class: "ta-topic-tab-bridg", id: tabEnum.BRIDGES, title: "Bridges" },
    { class: "ta-topic-tab-details", id: tabEnum.DETAILS, title: "Details" },
    { class: "ta-topic-tab-payload", id: tabEnum.PAYLOAD, title: "Payload" },
    { class: "ta-topic-tab-topology", id: tabEnum.TOPOLOGY, title: "Bridge diagrams" },
    { class: "ta-topic-tab-permissions", id: tabEnum.PERMISSIONS, title: "Permissions" },
    { class: "ta-topic-tab-history", id: tabEnum.HISTORY, title: "Change history" },
  ];

  const handleTabChange = (id: tabEnum) => {
    props.onChange(id);
  };

  return (
    <Grid columns="1">
      <GridItem>
        <TabContainer>
          {tabList.map(
            (tab: any, index: any) =>
              (tab.id !== tabEnum.PERMISSIONS || props.showPermissionsTab) && (
                <Tab
                  key={index}
                  className={tab.class}
                  active={props.activeTab === tab.id}
                  onClick={() => {
                    handleTabChange(tab.id);
                  }}
                >
                  {tab.title}
                </Tab>
              )
          )}
        </TabContainer>
      </GridItem>
    </Grid>
  );
};
