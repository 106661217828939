/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTopicListData } from "../../../models/ITopicConfig";

export enum TopicListType {
  REQUEST = "GET_TOPIC_LIST_REQUEST",
  SUCCESS = "GET_TOPIC_LIST_SUCCESS",
  ERROR = "GET_TOPIC_LIST_ERROR",
}

export interface ITopicRequestAction extends IReduxBaseAction<TopicListType> {
  type: TopicListType.REQUEST;
}

export interface ITopicSuccessAction extends IReduxBaseAction<TopicListType> {
  payload: IGetTopicListData;
  type: TopicListType.SUCCESS;
}

export interface ITopicErrorAction extends IReduxBaseAction<TopicListType> {
  payload: string;
  type: TopicListType.ERROR;
}

export type TTopicListActions = ITopicRequestAction | ITopicSuccessAction | ITopicErrorAction;
