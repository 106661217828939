import {
  IGetAircraftGroupDetailsActionType,
  TGetAircraftGroupDetails,
} from "../../actions/aircraftgroup/get-details/types";

interface IAircraftGroupDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: IAircraftGroupDetailsState = {
  content: [],
  error: false,
  loading: true,
};

export const aircraftGroupDetails = (
  state: IAircraftGroupDetailsState = initialState,
  action: IGetAircraftGroupDetailsActionType
): IAircraftGroupDetailsState => {
  switch (action.type) {
    case TGetAircraftGroupDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetAircraftGroupDetails.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetAircraftGroupDetails.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
