import { Dispatch } from "redux";

import { AircraftGroupService } from "../../../services/AircraftGroupService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import { AddAircraftsToGroupsTypes, TAddAircraftsToGroupsActions } from "./types";

export const AddAircraftsToGroupsRequestAction = (data: any): TAddAircraftsToGroupsActions => ({
  payload: data,
  type: AddAircraftsToGroupsTypes.REQUEST,
});
export const AddAircraftsToGroupsSuccessAction = (data: any): TAddAircraftsToGroupsActions => ({
  payload: data,
  type: AddAircraftsToGroupsTypes.SUCCESS,
});
export const AddAircraftsToGroupsErrorAction = (error: any): TAddAircraftsToGroupsActions => ({
  payload: error,
  type: AddAircraftsToGroupsTypes.ERROR,
});

export const addAircraftsToGroups = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TAddAircraftsToGroupsActions | TEditModalActions>
) => {
  dispatch(AddAircraftsToGroupsRequestAction(config));
  AircraftGroupService.addAircraftsToGroups(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(AddAircraftsToGroupsSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Aircrafts added to groups successfully!",
          title: "Success",
          type: "Info",
        })
      );
      // dispatch(editModalCloseAction());
      if (cb) {
        cb(data.data.data.content);
      }
    },
    (error: any) => {
      dispatch(AddAircraftsToGroupsErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
