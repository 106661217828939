import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TDeleteApplication {
  ERROR = "APPLICATION_DELETE_ERROR",
  REQUEST = "APPLICATION_DELETE_REQUEST",
  SUCCESS = "APPLICATION_DELETE_SUCCESS",
}

export interface IDeleteApplicationRequestAction extends IReduxBaseAction<TDeleteApplication> {
  type: TDeleteApplication.REQUEST;
}

export interface IDeleteApplicationSuccessAction extends IReduxBaseAction<TDeleteApplication> {
  payload: any;
  type: TDeleteApplication.SUCCESS;
}

export interface IDeleteApplicationErrorAction extends IReduxBaseAction<TDeleteApplication> {
  payload: any;
  type: TDeleteApplication.ERROR;
}

export type IDeleteApplicationActionTypes =
  | IDeleteApplicationErrorAction
  | IDeleteApplicationRequestAction
  | IDeleteApplicationSuccessAction;
