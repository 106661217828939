import { topicTypeEnum } from "../models/ITopicConfig";

enum TopicValidationErrorCodeEnum {
  // no Error
  NO_ERROR = 0,

  // === topic name error ===
  // length < 3
  LENGTH_LT_THREE = -11,

  // invalid character(s)
  INVALID_CHARACTERS = -12,

  // uppercase
  UPPERCASE = -13,

  // leading slash
  LEADING_SLASH = -14,

  // empty level name
  EMPTY_LEVEL_NAME = -15,

  // tailing slash
  TAILING_SLASH = -16,

  // invalid wildcard #
  INVALID_WILDCARD_M = -17,

  // wildcard + in level name
  WILDCARD_S_IN_LEVEL = -18,

  // multiple wildcard #
  MULTIPLE_WILDCARD_M = -19,

  // wildcard # in level name
  WILDCARD_M_IN_LEVEL = -20,

  // invalid character $
  INVALID_CHARACTER_$ = -21,

  // length too long
  LENGTH_TOO_LONG = -22,

  // invalid wildcard +
  INVALID_WILDCARD_S = -23,

  // More than 5 slashes for a2g topics
  MORE_THAN_5_SLASHES = -24,

  // === other error ===
  // TBD
}

/**
 * Topic field validation
 */
// tslint:disable-next-line:no-unnecessary-class
class TopicValidatorUtil {
  /**
   * validateTopicName
   * @param topicName string - the topicName to be validated
   * @param isAdmin boolean - is admin user?
   * @returns TopicValidationErrorCodeEnum - error code
   */
  /* tslint:disable:cyclomatic-complexity */
  public static validateTopicName(
    topicName: string,
    isAdmin: boolean,
    topicType: topicTypeEnum = topicTypeEnum.AIR
  ): TopicValidationErrorCodeEnum {
    let errCode: TopicValidationErrorCodeEnum = TopicValidationErrorCodeEnum.NO_ERROR; // no error

    // topic name requirements and rules, from Adi, 12/10/2020
    //
    // 0. topic name must at least 3 chars in length
    //
    // 1. overall whitelist characters allowed
    //      [0-9A-Za-z/_-$+#]
    //
    // 2. no uppercase letters [A-Z] allowed, except admin
    //
    // 3. no leading, tailing, and multiple in a row of forward slash '/'
    //
    // 4. wildcards rules
    //      '+' is single-level wildcard, can be anywhere in the topic name and, can be repeated in different levels in a same
    //              "i/srvc/+/event"
    //              "i/srvcs/seats/+/process/+/status"
    //              "+/srvcs/seats/+/health/+"
    //
    //      '#' is multi-level wildcard, can only be in the end of topic name as last level.
    //              "i/srvcs/conn/transmission/#""
    //
    //      Both can be combined in a same topic:
    //              "i/srvcs/seats/+/process/+/health/#"
    //
    //    4.1 no multi-level wildcard '#' allowed, except admin
    //
    //    4.2 wildcard must be used as the whole level, not part of the level name
    //              "i/srvcs/#", "i/srvcs/seats/+/process/+/status" are valid
    //              "i/srvcs/seats/#health", "i/srvcs/seats/process+/status" are invalid
    //
    //    4.3 any wildcard is not allowed in A2G topic, UMB-1690, 02/01/2022
    //
    //
    // 5. special topic name '$SYS' handling
    //
    //    5.1 only admin can create special topic names, thus '$' is not allowed for app owner
    //
    // 6. a2g topics cannot have more than 5 slashes

    // topic name min length 3
    if (!topicName || topicName.length < 3) {
      errCode = TopicValidationErrorCodeEnum.LENGTH_LT_THREE;

      return errCode;
    }

    // topic name max length 199
    if (topicName.length > 199) {
      errCode = TopicValidationErrorCodeEnum.LENGTH_TOO_LONG;

      return errCode;
    }

    // whitelist chars check
    let nameTest1 = /[^0-9A-Za-z/_\-\$\+#]/;
    if (nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.INVALID_CHARACTERS;

      return errCode;
    }

    // no upper case, except admin
    nameTest1 = /[A-Z]/;
    if (!isAdmin && nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.UPPERCASE;

      return errCode;
    }

    // check forward slash usage
    // no leading forward slash
    nameTest1 = /^\//;
    if (nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.LEADING_SLASH;

      return errCode;
    }

    // no multiple slashes in a row - empty level, like 'a//bb///c'
    nameTest1 = /\/{2,}/;
    if (nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.EMPTY_LEVEL_NAME;

      return errCode;
    }

    // no tailing slash - incomplete level in the end, like 'a/bb/cccc/'
    nameTest1 = /\/$/;
    if (nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.TAILING_SLASH;

      return errCode;
    }

    // no single-level wildcard '+' in A2G topic
    nameTest1 = /\+/;
    if (topicType === topicTypeEnum.A2G && nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.INVALID_WILDCARD_S;

      return errCode;
    }

    // no multi-level wildcard '#', except admin
    // cannot be in A2G topic
    nameTest1 = /#/;
    if ((!isAdmin || topicType === topicTypeEnum.A2G) && nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.INVALID_WILDCARD_M;

      return errCode;
    }

    // more wildcard syntax check
    // 1. single-level wildcard '+'
    nameTest1 = /\+/;
    if (nameTest1.test(topicName)) {
      const topicLevels = topicName.split("/");

      let idx = 0;
      let levelName = "";
      for (idx = 0; idx < topicLevels.length; idx += 1) {
        // test each level names, wildcard '+' must be used as full level name
        levelName = topicLevels[idx];
        if (nameTest1.test(levelName) && levelName.length > 1) {
          errCode = TopicValidationErrorCodeEnum.WILDCARD_S_IN_LEVEL;

          return errCode;
        }
      }
    }

    // 2. multi-level wildcard '#'
    nameTest1 = /#/;
    if (nameTest1.test(topicName)) {
      // 2.1 no multiple wildcard '#'
      let nameTest2 = /#.*#/;
      if (nameTest2.test(topicName)) {
        errCode = TopicValidationErrorCodeEnum.MULTIPLE_WILDCARD_M;

        return errCode;
      }

      // 2.2 wildcard '#' must be as full level in the end
      nameTest2 = /\/#$/;
      if (!nameTest2.test(topicName)) {
        errCode = TopicValidationErrorCodeEnum.WILDCARD_M_IN_LEVEL;

        return errCode;
      }
    }

    // no special topic '$xxx' allowed, except admin
    nameTest1 = /\$/;
    if (!isAdmin && nameTest1.test(topicName)) {
      errCode = TopicValidationErrorCodeEnum.INVALID_CHARACTER_$;

      return errCode;
    }

    // a2g topics cannot have more than 5 slashes
    if (topicType === topicTypeEnum.A2G && topicName.split("/").length - 1 > 5) {
      errCode = TopicValidationErrorCodeEnum.MORE_THAN_5_SLASHES;

      return errCode;
    }

    // no error
    return errCode;
  }
  /* tslint:enable:cyclomatic-complexity */

  /**
   * validate form data
   * @param topic TopicInterface
   * @returns string[]
   */
  // static validateData(topic: TopicInterface, isAdmin: boolean): string[] {
  //     let errors: Array<string> = [];

  //     if (this.validateTopicName(topic.topicName, isAdmin) !== TopicValidationErrorCodeEnum.NO_ERROR) {
  //         errors.push(`Topic name is invalid`);
  //     }

  //     // no support for other fields yet
  //     //...

  //     return errors;
  // }
}

export { TopicValidationErrorCodeEnum, TopicValidatorUtil };
