import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum UpdatePayloadTypes {
  ERROR = "UPDATE_PAYLOAD_ERROR",
  REQUEST = "UPDATE_PAYLOAD_REQUEST",
  SUCCESS = "UPDATE_PAYLOAD_SUCCESS",
}

export interface IUpdatePayloadRequestAction extends IReduxBaseAction<UpdatePayloadTypes> {
  payload: any;
  type: UpdatePayloadTypes.REQUEST;
}

export interface IUpdatePayloadSuccessAction extends IReduxBaseAction<UpdatePayloadTypes> {
  payload: any;
  type: UpdatePayloadTypes.SUCCESS;
}

export interface IUpdatePayloadErrorAction extends IReduxBaseAction<UpdatePayloadTypes> {
  payload: any;
  type: UpdatePayloadTypes.ERROR;
}

export type TUpdatePayloadActions =
  | IUpdatePayloadErrorAction
  | IUpdatePayloadRequestAction
  | IUpdatePayloadSuccessAction;
