import { Textarea } from "next-components";
import styled from "styled-components";

export const STopologyContainer = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  height: 600px;
`;

export const STopologyContainerNarrow = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  height: 400px;
`;

export const STextWrapper = styled.div`
  display: flex;
  line-height: 40px;
  white-space: nowrap;
`;

export const SPayloadSchema = styled(Textarea)`
  min-height: 300px;
`;

export const SChangeLogContent = styled(Textarea)`
  min-height: 360px;
  max-height: 720px;
`;

export const SInfoLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;

export const SInfoLabel = styled.span`
  color: #2a7fc9;
  margin-right: 5px;
`;
