import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";

import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import {
  SFlexItem,
  SMediumText,
  SSpacer,
  STextDataTitle,
  STextDataValue,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { StatusIcon } from "../StatusIcon";

export const ModalCreateAirlinePage2 = (props: any) => {
  const [changeComment, setChangeComment] = useState("");

  useEffect(() => {
    if (props.config) {
      setChangeComment(props.config.changeComment);
    }
  }, []);

  useEffect(() => {
    if (props.config && props.onChange) {
      props.onChange({ ...props.config, changeComment });
    }
  }, [changeComment]);

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setChangeComment(e.target.value);
    }
  };

  const getCommentHint = () => {
    let hintStr =
      props.commentHint && props.commentHint.length > 0
        ? props.commentHint
        : "Please enter a comment about why you are making this change.";

    hintStr += props.commentRequired ? " (required)" : " (optional)";

    return hintStr;
  };

  return (
    <>
      <SMediumText
        variant="uiText"
        className={getTAClass("create-airline", TA_TYPES.TEXT, "airline-name")}
      >
        <STextDataTitle>{"Airline name"}:</STextDataTitle>
        <STextDataValue>{props.tenantInfo.tenantName}</STextDataValue>
      </SMediumText>

      <SMediumText variant="uiText" className={getTAClass("create-airline", TA_TYPES.TEXT, "icao")}>
        <STextDataTitle>{"ICAO"}:</STextDataTitle>
        <STextDataValue>
          {props.tenantInfo.icao ? props.tenantInfo.icao.toUpperCase() : ""}
        </STextDataValue>
      </SMediumText>

      <SMediumText
        variant="uiText"
        className={getTAClass("create-airline", TA_TYPES.TEXT, "description")}
      >
        <STextDataTitle>{"Description"}:</STextDataTitle>
        <STextDataValue>{props.tenantInfo.description}</STextDataValue>
      </SMediumText>

      {/* <DataDetailLabel
        title="Airline name"
        value="American Airlines"
        className={getTAClass("applicationDetails", TA_TYPES.TEXT, "location")}
      />

      <DataDetailLabel
        title="ICAO"
        value="AAL"
        className={getTAClass("applicationDetails", TA_TYPES.TEXT, "location")}
      />

      <DataDetailLabel
        title="Description"
        value="This is American Airlines."
        className={getTAClass("applicationDetails", TA_TYPES.TEXT, "location")}
      /> */}

      <SSpacer />

      <Text variant="captionText" className="ta-modal-input-caption-change-comment">
        Please enter comment (max 2048 characters){" "}
        <StatusIcon
          className={
            !props.commentRequired || !!changeComment
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={!props.commentRequired || !!changeComment}
        />
      </Text>
      <Textarea
        className="ta-modal-input-comment"
        placeholder={getCommentHint()}
        value={changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "comment", 2048);
        }}
      />
    </>
  );
};
