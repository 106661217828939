import {
  AircraftFilterSortType,
  TAircraftFilterSortActions,
} from "../../actions/aircraft/filter-sort/types";
import { AircraftTypeEnum } from "../../models/IAircraft";
import { IFilterSortData as IAircraftFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const aircraftFilterSortEmptyFilter: any = {
  //   aircraftType: AircraftTypeEnum.AIRCRAFT,
  aircraftType: "",
  icao: "",
  modifiedBy: "",
  modifiedDate: { startDate: undefined, endDate: undefined },
  tailNumber: "",
  tenantId: "",
  tenantName: "",
};

export const aircraftFilterSortInitialState: IAircraftFilterSortState = {
  filter: aircraftFilterSortEmptyFilter,
  limit: 10,
  orderBy: "modifiedAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const aircraftFilterSort = (
  state: IAircraftFilterSortState = aircraftFilterSortInitialState,
  action: TAircraftFilterSortActions
): IAircraftFilterSortState => {
  switch (action.type) {
    case AircraftFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...aircraftFilterSortEmptyFilter },
        page: 0,
      };
    case AircraftFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...aircraftFilterSortInitialState,
      };
    case AircraftFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
