import {
  AircraftGroupSelectListType,
  TAircraftGroupSelectListActions,
} from "../../actions/aircraftgroup/get-select-list/types";

interface IAircraftGroupSelectListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IAircraftGroupSelectListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const aircraftGroupSelectList = (
  state: IAircraftGroupSelectListState = initialState,
  action: TAircraftGroupSelectListActions
): IAircraftGroupSelectListState => {
  switch (action.type) {
    case AircraftGroupSelectListType.RESET:
      return {
        ...state,
        ...initialState,
      };
    case AircraftGroupSelectListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case AircraftGroupSelectListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case AircraftGroupSelectListType.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
