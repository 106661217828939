import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetGndChangeLogTypes {
  ERROR = "GET_GND_CHANGE_LOG_ERROR",
  REQUEST = "GET_GND_CHANGE_LOG_REQUEST",
  SUCCESS = "GET_GND_CHANGE_LOG_SUCCESS",
}

export interface IGetGndChangeLogRequestAction extends IReduxBaseAction<GetGndChangeLogTypes> {
  payload: any;
  type: GetGndChangeLogTypes.REQUEST;
}

export interface IGetGndChangeLogSuccessAction extends IReduxBaseAction<GetGndChangeLogTypes> {
  payload: any;
  type: GetGndChangeLogTypes.SUCCESS;
}

export interface IGetGndChangeLogErrorAction extends IReduxBaseAction<GetGndChangeLogTypes> {
  payload: any;
  type: GetGndChangeLogTypes.ERROR;
}

export type TGetGndChangeLogActions =
  | IGetGndChangeLogErrorAction
  | IGetGndChangeLogRequestAction
  | IGetGndChangeLogSuccessAction;
