import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TDeleteTopic {
  ERROR = "TOPIC_DELETE_ERROR",
  REQUEST = "TOPIC_DELETE_REQUEST",
  SUCCESS = "TOPIC_DELETE_SUCCESS",
}

export interface IDeleteTopicRequestAction extends IReduxBaseAction<TDeleteTopic> {
  type: TDeleteTopic.REQUEST;
}

export interface IDeleteTopicSuccessAction extends IReduxBaseAction<TDeleteTopic> {
  payload: any;
  type: TDeleteTopic.SUCCESS;
}

export interface IDeleteTopicErrorAction extends IReduxBaseAction<TDeleteTopic> {
  payload: any;
  type: TDeleteTopic.ERROR;
}

export type IDeleteTopicActionTypes =
  | IDeleteTopicErrorAction
  | IDeleteTopicRequestAction
  | IDeleteTopicSuccessAction;
