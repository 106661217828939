import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { AircraftTypeEnum } from "../../models/IAircraft";
import { ADMINISTRATOR } from "../../models/UserTypes";
import {
  SFlexItem,
  SShortText,
  SSpacer,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../styles/styles";
import {
  AircraftTailNumberValidationErrorCodeEnum,
  AircraftTailNumberValidatorUtil,
} from "../AircraftTailNumberValidatorUtil";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";

export const ModalCreateAircraftPage1 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [errorString, setErrorSting] = useState(" ");

  const MAX_CHARACTER_LIMIT = 100;

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [updatedConfig]);

  const validateTextInput = (e: any) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > MAX_CHARACTER_LIMIT) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (e.target.value === " " || !validateTextInput(e)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    const errorCode = AircraftTailNumberValidatorUtil.validateTailNumber(props.config.tailNumber);
    props.setAircraftTailNumberValidCode(errorCode);

    switch (errorCode) {
      case AircraftTailNumberValidationErrorCodeEnum.NO_ERROR:
        setErrorSting(" ");
        break;
      case AircraftTailNumberValidationErrorCodeEnum.LENGTH_LT_TWO:
      case AircraftTailNumberValidationErrorCodeEnum.LENGTH_TOO_LONG:
        setErrorSting("Tail number should be a minimum of 2 and maximum of 10 characters");
        break;
      case AircraftTailNumberValidationErrorCodeEnum.INVALID_CHARACTERS:
        setErrorSting("Entered tail number has invalid format or character(s)");
        break;
      default:
        setErrorSting(" ");
    }
  }, [props.config.tailNumber]);

  return (
    <>
      <SShortText
        variant="uiText"
        className={getTAClass("aircraft-group", TA_TYPES.TEXT, "airline-name")}
      >
        <STextDataTitle>{"Airline name"}:</STextDataTitle>
        <STextDataValue>{props.tenant ? props.tenant.tenantName : ""}</STextDataValue>
      </SShortText>

      <SShortText variant="uiText" className={getTAClass("aircraft-group", TA_TYPES.TEXT, "icao")}>
        <STextDataTitle>{"ICAO"}:</STextDataTitle>
        <STextDataValue>{props.tenant ? props.tenant.icao.toUpperCase() : ""}</STextDataValue>
      </SShortText>

      <SSpacer height="12px" />

      <TextInput
        label={`${
          props.config.aircraftType === AircraftTypeEnum.AIRCRAFT ? "Tail number" : "Rack name"
        }: ${errorString}`}
        name="app-version"
        placeholder={
          props.config.aircraftType === AircraftTypeEnum.AIRCRAFT ? "Tail number" : "Rack name"
        }
        valid={
          props.aircraftTailNumberValidCode === AircraftTailNumberValidationErrorCodeEnum.NO_ERROR
        }
        value={props.config.tailNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "tailNumber");
        }}
      />

      <Text variant="captionText" className="ta-modal-input-caption-description">
        Description:
      </Text>
      <Textarea
        className="ta-modal-input-description"
        placeholder="Description"
        value={props.config.description}
        onChange={(e: any) => {
          handleTextInput(e, "description");
        }}
      />
    </>
  );
};
