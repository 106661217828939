import { TUserFilterSortActions, UserFilterSortType } from "./types";

export const userFilterSortClearAction = (): TUserFilterSortActions => ({
  type: UserFilterSortType.CLEAR,
});

export const userFilterSortResetAction = (): TUserFilterSortActions => ({
  type: UserFilterSortType.RESET,
});

export const userFilterSortApplyAction = (data: any): TUserFilterSortActions => ({
  payload: data,
  type: UserFilterSortType.APPLY,
});
