import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateAppDestinationTypes,
  ICreateAppDestinationErrorAction,
  ICreateAppDestinationRequestAction,
  ICreateAppDestinationSuccessAction,
  TCreateAppDestinationActions,
} from "./types";

export const CreateAppDestinationRequestAction = (
  data: any
): ICreateAppDestinationRequestAction => ({
  payload: data,
  type: CreateAppDestinationTypes.REQUEST,
});
export const CreateAppDestinationSuccessAction = (
  data: any
): ICreateAppDestinationSuccessAction => ({
  payload: data,
  type: CreateAppDestinationTypes.SUCCESS,
});
export const CreateAppDestinationErrorAction = (error: any): ICreateAppDestinationErrorAction => ({
  payload: error,
  type: CreateAppDestinationTypes.ERROR,
});

export const createAppDestination = (appId: string, config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreateAppDestinationActions | TEditModalActions>
) => {
  dispatch(CreateAppDestinationRequestAction(config));
  ApplicationService.createAppDestination(appId, config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateAppDestinationSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Destination created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      // dispatch(editModalCloseAction());
      if (cb) {
        cb(data.data.data.content.appClientDestinationId);
      }
    },
    (error: any) => {
      dispatch(CreateAppDestinationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
