import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum TGetTopicBridges {
  ERROR = "TOPIC_GETBRIDGES_ERROR",
  REQUEST = "TOPIC_GETBRIDGES_REQUEST",
  SUCCESS = "TOPIC_GETBRIDGES_SUCCESS",
  CLEAR = "TOPIC_GETBRIDGES_CLEAR",
}

export interface IGetTopicBridgesRequestAction extends IReduxBaseAction<TGetTopicBridges> {
  type: TGetTopicBridges.REQUEST;
}

export interface IGetTopicBridgesSuccessAction extends IReduxBaseAction<TGetTopicBridges> {
  payload: any;
  type: TGetTopicBridges.SUCCESS;
}

export interface IGetTopicBridgesErrorAction extends IReduxBaseAction<TGetTopicBridges> {
  payload: any;
  type: TGetTopicBridges.ERROR;
}

export interface IGetTopicBridgesClearAction extends IReduxBaseAction<TGetTopicBridges> {
  type: TGetTopicBridges.CLEAR;
}

export type IGetTopicBridgesActionType =
  | IGetTopicBridgesRequestAction
  | IGetTopicBridgesSuccessAction
  | IGetTopicBridgesErrorAction
  | IGetTopicBridgesClearAction;
