import { OverlayFullscreen } from "next-components";
import React from "react";

import { SNextAnimatedContent, SNextCenteredContainer } from "../../styles/styles";

export const ModalBaseBSD = (props: any) => (
  <OverlayFullscreen>
    <SNextCenteredContainer>
      <SNextAnimatedContent elevation={"small"}>{props.children}</SNextAnimatedContent>
    </SNextCenteredContainer>
  </OverlayFullscreen>
);
