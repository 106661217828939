import { Heading, Text } from "next-components";
import styled, { createGlobalStyle } from "styled-components";

import { IFixedContainerPropTypes, IFlexContainerPropTypes } from "../models/IStyles";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color:#f9f9f9;
    margin: 0px;
    font-family: 'Inter', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const AppContainer = styled.div`
  position: relative;
  display: flex;
`;
export const SContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
  overflow-x: auto;
`;

export const SPageContainer = styled.div`
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
`;

export const SPageHeading = styled(Heading)`
  padding-top: 30px;
  margin-bottom: 30px;
`;

export const SOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
`;

export const SOverlayCenterAlign = styled(SOverlay as any)`
  justify-content: center;
  align-items: center;
`;

export const STallText = styled(Text)`
  display: flex;
  line-height: 40px;
  flex-wrap: wrap;
`;

export const SMediumText = styled(Text)`
  display: flex;
  line-height: 32px;
  flex-wrap: wrap;
`;

export const SShortText = styled(Text)`
  display: flex;
  line-height: 24px;
  flex-wrap: wrap;
`;

export const STextDataTitle = styled.div`
  display: flex;
  font-weight: bold;
  padding-right: 10px;
`;

export const STextDataValue = styled.div`
  display: flex;
  flex-grow: 0;
`;

export const STextDataValue2 = styled.div`
  display: flex;
  flex-grow: 0;
  padding-right: 4px;
  align-items: center;
`;

export const SFlexContainer = styled("div")<IFlexContainerPropTypes>`
  display: flex;
  justify-content: ${(props: IFlexContainerPropTypes) => props.justifyContent};
`;

export const SFlexItem = styled.div`
  display: flex;
  align-items: center;
`;

export const SActiveLink = styled.span`
  color: #2a7fc9;
  &:hover  {
    color: #21649f;
    text-decoration: underline;
`;

export const SNextCenteredContainer = styled.div`
  left: 50%;
  max-height: 90%;
  overflow: scroll;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const SNextFixedHeightContainer = styled("div")<IFixedContainerPropTypes>`
  height: ${(props: IFixedContainerPropTypes) => props.height || "auto"};
  max-height: ${(props: IFixedContainerPropTypes) => props.maxHeight || "240px"};
  overflow: ${(props: IFixedContainerPropTypes) => props.overflow || "scroll"};
`;

export const SNextFixedWidthContainer = styled("div")<IFixedContainerPropTypes>`
  width: ${(props: IFixedContainerPropTypes) => props.width || "auto"};
  max-width: ${(props: IFixedContainerPropTypes) => props.maxWidth || "640px"};
  min-width: ${(props: IFixedContainerPropTypes) => props.minWidth || "240px"};
  padding-left: ${(props: IFixedContainerPropTypes) => props.paddingLeft || "0px"};
`;

export const STextDataValueDropdown = styled.div`
  display: flex;
  flex-grow: 0;
  min-width: 50%;
`;

export const FilterWrapper = styled.div``;

export const SimpleLabel = styled.span`
  font-family: Inter, serif;
  font-weight: bold;
  padding-right: 10px;
`;

export const SimpleText = styled.span`
  color: black;
  font-family: Inter, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
`;
