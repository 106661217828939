import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React from "react";

import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { SSpacer, STallText, STextDataTitle, STextDataValue } from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";

export const AircraftGroupDetailsDetailsTab = (props: any) => (
  <Grid columns="2fr 1fr">
    <GridItem>
      <STallText variant="uiText" className="ta-aircraft-group-details-desc">
        <STextDataTitle>Description:</STextDataTitle>
        {/* <STextDataValue>{props.acgContent.groups}</STextDataValue> */}
        <STextDataValue>{props.acgContent.description}</STextDataValue>
      </STallText>
    </GridItem>

    <GridItem>
      <STallText variant="uiText" className="ta-aircraft-group-details-created-by">
        <STextDataTitle>Created by:</STextDataTitle>
        <STextDataValue>
          {props.acgContent.createdBy && props.acgContent.createdBy.fullName}
        </STextDataValue>
      </STallText>
      <STallText variant="uiText" className="ta-aircraft-group-details-created-at">
        <STextDataTitle>Created at:</STextDataTitle>
        <STextDataValue>
          {props.acgContent.createdAt &&
            moment(props.acgContent.createdAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
        </STextDataValue>
      </STallText>
      <STallText
        variant="uiText"
        className={getTAClass("aircraft-group-details", TA_TYPES.INPUT, "modified-by")}
      >
        <STextDataTitle>Modified by:</STextDataTitle>
        <STextDataValue>
          {props.acgContent.modifiedBy && props.acgContent.modifiedBy.fullName}
        </STextDataValue>
      </STallText>
      <STallText variant="uiText" className="ta-aircraft-group-details-modified-at">
        <STextDataTitle>Modified at:</STextDataTitle>
        <STextDataValue>
          {props.acgContent.modifiedAt &&
            moment(props.acgContent.modifiedAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
        </STextDataValue>
      </STallText>
    </GridItem>
  </Grid>
);
