import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const HeaderPageSpacer = styled.div`
  height: 100%;
  display: flex;
  flex-shrink: 0;
  width: 300px;
  transition: width 0.3s ease;

  &.collapsed {
    width: 80px;
  }
`;

export const HeaderContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: stretch;
  position: fixed;
  top: 0;
  padding: 1rem;
  background: linear-gradient(0deg, rgb(16, 47, 74) 0%, rgb(24, 74, 117) 100%);
  width: 300px;
  overflow-y: auto;
  transition: width 0.3s ease;

  .menu-item-label {
    overflow-x: hidden;
    white-space: nowrap;
  }

  &.collapsed {
    width: 80px;
  }
  svg {
    width: 44px;
  }
`;

export const VersionContainer = styled.div`
  padding: 0 1rem;
  color: #6b7280;
  font-size: 0.75rem;
  overflow-x: hidden;
  white-space: nowrap;
  transition: display 0.3s ease;

  .collapsed & {
    display: none;
  }
`;

export const MenuContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem;

  &.menu-container-bottom {
    margin-top: auto;
  }
`;

export const MenuItem = styled.li`
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  border-radius: 0.5rem;
  padding-bottom: 0.4rem;

  .menu-item-label {
    overflow-x: hidden;
    white-space: nowrap;
  }

  .collapsed & {
    .menu-item-label {
      display: none;
    }
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 1.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 0.5rem 0;
  align-self: center;
  transition: display 0.3s ease;

  .collapsed & {
    display: none;
  }
`;

export const SectionSeparator = styled.div`
  width: 100%;
  height: 0.5px; /* Reduce height */
  background-color: rgba(255, 255, 255, 0.1); /* Lighter shade */
  margin: 1rem 0;
`;

export const HeaderTitle = styled(NavLink)`
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  padding: 0.5rem 1rem 1rem;
  text-align: left;
  width: 100%;
  min-height: 50px;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  text-decoration: none;
  transition:
    width 0.3s ease,
    padding 0.3s ease;

  svg {
    flex-shrink: 0;
    margin-top: 0.3rem;
  }

  .collapsed & {
    width: auto;
    padding: 0.5rem 0.2rem 1rem;
  }
`;

export const StyledNavLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  border-radius: 0.5rem;
  transition: width 0.3s ease;
  overflow-x: hidden;
  white-space: nowrap;

  .collapsed &,
  &.mini {
    width: auto;

    svg {
      margin-right: 0;
    }
  }

  > svg {
    height: 24px;
    width: 24px;
    min-width: 24px;
    margin-right: 0.5rem;
    flex-shrink: 0;

    .collapsed & {
      margin-right: 0;
    }
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
