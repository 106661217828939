import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { setUserDetails } from "../../actions/user/set-details/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import {
  ADMINISTRATOR,
  APPLICATION_OWNER,
  APPROVER,
  getUserRole,
  READ,
} from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";

import { DropdownInput } from "./Dropdownlnput";
import { ModalCreateBase } from "./ModalCreateBase";
import { TextInput } from "./TextInput";

export const ModalEditUser = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { currentUser, userDetails } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    userDetails: state.userDetails,
  }));

  const originalRole = userDetails.content.role;
  const [selectedRole, setSelectedRole] = useState(originalRole);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [roles, setRoles] = useState([]);
  const rolesForOwnUser: any = {
    APPLICATION_OWNER: [APPROVER],
    READ: [APPLICATION_OWNER, APPROVER],
  };
  const rolesForOthers: any = {
    ADMINISTRATOR: [ADMINISTRATOR, APPROVER, APPLICATION_OWNER, READ],
  };

  const allUserRoleOptions = [
    {
      label: (
        <span className={getTAClass("userDetails", TA_TYPES.DROPDOWN, "administrator")}>
          {getUserRole(ADMINISTRATOR)}
        </span>
      ),
      value: ADMINISTRATOR,
    },
    {
      label: (
        <span className={getTAClass("userDetails", TA_TYPES.DROPDOWN, "approver")}>
          {getUserRole(APPROVER)}
        </span>
      ),
      value: APPROVER,
    },
    {
      label: (
        <span className={getTAClass("userDetails", TA_TYPES.DROPDOWN, "app-owner")}>
          {getUserRole(APPLICATION_OWNER)}
        </span>
      ),
      value: APPLICATION_OWNER,
    },
    {
      label: (
        <span className={getTAClass("userDetails", TA_TYPES.DROPDOWN, "read-only")}>
          {getUserRole(READ)}
        </span>
      ),
      value: READ,
    },
  ];

  const setUserRoleOptions = () => {
    const roleOptions: any = [];
    const isOwnProfile = currentUser.data.userId === userDetails.content.userId;
    const loggedInUserRole = currentUser.data.role;

    allUserRoleOptions.forEach((currentRoleOption) => {
      if (
        (isOwnProfile &&
          rolesForOwnUser[`${loggedInUserRole}`] &&
          rolesForOwnUser[`${loggedInUserRole}`].indexOf(currentRoleOption.value) !== -1) ||
        (!isOwnProfile &&
          rolesForOthers[`${loggedInUserRole}`] &&
          rolesForOthers[`${loggedInUserRole}`].indexOf(currentRoleOption.value) !== -1) ||
        originalRole === currentRoleOption.value
      ) {
        roleOptions.push(currentRoleOption);
      }
    });
    setRoles(roleOptions);
  };

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);
      setUserRoleOptions();
    }
  }, [currentUser]);

  const handleRoleDropdown = (role: string): void => {
    setSelectedRole(role);
    setFormValid(role !== originalRole);
  };

  const handleSubmit = () => {
    const userData = {
      role: selectedRole,
      userId: userDetails.content.userId,
    };
    const successMessage: string = isAdmin
      ? "User updated successfully!"
      : "Request submitted successfully!";
    dispatch(setUserDetails(userData, successMessage));
  };

  return (
    <ModalCreateBase
      title={
        [APPLICATION_OWNER, READ].includes(currentUser.data.role)
          ? "Request role change"
          : "Edit user"
      }
    >
      <DropdownInput
        label="Role"
        name="role"
        onChange={handleRoleDropdown}
        options={roles}
        selected={selectedRole}
      />
      <TextInput
        label="First name"
        name="first-name"
        placeholder="First name"
        disabled={true}
        value={userDetails.content.firstName}
        statusIcon={false}
      />
      <TextInput
        label="Last name"
        name="last-name"
        placeholder="Last name"
        disabled={true}
        value={userDetails.content.lastName}
        statusIcon={false}
      />
      <TextInput
        label="Email"
        name="email"
        placeholder="Email"
        disabled={true}
        statusIcon={false}
        value={userDetails.content.email}
      />

      <SFlexContainer justifyContent="space-around">
        <SFlexItem>
          <ButtonPrimary
            onClick={handleSubmit}
            disabled={!formValid}
            className="ta-modal-submit-button"
          >
            {isAdmin ? "Save" : "Request change"}
          </ButtonPrimary>
        </SFlexItem>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
