import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditAppVisibilityTypes,
  IEditAppVisibilityErrorAction,
  IEditAppVisibilityRequestAction,
  IEditAppVisibilitySuccessAction,
  TSetEditAppVisibilityActions,
} from "./types";

export const EditAppVisibilityRequestAction = (data: any): IEditAppVisibilityRequestAction => ({
  payload: data,
  type: EditAppVisibilityTypes.REQUEST,
});
export const EditAppVisibilitySuccessAction = (data: any): IEditAppVisibilitySuccessAction => ({
  payload: data,
  type: EditAppVisibilityTypes.SUCCESS,
});
export const EditAppVisibilityErrorAction = (error: any): IEditAppVisibilityErrorAction => ({
  payload: error,
  type: EditAppVisibilityTypes.ERROR,
});

export const editAppVisibility = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetEditAppVisibilityActions | TEditModalActions>
) => {
  dispatch(EditAppVisibilityRequestAction(config));
  ApplicationService.updateAppVisibility(config).then(
    (data: { data: any }) => {
      dispatch(EditAppVisibilitySuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: "Application visibility updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data.data.content.appClientId);
    },
    (error: any) => {
      dispatch(EditAppVisibilityErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
