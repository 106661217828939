import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateApplicationTypes {
  ERROR = "CREATE_APPLICATION_ERROR",
  REQUEST = "CREATE_APPLICATION_REQUEST",
  SUCCESS = "CREATE_APPLICATION_SUCCESS",
}

export interface ICreateApplicationRequestAction extends IReduxBaseAction<CreateApplicationTypes> {
  payload: any;
  type: CreateApplicationTypes.REQUEST;
}

export interface ICreateApplicationSuccessAction extends IReduxBaseAction<CreateApplicationTypes> {
  payload: any;
  type: CreateApplicationTypes.SUCCESS;
}

export interface ICreateApplicationErrorAction extends IReduxBaseAction<CreateApplicationTypes> {
  payload: any;
  type: CreateApplicationTypes.ERROR;
}

export type TSetCreateApplicationActions =
  | ICreateApplicationErrorAction
  | ICreateApplicationRequestAction
  | ICreateApplicationSuccessAction;
