export enum modalTypeEnum {
  ACTIVATE_TOPIC = "ACTIVATE_TOPIC",
  APPROVE_REQUEST = "APPROVE_REQUEST",
  CREATE_TOPIC_PUBLISH = "CREATE_TOPIC_PUBLISH",
  CREATE_TOPIC_SUBSCRIBE = "CREATE_TOPIC_SUBSCRIBE",
  SELECT_APP_SUBSCRIPTION = "SELECT_APP_SUBSCRIPTION",
  EDIT_APP = "EDIT_APP",
  EDIT_APP_DESTINATION = "EDIT_APP_DESTINATION",
  EDIT_APP_OWNERS = "EDIT_APP_OWNERS",
  EDIT_APP_VISIBILITY = "EDIT_APP_VISIBILITY",
  EDIT_TOPIC = "EDIT_TOPIC",
  EDIT_TOPIC_PERMISSION = "EDIT_TOPIC_PERMISSION",
  EDIT_USER = "EDIT_USER",
  DELETE_APP = "DELETE_APP",
  DELETE_MQTT = "DELETE_MQTT",
  DELETE_MQTT_BULK = "DELETE_MQTT_BULK",
  DELETE_TOPIC = "DELETE_TOPIC",
  DEPLOY_GROUND_CONFIGS = "DEPLOY_GROUND_CONFIGS",
  DISAPPROVE_REQUEST = "DISAPPROVE_REQUEST",
  DISPLAY_CHANGE_LOG = "DISPLAY_CHANGE_LOG",
  DISPLAY_CONFIG_VERSION = "DISPLAY_CONFIG_VERSION",
  DISPLAY_EDIT_GENERATE_COMMENT = "DISPLAY_EDIT_GENERATE_COMMENT",
  DISPLAY_GND_CHANGE_LOG = "DISPLAY_GND_CHANGE_LOG",
  DISPLAY_HISTORY_ITEMS = "DISPLAY_HISTORY_ITEMS",
  DISPLAY_SQS_CONFIG_INFO = "DISPLAY_SQS_CONFIG_INFO",
  NEW_AIRCRAFT = "NEW_AIRCRAFT",
  NEW_AIRCRAFT_GROUP = "NEW_AIRCRAFT_GROUP",
  NEW_AIRLINE = "NEW_AIRLINE",
  NEW_APP = "NEW_APP",
  NEW_TOPIC = "NEW_TOPIC",
  PUT_ON_HOLD_TOPIC = "PUT_ON_HOLD_TOPIC",
  REMOVE_ROUTE_GROUP = "REMOVE_ROUTE_GROUP",
  REQUEST_TOPIC_ACTIVATION = "REQUEST_TOPIC_ACTIVATION",
  SELECT_AIRCRAFT_GROUPS = "SELECT_AIRCRAFT_GROUPS",
  SELECT_ROUTE_GROUP = "SELECT_ROUTE_GROUP",
}
