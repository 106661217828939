import { Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { SFlexItem, SModalStackItem, SVerticalSpacer } from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import {
  ClientPasswordValidationErrorCodeEnum as ClientPasswordError,
  ClientPasswordValidator,
} from "../ClientPasswordValidator";
import { ClientUsernameValidator } from "../ClientUsernameValidator";
import { StatusIcon } from "../StatusIcon";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";
import { TextInputLink } from "./TextInputLink";

export const numConnRetriesValues = {
  default: 6,
  max: 65535,
  min: -1,
};

export const connRetryDelaySecValues = {
  default: 10,
  max: 60,
  min: 2,
};

export const isValidNumConnRetries = (value: number) =>
  !isNaN(value) && value >= numConnRetriesValues.min && value <= numConnRetriesValues.max;

export const isValidConnRetryDelaySec = (value: number) =>
  !isNaN(value) && value >= connRetryDelaySecValues.min && value <= connRetryDelaySecValues.max;

export const ModalEditApplicationPage1 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();

  const MAX_CHARACTER_LIMIT = 100;

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [updatedConfig]);

  const validateTextInput = (e: any) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > MAX_CHARACTER_LIMIT) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    if (e.target.value === " " || !validateTextInput(e)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleNumberInput = (value: number, field: string) => {
    if (isNaN(value)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: value });
  };

  // const handleTypeaheadInput = (user: any) => {
  //   const fullName = user.firstName ? `${user.firstName} ${user.lastName}` : "";
  //   setUpdatedConfig({ ...props.config, user, fullName });
  // };

  const handleInternalCheckboxClick = (e: any) => {
    setUpdatedConfig({
      ...props.config,
      internal: !props.config.internal,
    });
  };

  const handleDispatchTypeChange = (value: any) => {
    setUpdatedConfig({ ...props.config, groundDestinationType: value });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  return (
    <>
      <Text variant="captionText" className="ta-modal-input-caption-app-location">
        App location:{" "}
        {props.appData.appClientLocation === brokerLocationEnum.GROUNDSIDE
          ? "Groundside"
          : "Airside"}
      </Text>

      <TextInput
        label="App name"
        name="app-name"
        placeholder="App name"
        valid={
          props.config.appName.length > 0 && props.config.appName.length <= MAX_CHARACTER_LIMIT
        }
        value={props.config.appName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "appName");
        }}
      />

      <TextInput
        label="App version"
        name="app-version"
        placeholder="App version"
        valid={
          props.config.appVersion.length > 0 &&
          props.config.appVersion.length <= MAX_CHARACTER_LIMIT
        }
        value={props.config.appVersion}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleTextInput(e, "appVersion");
        }}
      />

      {/* <Text variant="captionText" className="ta-modal-input-caption-app-owner">
        Application owner{" "}
        <StatusIcon
          className={
            props.config.user && props.config.user.userId !== ""
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={props.config.user && props.config.user.userId !== ""}
        />
      </Text>
      <TypeaheadUser
        pageSelector="editApplication"
        value={props.config.fullName}
        onChange={handleTypeaheadInput}
        disabled={currentUser.data.role !== ADMINISTRATOR}
      /> */}

      {/* <TextInput
          label="System name"
          name="system-name"
          placeholder="System name"
          valid={props.config.systemName.length > 0}
          value={props.config.systemName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleTextInput(e, "systemName");
          }}
        /> */}

      {/* <SFlexItem>
        <div className="ta-modal-input-internal">
          <Checkbox
            onClick={handleInternalCheckboxClick}
            marked={props.config.internal ? CheckboxOptions.selected : CheckboxOptions.unselected}
            disabled={currentUser.data.role !== ADMINISTRATOR}
          />
        </div>
        <SVerticalSpacer />
        <Text variant="captionText" className="ta-modal-input-caption-internal">
          Internal
        </Text>
      </SFlexItem> */}

      {props.appData.appClientLocation === brokerLocationEnum.AIRSIDE && (
        <>
          <NumberInput
            label="Number of connection retries. For infinite retries, enter (-1)"
            name="number-of-connection-retries"
            placeholder="Number of connection retries"
            valid={isValidNumConnRetries(props.config.numConnRetries)}
            value={props.config.numConnRetries}
            min={numConnRetriesValues.min}
            max={numConnRetriesValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "numConnRetries");
            }}
          />

          <NumberInput
            label="Connection retry delay (sec)"
            name="connection-retry-delay"
            placeholder="Connection retry delay (sec)"
            valid={isValidConnRetryDelaySec(props.config.connRetryDelaySec)}
            value={props.config.connRetryDelaySec}
            min={connRetryDelaySecValues.min}
            max={connRetryDelaySecValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "connRetryDelaySec");
            }}
          />

          <Text variant="authenticationText" className="ta-modal-input-authentication-app-owner">
            Credentials
          </Text>
          <SModalStackItem className="ta-modal-auth-container-cred">
            <TextInput
              label='Application user name (Must be at least 3 characters and contains only "A-Z", "a-z", "0-9", "-_") '
              name="username"
              placeholder="User name"
              valid={ClientUsernameValidator.validateUsername(props.config.userName)}
              value={props.config.userName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleTextInput(e, "userName");
              }}
              disabled={currentUser.data.role !== ADMINISTRATOR}
            />

            <TextInput
              // [UMB-474] if empty/null password received from BE, it means new password is required during app edit, it can not be empty
              // label="Password (Current password will be kept if field left empty)"
              label='Application password (Must be at least 8 characters and contains only "A-Z", "a-z", "0-9", "-=") '
              name="password"
              placeholder="New password"
              // valid={props.config.password.length <= 100}
              valid={
                props.appData.password && !!props.appData.password && !props.config.password
                  ? true
                  : ClientPasswordError.ERROR_NONE ===
                    ClientPasswordValidator.validatePassword(props.config.password)
              }
              value={props.config.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleTextInput(e, "password");
              }}
            />
          </SModalStackItem>
        </>
      )}
    </>
  );
};
