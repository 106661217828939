import { OrderType } from "./ITableConfig";

export enum tabGroundsideConfigurationEnum {
  AIRLINES,
  GLOBAL_GROUPS,
  DEPLOYED_CONFIGS,
  HISTORY,
}

interface IGroundConfigListFilters {
  [key: string]: any;
  created_at?: string;
  created_by?: string;
  isCurrent?: boolean;
  isIntegration?: boolean;
  version?: string;
}

export interface IGroundConfigListTableConfig {
  [key: string]: any;
  filter: IGroundConfigListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetGroundConfigListData {
  content: any[];
  count: number;
}
