import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { HistoryChangeTypeEnum, HistoryDisplayModeEnum } from "../../../models/IChangeHistories";
import { ITableHeaderCells } from "../../../models/ITableConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import { SSpacer } from "../../../styles/styles";
import { ChangeHistoryList } from "../../ChangeHistoryList";

export const ConfigurationReleaseHistories = (props: any) => {
  const { configFileGenerate } = useSelector((state: RootState) => ({
    configFileGenerate: state.configFileGenerate,
  }));

  const [isAdmin, setIsAdmin] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(configFileGenerate);

  const headCells: ITableHeaderCells[] = [
    { id: "type", label: "Type" },
    { id: "version", label: "Version" },
    { id: "changeLog", label: "Change log", width: "2fr" },
  ];

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
    }
  }, [props]);

  useEffect(() => {
    if (!configFileGenerate.loading && !configFileGenerate.error) {
      setNeedRefresh(configFileGenerate);
    }
  }, [configFileGenerate]);

  return (
    <>
      <SSpacer />

      <ChangeHistoryList
        displayMode={HistoryDisplayModeEnum.CONFIG_VERSION_HISTORY}
        changeType={HistoryChangeTypeEnum.CONFIG_VERSION_CREATED}
        isAdmin={isAdmin}
        curUserId={props.userData ? props.userData.userId : ""}
        header={headCells}
        refresh={needRefresh}
      />
    </>
  );
};
