import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React from "react";

import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import {
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";

export const AircraftDetailsDetailsTab = (props: any) => (
  <Grid columns="2fr 1fr">
    <GridItem>
      <STallText variant="uiText" className="ta-aircraft-details-desc">
        <STextDataTitle>Description:</STextDataTitle>
        <STextDataValue>{props.acContent.description}</STextDataValue>
      </STallText>
      {props.acContent.autoProvisionStatus && (
        <>
          <STallText variant="uiText" className="ta-aircraft-details-prov-status">
            <STextDataTitle>Provision status:</STextDataTitle>
            <STextDataValue>{props.acContent.autoProvisionStatus}</STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-aircraft-details-prov-desc">
            <STextDataTitle>
              <SVerticalSpacer />
            </STextDataTitle>
            <STextDataValue>
              {props.acContent.autoProvisionStatusMsg || props.acContent.autoProvisionStatusDesc}
            </STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-aircraft-details-cert-serial">
            <STextDataTitle>Certificate serial:</STextDataTitle>
            <STextDataValue>{props.acContent.autoProvisionCertSerial}</STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-aircraft-details-cert-created-at">
            <STextDataTitle>Certificate created at:</STextDataTitle>
            <STextDataValue>
              {moment(props.acContent.autoProvisionCertCreatedAt)
                .tz(moment.tz.guess())
                .format("MMM/DD/YYYY HH:mm")}
            </STextDataValue>
          </STallText>
          <STallText variant="uiText" className="ta-aircraft-details-cert-expired-at">
            <STextDataTitle>Certificate expired at:</STextDataTitle>
            <STextDataValue>
              {moment(props.acContent.autoProvisionCertExpiredAt)
                .tz(moment.tz.guess())
                .format("MMM/DD/YYYY HH:mm")}
            </STextDataValue>
          </STallText>
        </>
      )}
    </GridItem>

    <GridItem>
      <STallText variant="uiText" className="ta-aircraft-details-created-by">
        <STextDataTitle>Created by:</STextDataTitle>
        <STextDataValue>
          {props.acContent.createdBy && props.acContent.createdBy.fullName}
        </STextDataValue>
      </STallText>
      <STallText variant="uiText" className="ta-aircraft-details-created-at">
        <STextDataTitle>Created at:</STextDataTitle>
        <STextDataValue>
          {props.acContent.createdAt &&
            moment(props.acContent.createdAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
        </STextDataValue>
      </STallText>
      <STallText
        variant="uiText"
        className={getTAClass("aircraft-details", TA_TYPES.INPUT, "modified-by")}
      >
        <STextDataTitle>Modified by:</STextDataTitle>
        <STextDataValue>
          {props.acContent.modifiedBy && props.acContent.modifiedBy.fullName}
        </STextDataValue>
      </STallText>
      <STallText variant="uiText" className="ta-aircraft-details-modified-at">
        <STextDataTitle>Modified at:</STextDataTitle>
        <STextDataValue>
          {props.acContent.modifiedAt &&
            moment(props.acContent.modifiedAt)
              // .tz("America/Los_Angeles")
              .tz(moment.tz.guess())
              .format("MMM/DD/YYYY HH:mm")}
        </STextDataValue>
      </STallText>
    </GridItem>
  </Grid>
);
