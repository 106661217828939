import { List } from "@material-ui/core";
import deepEqual from "deep-equal";
import { ButtonPrimary, GridItem, ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAppDestinationDetailsApplyAction } from "../../../actions/destination/get-details/actions";
import { getDestinationList } from "../../../actions/destination/get-list/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate, getTenantLabel, isUserAppOwner } from "../../../helper/util";
import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import {
  GroundDestinationTypeEnum,
  GroundEnvironmentTypeEnum,
  newDestinationId,
} from "../../../models/IDestination";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { ApplicationService } from "../../../services/ApplicationService";
import { RootState } from "../../../store";
import { SActiveLink, SOverlay, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

export const ApplicationDestinationRouting = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [groupAppDestinationList, setGroupAppDestinationList] = useState({
    content: [{}],
    count: 3,
  });

  const destList = [
    {
      appClientDestinationId: newDestinationId,
      destinationEndpoint: "",
      destinationEnvironment: GroundEnvironmentTypeEnum.PRODUCTION,
      destinationType: "",
      index: 0,
      routeCount: 0,
    },
    {
      appClientDestinationId: newDestinationId,
      destinationEndpoint: "",
      destinationEnvironment: GroundEnvironmentTypeEnum.INTEGRATION,
      destinationType: "",
      index: 0,
      routeCount: 0,
    },
    {
      appClientDestinationId: newDestinationId,
      destinationEndpoint: "",
      destinationEnvironment: GroundEnvironmentTypeEnum.INTEGRATION,
      destinationType: "",
      index: 1,
      routeCount: 0,
    },
  ];

  const headCells = [
    {
      id: "",
      label: "Destination",
      value: (row: ITableRow) =>
        row.destinationEnvironment === GroundEnvironmentTypeEnum.PRODUCTION
          ? `Production`
          : `Integration ${Number(row.index) + 1}`,
    },
    {
      id: "",
      label: "Type",
      value: (row: ITableRow) => row.destinationType,
    },
    {
      id: "",
      label: "Endpoint URL",
      value: (row: ITableRow) => row.destinationEndpoint,
      width: "3fr",
    },
    {
      id: "",
      label: "Source groups",
      value: (row: ITableRow) => row.routeCount,
    },
    {
      id: "edit",
      label: "Edit",
      width: "0.5fr",
    },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { appDestinationList } = useSelector((state: RootState) => ({
    appDestinationList: state.appDestinationList,
  }));

  const emptyFilterObject = {
    // aircraftGroupType: "",
    // modifiedBy: "",
    // modifiedDate: { startDate: undefined, endDate: undefined },
    createdBy: "",
    createdDate: { startDate: undefined, endDate: undefined },
    routeName: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      setIsOwner(
        props.appContent &&
          props.appContent.appOwners &&
          isUserAppOwner(props.userData, props.appContent.appOwners)
      );
    }
    if (props.appContent && !!props.appContent.appClientId) {
      dispatch(getDestinationList(props.appContent.appClientId, config));
    }
  }, []);

  // Wait for the first filter set
  // useEffect(() => {
  //   if (props.appContent && !!props.appContent.appId) {
  //     dispatch(getDestinationList(props.appContent.appId, config));
  //   }
  // }, [config]);

  useEffect(() => {
    appDestinationList.content.forEach((value: any) => {
      if (value.destinationEnvironment === GroundEnvironmentTypeEnum.PRODUCTION) {
        // destList[0].destinationEndpoint = value.destinationEndpoint;
        // destList[0].destinationType = value.destinationType;
        destList[0] = {
          ...destList[0],
          ...value,
          index: 0,
        };
      } else {
        if (value.index === 0) {
          // destList[1].destinationEndpoint = value.destinationEndpoint;
          // destList[1].destinationType = value.destinationType;
          destList[1] = {
            ...destList[1],
            ...value,
            destinationEnvironment: GroundEnvironmentTypeEnum.INTEGRATION,
          };
        } else if (value.index === 1) {
          // destList[2].destinationEndpoint = value.destinationEndpoint;
          // destList[2].destinationType = value.destinationType;
          destList[2] = {
            ...destList[2],
            ...value,
            destinationEnvironment: GroundEnvironmentTypeEnum.INTEGRATION,
          };
        }
      }
    });

    setGroupAppDestinationList({
      ...groupAppDestinationList,
      content: destList,
    });
  }, [appDestinationList]);

  const handleEditDestination = (row: ITableRow) => {
    let envIdx = "";
    if (row.appClientDestinationId === newDestinationId) {
      envIdx = `&env=${row.destinationEnvironment}&idx=${row.index}`;

      // clear existing dest details data and
      // push selected destination data
      dispatch(
        getAppDestinationDetailsApplyAction({
          ...row,
          appClient: {
            appClientId: props.appContent.appClientId,
            appName: props.appContent.appName,
            appVersion: props.appContent.appVersion,
          },
          appClientDestinationId: newDestinationId,
          destinationEndpoint: "",
          destinationType: "",
        })
      );
    }

    navigateToPage(
      `/destination/${row.appClientDestinationId}?appId=${props.appContent.appClientId}${envIdx}`
    );
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    editCell: {
      as: "a",
      disabled: (row: ITableRow) => !(isAdmin || isOwner),
      // disabled: (row: ITableRow) => !isAdmin,
      onClick: (row: ITableRow) => {
        if (isAdmin || isOwner) {
          // if (isAdmin) {
          handleEditDestination(row);
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: groupAppDestinationList,
    name: "route-groups",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* LOADING */}
      {/* {topicMQTTClientRoutes.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )} */}

      {/* TABLE */}
      {/* {!topicMQTTClientRoutes.loading && <Table {...tableProps} />} */}
      <Table {...tableProps} />
    </>
  );
};
