import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TDeleteAssociation {
  ERROR = "TOPIC_DELETEASSOCIATION_ERROR",
  REQUEST = "TOPIC_DELETEASSOCIATION_REQUEST",
  SUCCESS = "TOPIC_DELETEASSOCIATION_SUCCESS",
}

export interface IDeleteAssociationRequestAction extends IReduxBaseAction<TDeleteAssociation> {
  type: TDeleteAssociation.REQUEST;
}

export interface IDeleteAssociationSuccessAction extends IReduxBaseAction<TDeleteAssociation> {
  payload: any;
  type: TDeleteAssociation.SUCCESS;
}

export interface IDeleteAssociationErrorAction extends IReduxBaseAction<TDeleteAssociation> {
  payload: any;
  type: TDeleteAssociation.ERROR;
}

export type IDeleteAssociationActionTypes =
  | IDeleteAssociationErrorAction
  | IDeleteAssociationRequestAction
  | IDeleteAssociationSuccessAction;
