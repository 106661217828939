import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreatePublisherTypes {
  ERROR = "CREATE_PUBLISHER_ERROR",
  REQUEST = "CREATE_PUBLISHER_REQUEST",
  SUCCESS = "CREATE_PUBLISHER_SUCCESS",
}

export interface ICreatePublisherRequestAction extends IReduxBaseAction<CreatePublisherTypes> {
  payload: any;
  type: CreatePublisherTypes.REQUEST;
}

export interface ICreatePublisherSuccessAction extends IReduxBaseAction<CreatePublisherTypes> {
  payload: any;
  type: CreatePublisherTypes.SUCCESS;
}

export interface ICreatePublisherErrorAction extends IReduxBaseAction<CreatePublisherTypes> {
  payload: any;
  type: CreatePublisherTypes.ERROR;
}

export type TCreatePublisherActions =
  | ICreatePublisherErrorAction
  | ICreatePublisherRequestAction
  | ICreatePublisherSuccessAction;
