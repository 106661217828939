import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getTopicBridges } from "../../../actions/topic/get-bridges/actions";
import { getFormattedDate } from "../../../helper/util";
import { ITableConfig, ITableRow, OrderType } from "../../../models/ITableConfig";
import { RootState } from "../../../store";
import { SOverlay } from "../../../styles/styles";
import { Table } from "../../Table";

export const TopicDetailsBridgesList = (props?: any) => {
  const headCells = [
    {
      id: "from",
      label: "From",
      value: (row: ITableRow) => row.brokerTopology.parentBrokerType.brokerTypeName,
      width: "2fr",
    },
    {
      id: "to",
      label: "To",
      value: (row: ITableRow) => row.brokerTopology.remoteBrokerType.brokerTypeName,
      width: "2fr",
    },
    {
      id: "topologyName",
      label: "Topology name",
      value: (row: ITableRow) => row.brokerTopology.brokerTopologyName,
    },
    { id: "direction", label: "Direction" },
    { id: "qosLevel", label: "QoS Level" },
    { id: "secure", label: "Secure" },
    { id: "localPrefix", label: "Local prefix" },
    { id: "remotePrefix", label: "Remote prefix" },
    { id: "createdBy", label: "Created by", value: (row: ITableRow) => row.createdBy.fullName },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
    },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { topicBridges } = useSelector((state: RootState) => ({
    topicBridges: state.topicBridges,
  }));

  const emptyFilterObject = {
    topicId: props.data.topicId,
  };

  const initialSortAndPagination = {
    limit: 25,
    orderBy: "from",
    orderType: OrderType.ASC,
    page: 0,
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    ...initialSortAndPagination,
  });

  // Wait for the first filter set
  useEffect(() => {
    if (config.filter.topicId !== "") {
      dispatch(getTopicBridges(config));
    }
  }, [config]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...topicBridges,
    },
    name: "topic-bridges",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* LOADING */}
      {topicBridges.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      {/* TABLE */}
      <Table {...tableProps} />
      {/* TABLE */}
    </>
  );
};
