import moment from "moment-timezone";
import { Grid, GridItem } from "next-components";
import React, { useEffect, useState } from "react";

import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { localGroupBridgeTypeEnum, topicTypeEnum } from "../../../models/ITopicConfig";
import { ITopicDetailsContent } from "../../../models/ITopicDetailsConfig";
import { SimpleLabel, SimpleText, SSpacer } from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";

export const TopicDetailsTabDetails = (props: { data: ITopicDetailsContent }) => {
  const topicData = { ...props.data };

  enum fieldTypeEnum {
    DATE,
    STRING,
  }

  const topicDetailsColumns_Air = [
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "ttl"),
        title: "TTL",
        type: fieldTypeEnum.STRING,
        value: topicData.ttl,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "localGroupBridge"),
        title: !!topicData.localGroupBridge ? "Local group bridge" : "",
        type: fieldTypeEnum.STRING,
        // value: topicData.localGroupBridge,
        value: topicData.localGroupBridge === localGroupBridgeTypeEnum.ASTROVA ? "Astrova" : "",
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "qosLevel"),
        title: "QoS level",
        type: fieldTypeEnum.STRING,
        value: topicData.qosLevel,
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "isSecure"),
        title: "Secure",
        type: fieldTypeEnum.STRING,
        value: topicData.isSecure ? "Yes" : "No",
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "multicast"),
        title: "Multicast",
        type: fieldTypeEnum.STRING,
        value: topicData.multicast ? "Yes" : "No",
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "accessAnonymous"),
        title: "Anonymous access",
        type: fieldTypeEnum.STRING,
        value: topicData.accessAnonymous ? "Yes" : "No",
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "defaultRetainRequired"),
        title: "Default retain required",
        type: fieldTypeEnum.STRING,
        value: topicData.retainRequired ? "Yes" : "No",
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "createdBy"),
        title: "Created by",
        type: fieldTypeEnum.STRING,
        value: topicData.createdBy && topicData.createdBy.fullName,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "createdAt"),
        title: "Created at",
        type: fieldTypeEnum.DATE,
        value: topicData.createdAt,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "modifiedBy"),
        title: "Modified by",
        type: fieldTypeEnum.STRING,
        value: topicData.modifiedBy && topicData.modifiedBy.fullName,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "modifiedAt"),
        title: "Modified at",
        type: fieldTypeEnum.DATE,
        value: topicData.modifiedAt,
      },
    ],
  ];

  const topicDetailsColumns_A2G = [
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "offload"),
        title: "Offload type",
        type: fieldTypeEnum.STRING,
        value: topicData.offloadType,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "ttl"),
        title: "TTL",
        type: fieldTypeEnum.STRING,
        value: topicData.ttl,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "maxPayloadSize"),
        title: "Max payload size (KB)",
        type: fieldTypeEnum.STRING,
        value: topicData.maxPayloadSize,
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "priority"),
        title: "Priority",
        type: fieldTypeEnum.STRING,
        value: topicData.priority,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "qosLevel"),
        title: "QoS level",
        type: fieldTypeEnum.STRING,
        value: topicData.qosLevel,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "throttlingThresholdFactor"),
        // title: "Number of messages published per minute",
        title: "Max messages published per minute",
        type: fieldTypeEnum.STRING,
        value: topicData.throttleThresholdFactor,
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "isSecure"),
        title: "Secure",
        type: fieldTypeEnum.STRING,
        value: topicData.isSecure ? "Yes" : "No",
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "defaultRetainRequired"),
        title: "Default retain required",
        type: fieldTypeEnum.STRING,
        value: topicData.retainRequired ? "Yes" : "No",
      },
    ],
    [
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "createdBy"),
        title: "Created by",
        type: fieldTypeEnum.STRING,
        value: topicData.createdBy && topicData.createdBy.fullName,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "createdAt"),
        title: "Created at",
        type: fieldTypeEnum.DATE,
        value: topicData.createdAt,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "modifiedBy"),
        title: "Modified by",
        type: fieldTypeEnum.STRING,
        value: topicData.modifiedBy && topicData.modifiedBy.fullName,
      },
      {
        class: getTAClass("topicDetails", TA_TYPES.TEXT, "modifiedAt"),
        title: "Modified at",
        type: fieldTypeEnum.DATE,
        value: topicData.modifiedAt,
      },
    ],
  ];

  const createColumnItem = (field: any, idx: any) => {
    if (!!field.title) {
      return (
        <DataDetailLabel
          title={field.title}
          value={
            field.type === fieldTypeEnum.STRING
              ? field.value
              : moment(field.value)
                  // .tz("America/Los_Angeles")
                  .tz(moment.tz.guess())
                  .format("MMM/DD/YYYY HH:mm")
          }
          className={field.class}
          key={idx}
        ></DataDetailLabel>
      );
    }

    return "";
  };

  return (
    <>
      {topicData.description && (
        <div className={getTAClass("topicDetails", TA_TYPES.TEXT, "description")}>
          <SimpleLabel>Description:</SimpleLabel>
          <SimpleText>{topicData.description}</SimpleText>
        </div>
      )}
      <SSpacer />

      <Grid columns="repeat(4, 1fr)">
        {props.data.topicType === topicTypeEnum.AIR &&
          topicDetailsColumns_Air.map((column: any, colIndex: any) => (
            <GridItem key={colIndex}>{column.map(createColumnItem)}</GridItem>
          ))}

        {props.data.topicType === topicTypeEnum.A2G &&
          topicDetailsColumns_A2G.map((column: any, colIndex: any) => (
            <GridItem key={colIndex}>{column.map(createColumnItem)}</GridItem>
          ))}

        {/* {topicDetailsColumns.map((column: any, colIndex: any) => (
          <GridItem key={colIndex}>
            {column.map((field: any, index: any) => (
              <DataDetailLabel
                title={field.title}
                value={
                  field.type === fieldTypeEnum.STRING
                    ? field.value
                    : moment(field.value)
                        // .tz("America/Los_Angeles")
                        .tz(moment.tz.guess())
                        .format("MMM/DD/YYYY HH:mm")
                }
                className={field.class}
                key={index}
              ></DataDetailLabel>
            ))}
          </GridItem>
        ))} */}
      </Grid>
    </>
  );
};
