import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { isUserAppOwner } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { RootState } from "../../../store";

import { AppDestinationSourceList } from "./AppDestinationSourceList";

export const AppDestinationSources = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      setIsOwner(
        props.appData &&
          props.appData.appOwners &&
          isUserAppOwner(props.userData, props.appData.appOwners)
      );
    }
  }, [props]);

  const openAddRouteGroupModal = (routeGroupFilter: any) => {
    dispatch(
      editModalOpenAction({
        appData: props.appData,
        destData: props.destData,
        popupData: {
          commentHint: `Please enter a comment about why you are adding this source group.`,
          // commentRequired: false,
        },
        routeFilter: {
          ...routeGroupFilter,
        },
        type: modalTypeEnum.SELECT_ROUTE_GROUP,
      })
    );
  };

  return (
    <AppDestinationSourceList
      appData={props.appData}
      destData={props.destData}
      btnClick={(filter: any) => {
        openAddRouteGroupModal(filter);
      }}
      btnDisabled={!(isAdmin || isOwner)} // app owner can manage source groups now
      isAdmin={isAdmin}
    />
  );
};
