/* istanbul ignore file */
import { IGetApplicationListData } from "../../../models/IAppListingTableConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AppVisibilityUsersType {
  RESET = "GET_APP_VISIBILITY_USERS_RESET",
  REQUEST = "GET_APP_VISIBILITY_USERS_REQUEST",
  SUCCESS = "GET_APP_VISIBILITY_USERS_SUCCESS",
  ERROR = "GET_APP_VISIBILITY_USERS_ERROR",
}

export interface IAppVisibilityUsersResetAction extends IReduxBaseAction<AppVisibilityUsersType> {
  type: AppVisibilityUsersType.RESET;
}

export interface IAppVisibilityUsersRequestAction extends IReduxBaseAction<AppVisibilityUsersType> {
  type: AppVisibilityUsersType.REQUEST;
}

export interface IAppVisibilityUsersSuccessAction extends IReduxBaseAction<AppVisibilityUsersType> {
  payload: IGetApplicationListData;
  type: AppVisibilityUsersType.SUCCESS;
}

export interface IAppVisibilityUsersErrorAction extends IReduxBaseAction<AppVisibilityUsersType> {
  payload: any;
  type: AppVisibilityUsersType.ERROR;
}

export type TGetAppVisibilityUsersActions =
  | IAppVisibilityUsersResetAction
  | IAppVisibilityUsersRequestAction
  | IAppVisibilityUsersSuccessAction
  | IAppVisibilityUsersErrorAction;
