export enum topicStatusEnum {
  ALL = "ALL",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  ONHOLD = "ON HOLD",
  DELETED = "DELETED",
}

export enum payloadFormatEnum {
  JSON = "JSON",
  PROTOBUF = "Protobuf",
}

export enum qosEnum {
  FIRE_AND_FORGET = 0,
  ATLEAST_ONCE = 1,
}

// export enum retainRequiredEnum {
//   YES = "YES",
//   NO = "NO",
// }

export enum topicTypeEnum {
  /**
   * Air
   */
  AIR = "air",
  /**
   * Air-to-ground
   */
  A2G = "a2g",
}

export enum localGroupBridgeTypeEnum {
  ASTROVA = "ASTROVA",
}

/*
 * Max payload size (KB)
 */
export const maxPayloadSizeValues = {
  default: 1,
  max: 90, // UMB-1926: limit a2g topic payload size to maximum of 90KB
  min: 0,
  warning: 64,
};

/*
 * Number of messages published per minute
 */
export const throttleThresholdFactorValues = {
  default: 1,
  max: 99,
  min: 1,
  warning: 60,
};

export interface ITopicListFilters {
  [key: string]: any;
}

export interface ITopicListingTableConfig {
  [key: string]: any;
  filter: ITopicListFilters;
  limit: number;
  orderBy: string;
  orderType: any;
  page: number;
}

export interface IGetTopicListData {
  content: IGetTopicListContent[];
  count: number;
  loading: boolean;
}

export interface IGetTopicDetailsData {
  content: IGetTopicListContent;
  count: number;
  loading: boolean;
}

export interface IGetTopicListContent {
  appClient: IGetTopicAppClient[];
  brokerType: IGetTopicBrokerType[];
  createdAt: string;
  createdBy: IGetTopicListUser[];
  modifiedAt: string;
  modifiedBy: IGetTopicListUser[];
  topicId: string;
  topicName: string;
}

export interface IGetTopicDetailsContent {
  appClient: IGetTopicAppClient[];
  createdBy: IGetTopicListUser[];
  modifiedBy: IGetTopicListUser[];
  topicId: string;
  topicName: string;
}

export interface IGetTopicAppClient {
  appClientId: string;
  appName: string;
  appVersion: string;
}

export interface IGetTopicBrokerType {
  brokerType: string;
  brokerTypeId: string;
  brokerTypeName: string;
  lruDescription: string;
}

interface IGetTopicListUser {
  fullName: string;
  userId: string;
}

export enum offloadType {
  DEFERRED = "Deferred",
  LIVE = "Live",
}

export interface ITopic {
  accessAnonymous: boolean;
  appClientId: string;
  changeComment: string;
  dependencies: string;
  description: string;
  featureName: string;
  isSecure: boolean;

  localGroupBridge: string;

  /**
   * Max payload size (KB)
   */
  maxPayloadSize?: number;

  multicast: boolean;
  obfuscationRequirement: string;
  offloadType: offloadType;
  priority: number;
  qosLevel: number;

  retainRequired: boolean;

  serviceName: string;

  /**
   * Throttling threshold factor (per minute)
   */
  throttleThresholdFactor?: number;

  topicCategory: string;
  // topicMqttClient: {
  //   accessLevel: string;
  //   frequency: string;
  //   retainRequired: boolean;
  // };
  topicName: string;
  topicPayloadId: string;
  topicType: topicTypeEnum;
  ttl: number;
  versionId: string;
}

export interface IPayload {
  currentPayloadId?: string;
  payloadFile: any;
  payloadFormat: string;
  payloadType: string;
}
