import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getGroundConfigList } from "../../../actions/groundconfig/get-list/actions";
import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getFormattedDate } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { RootState } from "../../../store";
import { SActiveLink, SOverlay, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalDisplayConfigVersion } from "../../modals/ModalDisplayConfigVersion";
import { Table } from "../../Table";

export const DeployedConfigurationsList = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { groundConfigList } = useSelector((state: RootState) => ({
    groundConfigList: state.groundConfigList,
  }));

  const { editModal } = useSelector((state: RootState) => ({
    editModal: state.editModal,
  }));

  const emptyFilterObject = {
    createdBy: "",
    createdDate: { startDate: null, endDate: null },
    isIntegration: "",
    version: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "createdAt",
    orderType: OrderType.DESC,
    page: 0,
  });

  // Get configs only once
  useEffect(() => {
    dispatch(getGroundConfigList(config));
  }, [config, props.refresh]);

  const handleShowConfigVersionDetail = (data: any) => {
    if (data) {
      dispatch(
        editModalOpenAction({
          configVersion: {
            comment: data.comment,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            title: "Groundside configuration version details",
            version: data.version,
          },
          type: modalTypeEnum.DISPLAY_CONFIG_VERSION,
        })
      );
    }
  };

  const IsIntegrationOptions = [
    {
      label: <span className="ta-dropdown-yes">Integration</span>,
      value: "true",
    },
    {
      label: <span className="ta-dropdown-no">Production</span>,
      value: "false",
    },
  ];

  const IsCurrentOptions = [
    {
      label: <span className="ta-dropdown-yes">Yes</span>,
      value: "true",
    },
    {
      label: <span className="ta-dropdown-no">No</span>,
      value: "false",
    },
  ];

  const headCells: ITableHeaderCells[] = [
    {
      id: "version",
      label: "Version",
      value: (row: ITableRow) => (
        <SActiveLink
          title="Click to view version detail"
          onClick={() => {
            handleShowConfigVersionDetail(row);
          }}
        >
          {row.version}
        </SActiveLink>
      ),
    },
    {
      id: "isIntegration",
      label: "Environment",
      value: (row: ITableRow) => (row.isIntegration ? "Integration" : "Production"),
    },
    {
      id: "isCurrent",
      label: "Current",
      value: (row: ITableRow) => (row.isCurrent ? "Yes" : "No"),
    },
    {
      id: "createdBy",
      label: "Created By",
      value: (row: ITableRow) => row.createdBy && row.createdBy.fullName,
    },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
    },
  ];

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "version",
        placeholder: "Version",
        taClass: "version",
      },
      {
        data: IsIntegrationOptions,
        name: "isIntegration",
        placeholder: "Environment",
        taClass: "isIntegration",
        type: FilterType.DROPDOWN,
      },
      {
        data: IsCurrentOptions,
        name: "isCurrent",
        placeholder: "Current",
        taClass: "isCurrent",
        type: FilterType.DROPDOWN,
      },
      {
        name: "createdBy",
        placeholder: "Created by",
        taClass: "createdBy",
        type: FilterType.TEXT,
      },
      {
        name: "createdDate",
        placeholder: "Created",
        taClass: "createdDate",
        type: FilterType.DATEPICKER,
      },
    ],
    pageName: "groundConfigList",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...groundConfigList,
    },
    name: "gound-config",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* FILTER */}
      <Filter {...filterConfig} />

      <SSpacer />

      {/* LOADING */}
      {groundConfigList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      {/* TABLE */}
      <Table {...tableProps}></Table>

      {editModal.open && editModal.type === modalTypeEnum.DISPLAY_CONFIG_VERSION && (
        <ModalDisplayConfigVersion />
      )}
    </>
  );
};
