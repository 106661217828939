import { Dispatch } from "redux";

import { IGetTopicListData, ITopicListingTableConfig } from "../../../models/ITopicConfig";
import { TopicService } from "../../../services/TopicService";

import { FilterTopicListType, TFilterTopicListActions } from "./types";

export const filterTopicListRequestAction = (): TFilterTopicListActions => ({
  type: FilterTopicListType.REQUEST,
});

export const filterTopicListSuccessAction = (data: any): TFilterTopicListActions => ({
  payload: data,
  type: FilterTopicListType.SUCCESS,
});

export const filterTopicListErrorAction = (error: string): TFilterTopicListActions => ({
  payload: error,
  type: FilterTopicListType.ERROR,
});

export const getFilteredTopicList = (conf: ITopicListingTableConfig) => (
  dispatch: Dispatch<TFilterTopicListActions>
) => {
  dispatch(filterTopicListRequestAction());
  TopicService.getTopicList(conf).then(
    (response: { data: { data: IGetTopicListData } }) => {
      if (
        conf &&
        conf.statuses &&
        response.data &&
        response.data.data &&
        response.data.data.content &&
        conf.statuses.length > 0
      ) {
        response.data.data.content = response.data.data.content.filter(
          (topic: any) => conf.statuses.indexOf(topic.status) !== -1
        );
      }
      dispatch(filterTopicListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(filterTopicListErrorAction(error))
  );
};
