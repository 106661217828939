import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum UploadPayloadTypes {
  ERROR = "UPLOAD_PAYLOAD_ERROR",
  REQUEST = "UPLOAD_PAYLOAD_REQUEST",
  SUCCESS = "UPLOAD_PAYLOAD_SUCCESS",
}

export interface IUploadPayloadRequestAction extends IReduxBaseAction<UploadPayloadTypes> {
  payload: any;
  type: UploadPayloadTypes.REQUEST;
}

export interface IUploadPayloadSuccessAction extends IReduxBaseAction<UploadPayloadTypes> {
  payload: any;
  type: UploadPayloadTypes.SUCCESS;
}

export interface IUploadPayloadErrorAction extends IReduxBaseAction<UploadPayloadTypes> {
  payload: any;
  type: UploadPayloadTypes.ERROR;
}

export type TUploadPayloadActions =
  | IUploadPayloadErrorAction
  | IUploadPayloadRequestAction
  | IUploadPayloadSuccessAction;
