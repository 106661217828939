import { Dropdown, IconInfo16, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getBrokerTypeList } from "../../actions/broker-type/get-filtered-list/actions";
import { popupOpenAction } from "../../actions/popup/actions";
import { getTopicList } from "../../actions/topic/get-list/actions";
import { getMqttClients } from "../../actions/topic/get-mqtt-clients/actions";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { MqttLocalGroupDataEnum } from "../../models/IMqtt";
import {
  localGroupBridgeTypeEnum,
  topicStatusEnum,
  topicTypeEnum,
} from "../../models/ITopicConfig";
import { RootState } from "../../store";
import {
  SActiveLink,
  SFlexContainer,
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { BrokerTypeDropdown } from "../BrokerTypeDropdown";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";
import { Typeahead } from "../Typeahead";

// tslint:disable-next-line: cyclomatic-complexity
export const ModalAddTopicSubscriberPage1 = (props: any) => {
  const { applicationTopicFullList, brokerTypeList, topicList } = useSelector(
    (state: RootState) => ({
      applicationTopicFullList: state.applicationTopicFullList,
      brokerTypeList: state.brokerTypeList,
      topicList: state.topicList,
    })
  );

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [topicIsNotSubscribed, setTopicIsNotSubscribed] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [alertMultipleSubscribeText, setAlertMultipleSubscribeText] = useState("");
  const [topicIsDraft, setTopicIsDraft] = useState(false);
  const [topicIsLocalGroup, setTopicIsLocalGroup] = useState(false);
  const [alertText2, setAlertText2] = useState("");

  const [sebBrokerType, setSebBrokerType]: any = useState({});

  const astrovaSOMOptions: any[] = [
    {
      label: <span className="ta-dropdown-astrova-soms-option-primary">Primary unit</span>,
      value: MqttLocalGroupDataEnum.ASTROVA_PUB_PRIMARY,
    },
    {
      label: <span className="ta-dropdown-astrova-soms-option-secondary">Secondary unit</span>,
      value: MqttLocalGroupDataEnum.ASTROVA_PUB_SECONDARY,
    },
  ];

  useEffect(() => {
    dispatch(
      getBrokerTypeList({
        filter: { brokerLocation: brokerLocationEnum.AIRSIDE },
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setSebBrokerType(brokerTypeList.content.filter((broker) => broker.brokerType === "SEB")[0]);
  }, [brokerTypeList]);

  // Initialize the brokerType with the last used brokerType
  useEffect(() => {
    if (applicationTopicFullList.content.length) {
      const mqttClient = applicationTopicFullList.content.reduce((a, b) =>
        new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? a : b
      );
      setUpdatedConfig({ ...props.config, brokerType: mqttClient.brokerType });
    }
  }, [applicationTopicFullList]);

  useEffect(() => {
    if (props.config && updatedConfig && props.onChange) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    setTopicIsNotSubscribed(true);

    if (applicationTopicFullList.content.length) {
      applicationTopicFullList.content.forEach((row: any) => {
        if (
          row.brokerType &&
          props.config.brokerType.brokerTypeId &&
          row.brokerType.brokerTypeId === props.config.brokerType.brokerTypeId &&
          row.topic.topicId === props.config.topic.topicId
        ) {
          setTopicIsNotSubscribed(false);
        }
      });
    }

    if (props.config.topic.topicId === undefined) {
      setTopicIsDraft(false);
      setTopicIsLocalGroup(false);
      setAlertMultipleSubscribeText("");
      setAlertText("");
      setAlertText2("");
      setTopicIsNotSubscribed(true);
    }
  }, [props.config]);

  useEffect(() => {
    if (topicIsLocalGroup) {
      setUpdatedConfig({
        ...props.config,
        // props.config && !!props.config.localGroupData
        //   ? props.config.localGroupData
        //   : astrovaSOMOptions[0].value,
        bridge: true,
        brokerType: sebBrokerType,
        localGroupData: "",
      });
    }
  }, [topicIsLocalGroup]);

  const handleCreateChipLabel = ({ value }: { value: any }) => {
    let label = "";
    setAlertText("");
    setAlertText2("");
    setAlertMultipleSubscribeText("");
    setTopicIsDraft(false);
    setTopicIsLocalGroup(false);

    if (value && value.topicName) {
      label = value.topicName;
      if (value.topicStatus === topicStatusEnum.ONHOLD) {
        setAlertText("⚠️ Topic is on hold.");
      }
      if (value.topicStatus === topicStatusEnum.DRAFT) {
        setTopicIsDraft(true);
        setAlertText("⚠️ Topic is a draft.");
      }
      if (value.topicLocalGroupBridge === localGroupBridgeTypeEnum.ASTROVA) {
        setTopicIsLocalGroup(true);
        setAlertText2("⚠️ Topic is for local group bridging on Astrova units only.");
      }
      if (!topicIsNotSubscribed) {
        setAlertMultipleSubscribeText("⚠️ Topic is already subscribed.");
      }
    }

    return label;
  };

  const handleCreateBridgeInfo = () => {
    dispatch(
      popupOpenAction({
        content: "Bridge allows you to receive messages from topics published from a remote LRU.",
        title: "Create bridge",
        type: "Info",
      })
    );
  };

  const handleTopicNotFoundHelp = () => {
    dispatch(
      popupOpenAction({
        content: `<div>
            Mostly it's because your application is not allowed to subscribe the topic due to its "Subscribe permission".
          </div>
          <br>
          <div>
            You will need to contact the topic's owner admins and have them to change the setting to allow other applications to subscribe.
          </div>`,
        isContentHtml: true,
        title: "Can't find the topic?",
        type: "Info",
      })
    );
  };

  const handleTypeaheadInput = (topic: any) => {
    setUpdatedConfig({
      ...props.config,
      topic: {
        appClientId:
          topic.appClient && topic.appClient.appClientId
            ? topic.appClient.appClientId
            : topic.appClientId,
        topicId: topic.topicId,
        topicLocalGroupBridge: topic.localGroupBridge,
        topicName: topic.topicName,
        topicStatus: topic.status,
      },
    });
  };

  const handleBrokerTypeDropdown = (value: any): any => {
    setUpdatedConfig({
      ...props.config,
      brokerType: value,
      topic: {
        appClientId: undefined,
        topicId: undefined,
        topicLocalGroupBridge: "",
        topicName: "",
        topicStatus: "",
      },
    });
  };

  const handleAstrovaSOMTypeDropdown = (value: any): any => {
    setUpdatedConfig({
      ...props.config,
      localGroupData: value,
    });
  };

  const checkStatus = () =>
    (props.config.topic.topicStatus === topicStatusEnum.DRAFT ||
      props.config.topic.topicStatus === topicStatusEnum.ACTIVE) &&
    topicIsNotSubscribed;

  return (
    <>
      <Text>
        My application:{" "}
        {/* <span>{applicationDetails.content && applicationDetails.content.appName}</span> */}
        <span>{props.appData && props.appData.appName}</span>
      </Text>

      <div>
        <Text variant="captionText" className="ta-modal-input-caption-broker-type">
          Subscribing from{" "}
          <StatusIcon
            className={
              /* tslint:disable:prefer-template */
              "ta-status-icon-broker-type " +
              (props.config && props.config.brokerType && props.config.brokerType.brokerTypeId
                ? "ta-status-icon-success"
                : "ta-status-icon-fail")
            }
            check={props.config && props.config.brokerType && props.config.brokerType.brokerTypeId}
          />
        </Text>
        <div className="ta-modal-input-broker-type">
          <BrokerTypeDropdown
            location={
              (props.appData && props.appData.appClientLocation) || brokerLocationEnum.AIRSIDE
            }
            onChange={handleBrokerTypeDropdown}
            selected={props.config.brokerType}
            useLruDescriptionForLabel
          />
        </div>
      </div>

      {/* <div>
        <Text variant="captionText" className="ta-modal-input-caption-som-type">
          Subscribing through Astrova unit{" "}
          <StatusIcon
            className={
              "ta-status-icon-som-type " +
              (props.config && !!props.config.localGroupData
                ? "ta-status-icon-success"
                : "ta-status-icon-fail")
            }
            check={props.config && !!props.config.localGroupData}
          />
        </Text>
        <SFlexItem>
          <SVerticalSpacer />
          <Text>
            Primary SOM / Secondary SOM
          </Text>
        </SFlexItem>
        <div className="ta-modal-input-som-type">
          <Dropdown
            options={astrovaSOMOptions}
            label="Select SOM type"
            onChange={handleAstrovaSOMTypeDropdown}
            selected={props.config.localGroupData}
          />
        </div>
      </div> */}

      <SFlexContainer justifyContent="space-between">
        <Text variant="captionText" className="ta-modal-input-caption-topic">
          Topic name{" "}
          <StatusIcon
            className={
              /* tslint:disable:prefer-template */
              "ta-status-icon-topic " +
              (props.config && props.config.topic && props.config.topic.topicId && checkStatus()
                ? "ta-status-icon-success"
                : "ta-status-icon-fail")
            }
            check={
              props.config && props.config.topic && props.config.topic.topicId && checkStatus()
            }
          />
        </Text>
        {/* <SVerticalSpacer width="20px"/> */}
        <Text variant="captionText">
          <SActiveLink onClick={handleTopicNotFoundHelp}>Can't find the topic?</SActiveLink>
        </Text>
      </SFlexContainer>

      <div className="ta-modal-input-topic-name">
        <Typeahead
          pageSelector="addTopicSubscriber"
          autoFillOnExactMatchOnly={{
            key: "topicName",
            rule: props.autoFillOnExactMatchOnly,
          }}
          disabled={props.disabled}
          config={{
            default: {
              filter: {
                appClientId: undefined,
                subscribableBy:
                  props.appData && props.appData.appClientId
                    ? props.appData.appClientId
                    : undefined,
                topicId: undefined,
                topicName: "",
                topicStatus: "",
                // topicType:
                //   props.appData && props.appData.appClientLocation === brokerLocationEnum.GROUNDSIDE
                //     ? topicTypeEnum.A2G
                //     : topicTypeEnum.AIR,
              },
              orderBy: "topicName",
              statuses: [topicStatusEnum.ACTIVE, topicStatusEnum.DRAFT, topicStatusEnum.ONHOLD],
            },
            setFilter: (topicName: string) => ({
              topicName,
            }),
          }}
          suggestion={{
            getList: (config: any) => dispatch(getTopicList(config)),
            isItemDisabled: (topic) => {
              let subscribedStatus = false;
              if (applicationTopicFullList.content.length) {
                subscribedStatus = applicationTopicFullList.content.some(
                  (row: any) =>
                    row.brokerType &&
                    props.config.brokerType.brokerTypeId &&
                    row.brokerType.brokerTypeId === props.config.brokerType.brokerTypeId &&
                    row.topic.topicId === topic.topicId
                );
              }

              if (topic.status === topicStatusEnum.ONHOLD || subscribedStatus) {
                return true;
              }

              return false;
            },
            list: topicList,
            select: (topic: any) => `${topic.topicName}`,
          }}
          name="topic"
          chipLabel={handleCreateChipLabel}
          value={props.config && props.config.topic}
          hasSelected={(p: any) => p && p.topicId === undefined}
          valueToSetOnLoad="topicName"
          handleTypeahead={handleTypeaheadInput}
          pattern="{topicName} - {status}"
        />
      </div>

      {!topicIsLocalGroup && (
        <SFlexItem>
          <div className="ta-modal-input-createBridge">
            <Checkbox
              onClick={() => {
                setUpdatedConfig({ ...props.config, bridge: !props.config.bridge });

                return;
              }}
              marked={props.config.bridge ? CheckboxOptions.selected : CheckboxOptions.unselected}
              disabled={
                (props.appData &&
                  props.appData.appClientLocation === brokerLocationEnum.GROUNDSIDE) ||
                topicIsLocalGroup
              }
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-createBridge">
            I want to create a bridge
          </Text>
          <SVerticalSpacer />
          <SInfoLabelContainer className="ta-modal-info-createBridge">
            <SInfoLabel>
              <IconInfo16 onClick={handleCreateBridgeInfo} />
            </SInfoLabel>{" "}
          </SInfoLabelContainer>
        </SFlexItem>
      )}

      {!!alertMultipleSubscribeText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-subscribed-alert-text">
            <span
              style={{
                color: "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertMultipleSubscribeText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text">
            <span
              style={{
                color: topicIsDraft ? "black" : "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText}
            </span>
          </Text>
        </div>
      )}
      {!!alertText2 && (
        <div
          style={{
            backgroundColor: "#fff3cd",
            border: "2px solid #ffe69c",
            borderRadius: "6px",
            height: "36px",
          }}
        >
          <Text className="ta-modal-alert-text2">
            <span
              style={{
                color: topicIsLocalGroup ? "black" : "red",
                lineHeight: "32px",
                paddingLeft: "8px",
                verticalAlign: "middle",
              }}
            >
              {alertText2}
            </span>
          </Text>
        </div>
      )}

      <SSpacer height="40px" />
    </>
  );
};
