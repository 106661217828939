import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetPublisherListTypes {
  ERROR = "GET_PUBLISHER_LIST_ERROR",
  REQUEST = "GET_PUBLISHER_LIST_REQUEST",
  SUCCESS = "GET_PUBLISHER_LIST_SUCCESS",
}

export interface IGetPublisherListRequestAction extends IReduxBaseAction<GetPublisherListTypes> {
  payload: any;
  type: GetPublisherListTypes.REQUEST;
}

export interface IGetPublisherListSuccessAction extends IReduxBaseAction<GetPublisherListTypes> {
  payload: any;
  type: GetPublisherListTypes.SUCCESS;
}

export interface IGetPublisherListErrorAction extends IReduxBaseAction<GetPublisherListTypes> {
  payload: any;
  type: GetPublisherListTypes.ERROR;
}

export type TGetPublisherListActions =
  | IGetPublisherListErrorAction
  | IGetPublisherListRequestAction
  | IGetPublisherListSuccessAction;
