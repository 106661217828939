import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { userTabEnum } from "../../../models/IUserTabs";

export const UserTabs = (props: { activeTab: userTabEnum; onChange: any }) => {
  const tabList = [
    {
      class: "ta-user-tab-owns-applications",
      id: userTabEnum.OWNS_APP_TABLE,
      title: "Owns applications",
    },
    { class: "ta-user-tab-details", id: userTabEnum.DETAILS, title: "Details" },
  ];

  const handleTabChange = (id: userTabEnum) => {
    props.onChange(id);
  };

  return (
    <Grid columns="2fr 2fr">
      <GridItem>
        <TabContainer>
          {tabList.map((tab: any, index: any) => (
            <Tab
              key={index}
              className={tab.class}
              active={props.activeTab === tab.id}
              onClick={() => {
                handleTabChange(tab.id);
              }}
            >
              {tab.title}
            </Tab>
          ))}
        </TabContainer>
      </GridItem>
    </Grid>
  );
};
