import { ButtonGhostInverted, ButtonPrimary, ButtonPrimaryIcon, Grid, Text } from "next-components";
import React from "react";

import { Actions, BatchActionGrid, Separator } from "./BatchActionActive.style";
import { IBatchActionActive } from "./IBatchActionActive";

const BatchActionActive = (props: IBatchActionActive) => {
  const columns = "fit-content(100%) auto";

  return (
    <BatchActionGrid columns={columns} alignItems="center">
      <Text ellipsis variant={"label"} color="#fff">
        {props.label}
      </Text>
      <Grid alignItems="center" columns="1fr 1px auto" gap="0">
        <Actions>
          {props.actions &&
            props.actions.map((action: any) =>
              action.icon ? (
                <ButtonPrimaryIcon
                  key={Math.random()}
                  icon={action.icon}
                  onClick={action.onClick}
                  disabled={action.disabled}
                >
                  {action.title}
                </ButtonPrimaryIcon>
              ) : (
                <ButtonPrimary
                  key={Math.random()}
                  onClick={action.onClick}
                  disabled={action.disabled}
                >
                  {action.title}
                </ButtonPrimary>
              )
            )}
        </Actions>
        <Separator />
        <ButtonGhostInverted
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </ButtonGhostInverted>
      </Grid>
    </BatchActionGrid>
  );
};

export default BatchActionActive;
