import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Error } from "./pages/Error";

export const RouteUnauthenticated = () => (
  <Switch>
    <Route exact path="/error" component={Error} />
    <Redirect from="/" to="/error" />
  </Switch>
);
