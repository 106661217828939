export enum MqttClientTypesEnum {
  PUBLISHER = "PUBLISHER",
  SUBSCRIBER = "SUBSCRIBER",
}

/**
 * Mqtt topic local group setting data
 */
export enum MqttLocalGroupDataEnum {
  /**
   * For ASTROVA - pub from primary unit
   */
  ASTROVA_PUB_PRIMARY = "ASTROVA_PUB_PRIMARY",
  /**
   * For ASTROVA - pub from secondary unit
   */
  ASTROVA_PUB_SECONDARY = "ASTROVA_PUB_SECONDARY",
}

export interface IMqtt {
  appClient: {
    // appBrokerType: {
    //   brokerType: string;
    //   brokerTypeId: string;
    // };
    appClientId: string;
    appName: string;
    appOwner: {
      fullName: string;
      userId: string;
    };
    appVersion: string;
  };
  brokerTopology: {
    brokerTopologyId: string;
    brokerTopologyName: string;
    parentBrokerType: {
      brokerType: string;
      brokerTypeId: string;
    };
    remoteBrokerType: {
      brokerType: string;
      brokerTypeId: string;
    };
  };
  brokerType: {
    brokerLocation: string;
    brokerType: string;
    brokerTypeId: string;
    lruDescription: string;
  };
  clientType: string;
  createdAt: string;
  modifiedAt: string;
  modifiedBy: {
    fullName: string;
    userId: string;
  };
  routeCount: string;
  topic: {
    appClient: {
      // appBrokerType: {
      //   brokerType: string;
      //   brokerTypeId: string;
      // };
      appClientId: string;
      appName: string;
      appOwner: {
        fullName: string;
        userId: string;
      };
      appVersion: string;
    };
    status: string;
    topicId: string;
    topicName: string;
  };
  topicMqttClientId: string;
}
