import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";

import { HistoryChangeTypeEnum, HistoryDisplayModeEnum } from "../../../models/IChangeHistories";
import { ADMINISTRATOR } from "../../../models/UserTypes";
// import { RootState } from "../../../reducers";
import { ChangeHistoryList } from "../../ChangeHistoryList";

export const AirlineChangeHistory = (props: any) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
    }
  }, [props]);

  return (
    <ChangeHistoryList
      displayMode={HistoryDisplayModeEnum.AIRLINE_HISTORY}
      tenantId={props.tenant ? props.tenant.tenantId : ""}
      isAdmin={isAdmin}
      curUserId={props.userData ? props.userData.userId : ""}
    />
  );
};
