import { Dispatch } from "redux";

import { topicPermissionTypeEnum } from "../../../models/ITopicDetailsConfig";
import { TopicService } from "../../../services/TopicService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditTopicPermissionTypes,
  IEditTopicPermissionErrorAction,
  IEditTopicPermissionRequestAction,
  IEditTopicPermissionSuccessAction,
  TSetEditTopicPermissionActions,
} from "./types";

export const EditTopicPermissionRequestAction = (data: any): IEditTopicPermissionRequestAction => ({
  payload: data,
  type: EditTopicPermissionTypes.REQUEST,
});
export const EditTopicPermissionSuccessAction = (data: any): IEditTopicPermissionSuccessAction => ({
  payload: data,
  type: EditTopicPermissionTypes.SUCCESS,
});
export const EditTopicPermissionErrorAction = (error: any): IEditTopicPermissionErrorAction => ({
  payload: error,
  type: EditTopicPermissionTypes.ERROR,
});

export const editTopicPermission = (
  config: any,
  topicId: any,
  permType: topicPermissionTypeEnum,
  cb: any
) => (dispatch: Dispatch<TPopupActions | TSetEditTopicPermissionActions | TEditModalActions>) => {
  dispatch(EditTopicPermissionRequestAction(config));
  TopicService.updateTopicPermission(config, topicId, permType).then(
    (data: { data: any }) => {
      dispatch(EditTopicPermissionSuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: `Topic ${
            permType === topicPermissionTypeEnum.PUBLISH ? "publish" : "subscribe"
          } permission updated successfully!`,
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data && data.data.data && data.data.data.content && data.data.data.content.topicId);
    },
    (error: any) => {
      dispatch(EditTopicPermissionErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
