import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  GetTopicPayloadTypes,
  IGetTopicPayloadErrorAction,
  IGetTopicPayloadRequestAction,
  IGetTopicPayloadSuccessAction,
  TGetTopicPayloadActions,
} from "./types";

export const GetTopicPayloadRequestAction = (): IGetTopicPayloadRequestAction => ({
  type: GetTopicPayloadTypes.REQUEST,
});
export const GetTopicPayloadSuccessAction = (data: any): IGetTopicPayloadSuccessAction => ({
  payload: data,
  type: GetTopicPayloadTypes.SUCCESS,
});
export const GetTopicPayloadErrorAction = (error: any): IGetTopicPayloadErrorAction => ({
  payload: error,
  type: GetTopicPayloadTypes.ERROR,
});

// ACTION
export const getTopicPayload = (config: any) => (
  dispatch: Dispatch<TPopupActions | TGetTopicPayloadActions | TEditModalActions>
) => {
  dispatch(GetTopicPayloadRequestAction());
  TopicService.getPayload(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(GetTopicPayloadSuccessAction(data.data.data));
    },
    (error: any) => {
      dispatch(GetTopicPayloadErrorAction(error));
    }
  );
};
