import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AircraftFilterSortApplyAction } from "../../../actions/aircraft/filter-sort/actions";
import { getAircraftList } from "../../../actions/aircraft/get-list/actions";
import { regenerateRackCertLoadable } from "../../../actions/aircraft/re-generate-cert-loadable/actions";
import { addAircraftsToGroups } from "../../../actions/aircraftgroup/add-aircrafts-to-groups/actions";
import { removeAircraftsFromGroups } from "../../../actions/aircraftgroup/remove-aircrafts-from-groups/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { popupCloseAction, popupOpenAction } from "../../../actions/popup/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate } from "../../../helper/util";
import { AircraftTypeEnum } from "../../../models/IAircraft";
import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import { ITableConfig, ITableRow, OrderType } from "../../../models/ITableConfig";
import { aircraftFilterSortEmptyFilter as emptyFilterObject } from "../../../reducers/aircraft/AircraftFilterSortReducer";
import { RootState } from "../../../store";
import {
  SActiveLink,
  SButtonIcon,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SSpacer,
} from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalCreateAircraft } from "../../modals/ModalCreateAircraft";
import { ModalSelectAircraftGroups } from "../../modals/ModalSelectAircraftGroups";
import { Table } from "../../Table";
import { UserRolePermissionsUtil } from "../../UserRolePermissionsUtil";

export const RackList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { aircraftList, currentUser, editModal } = useSelector((state: RootState) => ({
    aircraftList: state.aircraftList,
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const { config } = useSelector((state: RootState) => ({
    config: state.aircraftFilterSort,
  }));

  const setConfig = (newConfig: any) => {
    dispatch(AircraftFilterSortApplyAction(newConfig));
  };

  // useEffect(() => {
  //   dispatch(AircraftFilterSortResetAction());
  // }, []);

  useEffect(() => {
    const myConfig = {
      ...config,
      filter: {
        ...config.filter,
        aircraftGroupId: props.acgContent ? props.acgContent.aircraftGroupId : "",
        aircraftType: AircraftTypeEnum.RACK,
        tenantId: props.tenant ? props.tenant.tenantId : "",
      },
    };
    dispatch(getAircraftList(myConfig));
  }, [config]);

  const [headCells, setHeadCells] = useState([
    {
      id: "tailNumber",
      label: "Rack name",
      onClickCell: (row: ITableRow) => {
        if (!props.noLink) {
          navigateToPage(`/aircrafts/${row.aircraftId}`);
        }
      },
      value: (row: ITableRow) =>
        props.noLink ? row.tailNumber : <SActiveLink>{row.tailNumber}</SActiveLink>,
    },
    {
      id: "",
      label: "Group count",
      value: (row: ITableRow) => row.aircraftGroupCount,
      // width: "3fr",
    },
    {
      id: "modifiedBy",
      label: "Modified by",
      value: (row: ITableRow) => (row.modifiedBy ? row.modifiedBy.fullName : "Unknown"),
    },
    {
      id: "modifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
  ]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const handleAddToGroupsSelection = (acIds: string[], tailNums: string) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are adding racks to these groups.",
          commentRequired: true,
          groupEnv: GroundEnvironmentTypeEnum.INTEGRATION,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              addAircraftsToGroups(
                {
                  aircraftGroups: param.groupIds,
                  aircrafts: acIds,
                  changeComment: param.changeComment,
                },
                () => {
                  setConfig({ ...config, page: 0 });
                }
              )
            );
          },
          tailNumbers: tailNums,
          tenantId: props.tenant.tenantId,
          title: `Add racks to groups`,
        },
        type: modalTypeEnum.SELECT_AIRCRAFT_GROUPS,
      })
    );
  };

  const batchAddToGroups = (selRows: ITableRow[]) => {
    if (selRows.length > 0) {
      const aircraftIds = selRows.map((row: ITableRow) => row.aircraftId);
      const tailNumbers = selRows.map((row: ITableRow) => row.tailNumber).toString();
      handleAddToGroupsSelection(aircraftIds, tailNumbers);
    }
  };

  const batchRegenerateCertLoadable = (selRows: ITableRow[]) => {
    if (selRows.length > 0) {
      const aircraftIds = selRows.map((row) => row.aircraftId);
      const tailNumbers = selRows.map((row) => row.tailNumber).join(", ");

      // Dispatch an action to open a confirmation popup
      dispatch(
        popupOpenAction({
          content: `Confirm to regenerate cert loadables for selected aircraft: ${tailNumbers}`,
          onCancel: () => {
            dispatch(popupCloseAction());
          },
          onConfirm: () => {
            aircraftIds.forEach((aircraftId) => {
              dispatch(
                regenerateRackCertLoadable({
                  aircraftId,
                  changeComment: "Batch cert loadable regeneration initiated",
                })
              );
            });
            // Close the popup after action
            dispatch(popupCloseAction());

            dispatch(
              popupOpenAction({
                content: "Regeneration process initiated for all selected aircraft.",
                title: "Regeneration Initiated",
                type: "Info",
              })
            );
          },
          title: "Confirm Cert Loadable Regeneration",
          type: "Confirmation",
        })
      );
    } else {
      dispatch(
        popupOpenAction({
          content: "No aircraft selected for regeneration.",
          title: "No Selection",
          type: "Info",
        })
      );
    }
  };

  const handleRemoveFromGroupsSelection = (acIds: string[], tailNums: string) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are removing racks from these groups.",
          commentRequired: true,
          groupEnv: GroundEnvironmentTypeEnum.INTEGRATION,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              removeAircraftsFromGroups(
                {
                  aircraftGroups: param.groupIds,
                  aircrafts: acIds,
                  changeComment: param.changeComment,
                },
                () => {
                  setConfig({ ...config, page: 0 });
                }
              )
            );
          },
          tailNumbers: tailNums,
          tenantId: props.tenant.tenantId,
          title: `Remove racks from groups`,
        },
        type: modalTypeEnum.SELECT_AIRCRAFT_GROUPS,
      })
    );
  };

  const batchRemoveFromGroups = (selRows: ITableRow[]) => {
    if (selRows.length > 0) {
      const aircraftIds = selRows.map((row: ITableRow) => row.aircraftId);
      const tailNumbers = selRows.map((row: ITableRow) => row.tailNumber).toString();
      handleRemoveFromGroupsSelection(aircraftIds, tailNumbers);
    }
  };

  const tableProps: ITableConfig = {
    batchAction:
      props.noBatch ||
      !UserRolePermissionsUtil.hasGroundsideConfig_ManageIntGroups(currentUser.data)
        ? undefined
        : {
            actions: [
              { title: "Add to groups", onClick: batchAddToGroups },
              { title: "Remove from groups", onClick: batchRemoveFromGroups },
              { title: "Re-generate cert", onClick: batchRegenerateCertLoadable },
            ],
            label: (selRows: ITableRow[]) => {
              const selectedMsg = `${selRows.length} item${selRows.length > 1 ? "s" : ""} selected`;

              return selectedMsg;
            },
          },
    head: {
      cells: headCells,
    },
    list: {
      ...aircraftList,
    },
    name: "topics",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const handleOpenModal = () => {
    dispatch(
      editModalOpenAction({
        aircraftType: AircraftTypeEnum.RACK,
        tenant: props.tenant,
        type: modalTypeEnum.NEW_AIRCRAFT,
      })
    );
  };

  const [filterItems, setFilterItems] = useState([
    {
      name: "tailNumber",
      placeholder: "Rack name",
      taClass: "tailNumber",
      type: FilterType.TEXT,
    },
    // {
    //   name: "icaoCode",
    //   placeholder: "ICAO",
    //   taClass: "topicOwner",
    //   type: FilterType.TEXT,
    // },
    // {
    //   name: "airlineName",
    //   placeholder: "Airline",
    //   taClass: "topicOwner",
    //   type: FilterType.TEXT,
    // },
    // {
    //   name: "groupName",
    //   placeholder: "Group",
    //   taClass: "topicOwner",
    //   type: FilterType.TEXT,
    // },
    // {
    //   name: "provisionEnv",
    //   placeholder: "Environment",
    //   taClass: "topicOwner",
    //   type: FilterType.TEXT,
    // },
    {
      name: "modifiedBy",
      placeholder: "Modified by",
      taClass: "modifiedBy",
      type: FilterType.TEXT,
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ]);

  const filterConfig: IFilterConfig = {
    customElements: props.noCreate
      ? undefined
      : [
          {
            element: (
              <SButtonIcon
                size="default"
                icon={<SIconAdd32 />}
                onClick={handleOpenModal}
                className={getTAClass("rack", TA_TYPES.BUTTON, "createNew")}
                // disabled={currentUser.data.role !== ADMINISTRATOR}
                disabled={
                  !UserRolePermissionsUtil.hasGroundsideConfig_ProvisionRacks(currentUser.data)
                }
              />
            ),
            width: "0.25fr",
          },
        ],
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: filterItems,
    pageName: "topics",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  //   useEffect(() => {
  //     if (!currentUser.data || currentUser.data.role !== ADMINISTRATOR) {
  //       // for non-admin user - remove 'multicast' column/header/filter
  //       headCells.splice(4, 1);
  //       filterItems.splice(4, 1);
  //     }

  //     // apply header/filter update
  //     setHeadCells(headCells);
  //     setFilterItems(filterItems);
  //   }, [currentUser]);

  return (
    <>
      <SPageContainer>
        {/* <SPageHeading className="ta-topics-title">Aircrafts / Racks</SPageHeading> */}

        <Filter {...filterConfig} />

        <SSpacer height="20px" />

        {/* LOADING */}
        {aircraftList.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        <Table {...tableProps}></Table>
      </SPageContainer>

      {/* CREATE AIRCRAFT MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.NEW_AIRCRAFT && (
        <ModalCreateAircraft />
      )}

      {/* SELECT AIRCRAFT GROUPS MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.SELECT_AIRCRAFT_GROUPS && (
        <ModalSelectAircraftGroups />
      )}
    </>
  );
};
