import { queryFilterHelper } from "../helper/queryFilterHelper";
import { IBrokerTypeListingTableConfig } from "../models/IBrokerTypeListingTableConfig";
import { IPayload, ITopic } from "../models/ITopicConfig";
import { topicPermissionTypeEnum } from "../models/ITopicDetailsConfig";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: IBrokerTypeListingTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getTopicList = async (conf: IBrokerTypeListingTableConfig) => {
  const query = getQueryString(conf);
  const axios = await axiosInstance();

  return axios.get(`/topics${query}`);
};

const getTopicDetails = async (topicId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/topics/${topicId}`);
};

const deleteTopic = async (topicId: string, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/topics/${topicId}`, {
    params: { changeComment },
  });
};

const updateTopic = async (config: any, id: any) => {
  const postData = config;
  const axios = await axiosInstance();

  return axios.put(`/topics/${id}`, postData);
};

const updateTopicPermission = async (
  config: any,
  topicId: string,
  permType: topicPermissionTypeEnum
) => {
  const postData = {
    changeComment: config.changeComment,
    isPermissibleForPac: config.isAllowedForPac,
    permission: config.permission,
  };

  const axios = await axiosInstance();

  return axios.put(`/topics/${topicId}/permission/${permType}`, postData);
};

const getTopicPermissionApps = async (
  topicId: string,
  conf: IBrokerTypeListingTableConfig,
  permType: topicPermissionTypeEnum
) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/topics/${topicId}/permission/${permType}/applications${query}`);
};

const addTopicPermissionApp = async (
  topicId: string,
  config: any,
  permType: topicPermissionTypeEnum
) => {
  const postData = {
    application: config.appId,
    changeComment: config.changeComment,
  };

  const axios = await axiosInstance();

  return axios.post(`/topics/${topicId}/permission/${permType}/applications`, postData);
};

const deleteTopicPermissionApp = async (
  topicId: any,
  config: any,
  permType: topicPermissionTypeEnum
) => {
  const axios = await axiosInstance();

  return axios.delete(`/topics/${topicId}/permission/${permType}/applications/${config.appId}`, {
    params: { changeComment: config.changeComment },
  });
};

const getTopicClientList = async (conf: any) => {
  const query = getQueryString(conf);
  const axios = await axiosInstance();

  return axios.get(`/mqtt${query}`);
};

const createTopic = async (config: ITopic) => {
  const postData: ITopic = {
    accessAnonymous: config.accessAnonymous,
    appClientId: config.appClientId,
    changeComment: config.changeComment,
    dependencies: config.dependencies,
    description: config.description,
    featureName: config.featureName,
    isSecure: config.isSecure,
    localGroupBridge: config.localGroupBridge,
    maxPayloadSize: config.maxPayloadSize,
    multicast: config.multicast,
    obfuscationRequirement: config.obfuscationRequirement,
    offloadType: config.offloadType,
    priority: config.priority,
    qosLevel: config.qosLevel,
    retainRequired: config.retainRequired,
    serviceName: config.serviceName,
    throttleThresholdFactor: config.throttleThresholdFactor,
    topicCategory: config.topicCategory,
    // topicMqttClient: config.topicMqttClient,
    topicName: config.topicName,
    topicPayloadId: config.topicPayloadId,
    topicType: config.topicType,
    ttl: config.ttl,
    versionId: config.versionId,
  };
  const axios = await axiosInstance();

  return axios.post(`/topics/`, postData);
};

const uploadPayload = async (config: IPayload) => {
  const requestOptions = { headers: { "Content-Type": "multipart/form-data" } };

  const formData = new FormData();
  formData.append("payloadFormat", config.payloadFormat);
  formData.append("payload", config.payloadFile);
  if (config.currentPayloadId) {
    formData.append("currentPayloadId", config.currentPayloadId);
  }
  const axios = await axiosInstance();

  return axios.post(`/topics/payload`, formData, requestOptions);
};

const getPayload = async (payloadId: any) => {
  const axios = await axiosInstance();

  return axios.get(`/topics/payload/${payloadId}`);
};

const updatePayload = async (config: any) => {
  const axios = await axiosInstance();

  return axios.post(`/topics/changepayload`, config);
};

const getBridges = async (conf: any) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;
  const query = `?${pagination}${queryFilterHelper(conf)}`;
  const axios = await axiosInstance();

  return axios.get(`/topics/bridges/${query}`);
};

export const TopicService = {
  addTopicPermissionApp,
  createTopic,
  deleteTopic,
  deleteTopicPermissionApp,
  getBridges,
  getPayload,
  getTopicClientList,
  getTopicDetails,
  getTopicList,
  getTopicPermissionApps,
  updatePayload,
  updateTopic,
  updateTopicPermission,
  uploadPayload,
};
