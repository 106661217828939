import {
  AircraftGroupFilterSortType,
  TAircraftGroupFilterSortActions,
} from "../../actions/aircraftgroup/filter-sort/types";
import { IFilterSortData as IAircraftGroupFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

export const aircraftGroupFilterSortEmptyFilter: any = {
  aircraftGroupEnvironment: "",
  aircraftGroupName: "",
  aircraftGroupType: "",
  aircraftId: "",
  globalOrTenantId: "",
  // icao: "",
  modifiedBy: "",
  modifiedDate: { startDate: undefined, endDate: undefined },
  tenantId: "",
  tenantName: "",
};

export const aircraftGroupFilterSortInitialState: IAircraftGroupFilterSortState = {
  filter: aircraftGroupFilterSortEmptyFilter,
  limit: 10,
  orderBy: "modifiedAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const aircraftGroupFilterSort = (
  state: IAircraftGroupFilterSortState = aircraftGroupFilterSortInitialState,
  action: TAircraftGroupFilterSortActions
): IAircraftGroupFilterSortState => {
  switch (action.type) {
    case AircraftGroupFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...aircraftGroupFilterSortEmptyFilter },
        page: 0,
      };
    case AircraftGroupFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...aircraftGroupFilterSortInitialState,
      };
    case AircraftGroupFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
