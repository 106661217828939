import { OrderType } from "./ITableConfig";

export enum tabAircraftsEnum {
  AIRCRAFTS,
  RACKS,
  GROUPS,
  HISTORY,
}

/**
 * Aircraft types
 */
export enum AircraftTypeEnum {
  /**
   * Aircraft
   */
  AIRCRAFT = "aircraft",
  /**
   * Rack
   */
  RACK = "rack",
}

interface IAircraftListFilters {
  [key: string]: any;
  aircraftGroupId?: string;
  aircraftType?: string;
  created_at?: string;
  created_by?: string;
  icao?: string;
  modified_at?: string;
  modified_by?: string;
  tailNumber?: string;
  tenantId?: string;
  tenantName?: string;
}

export interface IAircraftListTableConfig {
  [key: string]: any;
  filter: IAircraftListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetAircraftListData {
  content: any[];
  count: number;
}
