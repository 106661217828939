import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";

import {
  IGetAppDestinationDetailsActionType,
  IGetAppDestinationDetailsApplyAction,
  IGetAppDestinationDetailsErrorAction,
  IGetAppDestinationDetailsRequestAction,
  IGetAppDestinationDetailsSuccessAction,
  TGetAppDestinationDetails,
} from "./types";

export const getAppDestinationDetailsRequestAction = (): IGetAppDestinationDetailsRequestAction => ({
  type: TGetAppDestinationDetails.REQUEST,
});
export const getAppDestinationDetailsSuccessAction = (
  data: any
): IGetAppDestinationDetailsSuccessAction => ({
  payload: data,
  type: TGetAppDestinationDetails.SUCCESS,
});
export const getAppDestinationDetailsErrorAction = (
  error: any
): IGetAppDestinationDetailsErrorAction => ({
  payload: error,
  type: TGetAppDestinationDetails.ERROR,
});
export const getAppDestinationDetailsApplyAction = (
  data: any
): IGetAppDestinationDetailsApplyAction => ({
  payload: data,
  type: TGetAppDestinationDetails.APPLY,
});

// GET APPLICATION
export const getAppDestinationDetails = (appId: string, destId: string) => (
  dispatch: Dispatch<IGetAppDestinationDetailsActionType>
) => {
  dispatch(getAppDestinationDetailsRequestAction());
  ApplicationService.getAppDestinationDetails(appId, destId).then(
    (data: { data: any }) => {
      dispatch(getAppDestinationDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(getAppDestinationDetailsErrorAction(error))
  );
};
