import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateAircraftTypes {
  ERROR = "CREATE_AIRCRAFT_ERROR",
  REQUEST = "CREATE_AIRCRAFT_REQUEST",
  SUCCESS = "CREATE_AIRCRAFT_SUCCESS",
}

export interface ICreateAircraftRequestAction extends IReduxBaseAction<CreateAircraftTypes> {
  payload: any;
  type: CreateAircraftTypes.REQUEST;
}

export interface ICreateAircraftSuccessAction extends IReduxBaseAction<CreateAircraftTypes> {
  payload: any;
  type: CreateAircraftTypes.SUCCESS;
}

export interface ICreateAircraftErrorAction extends IReduxBaseAction<CreateAircraftTypes> {
  payload: any;
  type: CreateAircraftTypes.ERROR;
}

export type TCreateAircraftActions =
  | ICreateAircraftErrorAction
  | ICreateAircraftRequestAction
  | ICreateAircraftSuccessAction;
