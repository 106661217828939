import { OrderType } from "./ITableConfig";

export enum ConfigVersionDetailsTabEnum {
  STANDARD,
  SUPPLEMENTAL,
}

export enum ConfigLoadableStatusEnum {
  PENDING = "PENDING",
  BUILDING = "BUILDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

interface IConfigVersionListFilters {
  [key: string]: any;
  created_at?: string;
  created_by?: string;
  isRelease?: boolean;
  version?: string;
}

export interface IConfigVersionListTableConfig {
  [key: string]: any;
  filter: IConfigVersionListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetConfigVersionListData {
  content: any[];
  count: number;
}
