import { NumberInput as NumberInputNext, Text } from "next-components";
import React from "react";

import { INumberInput } from "../../models/ITextInput";
import { StatusIcon } from "../StatusIcon";

export const NumberInput = (props: INumberInput) => (
  <>
    <Text variant="captionText" className={`ta-modal-input-caption-${props.name}`}>
      {props.label}{" "}
      <StatusIcon
        className={`ta-status-icon-${props.valid ? "success" : "fail"}`}
        check={props.valid}
      />
    </Text>
    <NumberInputNext
      disabled={props.disabled}
      className={`ta-modal-input-${props.name}`}
      placeholder={props.placeholder}
      value={props.value}
      min={props.min}
      max={props.max}
      onChange={props.onChange.bind(props)}
    />
  </>
);
