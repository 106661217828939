import {
  GetConfigGenerateType,
  TGetConfigGenerateActions,
} from "../../actions/configfile/generate/types";

interface IConfigFileGenerateState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigFileGenerateState = {
  content: [],
  count: 0,
  error: false,
  loading: false,
};

export const configFileGenerate = (
  state: IConfigFileGenerateState = initialState,
  action: TGetConfigGenerateActions
): IConfigFileGenerateState => {
  switch (action.type) {
    case GetConfigGenerateType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetConfigGenerateType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetConfigGenerateType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
