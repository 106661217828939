import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetConfigVersionTypes {
  ERROR = "GET_CONFIG_VERSION_ERROR",
  REQUEST = "GET_CONFIG_VERSION_REQUEST",
  SUCCESS = "GET_CONFIG_VERSION_SUCCESS",
}

export interface IGetConfigVersionRequestAction extends IReduxBaseAction<GetConfigVersionTypes> {
  payload: any;
  type: GetConfigVersionTypes.REQUEST;
}

export interface IGetConfigVersionSuccessAction extends IReduxBaseAction<GetConfigVersionTypes> {
  payload: any;
  type: GetConfigVersionTypes.SUCCESS;
}

export interface IGetConfigVersionErrorAction extends IReduxBaseAction<GetConfigVersionTypes> {
  payload: any;
  type: GetConfigVersionTypes.ERROR;
}

export type TGetConfigVersionActions =
  | IGetConfigVersionErrorAction
  | IGetConfigVersionRequestAction
  | IGetConfigVersionSuccessAction;
