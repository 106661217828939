import { AircraftGroupFilterSortType, TAircraftGroupFilterSortActions } from "./types";

export const AircraftGroupFilterSortClearAction = (): TAircraftGroupFilterSortActions => ({
  type: AircraftGroupFilterSortType.CLEAR,
});

export const AircraftGroupFilterSortResetAction = (): TAircraftGroupFilterSortActions => ({
  type: AircraftGroupFilterSortType.RESET,
});

export const AircraftGroupFilterSortApplyAction = (data: any): TAircraftGroupFilterSortActions => ({
  payload: data,
  type: AircraftGroupFilterSortType.APPLY,
});
