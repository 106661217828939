import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import { AddAppVisibilityUserTypes, TSetAddAppVisibilityUserActions } from "./types";

export const AddAppVisibilityUserRequestAction = (data: any): TSetAddAppVisibilityUserActions => ({
  payload: data,
  type: AddAppVisibilityUserTypes.REQUEST,
});
export const AddAppVisibilityUserSuccessAction = (data: any): TSetAddAppVisibilityUserActions => ({
  payload: data,
  type: AddAppVisibilityUserTypes.SUCCESS,
});
export const AddAppVisibilityUserErrorAction = (error: any): TSetAddAppVisibilityUserActions => ({
  payload: error,
  type: AddAppVisibilityUserTypes.ERROR,
});

export const addAppVisibilityUser = (appid: string, config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetAddAppVisibilityUserActions | TEditModalActions>
) => {
  dispatch(AddAppVisibilityUserRequestAction(config));
  ApplicationService.addAppVisibilityUser(appid, config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(AddAppVisibilityUserSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "App visibility user added successfully!",
          title: "Success",
          type: "Info",
        })
      );
      // dispatch(editModalCloseAction());
      cb(data.data.data.content.appClientId);
    },
    (error: any) => {
      dispatch(AddAppVisibilityUserErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
