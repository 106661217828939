import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum TGetTopicDetails {
  ERROR = "TOPIC_GETDETAILS_ERROR",
  REQUEST = "TOPIC_GETDETAILS_REQUEST",
  SUCCESS = "TOPIC_GETDETAILS_SUCCESS",
}

export interface IGetTopicDetailsRequestAction extends IReduxBaseAction<TGetTopicDetails> {
  type: TGetTopicDetails.REQUEST;
}

export interface IGetTopicDetailsSuccessAction extends IReduxBaseAction<TGetTopicDetails> {
  payload: any;
  type: TGetTopicDetails.SUCCESS;
}

export interface IGetTopicDetailsErrorAction extends IReduxBaseAction<TGetTopicDetails> {
  payload: any;
  type: TGetTopicDetails.ERROR;
}

export type IGetTopicDetailsActionType =
  | IGetTopicDetailsRequestAction
  | IGetTopicDetailsSuccessAction
  | IGetTopicDetailsErrorAction;
