import { Dropdown } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAllBrokerType } from "../actions/broker-type/get-all/actions";
import { IBrokerTypeDropdown } from "../models/IBrokerTypeDropdown";
import { RootState } from "../store";

export const BrokerTypeDropdown = (props: IBrokerTypeDropdown) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { allBrokerType } = useSelector((state: RootState) => ({
    allBrokerType: state.allBrokerType,
  }));

  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  const getLabel = (broker: any): string => {
    if (props.useLruDescriptionForLabel && broker.lruDescription) {
      return `${broker.lruDescription} - ${broker.brokerType}`;
    }

    return broker.brokerType;
  };

  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: { brokerLocation: props.location || "" },
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{getLabel(broker)}</span>,
        value:
          props.type === "filter"
            ? broker.brokerType
            : {
                brokerType: broker.brokerType,
                brokerTypeId: broker.brokerTypeId,
                brokerTypeName: broker.brokerTypeName,
                systemType: broker.systemType,
              },
      }))
    );
  }, [allBrokerType]);

  return (
    <Dropdown
      options={brokerTypes}
      label={props.label || getLabel(props.selected)}
      onChange={(e: Event) => {
        props.onChange(e);
      }}
      selected={props.selected}
    />
  );
};
