import { Dispatch } from "redux";

import { IGetTopicListData, ITopicListingTableConfig } from "../../../models/ITopicConfig";
import { ApplicationService } from "../../../services/ApplicationService";

import { AppTopicFullListType, TAppTopicFullListActions } from "./types";

export const appTopicFullListRequestAction = (): TAppTopicFullListActions => ({
  type: AppTopicFullListType.REQUEST,
});

export const appTopicFullListSuccessAction = (data: any): TAppTopicFullListActions => ({
  payload: data,
  type: AppTopicFullListType.SUCCESS,
});

export const appTopicFullListErrorAction = (error: string): TAppTopicFullListActions => ({
  payload: error,
  type: AppTopicFullListType.ERROR,
});

export const appTopicFullListClearAction = (): TAppTopicFullListActions => ({
  type: AppTopicFullListType.CLEAR,
});

export const getApplicationTopicFullList = (conf: ITopicListingTableConfig) => (
  dispatch: Dispatch<TAppTopicFullListActions>
) => {
  dispatch(appTopicFullListRequestAction());
  ApplicationService.getApplicationTopicList(conf).then(
    (response: { data: { data: IGetTopicListData } }) => {
      dispatch(appTopicFullListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(appTopicFullListErrorAction(error))
  );
};
