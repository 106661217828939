import { ButtonPrimary, ProgressIndicatorCircular, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getPayload } from "../../actions/payload/get/actions";
import { uploadPayload } from "../../actions/payload/upload/actions";
import { getTaClassedOptions } from "../../helper/dropdown";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { payloadFormatEnum } from "../../models/ITopicConfig";
import { RootState } from "../../store";
import { SChip, SFlexContainer } from "../../styles/styles";
import { FileInput } from "../FileInput";

import { DropdownInput } from "./Dropdownlnput";
import { PayloadSchemaEditor } from "./PayloadSchemaEditor";

export const ModalEditTopicPage3 = (props: any) => {
  const { payloadGet, payloadUpload, topicDetails } = useSelector((state: any) => ({
    payloadGet: state.payloadGet,
    payloadUpload: state.payloadUpload,
    topicDetails: state.topicDetails,
  }));

  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const payloadFormatOptions: any[] = [
    { label: "JSON", value: payloadFormatEnum.JSON },
    { label: "PROTOBUF", value: payloadFormatEnum.PROTOBUF },
  ];

  const handlePayloadFormatDropdown = (value: payloadFormatEnum) => {
    setUpdatedConfig({
      ...props.config,
      payloadData: { ...props.config.payloadData, payloadFormat: value },
    });
  };

  const handleUpload = (payloadFile: File) => {
    dispatch(
      uploadPayload(
        {
          ...props.config.payloadData,
          payloadFile,
          // tslint:disable-next-line:object-literal-sort-keys
          currentPayloadId: topicDetails.content.topicPayloadId,
        },
        (response: any) => {
          if (response && response.payloadSchema && response.payloadId) {
            const payloadData = {
              ...props.config.payloadData,
              payloadFileName: payloadFile.name,
              payloadSchema:
                props.config.payloadData.payloadFormat === payloadFormatEnum.JSON
                  ? JSON.stringify(response.payloadSchema, undefined, 4)
                  : response.payloadSchema,
            };

            setUpdatedConfig({
              ...props.config,
              payloadData: { ...payloadData },
              topicPayloadId: response.payloadId,
            });
          }
        }
      )
    );
  };

  const handleClearFileName = () => {
    setUpdatedConfig({
      ...props.config,
      payloadData: {
        ...props.config.payloadData,
        payloadFile: File,
        payloadFileName: "",
        payloadSchema: "",
      },
      topicPayloadId: null,
    });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [props.config]);

  useEffect(() => {
    if (props.config && props.config.topicPayloadId) {
      dispatch(
        getPayload(props.config.topicPayloadId, (payload: any) => {
          setUpdatedConfig({
            ...props.config,
            payloadData: {
              ...props.config.payloadData,
              payloadFile: File,
              payloadFileName: payload.payloadFile,
              payloadFormat: payload.payloadFormat,
              payloadSchema: payload.payloadSchema,
            },
          });
        })
      );
    }
  }, []);

  return (
    <>
      <Text className="ta-modal-text-topic">
        Topic: <span>{props.config && props.config.topicName}</span>
      </Text>
      {payloadGet.loading && <ProgressIndicatorCircular size="small" />}
      {payloadUpload.loading && <ProgressIndicatorCircular size="small" />}

      {!payloadGet.loading &&
        !payloadUpload.loading &&
        props.config.payloadData.payloadFileName === "" && (
          <>
            <DropdownInput
              label="Payload format:"
              name="payloadFormat"
              onChange={handlePayloadFormatDropdown}
              options={getTaClassedOptions(payloadFormatOptions)}
              selected={props.config.payloadData && props.config.payloadData.payloadFormat}
            />
          </>
        )}

      {!payloadGet.loading &&
        !payloadUpload.loading &&
        props.config.payloadData.payloadFileName === "" && (
          <FileInput
            onChange={(e: any) => {
              handleUpload(e);
            }}
            disabled={props.config.payloadData.payloadFormat === ""}
          />
        )}
      {!payloadGet.loading &&
        !payloadUpload.loading &&
        props.config.payloadData.payloadFileName !== "" && (
          <SChip
            className="ta-chip-payloadFile"
            tabIndex="0"
            label={props.config.payloadData.payloadFileName}
            disabled={props.isEditing}
            size="default"
            onClick={() => {
              if (!props.isEditing) {
                handleClearFileName();
              }
            }}
          />
        )}

      <Text variant="captionText" className="ta-modal-input-caption-schema">
        Schema:
      </Text>

      {!props.isEditing ? (
        <>
          <Textarea
            placeholder="Schema"
            readOnly={true}
            value={
              props.config.payloadData &&
              props.config.payloadData.payloadSchema &&
              (props.config.payloadData.payloadFormat === "json"
                ? JSON.parse(props.config.payloadData.payloadSchema)
                : props.config.payloadData.payloadSchema)
            }
          />
          {props.config.payloadData.payloadSchema && (
            <SFlexContainer justifyContent="flex-start">
              <ButtonPrimary
                className={getTAClass("editTopic", TA_TYPES.BUTTON, "editSchema")}
                onClick={() => {
                  props.onToggleEditing(true);
                }}
              >
                Edit schema
              </ButtonPrimary>
            </SFlexContainer>
          )}
        </>
      ) : (
        <PayloadSchemaEditor
          payloadId={topicDetails.content.topicPayloadId}
          payloadData={props.config.payloadData}
          callback={(response: any) => {
            setUpdatedConfig({
              ...props.config,
              payloadData: {
                ...props.config.payloadData,
                payloadSchema: response.payloadSchema,
              },
              topicPayloadId: response.payloadId,
            });
            props.onToggleEditing(false);
          }}
          cancel={() => {
            props.onToggleEditing(false);
          }}
        />
      )}
    </>
  );
};
