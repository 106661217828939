/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum TopicFilterSortType {
  CLEAR = "TOPIC_FILTER_SORT_CLEAR",
  RESET = "TOPIC_FILTER_SORT_RESET",
  APPLY = "TOPIC_FILTER_SORT_APPLY",
}

export interface ITopicFilterSortClearAction extends IReduxBaseAction<TopicFilterSortType> {
  type: TopicFilterSortType.CLEAR;
}

export interface ITopicFilterSortResetAction extends IReduxBaseAction<TopicFilterSortType> {
  type: TopicFilterSortType.RESET;
}

export interface ITopicFilterSortApplyAction extends IReduxBaseAction<TopicFilterSortType> {
  payload: IFilterSortData;
  type: TopicFilterSortType.APPLY;
}


export type TTopicFilterSortActions =
  | ITopicFilterSortClearAction
  | ITopicFilterSortResetAction
  | ITopicFilterSortApplyAction;
