/* istanbul ignore file */
import { IGetChangeHistoryListData } from "../../../models/IChangeHistoryConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ChangeHistoryListType {
  REQUEST = "GET_CHANGE_HISTORY_LIST_REQUEST",
  SUCCESS = "GET_CHANGE_HISTORY_LIST_SUCCESS",
  ERROR = "GET_CHANGE_HISTORY_LIST_ERROR",
}

export interface IChangeHistoryRequestAction extends IReduxBaseAction<ChangeHistoryListType> {
  type: ChangeHistoryListType.REQUEST;
}

export interface IChangeHistorySuccessAction extends IReduxBaseAction<ChangeHistoryListType> {
  payload: IGetChangeHistoryListData;
  type: ChangeHistoryListType.SUCCESS;
}

export interface IChangeHistoryErrorAction extends IReduxBaseAction<ChangeHistoryListType> {
  payload: string;
  type: ChangeHistoryListType.ERROR;
}

export type TChangeHistoryActions =
  | IChangeHistoryRequestAction
  | IChangeHistorySuccessAction
  | IChangeHistoryErrorAction;
