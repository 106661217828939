import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";

import {
  IGetTopicBridgesActionType,
  IGetTopicBridgesClearAction,
  IGetTopicBridgesErrorAction,
  IGetTopicBridgesRequestAction,
  IGetTopicBridgesSuccessAction,
  TGetTopicBridges,
} from "./types";

export const getTopicBridgesRequestAction = (): IGetTopicBridgesRequestAction => ({
  type: TGetTopicBridges.REQUEST,
});
export const getTopicBridgesSuccessAction = (data: any): IGetTopicBridgesSuccessAction => ({
  payload: data,
  type: TGetTopicBridges.SUCCESS,
});
export const getTopicBridgesErrorAction = (error: any): IGetTopicBridgesErrorAction => ({
  payload: error,
  type: TGetTopicBridges.ERROR,
});

export const clearTopicBridgesAction = (): IGetTopicBridgesClearAction => ({
  type: TGetTopicBridges.CLEAR,
});

export const getTopicBridges = (conf: any) => (dispatch: Dispatch<IGetTopicBridgesActionType>) => {
  dispatch(getTopicBridgesRequestAction());
  TopicService.getBridges(conf).then(
    (data: { data: any }) => {
      dispatch(getTopicBridgesSuccessAction(data.data.data));
    },
    (error: any) => dispatch(getTopicBridgesErrorAction(error))
  );
};
