import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { getTenantLabel } from "../../helper/util";
import { userTenantEnum } from "../../models/ITenant";
import { topicPermissionEnum, topicPermissionTypeEnum } from "../../models/ITopicDetailsConfig";
import { SFlexContainer, SFlexItem, SSpacer, SVerticalSpacer } from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";

import { DropdownInput } from "./Dropdownlnput";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalEditTopicPermission = () => {
  //   const dispatch = useDispatch();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = false; // comment required?
  const [dialogTitle, setDialogTitle] = useState("Topic permission");
  const [isCommentValid, setIsCommentValid] = useState(!changeCommentRequired);
  const [isDirty, setIsDirty] = useState(false);
  const [includePacAppsHidden, setIncludePacAppsHidden] = useState(true);
  const [orgIsAllowedForPac, setOrgIsAllowedForPac] = useState(false);
  const [orgPermission, setOrgPermission] = useState("");

  const [config2, setConfig2] = useState({
    changeComment: "",
    isAllowedForPac: false,
    permission: "",
  });

  const [permissionOptions, setPermissionOptions] = useState([
    { label: "Owner applications", value: topicPermissionEnum.OWNER_APPS },
    { label: "Tenant applications", value: topicPermissionEnum.TENANT_APPS },
    { label: "All applications", value: topicPermissionEnum.ALL_APPS },
  ]);

  useEffect(() => {
    if (editModal.permissionType === topicPermissionTypeEnum.PUBLISH) {
      setDialogTitle("Topic publish permission");
    } else {
      setDialogTitle("Topic subscribe permission");
    }

    if (editModal.topicData) {
      setOrgIsAllowedForPac(
        editModal.permissionType === topicPermissionTypeEnum.PUBLISH
          ? editModal.topicData.isPublishableForPac
          : editModal.topicData.isSubscribableForPac
      );
      setOrgPermission(
        editModal.permissionType === topicPermissionTypeEnum.PUBLISH
          ? editModal.topicData.publishPermission
          : editModal.topicData.subscribePermission
      );

      // setup 'tenant apps' dropdown label from owner tenant info
      const permOptions = permissionOptions;
      permOptions[1].label = `${getTenantLabel(editModal.topicData.appClient)} applications`;
      setPermissionOptions(permOptions);

      if (
        editModal.topicData.appClient &&
        editModal.topicData.appClient.tenant &&
        !!editModal.topicData.appClient.tenant.tenantId
      ) {
        // hide this checkbox if topic's tenant is already PAC
        setIncludePacAppsHidden(
          editModal.topicData.appClient.tenant.tenantId === userTenantEnum.PAC
        );
      }
    }
  }, [editModal]);

  useEffect(() => {
    setConfig2({
      ...config2,
      isAllowedForPac: orgIsAllowedForPac,
      permission: orgPermission,
    });
  }, [orgIsAllowedForPac, orgPermission]);

  useEffect(() => {
    if (config2.isAllowedForPac !== orgIsAllowedForPac || config2.permission !== orgPermission) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [config2]);

  const setChangeComment = (comment: any) => {
    if (changeCommentRequired) {
      setIsCommentValid(comment.length > 0);
    } else {
      setIsCommentValid(true);
    }
    setConfig2({ ...config2, changeComment: comment });
  };

  const handlePermissionChange = (value: any) => {
    setConfig2({ ...config2, permission: value });
  };

  const handleAllowPacAppsCheckboxClick = (e: any) => {
    setConfig2({ ...config2, isAllowedForPac: !config2.isAllowedForPac });
  };

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setChangeComment(e.target.value);
    }
  };

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      editModal.popupData.onConfirm({ ...config2 });
    }
  };

  const getCommentHint = () => {
    let hintStr =
      editModal.popupData.commentHint && editModal.popupData.commentHint.length > 0
        ? editModal.popupData.commentHint
        : "Please enter a comment about why you are making this change.";

    hintStr += changeCommentRequired ? " (required)" : " (optional)";

    return hintStr;
  };

  return (
    <ModalCreateBase title={dialogTitle}>
      <DropdownInput
        label={
          editModal.permissionType === topicPermissionTypeEnum.PUBLISH
            ? "Publishable by:"
            : "Subscribable by"
        }
        name="permission"
        onChange={handlePermissionChange}
        options={getTaClassedOptions(permissionOptions)}
        selected={config2.permission}
      />
      <Text variant="captionText" className="ta-modal-visible-caption-note">
        {`Note: topic is always ${
          editModal.permissionType === topicPermissionTypeEnum.PUBLISH
            ? `publishable`
            : `subscribable`
        } by its owner applications. Owner applications are the applications owned by topic owner admins.`}
      </Text>

      {/* <SSpacer height="8px" /> */}

      {!includePacAppsHidden && config2.permission !== topicPermissionEnum.ALL_APPS && (
        <SFlexItem>
          <div className="ta-modal-input-include-pac-apps">
            <Checkbox
              onClick={handleAllowPacAppsCheckboxClick}
              marked={
                config2.isAllowedForPac ? CheckboxOptions.selected : CheckboxOptions.unselected
              }
              // disabled={config2.visibility === appVisibilityEnum.ALL_USERS}
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-include-pac-apps">
            Allow PAC applications
          </Text>
        </SFlexItem>
      )}

      <SSpacer height="40px" />

      <Text variant="captionText" className="ta-modal-input-caption-comment">
        Comment:
      </Text>
      <Textarea
        className="ta-modal-input-comment"
        placeholder={getCommentHint()}
        value={config2.changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "comment", 2048);
        }}
        handleCloseButton={editModal.popupData.onCancel}
      />

      <SSpacer height="20px" />

      <SFlexContainer justifyContent="flex-end">
        <ButtonPrimary
          className="ta-modal-button-Save"
          onClick={handleConfirm}
          disabled={!isDirty || !isCommentValid}
        >
          {editModal.popupData.confirmText ? <>{editModal.popupData.confirmText}</> : <>Save</>}
        </ButtonPrimary>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
