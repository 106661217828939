import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React, { useState } from "react";

import { tabAppDetailsEnum } from "../../../models/IAppDetailsConfig";
import { brokerLocationEnum } from "../../../models/IBrokerTypeListingTableConfig";

export const ApplicationDetailsTabs = (props: any) => (
  <Grid columns="0.9fr">
    <GridItem>
      <TabContainer>
        <Tab
          className="ta-application-details-tab-topic-subscribe"
          onClick={() => {
            props.onChange(tabAppDetailsEnum.SUBSCRIBE);
          }}
          active={props.activeTab === tabAppDetailsEnum.SUBSCRIBE}
        >
          Topics to subscribe
        </Tab>
        <Tab
          className="ta-application-details-tab-topic-publish"
          onClick={() => {
            props.onChange(tabAppDetailsEnum.PUBLISH);
          }}
          active={props.activeTab === tabAppDetailsEnum.PUBLISH}
        >
          Topics to publish
        </Tab>
        {props.appContent && props.appContent.appClientLocation === brokerLocationEnum.GROUNDSIDE && (
          <Tab
            className="ta-application-details-tab-groundside-dest"
            onClick={() => {
              props.onChange(tabAppDetailsEnum.DESTINATION_ROUTING);
            }}
            active={props.activeTab === tabAppDetailsEnum.DESTINATION_ROUTING}
          >
            Groundside destinations
          </Tab>
        )}
        <Tab
          className="ta-application-details-tab-details"
          onClick={() => {
            props.onChange(tabAppDetailsEnum.DETAILS);
          }}
          active={props.activeTab === tabAppDetailsEnum.DETAILS}
        >
          Details
        </Tab>
        {props.showPermissionsTab && (
          <Tab
            className="ta-application-details-tab-permissions"
            onClick={() => {
              props.onChange(tabAppDetailsEnum.PERMISSIONS);
            }}
            active={props.activeTab === tabAppDetailsEnum.PERMISSIONS}
          >
            Permissions
          </Tab>
        )}
        <Tab
          className="ta-application-details-tab-change-history"
          onClick={() => {
            props.onChange(tabAppDetailsEnum.HISTORY);
          }}
          active={props.activeTab === tabAppDetailsEnum.HISTORY}
        >
          Change history
        </Tab>
      </TabContainer>
    </GridItem>
  </Grid>
);
