import {
  BrokerTypeListType,
  TBrokerTypeActions,
} from "../../actions/broker-type/get-filtered-list/types";
import { IGetBrokerTypeListContent } from "../../models/IBrokerTypeListingTableConfig";

interface IBrokerTypeListState {
  content: IGetBrokerTypeListContent[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IBrokerTypeListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const brokerTypeList = (
  state: IBrokerTypeListState = initialState,
  action: TBrokerTypeActions
): IBrokerTypeListState => {
  switch (action.type) {
    case BrokerTypeListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case BrokerTypeListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case BrokerTypeListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
