import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationList } from "../actions/application/get-list/actions";
import { userListStatusEnum } from "../models/IAppListingTableConfig";
import { ADMINISTRATOR } from "../models/UserTypes";
import { RootState } from "../store";

import { Typeahead } from "./Typeahead";

export const TypeaheadApplication = (props: any) => {
  const { applicationList, currentUser } = useSelector((state: RootState) => ({
    applicationList: state.applicationList,
    currentUser: state.currentUser,
  }));
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  return (
    <Typeahead
      pageSelector={props.pageSelector}
      autoFillOnExactMatchOnly={{
        key: "topicName",
        rule: props.autoFillOnExactMatchOnly,
      }}
      disabled={props.disabled}
      config={{
        default: {
          filter: {
            appClientId: "",
            appClientLocation: props.appLocation ? props.appLocation : "",
            appName: "",
            appNameAndVersion: "",
            appVersion: "",
            ownerUserId: currentUser.data.role === ADMINISTRATOR ? "" : currentUser.data.userId,
            status: userListStatusEnum.ACTIVE,
          },
          orderBy: "appName",
        },
        setFilter: (app: string) => ({
          appNameAndVersion: app,
          status: userListStatusEnum.ACTIVE,
        }),
      }}
      suggestion={{
        getList: (config: any) => dispatch(getApplicationList(config)),
        list: applicationList,
        select: (data: any) => data.appName,
      }}
      name="application"
      value={props.value}
      handleTypeahead={props.onChange}
      pattern="{appName} {appVersion}"
    />
  );
};
