import { Heading, IconClose24, ModalBase } from "next-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalCloseAction } from "../../actions/modal/edit/actions";
import { RootState } from "../../store";
import { SCloseButton, SModalContainer, SModalHeader } from "../../styles/styles";

import { ModalBaseBSD } from "./ModalBaseBSD";

export const ModalCreateBase = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const handleCloseModal = () => {
    if (props.handleCloseButton) {
      props.handleCloseButton();
    }
    dispatch(editModalCloseAction());
  };

  return (
    <>
      <ModalBaseBSD>
        <SModalContainer id={editModal.type} className="ta-modal-container">
          <SModalHeader>
            <Heading variant="heading2" className="ta-modal-header">
              {props.title}
            </Heading>
            <SCloseButton
              icon={<IconClose24 />}
              onClick={handleCloseModal}
              className="ta-modal-close-button"
            />
          </SModalHeader>
          <>{props.children}</>
        </SModalContainer>
      </ModalBaseBSD>
    </>
  );
};
