import { Chip, Input } from "next-components";
import styled from "styled-components";

export const STypeaheadContainer = styled.div`
  position: relative;
`;

export const STypeaheadListContainer = styled.div`
  background-color: white;
  max-height: calc(4.5 * 40px);
  overflow: scroll;
  position: absolute;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
`;

export const STypeaheadListItem = styled.div<{disabled?: boolean}>`
  box-shadow: inset 0 -1px 0px 0px #ededed, inset 0 0 0 2px transparent;
  
  padding: 11px 16px;

  cursor: ${props => props.disabled ? "disabled" : "pointer"};
  color: ${props => props.disabled ? "#e0e0e0" : "inherit"};

  &:hover  {
    background: #fafafa;
  }
`;

export const STypeaheadNestedHeader = styled.div`
  padding: 5px 10px;
  font-size: 13px;
  background: #f6f6f6;
  color: #717171;
`;

export const STypeaheadInput = styled(Input)`
  width: 100%;
`;

export const SInput = styled(Input)`
  &[disabled] svg {
    display: none;
  }
`;

export const STypeaheadChip = styled(Chip)`
  height: 100%;
  min-height: 40px;
  max-height: 100px;
  overflow: auto;
`;