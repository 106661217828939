import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum AddAircraftsToGroupsTypes {
  ERROR = "ADD_AIRCRAFTS_TO_GROUPS_ERROR",
  REQUEST = "ADD_AIRCRAFTS_TO_GROUPS_REQUEST",
  SUCCESS = "ADD_AIRCRAFTS_TO_GROUPS_SUCCESS",
}

export interface IAddAircraftsToGroupsRequestAction
  extends IReduxBaseAction<AddAircraftsToGroupsTypes> {
  payload: any;
  type: AddAircraftsToGroupsTypes.REQUEST;
}

export interface IAddAircraftsToGroupsSuccessAction
  extends IReduxBaseAction<AddAircraftsToGroupsTypes> {
  payload: any;
  type: AddAircraftsToGroupsTypes.SUCCESS;
}

export interface IAddAircraftsToGroupsErrorAction
  extends IReduxBaseAction<AddAircraftsToGroupsTypes> {
  payload: any;
  type: AddAircraftsToGroupsTypes.ERROR;
}

export type TAddAircraftsToGroupsActions =
  | IAddAircraftsToGroupsErrorAction
  | IAddAircraftsToGroupsRequestAction
  | IAddAircraftsToGroupsSuccessAction;
