import { ButtonPrimary, DateRangePicker, Dropdown, Grid, GridItem, Input } from "next-components";
import React, { useEffect, useState } from "react";

import { getTAClass, TA_TYPES } from "../helper/taHelper";
import { useDebounce } from "../hooks/useDebounce";
import { FilterType, ICustomElement, IFilterConfig, IFilterItem } from "../models/IFilterConfig";
import { DEFAULT_WIDTH } from "../models/ITableConfig";
import { FilterWrapper, SSpacer, SVerticalSpacer } from "../styles/styles";

export const Filter = (props: IFilterConfig) => {
  const createFilterObject = () =>
    props.items.reduce(
      (acc: any, cur: IFilterItem) => ({
        ...acc,
        [cur.name]:
          cur.type === FilterType.DATEPICKER ? { startDate: undefined, endDate: undefined } : "",
      }),
      {}
    );

  const [filter, setFilter] = useState<{ [key: string]: any }>(
    props.initFilter ? props.initFilter : createFilterObject()
  );
  const [emptyFilter, setEmptyFilter] = useState<object>(
    props.emptyFilter ? props.emptyFilter : createFilterObject()
  );
  const [columnsRatio, setColumnsRatio] = useState<string>();
  const [columnsRatio2, setColumnsRatio2] = useState<string>();

  const [filterDirty, setFilterDirty] = useState(false);

  useEffect(() => {
    if (!props.columns) {
      const ratio = props.items.map((headCell: IFilterItem) => headCell.width || DEFAULT_WIDTH);

      if (!props.items2) {
        // add clear button
        ratio.push(DEFAULT_WIDTH);
        // add custom elements
        if (props.customElements) {
          props.customElements.forEach((element: ICustomElement) =>
            ratio.push(element.width ? element.width : DEFAULT_WIDTH)
          );
        }
      }
      setColumnsRatio(ratio.join(" "));
    } else {
      setColumnsRatio(props.columns);
    }

    if (!props.columns2) {
      if (props.items2) {
        const ratio2 = props.items2.map((headCell: IFilterItem) => headCell.width || DEFAULT_WIDTH);

        // add clear button
        ratio2.push(DEFAULT_WIDTH);
        // add custom elements
        if (props.customElements) {
          props.customElements.forEach((element: ICustomElement) =>
            ratio2.push(element.width ? element.width : DEFAULT_WIDTH)
          );
        }
        setColumnsRatio2(ratio2.join(" "));
      }
    } else {
      setColumnsRatio2(props.columns2);
    }
  }, [props]);

  useEffect(() => {
    // let str1 = JSON.stringify(filter);
    // let str2 = JSON.stringify(emptyFilter);
    // (str1 === str2)
    JSON.stringify(filter) === JSON.stringify(emptyFilter)
      ? setFilterDirty(false)
      : setFilterDirty(true);
  }, [filter]);

  const handleFilterTextInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ): void => {
    if (event.target.value === " ") {
      return;
    } // Don't allow empty spaces
    const updatedFilter = { [field]: event.target.value };
    setFilter({ ...filter, ...updatedFilter });
  };

  const returnFilter = (f?: typeof emptyFilter) => {
    props.returnFilter(f ? f : filter);
  };

  const handleFilterDropdown = (field: string, value: string): void => {
    const f = { ...filter, [field]: value };
    setFilter(f);
    returnFilter(f);
  };

  const handleClearFilter = (): void => {
    setFilter(emptyFilter);
    returnFilter(emptyFilter);
  };

  const handleFilterKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      returnFilter();
    }
  };

  const handleDatePicker = (field: string, date: any) => {
    setFilter({ ...filter, [field]: { ...date } });
  };

  const debounceFilter = useDebounce(filter, 1000);
  useEffect(() => {
    if (debounceFilter && JSON.stringify(filter) && JSON.stringify(emptyFilter)) {
      returnFilter();
    }
  }, [debounceFilter]);

  const createFilterItems = (item: IFilterItem, index: number) => {
    switch (item.type) {
      case FilterType.DROPDOWN:
        return (
          <GridItem
            key={`${item.name}-${index}`}
            className={getTAClass(props.pageName, TA_TYPES.FILTER, item.taClass)}
          >
            <Dropdown
              options={item.data}
              onChange={(e: any) => {
                handleFilterDropdown(item.name, e);
              }}
              selected={filter[item.name]}
              label={item.placeholder}
            />
          </GridItem>
        );
      case FilterType.DATEPICKER:
        return (
          <GridItem key={`${item.name}-${index}`}>
            <DateRangePicker
              className={getTAClass(props.pageName, TA_TYPES.FILTER, item.taClass)}
              startDatePlaceholderText={`${item.placeholder || item.name} from`}
              endDatePlaceholderText={`${item.placeholder || item.name} to`}
              startDate={filter[item.name].startDate}
              endDate={filter[item.name].endDate}
              onDatesChange={(e: any) => {
                handleDatePicker(item.name, e);
              }}
              enableOutsideDays
              isOutsideRange={() => false}
            />
          </GridItem>
        );
      case FilterType.SPACE:
        return (
          <GridItem key={`${item.name}-${index}`}>
            <SVerticalSpacer />
          </GridItem>
        );
      default:
        return (
          <GridItem key={`${item.name}-${index}`}>
            <Input
              className={getTAClass(props.pageName, TA_TYPES.FILTER, item.taClass)}
              placeholder={item.placeholder || item.name}
              value={filter[item.name]}
              onChange={(e: any) => {
                handleFilterTextInput(e, item.name);
              }}
              onKeyPress={handleFilterKeyPress}
            />
          </GridItem>
        );
    }
  };

  return (
    <FilterWrapper>
      {filter ? (
        <Grid columns={columnsRatio}>
          {props.items.map(createFilterItems)}
          {!props.items2 && (
            <GridItem>
              <ButtonPrimary
                onClick={handleClearFilter}
                disabled={!filterDirty}
                className={getTAClass(props.pageName, TA_TYPES.BUTTON, "clearFilter")}
              >
                Clear filter
              </ButtonPrimary>
            </GridItem>
          )}
          {!props.items2 &&
            props.customElements &&
            props.customElements.map((customElement: ICustomElement) => customElement.element)}
        </Grid>
      ) : (
        ""
      )}

      {filter && props.items2 && <SSpacer height="8px" />}

      {filter && props.items2 && (
        <Grid columns={columnsRatio2}>
          {props.items2.map(createFilterItems)}
          <GridItem>
            <ButtonPrimary
              onClick={handleClearFilter}
              disabled={!filterDirty}
              className={getTAClass(props.pageName, TA_TYPES.BUTTON, "clearFilter")}
            >
              Clear filter
            </ButtonPrimary>
          </GridItem>
          {props.customElements &&
            props.customElements.map((customElement: ICustomElement) => customElement.element)}
        </Grid>
      )}
    </FilterWrapper>
  );
};
