import moment from "moment";

import { IApplicationListingTableConfig } from "../models/IAppListingTableConfig";
import { IBrokerTypeListingTableConfig } from "../models/IBrokerTypeListingTableConfig";

export const queryFilterHelper = (
  conf: IApplicationListingTableConfig | IBrokerTypeListingTableConfig
) => {
  let filter = "";
  Object.keys(conf.filter).forEach(key => {
    const isEmpty = /^ *$/.test(conf.filter[`${key}`]); // Check for 0+ spaces
    if (!isEmpty) {
      if (key === "modifiedDate" || key === "createdDate") {
        const keyType = key === "modifiedDate" ? "modified" : "created";
        let dateFilters = "";

        if (conf.filter[`${key}`].startDate) {
          dateFilters += `&filter[${keyType}From]=${moment(conf.filter[`${key}`].startDate)
            // .tz("America/Los_Angeles")
            .tz(moment.tz.guess())
            .hours(0)
            .minutes(0)
            .utc()
            .format("YYYY-MM-DD HH:mm")}`;
        }
        if (conf.filter[`${key}`].endDate) {
          dateFilters += `&filter[${keyType}To]=${moment(conf.filter[`${key}`].endDate)
            // .tz("America/Los_Angeles")
            .tz(moment.tz.guess())
            .hours(23)
            .minutes(59)
            .utc()
            .format("YYYY-MM-DD HH:mm")}`;
        }

        return (filter += dateFilters);
      }

      return (filter += `&filter[${key}]=${encodeURIComponent(conf.filter[`${key}`])}`);
    }

    return "";
  });

  return filter;
};
