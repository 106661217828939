import { Dispatch } from "redux";

import { AircraftService } from "../../../services/AircraftService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateAircraftTypes,
  ICreateAircraftErrorAction,
  ICreateAircraftRequestAction,
  ICreateAircraftSuccessAction,
  TCreateAircraftActions,
} from "./types";

export const CreateAircraftRequestAction = (data: any): ICreateAircraftRequestAction => ({
  payload: data,
  type: CreateAircraftTypes.REQUEST,
});
export const CreateAircraftSuccessAction = (data: any): ICreateAircraftSuccessAction => ({
  payload: data,
  type: CreateAircraftTypes.SUCCESS,
});
export const CreateAircraftErrorAction = (error: any): ICreateAircraftErrorAction => ({
  payload: error,
  type: CreateAircraftTypes.ERROR,
});

export const createAircraft = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreateAircraftActions | TEditModalActions>
) => {
  dispatch(CreateAircraftRequestAction(config));
  AircraftService.createAircraft(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateAircraftSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Aircraft created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.aircraftId);
    },
    (error: any) => {
      dispatch(CreateAircraftErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
