/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTopicListData } from "../../../models/ITopicConfig";

export enum FilterTopicListType {
  REQUEST = "FILTER_TOPIC_LIST_REQUEST",
  SUCCESS = "FILTER_TOPIC_LIST_SUCCESS",
  ERROR = "FILTER_TOPIC_LIST_ERROR",
}

export interface ITopicRequestAction extends IReduxBaseAction<FilterTopicListType> {
  type: FilterTopicListType.REQUEST;
}

export interface ITopicSuccessAction extends IReduxBaseAction<FilterTopicListType> {
  payload: IGetTopicListData;
  type: FilterTopicListType.SUCCESS;
}

export interface ITopicErrorAction extends IReduxBaseAction<FilterTopicListType> {
  payload: string;
  type: FilterTopicListType.ERROR;
}

export type TFilterTopicListActions = ITopicRequestAction | ITopicSuccessAction | ITopicErrorAction;
