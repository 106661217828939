export const READ = "READ";
export const ADMINISTRATOR = "ADMINISTRATOR";
export const APPLICATION_OWNER = "APPLICATION_OWNER";
export const APPROVER = "APPROVER";

// export const UMBT_IT_SUPPORT_TEAM = "UMBT_IT_SUPPORT_TEAM";
// export const UMBT_OPERATIONAL_SUPPORT_TEAM = "UMBT_OPERATIONAL_SUPPORT_TEAM";

export const getUserRole = (role: string) => {
  let userRole = "";
  switch (role) {
    case ADMINISTRATOR:
      userRole = "Administrator";
      break;
    case APPLICATION_OWNER:
      userRole = "Application owner";
      break;
    case APPROVER:
      userRole = "Approver";
      break;
    case READ:
      userRole = "Read only";
      break;
    // case UMBT_IT_SUPPORT_TEAM:
    //   userRole = "UMBT IT support team";
    //   break;
    // case UMBT_OPERATIONAL_SUPPORT_TEAM:
    //   userRole = "UMBT operational support team";
    //   break;
    default:
      userRole = role;
  }

  return userRole;
};
