import deepEqual from "deep-equal";
import {
  DataCell,
  HeaderCell,
  IconDelete16,
  IconEdit16,
  Link as NextLink,
  Row,
  SortableHeaderCell,
  TablePagination,
  TextCell,
} from "next-components";
import React, { useEffect, useState } from "react";

import { BatchActionActive } from "../components/next-component-fork";
import { getTAClass, TA_TYPES } from "../helper/taHelper";
import {
  DEFAULT_BATCH_WIDTH,
  DEFAULT_WIDTH,
  IBatchAction,
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../models/ITableConfig";
import { SDeleteIconContainer } from "../styles/styles";

import { Checkbox, CheckboxOptions } from "./Checkbox";

export const Table = (props: ITableConfig) => {
  const [columnsRatio, setColumnsRatio] = useState("");
  const [selectedRows, setSelectedRows] = useState(Array<ITableRow>());
  const [batchLabel, setBatchLabel] = useState("");

  useEffect(() => {
    const ratio = props.head.cells.map(
      (headCell: ITableHeaderCells) => headCell.width || DEFAULT_WIDTH
    );
    if (props.batchAction) {
      ratio.splice(0, 0, props.batchAction.width || DEFAULT_BATCH_WIDTH);
    }
    setColumnsRatio(ratio.join(" "));
  }, [props.head]);

  useEffect(() => {
    setSelectedRows([]);
  }, [props.list]);

  const handleChangePage = (page: number): void => {
    props.paginationConfig.onPageChange(page);
  };

  const handleSort = (column: string): void => {
    switch (props.sortConfig.orderBy) {
      case column:
        props.sortConfig.onSort(
          column,
          props.sortConfig.orderType === OrderType.ASC ? OrderType.DESC : OrderType.ASC
        );
        break;
      default:
        props.sortConfig.onSort(column, OrderType.ASC);
    }
  };

  const isAllVisibleRowsSelected = () => selectedRows.length === props.list.content.length;

  const isRowSelected = (row: ITableRow) =>
    selectedRows.filter((r: ITableRow) => deepEqual(r, row)).length;

  const onSelectRows = () => {
    const currentSelectedRows: ITableRow[] = [];
    if (isAllVisibleRowsSelected()) {
      setSelectedRows(Array<ITableRow>());
    } else {
      props.list.content.map((row: ITableRow) => currentSelectedRows.push(row));
    }
    setSelectedRows(currentSelectedRows);
  };

  const onSelectRow = (row: ITableRow) => {
    let currentSelectedRows = [...selectedRows];
    if (isRowSelected(row)) {
      currentSelectedRows = selectedRows.filter((r: ITableRow) => !deepEqual(r, row));
    } else {
      currentSelectedRows.push(row);
    }
    setSelectedRows(currentSelectedRows);
  };

  const onCancel = () => {
    setSelectedRows([]);
  };

  const getLabel = (key: string, value: string) => {
    const rules = props.list.cellRules
      ? props.list.cellRules.filter(cellRule => cellRule.columnName === key)
      : [];

    if (!rules.length) {
      return value;
    }

    if (rules.length > 1) {
      throw new Error("There could be only one rule for a cell.");
    }

    const record = rules[0].mask.source.filter((rule: any) => rule[rules[0].mask.from] === value);

    if (!record.length) {
      return value;
    }

    return record[0][rules[0].mask.to];
  };

  const getCellTitle = (header: ITableHeaderCells, row: ITableRow) => {
    let cellTitle: any = null;
    if (header.title) {
      cellTitle = header.title(row);
    }

    return cellTitle;
  };

  const getCellValue = (header: ITableHeaderCells, row: ITableRow) => {
    let cellValue = row[header.id];
    if (header.value) {
      cellValue = header.value(row);
    }

    return cellValue;
  };

  useEffect(() => {
    if (props.batchAction && props.batchAction.label) {
      setBatchLabel(props.batchAction.label(selectedRows));
    }
  }, [selectedRows]);

  const batchProps = {
    actions: props.batchAction
      ? props.batchAction.actions.map((action: IBatchAction) => ({
          ...action,
          disabled: action.disabled ? action.disabled([...selectedRows]) : false,
          onClick: () => {
            if (action.onClick) {
              action.onClick([...selectedRows]);
            }
          },
        }))
      : [],
  };

  return (
    <>
      {props.batchAction && selectedRows.length ? (
        <div className={getTAClass(props.name, TA_TYPES.TABLE, "header", "batchActions")}>
          <BatchActionActive {...batchProps} label={batchLabel} onCancel={onCancel} />
        </div>
      ) : (
        <></>
      )}
      {/* HEADER */}
      {!props.noHeader && (
        <Row columns={columnsRatio} paddingLeft="10px" className={`ta-${props.name}-row-header`}>
          {props.batchAction && (
            <DataCell justifyItems="center">
              <Checkbox
                onClick={onSelectRows}
                marked={
                  isAllVisibleRowsSelected() && selectedRows.length
                    ? CheckboxOptions.selected
                    : CheckboxOptions.unselected
                }
              />
            </DataCell>
          )}
          {props.head.cells.map((headCell: ITableHeaderCells) =>
            headCell.id !== "delete" && headCell.id !== "edit" ? (
              !!headCell.id ? (
                <SortableHeaderCell
                  key={headCell.label}
                  value={headCell.label}
                  sortDirection={
                    props.sortConfig.orderBy === headCell.id
                      ? props.sortConfig.orderType === OrderType.ASC
                        ? "ascending"
                        : "descending"
                      : "none"
                  }
                  onClick={() => {
                    handleSort(headCell.id);
                  }}
                  className={`ta-${props.name}-header-${headCell.id}`}
                />
              ) : (
                <HeaderCell
                  key={headCell.label}
                  value={headCell.label}
                  className={`ta-${props.name}-header-${headCell.id}`}
                />
              )
            ) : (
              ""
            )
          )}
        </Row>
      )}

      {/* ERROR */}
      {props.list.error && (
        <Row paddingLeft="10px">
          <TextCell
            value="Error retrieving data."
            className={getTAClass(props.name, TA_TYPES.TABLE, "error")}
          />
        </Row>
      )}

      {props.list.loading && (
        <Row paddingLeft="10px">
          <TextCell
            value="Loading..."
            className={getTAClass(props.name, TA_TYPES.TABLE, "loading")}
          />
        </Row>
      )}

      {!props.list.loading && !props.list.error && (
        <>
          {/* BODY */}
          {props.list.content &&
            props.list.content.map((row: ITableRow, index: number) => (
              <Row
                columns={columnsRatio}
                key={`${props.name}-${index}`}
                striped={props.rowStriped || "odd"}
                paddingLeft="10px"
                className={`ta-${props.name}-row`}
                onClick={() => {
                  if (props.list.onClickRow) {
                    props.list.onClickRow(row);
                  }
                }}
              >
                {props.batchAction && (
                  <DataCell justifyItems="center">
                    <Checkbox
                      onClick={() => {
                        onSelectRow(row);
                      }}
                      marked={
                        isRowSelected(row) ? CheckboxOptions.selected : CheckboxOptions.unselected
                      }
                    />
                  </DataCell>
                )}
                {props.head.cells.map((headCell: ITableHeaderCells, colIndex: number) =>
                  headCell.id === "delete" ? (
                    <SDeleteIconContainer
                      key={colIndex}
                      value={
                        <NextLink
                          disabled={
                            props.deleteCell &&
                            props.deleteCell.disabled &&
                            // tslint:disable-next-line: no-void-expression
                            props.deleteCell.disabled(row)
                          }
                          as={props.deleteCell && props.deleteCell.as}
                          onClick={(e: any) => {
                            if (e) {
                              e.preventDefault();
                            }
                            if (props.deleteCell && props.deleteCell.onClick) {
                              props.deleteCell.onClick(row);
                            }
                          }}
                        >
                          <IconDelete16 />
                        </NextLink>
                      }
                      className={`ta-${props.name}-${props.head.cells[Number(`${colIndex}`)].id}`}
                    ></SDeleteIconContainer>
                  ) : headCell.id === "edit" ? (
                    <SDeleteIconContainer
                      key={colIndex}
                      value={
                        <NextLink
                          disabled={
                            props.editCell &&
                            props.editCell.disabled &&
                            // tslint:disable-next-line: no-void-expression
                            props.editCell.disabled(row)
                          }
                          as={props.editCell && props.editCell.as}
                          onClick={(e: any) => {
                            if (e) {
                              e.preventDefault();
                            }
                            if (props.editCell && props.editCell.onClick) {
                              props.editCell.onClick(row);
                            }
                          }}
                        >
                          <IconEdit16 />
                        </NextLink>
                      }
                      className={`ta-${props.name}-${props.head.cells[Number(`${colIndex}`)].id}`}
                    ></SDeleteIconContainer>
                  ) : (
                    <TextCell
                      key={colIndex}
                      title={getCellTitle(headCell, row)}
                      value={
                        props.list.cellRules
                          ? getLabel(headCell.id, row[headCell.id])
                          : getCellValue(headCell, row)
                      }
                      onClick={() => {
                        if (headCell.onClickCell) {
                          headCell.onClickCell(row);
                        }
                      }}
                      className={`ta-${props.name}-column-${props.head.cells[Number(`${colIndex}`)].id}`}
                    />
                  )
                )}
              </Row>
            ))}

          {/* PAGINATION */}
          {!props.noPagination && (
            <div className={`ta-${props.name}-pagination`}>
              <TablePagination
                itemCount={props.list.count || 0}
                onChangePage={handleChangePage}
                paging={{
                  count: props.paginationConfig.limit,
                  page: props.paginationConfig.page,
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
