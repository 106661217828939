import { Dispatch } from "redux";

import { IGetApplicationListData } from "../../../models/IAppListingTableConfig";
import { ApplicationService } from "../../../services/ApplicationService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";
import { getApplicationDetails } from "../get-details/actions";

import {
  IDeleteApplicationActionTypes,
  IDeleteApplicationErrorAction,
  IDeleteApplicationRequestAction,
  IDeleteApplicationSuccessAction,
  TDeleteApplication,
} from "./types";

// DELETE FUNCTIONALITY
export const deleteApplicationRequestAction = (): IDeleteApplicationRequestAction => ({
  type: TDeleteApplication.REQUEST,
});
export const deleteApplicationSuccessAction = (data: any): IDeleteApplicationSuccessAction => ({
  payload: data,
  type: TDeleteApplication.SUCCESS,
});
export const deleteApplicationErrorAction = (error: any): IDeleteApplicationErrorAction => ({
  payload: error,
  type: TDeleteApplication.ERROR,
});

export const deleteApplication = (appId: any, changeComment: string) => (
  dispatch: Dispatch<IDeleteApplicationActionTypes | TPopupActions>
) => {
  dispatch(deleteApplicationRequestAction());
  ApplicationService.deleteApplication(appId, changeComment).then(
    (data: { data: IGetApplicationListData }) => {
      dispatch(deleteApplicationSuccessAction(data.data));
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(getApplicationDetails(appId));
      dispatch(
        popupOpenAction({
          content: "Application client deleted successfully!",
          title: "Success",
          type: "Info",
        })
      );
    },
    (error: any) => {
      dispatch(deleteApplicationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
