import { ButtonPrimary, Grid, GridItem, Tab, TabContainer } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalOpenAction } from "../../actions/modal/edit/actions";
import { configTabEnum } from "../../models/IConfigurationFilesConfig";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ModalDisplayChangeLog } from "../modals/ModalDisplayChangeLog";
import { ModalEditGenerateConfigComment } from "../modals/ModalEditGenerateConfigComment";

import { ConfigurationAppList } from "./ConfigurationFiles/ConfigurationAppList";
import { ConfigurationBrokerList } from "./ConfigurationFiles/ConfigurationBrokerList";
import { ConfigurationReleaseHistories } from "./ConfigurationFiles/ConfigurationReleaseHistories";
import { ConfigurationVersionList } from "./ConfigurationFiles/ConfigurationVersionList";

export const ConfigurationFiles = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { currentUser, editModal } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState(configTabEnum.VERSION);
  const [showGenerateButton, setShowGenerateButton] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);

      if ([ADMINISTRATOR, APPLICATION_OWNER, APPROVER].includes(currentUser.data.role)) {
        setShowGenerateButton(true);
      }
    }
  }, [currentUser]);

  const tabList = [
    { class: "ta-config-tab-loadables", id: configTabEnum.VERSION, title: "Loadables" },
    { class: "ta-config-tab-apps", id: configTabEnum.APP, title: "Application clients" },
    { class: "ta-config-tab-brokers", id: configTabEnum.BROKER, title: "Brokers" },
    { class: "ta-config-tab-history", id: configTabEnum.HISTORY, title: "Release history" },
  ];

  const handleTabChange = (tabId: configTabEnum) => {
    setActiveTab(tabId);
  };

  const handleViewChangeLog = (isRelease: boolean) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          isRelease,
          // lastVersion: `unknown`,
          // title: `View releasable changes`,
        },
        type: modalTypeEnum.DISPLAY_CHANGE_LOG,
      })
    );
  };

  const openNewGenerateConfigModal = (isRelease: boolean) => {
    dispatch(
      editModalOpenAction({
        isRelease,
        type: "DISPLAY_EDIT_GENERATE_COMMENT",
      })
    );
  };

  return (
    <SPageContainer>
      <SPageHeading>Configuration files</SPageHeading>
      {/* TABS */}
      <Grid columns="3fr 2fr">
        <GridItem>
          <TabContainer>
            {tabList.map((tab: any, index: any) => (
              <Tab
                key={index}
                className={tab.class}
                active={activeTab === tab.id}
                onClick={() => {
                  handleTabChange(tab.id);
                }}
              >
                {tab.title}
              </Tab>
            ))}
          </TabContainer>
        </GridItem>

        <GridItem style={{ justifySelf: "end" }}>
          <SFlexContainer justifyContent="flex-end">
            <SFlexItem>
              {showGenerateButton && activeTab === configTabEnum.HISTORY && (
                <ButtonPrimary
                  className="ta-config-view-changelog-btn"
                  onClick={() => {
                    handleViewChangeLog(false);
                  }}
                >
                  View test changes
                </ButtonPrimary>
              )}
              <SVerticalSpacer />
              {showGenerateButton && (
                <ButtonPrimary
                  className="ta-config-generate-btn"
                  onClick={() => {
                    openNewGenerateConfigModal(false);
                  }}
                >
                  Generate test loadable
                </ButtonPrimary>
              )}
            </SFlexItem>
          </SFlexContainer>

          {isAdmin && <SSpacer height="8px" />}

          {isAdmin && (
            <SFlexContainer justifyContent="flex-end">
              <SFlexItem>
                {showGenerateButton && activeTab === configTabEnum.HISTORY && (
                  <ButtonPrimary
                    style={{ color: "#00FF77" }}
                    className="ta-config-view-changelog-btn-release"
                    onClick={() => {
                      handleViewChangeLog(true);
                    }}
                  >
                    View release changes
                  </ButtonPrimary>
                )}
                <SVerticalSpacer />
                {showGenerateButton && (
                  <ButtonPrimary
                    style={{ color: "#00FF77" }}
                    className="ta-config-generate-btn-release"
                    onClick={() => {
                      openNewGenerateConfigModal(true);
                    }}
                  >
                    Generate release loadable
                  </ButtonPrimary>
                )}
              </SFlexItem>
            </SFlexContainer>
          )}
        </GridItem>
      </Grid>

      {activeTab === configTabEnum.VERSION && <ConfigurationVersionList />}
      {activeTab === configTabEnum.APP && <ConfigurationAppList />}
      {activeTab === configTabEnum.BROKER && <ConfigurationBrokerList />}
      {activeTab === configTabEnum.HISTORY && (
        <ConfigurationReleaseHistories userData={currentUser.data} />
      )}
      {editModal && editModal.open && editModal.type === "DISPLAY_EDIT_GENERATE_COMMENT" ? (
        <ModalEditGenerateConfigComment />
      ) : (
        ""
      )}
      {editModal && editModal.open && editModal.type === modalTypeEnum.DISPLAY_CHANGE_LOG && (
        <ModalDisplayChangeLog />
      )}

      {/* TAB CONTENT */}
    </SPageContainer>
  );
};
