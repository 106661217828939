/* istanbul ignore file */
import { IGetBrokerTypeListData } from "../../../models/IBrokerTypeListingTableConfig";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AllBrokerTypeListType {
  REQUEST = "GET_ALLBROKERTYPE_LIST_REQUEST",
  SUCCESS = "GET_ALLBROKERTYPE_LIST_SUCCESS",
  ERROR = "GET_ALLBROKERTYPE_LIST_ERROR",
}

export interface IBrokerTypeRequestAction extends IReduxBaseAction<AllBrokerTypeListType> {
  type: AllBrokerTypeListType.REQUEST;
}

export interface IBrokerTypeSuccessAction extends IReduxBaseAction<AllBrokerTypeListType> {
  payload: IGetBrokerTypeListData;
  type: AllBrokerTypeListType.SUCCESS;
}

export interface IBrokerTypeErrorAction extends IReduxBaseAction<AllBrokerTypeListType> {
  payload: string;
  type: AllBrokerTypeListType.ERROR;
}

export type TAllBrokerTypeActions =
  | IBrokerTypeRequestAction
  | IBrokerTypeSuccessAction
  | IBrokerTypeErrorAction;
