/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AircraftGroupFilterSortType {
  CLEAR = "AIRCRAFT_GROUP_FILTER_SORT_CLEAR",
  RESET = "AIRCRAFT_GROUP_FILTER_SORT_RESET",
  APPLY = "AIRCRAFT_GROUP_FILTER_SORT_APPLY",
}

export interface IAircraftGroupFilterSortClearAction
  extends IReduxBaseAction<AircraftGroupFilterSortType> {
  type: AircraftGroupFilterSortType.CLEAR;
}

export interface IAircraftGroupFilterSortResetAction
  extends IReduxBaseAction<AircraftGroupFilterSortType> {
  type: AircraftGroupFilterSortType.RESET;
}

export interface IAircraftGroupFilterSortApplyAction
  extends IReduxBaseAction<AircraftGroupFilterSortType> {
  payload: IFilterSortData;
  type: AircraftGroupFilterSortType.APPLY;
}

export type TAircraftGroupFilterSortActions =
  | IAircraftGroupFilterSortClearAction
  | IAircraftGroupFilterSortResetAction
  | IAircraftGroupFilterSortApplyAction;
