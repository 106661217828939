import { IReduxBaseAction } from "../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum PopupActions {
  OPEN = "POPUP_OPEN",
  CLOSE = "POPUP_CLOSE",
}

export interface IPopupOpenAction extends IReduxBaseAction<PopupActions> {
  payload: any;
  type: PopupActions.OPEN;
}

export interface IPopupCloseAction extends IReduxBaseAction<PopupActions> {
  type: PopupActions.CLOSE;
}

export type TPopupActions = IPopupCloseAction | IPopupOpenAction;
