import {
  IGetAircraftDetailsActionType,
  TGetAircraftDetails,
} from "../../actions/aircraft/get-details/types";

interface IAircraftDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: IAircraftDetailsState = {
  content: [],
  error: false,
  loading: true,
};

export const aircraftDetails = (
  state: IAircraftDetailsState = initialState,
  action: IGetAircraftDetailsActionType
): IAircraftDetailsState => {
  switch (action.type) {
    case TGetAircraftDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetAircraftDetails.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetAircraftDetails.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
