import { queryFilterHelper } from "../helper/queryFilterHelper";

import { axiosInstance } from "./AxiosInstance";

export enum DownloadTypeEnum {
  CONFIG,
  CHANGELOG,
  LOADABLE,
}

const getQueryString = (conf: any) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getConfigBroker = async (config: any) => {
  const query = getQueryString(config);
  const axios = await axiosInstance();

  return axios.get(`/configFile/broker${query}`);
};

const getConfigApplication = async (config: any) => {
  const query = getQueryString(config);
  const axios = await axiosInstance();

  return axios.get(`/configFile/application${query}`);
};

const generateConfig = async (config: any) => {
  const postData = {
    comment: config.comment,
    isRelease: config.isRelease,
  };
  const axios = await axiosInstance();

  return axios.post(`/configFile/generate`, postData);
};

const s3ProxyUrl = async (s3RawUrl: string) => {
  const escapedUrl = s3RawUrl.replace("/", "%2F");
  const axios = await axiosInstance();

  await axios({
    method: "GET",
    responseType: "blob",
    timeout: 30000,
    url: `/configFile/download/${escapedUrl}`,
  })
    .then((response: any) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((err: any) => {
      console.info("ERROR:", err);
    });
};

const s3ChangeLogUrl = async (s3RawUrl: string) => {
  const escapedUrl = s3RawUrl.replace("/", "%2F");
  const axios = await axiosInstance();

  await axios({
    method: "GET",
    responseType: "blob",
    timeout: 30000,
    url: `/configVersion/${escapedUrl}/changeLogFile`,
  })
    .then((response: any) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((err: any) => {
      console.info("ERROR:", err);
    });
};

const getConfigVersion = async (configVersionId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/configVersion/${configVersionId}`);
};

const getConfigVersionList = async (config: any) => {
  const query = getQueryString(config);
  const axios = await axiosInstance();

  return axios.get(`/configVersion${query}`);
};

const getConfigLoadableList = async (config: any) => {
  const query = getQueryString(config);
  const axios = await axiosInstance();

  return axios.get(`/loadable${query}`);
};

const getDownloadAPIUrl = (param: string, type: DownloadTypeEnum) => {
  switch (type) {
    case DownloadTypeEnum.CHANGELOG:
      return `/configVersion/${param}/changeLogFile`;

    case DownloadTypeEnum.CONFIG:
      return `/configFile/download/${param}`;

    case DownloadTypeEnum.LOADABLE:
      return `/loadable/download/${param}`;

    default:
      return "";
  }
};

const downloadS3File = async (fileId: string, type: DownloadTypeEnum) => {
  const escapedUrl = fileId.replace("/", "%2F");
  const axios = await axiosInstance();

  await axios({
    method: "GET",
    responseType: "blob",
    timeout: 30000,
    url: getDownloadAPIUrl(escapedUrl, type),
  })
    .then((response: any) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=(.+)/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((err: any) => {
      console.info("ERROR:", err);
    });
};

export const ConfigService = {
  downloadS3File,
  generateConfig,
  getConfigApplication,
  getConfigBroker,
  getConfigLoadableList,
  getConfigVersion,
  getConfigVersionList,
  s3ChangeLogUrl,
  s3ProxyUrl,
};
