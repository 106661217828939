import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  AddDestinationRouteTypes,
  IAddDestinationRouteErrorAction,
  IAddDestinationRouteRequestAction,
  IAddDestinationRouteSuccessAction,
  TAddDestinationRouteActions,
} from "./types";

export const AddDestinationRouteRequestAction = (data: any): IAddDestinationRouteRequestAction => ({
  payload: data,
  type: AddDestinationRouteTypes.REQUEST,
});
export const AddDestinationRouteSuccessAction = (data: any): IAddDestinationRouteSuccessAction => ({
  payload: data,
  type: AddDestinationRouteTypes.SUCCESS,
});
export const AddDestinationRouteErrorAction = (error: any): IAddDestinationRouteErrorAction => ({
  payload: error,
  type: AddDestinationRouteTypes.ERROR,
});

export const addDestinationRoute = (appId: string, destId: string, config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TAddDestinationRouteActions | TEditModalActions>
) => {
  dispatch(AddDestinationRouteRequestAction(config));
  ApplicationService.addAppDestinationRoute(appId, destId, config).then(
    (data: { config: any; data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(AddDestinationRouteSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Application destination route group added successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb();
    },
    (error: any) => {
      dispatch(AddDestinationRouteErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
