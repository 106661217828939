import { TUserFilterSortActions, UserFilterSortType } from "../../actions/user/filter-sort/types";
import { OrderType } from "../../models/ITableConfig";
import { IUserListingFilters, IUserListingTableConfig } from "../../models/IUserListingTableConfig";

export const userFilterSortEmptyFilter: IUserListingFilters = {
  email: "",
  firstName: "",
  lastName: "",
  role: "",
};

export const userFilterSortInitialState: IUserListingTableConfig = {
  filter: userFilterSortEmptyFilter,
  limit: 10,
  orderBy: "firstName",
  orderType: OrderType.ASC,
  page: 0,
  roles: [],
};

export const userFilterSort = (
  state: IUserListingTableConfig = userFilterSortInitialState,
  action: TUserFilterSortActions
): IUserListingTableConfig => {
  switch (action.type) {
    case UserFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...userFilterSortEmptyFilter },
        page: 0,
      };
    case UserFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...userFilterSortInitialState,
      };
    case UserFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
