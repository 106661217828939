import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateAircraftGroupTypes {
  ERROR = "CREATE_AIRCRAFT_GROUP_ERROR",
  REQUEST = "CREATE_AIRCRAFT_GROUP_REQUEST",
  SUCCESS = "CREATE_AIRCRAFT_GROUP_SUCCESS",
}

export interface ICreateAircraftGroupRequestAction
  extends IReduxBaseAction<CreateAircraftGroupTypes> {
  payload: any;
  type: CreateAircraftGroupTypes.REQUEST;
}

export interface ICreateAircraftGroupSuccessAction
  extends IReduxBaseAction<CreateAircraftGroupTypes> {
  payload: any;
  type: CreateAircraftGroupTypes.SUCCESS;
}

export interface ICreateAircraftGroupErrorAction
  extends IReduxBaseAction<CreateAircraftGroupTypes> {
  payload: any;
  type: CreateAircraftGroupTypes.ERROR;
}

export type TCreateAircraftGroupActions =
  | ICreateAircraftGroupErrorAction
  | ICreateAircraftGroupRequestAction
  | ICreateAircraftGroupSuccessAction;
