import { Dispatch } from "redux";

import { MQTTService } from "../../../services/MQTTService";

import { MQTTClientType, TMQTTClientActions } from "./types";

export const MQTTClientRequestAction = (): TMQTTClientActions => ({
  type: MQTTClientType.REQUEST,
});

export const MQTTClientSuccessAction = (data: any): TMQTTClientActions => ({
  payload: data,
  type: MQTTClientType.SUCCESS,
});

export const MQTTClientErrorAction = (error: string): TMQTTClientActions => ({
  payload: error,
  type: MQTTClientType.ERROR,
});

export const MQTTClientClearAction = (): TMQTTClientActions => ({
  type: MQTTClientType.CLEAR,
});

export const getMqttClients = (conf: any) => (dispatch: Dispatch<TMQTTClientActions>) => {
  dispatch(MQTTClientRequestAction());
  MQTTService.getMqttClients(conf).then(
    (data: any) => {
      dispatch(MQTTClientSuccessAction(data.data.data));
    },
    (error: any) => dispatch(MQTTClientErrorAction(error))
  );
};
