/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AircraftFilterSortType {
  CLEAR = "AIRCRAFT_FILTER_SORT_CLEAR",
  RESET = "AIRCRAFT_FILTER_SORT_RESET",
  APPLY = "AIRCRAFT_FILTER_SORT_APPLY",
}

export interface IAircraftFilterSortClearAction extends IReduxBaseAction<AircraftFilterSortType> {
  type: AircraftFilterSortType.CLEAR;
}

export interface IAircraftFilterSortResetAction extends IReduxBaseAction<AircraftFilterSortType> {
  type: AircraftFilterSortType.RESET;
}

export interface IAircraftFilterSortApplyAction extends IReduxBaseAction<AircraftFilterSortType> {
  payload: IFilterSortData;
  type: AircraftFilterSortType.APPLY;
}

export type TAircraftFilterSortActions =
  | IAircraftFilterSortClearAction
  | IAircraftFilterSortResetAction
  | IAircraftFilterSortApplyAction;
