import registerLanguage from "monaco-proto-lint";
import { ButtonPrimary } from "next-components";
import React, { useEffect, useRef, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { updatePayload } from "../../actions/payload/update/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { payloadFormatEnum } from "../../models/ITopicConfig";
import { RootState } from "../../store";
import { SFlexContainer } from "../../styles/styles";

interface IPayloadSchemaEditor {
  payloadData: {
    payloadFormat: any;
    payloadSchema: any;
  };
  payloadId?: string;
  callback(response: any): void;
  cancel(): void;
}

export const PayloadSchemaEditor = ({
  payloadId,
  payloadData,
  cancel,
  callback,
}: IPayloadSchemaEditor) => {
  const [payloadSchema, setPayloadSchema] = useState(payloadData.payloadSchema);

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const handleEditorWillMount = (monaco: any) => {
    if (monaco.languages.json) {
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        enableSchemaRequest: true,
        validate: true,
      });
    }

    // Register "protobuf" as language and theme
    registerLanguage(monaco);
  };

  const handleUpload = () => {
    dispatch(
      updatePayload(
        {
          currentPayloadId: payloadId,
          payload: payloadSchema,
          payloadFormat: payloadData.payloadFormat,
        },
        callback
      )
    );
  };

  const editorOptions = {
    editorWillMount: handleEditorWillMount,
    height: 400,
    language: payloadData.payloadFormat === payloadFormatEnum.PROTOBUF ? "protobuf" : "json",
    onChange: setPayloadSchema,
    options: {
      minimap: {
        enabled: false,
      },
      selectOnLineNumbers: true,
    },
    theme: "vs-dark",
    value: payloadSchema,
  };

  return (
    <>
      {/* <Editor {...editorOptions} /> */}
      <MonacoEditor {...editorOptions} />

      <SFlexContainer justifyContent="space-between">
        <SFlexContainer justifyContent="flex-start">
          <ButtonPrimary
            className={getTAClass("payloadSchemaEditor", TA_TYPES.BUTTON, "cancel")}
            onClick={cancel}
          >
            Cancel
          </ButtonPrimary>
        </SFlexContainer>
        <SFlexContainer justifyContent="flex-end">
          <ButtonPrimary
            className={getTAClass("payloadSchemaEditor", TA_TYPES.BUTTON, "update")}
            onClick={handleUpload}
          >
            Update
          </ButtonPrimary>
        </SFlexContainer>
      </SFlexContainer>
    </>
  );
};
