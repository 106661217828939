import { Grid } from "next-components";
import styled from "styled-components";

export const BatchActionGrid = styled(Grid)`
  background-color: #2a7fc9;
  height: 48px;
  padding-left: 12px;
  white-space: nowrap;
`;

export const Actions = styled.div`
  display: flex;
  justify-self: end;
  max-width: 100%;
  min-width: 0;
`;

export const Separator = styled.div`
  background-color: #77b0e2;
  height: 40px;
  width: 1px;
`;
