import { ButtonIcon, Grid, GridItem, IconArrowLeft32 } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationDetails } from "../../actions/application/get-details/actions";
import { isUserAppOwner } from "../../helper/util";
import { tabAppDetailsEnum } from "../../models/IAppDetailsConfig";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SPageContainer, SPageHeading, SSpacer } from "../../styles/styles";
import { ModalAddTopicPublisher } from "../modals/ModalAddTopicPublisher";
import { ModalAddTopicSubscriber } from "../modals/ModalAddTopicSubscriber";
import { ModalEditApplication } from "../modals/ModalEditApplication";
import { ModalEditComment } from "../modals/ModalEditComment";
import { PageError, PageLoading } from "../PageState";

import { AppChangeHistories } from "./ApplicationClientDetails/AppChangeHistories";
import { ApplicationDestinationRouting } from "./ApplicationClientDetails/ApplicationDestinationRouting";
import { ApplicationDetailsDetailsTab } from "./ApplicationClientDetails/ApplicationDetailsDetailsTab";
import { ApplicationDetailsPermissionsTab } from "./ApplicationClientDetails/ApplicationDetailsPermissionsTab";
import { ApplicationDetailsTabs } from "./ApplicationClientDetails/ApplicationDetailsTabs";
import { ApplicationDetailsTopSection } from "./ApplicationClientDetails/ApplicationDetailsTopSection";
import { TopicListPublish } from "./ApplicationClientDetails/TopicListPublish";
import { TopicListSubscribe } from "./ApplicationClientDetails/TopicListSubscribe";

export const ApplicationClientsDetails = ({ match, history }: { history: any; match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { applicationDetails, currentUser, editModal } = useSelector((state: RootState) => ({
    applicationDetails: state.applicationDetails,
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const [appId, setAppId] = useState("");
  const [activeTab, setActiveTab] = useState(tabAppDetailsEnum.PUBLISH);
  const [showPermissionsTab, setShowPermissionsTab] = useState(false);

  const resetActiveTab = () => {
    setActiveTab(
      applicationDetails.content.appClientLocation === brokerLocationEnum.GROUNDSIDE
        ? tabAppDetailsEnum.SUBSCRIBE
        : tabAppDetailsEnum.PUBLISH
    );
  };

  useEffect(() => {
    setShowPermissionsTab(
      currentUser.data.role === ADMINISTRATOR ||
        (applicationDetails.content.appOwners &&
          isUserAppOwner(currentUser.data, applicationDetails.content.appOwners))
    );
    setActiveTab(
      applicationDetails.content.appClientLocation === brokerLocationEnum.GROUNDSIDE
        ? tabAppDetailsEnum.SUBSCRIBE
        : tabAppDetailsEnum.PUBLISH
    );
  }, [applicationDetails, currentUser]);

  useEffect(() => {
    if (match.params.appId !== "" && typeof match.params.appId !== "undefined") {
      setAppId(match.params.appId);
      dispatch(getApplicationDetails(match.params.appId));
    }
  }, [match.params.appId]);

  /* tslint:disable:cyclomatic-complexity */
  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link to="/applicationClients" className="ta-application-details-button-back">
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-application-title">Application client details</SPageHeading>
          </GridItem>
        </Grid>

        {applicationDetails.loading && <PageLoading />}

        {applicationDetails.error && <PageError />}

        {!applicationDetails.loading && !applicationDetails.error && (
          <>
            {/* APP DETAILS */}
            {applicationDetails.content && currentUser.data && (
              <ApplicationDetailsTopSection
                appContent={applicationDetails.content}
                editModal={editModal}
                userData={currentUser.data}
              />
            )}

            <SSpacer />

            {/* APP TABS */}
            <ApplicationDetailsTabs
              appContent={applicationDetails.content}
              activeTab={activeTab}
              onChange={(tab: tabAppDetailsEnum) => {
                setActiveTab(tab);
              }}
              showPermissionsTab={showPermissionsTab}
            />

            <SSpacer />

            {/* TABLE */}
            {activeTab === tabAppDetailsEnum.SUBSCRIBE &&
              applicationDetails.content &&
              currentUser.data && (
                <TopicListSubscribe
                  userData={currentUser.data}
                  appContent={applicationDetails.content}
                />
              )}
            {activeTab === tabAppDetailsEnum.PUBLISH &&
              applicationDetails.content &&
              currentUser.data && (
                <TopicListPublish
                  userData={currentUser.data}
                  appContent={applicationDetails.content}
                />
              )}
            {activeTab === tabAppDetailsEnum.DESTINATION_ROUTING &&
              applicationDetails.content &&
              currentUser.data && (
                <ApplicationDestinationRouting
                  appContent={applicationDetails.content}
                  editModal={editModal}
                  userData={currentUser.data}
                />
              )}
            {activeTab === tabAppDetailsEnum.DETAILS &&
              applicationDetails.content &&
              currentUser.data && (
                <ApplicationDetailsDetailsTab
                  history={history}
                  onApplicationOpened={resetActiveTab}
                  appContent={applicationDetails.content}
                  editModal={editModal}
                  userData={currentUser.data}
                />
              )}
            {showPermissionsTab &&
              activeTab === tabAppDetailsEnum.PERMISSIONS &&
              applicationDetails.content &&
              currentUser.data && (
                <ApplicationDetailsPermissionsTab
                  appContent={applicationDetails.content}
                  editModal={editModal}
                  userData={currentUser.data}
                />
              )}
            {activeTab === tabAppDetailsEnum.HISTORY &&
              applicationDetails.content &&
              currentUser.data && (
                <AppChangeHistories
                  appContent={applicationDetails.content}
                  userData={currentUser.data}
                />
              )}
          </>
        )}
      </SPageContainer>

      {editModal.open && editModal.type === modalTypeEnum.EDIT_APP && <ModalEditApplication />}
      {editModal.open &&
        (editModal.type === modalTypeEnum.DELETE_APP ||
          editModal.type === modalTypeEnum.DELETE_MQTT ||
          editModal.type === modalTypeEnum.DELETE_MQTT_BULK) && <ModalEditComment />}
      {editModal.open && editModal.type === modalTypeEnum.CREATE_TOPIC_PUBLISH && (
        <ModalAddTopicPublisher />
      )}
      {editModal.open && editModal.type === modalTypeEnum.CREATE_TOPIC_SUBSCRIBE && (
        <ModalAddTopicSubscriber />
      )}
    </>
  );
  /* tslint:enable:cyclomatic-complexity */
};
