import { Dispatch } from "redux";

import {
  IBrokerTypeListingTableConfig,
  IGetBrokerTypeListData,
} from "../../../models/IBrokerTypeListingTableConfig";
import { BrokerTypeService } from "../../../services/BrokerTypeService";

import { BrokerTypeListType, TBrokerTypeActions } from "./types";

export const brokerTypeListRequestAction = (): TBrokerTypeActions => ({
  type: BrokerTypeListType.REQUEST,
});

export const brokerTypeListSuccessAction = (data: any): TBrokerTypeActions => ({
  payload: data,
  type: BrokerTypeListType.SUCCESS,
});

export const brokerTypeListErrorAction = (error: string): TBrokerTypeActions => ({
  payload: error,
  type: BrokerTypeListType.ERROR,
});

export const getBrokerTypeList = (conf: IBrokerTypeListingTableConfig) => (
  dispatch: Dispatch<TBrokerTypeActions>
) => {
  dispatch(brokerTypeListRequestAction());
  BrokerTypeService.getBrokerTypeList(conf).then(
    (response: { data: { data: IGetBrokerTypeListData } }) => {
      dispatch(brokerTypeListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(brokerTypeListErrorAction(error))
  );
};
