import { Dispatch } from "redux";

import { topicPermissionTypeEnum } from "../../../models/ITopicDetailsConfig";
import { TopicService } from "../../../services/TopicService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import { AddTopicPermissionAppTypes, TSetAddTopicPermissionAppActions } from "./types";

export const AddTopicPermissionAppRequestAction = (
  data: any
): TSetAddTopicPermissionAppActions => ({
  payload: data,
  type: AddTopicPermissionAppTypes.REQUEST,
});
export const AddTopicPermissionAppSuccessAction = (
  data: any
): TSetAddTopicPermissionAppActions => ({
  payload: data,
  type: AddTopicPermissionAppTypes.SUCCESS,
});
export const AddTopicPermissionAppErrorAction = (error: any): TSetAddTopicPermissionAppActions => ({
  payload: error,
  type: AddTopicPermissionAppTypes.ERROR,
});

export const addTopicPermissionApp = (
  topicId: string,
  config: any,
  permType: topicPermissionTypeEnum,
  cb: any
) => (dispatch: Dispatch<TPopupActions | TSetAddTopicPermissionAppActions>) => {
  dispatch(AddTopicPermissionAppRequestAction(config));
  TopicService.addTopicPermissionApp(topicId, config, permType).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(AddTopicPermissionAppSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Topic permission app added successfully!",
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data.data.content.topicId, permType);
    },
    (error: any) => {
      dispatch(AddTopicPermissionAppErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
