/* istanbul ignore file */
import { IGetAircraftGroupListData } from "../../../models/IAircraftGroup";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AircraftGroupListType {
  RESET = "GET_AIRCRAFT_GROUP_LIST_RESET",
  REQUEST = "GET_AIRCRAFT_GROUP_LIST_REQUEST",
  SUCCESS = "GET_AIRCRAFT_GROUP_LIST_SUCCESS",
  ERROR = "GET_AIRCRAFT_GROUP_LIST_ERROR",
}

export interface IAircraftGroupListResetAction extends IReduxBaseAction<AircraftGroupListType> {
  type: AircraftGroupListType.RESET;
}

export interface IAircraftGroupListRequestAction extends IReduxBaseAction<AircraftGroupListType> {
  type: AircraftGroupListType.REQUEST;
}

export interface IAircraftGroupListSuccessAction extends IReduxBaseAction<AircraftGroupListType> {
  payload: IGetAircraftGroupListData;
  type: AircraftGroupListType.SUCCESS;
}

export interface IAircraftGroupListErrorAction extends IReduxBaseAction<AircraftGroupListType> {
  payload: string;
  type: AircraftGroupListType.ERROR;
}

export type TAircraftGroupListActions =
  | IAircraftGroupListResetAction
  | IAircraftGroupListRequestAction
  | IAircraftGroupListSuccessAction
  | IAircraftGroupListErrorAction;
