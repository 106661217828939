/**
 * Client username validation
 */
// tslint:disable-next-line:no-unnecessary-class
class ClientUsernameValidator {
  /**
   * validateUsername
   * @param userName string - the username to be validated
   * @returns boolean
   */
  public static validateUsername(userName: string): boolean {
    if (
      userName &&
      userName.length >= 3 &&
      userName.length <= 100 &&
      new RegExp("^[a-zA-Z][a-zA-Z0-9_-]*$").test(userName)
    ) {
      return true;
    }

    return false;
  }
}

export { ClientUsernameValidator };
