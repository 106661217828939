import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  GetPayloadTypes,
  IGetPayloadErrorAction,
  IGetPayloadRequestAction,
  IGetPayloadSuccessAction,
  TGetPayloadActions,
} from "./types";

export const GetPayloadRequestAction = (data: any): IGetPayloadRequestAction => ({
  payload: data,
  type: GetPayloadTypes.REQUEST,
});
export const GetPayloadSuccessAction = (data: any): IGetPayloadSuccessAction => ({
  payload: data,
  type: GetPayloadTypes.SUCCESS,
});
export const GetPayloadErrorAction = (error: any): IGetPayloadErrorAction => ({
  payload: error,
  type: GetPayloadTypes.ERROR,
});

// ACTION
export const getPayload = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TGetPayloadActions | TEditModalActions>
) => {
  dispatch(GetPayloadRequestAction(config));
  TopicService.getPayload(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(GetPayloadSuccessAction(data.data));
      cb(data.data.data.content);
    },
    (error: any) => {
      dispatch(GetPayloadErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
