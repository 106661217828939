import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";
// import { ModalRequestTopicActivationPage1 } from "./ModalRequestTopicActivationPage1";

export const ModalRequestTopicActivation = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = true; // comment required?
  const [validPage, setValidPage] = useState([!changeCommentRequired]);
  const [dialogTitle, setDialogTitle] = useState("Request topic activation");
  const [currentPage, setCurrentPage] = useState(0);

  const [config, setConfig] = useState({
    changeComment: "",
  });

  useEffect(() => {
    if (editModal.popupData.title && editModal.popupData.title.length > 0) {
      setDialogTitle(editModal.popupData.title);
    }
  }, [editModal]);

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      editModal.popupData.onConfirm({
        changeComment: config.changeComment,
      });
    }
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(0, isCommentValid);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkCommentInput();
    }
  }, [config]);

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  const modalTheme = {
    modal: {
      width: "800px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {currentPage === 0 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are requesting to activate this topic.`}
            commentRequired={changeCommentRequired}
            topInfoStr={`Your topic activation request will be sent to Architecture team for review and approval.`}
          />
        )}

        <SSpacer height="8px" />

        <SFlexContainer justifyContent="flex-end">
          <ButtonPrimary
            className="ta-modal-confirm-button"
            onClick={handleConfirm}
            disabled={!validPage[Number(currentPage)]}
          >
            {editModal.popupData.confirmText ? (
              <>{editModal.popupData.confirmText}</>
            ) : (
              <>Confirm</>
            )}
          </ButtonPrimary>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
