/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum UserFilterSortType {
  CLEAR = "USER_FILTER_SORT_CLEAR",
  RESET = "USER_FILTER_SORT_RESET",
  APPLY = "USER_FILTER_SORT_APPLY",
}

export interface IUserFilterSortClearAction extends IReduxBaseAction<UserFilterSortType> {
  type: UserFilterSortType.CLEAR;
}

export interface IUserFilterSortResetAction extends IReduxBaseAction<UserFilterSortType> {
  type: UserFilterSortType.RESET;
}

export interface IUserFilterSortApplyAction extends IReduxBaseAction<UserFilterSortType> {
  payload: IFilterSortData;
  type: UserFilterSortType.APPLY;
}

export type TUserFilterSortActions =
  | IUserFilterSortClearAction
  | IUserFilterSortResetAction
  | IUserFilterSortApplyAction;
