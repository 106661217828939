import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { userFilterSortApplyAction } from "../../../actions/user/filter-sort/actions";
import { getUserList } from "../../../actions/user/get-list/actions";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import { ITableConfig, ITableRow, OrderType } from "../../../models/ITableConfig";
import {
  ADMINISTRATOR,
  APPLICATION_OWNER,
  APPROVER,
  getUserRole,
  READ,
} from "../../../models/UserTypes";
import { userFilterSortEmptyFilter as emptyFilterObject } from "../../../reducers/user/UserFilterSortReducer";
import { RootState } from "../../../store";
import { SOverlay, SPageContainer, SPageHeading, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

export const Users = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { userList } = useSelector((state: any) => ({
    userList: state.userList,
  }));
  const { config } = useSelector((state: RootState) => ({
    config: state.userFilterSort,
  }));

  const headCells = [
    { id: "firstName", label: "First name" },
    { id: "lastName", label: "Last name" },
    { id: "email", label: "Email" },
    { id: "role", label: "Role" },
  ];

  const userRoles = [
    {
      label: <span className="ta-dropdown-all">All roles</span>,
      purelabel: "All",
      value: "",
    },
    {
      label: <span className="ta-dropdown-read_only">{getUserRole(READ)}</span>,
      purelabel: getUserRole(READ),
      value: READ,
    },
    {
      label: (
        <span className="ta-dropdown-application_owner">{getUserRole(APPLICATION_OWNER)}</span>
      ),
      purelabel: getUserRole(APPLICATION_OWNER),
      value: APPLICATION_OWNER,
    },
    {
      label: <span className="ta-dropdown-approver">{getUserRole(APPROVER)}</span>,
      purelabel: getUserRole(APPROVER),
      value: APPROVER,
    },
    {
      label: <span className="ta-dropdown-administrator">{getUserRole(ADMINISTRATOR)}</span>,
      purelabel: getUserRole(ADMINISTRATOR),
      value: ADMINISTRATOR,
    },
  ];

  // const emptyFilterObject = {
  //   email: "",
  //   firstName: "",
  //   lastName: "",
  //   role: "",
  // };

  // const initialSortAndPagination = {
  //   limit: 10,
  //   orderBy: "firstName",
  //   orderType: OrderType.ASC,
  //   page: 0,
  // };

  // const [config, setConfig] = useState({
  //   filter: { ...emptyFilterObject },
  //   ...initialSortAndPagination,
  //   roles: [],
  // });

  const setConfig = (newConfig: any) => {
    dispatch(userFilterSortApplyAction(newConfig));
  };

  useEffect(() => {
    dispatch(getUserList(config));
  }, [config]);

  const openDetails = (userRow: ITableRow): void => {
    router.history.push(`users/${userRow.userId}`);
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...userList,
      cellRules: [
        {
          columnName: "role",
          mask: {
            from: "value",
            source: userRoles,
            to: "purelabel",
          },
        },
      ],
      onClickRow: openDetails,
    },
    name: "users",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "firstName",
        placeholder: "First name",
        taClass: "firstName",
      },
      {
        name: "lastName",
        placeholder: "Last name",
        taClass: "lastName",
      },
      {
        name: "email",
        placeholder: "Email",
        taClass: "email",
      },
      {
        data: userRoles,
        name: "role",
        taClass: "role",
        type: FilterType.DROPDOWN,
      },
    ],
    pageName: "users",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  return (
    <>
      <SPageContainer>
        {/* LOADING */}
        {userList.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        <SPageHeading className="ta-users-title">Users</SPageHeading>
        <Filter {...filterConfig} />
        <SSpacer />
        <Table {...tableProps}></Table>
      </SPageContainer>
    </>
  );
};
