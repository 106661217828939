import React from "react";

import { ISideBarItem } from "./ICloudBar";
import { BadgeContainer, Icon, Label, LinkContainer } from "./ItemNode.style";

const SideBarItem = (props: ISideBarItem) => (
  <LinkContainer {...props}>
    <Icon>{props.icon}</Icon>
    {props.showLabel && <Label>{props.label}</Label>}
    {props.badge && <BadgeContainer>{props.badge}</BadgeContainer>}
  </LinkContainer>
);

export default SideBarItem;
