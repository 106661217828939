import React from "react";
import { Link } from "react-router-dom";

import { IDataDetailLabelProps } from "../models/IDataDetailLabelProps";
import { SActiveLink, STallText, STextDataTitle, STextDataValue } from "../styles/styles";

export const DataDetailLabel = (props: IDataDetailLabelProps) => (
  <STallText variant="uiText" className={props.className}>
    <STextDataTitle>{props.title}:</STextDataTitle>
    {props.link ? (
      <STextDataValue>
        <SActiveLink>
          <Link to={props.link}>{props.value}</Link>
        </SActiveLink>
      </STextDataValue>
    ) : (
      <STextDataValue>{props.value}</STextDataValue>
    )}
  </STallText>
);
