import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum RemoveAircraftsFromGroupsTypes {
  ERROR = "REMOVE_AIRCRAFTS_FROM_GROUPS_ERROR",
  REQUEST = "REMOVE_AIRCRAFTS_FROM_GROUPS_REQUEST",
  SUCCESS = "REMOVE_AIRCRAFTS_FROM_GROUPS_SUCCESS",
}

export interface IRemoveAircraftsFromGroupsRequestAction
  extends IReduxBaseAction<RemoveAircraftsFromGroupsTypes> {
  type: RemoveAircraftsFromGroupsTypes.REQUEST;
}

export interface IRemoveAircraftsFromGroupsSuccessAction
  extends IReduxBaseAction<RemoveAircraftsFromGroupsTypes> {
  payload: any;
  type: RemoveAircraftsFromGroupsTypes.SUCCESS;
}

export interface IRemoveAircraftsFromGroupsErrorAction
  extends IReduxBaseAction<RemoveAircraftsFromGroupsTypes> {
  payload: any;
  type: RemoveAircraftsFromGroupsTypes.ERROR;
}

export type TRemoveAircraftsFromGroupsActions =
  | IRemoveAircraftsFromGroupsErrorAction
  | IRemoveAircraftsFromGroupsRequestAction
  | IRemoveAircraftsFromGroupsSuccessAction;
