import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TRemoveDestinationRoute {
  ERROR = "REMOVE_DESTINATION_ROUTE_ERROR",
  REQUEST = "REMOVE_DESTINATION_ROUTE_REQUEST",
  SUCCESS = "REMOVE_DESTINATION_ROUTE_SUCCESS",
}

export interface IRemoveDestinationRouteRequestAction
  extends IReduxBaseAction<TRemoveDestinationRoute> {
  type: TRemoveDestinationRoute.REQUEST;
}

export interface IRemoveDestinationRouteSuccessAction
  extends IReduxBaseAction<TRemoveDestinationRoute> {
  payload: any;
  type: TRemoveDestinationRoute.SUCCESS;
}

export interface IRemoveDestinationRouteErrorAction
  extends IReduxBaseAction<TRemoveDestinationRoute> {
  payload: any;
  type: TRemoveDestinationRoute.ERROR;
}

export type IRemoveDestinationRouteActionTypes =
  | IRemoveDestinationRouteErrorAction
  | IRemoveDestinationRouteRequestAction
  | IRemoveDestinationRouteSuccessAction;
