import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IUploadPayloadErrorAction,
  IUploadPayloadRequestAction,
  IUploadPayloadSuccessAction,
  TUploadPayloadActions,
  UploadPayloadTypes,
} from "./types";

export const uploadPayloadRequestAction = (data: any): IUploadPayloadRequestAction => ({
  payload: data,
  type: UploadPayloadTypes.REQUEST,
});
export const uploadPayloadSuccessAction = (data: any): IUploadPayloadSuccessAction => ({
  payload: data,
  type: UploadPayloadTypes.SUCCESS,
});
export const uploadPayloadErrorAction = (error: any): IUploadPayloadErrorAction => ({
  payload: error,
  type: UploadPayloadTypes.ERROR,
});

// ACTION
export const uploadPayload = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TUploadPayloadActions | TEditModalActions>
) => {
  dispatch(uploadPayloadRequestAction(config));
  TopicService.uploadPayload(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(uploadPayloadSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Payload uploaded successfully!",
          title: "Success",
          type: "Info",
        })
      );
      // REMOVED dispatch(editModalCloseAction());
      cb(data.data.data.content);
    },
    (error: any) => {
      dispatch(uploadPayloadErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
