export enum FilterType {
  CUSTOM = "custom",
  TEXT = "text",
  DATEPICKER = "datepicker",
  DROPDOWN = "dropdown",
  SPACE = "space",
}

export interface IFilterItem {
  /**
   * Mandatory if type is FilterType.DROPDOWN
   */
  data?: any[];
  /**
   * Key of column
   */
  name: string;
  /**
   * Not mandatory field. Name will be used if this is not provided.
   */
  placeholder?: string;
  /**
   * Test automation class, because in most cases it differs from the name property.
   */
  taClass: string;
  /**
   * What type of filter should be rendered
   */
  type?: FilterType;
  /**
   * Set width of column in fragment
   */
  width?: string;
}

export interface ICustomElement {
  element: JSX.Element;
  width?: string;
}

export interface IFilterConfig {
  columns?: string;
  columns2?: string;
  customElements?: ICustomElement[];
  emptyFilter?: any;
  initFilter?: any;
  items: IFilterItem[];
  items2?: IFilterItem[];
  pageName: string;
  returnFilter(filter: any): void;
}
