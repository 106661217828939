import { GetTopologyListType, TTopologyListActions } from "../../actions/topology/types";

interface ITopologyListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
} as ITopologyListState;

export const topologyList = (
  state: ITopologyListState = initialState,
  action: TTopologyListActions
): ITopologyListState => {
  switch (action.type) {
    case GetTopologyListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetTopologyListType.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case GetTopologyListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
