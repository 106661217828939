import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTaClassedOptions } from "../../helper/dropdown";
import { getTenantLabel } from "../../helper/util";
import { appVisibilityEnum } from "../../models/IAppDetailsConfig";
import { userTenantEnum } from "../../models/ITenant";
import { SFlexContainer, SFlexItem, SSpacer, SVerticalSpacer } from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";

import { DropdownInput } from "./Dropdownlnput";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalEditAppVisibility = () => {
  //   const dispatch = useDispatch();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = false; // comment required?
  const [dialogTitle, setDialogTitle] = useState("Application group visibility");
  //   const [changeComment, setChangeComment] = useState("");
  const [isCommentValid, setIsCommentValid] = useState(!changeCommentRequired);
  const [isDirty, setIsDirty] = useState(false);
  const [includePacUsersHidden, setIncludePacUsersHidden] = useState(true);

  const [config2, setConfig2] = useState({
    changeComment: "",
    isVisibleForPac: false,
    visibility: "",
  });

  const [visibilityOptions, setVisibilityOptions] = useState([
    { label: "Tenant users", value: appVisibilityEnum.TENANT_USERS },
    { label: "All users", value: appVisibilityEnum.ALL_USERS },
  ]);

  useEffect(() => {
    if (editModal.popupData.title && editModal.popupData.title.length > 0) {
      setDialogTitle(editModal.popupData.title);
    }
    if (editModal.appData) {
      //   config.isVisibleForPac = editModal.appData.isVisibleForPac;
      //   config.visibility = editModal.appData.visibility;
      //   console.info("ModalEditAppVisibility: config2[", config2, "] #1");
      setConfig2({
        ...config2,
        isVisibleForPac: editModal.appData.isVisibleForPac,
        visibility: editModal.appData.visibility,
      });
      //   console.info("ModalEditAppVisibility: config2[", config2, "] #2");

      // setup 'tenant users' dropdown label from tenant info
      const visOptions = visibilityOptions;
      visOptions[0].label = `${getTenantLabel(editModal.appData)} users`;
      setVisibilityOptions(visOptions);

      // setIncludePacUsersHidden(editModal.appData.internal);
      if (editModal.appData.tenant && !!editModal.appData.tenant.tenantId) {
        setIncludePacUsersHidden(editModal.appData.tenant.tenantId === userTenantEnum.PAC);
      }
    }
  }, [editModal]);

  useEffect(() => {
    // if (editModal.appData) {
    // //   config.isVisibleForPac = editModal.appData.isVisibleForPac;
    // //   config.visibility = editModal.appData.visibility;
    //   setConfig({
    //     ...config,
    //     isVisibleForPac: editModal.appData.isVisibleForPac,
    //     visibility: editModal.appData.visibility,
    //   });
    // }
    // console.info("ModalEditAppVisibility: config2[", config2, "] #3");
  }, [visibilityOptions]);

  useEffect(() => {
    // console.info("ModalEditAppVisibility: config2[", config2, "] #4");
    if (editModal.appData) {
      if (
        config2.isVisibleForPac !== editModal.appData.isVisibleForPac ||
        config2.visibility !== editModal.appData.visibility
      ) {
        setIsDirty(true);
      } else {
        setIsDirty(false);
      }
    }
  }, [config2]);

  const setChangeComment = (comment: any) => {
    if (changeCommentRequired) {
      setIsCommentValid(comment.length > 0);
    } else {
      setIsCommentValid(true);
    }
    // console.info("ModalEditAppVisibility: config2[", config2, "] #6");
    setConfig2({ ...config2, changeComment: comment });
    // console.info("ModalEditAppVisibility: config2[", config2, "] #6.1");
  };

  const handleVisibilityChange = (value: any) => {
    // console.info("ModalEditAppVisibility: config[", config, "] #5");
    setConfig2({ ...config2, visibility: value });
    // console.info("ModalEditAppVisibility: config[", config, "] #5.1");
  };

  const handleIncludePACUserCheckboxClick = (e: any) => {
    setConfig2({ ...config2, isVisibleForPac: !config2.isVisibleForPac });
  };

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setChangeComment(e.target.value);
    }
  };

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      editModal.popupData.onConfirm({ ...config2 });
    }
  };

  const getCommentHint = () => {
    let hintStr =
      editModal.popupData.commentHint && editModal.popupData.commentHint.length > 0
        ? editModal.popupData.commentHint
        : "Please enter a comment about why you are making this change.";

    hintStr += changeCommentRequired ? " (required)" : " (optional)";

    return hintStr;
  };

  return (
    <ModalCreateBase title={dialogTitle}>
      <DropdownInput
        label="Visible by:"
        name="visibility"
        onChange={handleVisibilityChange}
        options={getTaClassedOptions(visibilityOptions)}
        selected={config2.visibility}
      />
      <Text variant="captionText" className="ta-modal-visible-caption-note">
        Note: app client is always visible by its owners.
      </Text>

      {/* <SSpacer height="8px" /> */}

      {!includePacUsersHidden && config2.visibility !== appVisibilityEnum.ALL_USERS && (
        <SFlexItem>
          <div className="ta-modal-input-include-pac-users">
            <Checkbox
              onClick={handleIncludePACUserCheckboxClick}
              marked={
                config2.isVisibleForPac ? CheckboxOptions.selected : CheckboxOptions.unselected
              }
              // disabled={config2.visibility === appVisibilityEnum.ALL_USERS}
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-include-pac-users">
            Allow PAC users visibility
          </Text>
        </SFlexItem>
      )}

      <SSpacer height="40px" />

      <Text variant="captionText" className="ta-modal-input-caption-comment">
        Comment:
      </Text>
      <Textarea
        className="ta-modal-input-comment"
        placeholder={getCommentHint()}
        value={config2.changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "comment", 2048);
        }}
        handleCloseButton={editModal.popupData.onCancel}
      />

      <SSpacer height="20px" />

      <SFlexContainer justifyContent="flex-end">
        <ButtonPrimary
          className="ta-modal-button-Save"
          onClick={handleConfirm}
          disabled={!isDirty || !isCommentValid}
        >
          {editModal.popupData.confirmText ? <>{editModal.popupData.confirmText}</> : <>Save</>}
        </ButtonPrimary>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
