import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { editModalOpenAction } from "../../actions/modal/edit/actions";
import {
  tenantFilterSortApplyAction,
  tenantFilterSortClearAction,
} from "../../actions/tenant/filter-sort/actions";
import { getTenantList } from "../../actions/tenant/get-list/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { getFormattedDate, getTenantLabel, getTopicTypeLabel } from "../../helper/util";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { FilterType, ICustomElement, IFilterConfig, IFilterItem } from "../../models/IFilterConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { topicStatusEnum, topicTypeEnum } from "../../models/ITopicConfig";
import { ADMINISTRATOR, READ } from "../../models/UserTypes";
import { tenantFilterSortEmptyFilter as emptyFilterObject } from "../../reducers/tenant/TenantFilterSortReducer";
import { RootState } from "../../store";
import {
  SActiveLink,
  SButtonIcon,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../styles/styles";
import { Filter } from "../Filter";
import { ModalCreateAirline } from "../modals/ModalCreateAirline";
import { Table } from "../Table";

export const Airlines = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const history = useHistory();

  const { currentUser, editModal, tenantList } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    tenantList: state.tenantList,
  }));

  const { config } = useSelector((state: RootState) => ({
    config: state.tenantFilterSort,
  }));

  const setConfig = (newConfig: any) => {
    dispatch(tenantFilterSortApplyAction(newConfig));
  };

  useEffect(() => {
    dispatch(getTenantList(config));
  }, [config]);

  const navigateToPage = (url: string): void => {
    // router.history.push(url);
    history.push(url);
  };

  const [headCells, setHeadCells] = useState([
    {
      id: "tenantName",
      label: "Airline",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/airlines/${row.tenantId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.tenantName}</SActiveLink>,
    },
    {
      id: "icao",
      label: "ICAO",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`/airlines/${row.tenantId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.icao.toUpperCase()}</SActiveLink>,
    },
    {
      id: "modifiedBy",
      label: "Modified by",
      value: (row: ITableRow) => (row.modifiedBy ? row.modifiedBy.fullName : "Unknown"),
    },
    {
      id: "modifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
  ]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...tenantList,
    },
    name: "airlines",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const handleOpenModal = () => {
    dispatch(
      editModalOpenAction({
        // popupData: {
        //   provisionEnv: "production",
        // },
        type: modalTypeEnum.NEW_AIRLINE,
      })
    );
  };

  const [filterItems, setFilterItems] = useState([
    {
      name: "tenantName",
      placeholder: "Airline",
      taClass: "tenantName",
      type: FilterType.TEXT,
    },
    {
      name: "icao",
      placeholder: "ICAO",
      taClass: "icao",
      type: FilterType.TEXT,
    },
    {
      name: "modifiedBy",
      placeholder: "Modified by",
      taClass: "modifiedBy",
      type: FilterType.TEXT,
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ]);

  const filterConfig: IFilterConfig = {
    customElements: [
      {
        element: (
          <SButtonIcon
            size="default"
            icon={<SIconAdd32 />}
            onClick={handleOpenModal}
            className={getTAClass("airlines", TA_TYPES.BUTTON, "createNew")}
            disabled={currentUser.data.role !== ADMINISTRATOR}
          />
        ),
        width: "0.25fr",
      },
    ],
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: filterItems,
    pageName: "airlines",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  //   useEffect(() => {
  //     if (!currentUser.data || currentUser.data.role !== ADMINISTRATOR) {
  //       // for non-admin user - remove 'multicast' column/header/filter
  //       headCells.splice(4, 1);
  //       filterItems.splice(4, 1);
  //     }

  //     // apply header/filter update
  //     setHeadCells(headCells);
  //     setFilterItems(filterItems);
  //   }, [currentUser]);

  return (
    <>
      <SPageContainer>
        {/* <SPageHeading className="ta-airlines-title">Airlines</SPageHeading> */}

        <Filter {...filterConfig} />

        <SSpacer height="20px" />

        {/* LOADING */}
        {tenantList.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        <Table {...tableProps}></Table>
      </SPageContainer>

      {/* CREATE Airline MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.NEW_AIRLINE && (
        <ModalCreateAirline />
      )}
    </>
  );
};
