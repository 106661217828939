import deepEqual from "deep-equal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAllBrokerType } from "../../../actions/broker-type/get-all/actions";
import { configAppFilterSortApplyAction } from "../../../actions/configfile/app-filter-sort/actions";
import { getConfigApplication } from "../../../actions/configfile/application/actions";
import { getConfigVersion } from "../../../actions/configversion/get/actions";
import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getFormattedDate } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { configFileAppFilterSortEmptyFilter as emptyFilterObject } from "../../../reducers/configfile/ConfigFileAppFilterSortReducer";
import { ConfigService } from "../../../services/ConfigService";
import { RootState } from "../../../store";
import { SActiveLink, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalDisplayConfigVersion } from "../../modals/ModalDisplayConfigVersion";
import { Table } from "../../Table";

export const ConfigurationAppList = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { configFileGenerate } = useSelector((state: RootState) => ({
    configFileGenerate: state.configFileGenerate,
  }));
  const { config } = useSelector((state: RootState) => ({
    config: state.configFileAppFilterSort,
  }));

  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  const { configFileApplication, allBrokerType } = useSelector((state: RootState) => ({
    allBrokerType: state.allBrokerType,
    configFileApplication: state.configFileApplication,
  }));

  // const emptyFilterObject = {
  //   appName: "",
  //   appVersion: "",
  //   createdDate: { startDate: null, endDate: null },
  //   fileName: "",
  //   fileVersion: "",
  // };

  // const [config, setConfig] = useState({
  //   filter: { ...emptyFilterObject },
  //   limit: 10,
  //   orderBy: "createdAt",
  //   orderType: OrderType.DESC,
  //   page: 0,
  // });

  const setConfig = (newConfig: any) => {
    dispatch(configAppFilterSortApplyAction(newConfig));
  };

  const { editModal } = useSelector((state: RootState) => ({
    editModal: state.editModal,
  }));

  // Get configs only once
  useEffect(() => {
    dispatch(
      getAllBrokerType({
        filter: {},
        limit: 0,
        orderBy: "brokerTypeName",
        orderType: "ASC",
        page: 0,
      })
    );
  }, []);

  // Get configs after "generate config"
  useEffect(() => {
    if (!configFileGenerate.loading && !configFileGenerate.error) {
      dispatch(getConfigApplication(config));
    }
  }, [configFileGenerate]);

  useEffect(() => {
    dispatch(getConfigApplication(config));
  }, [config]);

  const handleShowConfigVersionDetail = (configVersionId: string) => {
    if (configVersionId) {
      dispatch(
        getConfigVersion(configVersionId, (configVersion: any) => {
          dispatch(
            editModalOpenAction({
              configVersion,
              type: modalTypeEnum.DISPLAY_CONFIG_VERSION,
            })
          );
        })
      );
    }
  };

  const headCells: ITableHeaderCells[] = [
    { id: "appName", label: "App name" },
    { id: "appVersion", label: "Version" },
    {
      id: "fileName",
      label: "File name",
      value: (row: ITableRow) => (
        <SActiveLink onClick={() => ConfigService.s3ProxyUrl(row.fileId)}>
          {row.fileName}
        </SActiveLink>
      ),
    },
    {
      id: "fileVersion",
      label: "File version",
      value: (row: ITableRow) => (
        <SActiveLink
          title="Click to view version detail"
          onClick={() => {
            handleShowConfigVersionDetail(row.configVersionId);
          }}
        >
          {row.fileVersion}
        </SActiveLink>
      ),
    },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
    },
  ];

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "appName",
        placeholder: "App name",
        taClass: "appName",
      },
      {
        name: "appVersion",
        placeholder: "Version",
        taClass: "version",
      },
      {
        name: "fileName",
        placeholder: "File name",
        taClass: "fileName",
      },
      {
        name: "fileVersion",
        placeholder: "File version",
        taClass: "fileVersion",
      },
      {
        name: "createdDate",
        placeholder: "Created",
        taClass: "createdDate",
        type: FilterType.DATEPICKER,
      },
    ],
    pageName: "configurationAppList",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...configFileApplication,
    },
    name: "application",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      <SSpacer />
      <Filter {...filterConfig} />
      {/* FILTER */}
      <SSpacer />

      {/* TABLE */}
      <Table {...tableProps}></Table>

      {editModal.open && editModal.type === modalTypeEnum.DISPLAY_CONFIG_VERSION && (
        <ModalDisplayConfigVersion />
      )}
    </>
  );
};
