import {
  IGetMQTTClientDetailsActionType,
  TGetMQTTClientDetails,
} from "../../actions/topic/get-mqtt-client-details/types";

interface ITopicMQTTClientDetailsState {
  content: any;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicMQTTClientDetailsState = {
  content: {},
  error: false,
  loading: true,
};

export const topicMQTTClientDetails = (
  state: ITopicMQTTClientDetailsState = initialState,
  action: IGetMQTTClientDetailsActionType
): ITopicMQTTClientDetailsState => {
  switch (action.type) {
    case TGetMQTTClientDetails.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TGetMQTTClientDetails.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TGetMQTTClientDetails.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
