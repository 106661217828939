export enum configTabEnum {
  VERSION,
  APP,
  BROKER,
  HISTORY,
}
export interface IConfigListFilters {
  [key: string]: any;
}

export interface IConfigListingTableConfig {
  [key: string]: any;
  filter: IConfigListFilters;
  limit: number;
  orderBy: string;
  orderType: string;
  page: number;
}

export interface IGetConfigListData {
  content: any[];
  count: number;
}
