import {
  Grid,
  GridItem,
  IconInfo16,
  NumberInput as NumberInputNext,
  Text,
  Textarea,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { popupOpenAction } from "../../actions/popup/actions";
import { getTopicTypeLabel, isValidValueWithoutWarning } from "../../helper/util";
import {
  localGroupBridgeTypeEnum,
  maxPayloadSizeValues,
  offloadType,
  qosEnum,
  throttleThresholdFactorValues,
  topicStatusEnum,
  topicTypeEnum,
} from "../../models/ITopicConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SModalStackItem,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";

// tslint:disable-next-line: cyclomatic-complexity
export const ModalEditTopicPage2 = (props: any) => {
  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();

  const [isAccessAnonymousDisabled, setIsAccessAnonymousDisabled] = useState(false);
  const [isSecureDisabled, setIsSecureDisabled] = useState(false);
  const [isMulticastDisabled, setIsMulticastDisabled] = useState(false);
  const [isLocalGroup, setIsLocalGroup] = useState(false);

  const priorities: any[] = [
    {
      label: <span className="ta-dropdown-priority-1">1</span>,
      value: "1",
    },
    {
      label: <span className="ta-dropdown-priority-2">2</span>,
      value: "2",
    },
  ];

  const qosLevelList: any[] = [
    {
      label: <span className="ta-dropdown-qosLevel-0">{qosEnum.FIRE_AND_FORGET}</span>,
      value: qosEnum.FIRE_AND_FORGET,
    },
    {
      label: <span className="ta-dropdown-qosLevel-1">{qosEnum.ATLEAST_ONCE}</span>,
      value: qosEnum.ATLEAST_ONCE,
    },
  ];

  const offloadTypeList: any[] = [
    {
      label: <span className="ta-dropdown-offloadType-deferred">{offloadType.DEFERRED}</span>,
      value: offloadType.DEFERRED,
    },
    {
      label: <span className="ta-dropdown-offloadType-live">{offloadType.LIVE}</span>,
      value: offloadType.LIVE,
    },
  ];

  const localGroupBridgeTypes: any[] = [
    {
      label: <span className="ta-dropdown-local-group-bridge-option-astrova">Astrova</span>,
      value: localGroupBridgeTypeEnum.ASTROVA,
    },
  ];

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return;
    }
    if (e.target.value.length > (maxChar || 250)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleNumberInput = (value: any, field: string) => {
    let fieldValue = value;
    if (isNaN(value)) {
      fieldValue = 0;
    }
    if (value < 0) {
      fieldValue = Math.abs(value);
    }
    setUpdatedConfig({ ...props.config, [field]: fieldValue });
  };

  const handleSecureCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldIsSecure = props.config.isSecure;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldIsSecure ? false : props.config.accessAnonymous,
      isSecure: !oldIsSecure,
      multicast: !oldIsSecure ? false : props.config.multicast,
    });
  };

  const handleAccessAnonymousCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldAccessAnonymous = props.config.accessAnonymous;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldAccessAnonymous,
      isSecure: !oldAccessAnonymous ? false : props.config.isSecure,
      multicast: !oldAccessAnonymous ? false : props.config.multicast,
    });
  };

  const handleMulticastCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldMulticast = props.config.multicast;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldMulticast ? false : props.config.accessAnonymous,
      isSecure: !oldMulticast ? false : props.config.isSecure,
      multicast: !oldMulticast,
    });
  };

  const handleDefaultRetainRequiredCheckboxClick = (e: any) => {
    setUpdatedConfig({
      ...props.config,
      retainRequired: !props.config.retainRequired,
    });
  };

  const handleLocalGroupCheckboxClick = (e: any) => {
    setIsLocalGroup(!isLocalGroup);

    // setUpdatedConfig({
    //   ...props.config,
    //   localGroup: props.config.localGroup > 0 ? 0 : 1,
    // });
  };

  const handleLocalGroupBridgeTypeChange = (value: any) => {
    setUpdatedConfig({ ...props.config, localGroupBridge: value });
  };

  const handlePriorityDropdown = (value: any) => {
    setUpdatedConfig({ ...props.config, priority: value });
  };

  const handleQosDropdown = (value: any) => {
    setUpdatedConfig({ ...props.config, qosLevel: value });
  };

  const handleOffloadChange = (value: any) => {
    setUpdatedConfig({ ...props.config, offloadType: value });
  };

  const handleLocalGroupInfo = () => {
    dispatch(
      popupOpenAction({
        content: `<div>
                    This allows topic communication to be routed between local seatback group units instead of being routed through central broker.
                  </div>
                  <br>
                  <div>
                    This is only available for units that support this feature, such as Astrova.
                  </div>`,
        isContentHtml: true,
        title: "Local group",
        type: "Info",
      })
    );
  };

  useEffect(() => {
    if (props.config && props.onChange && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }

    // for [UMB-1418] -
    // added logic for existing topics in case there are both (accessAnonymous|isSecure) and multicast checked, all 3 boxes will be disabled.
    // enable accessAnonymous|isSecure in such case to allow user to edit under new rule.
    let retVal = false;
    // accessAnonymous
    retVal = props.config.isSecure || props.config.multicast;
    retVal = props.config.accessAnonymous && props.config.multicast ? false : retVal;
    setIsAccessAnonymousDisabled(retVal);

    // isSecure
    retVal = props.config.accessAnonymous || props.config.multicast;
    retVal = props.config.isSecure && props.config.multicast ? false : retVal;
    setIsSecureDisabled(retVal);

    // multicast
    retVal = props.config.accessAnonymous || props.config.isSecure;
    setIsMulticastDisabled(retVal);
  }, [props.config]);

  useEffect(() => {
    // local group bridge
    setIsLocalGroup(!!props.config.localGroupBridge);
  }, []);

  useEffect(() => {
    if (isLocalGroup) {
      handleLocalGroupBridgeTypeChange(localGroupBridgeTypes[0].value);
    } else {
      if (!!props.config.localGroupBridge) {
        setUpdatedConfig({ ...props.config, localGroupBridge: "" });
      }
    }
  }, [isLocalGroup]);

  const getMaxPayloadsizeWarningText = (value: number) =>
    value > maxPayloadSizeValues.max
      ? `Error: payload size greater than ${maxPayloadSizeValues.max} KB is not allowed.`
      : value > maxPayloadSizeValues.warning
        ? `Warning: payload size greater than ${maxPayloadSizeValues.warning} KB is not recommended.`
        : ``;

  const getThrottleThresholdFactorWarningText = (value: number) =>
    value > throttleThresholdFactorValues.max
      ? `Error: more than ${throttleThresholdFactorValues.max}/min is not allowed.`
      : value > throttleThresholdFactorValues.warning
        ? `Warning: more than ${throttleThresholdFactorValues.warning}/min is not recommended.`
        : ``;

  return (
    <>
      <Text variant="captionText" className="ta-modal-text-topic">
        Topic: <span>{props.config && props.config.topicName}</span>
      </Text>

      <Text variant="captionText" className="ta-modal-text-topic">
        Type: <span>{props.config && getTopicTypeLabel(props.config.topicType)}</span>
      </Text>

      <Grid columns="1fr 1fr">
        <SFlexItem>
          <div className="ta-modal-input-secure">
            <Checkbox
              onClick={handleSecureCheckboxClick}
              marked={props.config.isSecure ? CheckboxOptions.selected : CheckboxOptions.unselected}
              disabled={
                // (currentUser.data.role === APPLICATION_OWNER &&
                // ((currentUser.data.role === APPLICATION_OWNER ||
                //   currentUser.data.role === APPROVER) &&
                (currentUser.data.role !== ADMINISTRATOR &&
                  props.config.status !== topicStatusEnum.DRAFT) ||
                // props.config.accessAnonymous || props.config.multicast
                isSecureDisabled ||
                props.config.topicType === topicTypeEnum.A2G
              }
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-secure">
            Secure
          </Text>
        </SFlexItem>

        {props.config.topicType === topicTypeEnum.AIR && (
          <GridItem>
            <SFlexItem>
              <div className="ta-modal-input-multicast">
                <Checkbox
                  onClick={handleMulticastCheckboxClick}
                  marked={
                    props.config.multicast ? CheckboxOptions.selected : CheckboxOptions.unselected
                  }
                  disabled={
                    // currentUser.data.role === APPLICATION_OWNER &&
                    // ((currentUser.data.role === APPLICATION_OWNER ||
                    //   currentUser.data.role === APPROVER) &&
                    (currentUser.data.role !== ADMINISTRATOR &&
                      props.config.status !== topicStatusEnum.DRAFT) ||
                    // props.config.accessAnonymous || props.config.isSecure
                    isMulticastDisabled
                  }
                />
              </div>
              <SVerticalSpacer />
              <Text variant="captionText" className="ta-modal-input-caption-multicast">
                Multicast
              </Text>
            </SFlexItem>
          </GridItem>
        )}
      </Grid>

      {props.config.topicType === topicTypeEnum.AIR && currentUser.data.role === ADMINISTRATOR && (
        <SFlexItem>
          <div className="ta-modal-input-access-anonymous">
            <Checkbox
              onClick={handleAccessAnonymousCheckboxClick}
              marked={
                props.config.accessAnonymous ? CheckboxOptions.selected : CheckboxOptions.unselected
              }
              disabled={
                // (currentUser.data.role === APPLICATION_OWNER &&
                // ((currentUser.data.role === APPLICATION_OWNER ||
                //   currentUser.data.role === APPROVER) &&
                (currentUser.data.role !== ADMINISTRATOR &&
                  props.config.status !== topicStatusEnum.DRAFT) ||
                // props.config.isSecure || props.config.multicast
                isAccessAnonymousDisabled
              }
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-access-anonymous">
            Allow anonymous access
          </Text>
        </SFlexItem>
      )}

      <SFlexItem>
        <div className="ta-modal-input-default-retain-required">
          <Checkbox
            onClick={handleDefaultRetainRequiredCheckboxClick}
            marked={
              props.config.retainRequired ? CheckboxOptions.selected : CheckboxOptions.unselected
            }
            // disabled={false}
          />
        </div>
        <SVerticalSpacer />
        <Text variant="captionText" className="ta-modal-input-default-retain-required">
          Default retain required (can be overwritten when creating a publisher)
        </Text>
      </SFlexItem>

      <Grid
        autoFlow="column"
        columns={props.config.topicType === topicTypeEnum.AIR ? "auto" : "1fr 1fr"}
      >
        {props.config.topicType === topicTypeEnum.A2G && (
          <GridItem>
            <DropdownInput
              label="Offload type:"
              name="offload"
              onChange={handleOffloadChange}
              options={offloadTypeList}
              selected={props.config && props.config.offloadType}
              disabled={
                // currentUser.data.role === APPLICATION_OWNER &&
                // (currentUser.data.role === APPLICATION_OWNER ||
                //   currentUser.data.role === APPROVER) &&
                currentUser.data.role !== ADMINISTRATOR &&
                props.config.status !== topicStatusEnum.DRAFT
              }
            />
          </GridItem>
        )}

        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-ttl">
            TTL:
          </Text>
          <NumberInputNext
            value={props.config.ttl}
            min={0}
            disabled={
              // currentUser.data.role === APPLICATION_OWNER &&
              // (currentUser.data.role === APPLICATION_OWNER || currentUser.data.role === APPROVER) &&
              currentUser.data.role !== ADMINISTRATOR &&
              props.config.status !== topicStatusEnum.DRAFT
            }
            onChange={(value: any) => {
              handleNumberInput(value, "ttl");
            }}
          />
        </GridItem>
      </Grid>

      <Grid
        autoFlow="column"
        columns={props.config.topicType === topicTypeEnum.AIR ? "auto" : "1fr 1fr"}
      >
        {props.config.topicType === topicTypeEnum.A2G && (
          <GridItem>
            <DropdownInput
              label="Priority:"
              name="priority"
              onChange={handlePriorityDropdown}
              options={priorities}
              selected={props.config && props.config.priority}
              disabled={
                // currentUser.data.role === APPLICATION_OWNER &&
                // (currentUser.data.role === APPLICATION_OWNER ||
                //   currentUser.data.role === APPROVER) &&
                currentUser.data.role !== ADMINISTRATOR &&
                props.config.status !== topicStatusEnum.DRAFT
              }
            />
          </GridItem>
        )}

        <GridItem>
          <DropdownInput
            label="QoS level:"
            name="qosLevel"
            onChange={handleQosDropdown}
            options={qosLevelList}
            selected={props.config && props.config.qosLevel}
            disabled={
              // currentUser.data.role === APPLICATION_OWNER &&
              // ((currentUser.data.role === APPLICATION_OWNER ||
              //   currentUser.data.role === APPROVER) &&
              (currentUser.data.role !== ADMINISTRATOR &&
                props.config.status !== topicStatusEnum.DRAFT) ||
              props.config.topicType === topicTypeEnum.A2G
            }
          />
        </GridItem>
      </Grid>

      {props.config.topicType === topicTypeEnum.A2G && (
        <>
          <NumberInput
            label={
              <span>
                Max payload size (KB){" "}
                <span style={{ color: "red" }}>
                  {getMaxPayloadsizeWarningText(props.config.maxPayloadSize)}
                </span>
              </span>
            }
            name="max-payload-size"
            placeholder="Maximum payload size"
            valid={isValidValueWithoutWarning(props.config.maxPayloadSize, maxPayloadSizeValues)}
            value={props.config.maxPayloadSize}
            min={maxPayloadSizeValues.min}
            max={maxPayloadSizeValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "maxPayloadSize");
            }}
            disabled={
              // (currentUser.data.role === APPLICATION_OWNER || currentUser.data.role === APPROVER) &&
              currentUser.data.role !== ADMINISTRATOR &&
              props.config.status !== topicStatusEnum.DRAFT
            }
          />

          <NumberInput
            label={
              <span>
                Max number of messages published per minute{" "}
                <span style={{ color: "red" }}>
                  {getThrottleThresholdFactorWarningText(props.config.throttleThresholdFactor)}
                </span>
              </span>
            }
            name="throtting-threshold-factor"
            placeholder="Throttling threshold factor"
            valid={isValidValueWithoutWarning(
              props.config.throttleThresholdFactor,
              throttleThresholdFactorValues
            )}
            value={props.config.throttleThresholdFactor}
            min={throttleThresholdFactorValues.min}
            max={throttleThresholdFactorValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "throttleThresholdFactor");
            }}
            disabled={
              // (currentUser.data.role === APPLICATION_OWNER || currentUser.data.role === APPROVER) &&
              currentUser.data.role !== ADMINISTRATOR &&
              props.config.status !== topicStatusEnum.DRAFT
            }
          />
        </>
      )}

      {props.config.topicType === topicTypeEnum.AIR && (
        <SModalStackItem className="ta-modal-auth-container-cred">
          <SFlexItem>
            <Text variant="captionText" className="ta-modal-label-local-group">
              For units that support local group bridging:
            </Text>
            <SVerticalSpacer />
            <SInfoLabelContainer className="ta-modal-info-local-group">
              <SInfoLabel>
                <IconInfo16 onClick={handleLocalGroupInfo} />
              </SInfoLabel>{" "}
            </SInfoLabelContainer>
          </SFlexItem>

          <SFlexItem>
            <div className="ta-modal-checkbox-local-group">
              <Checkbox
                onClick={handleLocalGroupCheckboxClick}
                marked={isLocalGroup ? CheckboxOptions.selected : CheckboxOptions.unselected}
                disabled={
                  currentUser.data.role !== ADMINISTRATOR &&
                  props.config.status !== topicStatusEnum.DRAFT
                }
              />
            </div>
            <SVerticalSpacer />
            <Text variant="captionText" className="ta-modal-checkbox-label-local-group">
              Local group bridging
            </Text>
          </SFlexItem>

          {isLocalGroup && (
            <DropdownInput
              label="Local group bridge type:"
              name="bridge-type"
              onChange={handleLocalGroupBridgeTypeChange}
              options={localGroupBridgeTypes}
              selected={props.config && props.config.localGroupBridge}
              disabled={
                currentUser.data.role !== ADMINISTRATOR &&
                props.config.status !== topicStatusEnum.DRAFT
              }
            />
          )}
        </SModalStackItem>
      )}

      <Text variant="captionText" className="ta-modal-input-caption-description">
        Description:
      </Text>
      <Textarea
        className="ta-modal-input-description"
        placeholder="Description"
        value={props.config.description}
        onChange={(e: any) => {
          handleTextInput(e, "description", 1000);
        }}
        disabled={
          // currentUser.data.role === APPLICATION_OWNER &&
          // (currentUser.data.role === APPLICATION_OWNER || currentUser.data.role === APPROVER) &&
          currentUser.data.role !== ADMINISTRATOR && props.config.status !== topicStatusEnum.DRAFT
        }
      />
    </>
  );
};
