import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TDeleteAssociationBulk {
  ERROR = "TOPIC_DELETEASSOCIATIONBULK_ERROR",
  REQUEST = "TOPIC_DELETEASSOCIATIONBULK_REQUEST",
  SUCCESS = "TOPIC_DELETEASSOCIATIONBULK_SUCCESS",
}

export interface IDeleteAssociationBulkRequestAction
  extends IReduxBaseAction<TDeleteAssociationBulk> {
  type: TDeleteAssociationBulk.REQUEST;
}

export interface IDeleteAssociationBulkSuccessAction
  extends IReduxBaseAction<TDeleteAssociationBulk> {
  payload: any;
  type: TDeleteAssociationBulk.SUCCESS;
}

export interface IDeleteAssociationBulkErrorAction
  extends IReduxBaseAction<TDeleteAssociationBulk> {
  payload: any;
  type: TDeleteAssociationBulk.ERROR;
}

export type IDeleteAssociationBulkActionTypes =
  | IDeleteAssociationBulkErrorAction
  | IDeleteAssociationBulkRequestAction
  | IDeleteAssociationBulkSuccessAction;
