import { Dispatch } from "redux";

import { IGetTenantListData, ITenantListTableConfig } from "../../../models/ITenant";
import { TenantService } from "../../../services/TenantService";

import { TenantListType, TTenantListActions } from "./types";

export const tenantResetAction = (): TTenantListActions => ({
  type: TenantListType.RESET,
});

export const tenantRequestAction = (): TTenantListActions => ({
  type: TenantListType.REQUEST,
});

export const tenantSuccessAction = (data: any): TTenantListActions => ({
  payload: data,
  type: TenantListType.SUCCESS,
});

export const tenantErrorAction = (error: string): TTenantListActions => ({
  payload: error,
  type: TenantListType.ERROR,
});

export const getTenantList = (conf: ITenantListTableConfig) => (
  dispatch: Dispatch<TTenantListActions>
) => {
  dispatch(tenantRequestAction());
  TenantService.getTenantList(conf).then(
    (response: { data: { data: IGetTenantListData } }) => {
      dispatch(tenantSuccessAction(response.data.data));
    },
    (error: any) => dispatch(tenantErrorAction(error))
  );
};
