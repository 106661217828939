import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetBrokerTypeDetails {
  ERROR = "BROKER_TYPE_GETDETAILS_ERROR",
  REQUEST = "BROKER_TYPE_GETDETAILS_REQUEST",
  SUCCESS = "BROKER_TYPE_GETDETAILS_SUCCESS",
}

export interface IGetBrokerTypeDetailsRequestAction
  extends IReduxBaseAction<TGetBrokerTypeDetails> {
  type: TGetBrokerTypeDetails.REQUEST;
}

export interface IGetBrokerTypeDetailsSuccessAction
  extends IReduxBaseAction<TGetBrokerTypeDetails> {
  payload: any;
  type: TGetBrokerTypeDetails.SUCCESS;
}

export interface IGetBrokerTypeDetailsErrorAction extends IReduxBaseAction<TGetBrokerTypeDetails> {
  payload: any;
  type: TGetBrokerTypeDetails.ERROR;
}

export type IGetBrokerTypeDetailsActionType =
  | IGetBrokerTypeDetailsRequestAction
  | IGetBrokerTypeDetailsSuccessAction
  | IGetBrokerTypeDetailsErrorAction;
