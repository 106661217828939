const REDIRECT_URL = "https://login.nextcloud.aero/";

export const isExpired = (expiration: string): boolean => {
  if (+(new Date().getTime() / 1000).toFixed(0) < +expiration) {
    return false;
  }

  return true;
};

export const clearSession = () => {
  localStorage.removeItem("UMB_AT");
  localStorage.removeItem("UMB_RT");
  localStorage.removeItem("CLIENT_ID");
  localStorage.removeItem("PAC_APP_CLIENT_ID");
  localStorage.removeItem("UMB_IT");
};

export const redirect = () => {
  (window as any).location.assign(REDIRECT_URL);
};
