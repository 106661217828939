import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum AddDestinationRouteTypes {
  ERROR = "ADD_DESTINATION_ROUTE_ERROR",
  REQUEST = "ADD_DESTINATION_ROUTE_REQUEST",
  SUCCESS = "ADD_DESTINATION_ROUTE_SUCCESS",
}

export interface IAddDestinationRouteRequestAction
  extends IReduxBaseAction<AddDestinationRouteTypes> {
  payload: any;
  type: AddDestinationRouteTypes.REQUEST;
}

export interface IAddDestinationRouteSuccessAction
  extends IReduxBaseAction<AddDestinationRouteTypes> {
  payload: any;
  type: AddDestinationRouteTypes.SUCCESS;
}

export interface IAddDestinationRouteErrorAction
  extends IReduxBaseAction<AddDestinationRouteTypes> {
  payload: any;
  type: AddDestinationRouteTypes.ERROR;
}

export type TAddDestinationRouteActions =
  | IAddDestinationRouteErrorAction
  | IAddDestinationRouteRequestAction
  | IAddDestinationRouteSuccessAction;
