import { Dispatch } from "redux";

import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import { AppDestinationRouteTypeEnum } from "../../../models/IDestinationRoute";
import { ApplicationService } from "../../../services/ApplicationService";

import { DestinationRouteListType, TDestinationRouteListActions } from "./types";

export const DestinationRouteListRequestAction = (): TDestinationRouteListActions => ({
  type: DestinationRouteListType.REQUEST,
});

export const DestinationRouteListSuccessAction = (data: any): TDestinationRouteListActions => ({
  payload: data,
  type: DestinationRouteListType.SUCCESS,
});

export const DestinationRouteListErrorAction = (error: string): TDestinationRouteListActions => ({
  payload: error,
  type: DestinationRouteListType.ERROR,
});

export const DestinationRouteListClearAction = (): TDestinationRouteListActions => ({
  type: DestinationRouteListType.CLEAR,
});

export const getDestinationRouteList = (appId: string, destId: string, conf: any) => (
  dispatch: Dispatch<TDestinationRouteListActions>
) => {
  dispatch(DestinationRouteListRequestAction());
  ApplicationService.getAppDestinationRoutes(appId, destId, conf).then(
    (data: any) => {
      if (data.data && data.data.data && data.data.data.content) {
        data.data.data.content = data.data.data.content.map((routeData: any) => ({
          ...routeData,
          aircraftGroupEnvironment: routeData.aircraftGroup
            ? routeData.aircraftGroup.aircraftGroupEnvironment
            : "",
          aircraftGroupId: routeData.aircraftGroup ? routeData.aircraftGroup.aircraftGroupId : "",
          aircraftGroupName: routeData.aircraftGroup
            ? routeData.aircraftGroup.aircraftGroupName
            : "",
          aircraftGroupType:
            routeData.routeType === AppDestinationRouteTypeEnum.GROUP
              ? routeData.aircraftGroup.aircraftGroupType
              : AircraftGroupTypeEnum.TENANT,
          icao: routeData.tenant ? routeData.tenant.icao : "",
          tenantId: routeData.tenant ? routeData.tenant.tenantId : "",
          tenantName: routeData.tenant ? routeData.tenant.tenantName : "",
        }));
      }
      dispatch(DestinationRouteListSuccessAction(data.data.data));
    },
    (error: any) => dispatch(DestinationRouteListErrorAction(error))
  );
};
