import "sanitize.css/sanitize.css";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { ButtonBase, ButtonSize } from "../Base/ButtonBase";
import colors from "../../colors";
import * as variables from "../../variables";

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      color: ", ";\n      background-color: ", ";\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([
    "\n      font-size: 14px;\n      height: ",
    ";\n      padding-left: ",
    ";\n      padding-right: ",
    ";\n    ",
  ]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([
    "\n      cursor: pointer;\n\n      &:hover {\n        background-color: ",
    ";\n      }\n\n      &:focus {\n        background-color: ",
    ";\n        box-shadow: inset 0 0 0 2px ",
    ",\n          inset 0 0 0 4px ",
    ";\n      }\n\n      &:active {\n        background-color: ",
    ";\n      }\n    ",
  ]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([
    "\n  ",
    "\n\n  color: ",
    ";\n  background-color: ",
    ";\n  width: ",
    ";\n\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n",
  ]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}

var StyledButton = styled.button(
  _templateObject(),
  ButtonBase,
  colors.general.white,
  colors.primary["default"],
  function(props) {
    return props.buttonWidth;
  },
  function(props) {
    return (
      props.disabled === false &&
      css(
        _templateObject2(),
        colors.primary.lighten10,
        colors.primary.lighten10,
        colors.primary.darken20,
        colors.general.white,
        colors.primary.darken20
      )
    );
  },
  function(props) {
    return (
      props.size === ButtonSize.small &&
      css(_templateObject3(), variables.getSize(8), variables.getSize(9), variables.getSize(9))
    );
  },
  function(props) {
    return props.disabled && css(_templateObject4(), colors.greys.darken10, colors.greys.lighten20);
  }
);

var ButtonPrimary = function ButtonPrimary(_ref) {
  var children = _ref.children,
    props = _objectWithoutProperties(_ref, ["children"]);

  return React.createElement(StyledButton, props, children);
};

ButtonPrimary.propTypes = {
  /** Button text */
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([ButtonSize["default"], ButtonSize.small]),
  buttonWidth: PropTypes.string,
  onClick: PropTypes.func,
};
ButtonPrimary.defaultProps = {
  children: "",
  disabled: false,
  size: ButtonSize["default"],
  buttonWidth: "auto",
  onClick: null,
};
ButtonPrimary.displayName = "ButtonPrimary";
export default ButtonPrimary;
