import { ConfigBrokerFilterSortType, TConfigBrokerFilterSortActions } from "./types";

export const configBrokerFilterSortClearAction = (): TConfigBrokerFilterSortActions => ({
  type: ConfigBrokerFilterSortType.CLEAR,
});

export const configBrokerFilterSortResetAction = (): TConfigBrokerFilterSortActions => ({
  type: ConfigBrokerFilterSortType.RESET,
});

export const configBrokerFilterSortApplyAction = (data: any): TConfigBrokerFilterSortActions => ({
  payload: data,
  type: ConfigBrokerFilterSortType.APPLY,
});
