import {
  GetConfigApplicationType,
  TGetConfigApplicationActions,
} from "../../actions/configfile/application/types";

interface IConfigFileApplicationState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IConfigFileApplicationState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const configFileApplication = (
  state: IConfigFileApplicationState = initialState,
  action: TGetConfigApplicationActions
): IConfigFileApplicationState => {
  switch (action.type) {
    case GetConfigApplicationType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetConfigApplicationType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetConfigApplicationType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
