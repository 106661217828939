import { Dispatch } from "redux";

import { TenantService } from "../../../services/TenantService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  ISearchTenantActionType,
  ISearchTenantErrorAction,
  ISearchTenantRequestAction,
  ISearchTenantSuccessAction,
  TSearchTenant,
} from "./types";

export const searchTenantRequestAction = (): ISearchTenantRequestAction => ({
  type: TSearchTenant.REQUEST,
});
export const searchTenantSuccessAction = (data: any): ISearchTenantSuccessAction => ({
  payload: data,
  type: TSearchTenant.SUCCESS,
});
export const searchTenantErrorAction = (error: any): ISearchTenantErrorAction => ({
  payload: error,
  type: TSearchTenant.ERROR,
});

// GET APPLICATION
export const searchTenant = (icao: any, cbSuccess?: any, cbError?: any) => (
  dispatch: Dispatch<ISearchTenantActionType | TPopupActions>
) => {
  dispatch(searchTenantRequestAction());
  TenantService.searchTenantByIcao(icao).then(
    (data: { data: any }) => {
      dispatch(searchTenantSuccessAction(data.data));
      if (cbSuccess) {
        cbSuccess(data.data.data.content.tenantId);
      }
    },
    (error: any) => {
      dispatch(searchTenantErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "Unknwon error",
          title: "Error",
          type: "Info",
        })
      );
      if (cbError) {
        cbError();
      }
    }
  );
};
