import { Dispatch } from "redux";

import { UserService } from "../../../services/UserService";
import store from "../../../store";

import {
  CurrentUserTypes,
  IGetLoggedInUserErrorAction,
  IGetLoggedInUserSuccessAction,
  TGetLoggedInUserType,
} from "./types";

export const getLoggedinUserSuccessAction = (data: any): IGetLoggedInUserSuccessAction => ({
  payload: data,
  type: CurrentUserTypes.SUCCESS,
});

export const getLoggedinUserErrorAction = (error: any): IGetLoggedInUserErrorAction => ({
  payload: error,
  type: CurrentUserTypes.ERROR,
});

export const getLoggedinUser = () => (dispatch: Dispatch<TGetLoggedInUserType>) => {
  // Only get loggedinUser if not stored already
  if (store.getState().currentUser.data && !store.getState().currentUser.data.content) {
    UserService.getLoggedinUser().then(
      data => {
        dispatch(getLoggedinUserSuccessAction(data));
      },
      error => {
        dispatch(getLoggedinUserErrorAction(error));
      }
    );
  }
};
