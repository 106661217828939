import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditApplicationTypes,
  IEditApplicationErrorAction,
  IEditApplicationRequestAction,
  IEditApplicationSuccessAction,
  TSetEditApplicationActions,
} from "./types";

export const EditApplicationRequestAction = (data: any): IEditApplicationRequestAction => ({
  payload: data,
  type: EditApplicationTypes.REQUEST,
});
export const EditApplicationSuccessAction = (data: any): IEditApplicationSuccessAction => ({
  payload: data,
  type: EditApplicationTypes.SUCCESS,
});
export const EditApplicationErrorAction = (error: any): IEditApplicationErrorAction => ({
  payload: error,
  type: EditApplicationTypes.ERROR,
});

export const EditApplication = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetEditApplicationActions | TEditModalActions>
) => {
  dispatch(EditApplicationRequestAction(config));
  ApplicationService.updateApplication(config).then(
    (data: { data: any }) => {
      dispatch(EditApplicationSuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: "Application updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data.data.content.appClientId);
    },
    (error: any) => {
      dispatch(EditApplicationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
