import { Checkbox as NextCheckbox } from "next-components";
import React from "react";

export enum CheckboxOptions {
  unselected = "unselected",
  selected = "selected",
  semi = "semi",
}

interface ICheckbox extends React.HTMLAttributes<HTMLInputElement> {
  disabled?: boolean;
  marked: CheckboxOptions;
}

const MarkedCheckedMap = {
  selected: true,
  semi: false,
  unselected: false,
};

export const Checkbox = (props: ICheckbox) => (
  <NextCheckbox {...props} checked={MarkedCheckedMap[props.marked]} />
);
