import { Chip, Grid, StackItem } from "next-components";
import styled from "styled-components";

export const SModalContainer = styled(Grid)`
  width: ${props => (props.theme && props.theme.modal && props.theme.modal.width) ? props.theme.modal.width : "600px"};
  padding: 16px;
`;

export const SModalHeader = styled.header`
  margin-bottom: 4px;
  position: relative;
`;

export const SModalStackItem = styled(StackItem)`
  display: grid;
  height: auto;
  padding: 10px;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
`;

export const SChip = styled(Chip)`
  & > div:first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
