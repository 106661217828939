/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum GetGroundConfigDeployType {
  REQUEST = "DEPLOY_GROUND_CONFIG_REQUEST",
  SUCCESS = "DEPLOY_GROUND_CONFIG_SUCCESS",
  ERROR = "DEPLOY_GROUND_CONFIG_ERROR",
}

export interface IGroundConfigDeployRequestAction
  extends IReduxBaseAction<GetGroundConfigDeployType> {
  type: GetGroundConfigDeployType.REQUEST;
}

export interface IGroundConfigDeploySuccessAction
  extends IReduxBaseAction<GetGroundConfigDeployType> {
  payload: any;
  type: GetGroundConfigDeployType.SUCCESS;
}

export interface IGroundConfigDeployErrorAction
  extends IReduxBaseAction<GetGroundConfigDeployType> {
  payload: string;
  type: GetGroundConfigDeployType.ERROR;
}

export type TGetGroundConfigDeployActions =
  | IGroundConfigDeployRequestAction
  | IGroundConfigDeploySuccessAction
  | IGroundConfigDeployErrorAction;
