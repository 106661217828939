import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, withRouter } from "react-router-dom";

import { Authentication } from "./Authentication";
import { Header } from "./components/Header";
import { Popup } from "./components/Popup";
import { RouteAuthenticated } from "./components/RouteAuthenticated";
import { RouteUnauthenticated } from "./components/RouteUnauthenticated";
import store from "./store";
import { AppContainer, GlobalStyle, SContainer } from "./styles/styles";

const HeaderWithRouter = withRouter(Header);

const App: React.FC = () => (
  <Provider store={store}>
    <GlobalStyle />
    <>
      <BrowserRouter>
        <AppContainer>
          {/* HEADER */}
          <HeaderWithRouter />
          {/* BODY */}
          <SContainer>
            <Authentication
              authenticated={RouteAuthenticated}
              unauthenticated={RouteUnauthenticated}
            />
          </SContainer>
        </AppContainer>
        {/* INFO POPUP */}
        <Popup />
      </BrowserRouter>
    </>
  </Provider>
);

export default App;
