import { ButtonGroup } from "@material-ui/core";
import { ButtonIcon, Grid, GridItem, Heading, IconArrowLeft32 } from "next-components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  selectAircraftEnv,
  updateAircraftEnv,
} from "../../actions/aircraft/aircraft-env/aircraft-env-slice";
import { useGetTenantQuery, useLazyProvisionQuery } from "../../actions/tenant/api/tenant-api";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { isCurrentUserRole } from "../../helper/util";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../models/IDestination";
import { tabTenantDetailsEnum } from "../../models/ITenantDetails";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SButton } from "../../styles/buttons";
import {
  SFlexContainer,
  SFlexItem,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { PageError, PageLoading } from "../PageState";

import { AircraftGroupList } from "./AircraftDetails/AircraftGroupList";
import { AircraftList } from "./AircraftDetails/AircraftList";
import { RackList } from "./AircraftDetails/RackList";
import { AirlineChangeHistory } from "./AirlineDetails/AirlineChangeHistory";
import { AirlineDetailsTabs } from "./AirlineDetails/AirlineDetailsTabs";

export const AirlineDetails = ({ match }: { match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const activeEnv = useSelector(selectAircraftEnv);

  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));

  const [activeTab, setActiveTab] = useState(
    activeEnv === GroundEnvironmentTypeEnum.INTEGRATION
      ? tabTenantDetailsEnum.RACKS
      : tabTenantDetailsEnum.AIRCRAFTS
  );

  const {
    data: tenantDetails,
    error: tenantDetailsError,
    isFetching: tenantDetailsFetching,
    isLoading: tenantDetailsLoading,
    refetch: refetchTenantDetails,
  } = useGetTenantQuery(match.params.tenantId);

  const [provision, { error: provisioningError, isFetching: provisionFetching }] =
    useLazyProvisionQuery();

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link to="/groundsideConfiguration" className="ta-tenant-details-button-back">
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-tenant-details-title">Airline details</SPageHeading>
          </GridItem>
        </Grid>
        {/* ERROR */}

        {tenantDetailsLoading && <PageLoading />}

        {tenantDetailsError && <PageError />}

        {/* Airline DETAILS */}
        {tenantDetails && (
          <>
            <Grid>
              <GridItem>
                <SFlexContainer justifyContent="space-between">
                  <SFlexItem>
                    <Heading variant="heading2">
                      <span className={getTAClass("tenant-details", TA_TYPES.TITLE, "tenant-name")}>
                        {tenantDetails.tenantName}
                      </span>
                    </Heading>
                  </SFlexItem>
                </SFlexContainer>
              </GridItem>
            </Grid>

            <SSpacer />

            <Grid>
              <GridItem>
                <DataDetailLabel
                  title="ICAO"
                  value={tenantDetails.icao ? tenantDetails.icao.toUpperCase() : ""}
                  className={getTAClass("tenant-details", TA_TYPES.TEXT, "icao")}
                />
              </GridItem>
              <GridItem>
                <DataDetailLabel
                  title="Description"
                  value={tenantDetails.description || "No description"}
                  className={getTAClass("tenant-details", TA_TYPES.TEXT, "description")}
                />
              </GridItem>

              <GridItem>
                <DataDetailLabel
                  title="Provisioning group"
                  value={
                    <>
                      <div>
                        {provisionFetching || tenantDetailsFetching ? (
                          "Refreshing..."
                        ) : !tenantDetails.iotThingGroupName ? (
                          <span>Airline not provisioned.</span>
                        ) : (
                          tenantDetails.iotThingGroupName
                        )}
                      </div>
                      &nbsp;&nbsp;
                      {
                        <SButton
                          onClick={() =>
                            provision(match.params.tenantId).finally(refetchTenantDetails)
                          }
                          disabled={!isCurrentUserRole(currentUser, [ADMINISTRATOR])}
                        >
                          Provision Airline
                        </SButton>
                      }
                    </>
                  }
                  className={getTAClass("tenant-details", TA_TYPES.TEXT, "IOT Group Name")}
                />
                {!!provisioningError && (
                  <div style={{ color: "red", fontWeight: 500, padding: "1em 0" }}>
                    IOT provisioning failed: {(provisioningError as any).data.message}
                  </div>
                )}
              </GridItem>
            </Grid>

            <SSpacer />

            <ButtonGroup>
              <SButton
                isPrimary={activeEnv === GroundEnvironmentTypeEnum.PRODUCTION}
                onClick={() => {
                  dispatch(updateAircraftEnv(GroundEnvironmentTypeEnum.PRODUCTION));
                  setActiveTab(tabTenantDetailsEnum.AIRCRAFTS);
                }}
              >
                Production
              </SButton>
              <SButton
                isPrimary={activeEnv === GroundEnvironmentTypeEnum.INTEGRATION}
                onClick={() => {
                  dispatch(updateAircraftEnv(GroundEnvironmentTypeEnum.INTEGRATION));
                  setActiveTab(tabTenantDetailsEnum.RACKS);
                }}
              >
                Integration
              </SButton>
            </ButtonGroup>
          </>
        )}

        <SSpacer />

        {/* TABS */}
        <AirlineDetailsTabs
          activeTab={activeTab}
          groupEnv={activeEnv}
          onChange={(tab: tabTenantDetailsEnum) => {
            setActiveTab(tab);
          }}
        />

        <SSpacer />

        {/* TABLE */}
        {!tenantDetailsLoading && tenantDetails && (
          <>
            {activeTab === tabTenantDetailsEnum.AIRCRAFTS && (
              <AircraftList tenant={tenantDetails} />
            )}
            {activeTab === tabTenantDetailsEnum.RACKS && <RackList tenant={tenantDetails} />}
            {activeTab === tabTenantDetailsEnum.GROUPS && (
              <AircraftGroupList
                tenant={tenantDetails}
                groupType={AircraftGroupTypeEnum.TENANT}
                groupEnv={GroundEnvironmentTypeEnum.PRODUCTION}
              />
            )}
            {activeTab === tabTenantDetailsEnum.GROUPS_INT && (
              <AircraftGroupList
                tenant={tenantDetails}
                groupType={AircraftGroupTypeEnum.TENANT}
                groupEnv={GroundEnvironmentTypeEnum.INTEGRATION}
              />
            )}
            {activeTab === tabTenantDetailsEnum.HISTORY && (
              <AirlineChangeHistory tenant={tenantDetails} userData={currentUser.data} />
            )}
          </>
        )}
      </SPageContainer>
    </>
  );
};
