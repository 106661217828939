import { Dispatch } from "redux";

import { TenantService } from "../../../services/TenantService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateTenantTypes,
  ICreateTenantErrorAction,
  ICreateTenantRequestAction,
  ICreateTenantSuccessAction,
  TCreateTenantActions,
} from "./types";

export const CreateTenantRequestAction = (data: any): ICreateTenantRequestAction => ({
  payload: data,
  type: CreateTenantTypes.REQUEST,
});
export const CreateTenantSuccessAction = (data: any): ICreateTenantSuccessAction => ({
  payload: data,
  type: CreateTenantTypes.SUCCESS,
});
export const CreateTenantErrorAction = (error: any): ICreateTenantErrorAction => ({
  payload: error,
  type: CreateTenantTypes.ERROR,
});

export const createTenant = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreateTenantActions | TEditModalActions>
) => {
  dispatch(CreateTenantRequestAction(config));
  TenantService.createTenant(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateTenantSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Airline created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb(data.data.data.content.tenantId);
    },
    (error: any) => {
      dispatch(CreateTenantErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
