import { Grid, GridItem, ProgressIndicatorLinear, Text } from "next-components";
import React, { useEffect, useState } from "react";
import DiffViewer, { DiffMethod } from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { getPayload } from "../../actions/payload/get/actions";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SNextFixedHeightContainer,
  SOverlay,
  SPayloadSchema,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextWrapper,
} from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalDisplayHistoryItems = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal, items, payloadGet } = useSelector((state: any) => ({
    editModal: state.editModal,
    items: state.editModal.popupData.items,
    payloadGet: state.payloadGet,
  }));

  const [valueBefore, setValueBefore] = useState("");
  const [valueAfter, setValueAfter] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Change history details");

  useEffect(() => {
    if (editModal.popupData.title && editModal.popupData.title.length > 0) {
      setDialogTitle(editModal.popupData.title);
    }
  }, [editModal]);

  useEffect(() => {
    if (items && items.length > 0) {
      if (
        items[0].topicPayloadDetailBefore &&
        items[0].topicPayloadDetailBefore.payloadMasterId &&
        items[0].topicPayloadDetailBefore.payloadMasterId.length > 0
      ) {
        dispatch(
          getPayload(items[0].topicPayloadDetailBefore.payloadMasterId, (payload: any) => {
            setValueBefore(payload.payloadSchema);
          })
        );
      }
      if (
        items[0].topicPayloadDetailAfter &&
        items[0].topicPayloadDetailAfter.payloadMasterId &&
        items[0].topicPayloadDetailAfter.payloadMasterId.length > 0
      ) {
        dispatch(
          getPayload(items[0].topicPayloadDetailAfter.payloadMasterId, (payload: any) => {
            setValueAfter(payload.payloadSchema);
          })
        );
      }
    }
  }, [items]);

  const modalTheme = {
    modal: {
      width: "1024px",
    },
  };

  // const diffViewerStyles = {
  //   // variables: {
  //   //   dark: {
  //   //     highlightBackground: '#fefed5',
  //   //     highlightGutterBackground: '#ffcd3c',
  //   //   },
  //   // },
  //   // line: {
  //   //   padding: '10px 2px',
  //   //   '&:hover': {
  //   //     background: '#a26ea1',
  //   //   },
  //   // },
  //   diffContainer: {
  //     height: '600px',
  //   }
  // };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {payloadGet.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        <SSpacer height="4px" />

        {editModal.popupData.topicName && editModal.popupData.topicName.length > 0 && (
          <SFlexContainer justifyContent="flex-start">
            <STextDataValue>Topic name: {editModal.popupData.topicName}</STextDataValue>
          </SFlexContainer>
        )}

        <SSpacer height="4px" />

        {/* <Grid columns="1fr 1fr">
          <GridItem>
            <STextDataTitle>Previous value</STextDataTitle>
            <SSpacer height="8px" />
            <SPayloadSchema
              placeholder="Before"
              readonly={true}
              value={
                payloadGet.loading
                  ? "loading..."
                  : valueBefore.length > 0
                  ? valueBefore
                  : "payload data not available"
              }
            />
          </GridItem>

          <GridItem>
            <STextDataTitle>Current value</STextDataTitle>
            <SSpacer height="8px" />
            <SPayloadSchema
              placeholder="After"
              readonly={true}
              value={
                payloadGet.loading
                  ? "loading..."
                  : valueAfter.length > 0
                  ? valueAfter
                  : "payload data not available"
              }
            />
          </GridItem>
        </Grid> */}

        <SFlexContainer justifyContent="space-around">
          <STextDataTitle>Previous value</STextDataTitle>
          <STextDataTitle>Current value</STextDataTitle>
        </SFlexContainer>

        <SNextFixedHeightContainer maxHeight="540px">
          <DiffViewer
            compareMethod={DiffMethod.WORDS_WITH_SPACE}
            // leftTitle={<STextDataTitle>Previous value</STextDataTitle>}
            oldValue={
              payloadGet.loading
                ? "loading..."
                : valueBefore.length > 0
                  ? valueBefore
                  : "payload data not available"
            }
            newValue={
              payloadGet.loading
                ? "loading..."
                : valueAfter.length > 0
                  ? // ? JSON.stringify(JSON.parse(valueAfter), undefined, 4)
                    valueAfter
                  : "payload data not available"
            }
            // rightTitle={<STextDataTitle>Current value</STextDataTitle>}
            // styles={diffViewerStyles}
            splitView={true}
          />
        </SNextFixedHeightContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
