import { Dispatch } from "redux";

import { IGetTopicListData, ITopicListingTableConfig } from "../../../models/ITopicConfig";
import { ApplicationService } from "../../../services/ApplicationService";

import { AppTopicListType, TAppTopicListActions } from "./types";

export const appTopicListRequestAction = (): TAppTopicListActions => ({
  type: AppTopicListType.REQUEST,
});

export const appTopicListSuccessAction = (data: any): TAppTopicListActions => ({
  payload: data,
  type: AppTopicListType.SUCCESS,
});

export const appTopicListErrorAction = (error: string): TAppTopicListActions => ({
  payload: error,
  type: AppTopicListType.ERROR,
});

export const appTopicListClearAction = (): TAppTopicListActions => ({
  type: AppTopicListType.CLEAR,
});

export const getApplicationTopicList = (conf: ITopicListingTableConfig) => (
  dispatch: Dispatch<TAppTopicListActions>
) => {
  dispatch(appTopicListRequestAction());
  ApplicationService.getApplicationTopicList(conf).then(
    (response: { data: { data: IGetTopicListData } }) => {
      dispatch(appTopicListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(appTopicListErrorAction(error))
  );
};
