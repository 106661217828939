import deepEqual from "deep-equal";
import { ContentSwitcher, ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import {
  AircraftGroupFilterSortApplyAction,
  AircraftGroupFilterSortClearAction,
  AircraftGroupFilterSortResetAction,
} from "../../../actions/aircraftgroup/filter-sort/actions";
import { getAircraftGroupList } from "../../../actions/aircraftgroup/get-list/actions";
import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate, getTenantLabel, getTopicTypeLabel } from "../../../helper/util";
import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import {
  FilterType,
  ICustomElement,
  IFilterConfig,
  IFilterItem,
} from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { ADMINISTRATOR, APPROVER, READ } from "../../../models/UserTypes";
import { aircraftGroupFilterSortEmptyFilter as emptyFilterObject } from "../../../reducers/aircraftgroup/AircraftGroupFilterSortReducer";
import { RootState } from "../../../store";
import {
  SActiveLink,
  SButtonIcon,
  SFlexContainer,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalCreateAircraftGroup } from "../../modals/ModalCreateAircraftGroup";
import { Table } from "../../Table";
import { UserRolePermissionsUtil } from "../../UserRolePermissionsUtil";

export const AircraftGroupList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { aircraftGroupList, currentUser, editModal } = useSelector((state: RootState) => ({
    aircraftGroupList: state.aircraftGroupList,
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const { config } = useSelector((state: RootState) => ({
    config: state.aircraftGroupFilterSort,
  }));

  const setConfig = (newConfig: any) => {
    dispatch(AircraftGroupFilterSortApplyAction(newConfig));
  };

  // useEffect(() => {
  //   dispatch(AircraftGroupFilterSortResetAction());
  // }, []);

  const groupTypeOptions = [
    {
      label: <span className="ta-dropdown-tenant">Airline</span>,
      value: AircraftGroupTypeEnum.TENANT,
    },
    {
      label: <span className="ta-dropdown-global">Global</span>,
      value: AircraftGroupTypeEnum.GLOBAL,
    },
  ];

  useEffect(() => {
    const myConfig = {
      ...config,
      filter: {
        ...config.filter,
        aircraftGroupEnvironment: !!props.groupEnv ? props.groupEnv : "",
        aircraftGroupType: props.groupType ? props.groupType : config.filter.aircraftGroupType,
        aircraftId: props.acContent ? props.acContent.aircraftId : "",
        tenantId: props.tenant ? props.tenant.tenantId : "",
      },
    };
    dispatch(getAircraftGroupList(myConfig));
  }, [config, props.refresh]);

  const [headCells, setHeadCells] = useState([
    {
      id: "aircraftGroupName",
      label: "Group name",
      onClickCell: (row: ITableRow) => {
        if (!props.noLink) {
          navigateToPage(`/aircraftGroups/${row.aircraftGroupId}`);
        }
      },
      value: (row: ITableRow) =>
        props.noLink ? row.aircraftGroupName : <SActiveLink>{row.aircraftGroupName}</SActiveLink>,
    },
    {
      id: "",
      // label: props.groupEnv === GroundEnvironmentTypeEnum.PRODUCTION
      //   ? "Aircraft count"
      //   : "Rack count",
      label: "Member count",
      value: (row: ITableRow) =>
        `${
          row.aircraftGroupEnvironment === GroundEnvironmentTypeEnum.PRODUCTION
            ? row.aircraftCount
            : row.rackCount
        }`,
    },
    // {
    //   id: "",
    //   label: "Rack count",
    //   value: (row: ITableRow) => `${row.rackCount}`,
    // },
    {
      id: "aircraftGroupType",
      label: "Group type",
      value: (row: ITableRow) =>
        `${row.aircraftGroupType === AircraftGroupTypeEnum.TENANT ? "Airline" : "Global"}`,
    },
    {
      id: "modifiedBy",
      label: "Modified by",
      value: (row: ITableRow) => (row.modifiedBy ? row.modifiedBy.fullName : "Unknown"),
    },
    {
      id: "modifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
  ]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...aircraftGroupList,
    },
    name: "aircraft-group",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const handleOpenModal = () => {
    // allow to create and has group env specified
    if (!props.noCreate && !!props.groupEnv) {
      dispatch(
        editModalOpenAction({
          groupEnv: props.groupEnv,
          groupType: props.groupType,
          tenant: props.tenant,
          type: modalTypeEnum.NEW_AIRCRAFT_GROUP,
        })
      );
    }
  };

  const [filterItems, setFilterItems] = useState([
    {
      name: "aircraftGroupName",
      placeholder: "Group name",
      taClass: "aircraft-group-name",
      type: FilterType.TEXT,
    },
    {
      data: groupTypeOptions,
      name: "aircraftGroupType",
      placeholder: "Group Type",
      taClass: "aircraft-group-type",
      type: FilterType.DROPDOWN,
      // width: "1.5fr",
    },
    {
      name: "modifiedBy",
      placeholder: "Modified by",
      taClass: "modifiedBy",
      type: FilterType.TEXT,
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ]);

  const canCreateGroup = (user: any) => {
    if (!props.noCreate && !!props.groupType && !!props.groupEnv) {
      if (props.groupType === AircraftGroupTypeEnum.GLOBAL) {
        if (user.role === ADMINISTRATOR || user.role === APPROVER) {
          return true;
        }
      } else {
        // props.groupType === AircraftGroupTypeEnum.TENANT
        if (props.groupEnv === GroundEnvironmentTypeEnum.PRODUCTION) {
          if (UserRolePermissionsUtil.hasGroundsideConfig_ManagePrdGroups(user)) {
            return true;
          }
        } else if (props.groupEnv === GroundEnvironmentTypeEnum.INTEGRATION) {
          if (UserRolePermissionsUtil.hasGroundsideConfig_ManageIntGroups(user)) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const filterConfig: IFilterConfig = {
    customElements: props.noCreate
      ? undefined
      : [
          {
            element: (
              <SButtonIcon
                size="default"
                icon={<SIconAdd32 />}
                onClick={handleOpenModal}
                className={getTAClass("aircraft-group", TA_TYPES.BUTTON, "createNew")}
                // disabled={currentUser.data.role !== ADMINISTRATOR}
                disabled={!canCreateGroup(currentUser.data)}
              />
            ),
            width: "0.25fr",
          },
        ],
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: filterItems,
    pageName: "aircraft-group",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  useEffect(() => {
    if (!!props.groupType) {
      // if group type is specified, remove 'group type' header and filter
      headCells.splice(2, 1);
      filterItems.splice(1, 1);

      // apply header/filter update
      setHeadCells(headCells);
      setFilterItems(filterItems);
    }
  }, []);

  return (
    <>
      <SPageContainer>
        {/* <SPageHeading className="ta-topics-title">Aircrafts / Racks</SPageHeading> */}

        <Filter {...filterConfig} />

        <SSpacer height="20px" />

        {/* LOADING */}
        {aircraftGroupList.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        <Table {...tableProps}></Table>
      </SPageContainer>

      {/* CREATE AIRCRAFT GROUP MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.NEW_AIRCRAFT_GROUP && (
        <ModalCreateAircraftGroup />
      )}
    </>
  );
};
