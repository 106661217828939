import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum UserGetDetailTypes {
  REQUEST = "USER_GET_DETAILS_REQUEST",
  SUCCESS = "USER_GET_DETAILS_SUCCESS",
  ERROR = "USER_GET_DETAILS_ERROR",
}

export interface IGetUserDetailsRequestAction extends IReduxBaseAction<UserGetDetailTypes> {
  type: UserGetDetailTypes.REQUEST;
}

export interface IGetUserDetailsSuccessAction extends IReduxBaseAction<UserGetDetailTypes> {
  payload: any;
  type: UserGetDetailTypes.SUCCESS;
}

export interface IGetUserDetailsErrorAction extends IReduxBaseAction<UserGetDetailTypes> {
  payload: any;
  type: UserGetDetailTypes.ERROR;
}

export type TGetUserDetailsActionType =
  | IGetUserDetailsRequestAction
  | IGetUserDetailsSuccessAction
  | IGetUserDetailsErrorAction;
