import { Dispatch } from "redux";

import { IGetTopicListData, ITopicListingTableConfig } from "../../../models/ITopicConfig";
import { TopicService } from "../../../services/TopicService";

import { TopicClientListType, TTopicClientListActions } from "./types";

export const appTopicListClearAction = (): TTopicClientListActions => ({
  type: TopicClientListType.CLEAR,
});

export const appTopicListRequestAction = (): TTopicClientListActions => ({
  type: TopicClientListType.REQUEST,
});

export const appTopicListSuccessAction = (data: any): TTopicClientListActions => ({
  payload: data,
  type: TopicClientListType.SUCCESS,
});

export const appTopicListErrorAction = (error: string): TTopicClientListActions => ({
  payload: error,
  type: TopicClientListType.ERROR,
});

export const getTopicClientList = (conf: ITopicListingTableConfig) => (
  dispatch: Dispatch<TTopicClientListActions>
) => {
  dispatch(appTopicListRequestAction());
  TopicService.getTopicClientList(conf).then(
    (response: { data: { data: IGetTopicListData } }) => {
      dispatch(appTopicListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(appTopicListErrorAction(error))
  );
};
