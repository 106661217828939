import {
  TGetTopicPermissionAppsActions,
  TopicPermissionAppsType,
} from "../../actions/topic/get-permission-apps/types";

interface IAppListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = {
  content: [],
  count: 0,
  error: false,
  loading: false,
} as IAppListState;

export const topicPubPermissionApps = (
  state: IAppListState = initialState,
  action: TGetTopicPermissionAppsActions
): IAppListState => {
  switch (action.type) {
    case TopicPermissionAppsType.RESET_PUB:
      return {
        ...state,
        ...initialState,
      };
    case TopicPermissionAppsType.REQUEST_PUB:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TopicPermissionAppsType.SUCCESS_PUB:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case TopicPermissionAppsType.ERROR_PUB:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};

export const topicSubPermissionApps = (
  state: IAppListState = initialState,
  action: TGetTopicPermissionAppsActions
): IAppListState => {
  switch (action.type) {
    case TopicPermissionAppsType.RESET_SUB:
      return {
        ...state,
        ...initialState,
      };
    case TopicPermissionAppsType.REQUEST_SUB:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TopicPermissionAppsType.SUCCESS_SUB:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case TopicPermissionAppsType.ERROR_SUB:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
