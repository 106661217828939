/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum TenantFilterSortType {
  CLEAR = "TENANT_FILTER_SORT_CLEAR",
  RESET = "TENANT_FILTER_SORT_RESET",
  APPLY = "TENANT_FILTER_SORT_APPLY",
}

export interface ITenantFilterSortClearAction extends IReduxBaseAction<TenantFilterSortType> {
  type: TenantFilterSortType.CLEAR;
}

export interface ITenantFilterSortResetAction extends IReduxBaseAction<TenantFilterSortType> {
  type: TenantFilterSortType.RESET;
}

export interface ITenantFilterSortApplyAction extends IReduxBaseAction<TenantFilterSortType> {
  payload: IFilterSortData;
  type: TenantFilterSortType.APPLY;
}

export type TTenantFilterSortActions =
  | ITenantFilterSortClearAction
  | ITenantFilterSortResetAction
  | ITenantFilterSortApplyAction;
