import { Dispatch } from "redux";

import {
  IConfigListingTableConfig,
  IGetConfigListData,
} from "../../../models/IConfigurationFilesConfig";
import { ConfigService } from "../../../services/ConfigService";

import { ConfigLoadableListType, TConfigLoadableListActions } from "./types";

export const GetConfigLoadableListResetAction = (): TConfigLoadableListActions => ({
  type: ConfigLoadableListType.RESET,
});

export const GetConfigLoadableListRequestAction = (): TConfigLoadableListActions => ({
  type: ConfigLoadableListType.REQUEST,
});

export const GetConfigLoadableListSuccessAction = (data: any): TConfigLoadableListActions => ({
  payload: data,
  type: ConfigLoadableListType.SUCCESS,
});

export const GetConfigLoadableListErrorAction = (error: string): TConfigLoadableListActions => ({
  payload: error,
  type: ConfigLoadableListType.ERROR,
});

export const getConfigLoadableList = (conf: IConfigListingTableConfig) => (
  dispatch: Dispatch<TConfigLoadableListActions>
) => {
  dispatch(GetConfigLoadableListRequestAction());
  ConfigService.getConfigLoadableList(conf).then(
    (response: { data: { data: IGetConfigListData } }) => {
      dispatch(GetConfigLoadableListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(GetConfigLoadableListErrorAction(error))
  );
};
