import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getApplicationList } from "../actions/application/get-list/actions";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../models/ITableConfig";
import { RootState } from "../store";

import { Table } from "./Table";

export const SimpleApplicationList = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { applicationList } = useSelector((state: RootState) => ({
    applicationList: state.applicationList,
  }));

  const [config, setConfig] = useState({
    filter: {
      ...(props.userId && { ownerUserId: props.userId || "0" }),
      ...(props.userNameAndAppClientId && {
        userNameAndAppClientId: props.userNameAndAppClientId || "0",
      }),
    },
    limit: 10,
    orderBy: "appName",
    orderType: OrderType.ASC,
    page: 0,
  });

  useEffect(() => {
    dispatch(getApplicationList(config));
  }, [config]);

  const headCells: ITableHeaderCells[] = [
    { id: "appName", label: "App name" },
    { id: "appVersion", label: "Version" },
    { id: "status", label: "Status" },
  ];

  const openDetails = (row: ITableRow): void => {
    props.history.push(`/applicationClients/${row.appClientId}`);
    if (props.onOpened) {
      props.onOpened(row);
    }
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    componentName: "application",
    head: {
      cells: headCells,
    },
    list: {
      ...applicationList,
      onClickRow: openDetails,
    },
    name: "appClientId",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return <Table {...tableProps}></Table>;
};
