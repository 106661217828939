import {
  Grid,
  GridItem,
  IconInfo16,
  NumberInput as NumberInputNext,
  Text,
  Textarea,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { popupOpenAction } from "../../actions/popup/actions";
import { getFilteredTopicList } from "../../actions/topic/filter-list/actions";
import { getTaClassedOptions } from "../../helper/dropdown";
import { isValidValueWithoutWarning } from "../../helper/util";
import { useDebounce } from "../../hooks/useDebounce";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { OrderType } from "../../models/ITableConfig";
import {
  localGroupBridgeTypeEnum,
  maxPayloadSizeValues,
  offloadType,
  qosEnum,
  throttleThresholdFactorValues,
  topicStatusEnum,
  topicTypeEnum,
} from "../../models/ITopicConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexItem,
  SInfoLabel,
  SInfoLabelContainer,
  SModalStackItem,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { Checkbox, CheckboxOptions } from "../Checkbox";
import { StatusIcon } from "../StatusIcon";
import {
  TopicValidationErrorCodeEnum as TopicNameError,
  TopicValidatorUtil,
} from "../TopicValidatorUtil";
import { TypeaheadApplication } from "../TypeaheadApplication";

import { DropdownInput } from "./Dropdownlnput";
import { NumberInput } from "./NumberInput";
import { TextInput } from "./TextInput";

// tslint:disable-next-line: cyclomatic-complexity
export const ModalCreateTopicPage1 = (props: any) => {
  const [cursorLocation, setCursorLocation] = useState(0);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [updatedConfig, setUpdatedConfig] = useState();
  const [errorString, setErrorSting] = useState(" ");
  const [isLocalGroup, setIsLocalGroup] = useState(false);

  const priorities: any[] = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const offloadTypeList: any[] = [
    { label: "Deferred", value: offloadType.DEFERRED },
    { label: "Live", value: offloadType.LIVE },
  ];

  const qosLevelList: any[] = [
    { label: "0", value: qosEnum.FIRE_AND_FORGET },
    { label: "1", value: qosEnum.ATLEAST_ONCE },
  ];

  const localGroupBridgeTypes: any[] = [
    {
      label: <span className="ta-dropdown-local-group-bridge-option-astrova">Astrova</span>,
      value: localGroupBridgeTypeEnum.ASTROVA,
    },
  ];

  const { filterTopicList } = useSelector((state: RootState) => ({
    filterTopicList: state.filterTopicList,
  }));

  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));

  const validateTextInput = (e: any, maxChar?: number) => {
    setActiveInput(e.target);
    setCursorLocation(e.target.selectionStart);
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 250)) {
      return false;
    }

    return true;
  };

  const debounceFilter = useDebounce(props.config.topic ? props.config.topic.topicName : "", 300);
  useEffect(() => {
    // Check on topic name change if topic name does exist
    const config = {
      filter: {
        topicName: props.config.topic && props.config.topic.topicName,
      },
      limit: 10,
      orderBy: "topicName",
      orderType: OrderType.ASC,
      page: 0,
    };

    dispatch(getFilteredTopicList(config));
  }, [debounceFilter]);

  useEffect(() => {
    if (
      !props.config.topic ||
      !props.config.topic.topicName ||
      filterTopicList.content.filter(
        (topic: any) =>
          topic.topicName === props.config.topic.topicName &&
          topic.status !== topicStatusEnum.DELETED
      ).length
    ) {
      props.setIsTopicNameValid(TopicNameError.LENGTH_LT_THREE);
    } else {
      let errorCode: TopicNameError;
      errorCode = TopicValidatorUtil.validateTopicName(
        props.config.topic.topicName,
        currentUser.data.role === ADMINISTRATOR,
        props.config.topicType
      );

      props.setIsTopicNameValid(errorCode);

      switch (errorCode) {
        case TopicNameError.NO_ERROR:
          setErrorSting(" ");
          break;
        case TopicNameError.UPPERCASE:
          setErrorSting("Entered Topic name contains uppercase character(s)");
          break;
        case TopicNameError.LENGTH_LT_THREE:
        case TopicNameError.LENGTH_TOO_LONG:
          setErrorSting("Topic name should be a minimum of 3 and maximum of 199 characters");
          break;
        case TopicNameError.INVALID_CHARACTERS:
          setErrorSting("Entered Topic name contains invalid character(s)");
          break;
        case TopicNameError.LEADING_SLASH:
          setErrorSting("Entered Topic name contains leading slash");
          break;
        case TopicNameError.EMPTY_LEVEL_NAME:
          setErrorSting("Entered Topic name contains empty level name");
          break;
        case TopicNameError.TAILING_SLASH:
          setErrorSting("Entered Topic name contains trailing slash");
          break;
        case TopicNameError.INVALID_WILDCARD_M:
          setErrorSting("Entered Topic name contains invalid wildcard #");
          break;
        case TopicNameError.WILDCARD_S_IN_LEVEL:
          setErrorSting("Entered Topic name contains wildcard + in level name");
          break;
        case TopicNameError.MULTIPLE_WILDCARD_M:
          setErrorSting("Entered Topic name contains multiple wildcard #");
          break;
        case TopicNameError.WILDCARD_M_IN_LEVEL:
          setErrorSting("Entered Topic name contains wildcard # in level name");
          break;
        case TopicNameError.INVALID_CHARACTER_$:
          setErrorSting("Entered Topic name contains invalid character $");
          break;
        case TopicNameError.INVALID_WILDCARD_S:
          setErrorSting("Entered Topic name contains invalid wildcard +");
          break;
        case TopicNameError.MORE_THAN_5_SLASHES:
          setErrorSting("Entered Topic name contains more than 5 slashes");
          break;
        default:
          setErrorSting(" ");
      }
    }
  }, [filterTopicList]);

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (!validateTextInput(e, maxChar)) {
      return;
    }
    setUpdatedConfig({ ...props.config, [field]: e.target.value });
  };

  const handleNumberInput = (value: any, field: string) => {
    let fieldValue = value;
    if (isNaN(value)) {
      fieldValue = 0;
    }
    if (value < 0) {
      fieldValue = Math.abs(value);
    }
    setUpdatedConfig({ ...props.config, [field]: fieldValue });
  };

  const handlePriorityDropdown = (value: any) => {
    setUpdatedConfig({ ...props.config, priority: value });
  };

  const handleSecureCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldIsSecure = props.config.isSecure;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldIsSecure ? false : props.config.accessAnonymous,
      isSecure: !oldIsSecure,
      multicast: !oldIsSecure ? false : props.config.multicast,
    });
  };

  const handleAccessAnonymousCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldAccessAnonymous = props.config.accessAnonymous;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldAccessAnonymous,
      isSecure: !oldAccessAnonymous ? false : props.config.isSecure,
      multicast: !oldAccessAnonymous ? false : props.config.multicast,
    });
  };

  const handleMulticastCheckboxClick = (e: any) => {
    // Make isSecure, accessAnonymous & multicast mutually exclusive
    const oldMulticast = props.config.multicast;
    setUpdatedConfig({
      ...props.config,
      accessAnonymous: !oldMulticast ? false : props.config.accessAnonymous,
      isSecure: !oldMulticast ? false : props.config.isSecure,
      multicast: !oldMulticast,
    });
  };

  const handleDefaultRetainRequiredCheckboxClick = (e: any) => {
    setUpdatedConfig({
      ...props.config,
      retainRequired: !props.config.retainRequired,
    });
  };

  const handleLocalGroupCheckboxClick = (e: any) => {
    setIsLocalGroup(!isLocalGroup);

    // setUpdatedConfig({
    //   ...props.config,
    //   localGroup: props.config.localGroup > 0 ? 0 : 1,
    // });
  };

  const handleLocalGroupBridgeTypeChange = (value: any) => {
    setUpdatedConfig({ ...props.config, localGroupBridge: value });
  };

  const handleOffloadChange = (value: any) => {
    setUpdatedConfig({ ...props.config, offloadType: value });
  };

  const handleQosDropdown = (value: any) => {
    setUpdatedConfig({ ...props.config, qosLevel: value });
  };

  useEffect(() => {
    if (props.config && updatedConfig) {
      props.onChange(updatedConfig);
    }
  }, [updatedConfig]);

  useEffect(() => {
    if (activeInput && cursorLocation) {
      activeInput.setSelectionRange(cursorLocation, cursorLocation);
    }
  }, [props.config]);

  useEffect(() => {
    if (isLocalGroup) {
      handleLocalGroupBridgeTypeChange(localGroupBridgeTypes[0].value);
    } else {
      if (!!props.config.localGroupBridge) {
        setUpdatedConfig({ ...props.config, localGroupBridge: "" });
      }
    }
  }, [isLocalGroup]);

  const handleLocalGroupInfo = () => {
    dispatch(
      popupOpenAction({
        content: `<div>
                    This allows topic communication to be routed between local seatback group units instead of being routed through central broker.
                  </div>
                  <br>
                  <div>
                    This is only available for units that support this feature, such as Astrova.
                  </div>`,
        isContentHtml: true,
        title: "Local group bridging",
        type: "Info",
      })
    );
  };

  const handleTypeaheadInput = (app: any) => {
    setUpdatedConfig({
      ...props.config,
      appData: {
        appClientId: app.appClientId,
        appName: app.appName,
        appVersion: app.appVersion,
      },
    });
  };

  const getMaxPayloadsizeWarningText = (value: number) =>
    value > maxPayloadSizeValues.max
      ? `Error: payload size greater than ${maxPayloadSizeValues.max} KB is not allowed.`
      : value > maxPayloadSizeValues.warning
        ? `Warning: payload size greater than ${maxPayloadSizeValues.warning} KB is not recommended.`
        : ``;

  const getThrottleThresholdFactorWarningText = (value: number) =>
    value > throttleThresholdFactorValues.max
      ? `Error: more than ${throttleThresholdFactorValues.max}/min is not allowed.`
      : value > throttleThresholdFactorValues.warning
        ? `Warning: more than ${throttleThresholdFactorValues.warning}/min is not recommended.`
        : ``;

  return (
    <>
      <TextInput
        label={`Topic: ${errorString} `}
        name="topic"
        placeholder="Topic"
        valid={props.isTopicNameValid === TopicNameError.NO_ERROR}
        value={props.config.topic && props.config.topic.topicName}
        onChange={(e) => {
          if (!validateTextInput(e)) {
            return;
          }
          setUpdatedConfig({
            ...props.config,
            topic: {
              ...props.config.topic,
              // this will convert in place on the fly
              // topicName: e.target.value.toLowerCase(),
              topicName: e.target.value,
            },
          });
        }}
      />
      <Text variant="captionText" className="ta-modal-input-caption-topic-owner">
        Topic owner:{" "}
        <StatusIcon
          className={
            props.config && props.config.appData && props.config.appData.appName
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={props.config && props.config.appData && props.config.appData.appName}
        />
      </Text>

      <TypeaheadApplication
        pageSelector="create-topic"
        // value={`${
        //   props.config.appData && props.config.appData.appName
        //     ? `${props.config.appData.appName} ${props.config.appData.appVersion}`
        //     : ""
        // }`}
        value={
          props.config.appData && props.config.appData.appName
            ? `${props.config.appData.appName} ${props.config.appData.appVersion}`
            : ""
        }
        onChange={handleTypeaheadInput}
        disabled={false}
        appLocation={brokerLocationEnum.AIRSIDE}
      />

      <Grid columns="1fr 1fr">
        <GridItem>
          <SFlexItem>
            <div className="ta-modal-input-secure">
              <Checkbox
                onClick={handleSecureCheckboxClick}
                marked={
                  props.config.isSecure ? CheckboxOptions.selected : CheckboxOptions.unselected
                }
                disabled={
                  props.config.accessAnonymous ||
                  props.config.multicast ||
                  props.config.topicType === topicTypeEnum.A2G
                }
              />
            </div>
            <SVerticalSpacer />
            <Text variant="captionText" className="ta-modal-input-caption-secure">
              Secure
            </Text>
          </SFlexItem>
        </GridItem>

        {props.config.topicType === topicTypeEnum.AIR && (
          <GridItem>
            <SFlexItem>
              <div className="ta-modal-input-multicast">
                <Checkbox
                  onClick={handleMulticastCheckboxClick}
                  marked={
                    props.config.multicast ? CheckboxOptions.selected : CheckboxOptions.unselected
                  }
                  disabled={props.config.accessAnonymous || props.config.isSecure}
                />
              </div>
              <SVerticalSpacer />
              <Text variant="captionText" className="ta-modal-input-caption-multicast">
                Multicast
              </Text>
            </SFlexItem>
          </GridItem>
        )}
      </Grid>

      {props.config.topicType === topicTypeEnum.AIR && currentUser.data.role === ADMINISTRATOR && (
        <SFlexItem>
          <div className="ta-modal-input-access-anonymous">
            <Checkbox
              onClick={handleAccessAnonymousCheckboxClick}
              marked={
                props.config.accessAnonymous ? CheckboxOptions.selected : CheckboxOptions.unselected
              }
              disabled={props.config.isSecure || props.config.multicast}
            />
          </div>
          <SVerticalSpacer />
          <Text variant="captionText" className="ta-modal-input-caption-access-anonymous">
            Allow anonymous access
          </Text>
        </SFlexItem>
      )}

      <SFlexItem>
        <div className="ta-modal-input-default-retain-required">
          <Checkbox
            onClick={handleDefaultRetainRequiredCheckboxClick}
            marked={
              props.config.retainRequired ? CheckboxOptions.selected : CheckboxOptions.unselected
            }
            // disabled={false}
          />
        </div>
        <SVerticalSpacer />
        <Text variant="captionText" className="ta-modal-input-default-retain-required">
          Default retain required (can be overwritten when creating a publisher)
        </Text>
      </SFlexItem>

      <Grid
        autoFlow="column"
        columns={props.config.topicType === topicTypeEnum.AIR ? "auto" : "1fr 1fr"}
      >
        {props.config.topicType === topicTypeEnum.A2G && (
          <GridItem>
            <DropdownInput
              label="Offload type:"
              name="offload"
              onChange={handleOffloadChange}
              options={getTaClassedOptions(offloadTypeList)}
              selected={props.config && props.config.offloadType}
            />
          </GridItem>
        )}

        <GridItem>
          <Text variant="captionText" className="ta-modal-input-caption-ttl">
            TTL:
          </Text>
          <NumberInputNext
            name="ttl"
            value={props.config.ttl}
            min={0}
            onChange={(value: any) => {
              handleNumberInput(value, "ttl");
            }}
          />
        </GridItem>
      </Grid>

      <Grid
        autoFlow="column"
        columns={props.config.topicType === topicTypeEnum.AIR ? "auto" : "1fr 1fr"}
      >
        {props.config.topicType === topicTypeEnum.A2G && (
          <GridItem>
            <DropdownInput
              label="Priority:"
              name="priority"
              onChange={handlePriorityDropdown}
              options={getTaClassedOptions(priorities)}
              selected={props.config && props.config.priority}
            />
          </GridItem>
        )}

        <GridItem>
          <DropdownInput
            label="QoS level:"
            name="qosLevel"
            onChange={handleQosDropdown}
            options={getTaClassedOptions(qosLevelList)}
            selected={props.config && props.config.qosLevel}
            disabled={props.config.topicType === topicTypeEnum.A2G}
          />
        </GridItem>
      </Grid>

      {props.config.topicType === topicTypeEnum.A2G && (
        <>
          <NumberInput
            label={
              <span>
                Max payload size (KB){" "}
                <span style={{ color: "red" }}>
                  {getMaxPayloadsizeWarningText(props.config.maxPayloadSize)}
                </span>
              </span>
            }
            name="max-payload-size"
            placeholder="Maximum payload size"
            valid={isValidValueWithoutWarning(props.config.maxPayloadSize, maxPayloadSizeValues)}
            value={props.config.maxPayloadSize}
            min={maxPayloadSizeValues.min}
            max={maxPayloadSizeValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "maxPayloadSize");
            }}
          />

          <NumberInput
            label={
              <span>
                Max number of messages published per minute{" "}
                <span style={{ color: "red" }}>
                  {getThrottleThresholdFactorWarningText(props.config.throttleThresholdFactor)}
                </span>
              </span>
            }
            name="throtting-threshold-factor"
            placeholder="Throttling threshold factor"
            valid={isValidValueWithoutWarning(
              props.config.throttleThresholdFactor,
              throttleThresholdFactorValues
            )}
            value={props.config.throttleThresholdFactor}
            min={throttleThresholdFactorValues.min}
            max={throttleThresholdFactorValues.max}
            onChange={(value: number) => {
              handleNumberInput(value, "throttleThresholdFactor");
            }}
          />
        </>
      )}

      {props.config.topicType === topicTypeEnum.AIR && (
        <SModalStackItem className="ta-modal-auth-container-cred">
          <SFlexItem>
            <Text variant="captionText" className="ta-modal-label-local-group">
              For units that support local group bridging:
            </Text>
            <SVerticalSpacer />
            <SInfoLabelContainer className="ta-modal-info-local-group">
              <SInfoLabel>
                <IconInfo16 onClick={handleLocalGroupInfo} />
              </SInfoLabel>{" "}
            </SInfoLabelContainer>
          </SFlexItem>

          <SFlexItem>
            <div className="ta-modal-checkbox-local-group">
              <Checkbox
                onClick={handleLocalGroupCheckboxClick}
                marked={isLocalGroup ? CheckboxOptions.selected : CheckboxOptions.unselected}
                // disabled={false}
              />
            </div>
            <SVerticalSpacer />
            <Text variant="captionText" className="ta-modal-checkbox-label-local-group">
              Local group bridging
            </Text>
          </SFlexItem>

          {isLocalGroup && (
            <DropdownInput
              label="Local group bridge type:"
              name="bridge-type"
              onChange={handleLocalGroupBridgeTypeChange}
              options={localGroupBridgeTypes}
              selected={props.config && props.config.localGroupBridge}
            />
          )}
        </SModalStackItem>
      )}

      <Text variant="captionText" className="ta-modal-input-caption-description">
        Description:
      </Text>
      <Textarea
        className="ta-modal-input-description"
        placeholder="Description"
        value={props.config.description}
        onChange={(e: any) => {
          handleTextInput(e, "description", 1000);
        }}
      />
    </>
  );
};
