/* istanbul ignore file */
import { IDestinationListData } from "../../../models/IDestination";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum DestinationListType {
  RESET = "GET_DESTINATION_LIST_RESET",
  REQUEST = "GET_DESTINATION_LIST_REQUEST",
  SUCCESS = "GET_DESTINATION_LIST_SUCCESS",
  ERROR = "GET_DESTINATION_LIST_ERROR",
}

export interface IDestinationListResetAction extends IReduxBaseAction<DestinationListType> {
  type: DestinationListType.RESET;
}

export interface IDestinationListRequestAction extends IReduxBaseAction<DestinationListType> {
  type: DestinationListType.REQUEST;
}

export interface IDestinationListSuccessAction extends IReduxBaseAction<DestinationListType> {
  payload: IDestinationListData;
  type: DestinationListType.SUCCESS;
}

export interface IDestinationListErrorAction extends IReduxBaseAction<DestinationListType> {
  payload: string;
  type: DestinationListType.ERROR;
}

export type TDestinationListActions =
  | IDestinationListResetAction
  | IDestinationListRequestAction
  | IDestinationListSuccessAction
  | IDestinationListErrorAction;
