import { IReduxBaseAction } from "../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetTopologyListType {
  REQUEST = "GET_TOPOLOGY_REQUEST",
  SUCCESS = "GET_TOPOLOGY_SUCCESS",
  ERROR = "GET_TOPOLOGY_ERROR",
}

export interface IGetTopologyListRequestAction extends IReduxBaseAction<GetTopologyListType> {
  type: GetTopologyListType.REQUEST;
}

export interface IGetTopologyListSuccessAction extends IReduxBaseAction<GetTopologyListType> {
  payload: any;
  type: GetTopologyListType.SUCCESS;
}

export interface IGetTopologyListErrorAction extends IReduxBaseAction<GetTopologyListType> {
  payload: any;
  type: GetTopologyListType.ERROR;
}

export type TTopologyListActions =
  | IGetTopologyListRequestAction
  | IGetTopologyListSuccessAction
  | IGetTopologyListErrorAction;
