/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum AppFilterSortType {
  CLEAR = "APP_FILTER_SORT_CLEAR",
  RESET = "APP_FILTER_SORT_RESET",
  APPLY = "APP_FILTER_SORT_APPLY",
}

export interface IAppFilterSortClearAction extends IReduxBaseAction<AppFilterSortType> {
  type: AppFilterSortType.CLEAR;
}

export interface IAppFilterSortResetAction extends IReduxBaseAction<AppFilterSortType> {
  type: AppFilterSortType.RESET;
}

export interface IAppFilterSortApplyAction extends IReduxBaseAction<AppFilterSortType> {
  payload: IFilterSortData;
  type: AppFilterSortType.APPLY;
}


export type TAppFilterSortActions =
  | IAppFilterSortClearAction
  | IAppFilterSortResetAction
  | IAppFilterSortApplyAction;
