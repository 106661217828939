/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { ITopicPermissionAppsListData } from "../../../models/ITopicDetailsConfig";

export enum TopicPermissionAppsType {
  RESET_PUB = "GET_TOPIC_PUB_PERMISSION_APPS_RESET",
  REQUEST_PUB = "GET_TOPIC_PUB_PERMISSION_APPS_REQUEST",
  SUCCESS_PUB = "GET_TOPIC_PUB_PERMISSION_APPS_SUCCESS",
  ERROR_PUB = "GET_TOPIC_PUB_PERMISSION_APPS_ERROR",
  RESET_SUB = "GET_TOPIC_SUB_PERMISSION_APPS_RESET",
  REQUEST_SUB = "GET_TOPIC_SUB_PERMISSION_APPS_REQUEST",
  SUCCESS_SUB = "GET_TOPIC_SUB_PERMISSION_APPS_SUCCESS",
  ERROR_SUB = "GET_TOPIC_SUB_PERMISSION_APPS_ERROR",
}

export interface ITopicPubPermissionAppsResetAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  type: TopicPermissionAppsType.RESET_PUB;
}

export interface ITopicPubPermissionAppsRequestAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  type: TopicPermissionAppsType.REQUEST_PUB;
}

export interface ITopicPubPermissionAppsSuccessAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  payload: ITopicPermissionAppsListData;
  type: TopicPermissionAppsType.SUCCESS_PUB;
}

export interface ITopicPubPermissionAppsErrorAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  payload: any;
  type: TopicPermissionAppsType.ERROR_PUB;
}

export interface ITopicSubPermissionAppsResetAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  type: TopicPermissionAppsType.RESET_SUB;
}

export interface ITopicSubPermissionAppsRequestAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  type: TopicPermissionAppsType.REQUEST_SUB;
}

export interface ITopicSubPermissionAppsSuccessAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  payload: ITopicPermissionAppsListData;
  type: TopicPermissionAppsType.SUCCESS_SUB;
}

export interface ITopicSubPermissionAppsErrorAction
  extends IReduxBaseAction<TopicPermissionAppsType> {
  payload: any;
  type: TopicPermissionAppsType.ERROR_SUB;
}

export type TGetTopicPermissionAppsActions =
  | ITopicPubPermissionAppsResetAction
  | ITopicPubPermissionAppsRequestAction
  | ITopicPubPermissionAppsSuccessAction
  | ITopicPubPermissionAppsErrorAction
  | ITopicSubPermissionAppsResetAction
  | ITopicSubPermissionAppsRequestAction
  | ITopicSubPermissionAppsSuccessAction
  | ITopicSubPermissionAppsErrorAction;
