import deepEqual from "deep-equal";
import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { editModalOpenAction } from "../../actions/modal/edit/actions";
import {
  topicFilterSortApplyAction,
  topicFilterSortClearAction,
} from "../../actions/topic/filter-sort/actions";
import { getTopicList } from "../../actions/topic/get-list/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { getFormattedDate, getTenantLabel, getTopicTypeLabel } from "../../helper/util";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { FilterType, ICustomElement, IFilterConfig, IFilterItem } from "../../models/IFilterConfig";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../../models/ITableConfig";
import { topicStatusEnum, topicTypeEnum } from "../../models/ITopicConfig";
import { ADMINISTRATOR, READ } from "../../models/UserTypes";
import { topicFilterSortEmptyFilter as emptyFilterObject } from "../../reducers/topic/TopicFilterSortReducer";
import { RootState } from "../../store";
import {
  SActiveLink,
  SButtonIcon,
  SIconAdd32,
  SOverlay,
  SPageContainer,
  SPageHeading,
} from "../../styles/styles";
import { Filter } from "../Filter";
import { ModalCreateTopic } from "../modals/ModalCreateTopic";
import { Table } from "../Table";
import { UserRolePermissionsUtil } from "../UserRolePermissionsUtil";

export const Topics = (router: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const SSpacer = styled.div`
    height: 20px;
  `;

  const { currentUser, editModal, topicList } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    topicList: state.topicList,
  }));
  const { config } = useSelector((state: RootState) => ({
    config: state.topicFilterSort,
  }));

  const typeOptions = [
    {
      label: <span className="ta-dropdown-all">Airside</span>,
      value: topicTypeEnum.AIR,
    },
    {
      label: <span className="ta-dropdown-draft">Airside to groundside</span>,
      value: topicTypeEnum.A2G,
    },
  ];

  const statusOptions = [
    {
      label: <span className="ta-dropdown-all">ALL</span>,
      value: topicStatusEnum.ALL,
    },
    {
      label: <span className="ta-dropdown-draft">DRAFT</span>,
      value: topicStatusEnum.DRAFT,
    },
    {
      label: <span className="ta-dropdown-active">ACTIVE</span>,
      value: topicStatusEnum.ACTIVE,
    },
    {
      label: <span className="ta-dropdown-onhold">ON HOLD</span>,
      value: topicStatusEnum.ONHOLD,
    },
    {
      label: <span className="ta-dropdown-deleted">DELETED</span>,
      value: topicStatusEnum.DELETED,
    },
  ];

  const multicastOptions = [
    {
      label: <span className="ta-dropdown-yes">Yes</span>,
      value: "true",
    },
    {
      label: <span className="ta-dropdown-no">No</span>,
      value: "false",
    },
  ];

  // const emptyFilterObject = {
  //   modifiedBy: "",
  //   modifiedDate: { startDate: null, endDate: null },
  //   status: "",
  //   topicName: "",
  //   topicOwner: "",
  // };

  // const [config, setConfig] = useState({
  //   filter: { ...emptyFilterObject },
  //   limit: 10,
  //   orderBy: "topicName",
  //   orderType: OrderType.ASC,
  //   page: 0,
  // });

  const setConfig = (newConfig: any) => {
    dispatch(topicFilterSortApplyAction(newConfig));
  };

  useEffect(() => {
    dispatch(getTopicList(config));
  }, [config]);

  const navigateToPage = (url: string): void => {
    router.history.push(url);
  };

  const [headCells, setHeadCells] = useState([
    {
      id: "topicName",
      label: "Topic name",
      onClickCell: (row: ITableRow) => {
        navigateToPage(`topics/${row.topicId}`);
      },
      value: (row: ITableRow) => <SActiveLink>{row.topicName}</SActiveLink>,
    },
    {
      id: "topicOwner",
      label: "Topic owner",
      onClickCell: (row: ITableRow) => {
        if (row.appClient && row.appClient.appName && row.appClient.appVersion) {
          navigateToPage(`applicationClients/${row.appClient.appClientId}`);
        }
      },
      value: (row: ITableRow) =>
        row.appClient && row.appClient.appName && row.appClient.appVersion ? (
          <SActiveLink>{`${row.appClient.appName} ${row.appClient.appVersion}`}</SActiveLink>
        ) : row.appClient && row.appClient.tenant && row.appClient.tenant.tenantName ? (
          getTenantLabel(row.appClient)
        ) : (
          "* * * * * *"
        ),
    },
    { id: "status", label: "Status" },
    {
      id: "topicType",
      label: "Type",
      value: (row: ITableRow) => getTopicTypeLabel(row.topicType),
    },
    {
      id: "multicast",
      label: "Multicast",
      value: (row: ITableRow) => (row.multicast ? "Yes" : "No"),
    },
    { id: "modifiedBy", label: "Modified by", value: (row: ITableRow) => row.modifiedBy.fullName },
    {
      id: "modifiedAt",
      label: "Modified at",
      value: (row: ITableRow) => getFormattedDate(row.modifiedAt),
    },
  ]);

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...topicList,
    },
    name: "topics",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const handleOpenModal = () => {
    dispatch(editModalOpenAction({ type: modalTypeEnum.NEW_TOPIC }));
  };

  const [filterItems, setFilterItems] = useState([
    {
      name: "topicName",
      placeholder: "Topic name",
      taClass: "topicName",
      type: FilterType.TEXT,
    },
    {
      name: "topicOwner",
      placeholder: "Topic owner",
      taClass: "topicOwner",
      type: FilterType.TEXT,
    },
    {
      data: statusOptions,
      name: "status",
      placeholder: "Status",
      taClass: "status",
      type: FilterType.DROPDOWN,
    },
    {
      data: typeOptions,
      name: "topicType",
      placeholder: "Type",
      taClass: "type",
      type: FilterType.DROPDOWN,
      width: "1.5fr",
    },
    {
      data: multicastOptions,
      name: "multicast",
      placeholder: "Multicast",
      taClass: "multicast",
      type: FilterType.DROPDOWN,
    },
    {
      name: "modifiedBy",
      placeholder: "Modified by",
      taClass: "modifiedBy",
      type: FilterType.TEXT,
    },
    {
      name: "modifiedDate",
      placeholder: "Modified",
      taClass: "modifiedDate",
      type: FilterType.DATEPICKER,
    },
  ]);

  const filterConfig: IFilterConfig = {
    customElements: [
      {
        element: (
          <SButtonIcon
            size="default"
            icon={<SIconAdd32 />}
            onClick={handleOpenModal}
            className={getTAClass("topics", TA_TYPES.BUTTON, "createNew")}
            // disabled={currentUser.data.role === READ}
            disabled={currentUser.data.role === READ}
          />
        ),
        width: "0.25fr",
      },
    ],
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: filterItems,
    pageName: "topics",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  useEffect(() => {
    if (!currentUser.data || currentUser.data.role !== ADMINISTRATOR) {
      // for non-admin user - remove 'multicast' column/header/filter
      headCells.splice(4, 1);
      filterItems.splice(4, 1);
    }

    // apply header/filter update
    setHeadCells(headCells);
    setFilterItems(filterItems);
  }, [currentUser]);

  return (
    <>
      {/* LOADING */}
      {topicList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}

      <SPageContainer>
        <SPageHeading className="ta-topics-title">Topics</SPageHeading>

        <Filter {...filterConfig} />

        <SSpacer />
        <Table {...tableProps}></Table>
      </SPageContainer>

      {/* CREATE TOPIC MODAL */}
      {editModal && editModal.open && editModal.type === modalTypeEnum.NEW_TOPIC ? (
        <ModalCreateTopic />
      ) : (
        ""
      )}
    </>
  );
};
