import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { RootState } from "../store";

interface IPrivateRoute extends RouteProps {
  userLevelAccess?: string[];
}

const PrivateRoute = (props: IPrivateRoute) => {
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));

  const checkAccess = () => {
    if (props.userLevelAccess) {
      return props.userLevelAccess.indexOf(currentUser.data.role) !== -1;
    }

    return true;
  };

  return checkAccess() ? (
    // AUTHORIZED
    <Route {...props} />
  ) : (
    // AUTHORIZATION FAILED
    <Redirect to={{ pathname: "/error", state: { from: props.location } }} />
  );
};

export default PrivateRoute;
