/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum GetConfigBrokerType {
  REQUEST = "GET_CONFIG_BROKER_REQUEST",
  SUCCESS = "GET_CONFIG_BROKER_SUCCESS",
  ERROR = "GET_CONFIG_BROKER_ERROR",
}

export interface IGetConfigBrokerRequestAction extends IReduxBaseAction<GetConfigBrokerType> {
  type: GetConfigBrokerType.REQUEST;
}

export interface IGetConfigBrokerSuccessAction extends IReduxBaseAction<GetConfigBrokerType> {
  payload: any;
  type: GetConfigBrokerType.SUCCESS;
}

export interface IGetConfigBrokerErrorAction extends IReduxBaseAction<GetConfigBrokerType> {
  payload: string;
  type: GetConfigBrokerType.ERROR;
}

export type TGetConfigBrokerActions =
  | IGetConfigBrokerRequestAction
  | IGetConfigBrokerSuccessAction
  | IGetConfigBrokerErrorAction;
