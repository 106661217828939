import { ButtonPrimary, Grid, GridItem, ProgressIndicatorLinear, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { getUserList } from "../../actions/user/get-list/actions";
import { getAppOwnersCSV } from "../../helper/util";
import { OrderType } from "../../models/ITableConfig";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../../models/UserTypes";
import { userFilterSortInitialState } from "../../reducers/user/UserFilterSortReducer";
import {
  SChangeLogContent,
  SFlexContainer,
  SNextFixedHeightContainer,
  SOverlay,
  SPayloadSchema,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextWrapper,
} from "../../styles/styles";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";
import { ModalSelectAircraftGroupsPage1 } from "./ModalSelectAircraftGroupsPage1";

export const ModalSelectAircraftGroups = (props: any) => {
  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = false; // comment required?
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);
  const [dialogTitle, setDialogTitle] = useState("Select aircraft groups");
  const [commentHint, setCommentHint] = useState(
    "Please enter a comment about why you are making this change."
  );
  const [currentPage, setCurrentPage] = useState(0);

  const [config, setConfig] = useState({
    changeComment: "",
    groups: [{}],
  });

  useEffect(() => {
    if (editModal.popupData.title && editModal.popupData.title.length > 0) {
      setDialogTitle(editModal.popupData.title);
    }

    if (editModal.popupData.commentHint && editModal.popupData.commentHint.length > 0) {
      setCommentHint(editModal.popupData.commentHint);
    }

    const emptyGroups: any[] = [];
    setConfig({
      ...config,
      groups: emptyGroups,
    });
  }, [editModal]);

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      const grpIds: string[] = [];
      config.groups.forEach((group: any) => {
        grpIds.push(group.aircraftGroupId);
      });
      editModal.popupData.onConfirm({
        changeComment: config.changeComment,
        groupIds: grpIds,
      });
    }
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkValidity = () => {
    const isFormValid = config.groups.length >= 1;

    handleSetValidPage(0, isFormValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkValidity();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config]);

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  const modalTheme = {
    modal: {
      width: "800px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle} handleCloseButton={editModal.popupData.onCancel}>
        {currentPage === 0 && (
          <ModalSelectAircraftGroupsPage1
            groupEnv={editModal.popupData.groupEnv}
            tailNumbers={editModal.popupData.tailNumbers}
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            tenantId={editModal.popupData.tenantId}
          />
        )}

        <SSpacer />

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={commentHint}
            commentRequired={changeCommentRequired}
          />
        )}

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage !== 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 0}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage < 1 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Next
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                className="ta-modal-confirm-button"
                onClick={handleConfirm}
                disabled={!validPage[Number(currentPage)]}
              >
                {editModal.popupData.confirmText ? (
                  <>{editModal.popupData.confirmText}</>
                ) : (
                  <>Confirm</>
                )}
              </ButtonPrimary>
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
