import { AppTopicListType, TAppTopicListActions } from "../../actions/application/get-topics/types";
import { IGetTopicListContent } from "../../models/ITopicConfig";

interface ITopicListState {
  content: IGetTopicListContent[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const applicationTopicList = (
  state: ITopicListState = initialState,
  action: TAppTopicListActions
): ITopicListState => {
  switch (action.type) {
    case AppTopicListType.CLEAR:
      return initialState;
    case AppTopicListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case AppTopicListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case AppTopicListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
