import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
// import Grid from "../../Guidelines/Grid/Grid";
import { Grid } from "next-components";
// import { Striped } from "../Base/DataTableBase";
import * as variables from "../../variables";
import colors from "../../colors";
import setCssIfProp from "../../utils/setCssIfProp";
import { prototype } from "simplebar";

function _extends() {
  _extends =
    Object.assign ||
    function(target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
      return target;
    };
  return _extends.apply(this, arguments);
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([
    "\n      :hover {\n        background-color: ",
    ";\n        box-shadow: inset 4px 0px 0px 0px transparent;\n      }\n    ",
  ]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral([
    "\n      background-color: ",
    ";\n      box-shadow: inset 4px 0px 0px 0px ",
    ";\n    ",
  ]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral([
    "\n      :nth-child(odd) {\n        background-color: ",
    ";\n      }\n    ",
  ]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([
    "\n      :nth-child(even) {\n        background-color: ",
    ";\n      }\n    ",
  ]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([
    "\n  grid-template-columns: ",
    ";\n  gap: ",
    ";\n  align-items: stretch;\n\n  width: 100%;\n\n background-color: ",
    ";\n  border-bottom: 1px solid ",
    ";\n  box-shadow: inset 4px 0px 0px 0px transparent;\n\n\n  cursor: pointer;\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n\n  ",
    "\n  ",
    "\n",
  ]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}

var Striped = Object.freeze({
  none: "none",
  even: "even",
  odd: "odd",
});

var RowGrid = styled(Grid)(
  _templateObject(),
  function(props) {
    return props.columns;
  },
  function(props) {
    return props.gap;
  },
  colors.general.white,
  colors.greys.default,
  function(props) {
    return (
      props.striped === Striped.even &&
      css(_templateObject2(), function(props) {
        return props.selected ? colors.primary.sl2 : colors.greys.lighten25;
      })
    );
  },
  function(props) {
    return (
      props.striped === Striped.odd &&
      css(_templateObject3(), function(props) {
        return props.selected ? colors.primary.sl2 : colors.greys.lighten25;
      })
    );
  },
  function(props) {
    return props.selected && css(_templateObject4(), colors.primary.sl2, colors.primary["default"]);
  },
  function(props) {
    return !props.selected && !props.isHeader && css(_templateObject5(), colors.greys.lighten20);
  },
  function(props) {
    return setCssIfProp("padding-left", props.paddingLeft);
  },
  function(props) {
    return setCssIfProp("padding-right", props.paddingRight);
  },
  function(props) {
    return setCssIfProp("height", props.height);
  }
);

var Row = function Row(props) {
  return React.createElement(
    RowGrid,
    _extends(
      {
        alignItems: "center",
      },
      props
    )
  );
};

Row.propTypes = {
  columns: PropTypes.string,
  gap: PropTypes.string,

  /**
   * Only set if given.
   */
  height: PropTypes.string,

  /** Zebra striped rows */
  striped: PropTypes.oneOf([Striped.none, Striped.even, Striped.odd]),
  selected: PropTypes.bool,
  isHeader: PropTypes.bool,

  /**
   * Only set if given.
   */
  paddingLeft: PropTypes.string,

  /**
   * Only set if given.
   */
  paddingRight: PropTypes.string,

  children: PropTypes.node,
  className: PropTypes.string,
  key: PropTypes.string,
  onClick: PropTypes.func,
};
Row.defaultProps = {
  columns: "auto",
  gap: variables.getSize(2),
  // height: variables.getSize(12),
  striped: Striped.none,
  selected: false,
  isHeader: false,
  children: null,
};
Row.displayName = "Row";
export default Row;
