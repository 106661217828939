import { ButtonPrimary, ProgressIndicatorCircular, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createApplication } from "../../actions/application/create/actions";
import { brokerLocationEnum } from "../../models/IBrokerTypeListingTableConfig";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";
import {
  ClientPasswordValidationErrorCodeEnum as ClientPasswordError,
  ClientPasswordValidator,
} from "../ClientPasswordValidator";
import { ClientUsernameValidator } from "../ClientUsernameValidator";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateApplicationLocationSelection } from "./ModalCreateApplicationLocationSelection";
import {
  connRetryDelaySecValues,
  isValidConnRetryDelaySec,
  isValidNumConnRetries,
  ModalCreateApplicationPage1,
  numConnRetriesValues,
} from "./ModalCreateApplicationPage1";
import { ModalCreateBase } from "./ModalCreateBase";
import { ModalSQSInfoPage } from "./ModalSQSInfoPage";

export const ModalCreateApplication = () => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser } = useSelector((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { applicationCreation } = useSelector((state: any) => ({
    applicationCreation: state.applicationCreation,
  }));

  enum authTypeEnum {
    CREDENTIALS = "Credentials",
  }

  const changeCommentRequired = true; // app create does require comment
  const [validPage, setValidPage] = useState([true, false, !changeCommentRequired]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState("New application");
  const [isSqsIntegration, setIsSqsIntegration] = useState(false);

  const [authType, setAuthType] = useState(authTypeEnum.CREDENTIALS);

  const [config, setConfig] = useState({
    appClientLocation: brokerLocationEnum.AIRSIDE,
    appName: "",
    appVersion: "",
    changeComment: "",
    connRetryDelaySec: connRetryDelaySecValues.default,
    fullName: "",
    internal: false,
    numConnRetries: numConnRetriesValues.default,
    password: "",
    pathClientCa: "",
    pathToCa: "",
    systemName: "",
    user: {
      email: "",
      firstName: "",
      lastName: "",
      role: "",
      userId: "",
    },
    userName: "",
  });

  useEffect(() => {
    setConfig({ ...config, fullName: currentUser.data.fullName });
  }, []);

  useEffect(() => {
    setConfig({
      ...config,
      fullName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
      internal: currentUser.data.internal,
      user: {
        email: currentUser.data.email,
        firstName: currentUser.data.firstName,
        lastName: currentUser.data.lastName,
        role: currentUser.data.role,
        userId: currentUser.data.userId,
      },
    });
  }, [currentUser]);

  const handleSubmit = () => {
    dispatch(
      createApplication(
        { ...config, authenticationType: authTypeEnum.CREDENTIALS },
        (appId: any) => {
          history.push(`/applicationClients/${appId}`);
        }
      )
    );
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkFormFill = () => {
    let appSettingsValid = false;

    if (config.appClientLocation === brokerLocationEnum.AIRSIDE) {
      let authValid = false;

      if (authType === authTypeEnum.CREDENTIALS) {
        authValid =
          !!config.userName &&
          !!config.password &&
          ClientPasswordError.ERROR_NONE ===
            ClientPasswordValidator.validatePassword(config.password) &&
          ClientUsernameValidator.validateUsername(config.userName);
      }

      const numConnRetriesValid = isValidNumConnRetries(config.numConnRetries);
      const connRetryDelaySecValid = isValidConnRetryDelaySec(config.connRetryDelaySec);

      appSettingsValid = authValid && numConnRetriesValid && connRetryDelaySecValid;
    } else {
      // no more endpoint settings here
      appSettingsValid = true;
    }

    const isFormValid =
      !!config.appName && !!config.appVersion && !!config.user.userId && appSettingsValid;

    handleSetValidPage(1, isFormValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(2, isCommentValid);
  };

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  useEffect(() => {
    if (currentPage === 1) {
      checkFormFill();
    } else if (currentPage === 2) {
      checkCommentInput();
    }
  }, [config]);

  useEffect(() => {
    if (currentPage === 0) {
      setDialogTitle("New application");
    } else {
      const appLocation =
        config.appClientLocation === brokerLocationEnum.AIRSIDE ? "airside" : "groundside";
      setDialogTitle(
        currentPage !== 3
          ? `New ${appLocation} application`
          : `How to configure ${isSqsIntegration ? "integration" : "production"} destination`
      );
    }
  }, [currentPage]);

  const modalTheme = {
    modal: {
      width: "650px", // Increase the width of the modal dialog since we increased password label
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {currentPage === 0 && (
          <ModalCreateApplicationLocationSelection
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            nextPage={() => {
              setCurrentPage(currentPage + 1);
            }}
          />
        )}

        {currentPage === 1 && (
          <ModalCreateApplicationPage1
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            onSqsInfo={(isIntegration: boolean) => {
              setIsSqsIntegration(isIntegration);
              setCurrentPage(3);
            }}
          />
        )}

        {currentPage === 2 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are creating this application.`}
            commentRequired={changeCommentRequired}
          />
        )}

        {/* {currentPage === 3 && (
          <ModalSQSInfoPage
            isIntegration={isSqsIntegration}
            sqsUrl={
              isSqsIntegration
                ? config.intGroundDestinationEndpoint
                : config.groundDestinationEndpoint
            }
          />
        )} */}

        {!applicationCreation.loading ? (
          <SFlexContainer justifyContent="space-between">
            <SFlexContainer justifyContent="flex-start">
              {currentPage === 2 && (
                <ButtonPrimary
                  className="ta-modal-previous-button"
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  disabled={currentPage < 2}
                >
                  Previous
                </ButtonPrimary>
              )}
            </SFlexContainer>
            <SFlexContainer justifyContent="flex-end">
              {currentPage === 1 ? (
                <ButtonPrimary
                  className="ta-modal-next-button"
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  disabled={!validPage[Number(currentPage)]}
                >
                  Next
                </ButtonPrimary>
              ) : currentPage === 2 ? (
                <ButtonPrimary
                  onClick={handleSubmit}
                  disabled={!validPage[Number(currentPage)]}
                  className="ta-modal-submit-button"
                >
                  Save
                </ButtonPrimary>
              ) : currentPage === 3 ? (
                <ButtonPrimary
                  className="ta-modal-back-button"
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                >
                  Back
                </ButtonPrimary>
              ) : (
                ""
              )}
            </SFlexContainer>
          </SFlexContainer>
        ) : (
          <SFlexContainer justifyContent="space-around">
            <ProgressIndicatorCircular size="small" />
          </SFlexContainer>
        )}
      </ModalCreateBase>
    </ThemeProvider>
  );
};
