import { List } from "@material-ui/core";
import deepEqual from "deep-equal";
import { ButtonPrimary, GridItem, ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  DestinationRouteListClearAction,
  getDestinationRouteList,
} from "../../../actions/destination/get-destination-routes/actions";
import { removeDestinationRoute } from "../../../actions/destination/remove-destination-route/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate, getTenantLabel, isUserAppOwner } from "../../../helper/util";
import { AircraftGroupTypeEnum } from "../../../models/IAircraftGroup";
import { newDestinationId } from "../../../models/IDestination";
import { AppDestinationRouteTypeEnum } from "../../../models/IDestinationRoute";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { ApplicationService } from "../../../services/ApplicationService";
import { RootState } from "../../../store";
import { SActiveLink, SOverlay, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { Table } from "../../Table";

export const AppDestinationSourceList = (props: any) => {
  const history = useHistory();

  const navigateToPage = (url: string): void => {
    history.push(url);
  };

  const isGlobalGroupDisabled = (row: ITableRow) =>
    props.btnDisabled ||
    (!props.isAdmin &&
      row.routeType === AppDestinationRouteTypeEnum.GROUP &&
      row.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL);

  const groupTypeOptions = [
    {
      label: <span className="ta-dropdown-tenant">Airline</span>,
      value: AircraftGroupTypeEnum.TENANT,
    },
    {
      label: <span className="ta-dropdown-global">Global</span>,
      value: AircraftGroupTypeEnum.GLOBAL,
    },
  ];

  const headCells = [
    {
      id: "routeName",
      label: "Source name",
      onClickCell: (row: ITableRow) => {
        if (!props.noLink && !isGlobalGroupDisabled(row)) {
          if (row.routeType === AppDestinationRouteTypeEnum.GROUP) {
            navigateToPage(`/aircraftGroups/${row.aircraftGroupId}`);
          } else {
            navigateToPage(`/airlines/${row.tenantId}`);
          }
        }
      },
      value: (row: ITableRow) =>
        props.noLink || isGlobalGroupDisabled(row) ? (
          row.routeType === AppDestinationRouteTypeEnum.GROUP ? (
            <>{row.aircraftGroupName}</>
          ) : (
            <>{`${row.tenantName} - ${row.icao.toUpperCase()} (all aircrafts)`}</>
          )
        ) : row.routeType === AppDestinationRouteTypeEnum.GROUP ? (
          <SActiveLink>{row.aircraftGroupName}</SActiveLink>
        ) : (
          <SActiveLink>{`${
            row.tenantName
          } - ${row.icao.toUpperCase()} (all aircrafts)`}</SActiveLink>
        ),
      width: "5fr",
    },
    // {
    //   id: "aircraftCount",
    //   label: "Aircraft count",
    //   value: (row: ITableRow) => `${row.aircraftCount}`,
    // },
    // {
    //   id: "rackCount",
    //   label: "Rack count",
    //   value: (row: ITableRow) => `${row.rackCount}`,
    // },
    {
      id: "",
      label: "Type",
      value: (row: ITableRow) =>
        `${
          row.routeType === AppDestinationRouteTypeEnum.GROUP
            ? row.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL
              ? "Global group"
              : "Airline group"
            : "Airline"
        }`,
      width: "2fr",
    },
    {
      id: "createdBy",
      label: "Created by",
      value: (row: ITableRow) => (row.createdBy ? row.createdBy.fullName : "Unknown"),
      width: "3fr",
    },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
      width: "3fr",
    },
    { id: "delete", label: "Delete" },
  ];

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { appDestinationRouteList } = useSelector((state: RootState) => ({
    appDestinationRouteList: state.appDestinationRouteList,
  }));

  const emptyFilterObject = {
    // aircraftGroupType: "",
    // modifiedBy: "",
    // modifiedDate: { startDate: undefined, endDate: undefined },
    createdBy: "",
    createdDate: { startDate: undefined, endDate: undefined },
    routeName: "",
  };

  const [config, setConfig] = useState({
    filter: { ...emptyFilterObject },
    limit: 10,
    orderBy: "routeName",
    orderType: OrderType.ASC,
    page: 0,
  });

  // Wait for the first filter set
  useEffect(() => {
    if (
      props.appData &&
      props.destData &&
      props.destData.appClientDestinationId !== newDestinationId
    ) {
      dispatch(
        getDestinationRouteList(
          props.appData.appClientId,
          props.destData.appClientDestinationId,
          config
        )
      );
    }
  }, [config]);

  useEffect(() => {
    dispatch(DestinationRouteListClearAction());
  }, []);

  const handleRemoveSourceGroup = (routeId: string) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: "Please enter a comment about why you are removing this source group.",
          commentRequired: false,
          contentText: "You are trying to remove source group, do you want to continue?",
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              removeDestinationRoute(
                props.appData.appClientId,
                props.destData.appClientDestinationId,
                routeId,
                param.changeComment,
                () => {
                  setConfig({ ...config, page: 0 });
                }
              )
            );
          },
          title: `Remove source group`,
        },
        type: modalTypeEnum.REMOVE_ROUTE_GROUP,
      })
    );
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    deleteCell: {
      as: "a",
      disabled: isGlobalGroupDisabled,
      onClick: (row: ITableRow) => {
        if (!isGlobalGroupDisabled(row)) {
          handleRemoveSourceGroup(row.appClientDestinationRouteId);
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: {
      ...appDestinationRouteList,
    },
    name: "route-groups",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  const [filterItems, setFilterItems] = useState([
    {
      name: "routeName",
      placeholder: "Source name",
      taClass: "route-group-name",
      type: FilterType.TEXT,
    },
    // {
    //   data: groupTypeOptions,
    //   name: "aircraftGroupType",
    //   placeholder: "Group Type",
    //   taClass: "route-group-type",
    //   type: FilterType.DROPDOWN,
    //   // width: "1.5fr",
    // },
    {
      name: "createdBy",
      placeholder: "Created by",
      taClass: "createdBy",
      type: FilterType.TEXT,
    },
    {
      name: "createdDate",
      placeholder: "Created",
      taClass: "createdDate",
      type: FilterType.DATEPICKER,
    },
  ]);

  const filterConfig: IFilterConfig = {
    customElements: [
      {
        element: (
          <GridItem justifySelf="end">
            <ButtonPrimary
              className={getTAClass("route-group", TA_TYPES.BUTTON, "add-route-group")}
              onClick={() => {
                props.btnClick(config);

                return;
              }}
              disabled={
                props.btnDisabled ||
                props.destData.appClientDestinationId === newDestinationId ||
                tableProps.list.loading
              }
            >
              {<>Add source</>}
            </ButtonPrimary>
          </GridItem>
        ),
      },
    ],
    items: filterItems,
    pageName: "route-groups",
    returnFilter: (filter: any) => {
      const newFilterObject = {
        ...emptyFilterObject,
        ...filter,
      };

      if (!deepEqual(config.filter, newFilterObject)) {
        setConfig({
          ...config,
          filter: {
            ...newFilterObject,
          },
          page: 0,
        });
      }
    },
  };

  return (
    <>
      <Filter {...filterConfig} />
      {/* FILTER */}

      <SSpacer />

      {/* LOADING */}
      {appDestinationRouteList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {/* TABLE */}
      {!appDestinationRouteList.loading && <Table {...tableProps} />}
    </>
  );
};
