import { Dispatch } from "redux";

import { ITopologyTableConfig } from "../../models/ITopologyConfig";
import { TopologyService } from "../../services/TopologyService";

import {
  GetTopologyListType,
  IGetTopologyListErrorAction,
  IGetTopologyListRequestAction,
  IGetTopologyListSuccessAction,
  TTopologyListActions,
} from "./types";

export const topologyListRequestAction = (): IGetTopologyListRequestAction => ({
  type: GetTopologyListType.REQUEST,
});

export const topologyListSuccessAction = (data: any): IGetTopologyListSuccessAction => ({
  payload: data,
  type: GetTopologyListType.SUCCESS,
});

export const topologyListErrorAction = (error: any): IGetTopologyListErrorAction => ({
  payload: error,
  type: GetTopologyListType.ERROR,
});

export const getTopologies = (conf: ITopologyTableConfig) => (
  dispatch: Dispatch<TTopologyListActions>
) => {
  dispatch(topologyListRequestAction());
  TopologyService.getTopologies(conf).then(
    (data: { data: any }) => {
      dispatch(topologyListSuccessAction(data.data));
    },
    (error: any) => dispatch(topologyListErrorAction(error))
  );
};
