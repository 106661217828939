import styled, { css } from "styled-components";

/**
 * Cloud bar container with open and close animation
 */
export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;

export const LargeSideBarContainer = styled.div<{ expanded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform 200ms;
  backface-visibility: hidden;

  ${props =>
    props.expanded &&
    css`
      transform: translate3d(0, 0, 0);
    `}
`;
