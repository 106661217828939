import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum RegenerateCertTypes {
  ERROR = "REGENERATE_CERT_ERROR",
  REQUEST = "REGENERATE_CERT_REQUEST",
  SUCCESS = "REGENERATE_CERT_SUCCESS",
}

export interface IRegenerateCertRequestAction extends IReduxBaseAction<RegenerateCertTypes> {
  payload: string;
  type: RegenerateCertTypes.REQUEST;
}

export interface IRegenerateCertSuccessAction extends IReduxBaseAction<RegenerateCertTypes> {
  payload?: any;
  type: RegenerateCertTypes.SUCCESS;
}

export interface IRegenerateCertErrorAction extends IReduxBaseAction<RegenerateCertTypes> {
  payload: any;
  type: RegenerateCertTypes.ERROR;
}

export type TRegenerateCertActions =
  | IRegenerateCertErrorAction
  | IRegenerateCertRequestAction
  | IRegenerateCertSuccessAction;
