import deepEqual from "deep-equal";
import moment from "moment-timezone";
import { ButtonIcon, ButtonPrimary, Grid, GridItem, IconArrowLeft32 } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { popupOpenAction } from "../../../actions/popup/actions";
import { getUserDetails } from "../../../actions/user/get-details/actions";
import { setUserDetails } from "../../../actions/user/set-details/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getTenantLabel } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { PermissionActionEnum } from "../../../models/IPermissionActions";
import { getUserRoleStatusByEnum, userRoleStatusEnum } from "../../../models/IUserRoleStatus";
import { userTabEnum } from "../../../models/IUserTabs";
import {
  ADMINISTRATOR,
  APPLICATION_OWNER,
  APPROVER,
  getUserRole,
  READ,
} from "../../../models/UserTypes";
import { RootState } from "../../../store";
import {
  SFlexContainer,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { ModalEditUser } from "../../modals/ModalEditUser";
import { PageError, PageLoading } from "../../PageState";

import { UserDetailsDetails } from "./UserDetailsDetails";
import { UserOwnsApplication } from "./UserOwnsApplication";
import { UserTabs } from "./UserTabs";

// tslint:disable-next-line: cyclomatic-complexity
export const UserDetails = ({ match, history }: { history: any; match: any }) => {
  const { currentUser, editModal, userDetails, userSetDetails } = useSelector(
    (state: RootState) => ({
      currentUser: state.currentUser,
      editModal: state.editModal,
      userDetails: state.userDetails,
      userSetDetails: state.userSetDetails,
    })
  );

  const permissionRequestQueryParamKey = "action";
  const requestIdQueryParamKey = "requestId";

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const [userId, setUserId] = useState("");
  const [activeTab, setActiveTab] = useState(userTabEnum.OWNS_APP_TABLE);
  const [isNeedGrantDenyButtons, setIsNeedGrantDenyButtons] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  const handleTabChange = (tab: userTabEnum) => {
    setActiveTab(tab);
  };

  const handlePermission = (isGrant: boolean): void => {
    if (currentUser.data.role === ADMINISTRATOR) {
      const userData = {
        requestId: userDetails.content.latestChangeRequest.requestId,
        requestStatus: isGrant ? PermissionActionEnum.GRANT : PermissionActionEnum.DENY,
        role: userDetails.content.latestChangeRequest.requestedRole,
        userId: userDetails.content.userId,
      };
      dispatch(setUserDetails(userData));
      history.push(history.location.pathname);
    }
  };

  const handleOpenEditModal = () => {
    dispatch(editModalOpenAction({ type: modalTypeEnum.EDIT_USER, userData: { ...userDetails } }));
  };

  useEffect(() => {
    if (match.params.userId !== "" && typeof match.params.userId !== "undefined") {
      setUserId(match.params.userId);
      dispatch(getUserDetails(match.params.userId));
    }
  }, [match.params.userId]);

  useEffect(() => {
    if (userDetails.content.userId && currentUser.data.role) {
      setIsNeedGrantDenyButtons(
        userDetails.content.latestChangeRequest &&
          userDetails.content.latestChangeRequest.status === userRoleStatusEnum.PENDING &&
          currentUser.data.role === ADMINISTRATOR
      );

      setIsCurrentUser(currentUser.data.userId === userDetails.content.userId);

      const queryParams = new URLSearchParams(history.location.search);
      const requestParamAction = queryParams.get(permissionRequestQueryParamKey);
      const requestParamRequestId = queryParams.get(requestIdQueryParamKey);
      if (!!requestParamAction && !!requestParamRequestId) {
        if (
          requestParamRequestId === userDetails.content.latestChangeRequest.requestId &&
          userDetails.content.latestChangeRequest.status === userRoleStatusEnum.PENDING
        ) {
          if (requestParamAction.toUpperCase() === PermissionActionEnum.GRANT) {
            handlePermission(true);
          } else if (requestParamAction.toUpperCase() === PermissionActionEnum.DENY) {
            handlePermission(false);
          }
        } else {
          dispatch(
            popupOpenAction({
              content:
                "The role change request might have been processed or updated in a newer request.",
              title: "Invalid role change request",
              type: "Info",
            })
          );
        }
      }
    }
  }, [currentUser, userDetails]);

  return (
    <>
      <SPageContainer>
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          {currentUser.data && currentUser.data.role === ADMINISTRATOR && (
            <GridItem>
              <Link to="/users" className="ta-user-details-button-back">
                <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
              </Link>
            </GridItem>
          )}
          <GridItem>
            <SPageHeading className="ta-user-details-title">User details</SPageHeading>
          </GridItem>
        </Grid>

        {(userDetails.loading || userSetDetails.loading) && <PageLoading />}

        {userDetails.error && <PageError />}

        {!userDetails.loading && !userSetDetails.loading && !userDetails.error && (
          <>
            {/* LOADED */}
            {userDetails.content && (
              <Grid
                columns={isNeedGrantDenyButtons ? "1.5fr 2.5fr 0.5fr" : "repeat(3, auto)"}
                rows="repeat(1, minmax(150px,auto))"
              >
                <GridItem>
                  <DataDetailLabel
                    title="First name"
                    value={userDetails.content.firstName}
                    className="ta-user-details-first-name"
                  ></DataDetailLabel>
                  <DataDetailLabel
                    title="Last name"
                    value={userDetails.content.lastName}
                    className="ta-user-details-last-name"
                  ></DataDetailLabel>
                  <DataDetailLabel
                    title="Email"
                    value={userDetails.content.email}
                    className="ta-user-details-email"
                  ></DataDetailLabel>
                  {/* <DataDetailLabel
                    title="Internal"
                    value={userDetails.content.internal ? "Yes" : "No"}
                    className="ta-user-details-internal"
                  ></DataDetailLabel> */}
                  <DataDetailLabel
                    title="Company"
                    value={getTenantLabel(userDetails.content)}
                    className="ta-user-details-internal"
                  ></DataDetailLabel>
                </GridItem>
                <GridItem>
                  <DataDetailLabel
                    title="Role"
                    value={getUserRole(userDetails.content.role)}
                    className="ta-user-details-role"
                  ></DataDetailLabel>
                  <SFlexContainer justifyContent="space-between">
                    <DataDetailLabel
                      title="Last permission requested"
                      value={
                        userDetails.content.latestChangeRequest === undefined ||
                        deepEqual(userDetails.content.latestChangeRequest, {}) ||
                        userDetails.content.latestChangeRequest.requestedRole === null
                          ? " - "
                          : `${getUserRole(
                              userDetails.content.latestChangeRequest.requestedRole
                            )} (${getUserRoleStatusByEnum(
                              userDetails.content.latestChangeRequest.status
                            )})`
                      }
                      className="ta-user-details-last-permission-requested"
                    ></DataDetailLabel>
                    {isNeedGrantDenyButtons && (
                      <SFlexContainer justifyContent="space-between">
                        <ButtonPrimary
                          className="ta-user-details-grant-btn"
                          onClick={() => {
                            handlePermission(true);
                          }}
                        >
                          Grant
                        </ButtonPrimary>
                        <SVerticalSpacer />
                        <ButtonPrimary
                          className="ta-user-details-deny-btn"
                          onClick={() => {
                            handlePermission(false);
                          }}
                        >
                          Deny
                        </ButtonPrimary>
                      </SFlexContainer>
                    )}
                  </SFlexContainer>
                  <DataDetailLabel
                    title="Last permission requested date"
                    value={
                      userDetails.content.latestChangeRequest === undefined ||
                      deepEqual(userDetails.content.latestChangeRequest, {}) ||
                      userDetails.content.latestChangeRequest.createdAt === null
                        ? " - "
                        : moment(userDetails.content.latestChangeRequest.createdAt)
                            // .tz("America/Los_Angeles")
                            .tz(moment.tz.guess())
                            .format("MMM/DD/YYYY HH:mm")
                    }
                    className="ta-user-details-last-permission-requested-date"
                  ></DataDetailLabel>
                </GridItem>
                <GridItem>
                  <SFlexContainer justifyContent="flex-end">
                    <ButtonPrimary
                      className={getTAClass("userDetails", TA_TYPES.BUTTON, "edit")}
                      disabled={isNeedGrantDenyButtons && currentUser.data.role === ADMINISTRATOR}
                      onClick={handleOpenEditModal}
                    >
                      {[APPROVER, APPLICATION_OWNER, READ].includes(currentUser.data.role)
                        ? "Request role change"
                        : "Edit"}
                    </ButtonPrimary>
                  </SFlexContainer>
                </GridItem>
              </Grid>
            )}

            <SSpacer />
            <UserTabs activeTab={activeTab} onChange={handleTabChange} />
            <SSpacer />

            {/* TAB CONTENTS */}
            {activeTab === userTabEnum.OWNS_APP_TABLE && (
              <UserOwnsApplication history={history} userId={userId} />
            )}
            {activeTab === userTabEnum.DETAILS && (
              <UserDetailsDetails userDetails={userDetails} isCurrentUser={isCurrentUser} />
            )}

            {/* Edit user role modal */}
            {editModal && editModal.open ? <ModalEditUser /> : ""}
          </>
        )}
      </SPageContainer>
    </>
  );
};
