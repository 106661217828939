import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */

export enum TGetApplicationDetails {
  ERROR = "APPLICATION_GETDETAILS_ERROR",
  REQUEST = "APPLICATION_GETDETAILS_REQUEST",
  SUCCESS = "APPLICATION_GETDETAILS_SUCCESS",
}

export interface IGetApplicationDetailsRequestAction
  extends IReduxBaseAction<TGetApplicationDetails> {
  type: TGetApplicationDetails.REQUEST;
}

export interface IGetApplicationDetailsSuccessAction
  extends IReduxBaseAction<TGetApplicationDetails> {
  payload: any;
  type: TGetApplicationDetails.SUCCESS;
}

export interface IGetApplicationDetailsErrorAction
  extends IReduxBaseAction<TGetApplicationDetails> {
  payload: any;
  type: TGetApplicationDetails.ERROR;
}

export type IGetApplicationDetailsActionType =
  | IGetApplicationDetailsRequestAction
  | IGetApplicationDetailsSuccessAction
  | IGetApplicationDetailsErrorAction;
