import { Dispatch } from "redux";

import { ChangeHistoryService } from "../../../services/ChangeHistoryService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  GetChangeLogTypes,
  IGetChangeLogErrorAction,
  IGetChangeLogRequestAction,
  IGetChangeLogSuccessAction,
  TGetChangeLogActions,
} from "./types";

export const GetChangeLogRequestAction = (): IGetChangeLogRequestAction => ({
  payload: null,
  type: GetChangeLogTypes.REQUEST,
});
export const GetChangeLogSuccessAction = (data: any): IGetChangeLogSuccessAction => ({
  payload: data,
  type: GetChangeLogTypes.SUCCESS,
});
export const GetChangeLogErrorAction = (error: any): IGetChangeLogErrorAction => ({
  payload: error,
  type: GetChangeLogTypes.ERROR,
});

// ACTION
export const getChangeLog =
  (isRel: boolean, cb?: any) => (dispatch: Dispatch<TPopupActions | TGetChangeLogActions>) => {
    dispatch(GetChangeLogRequestAction());
    ChangeHistoryService.getChangeLogFile(isRel).then(
      (data: { data: any }) => {
        // tslint:disable-next-line: ban-ts-ignore
        // @ts-ignore
        dispatch(GetChangeLogSuccessAction(data.data.data));
        if (cb) {
          cb(data.data.data.content);
        }
      },
      (error: any) => {
        dispatch(GetChangeLogErrorAction(error));
        dispatch(
          popupOpenAction({
            content: (error && error.message) || "",
            title: "Error",
            type: "Info",
          })
        );
      }
    );
  };
