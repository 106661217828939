export enum brokerLocationEnum {
  AIRSIDE = "airside",
  GROUNDSIDE = "groundside",
}

export interface IBrokerTypeListFilters {
  [key: string]: any;
  brokerLocation?: brokerLocationEnum | string;
  brokerType?: string;
  brokerTypeName?: string;
  systemName?: string;
}

export interface IBrokerTypeListingTableConfig {
  [key: string]: any;
  filter: IBrokerTypeListFilters;
  limit: number;
  orderBy: string;
  orderType: string;
  page: number;
}

export interface IGetBrokerTypeListData {
  content: IGetBrokerTypeListContent[];
  count: number;
  loading: boolean;
}

export interface IGetBrokerTypeListContent {
  brokerType: string;
  brokerTypeId: string;
  brokerTypeName: string;
  createdAt: string;
  createdBy: IGetBrokerTypeUser[];
  modifiedAt: string;
  modifiedBy: IGetBrokerTypeUser[];
  pathToCa: string;
  pathToCbaIca: string;
  pathToPrivate: string;
  systemType: string;
}

interface IGetBrokerTypeUser {
  fullName: string;
  userId: string;
}
