/* istanbul ignore file */
import { IFilterSortData } from "../../../models/IFilterSort";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ChangeHistoryFilterSortType {
  CLEAR = "CHANGE_HISTORY_FILTER_SORT_CLEAR",
  RESET = "CHANGE_HISTORY_FILTER_SORT_RESET",
  APPLY = "CHANGE_HISTORY_FILTER_SORT_APPLY",
}

export interface IChangeHistoryFilterSortClearAction
  extends IReduxBaseAction<ChangeHistoryFilterSortType> {
  type: ChangeHistoryFilterSortType.CLEAR;
}

export interface IChangeHistoryFilterSortResetAction
  extends IReduxBaseAction<ChangeHistoryFilterSortType> {
  type: ChangeHistoryFilterSortType.RESET;
}

export interface IChangeHistoryFilterSortApplyAction
  extends IReduxBaseAction<ChangeHistoryFilterSortType> {
  payload: IFilterSortData;
  type: ChangeHistoryFilterSortType.APPLY;
}

export type TChangeHistoryFilterSortActions =
  | IChangeHistoryFilterSortClearAction
  | IChangeHistoryFilterSortResetAction
  | IChangeHistoryFilterSortApplyAction;
