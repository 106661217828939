import { Dispatch } from "redux";

import { IGetBrokerTypeListContent } from "../../../models/IBrokerTypeListingTableConfig";
import { BrokerTypeService } from "../../../services/BrokerTypeService";

import {
  IGetBrokerTypeDetailsActionType,
  IGetBrokerTypeDetailsErrorAction,
  IGetBrokerTypeDetailsRequestAction,
  IGetBrokerTypeDetailsSuccessAction,
  TGetBrokerTypeDetails,
} from "./types";

export const getBrokerTypeDetailsRequestAction = (): IGetBrokerTypeDetailsRequestAction => ({
  type: TGetBrokerTypeDetails.REQUEST,
});
export const getBrokerTypeDetailsSuccessAction = (
  data: any
): IGetBrokerTypeDetailsSuccessAction => ({
  payload: data,
  type: TGetBrokerTypeDetails.SUCCESS,
});
export const getBrokerTypeDetailsErrorAction = (error: any): IGetBrokerTypeDetailsErrorAction => ({
  payload: error,
  type: TGetBrokerTypeDetails.ERROR,
});

// GET BROKER TYPE
export const getBrokerTypeDetails = (brokerTypeId: any) => (
  dispatch: Dispatch<IGetBrokerTypeDetailsActionType>
) => {
  dispatch(getBrokerTypeDetailsRequestAction());
  BrokerTypeService.getBrokerTypeDetails(brokerTypeId).then(
    (data: { data: IGetBrokerTypeListContent }) => {
      dispatch(getBrokerTypeDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(getBrokerTypeDetailsErrorAction(error))
  );
};
