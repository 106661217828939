import { Dispatch } from "redux";

import { AircraftGroupService } from "../../../services/AircraftGroupService";

import {
  IGetAircraftGroupDetailsActionType,
  IGetAircraftGroupDetailsErrorAction,
  IGetAircraftGroupDetailsRequestAction,
  IGetAircraftGroupDetailsSuccessAction,
  TGetAircraftGroupDetails,
} from "./types";

export const GetAircraftGroupDetailsRequestAction = (): IGetAircraftGroupDetailsRequestAction => ({
  type: TGetAircraftGroupDetails.REQUEST,
});
export const GetAircraftGroupDetailsSuccessAction = (
  data: any
): IGetAircraftGroupDetailsSuccessAction => ({
  payload: data,
  type: TGetAircraftGroupDetails.SUCCESS,
});
export const GetAircraftGroupDetailsErrorAction = (
  error: any
): IGetAircraftGroupDetailsErrorAction => ({
  payload: error,
  type: TGetAircraftGroupDetails.ERROR,
});

// GET Aaircraft details
export const getAircraftGroupDetails = (acGroupId: any) => (
  dispatch: Dispatch<IGetAircraftGroupDetailsActionType>
) => {
  dispatch(GetAircraftGroupDetailsRequestAction());
  AircraftGroupService.getAircraftGroupDetails(acGroupId).then(
    (data: { data: any }) => {
      dispatch(GetAircraftGroupDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(GetAircraftGroupDetailsErrorAction(error))
  );
};
