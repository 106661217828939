import { Grid, GridItem, ProgressIndicatorLinear, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import DiffViewer, { DiffMethod } from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { getGndChangeLog } from "../../actions/changelog/get-gnd/actions";
import { RootState } from "../../store";
import {
  SChangeLogContent,
  SFlexContainer,
  SNextFixedHeightContainer,
  SOverlay,
  SPayloadSchema,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextWrapper,
} from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalDisplayGndChangeLog = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal, gndChangeLogGet } = useSelector((state: any) => ({
    editModal: state.editModal,
    gndChangeLogGet: state.gndChangeLogGet,
  }));

  const [dialogTitle, setDialogTitle] = useState("View integration changes");

  useEffect(() => {
    if (!editModal.popupData.isIntegration) {
      setDialogTitle("View production changes");
    }
    dispatch(getGndChangeLog(editModal.popupData.isIntegration));
  }, [editModal]);

  const modalTheme = {
    modal: {
      width: "1024px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {gndChangeLogGet.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        {gndChangeLogGet.fromConfigVersion && !!gndChangeLogGet.fromConfigVersion.version && (
          <SFlexContainer justifyContent="flex-start">
            <SSpacer height="4px" />
            <STextDataValue>
              Changes since last deployed version {gndChangeLogGet.fromConfigVersion.version}
            </STextDataValue>
            <SSpacer height="8px" />
          </SFlexContainer>
        )}

        <SChangeLogContent
          placeholder="Change log data"
          readonly={true}
          value={
            gndChangeLogGet.loading
              ? "loading..."
              : gndChangeLogGet.content.length > 0
                ? gndChangeLogGet.content
                : "Change log data not available"
          }
        />
      </ModalCreateBase>
    </ThemeProvider>
  );
};
