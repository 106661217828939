import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isUserAppOwner } from "../../../helper/util";
import { IGetTopicListData } from "../../../models/ITopicConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { TopicService } from "../../../services/TopicService";
import { RootState } from "../../../store";
import { TopicClientList } from "../../TopicClientList";

export const TopicDetailsPublishList = (props: any) => {
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [subCount, setSubCount] = useState(0);

  const { topicDetails } = useSelector((state: RootState) => ({
    topicDetails: state.topicDetails,
  }));

  useEffect(() => {
    TopicService.getTopicClientList({
      filter: {
        clientType: "SUBSCRIBER",
        topicId: topicDetails.content.topic.topicId,
      },
      limit: 1,
      orderBy: "topicName",
      orderType: "ASC",
    })
      .then(({ data }: { data: { data: IGetTopicListData } }) => {
        setSubCount(data.data.count);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [topicDetails]);

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);

      if (props.appContent) {
        setIsOwner(
          // props.appContent.appOwner && props.appContent.appOwner.userId === props.userData.userId
          props.appContent.appOwners && isUserAppOwner(props.userData, props.appContent.appOwners)
        );
      }
    }
  }, [props]);

  return <TopicClientList isAdmin={isAdmin} type="PUBLISHER" subscriberCount={subCount} />;
};
