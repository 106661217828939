import { Dispatch } from "redux";

import {
  IApplicationListingTableConfig,
  IGetApplicationListData,
} from "../../../models/IAppListingTableConfig";
import { ApplicationService } from "../../../services/ApplicationService";

import { ApplicationListType, TApplicationActions } from "./types";

export const applicationRequestAction = (): TApplicationActions => ({
  type: ApplicationListType.REQUEST,
});

export const applicationSuccessAction = (data: any): TApplicationActions => ({
  payload: data,
  type: ApplicationListType.SUCCESS,
});

export const applicationErrorAction = (error: string): TApplicationActions => ({
  payload: error,
  type: ApplicationListType.ERROR,
});

export const getApplicationList = (conf: IApplicationListingTableConfig) => (
  dispatch: Dispatch<TApplicationActions>
) => {
  dispatch(applicationRequestAction());
  ApplicationService.getApplicationList(conf).then(
    (response: { data: { data: IGetApplicationListData } }) => {
      dispatch(applicationSuccessAction(response.data.data));
    },
    (error: any) => dispatch(applicationErrorAction(error))
  );
};
