import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum AddAppVisibilityUserTypes {
  ERROR = "ADD_APP_VISIBILITY_USER_ERROR",
  REQUEST = "ADD_APP_VISIBILITY_USER_REQUEST",
  SUCCESS = "ADD_APP_VISIBILITY_USER_SUCCESS",
}

export interface IAddAppVisibilityUserRequestAction
  extends IReduxBaseAction<AddAppVisibilityUserTypes> {
  payload: any;
  type: AddAppVisibilityUserTypes.REQUEST;
}

export interface IAddAppVisibilityUserSuccessAction
  extends IReduxBaseAction<AddAppVisibilityUserTypes> {
  payload: any;
  type: AddAppVisibilityUserTypes.SUCCESS;
}

export interface IAddAppVisibilityUserErrorAction
  extends IReduxBaseAction<AddAppVisibilityUserTypes> {
  payload: any;
  type: AddAppVisibilityUserTypes.ERROR;
}

export type TSetAddAppVisibilityUserActions =
  | IAddAppVisibilityUserErrorAction
  | IAddAppVisibilityUserRequestAction
  | IAddAppVisibilityUserSuccessAction;
