import styled from "styled-components";

import { IColor, ISpacerPropTypes, IVerticalSpacerPropTypes } from "../models/IStyles";

export const SVerticalSpacer = styled("div")<IVerticalSpacerPropTypes>`
  width: ${(props: IVerticalSpacerPropTypes) => props.width || "10px"};
`;

export const SSpacer = styled("div")<ISpacerPropTypes>`
  height: ${(props: ISpacerPropTypes) => props.height || "20px"};
  background-color: ${(props: ISpacerPropTypes) => props.color || "initial"};
`;

export const SColor = styled("span")<IColor>`
  color: ${props =>
    props.success ? "#249446" : props.warning ? "#f5c800" : props.error ? "#ff0000" : ""};
`;
