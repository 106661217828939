import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IDeleteTopicActionTypes,
  IDeleteTopicErrorAction,
  IDeleteTopicRequestAction,
  IDeleteTopicSuccessAction,
  TDeleteTopic,
} from "./types";

// DELETE FUNCTIONALITY
export const deleteTopicRequestAction = (): IDeleteTopicRequestAction => ({
  type: TDeleteTopic.REQUEST,
});
export const deleteTopicSuccessAction = (data: any): IDeleteTopicSuccessAction => ({
  payload: data,
  type: TDeleteTopic.SUCCESS,
});
export const deleteTopicErrorAction = (error: any): IDeleteTopicErrorAction => ({
  payload: error,
  type: TDeleteTopic.ERROR,
});

export const deleteTopic = (topicId: any, changecomment: string, successCb?: any) => (
  dispatch: Dispatch<IDeleteTopicActionTypes | TPopupActions>
) => {
  dispatch(deleteTopicRequestAction());
  TopicService.deleteTopic(topicId, changecomment).then(
    (data: { data: any }) => {
      dispatch(deleteTopicSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Topic deleted successfully!",
          title: "Success",
          type: "Info",
        })
      );
      successCb(topicId);
    },
    (error: any) => {
      dispatch(deleteTopicErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
