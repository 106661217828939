/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum GetConfigGenerateType {
  REQUEST = "GENERATE_CONFIG_REQUEST",
  SUCCESS = "GENERATE_CONFIG_SUCCESS",
  ERROR = "GENERATE_CONFIG_ERROR",
}

export interface IConfigGenerateRequestAction extends IReduxBaseAction<GetConfigGenerateType> {
  type: GetConfigGenerateType.REQUEST;
}

export interface IConfigGenerateSuccessAction extends IReduxBaseAction<GetConfigGenerateType> {
  payload: any;
  type: GetConfigGenerateType.SUCCESS;
}

export interface IConfigGenerateErrorAction extends IReduxBaseAction<GetConfigGenerateType> {
  payload: string;
  type: GetConfigGenerateType.ERROR;
}

export type TGetConfigGenerateActions =
  | IConfigGenerateRequestAction
  | IConfigGenerateSuccessAction
  | IConfigGenerateErrorAction;
