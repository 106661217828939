import jwt from "jsonwebtoken";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getLoggedinUser } from "./actions/user/current/actions";
import { clearSession, isExpired, redirect } from "./helper/sessionHelper";
import { RootState } from "./store";

export const Authentication = (props: { authenticated: any; unauthenticated: any }) => {
  const { currentUser } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
  }));

  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  // Before redirection update localStorage
  const location: any = useLocation();

  // Initialize access and refresh tokens
  // Get the query parameters
  const splittedLocation: any = location.hash.replace("#", "").split("&");
  const hashAsObject: any = {};

  splittedLocation.forEach((param: string) => {
    const a = param.split("=");
    hashAsObject[a[0]] = a[1];
  });

  const idToken = hashAsObject.id_token || "";
  const accessToken = hashAsObject.access_token || "";
  const refreshToken = hashAsObject.refresh_token || "";

  useEffect(() => {
    // Immediately overwrite if there is any
    if (accessToken) {
      let accessDetails: any;

      // Get information from accessToken
      try {
        accessDetails = jwt.decode(accessToken);
      } catch (e) {
        console.info("JWT malformed", e);
        redirect();
      }

      clearSession();

      localStorage.setItem("UMB_CLIENT_ID", accessDetails.client_id || "");
      localStorage.setItem("UMB_PAC_APP_CLIENT_ID", accessDetails.pac_app_client_id || "");

      // UMB access token
      if (process.env.REACT_APP_ENV === "fethr" || !isExpired(accessDetails.exp)) {
        localStorage.setItem("UMB_AT", accessToken);
      }

      if (refreshToken) {
        localStorage.setItem("UMB_RT", refreshToken);
      }

      // refresh cached local time zone
      moment.tz.guess(true);
    }

    if (idToken) {
      let idDetails: any;

      // Verify idToken format
      try {
        idDetails = jwt.decode(idToken);
      } catch (e) {
        console.info("idToken malformed", e);
        redirect();
      }

      if (process.env.REACT_APP_ENV === "fethr" || !isExpired(idDetails.exp)) {
        localStorage.setItem("UMB_IT", idToken);
      }
    }

    dispatch(getLoggedinUser());
  }, []);

  if (currentUser.loading) {
    return <></>;
  }

  return currentUser.authenticated ? <props.authenticated /> : <props.unauthenticated />;
};
