import { Dispatch } from "redux";

import { ConfigService } from "../../../services/ConfigService";

import { GetConfigBrokerType, TGetConfigBrokerActions } from "./types";

export const getConfigBrokerRequestAction = (): TGetConfigBrokerActions => ({
  type: GetConfigBrokerType.REQUEST,
});

export const getConfigBrokerSuccessAction = (data: any): TGetConfigBrokerActions => ({
  payload: data,
  type: GetConfigBrokerType.SUCCESS,
});

export const getConfigBrokerErrorAction = (error: string): TGetConfigBrokerActions => ({
  payload: error,
  type: GetConfigBrokerType.ERROR,
});

export const getConfigBroker = (config: any) => (dispatch: Dispatch<TGetConfigBrokerActions>) => {
  dispatch(getConfigBrokerRequestAction());
  ConfigService.getConfigBroker(config).then(
    (response: { data: { data: any } }) => {
      dispatch(getConfigBrokerSuccessAction(response.data.data));
    },
    (error: any) => dispatch(getConfigBrokerErrorAction(error))
  );
};
