import { Grid, GridItem, Tab, TabContainer } from "next-components";
import React from "react";

import { GroundEnvironmentTypeEnum } from "../../../models/IDestination";
import { tabTenantDetailsEnum } from "../../../models/ITenantDetails";

export const AirlineDetailsTabs = (props: { activeTab: any; groupEnv: any; onChange: any }) => (
  <Grid columns="0.6fr">
    <GridItem>
      {props.groupEnv === GroundEnvironmentTypeEnum.PRODUCTION ? (
        <TabContainer>
          <Tab
            className="ta-airline-details-tab-aircrafts"
            onClick={() => {
              props.onChange(tabTenantDetailsEnum.AIRCRAFTS);
            }}
            active={props.activeTab === tabTenantDetailsEnum.AIRCRAFTS}
          >
            Aircrafts (production)
          </Tab>
          <Tab
            className="ta-airline-details-tab-groups"
            onClick={() => {
              props.onChange(tabTenantDetailsEnum.GROUPS);
            }}
            active={props.activeTab === tabTenantDetailsEnum.GROUPS}
          >
            Groups (production)
          </Tab>
        </TabContainer>
      ) : (
        <TabContainer>
          <Tab
            className="ta-airline-details-tab-racks"
            onClick={() => {
              props.onChange(tabTenantDetailsEnum.RACKS);
            }}
            active={props.activeTab === tabTenantDetailsEnum.RACKS}
          >
            Racks (integration)
          </Tab>
          <Tab
            className="ta-airline-details-tab-groups-int"
            onClick={() => {
              props.onChange(tabTenantDetailsEnum.GROUPS_INT);
            }}
            active={props.activeTab === tabTenantDetailsEnum.GROUPS_INT}
          >
            Groups (integration)
          </Tab>
          {/* <Tab
            className="ta-airline-details-tab-history"
            onClick={() => {
              props.onChange(tabTenantDetailsEnum.HISTORY);
            }}
            active={props.activeTab === tabTenantDetailsEnum.HISTORY}
          >
            Change history
          </Tab> */}
        </TabContainer>
      )}
    </GridItem>
  </Grid>
);
