import { Text } from "next-components";
import React from "react";

import { IStatusAndValidText, ITextInput } from "../../models/ITextInput";
import { SInput } from "../../styles/styles";
import { StatusIcon } from "../StatusIcon";

export const TextInput = (props: ITextInput | IStatusAndValidText) => (
  <>
    <Text variant="captionText" className={`ta-modal-input-caption-${props.name}`}>
      {props.label}{" "}
      {(props as IStatusAndValidText).statusIcon !== false ? (
        <StatusIcon
          className={`ta-status-icon-${(props as IStatusAndValidText).valid ? "success" : "fail"}`}
          check={(props as IStatusAndValidText).valid}
        />
      ) : (
        ""
      )}
    </Text>
    <SInput
      className={`ta-modal-input-${props.name}`}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange && props.onChange.bind(props)}
      disabled={props.disabled}
    />
  </>
);
