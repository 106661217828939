import {
  DestinationListType,
  TDestinationListActions,
} from "../../actions/destination/get-list/types";

interface IDestinationListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: IDestinationListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const appDestinationList = (
  state: IDestinationListState = initialState,
  action: TDestinationListActions
): IDestinationListState => {
  switch (action.type) {
    case DestinationListType.RESET:
      return {
        ...state,
        ...initialState,
      };
    case DestinationListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case DestinationListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case DestinationListType.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
