import { List } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { isUserAppOwner } from "../../../helper/util";
import { brokerLocationEnum } from "../../../models/IBrokerTypeListingTableConfig";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import { AppTopicsList } from "../../AppTopicsList";

export const TopicListPublish = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [isActive, setIsActive] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (props.userData && props.appContent) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      setIsOwner(
        // props.appContent.appOwner && props.appContent.appOwner.userId === props.userData.userId
        props.appContent.appOwners && isUserAppOwner(props.userData, props.appContent.appOwners)
      );
      setIsActive(props.appContent.status === "ACTIVE");
    }
  }, [props]);

  const openNewPublisherModal = (topicFilter: any) => {
    dispatch(
      editModalOpenAction({
        appData: { ...props.appContent },
        topicFilter: { ...topicFilter },
        type: modalTypeEnum.CREATE_TOPIC_PUBLISH,
      })
    );
  };

  return (
    <AppTopicsList
      appContent={props.appContent}
      isAdmin={isAdmin}
      isOwner={isOwner}
      type="PUBLISHER"
      btnDisabled={
        !(isActive && (isOwner || isAdmin)) ||
        props.appContent.appClientLocation === brokerLocationEnum.GROUNDSIDE
      }
      btnClick={(topicFilter: any) => {
        openNewPublisherModal(topicFilter);
      }}
    />
  );
};
