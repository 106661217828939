import { queryFilterHelper } from "../helper/queryFilterHelper";
import { ITenantListTableConfig } from "../models/ITenant";

import { axiosInstance } from "./AxiosInstance";

const getQueryString = (conf: ITenantListTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;

  return `?${pagination}${queryFilterHelper(conf)}`;
};

const getTenantList = async (conf: ITenantListTableConfig) => {
  const query = getQueryString(conf);

  const axios = await axiosInstance();

  return axios.get(`/tenants${query}`);
};

const getTenantDetails = async (tenantId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/tenants/${tenantId}`);
};

const searchTenantByIcao = async (icao: string) => {
  const axios = await axiosInstance();

  return axios.get(`/search/tenant?icao=${icao}`);
};

const createTenant = async (config: any) => {
  const postData: any = {
    changeComment: config.changeComment,
    tenantId: config.tenantId,
  };

  const axios = await axiosInstance();

  return axios.post(`/tenants/`, postData);
};

/*
const updateTenant = async (config: any) => {
  let postData: any = {
    appName: config.appName,
    // appOwner: config.user.userId,
    appVersion: config.appVersion,
    changeComment: config.changeComment,
    // internal: config.internal,
    status: "Active",
    systemName: config.systemName,
  };

  if (!!config.appClientLocation && config.appClientLocation === brokerLocationEnum.GROUNDSIDE) {
    postData = {
      ...postData,
      groundDestinationEndpoint: config.groundDestinationEndpoint,
      groundDestinationType: config.groundDestinationType,
    };
  } else {
    postData = {
      ...postData,
      authenticationType: config.authenticationType,
      connRetryDelaySec: config.connRetryDelaySec,
      numConnRetries: config.numConnRetries,
      password: config.password,
      pathClientCa: config.pathClientCa,
      pathToCa: config.pathToCa,
      userName: config.userName,
    };
  }

  const axios = await axiosInstance();

  return axios.put(`/tenants/${config.tenantId}`, postData);
};

const deleteTenant = async (tenantId: any, changeComment: string) => {
  const axios = await axiosInstance();

  return axios.delete(`/tenants/${tenantId}`, {
    params: { changeComment },
  });
};
*/

export const TenantService = {
  createTenant,
  // deleteTenant,
  getTenantDetails,
  getTenantList,
  searchTenantByIcao,
  // updateTenant,
};
