import { AircraftFilterSortType, TAircraftFilterSortActions } from "./types";

export const AircraftFilterSortClearAction = (): TAircraftFilterSortActions => ({
  type: AircraftFilterSortType.CLEAR,
});

export const AircraftFilterSortResetAction = (): TAircraftFilterSortActions => ({
  type: AircraftFilterSortType.RESET,
});

export const AircraftFilterSortApplyAction = (data: any): TAircraftFilterSortActions => ({
  payload: data,
  type: AircraftFilterSortType.APPLY,
});
