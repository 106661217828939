import "sanitize.css/sanitize.css";
import { css } from "styled-components";
import * as variables from "../../variables";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n      width: ", ";\n      height: ", ";\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral([
    "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: ",
    ";\n  height: ",
    ";\n  margin-right: ",
    ";\n\n  ",
    "\n",
  ]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      width: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral([
    "\n  justify-content: center;\n\n  padding: 0;\n  width: ",
    ";\n\n  ",
    "\n",
  ]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      font-size: 14px;\n      height: ", ";\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([
    "\n  ",
    "\n\n  display: flex;\n  align-items: center;\n\n  height: ",
    ";\n  padding-left: ",
    ";\n  padding-right: ",
    ";\n\n  ",
    "\n",
  ]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([
    '\n  outline: 0;\n\n  font-family: "Inter", sans-serif;\n  font-weight: 500;\n  font-size: 16px;\n\n  height: ',
    ";\n  padding-left: ",
    ";\n  padding-right: ",
    ";\n  border: none;\n  border-radius: 2px;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n",
  ]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } }));
}

export var ButtonSize = Object.freeze({
  default: "default",
  small: "small",
});
export var ButtonBase = css(
  _templateObject(),
  variables.getSize(10),
  variables.getSize(8),
  variables.getSize(8)
);
export var ButtonIconTextBase = css(
  _templateObject2(),
  ButtonBase,
  variables.getSize(10),
  variables.getSize(4),
  variables.getSize(6),
  function(props) {
    return props.size === ButtonSize.small && css(_templateObject3(), variables.getSize(8));
  }
);
export var ButtonIconBase = css(_templateObject4(), variables.getSize(10), function(props) {
  return props.size === ButtonSize.small && css(_templateObject5(), variables.getSize(8));
});
export var IconBase = css(
  _templateObject6(),
  variables.getSize(8),
  variables.getSize(8),
  variables.getSize(1),
  function(props) {
    return (
      props.size === ButtonSize.small &&
      css(_templateObject7(), variables.getSize(6), variables.getSize(6))
    );
  }
);
