import React, { useEffect, useState } from "react";

import { isUserAppOwner } from "../../../helper/util";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { TopicClientList } from "../../TopicClientList";

export const TopicDetailsSubscriberList = (props: any) => {
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (props.userData) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);

      if (props.appContent) {
        setIsOwner(
          // props.appContent.appOwner && props.appContent.appOwner.userId === props.userData.userId
          props.appContent.appOwners && isUserAppOwner(props.userData, props.appContent.appOwners)
        );
      }
    }
  }, [props]);

  return <TopicClientList isAdmin={isAdmin} type="SUBSCRIBER" topicDetails={props.topicDetails} />;
};
