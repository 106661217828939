import { Dispatch } from "redux";

import { IFilterSortData } from "../../../models/IFilterSort";

import { TopicFilterSortType, TTopicFilterSortActions } from "./types";

export const topicFilterSortClearAction = (): TTopicFilterSortActions => ({
  type: TopicFilterSortType.CLEAR,
});

export const topicFilterSortResetAction = (): TTopicFilterSortActions => ({
  type: TopicFilterSortType.RESET,
});

export const topicFilterSortApplyAction = (data: any): TTopicFilterSortActions => ({
// export const appFilterSortApplyAction = (data: IFilterSortData): TTopicFilterSortActions => ({
  payload: data,
  type: TopicFilterSortType.APPLY,
});

