import { ContentSwitcher } from "next-components";
import React, { useEffect, useState } from "react";
import { Edge, Network, Node } from "react-vis-network";

interface IRawData {
  from: string;
  label: string;
  to: string;
}

interface IGraph {
  rawData: IRawData[];
  undirectional?: boolean;
}

interface INode {
  id: string;
  label: string;
}

const IFE_BrokerTypes = ["CB", "CTB", "SEB", "SCB"];

const updateDataBySystem = (rawData: IRawData[], system: string) => {
  let rawDataCopy = rawData.map((item) => ({ ...item }));
  if (system === "GCS Standalone") {
    rawDataCopy = rawDataCopy.filter((item) => item.from !== "GIOTB" && item.to !== "GIOTB");
    rawDataCopy.push({ from: "GIOTB", label: "in", to: "GCS FSB" });
    // Filter out all items that contain any of the broker types
    rawDataCopy = rawDataCopy.filter(
      (item) => !IFE_BrokerTypes.includes(item.from) && !IFE_BrokerTypes.includes(item.to)
    );
  }

  return rawDataCopy; // Return the modified copy of rawData
};

const getNodes = (rawData: IRawData[], system: string) => {
  const nodes = [];
  const updatedData = updateDataBySystem(rawData, system);

  for (const row of updatedData) {
    if (!nodes.filter((r: INode) => r.id === row.from).length) {
      nodes.push({
        id: row.from,
        label: row.from,
      });
    }
    if (!nodes.filter((r: INode) => r.id === row.to).length) {
      nodes.push({
        id: row.to,
        label: row.to,
      });
    }
  }

  return nodes;
};

const getLinks = (rawData: IRawData[], system: string, isUndirectional: boolean = false) => {
  let updatedData = updateDataBySystem(rawData, system);
  // tslint:disable-next-line:no-parameter-reassignment
  updatedData = updatedData.map((row: IRawData) => ({
    arrows: isUndirectional ? { to: false } : row.label === "out" ? "from" : "to",
    from: row.from,
    label: row.label,
    to: row.to,
  }));

  if (updatedData.length === 1 && updatedData[0].from === updatedData[0].to) {
    return [];
  }

  return updatedData;
};

export const TopologyGraphVis = (props: IGraph) => {
  const [system, setSystem] = useState("Regular IFE");
  const [nodes, setNodes]: any[] = useState([]);
  const [edges, setEdges]: any[] = useState([]);

  useEffect(() => {
    if (props.rawData.length > 0) {
      setNodes(getNodes(props.rawData, system));
      setEdges(getLinks(props.rawData, system, props.undirectional));
    }
  }, [props, system]);

  const showSystemSwitch =
    nodes.some((node: INode) => node.label === "GCS FSB") &&
    nodes.some((node: INode) => node.label === "GIOTB");

  const systems = [
    {
      action: () => {
        setSystem("Regular IFE");
      },
      title: "Regular IFE",
    },
    {
      action: () => {
        setSystem("GCS Standalone");
      },
      title: "GCS Standalone",
    },
  ];

  return (
    <>
      {showSystemSwitch ? (
        <div style={{ float: "right" }}>
          <ContentSwitcher items={systems} />
        </div>
      ) : null}
      {nodes.length ? (
        <Network height={1000}>
          {nodes.map((node: INode) => (
            <Node key={node.id} id={node.id} label={node.label} />
          ))}
          {edges.map((edge: any, index: number) => (
            <Edge
              key={index}
              id={index}
              from={edge.from}
              to={edge.to}
              label={edge.label}
              arrows={edge.arrows}
            />
          ))}
        </Network>
      ) : (
        ""
      )}
    </>
  );
};

const isIdenticalGraph = (prevProps: any, nextProps: any) => {
  const prewRawData = prevProps.rawData;
  const nextRawData = nextProps.rawData;
  const haveSameLength = prewRawData.length === nextRawData.length;
  let isIdentical = true;
  for (const item of nextRawData) {
    if (!prewRawData.filter((raw: any) => JSON.stringify(raw) === JSON.stringify(item)).length) {
      isIdentical = false;
    }
  }

  return haveSameLength && isIdentical;
};
export const MemoizedTopologyGraphVis = React.memo(TopologyGraphVis, isIdenticalGraph);
