import { IDeleteTopicActionTypes, TDeleteTopic } from "../../actions/topic/delete/types";

interface ITopicDeleteState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: ITopicDeleteState = {
  content: [],
  error: false,
  loading: true,
};

export const topicDelete = (
  state: ITopicDeleteState = initialState,
  action: IDeleteTopicActionTypes
): ITopicDeleteState => {
  switch (action.type) {
    // DELETE
    case TDeleteTopic.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case TDeleteTopic.SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case TDeleteTopic.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
