import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";

import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { getConfigJson } from "../../services/AxiosInstance";
import {
  SActiveLink,
  SChangeLogContent,
  SFlexContainer,
  SFlexItem,
  SMediumText,
  SPayloadSchema,
  SSpacer,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { StatusIcon } from "../StatusIcon";

export const ModalSQSInfoPage = (props: any) => {
  const [isYaml, setIsYaml] = useState(true);
  const [sqsPermissionYaml, setSqsPermissionYaml] = useState("");
  const [sqsPermissionJson, setSqsPermissionJson] = useState("");

  const umbAwsPrincipal: any = {
    dev: "arn:aws:iam::860130150252:role/pac-umb-a2g-dispatcher-role-dev",
    dev_int: "arn:aws:iam::823320044431:role/pac-umb-a2g-dispatcher-role-dev-int",
    prd: "arn:aws:iam::028384449270:role/pac-umb-a2g-dispatcher-role-production",
    prd_int: "arn:aws:iam::790041243645:role/pac-umb-a2g-dispatcher-role-production-int",
    test: "arn:aws:iam::891026033580:role/pac-umb-a2g-dispatcher-role-staging",
    test_int: "arn:aws:iam::245367906066:role/pac-umb-a2g-dispatcher-role-staging-int",
  };

  const getAwsPrincipal = (env: string, isIntegration: boolean) => {
    const selection = `${env}${isIntegration ? "_int" : ""}`;

    return umbAwsPrincipal[selection];
  };

  const sqsYaml = `---
  Version: '2008-10-17'
  Id: __default_policy_ID
  Statement:
  - Sid: '1'
    Effect: Allow
    Principal:
      AWS: '${getAwsPrincipal(getConfigJson().stage, props.isIntegration)}'
    Action: SQS:SendMessage
    Resource: <Your SQS ARN>`;

  const sqsJson = `{
  "Version": "2008-10-17",
  "Id": "__default_policy_ID",
  "Statement": [
    {
      "Sid": "1",
      "Effect": "Allow",
      "Principal": {
        "AWS": "${getAwsPrincipal(getConfigJson().stage, props.isIntegration)}"
      },
      "Action": "SQS:SendMessage",
      "Resource": <Your SQS ARN>
    }
  ]
}`;

  useEffect(() => {
    setSqsPermissionYaml(sqsYaml);
    setSqsPermissionJson(sqsJson);
  }, [props]);

  return (
    <>
      <Text>
        In order for your SQS queue to successfully receive airside UMB messages, you will need to
        grant UMB the proper permission. Please reference one of the AWS policy code snippets below
        when configuring your SQS queue's permission. Make sure to insert your SQS queue's ARN for
        "Resource" in the policy.
      </Text>

      <SSpacer height="8px" />

      <SFlexContainer justifyContent="flex-start" style={{ lineHeight: "24px" }}>
        <Text>AWS SQS permission -</Text>
        <SVerticalSpacer width="8px" />
        {isYaml ? (
          <Text>
            <b>yaml</b>
          </Text>
        ) : (
          <SActiveLink
            onClick={() => {
              setIsYaml(true);
            }}
            style={{ cursor: "pointer" }}
          >
            yaml
          </SActiveLink>
        )}
        <SVerticalSpacer width="12px" />
        {!isYaml ? (
          <Text>
            <b>json</b>
          </Text>
        ) : (
          <SActiveLink
            onClick={() => {
              setIsYaml(false);
            }}
            style={{ cursor: "pointer" }}
          >
            json
          </SActiveLink>
        )}
      </SFlexContainer>

      <SChangeLogContent
        className="ta-modal-sqs-permission-code-snippet"
        placeholder={""}
        readonly={true}
        // style={{ fontFamily: "monospace", fontSize: "12px" }}
        style={{ fontFamily: "monospace" }}
        value={isYaml ? sqsPermissionYaml : sqsPermissionJson}
      />
    </>
  );
};
