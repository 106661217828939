import {
  GetTopicPayloadTypes,
  TGetTopicPayloadActions,
} from "../../actions/topic/get-payload/types";

interface ITopicListState {
  content: any;
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const topicPayload = (
  state: ITopicListState = initialState,
  action: TGetTopicPayloadActions
): ITopicListState => {
  switch (action.type) {
    case GetTopicPayloadTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GetTopicPayloadTypes.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case GetTopicPayloadTypes.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
