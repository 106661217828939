import { Dispatch } from "redux";

import { IGetTopicListData, ITopicListingTableConfig } from "../../../models/ITopicConfig";
import { TopicService } from "../../../services/TopicService";

import { TopicListType, TTopicListActions } from "./types";

export const topicListRequestAction = (): TTopicListActions => ({
  type: TopicListType.REQUEST,
});

export const topicListSuccessAction = (data: any): TTopicListActions => ({
  payload: data,
  type: TopicListType.SUCCESS,
});

export const topicListErrorAction = (error: string): TTopicListActions => ({
  payload: error,
  type: TopicListType.ERROR,
});

export const getTopicList = (conf: ITopicListingTableConfig) => (
  dispatch: Dispatch<TTopicListActions>
) => {
  dispatch(topicListRequestAction());
  TopicService.getTopicList(conf).then(
    (response: { data: { data: IGetTopicListData } }) => {
      if (
        conf &&
        conf.statuses &&
        response.data &&
        response.data.data &&
        response.data.data.content &&
        conf.statuses.length > 0
      ) {
        response.data.data.content = response.data.data.content.filter(
          (topic: any) => conf.statuses.indexOf(topic.status) !== -1
        );
      }
      dispatch(topicListSuccessAction(response.data.data));
    },
    (error: any) => dispatch(topicListErrorAction(error))
  );
};
