import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { addDestinationRoute } from "../../actions/destination/add-destination-route/actions";
import {
  DestinationRouteListSelectClearAction,
  getDestinationRouteListSelect,
} from "../../actions/destination/get-destination-routes-select/actions";
import { getDestinationRouteList } from "../../actions/destination/get-destination-routes/actions";
import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";
import { StatusIcon } from "../StatusIcon";

import { ModalCreateBase } from "./ModalCreateBase";
import { ModalSelectRouteGroupPage1 } from "./ModalSelectRouteGroupPage1";

export const ModalSelectRouteGroup = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const [config, setConfig] = useState({
    aircraftGroupId: "",
    aircraftGroupName: "",
    changeComment: "",
    icao: "",
    routeType: "",
    tenantId: "",
  });

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const changeCommentRequired = false; // adding source group does not require comment
  const [isFormValid, setIsFormValid] = useState(false);

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  // initialize the 2nd source group list at max 500 entries
  useEffect(() => {
    const myConfig = { ...editModal.routeFilter, limit: 500, page: 0 };
    dispatch(
      getDestinationRouteListSelect(
        editModal.appData.appClientId,
        editModal.destData.appClientDestinationId,
        myConfig
      )
    );
  }, [editModal]);

  const handleSubmit = () => {
    dispatch(
      addDestinationRoute(
        editModal.appData.appClientId,
        editModal.destData.appClientDestinationId,
        {
          ...config,
        },
        () => {
          dispatch(DestinationRouteListSelectClearAction());
          dispatch(
            getDestinationRouteList(
              editModal.appData.appClientId,
              editModal.destData.appClientDestinationId,
              editModal.routeFilter
            )
          );
        }
      )
    );
  };

  const checkValidity = () => {
    const isValid = !!config.aircraftGroupId || !!config.tenantId;

    setIsFormValid(isValid);
  };

  useEffect(() => {
    checkValidity();
  }, [config]);

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setConfig({
        ...config,
        changeComment: e.target.value,
      });
    }
  };

  const modalTheme = {
    modal: {
      width: "650px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase
        title="Add source group to destination"
        handleCloseButton={() => {
          dispatch(DestinationRouteListSelectClearAction());
        }}
      >
        <ModalSelectRouteGroupPage1
          appData={editModal.appData}
          destData={editModal.destData}
          config={{ ...config }}
          onChange={(updatedConfig: any) => {
            setConfig(updatedConfig);
          }}
        />

        <Text variant="captionText" className="ta-modal-input-caption-change-comment">
          Please enter comment (max 2048 characters){" "}
          <StatusIcon
            className={
              !changeCommentRequired || !!config.changeComment
                ? "ta-status-icon-success"
                : "ta-status-icon-fail"
            }
            check={!changeCommentRequired || !!config.changeComment}
          />
        </Text>
        <Textarea
          className="ta-modal-input-comment"
          placeholder={editModal.popupData.commentHint}
          value={config.changeComment}
          onChange={(e: any) => {
            handleTextInput(e, "comment", 2048);
          }}
        />

        <SSpacer height="50px" />

        <SFlexContainer justifyContent="flex-end">
          <ButtonPrimary
            className="ta-modal-save-button"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Confirm
          </ButtonPrimary>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
