/* @tslint-disable:match-default-export-name */
import { combineReducers } from "redux";

import aircraftEnvSlice from "../actions/aircraft/aircraft-env/aircraft-env-slice";
import { tenantApi } from "../actions/tenant/api/tenant-api";

import { aircraftDetails } from "./aircraft/AircraftDetailsReducer";
import { aircraftFilterSort } from "./aircraft/AircraftFilterSortReducer";
import { aircraftList } from "./aircraft/AircraftListReducer";
import { aircraftGroupDetails } from "./aircraftgroup/AircraftGroupDetailsReducer";
import { aircraftGroupFilterSort } from "./aircraftgroup/AircraftGroupFilterSortReducer";
import { aircraftGroupList } from "./aircraftgroup/AircraftGroupListReducer";
import { aircraftGroupSelectList } from "./aircraftgroup/AircraftGroupSelectListReducer";
import { appDestinationDetails } from "./application/AppDestinationDetailsReducer";
import { appDestinationRouteList } from "./application/AppDestinationRouteReducer";
import { appDestinationRouteSelectList } from "./application/AppDestinationRouteSelectReducer";
import { appDestinationList } from "./application/AppDestinationsReducer";
import { applicationCreation } from "./application/ApplicationCreationReducer";
import { applicationDelete } from "./application/ApplicationDeleteReducer";
import { applicationDetails } from "./application/ApplicationDetailsReducer";
import { applicationEdit } from "./application/ApplicationEditReducer";
import { applicationFilterSort } from "./application/ApplicationFilterSortReducer";
import { applicationList } from "./application/ApplicationListReducer";
import { applicationPublisherList } from "./application/ApplicationPublisherListReducer";
import { applicationTopicFullList } from "./application/ApplicationTopicFullListReducer";
import { applicationTopicList } from "./application/ApplicationTopicListReducer";
import { appVisibilityUsers } from "./application/AppVisibilityUsersReducer";
import { allBrokerType } from "./broker-type/AllBrokerTypeReducer";
import { brokerTypeDetails } from "./broker-type/BrokerTypeDetailsReducer";
import { brokerTypeList } from "./broker-type/BrokerTypeListReducer";
import { changeHistoryFilterSort } from "./changehistory/ChangeHistoryFilterSortReducer";
import { changeHistoryList } from "./changehistory/ChangeHistoryListReducer";
import { changeLogGet } from "./changehistory/ChangeLogReducer";
import { gndChangeLogGet } from "./changehistory/GndChangeLogReducer";
import { configFileAppFilterSort } from "./configfile/ConfigFileAppFilterSortReducer";
import { configFileApplication } from "./configfile/ConfigFileApplication";
import { configFileBroker } from "./configfile/ConfigFileBroker";
import { configFileBrokerFilterSort } from "./configfile/ConfigFileBrokerFilterSortReducer";
import { configFileGenerate } from "./configfile/ConfigFileGenerate";
import { configLoadableList } from "./configfile/ConfigLoadableListReducer";
import { configVersionDetails } from "./configversion/ConfigVersionGetReducer";
import { configVersionList } from "./configversion/ConfigVersionListReducer";
import { groundConfigList } from "./groundconfig/GroundConfigListReducer";
import { editModal } from "./modal/EditModalReducer";
import { payloadGet } from "./payload/PayloadGetReducer";
import { payloadUpload } from "./payload/PayloadUploadReducer";
import { popupData } from "./popup/PopupReducer";
import { tenantSearchResult } from "./tenant/SearchTenantReducer";
import { tenantCreation } from "./tenant/TenantCreationReducer";
import { tenantDetails } from "./tenant/TenantDetailsReducer";
import { tenantFilterSort } from "./tenant/TenantFilterSortReducer";
import { tenantList } from "./tenant/TenantListReducer";
import { filterTopicList } from "./topic/FilterTopicListReducer";
import { topicBridges } from "./topic/TopicBridgesReducer";
import { topicClientList } from "./topic/TopicClientListReducer";
import { topicDelete } from "./topic/TopicDeleteReducer";
import { topicDetails } from "./topic/TopicDetailsReducer";
import { topicEdit } from "./topic/TopicEditReducer";
import { topicFilterSort } from "./topic/TopicFilterSortReducer";
import { topicList } from "./topic/TopicListReducer";
import { topicMQTTClientDetails } from "./topic/TopicMqttClientDetailsReducer";
// import { topicMQTTClientRoutes } from "./topic/TopicMqttClientRoutesReducer";
// import { topicMQTTClientRoutesSelect } from "./topic/TopicMqttClientRoutesSelectReducer";
import { topicMQTTClients } from "./topic/TopicMqttClientsReducer";
import { topicPayload } from "./topic/TopicPayloadReducer";
import { topicPubPermissionApps, topicSubPermissionApps } from "./topic/TopicPermissionAppsReducer";
import { topologyList } from "./topology/TopologyListReducer";
import { currentUser } from "./user/CurrentUserReducer";
import { userDetails } from "./user/UserDetailsReducer";
import { userFilterSort } from "./user/UserFilterSortReducer";
import { userList } from "./user/UserListReducer";
import { userSetDetails } from "./user/UserSetDetailsReducer";

const sliceReducers = {
  aircraftEnv: aircraftEnvSlice.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
};

export const middleware = [tenantApi.middleware];

export const reducer = combineReducers({
  aircraftDetails,
  aircraftFilterSort,
  aircraftGroupDetails,
  aircraftGroupFilterSort,
  aircraftGroupList,
  aircraftGroupSelectList,
  aircraftList,
  allBrokerType,
  appDestinationDetails,
  appDestinationList,
  appDestinationRouteList,
  appDestinationRouteSelectList,
  appVisibilityUsers,
  applicationCreation,
  applicationDelete,
  applicationDetails,
  applicationEdit,
  applicationFilterSort,
  applicationList,
  applicationPublisherList,
  applicationTopicFullList,
  applicationTopicList,
  brokerTypeDetails,
  brokerTypeList,
  changeHistoryFilterSort,
  changeHistoryList,
  changeLogGet,
  configFileAppFilterSort,
  configFileApplication,
  configFileBroker,
  configFileBrokerFilterSort,
  configFileGenerate,
  configLoadableList,
  configVersionDetails,
  configVersionList,
  currentUser,
  editModal,
  filterTopicList,
  gndChangeLogGet,
  groundConfigList,
  payloadGet,
  payloadUpload,
  popupData,
  tenantCreation,
  tenantDetails,
  tenantFilterSort,
  tenantList,
  tenantSearchResult,
  topicBridges,
  topicClientList,
  topicDelete,
  topicDetails,
  topicEdit,
  topicFilterSort,
  topicList,
  topicMQTTClientDetails,
  // topicMQTTClientRoutes,
  // topicMQTTClientRoutesSelect,
  topicMQTTClients,
  topicPayload,
  topicPubPermissionApps,
  topicSubPermissionApps,
  topologyList,
  userDetails,
  userFilterSort,
  userList,
  userSetDetails,
  ...sliceReducers,
});
