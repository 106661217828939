import { Dispatch } from "redux";

import { TopicService } from "../../../services/TopicService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IUpdatePayloadErrorAction,
  IUpdatePayloadRequestAction,
  IUpdatePayloadSuccessAction,
  TUpdatePayloadActions,
  UpdatePayloadTypes,
} from "./types";

export const updatePayloadRequestAction = (data: any): IUpdatePayloadRequestAction => ({
  payload: data,
  type: UpdatePayloadTypes.REQUEST,
});
export const updatePayloadSuccessAction = (data: any): IUpdatePayloadSuccessAction => ({
  payload: data,
  type: UpdatePayloadTypes.SUCCESS,
});
export const updatePayloadErrorAction = (error: any): IUpdatePayloadErrorAction => ({
  payload: error,
  type: UpdatePayloadTypes.ERROR,
});

// ACTION
export const updatePayload = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TUpdatePayloadActions | TEditModalActions>
) => {
  dispatch(updatePayloadRequestAction(config));
  TopicService.updatePayload(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(updatePayloadSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Payload uploaded successfully!",
          title: "Success",
          type: "Info",
        })
      );

      if (cb) {
        cb(data.data.data.content);
      }
    },
    (error: any) => {
      dispatch(updatePayloadErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
