import { ISearchTenantActionType, TSearchTenant } from "../../actions/tenant/search/types";

interface ISearchTenantState {
  content: any;
  error: boolean;
  loading: boolean;
}
const initialState: ISearchTenantState = {
  content: {},
  error: false,
  loading: false,
};

export const tenantSearchResult = (
  state: ISearchTenantState = initialState,
  action: ISearchTenantActionType
): ISearchTenantState => {
  switch (action.type) {
    case TSearchTenant.REQUEST:
      return {
        ...state,
        ...initialState, // reset too
        error: false,
        loading: true,
      };
    case TSearchTenant.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case TSearchTenant.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
