import {
  ButtonPrimary,
  Grid,
  GridItem,
  ProgressIndicatorCircular,
  Tab,
  TabContainer,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { editModalOpenAction } from "../../actions/modal/edit/actions";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { tabGroundsideConfigurationEnum } from "../../models/IGroundsideConfiguration";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { ModalDeployGroundConfigs } from "../modals/ModalDeployGroundConfigs";
import { ModalDisplayGndChangeLog } from "../modals/ModalDisplayGndChangeLog";
import { UserRolePermissionsUtil } from "../UserRolePermissionsUtil";

import { Airlines } from "./Airlines";
import { DeployedConfigurationsList } from "./GroundConfigs/DeployedConfigurationsList";
import { GlobalGroupList } from "./GroundConfigs/GlobalGroupList";

export const GroundsideConfiguration = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { currentUser, editModal } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTab, setActiveTab] = useState(tabGroundsideConfigurationEnum.AIRLINES);
  const [needRefresh, setNeedRefresh] = useState(0);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(currentUser.data.role === ADMINISTRATOR);
    }
  }, [currentUser]);

  const handleDeployGroundConfigs = (isIntegration: boolean) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          isInt: isIntegration,
          onClose: () => {
            setNeedRefresh(needRefresh + 1);
          },
        },
        type: modalTypeEnum.DEPLOY_GROUND_CONFIGS,
      })
    );
  };

  const handleViewGndChangeLog = (isIntegration: boolean) => {
    dispatch(
      editModalOpenAction({
        popupData: {
          // lastVersion: `unknown`,
          // title: `View releasable changes`,
          isIntegration,
        },
        type: modalTypeEnum.DISPLAY_GND_CHANGE_LOG,
      })
    );
  };

  const tabList = [
    {
      class: "ta-groundside-tab-airlines",
      id: tabGroundsideConfigurationEnum.AIRLINES,
      title: "Airlines",
    },
    {
      class: "ta-groundside-tab-global-groups",
      id: tabGroundsideConfigurationEnum.GLOBAL_GROUPS,
      title: "Global groups",
    },
    {
      class: "ta-groundside-tab-deployed-configs",
      id: tabGroundsideConfigurationEnum.DEPLOYED_CONFIGS,
      title: "Deployed configurations",
    },
  ];

  const handleTabChange = (tabId: tabGroundsideConfigurationEnum) => {
    setActiveTab(tabId);
  };

  return (
    <SPageContainer>
      <SPageHeading>Groundside configuration</SPageHeading>
      {/* TABS */}
      <Grid columns="3fr 2fr">
        <GridItem>
          <TabContainer>
            {tabList.map(
              (tab: any, index: any) =>
                (tab.id !== tabGroundsideConfigurationEnum.GLOBAL_GROUPS || isAdmin) && (
                  <Tab
                    key={index}
                    className={tab.class}
                    active={activeTab === tab.id}
                    onClick={() => {
                      handleTabChange(tab.id);
                    }}
                  >
                    {tab.title}
                  </Tab>
                )
            )}
          </TabContainer>
        </GridItem>

        {activeTab === tabGroundsideConfigurationEnum.DEPLOYED_CONFIGS && (
          <GridItem style={{ justifySelf: "end" }}>
            <SFlexContainer justifyContent="flex-end">
              <SFlexItem>
                <ButtonPrimary
                  className="ta-groundside-view-change-log-integration-btn"
                  disabled={
                    !UserRolePermissionsUtil.hasGroundsideConfig_DeployIntConfig(currentUser.data)
                  }
                  onClick={() => {
                    handleViewGndChangeLog(true);
                  }}
                >
                  Integration change log
                </ButtonPrimary>
                <SVerticalSpacer />
                <ButtonPrimary
                  className="ta-groundside-config-deploy-integration-btn"
                  disabled={
                    !UserRolePermissionsUtil.hasGroundsideConfig_DeployIntConfig(currentUser.data)
                  }
                  onClick={() => handleDeployGroundConfigs(true)}
                >
                  Deploy for integration
                </ButtonPrimary>
              </SFlexItem>
            </SFlexContainer>

            {isAdmin && <SSpacer height="8px" />}

            {isAdmin && (
              <SFlexContainer justifyContent="flex-end">
                <SFlexItem>
                  <ButtonPrimary
                    style={{ color: "#00FF77" }}
                    className="ta-groundside-view-change-log-production-btn"
                    // disabled={!isAdmin}
                    onClick={() => {
                      handleViewGndChangeLog(false);
                    }}
                  >
                    Production change log
                  </ButtonPrimary>
                  <SVerticalSpacer />
                  <ButtonPrimary
                    style={{ color: "#00FF77" }}
                    className="ta-groundside-config-deploy-production-btn"
                    disabled={!isAdmin}
                    onClick={() => handleDeployGroundConfigs(false)}
                  >
                    Deploy for production
                  </ButtonPrimary>
                </SFlexItem>
              </SFlexContainer>
            )}
          </GridItem>
        )}
      </Grid>

      <SSpacer />

      {activeTab === tabGroundsideConfigurationEnum.AIRLINES && <Airlines />}
      {activeTab === tabGroundsideConfigurationEnum.GLOBAL_GROUPS && (
        // <AircraftGroupList groupType={AircraftGroupTypeEnum.GLOBAL} />
        <GlobalGroupList />
      )}
      {activeTab === tabGroundsideConfigurationEnum.DEPLOYED_CONFIGS && (
        <DeployedConfigurationsList refresh={needRefresh} />
      )}

      {editModal && editModal.open && editModal.type === modalTypeEnum.DEPLOY_GROUND_CONFIGS && (
        <ModalDeployGroundConfigs />
      )}

      {editModal && editModal.open && editModal.type === modalTypeEnum.DISPLAY_GND_CHANGE_LOG && (
        <ModalDisplayGndChangeLog />
      )}

      {/* TAB CONTENT */}
    </SPageContainer>
  );
};
