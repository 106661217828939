import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";
import { ModalSQSInfoPage } from "./ModalSQSInfoPage";

export const ModalDisplaySQSConfigInfo = () => {
  // const dispatch = useDispatch();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  // const handleConfirm = () => {
  //   if (editModal.popupData.onConfirm) {
  //     editModal.popupData.onConfirm({ changeComment });
  //   }
  // };

  const modalTheme = {
    modal: {
      width: "650px", // same width as app create/edit dialog
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase
        title={`How to configure ${
          editModal.popupData.isSqsIntegration ? "integration" : "production"
        } destination`}
      >
        {/* <ModalCreateBase title={`How to configure SQS permission`}> */}

        {/* <SSpacer height="8px" /> */}

        <ModalSQSInfoPage isIntegration={editModal.popupData.isSqsIntegration} />

        {/* <SFlexContainer justifyContent="flex-end">
          <ButtonPrimary
            className="ta-modal-close-button"
            onClick={handleConfirm}
          >
            {`Confirm`}
          </ButtonPrimary>
        </SFlexContainer> */}
      </ModalCreateBase>
    </ThemeProvider>
  );
};
