import { Dispatch } from "redux";

import { IGetApplicationListData } from "../../../models/IAppListingTableConfig";
import { ApplicationService } from "../../../services/ApplicationService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";
import { getApplicationDetails } from "../get-details/actions";

import { DeleteAppVisibilityUserTypes, TDeleteAppVisibilityUserActions } from "./types";

// DELETE FUNCTIONALITY
export const deleteAppVisibilityUserRequestAction = (): TDeleteAppVisibilityUserActions => ({
  type: DeleteAppVisibilityUserTypes.REQUEST,
});
export const deleteAppVisibilityUserSuccessAction = (
  data: any
): TDeleteAppVisibilityUserActions => ({
  payload: data,
  type: DeleteAppVisibilityUserTypes.SUCCESS,
});
export const deleteAppVisibilityUserErrorAction = (
  error: any
): TDeleteAppVisibilityUserActions => ({
  payload: error,
  type: DeleteAppVisibilityUserTypes.ERROR,
});

export const deleteAppVisibilityUser = (appId: any, config: any, cb: any) => (
  dispatch: Dispatch<TDeleteAppVisibilityUserActions | TPopupActions>
) => {
  dispatch(deleteAppVisibilityUserRequestAction());
  ApplicationService.deleteAppVisibilityUser(appId, config).then(
    (data: { data: any }) => {
      dispatch(deleteAppVisibilityUserSuccessAction(data.data));
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      // dispatch(getApplicationDetails(appId));
      dispatch(
        popupOpenAction({
          content: "App visibility user removed successfully!",
          title: "Success",
          type: "Info",
        })
      );
      if (cb) {
        cb(appId);
      }
    },
    (error: any) => {
      dispatch(deleteAppVisibilityUserErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
