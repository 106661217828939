import {
  ButtonPrimary,
  colors,
  Grid,
  GridItem,
  Heading,
  ProgressIndicatorLinear,
  Text,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { addAppVisibilityUser } from "../../../actions/application/add-visibility-user/actions";
import { deleteAppVisibilityUser } from "../../../actions/application/delete-visibility-user/actions";
import { editAppVisibility } from "../../../actions/application/edit-visibility/actions";
import { getApplicationDetails } from "../../../actions/application/get-details/actions";
import {
  appVisibilityUsersResetAction,
  getAppVisibilityUsers,
} from "../../../actions/application/get-visibility-users/actions";
import { editModalCloseAction, editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getUserList } from "../../../actions/user/get-list/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getTenantLabel, isUserAppOwner } from "../../../helper/util";
import { appVisibilityEnum } from "../../../models/IAppDetailsConfig";
import { userListStatusEnum } from "../../../models/IAppListingTableConfig";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { ITableHeaderCells, ITableRow, OrderType } from "../../../models/ITableConfig";
import { userTenantEnum } from "../../../models/ITenant";
import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ } from "../../../models/UserTypes";
import { RootState } from "../../../store";
import {
  SFlexContainer,
  SFlexItem,
  SNextFixedWidthContainer,
  SOverlay,
  SSpacer,
  STextDataTitle,
  STextDataValue,
  SVerticalSpacer,
} from "../../../styles/styles";
import { ModalEditAppVisibility } from "../../modals/ModalEditAppVisibility";
import { Table } from "../../Table";
import { Typeahead } from "../../Typeahead";

export const ApplicationDetailsPermissionsTab = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { appVisibilityUsers, userList } = useSelector((state: any) => ({
    appVisibilityUsers: state.appVisibilityUsers,
    userList: state.userList,
  }));

  const [isActive, setIsActive] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [config, setConfig] = useState({
    filter: { fullName: "" },
    limit: 5,
    orderBy: "",
    orderType: OrderType.ASC,
    page: 0,
    roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
  });

  useEffect(() => {
    if (props.userData && props.appContent) {
      setIsAdmin(props.userData.role === ADMINISTRATOR);
      setIsOwner(
        props.appContent.appOwners && isUserAppOwner(props.userData, props.appContent.appOwners)
      );
      setIsActive(props.appContent.status === userListStatusEnum.ACTIVE);
    }
  }, [props]);

  useEffect(() => {
    dispatch(appVisibilityUsersResetAction());
  }, []);

  const processGetAppVisibiityUsers = (appClientId: any) => {
    dispatch(getAppVisibilityUsers(appClientId, config));
  };

  useEffect(() => {
    if (isActive && (isOwner || isAdmin) && props.appContent) {
      processGetAppVisibiityUsers(props.appContent.appClientId);
    }
  }, [config, isActive, isAdmin, isOwner]);

  const submitSuccessCallback = (appClientId: any) => {
    dispatch(editModalCloseAction());
    dispatch(getApplicationDetails(appClientId));
  };

  const handleEditAppVisibility = (param: any) => {
    if (props.appContent) {
      dispatch(
        editAppVisibility(
          { ...param, appClientId: props.appContent.appClientId },
          submitSuccessCallback
        )
      );
    }
  };

  const handleOpenEditAppVisibility = () => {
    dispatch(
      editModalOpenAction({
        appData: { ...props.appContent },
        popupData: {
          //   commentHint: "Please enter a meaningful comment about why you delete this application.",
          //   commentRequired: true,
          //   contentText: "Are you sure you want to delete this application?",
          onConfirm: handleEditAppVisibility,
          //   title: `Edit app owners`,
        },
        type: modalTypeEnum.EDIT_APP_VISIBILITY,
        userData: { ...props.userData },
      })
    );
  };

  const handleAddAppVisibilityUser = (userId: string) => {
    if (!!userId) {
      dispatch(
        addAppVisibilityUser(
          props.appContent.appClientId,
          { userId, changeComment: "" },
          processGetAppVisibiityUsers
        )
      );
    }
  };

  const handleTypeaheadInput = (user: any) => {
    if (!!user.userId) {
      let isSelected = false;
      if (appVisibilityUsers.content.length > 0) {
        isSelected = appVisibilityUsers.content.some(
          (whiteUser: any) => whiteUser.userId === user.userId
        );
      }

      if (!isSelected) {
        // visTimer = setTimeout(() => {
        handleAddAppVisibilityUser(user.userId); // this is already async so no need to use timer
        // }, 200);
      }
    }
  };

  const handleDeleteAppVisibilityUser = (userId: string) => {
    if (!!userId) {
      dispatch(
        deleteAppVisibilityUser(
          props.appContent.appClientId,
          { userId, changeComment: "" },
          processGetAppVisibiityUsers
        )
      );
    }
  };

  const getAppVisibilityValue = (appData: any) => {
    let visibilityValue = "All users";
    if (appData.visibility === appVisibilityEnum.TENANT_USERS) {
      visibilityValue = `${getTenantLabel(appData)} users`;
    }

    return visibilityValue;
  };

  const getIncludePacUsersValue = (appData: any) => {
    if (appData.visibility === appVisibilityEnum.TENANT_USERS) {
      if (appData.isVisibleForPac && appData.tenant && !!appData.tenant.tenantId) {
        if (appData.tenant.tenantId !== userTenantEnum.PAC) {
          return ", PAC users";
        }
      }
    }

    return "";
  };

  const isRowDisabled = (row: ITableRow) => !(isActive && (isOwner || isAdmin));

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  useEffect(() => {
    if (appVisibilityUsers.count > 0 && appVisibilityUsers.count <= config.page * config.limit) {
      paginationCallback(config.page - 1);
    }
  }, [appVisibilityUsers]);

  const headCells: ITableHeaderCells[] = [
    {
      id: "fullName",
      label: "Full name",
      value: (row: ITableRow) => `${row.fullName}`,
      width: "3fr",
    },
    { id: "delete", label: "Delete" },
  ];

  const tableProps: any = {
    componentName: "selected-addl-users",
    deleteCell: {
      as: "a",
      disabled: isRowDisabled,
      onClick: (row: ITableRow) => {
        if (!isRowDisabled(row)) {
          handleDeleteAppVisibilityUser(row.userId);
        }
      },
    },
    head: {
      cells: headCells,
    },
    list: {
      ...appVisibilityUsers,
    },
    name: "selected-addl-users",
    noHeader: true,
    noPagination: appVisibilityUsers.count <= config.limit,
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    rowStriped: "none",
  };

  return (
    <>
      {/* <SSpacer height="2px" color={colors.greys.lighten25} />
      <SSpacer /> */}

      {/* <SFlexContainer justifyContent="space-between"> */}
      <SFlexItem>
        <Heading variant="heading3">
          <span className={getTAClass("app_details_permissions_", TA_TYPES.TITLE, "visibility")}>
            Group visibility:
          </span>
        </Heading>
        <SVerticalSpacer />
        <STextDataValue>
          {getAppVisibilityValue(props.appContent)}
          {getIncludePacUsersValue(props.appContent)}
        </STextDataValue>
      </SFlexItem>

      <SSpacer height="8px" />

      <SFlexItem>
        <ButtonPrimary
          disabled={!(isActive && (isOwner || isAdmin))}
          className={getTAClass("app_details_permissions_", TA_TYPES.BUTTON, "edit_visibility")}
          onClick={handleOpenEditAppVisibility}
        >
          Edit visibility
        </ButtonPrimary>
      </SFlexItem>
      {/* </SFlexContainer> */}

      <SSpacer height="24px" />

      <SFlexContainer justifyContent="flex-start">
        <SVerticalSpacer width="24px" />
        <STextDataTitle>Additional users</STextDataTitle>
      </SFlexContainer>

      <SSpacer height="8px" />

      <SNextFixedWidthContainer paddingLeft="24px" minWidth="320px" maxWidth="800px">
        {/* <SVerticalSpacer width="24px" /> */}
        {/* <SModalStackItem> */}
        {/* <Grid padding="4px" columns="1fr 1fr"> */}
        <Grid columns="2fr 3fr">
          <GridItem>
            <Text
              variant="captionText"
              className={getTAClass(
                "app_details_permissions_",
                TA_TYPES.TITLE,
                "select-addl-users"
              )}
            >
              Select additional user:
            </Text>

            <SSpacer height="8px" />

            <div className={getTAClass("app_details_permissions_", TA_TYPES.INPUT, "addl-users")}>
              <Typeahead
                pageSelector="app-permissions-addl-users"
                disabled={!(isActive && (isOwner || isAdmin))}
                config={{
                  default: {
                    filter: {
                      email: "",
                      firstName: "",
                      fullName: "",
                      lastName: "",
                      role: "",
                      userId: "",
                    },
                    orderBy: "fullName",
                    roles: [ADMINISTRATOR, APPLICATION_OWNER, APPROVER, READ],
                  },
                  setFilter: (value: string | any) =>
                    typeof value === "string" ? { fullName: value } : { fullName: value.fullName },
                }}
                suggestion={{
                  getList: (conf: any) => dispatch(getUserList(conf)),
                  list: userList,
                  select: (user: any) => `${user.fullName}`,
                }}
                placeholder="Additional user name"
                name="additionalUser"
                value={""}
                hasSelected={(p: any) => true}
                handleTypeahead={handleTypeaheadInput}
                pattern="{fullName}"
                //   minInputLen={2}
                showListOnFocus={true}
                clearOnSelect={true}
              />
            </div>

            <SSpacer height="200px" />
          </GridItem>

          <GridItem>
            <Text
              variant="captionText"
              className={getTAClass(
                "app_details_permissions_",
                TA_TYPES.TITLE,
                "selected-addl-users"
              )}
            >
              {`Selected additional users (total: ${appVisibilityUsers.count})`}
              {/* Selected additional users: */}
            </Text>

            <SSpacer height="8px" />

            {/* LOADING */}
            {appVisibilityUsers.loading ? (
              <SOverlay>
                <ProgressIndicatorLinear />
              </SOverlay>
            ) : (
              <SSpacer height="2px" color={colors.greys.lighten25} />
            )}
            {/* <SNextFixedHeightContainer maxHeight="calc(6.5 * 48px)" overflow="auto"> */}
            <Table {...tableProps} />
            {/* </SNextFixedHeightContainer> */}
          </GridItem>
        </Grid>
        {/* </SModalStackItem> */}
      </SNextFixedWidthContainer>

      {props.editModal &&
        props.editModal.open &&
        props.editModal.type === modalTypeEnum.EDIT_APP_VISIBILITY && <ModalEditAppVisibility />}
    </>
  );
};
