import { Dispatch } from "redux";

import { ConfigService } from "../../../services/ConfigService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  GetConfigVersionTypes,
  IGetConfigVersionErrorAction,
  IGetConfigVersionRequestAction,
  IGetConfigVersionSuccessAction,
  TGetConfigVersionActions,
} from "./types";

export const GetConfigVersionRequestAction = (data: any): IGetConfigVersionRequestAction => ({
  payload: data,
  type: GetConfigVersionTypes.REQUEST,
});
export const GetConfigVersionSuccessAction = (data: any): IGetConfigVersionSuccessAction => ({
  payload: data,
  type: GetConfigVersionTypes.SUCCESS,
});
export const GetConfigVersionErrorAction = (error: any): IGetConfigVersionErrorAction => ({
  payload: error,
  type: GetConfigVersionTypes.ERROR,
});

// ACTION
export const getConfigVersion = (config: any, cb?: any) => (
  dispatch: Dispatch<TPopupActions | TGetConfigVersionActions | TEditModalActions>
) => {
  dispatch(GetConfigVersionRequestAction(config));
  ConfigService.getConfigVersion(config).then(
    (data: { data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(GetConfigVersionSuccessAction(data.data));
      if (cb) {
        cb(data.data.data.content);
      }
    },
    (error: any) => {
      dispatch(GetConfigVersionErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
