import { Dispatch } from "redux";

import { ConfigService } from "../../../services/ConfigService";

import { GetConfigGenerateType, TGetConfigGenerateActions } from "./types";

export const getConfigGenerateRequestAction = (): TGetConfigGenerateActions => ({
  type: GetConfigGenerateType.REQUEST,
});

export const getConfigGenerateSuccessAction = (data: any): TGetConfigGenerateActions => ({
  payload: data,
  type: GetConfigGenerateType.SUCCESS,
});

export const getConfigGenerateErrorAction = (error: string): TGetConfigGenerateActions => ({
  payload: error,
  type: GetConfigGenerateType.ERROR,
});

export const generateConfig = (config: any, cb?: any) => (dispatch: Dispatch<TGetConfigGenerateActions>) => {
  dispatch(getConfigGenerateRequestAction());
  ConfigService.generateConfig(config).then(
    (response: { data: { data: any } }) => {
      dispatch(getConfigGenerateSuccessAction(response.data.data));
      cb(response.data.data);
    },
    (error: any) => {
      dispatch(getConfigGenerateErrorAction(error));
    }
  );
};
