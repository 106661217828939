import { ButtonPrimary } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { editModalCloseAction } from "../../actions/modal/edit/actions";
import { editTopic } from "../../actions/topic/edit/actions";
import { getTopicDetails } from "../../actions/topic/get-details/actions";
import { isValidValue } from "../../helper/util";
import {
  maxPayloadSizeValues,
  offloadType,
  payloadFormatEnum,
  qosEnum,
  throttleThresholdFactorValues,
  topicTypeEnum,
} from "../../models/ITopicConfig";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer } from "../../styles/styles";
import { TopicValidationErrorCodeEnum as TopicNameError } from "../TopicValidatorUtil";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateBase } from "./ModalCreateBase";
import { ModalEditTopicPage1 } from "./ModalEditTopicPage1";
import { ModalEditTopicPage2 } from "./ModalEditTopicPage2";
import { ModalEditTopicPage3 } from "./ModalEditTopicPage3";

export const ModalEditTopic = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser, editModal, payloadGet, payloadUpload } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
    payloadGet: state.payloadGet,
    payloadUpload: state.payloadUpload,
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isTopicNameValid, setIsTopicNameValid] = useState(TopicNameError.NO_ERROR);

  const [config, setConfig] = useState({
    accessAnonymous: false,
    appData: {
      appClientId: "",
      appName: "",
    },
    changeComment: "",
    description: "",
    featureName: "",
    isSecure: false,
    localGroupBridge: "",
    maxPayloadSize: maxPayloadSizeValues.default,
    multicast: false,
    offloadType: offloadType.DEFERRED,
    payloadData: {
      payloadFile: File,
      payloadFileName: "",
      payloadFormat: "",
      payloadSchema: "",
    },
    payloadFormat: payloadFormatEnum.JSON,
    payloadType: "",
    priority: "1",
    qosLevel: qosEnum.ATLEAST_ONCE,
    retainRequired: false,
    schema: "",
    status: "",
    throttleThresholdFactor: throttleThresholdFactorValues.default,
    topicId: "",
    topicName: "",
    topicPayloadId: "",
    topicType: topicTypeEnum.AIR,
    ttl: 0,
  });

  const changeCommentRequired = false; // topic edit does not require comment
  const [validPage, setValidPage] = useState([false, true, true, !changeCommentRequired]);

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(`${index}`)] = value;
    setValidPage(updatedPage);
  };

  const checkValidity = () => {
    if (
      config.topicName &&
      isTopicNameValid === TopicNameError.NO_ERROR &&
      config.appData &&
      config.appData.appName
    ) {
      handleSetValidPage(0, true);
    } else {
      handleSetValidPage(0, false);
    }
  };

  const checkTopicSettings = () => {
    let topicSettingsValid = true; // no additional checking for airside only topic

    if (config.topicType === topicTypeEnum.A2G) {
      topicSettingsValid =
        isValidValue(config.maxPayloadSize, maxPayloadSizeValues) &&
        isValidValue(config.throttleThresholdFactor, throttleThresholdFactorValues);
    }

    handleSetValidPage(1, topicSettingsValid);
  };

  const checkPayloadInput = () => {
    const isPayloadPageValid =
      (config.topicPayloadId && config.topicPayloadId.length > 0) ||
      config.payloadData.payloadFileName.length > 0;

    handleSetValidPage(2, isPayloadPageValid);
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(3, isCommentValid);
  };

  const handleSubmit = () => {
    const submitData: any = {
      accessAnonymous: config.accessAnonymous,
      changeComment: config.changeComment,
      dependencies: "",
      description: config.description,
      featureName: config.featureName,
      isSecure: config.isSecure,
      localGroupBridge: config.localGroupBridge,
      maxPayloadSize: config.maxPayloadSize,
      multicast: config.multicast,
      obfuscationRequirement: "",
      offloadType: config.offloadType,
      priority: config.priority,
      qosLevel: config.qosLevel,
      retainRequired: config.retainRequired,
      serviceName: "",
      throttleThresholdFactor: config.throttleThresholdFactor,
      topicCategory: "",
      // topicMqttClient: {
      //   accessLevel: "",
      //   frequency: "",
      //   retainRequired: "",
      // },
      topicName: config.topicName,
      topicOwner: config.appData && config.appData.appClientId,
      topicPayloadId: config.topicPayloadId,
      // topicType: "",
      ttl: config.ttl,
      versionId: "1.0",
    };

    if (currentUser.data.role !== ADMINISTRATOR) {
      delete submitData.accessAnonymous;
    }

    dispatch(
      editTopic(submitData, config.topicId, (topicId: any) => {
        dispatch(getTopicDetails(topicId));
        dispatch(editModalCloseAction());
      })
    );
  };

  /* tslint:disable:prefer-switch */
  useEffect(() => {
    if (currentPage === 0) {
      checkValidity();
    } else if (currentPage === 1) {
      checkTopicSettings();
    } else if (currentPage === 2) {
      checkPayloadInput();
    } else if (currentPage === 3) {
      checkCommentInput();
    }

    // switch(currentPage) {
    //   case 0:
    //     checkValidity();
    //     break;

    //   case 2:
    //     checkPayloadInput();
    //     break;

    //   case 3:
    //     checkCommentInput();
    //     break;

    //   default:
    // }
  }, [config, isTopicNameValid]);
  /* tslint:enable:prefer-switch */

  useEffect(() => {
    setConfig({
      ...config,
      accessAnonymous: editModal.topicData.accessAnonymous,
      appData: { ...editModal.appData },
      changeComment: editModal.topicData.changeComment,
      description: editModal.topicData.description,
      featureName: editModal.topicData.featureName,
      isSecure: editModal.topicData.isSecure,
      localGroupBridge: editModal.topicData.localGroupBridge,
      maxPayloadSize: editModal.topicData.maxPayloadSize
        ? editModal.topicData.maxPayloadSize
        : maxPayloadSizeValues.default,
      multicast: editModal.topicData.multicast,
      offloadType: editModal.topicData.offloadType,
      payloadData: config.payloadData,
      payloadFormat: payloadFormatEnum.JSON,
      // payloadType: "",
      priority: editModal.topicData.priority,
      qosLevel: editModal.topicData.qosLevel,
      retainRequired: editModal.topicData.retainRequired,
      // schema: "",
      status: editModal.topicData.status,
      throttleThresholdFactor: editModal.topicData.throttleThresholdFactor
        ? editModal.topicData.throttleThresholdFactor
        : throttleThresholdFactorValues.default,
      topicId: editModal.topicData.topicId,
      topicName: editModal.topicData.topicName,
      topicPayloadId: editModal.topicData.topicPayloadId,
      topicType:
        editModal.topicData.topicType === topicTypeEnum.A2G ? topicTypeEnum.A2G : topicTypeEnum.AIR,
      ttl: editModal.topicData.ttl,
    });
  }, [editModal]);

  const modalTheme = {
    modal: {
      width: "640px", // Increase the width of the modal dialog
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title="Edit topic">
        {currentPage === 0 && (
          <ModalEditTopicPage1
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            setIsTopicNameValid={setIsTopicNameValid}
            isTopicNameValid={isTopicNameValid}
          />
        )}
        {currentPage === 1 && (
          <ModalEditTopicPage2
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
          />
        )}
        {currentPage === 2 && (
          <ModalEditTopicPage3
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            isEditing={isEditing}
            onToggleEditing={setIsEditing}
          />
        )}
        {currentPage === 3 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are making changes to this topic.`}
            commentRequired={changeCommentRequired}
          />
        )}

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage !== 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 0 || isEditing}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage <= 2 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={
                  !validPage[Number(`${currentPage}`)] ||
                  isEditing ||
                  payloadGet.loading ||
                  payloadUpload.loading
                }
              >
                Next
              </ButtonPrimary>
            ) : (
              <ButtonPrimary
                className="ta-modal-save-button"
                onClick={handleSubmit}
                // tslint:disable-next-line:strict-type-predicates
                disabled={!validPage[Number(currentPage)]}
              >
                Save
              </ButtonPrimary>
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
