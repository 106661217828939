/* istanbul ignore file */
import { IGetConfigVersionListData } from "../../../models/IConfigVersion";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum ConfigVersionListType {
  RESET = "GET_CONFIG_VERSION_LIST_RESET",
  REQUEST = "GET_CONFIG_VERSION_LIST_REQUEST",
  SUCCESS = "GET_CONFIG_VERSION_LIST_SUCCESS",
  ERROR = "GET_CONFIG_VERSION_LIST_ERROR",
}

export interface IConfigVersionListResetAction extends IReduxBaseAction<ConfigVersionListType> {
  type: ConfigVersionListType.RESET;
}

export interface IConfigVersionListRequestAction extends IReduxBaseAction<ConfigVersionListType> {
  type: ConfigVersionListType.REQUEST;
}

export interface IConfigVersionListSuccessAction extends IReduxBaseAction<ConfigVersionListType> {
  payload: IGetConfigVersionListData;
  type: ConfigVersionListType.SUCCESS;
}

export interface IConfigVersionListErrorAction extends IReduxBaseAction<ConfigVersionListType> {
  payload: string;
  type: ConfigVersionListType.ERROR;
}

export type TConfigVersionListActions =
  | IConfigVersionListResetAction
  | IConfigVersionListRequestAction
  | IConfigVersionListSuccessAction
  | IConfigVersionListErrorAction;
