export enum tabAppDetailsEnum {
  PUBLISH,
  SUBSCRIBE,
  DESTINATION_ROUTING,
  DETAILS,
  PERMISSIONS,
  HISTORY,
}
export enum topicType {
  SUBSCRIBE = "SUBSCRIBE",
  PUBLISH = "PUBLISH",
}

export enum authTypeEnum {
  CREDENTIALS = "Credentials",
  CERTIFICATE = "Client certificate",
}

export enum appVisibilityEnum {
  TENANT_USERS = "TENANT USERS",
  ALL_USERS = "ALL USERS",
}
