import {
  FilterTopicListType,
  TFilterTopicListActions,
} from "../../actions/topic/filter-list/types";
import { IGetTopicListContent } from "../../models/ITopicConfig";

interface ITopicClientListState {
  content: IGetTopicListContent[];
  count: number;
  error: boolean;
  loading: boolean;
}

const initialState: ITopicClientListState = {
  content: [],
  count: 0,
  error: false,
  loading: true,
};

export const filterTopicList = (
  state: ITopicClientListState = initialState,
  action: TFilterTopicListActions
): ITopicClientListState => {
  switch (action.type) {
    case FilterTopicListType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case FilterTopicListType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case FilterTopicListType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
