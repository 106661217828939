import {
  ButtonIcon,
  ButtonPrimary,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAircraftDetails } from "../../actions/aircraft/get-details/actions";
import { regenerateRackCertLoadable } from "../../actions/aircraft/re-generate-cert-loadable/actions";
import { addAircraftsToGroups } from "../../actions/aircraftgroup/add-aircrafts-to-groups/actions";
import { removeAircraftsFromGroups } from "../../actions/aircraftgroup/remove-aircrafts-from-groups/actions";
import { editModalCloseAction, editModalOpenAction } from "../../actions/modal/edit/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { AircraftTypeEnum } from "../../models/IAircraft";
import { tabAircraftDetailsEnum } from "../../models/IAircraftDetails";
import { GroundEnvironmentTypeEnum } from "../../models/IDestination";
import { modalTypeEnum } from "../../models/IEditModalConfig";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { ModalSelectAircraftGroups } from "../modals/ModalSelectAircraftGroups";
import { PageError, PageLoading } from "../PageState";

import { AircraftChangeHistory } from "./AircraftDetails/AircraftChangeHistory";
import { AircraftDetailsDetailsTab } from "./AircraftDetails/AircraftDetailsDetailsTab";
import { AircraftDetailsTabs } from "./AircraftDetails/AircraftDetailsTabs";
import { AircraftGroupList } from "./AircraftDetails/AircraftGroupList";

/* tslint:disable:cyclomatic-complexity */
export const AircraftDetails = ({ match }: { match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { aircraftDetails, currentUser, editModal } = useSelector((state: RootState) => ({
    aircraftDetails: state.aircraftDetails,
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const [needRefresh, setNeedRefresh] = useState(0);

  const [activeTab, setActiveTab] = useState(tabAircraftDetailsEnum.DETAILS);

  useEffect(() => {
    dispatch(getAircraftDetails(match.params.aircraftId));
  }, [match.params.aircraftId, needRefresh]);

  const handleAddToGroups = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: `Please enter a comment about why you are adding ${
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack"
          } to these groups.`,
          commentRequired: true,
          groupEnv:
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
              ? GroundEnvironmentTypeEnum.PRODUCTION
              : GroundEnvironmentTypeEnum.INTEGRATION,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              addAircraftsToGroups(
                {
                  aircraftGroups: param.groupIds,
                  aircrafts: [aircraftDetails.content.aircraftId],
                  changeComment: param.changeComment,
                },
                () => {
                  setNeedRefresh(needRefresh + 1);
                }
              )
            );
          },
          tailNumbers: aircraftDetails.content.tailNumber,
          tenantId: aircraftDetails.content.tenant.tenantId,
          title: `Add ${
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack"
          } to groups`,
        },
        type: modalTypeEnum.SELECT_AIRCRAFT_GROUPS,
      })
    );
  };

  const handleRegenerateCertLoadable = () => {
    dispatch(
      regenerateRackCertLoadable(
        {
          aircraftId: aircraftDetails.content.aircraftId,
          changeComment: "Regeneration of cert loadble initiated",
        },
        () => {
          setNeedRefresh(needRefresh + 1);
        }
      )
    );
  };

  const handleRemoveFromGroups = () => {
    dispatch(
      editModalOpenAction({
        popupData: {
          commentHint: `Please enter a comment about why you are removing ${
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack"
          } from these groups.`,
          commentRequired: true,
          groupEnv:
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
              ? GroundEnvironmentTypeEnum.PRODUCTION
              : GroundEnvironmentTypeEnum.INTEGRATION,
          onConfirm: (param: any) => {
            dispatch(editModalCloseAction());
            dispatch(
              removeAircraftsFromGroups(
                {
                  aircraftGroups: param.groupIds,
                  aircrafts: [aircraftDetails.content.aircraftId],
                  changeComment: param.changeComment,
                },
                () => {
                  setNeedRefresh(needRefresh + 1);
                }
              )
            );
          },
          tailNumbers: aircraftDetails.content.tailNumber,
          tenantId: aircraftDetails.content.tenant.tenantId,
          title: `Remove ${
            aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT ? "aircraft" : "rack"
          } from groups`,
        },
        type: modalTypeEnum.SELECT_AIRCRAFT_GROUPS,
      })
    );
  };

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link
              to={
                !aircraftDetails.loading &&
                aircraftDetails.content.tenant &&
                `/airlines/${aircraftDetails.content.tenant.tenantId}?tab=${
                  aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
                    ? "aircrafts"
                    : "racks"
                }`
              }
              className="ta-aircraft-details-button-back"
            >
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-aircraft-details-title">
              {aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
                ? "Aircraft"
                : "Rack"}{" "}
              details
            </SPageHeading>
          </GridItem>
        </Grid>

        {aircraftDetails.loading && <PageLoading />}

        {aircraftDetails.error && <PageError />}

        {/* Airline DETAILS */}
        <Grid>
          <GridItem>
            <SFlexContainer justifyContent="space-between">
              <SFlexItem>
                <Heading variant="heading2">
                  <span className={getTAClass("aircraft-details", TA_TYPES.TITLE, "tail-number")}>
                    {aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
                      ? "Tail number"
                      : "Rack name"}
                    {": "}
                    {aircraftDetails.content.tailNumber}
                  </span>
                </Heading>
              </SFlexItem>

              {/* <SFlexItem>
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "edit")}
                // onClick={handleOpenEditModal}
                >
                  Edit
                </ButtonPrimary>
                <SVerticalSpacer />
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("applicationDetails", TA_TYPES.BUTTON, "delete")}
                // onClick={askForConfirmation}
                >
                  Disable
                </ButtonPrimary>
              </SFlexItem> */}
            </SFlexContainer>
          </GridItem>
        </Grid>

        <SSpacer />

        {!aircraftDetails.loading && aircraftDetails.content && (
          <Grid>
            <GridItem>
              <DataDetailLabel
                title="Airline"
                value={aircraftDetails.content.tenant.tenantName}
                className={getTAClass("aircraft-details", TA_TYPES.TEXT, "tenant-name")}
              />
              <DataDetailLabel
                title="ICAO"
                value={aircraftDetails.content.tenant.icao.toUpperCase()}
                className={getTAClass("aircraft-details", TA_TYPES.TEXT, "icao")}
              />
              <SFlexContainer justifyContent="space-between">
                <SFlexItem>
                  <DataDetailLabel
                    title="Status"
                    value={aircraftDetails.content.activeState ? "ACTIVE" : "INACTIVE"}
                    className={getTAClass("aircraft-details", TA_TYPES.TEXT, "status")}
                  />
                </SFlexItem>
                <SVerticalSpacer />
                {aircraftDetails.content.aircraftType === AircraftTypeEnum.RACK && (
                  <SFlexItem>
                    <ButtonPrimary
                      className={getTAClass(
                        "aircraft-details",
                        TA_TYPES.BUTTON,
                        "regenerate-loadable"
                      )}
                      onClick={handleRegenerateCertLoadable}
                    >
                      Re-generate cert
                    </ButtonPrimary>
                  </SFlexItem>
                )}
              </SFlexContainer>

              <SSpacer height="4px" />

              <SFlexContainer justifyContent="space-between">
                <SFlexItem>
                  <DataDetailLabel
                    title="Group count"
                    value={aircraftDetails.content.aircraftGroupCount}
                    className={getTAClass("aircraft-details", TA_TYPES.TEXT, "group-count")}
                  ></DataDetailLabel>
                </SFlexItem>
                <SFlexItem>
                  <ButtonPrimary
                    className={getTAClass("aircraft-details", TA_TYPES.BUTTON, "add-to-groups")}
                    onClick={handleAddToGroups}
                  >
                    Add to groups
                  </ButtonPrimary>
                  <SVerticalSpacer />
                  <ButtonPrimary
                    className={getTAClass(
                      "aircraft-details",
                      TA_TYPES.BUTTON,
                      "remove-from-groups"
                    )}
                    onClick={handleRemoveFromGroups}
                  >
                    Remove from groups
                  </ButtonPrimary>
                </SFlexItem>
              </SFlexContainer>
            </GridItem>
          </Grid>
        )}

        <SSpacer />

        {/* TABS */}
        <AircraftDetailsTabs
          activeTab={activeTab}
          onChange={(tab: tabAircraftDetailsEnum) => {
            setActiveTab(tab);
          }}
        />

        <SSpacer />

        {/* TABLE & DATA TAB */}
        {!aircraftDetails.loading && aircraftDetails.content && (
          <>
            {activeTab === tabAircraftDetailsEnum.GROUPS && (
              <AircraftGroupList
                acContent={aircraftDetails.content}
                // groupType={AircraftGroupTypeEnum.TENANT}
                // tenant={aircraftDetails.content.tenant}
                groupEnv={
                  aircraftDetails.content.aircraftType === AircraftTypeEnum.AIRCRAFT
                    ? GroundEnvironmentTypeEnum.PRODUCTION
                    : GroundEnvironmentTypeEnum.INTEGRATION
                }
                noCreate
                noLink
                refresh={needRefresh}
              />
            )}
            {activeTab === tabAircraftDetailsEnum.DETAILS && (
              <AircraftDetailsDetailsTab acContent={aircraftDetails.content} />
            )}
            {activeTab === tabAircraftDetailsEnum.HISTORY && (
              <AircraftChangeHistory
                acContent={aircraftDetails.content}
                userData={currentUser.data}
              />
            )}
          </>
        )}

        {/* SELECT AIRCRAFT GROUPS MODAL */}
        {editModal && editModal.open && editModal.type === modalTypeEnum.SELECT_AIRCRAFT_GROUPS && (
          <ModalSelectAircraftGroups />
        )}
      </SPageContainer>
    </>
  );
};
/* tslint:enable:cyclomatic-complexity */
