import { Grid, GridItem, ProgressIndicatorLinear, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import DiffViewer, { DiffMethod } from "react-diff-viewer";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { getChangeLog } from "../../actions/changelog/get/actions";
import { RootState } from "../../store";
import {
  SChangeLogContent,
  SFlexContainer,
  SNextFixedHeightContainer,
  SOverlay,
  SPayloadSchema,
  SSpacer,
  STallText,
  STextDataTitle,
  STextDataValue,
  STextWrapper,
} from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalDisplayChangeLog = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { changeLogGet, editModal } = useSelector((state: any) => ({
    changeLogGet: state.changeLogGet,
    editModal: state.editModal,
  }));

  const [dialogTitle, setDialogTitle] = useState("View test changes");

  useEffect(() => {
    if (editModal.popupData.isRelease) {
      setDialogTitle("View release changes");
    }
    dispatch(getChangeLog(editModal.popupData.isRelease));
  }, [editModal]);

  const modalTheme = {
    modal: {
      width: "1024px",
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {changeLogGet.loading && (
          <SOverlay>
            <ProgressIndicatorLinear />
          </SOverlay>
        )}

        {changeLogGet.fromConfigVersion && !!changeLogGet.fromConfigVersion.version && (
          <SFlexContainer justifyContent="flex-start">
            <SSpacer height="4px" />
            <STextDataValue>
              Changes since last release version {changeLogGet.fromConfigVersion.version}
            </STextDataValue>
            <SSpacer height="8px" />
          </SFlexContainer>
        )}

        <SChangeLogContent
          placeholder="Change log data"
          readonly={true}
          value={
            changeLogGet.loading
              ? "loading..."
              : changeLogGet.content.length > 0
                ? changeLogGet.content
                : "Change log data not available"
          }
        />
      </ModalCreateBase>
    </ThemeProvider>
  );
};
