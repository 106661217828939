import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditAppOwnersTypes,
  IEditAppOwnersErrorAction,
  IEditAppOwnersRequestAction,
  IEditAppOwnersSuccessAction,
  TSetEditAppOwnersActions,
} from "./types";

export const EditAppOwnersRequestAction = (data: any): IEditAppOwnersRequestAction => ({
  payload: data,
  type: EditAppOwnersTypes.REQUEST,
});
export const EditAppOwnersSuccessAction = (data: any): IEditAppOwnersSuccessAction => ({
  payload: data,
  type: EditAppOwnersTypes.SUCCESS,
});
export const EditAppOwnersErrorAction = (error: any): IEditAppOwnersErrorAction => ({
  payload: error,
  type: EditAppOwnersTypes.ERROR,
});

export const editAppOwners = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetEditAppOwnersActions | TEditModalActions>
) => {
  dispatch(EditAppOwnersRequestAction(config));
  ApplicationService.updateAppOwners(config).then(
    (data: { data: any }) => {
      dispatch(EditAppOwnersSuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: "Application owners updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
      cb(data.data.data.content.appClientId);
    },
    (error: any) => {
      dispatch(EditAppOwnersErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
