import { ButtonPrimary, ProgressIndicatorCircular, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { deployGroundConfig } from "../../actions/groundconfig/deploy/actions";
import { editModalCloseAction } from "../../actions/modal/edit/actions";
import { ADMINISTRATOR } from "../../models/UserTypes";
import { RootState } from "../../store";
import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalDeployGroundConfigs = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [changeComment, setChangeComment] = useState("");
  const [envStr, setEnvStr] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [newVersion, setNewVersion] = useState("");
  const [showProductionWarning, setShowProductionWarning] = useState(false);

  const handleGenerateConfigs = () => {
    if (!editModal.popupData.isInt && !showProductionWarning) {
      setShowProductionWarning(true);
    } else {
      setInProgress(true);
      dispatch(
        deployGroundConfig(editModal.popupData.isInt, changeComment, (data: any) => {
          if (data && data.content) {
            setNewVersion(data.content.version);
            setCurrentPage(1);
          }
        })
      );
    }
  };

  useEffect(() => {
    setEnvStr(editModal.popupData.isInt ? "integration" : "production");
  }, [editModal]);

  const handleCloseDialog = () => {
    dispatch(editModalCloseAction());
    if (editModal.popupData.onClose) {
      editModal.popupData.onClose();
    }
  };

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (!validateTextInput(e, maxChar)) {
      return;
    }
    setChangeComment(e.target.value);
  };

  return (
    <ModalCreateBase title={`Deploy groundside configuration`}>
      {currentPage === 0 && (
        <>
          <SSpacer height="4px" />
          <Text>{`Deploy environment: ${envStr}`}</Text>
          <SSpacer height="12px" />

          <Textarea
            className="ta-modal-input-comment"
            placeholder={`Please enter a comment about why you are deploying groundside configuration for ${envStr}.`}
            value={changeComment}
            onChange={(e: any) => {
              handleTextInput(e, "comment", 2048);
            }}
          />

          {showProductionWarning ? (
            <>
              <SSpacer height="4px" />
              <Text>
                {`You are about to deploy groundside configuration to `}
                <span style={{ color: "red" }}>
                  <b>{envStr}</b>
                </span>
                {`. Please confirm again!!`}
              </Text>
            </>
          ) : (
            <></>
          )}

          <SSpacer height="20px" />

          <SFlexContainer justifyContent="flex-end">
            <ButtonPrimary
              className="ta-modal-ground-config-confirm-button"
              onClick={handleGenerateConfigs}
              disabled={inProgress || changeComment.length <= 0}
            >
              {!inProgress &&
                (!editModal.popupData.isInt && !showProductionWarning ? (
                  <>Continue</>
                ) : (
                  <>Confirm</>
                ))}
              {inProgress && <ProgressIndicatorCircular size="small" />}
            </ButtonPrimary>
          </SFlexContainer>
        </>
      )}

      {currentPage === 1 && (
        <>
          <SSpacer height="4px" />
          <Text>{`Groundside configuration for ${envStr} is deployed successfully.`}</Text>

          <SSpacer height="4px" />
          <Text>{`New version: ${newVersion}`}</Text>

          <SSpacer height="20px" />

          <SFlexContainer justifyContent="flex-end">
            <ButtonPrimary className="ta-modal-close-button" onClick={handleCloseDialog}>
              Close
            </ButtonPrimary>
          </SFlexContainer>
        </>
      )}
    </ModalCreateBase>
  );
};
