/* istanbul ignore file */
/* tslint:disable:match-default-export-name */
import Axios, { AxiosInstance } from "axios";
import jwt from "jsonwebtoken";

import { clearSession, isExpired, redirect } from "../helper/sessionHelper";

import { UserService } from "./UserService";

let instance: AxiosInstance;
let configJson: any;
let apiUrl: string;

export const getConfigJson = () => configJson;

export const getApiUrl = async () => {
  if (apiUrl) {
    return apiUrl;
  }

  const fetchConfig = await fetch(`${process.env.PUBLIC_URL}/config.json`);
  configJson = await fetchConfig.json();

  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;

    return apiUrl;
  }

  apiUrl = configJson.apiUrl;

  return apiUrl;
};

export const getIdToken = () => {
  const refreshToken = localStorage.getItem("UMB_RT") || "";
  const idToken = localStorage.getItem("UMB_IT") || "";
  const clientId = localStorage.getItem("UMB_CLIENT_ID") || "";
  const pacAppClientId = localStorage.getItem("UMB_PAC_APP_CLIENT_ID") || "";
  let accessDetails: any;

  try {
    accessDetails = jwt.decode(idToken) || {};
  } catch (e) {
    console.info("JWT malformed", e);
    redirect();
  }

  if (process.env.REACT_APP_ENV === "fethr") {
    return idToken;
  }

  if ((!accessDetails.exp || isExpired(accessDetails.exp)) && !refreshToken) {
    redirect();
  }

  if (refreshToken && !clientId && !pacAppClientId) {
    redirect();
  }

  if (!accessDetails.exp || isExpired(accessDetails.exp)) {
    return UserService.refreshToken(clientId || pacAppClientId, refreshToken)
      .then(({ data }: { data: any }) => {
        const expiration =
          +(new Date().getTime() / 1000).toFixed(0) + +data.AuthenticationResult.ExpiresIn;

        if (data.AuthenticationResult.AccessToken === "" || isExpired(expiration.toString())) {
          clearSession();

          redirect();

          return;
        }

        console.info("Refreshed tokens");

        localStorage.setItem("UMB_AT", data.AuthenticationResult.AccessToken);
        localStorage.setItem("UMB_IT", data.AuthenticationResult.IdToken);

        return data.AuthenticationResult.IdToken;
      })
      .catch((error: any) => {
        console.error("Refresh failure:", error);
        clearSession();

        redirect();
      });
  }

  // Token is still valid
  return idToken;
};

export const axiosInstance = async () => {
  if (!instance) {
    instance = Axios.create({
      baseURL: await getApiUrl(),
    });

    instance.interceptors.request.use(
      async (config: any) => {
        if (process.env.NODE_ENV === "test") {
          return config;
        }
        const idToken = await getIdToken();

        config.headers.Authorization = `Bearer ${idToken}`;

        return config;
      },
      async (error: any) => Promise.reject(error)
    );

    // INTERCEPT AXIOS AND HANDLE ERROR
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // Redirect anything with Unauthorized response
        if (error.response.status === 401) {
          redirect();
        }

        return Promise.reject(error.response && error.response.data);
      }
    );
  }

  return instance;
};
