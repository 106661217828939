import { Dispatch } from "redux";

import { ChangeHistoryService } from "../../../services/ChangeHistoryService";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  GetGndChangeLogTypes,
  IGetGndChangeLogErrorAction,
  IGetGndChangeLogRequestAction,
  IGetGndChangeLogSuccessAction,
  TGetGndChangeLogActions,
} from "./types";

export const GetGndChangeLogRequestAction = (): IGetGndChangeLogRequestAction => ({
  payload: null,
  type: GetGndChangeLogTypes.REQUEST,
});
export const GetGndChangeLogSuccessAction = (data: any): IGetGndChangeLogSuccessAction => ({
  payload: data,
  type: GetGndChangeLogTypes.SUCCESS,
});
export const GetGndChangeLogErrorAction = (error: any): IGetGndChangeLogErrorAction => ({
  payload: error,
  type: GetGndChangeLogTypes.ERROR,
});

// ACTION
export const getGndChangeLog =
  (isInt: boolean, cb?: any) => (dispatch: Dispatch<TPopupActions | TGetGndChangeLogActions>) => {
    dispatch(GetGndChangeLogRequestAction());
    ChangeHistoryService.getGndChangeLogFile(isInt).then(
      (data: { data: any }) => {
        // tslint:disable-next-line: ban-ts-ignore
        // @ts-ignore
        dispatch(GetGndChangeLogSuccessAction(data.data.data));
        if (cb) {
          cb(data.data.data.content);
        }
      },
      (error: any) => {
        dispatch(GetGndChangeLogErrorAction(error));
        dispatch(
          popupOpenAction({
            content: (error && error.message) || "",
            title: "Error",
            type: "Info",
          })
        );
      }
    );
  };
