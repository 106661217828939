import { EditModalActions, TEditModalActions } from "../../actions/modal/edit/types";

export interface IEditModalState {
  open: boolean;
  type: string;
}

const initialState: IEditModalState = {
  open: false,
  type: "",
};

export const editModal = (
  state: IEditModalState = initialState,
  action: TEditModalActions
): IEditModalState => {
  switch (action.type) {
    case EditModalActions.OPEN:
      return {
        ...state,
        ...action.payload,
        open: true,
      };
    case EditModalActions.CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
