import { Tile } from "next-components";
import styled from "styled-components";

import { IAnimation } from "../models/IStyles";

export const SAnimatedContent = styled("div")<IAnimation>`
  position: relative;
  animation: slideInContent ${(props: IAnimation) => props.speed || "300ms"} ease-in-out;

  @keyframes slideInContent {
    0% {
      transform: scale(${(props: IAnimation) => props.scaleFrom || "0.95"});
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const SNextAnimatedContent = styled(Tile)<IAnimation>`
  position: relative;
  animation: slideInContent ${(props: IAnimation) => props.speed || "300ms"} ease-in-out;

  @keyframes slideInContent {
    0% {
      transform: scale(${(props: IAnimation) => props.scaleFrom || "0.8"});
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
