import {
  ButtonIcon,
  ButtonPrimary,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
  ProgressIndicatorLinear,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getAircraftGroupDetails } from "../../actions/aircraftgroup/get-details/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { AircraftGroupTypeEnum, tabAircraftGroupDetailsEnum } from "../../models/IAircraftGroup";
import { GroundEnvironmentTypeEnum } from "../../models/IDestination";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
  SVerticalSpacer,
} from "../../styles/styles";
import { DataDetailLabel } from "../DataDetailLabel";
import { PageError, PageLoading } from "../PageState";

import { AircraftGroupChangeHistory } from "./AircraftDetails/AircraftGroupChangeHistory";
import { AircraftGroupDetailsDetailsTab } from "./AircraftDetails/AircraftGroupDetailsDetailsTab";
import { AircraftGroupDetailsTabs } from "./AircraftDetails/AircraftGroupDetailsTabs";
import { AircraftGroupList } from "./AircraftDetails/AircraftGroupList";
import { AircraftList } from "./AircraftDetails/AircraftList";
import { RackList } from "./AircraftDetails/RackList";

export const AircraftGroupDetails = ({ match }: { match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { aircraftGroupDetails, currentUser } = useSelector((state: RootState) => ({
    aircraftGroupDetails: state.aircraftGroupDetails,
    currentUser: state.currentUser,
  }));

  const [activeTab, setActiveTab] = useState(tabAircraftGroupDetailsEnum.DETAILS);

  useEffect(() => {
    dispatch(getAircraftGroupDetails(match.params.aircraftGroupId));
  }, [match.params.aircraftGroupId]);

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link
              to={
                aircraftGroupDetails.content.tenant
                  ? `/airlines/${aircraftGroupDetails.content.tenant.tenantId}?tab=groups`
                  : `/groundsideConfiguration`
              }
              className="ta-aircraft-group-details-button-back"
            >
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-aircraft-group-details-title">
              {aircraftGroupDetails.content.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL
                ? "Global"
                : "Aircraft"}{" "}
              group details
            </SPageHeading>
          </GridItem>
        </Grid>
        {/* ERROR */}

        {aircraftGroupDetails.loading && <PageLoading />}

        {aircraftGroupDetails.error && <PageError />}

        {/* Airline DETAILS */}
        <Grid>
          <GridItem>
            <SFlexContainer justifyContent="space-between">
              <SFlexItem>
                <Heading variant="heading2">
                  <span
                    className={getTAClass("aircraft-group-details", TA_TYPES.TITLE, "tail-number")}
                  >
                    {"Group name: "}
                    {aircraftGroupDetails.content.aircraftGroupName}
                  </span>
                </Heading>
              </SFlexItem>

              {/* <SFlexItem>
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("aircraftGroupDetails", TA_TYPES.BUTTON, "edit")}
                // onClick={handleOpenEditModal}
                >
                  Edit
                </ButtonPrimary>
                <SVerticalSpacer />
                <ButtonPrimary
                // disabled={!(isActive && (isOwner || isAdmin))}
                // className={getTAClass("aircraftGroupDetails", TA_TYPES.BUTTON, "delete")}
                // onClick={askForConfirmation}
                >
                  Disable
                </ButtonPrimary>
              </SFlexItem> */}
            </SFlexContainer>
          </GridItem>
        </Grid>

        <SSpacer />

        {!aircraftGroupDetails.loading && aircraftGroupDetails.content && (
          <Grid>
            <GridItem>
              <DataDetailLabel
                title="Airline"
                value={
                  aircraftGroupDetails.content.tenant
                    ? aircraftGroupDetails.content.tenant.tenantName
                    : "Any"
                }
                className={getTAClass("aircraftGroupDetails", TA_TYPES.TEXT, "airline")}
              />
              <DataDetailLabel
                title="ICAO"
                value={
                  aircraftGroupDetails.content.tenant
                    ? aircraftGroupDetails.content.tenant.icao.toUpperCase()
                    : "Any"
                }
                className={getTAClass("aircraftGroupDetails", TA_TYPES.TEXT, "icao")}
              />
              <DataDetailLabel
                title="Group type"
                value={
                  aircraftGroupDetails.content.aircraftGroupType === AircraftGroupTypeEnum.GLOBAL
                    ? "Global for any airlines"
                    : "Airline specific"
                }
                className={getTAClass("aircraftGroupDetails", TA_TYPES.TEXT, "groupType")}
              />
              <DataDetailLabel
                title="Environment"
                value={
                  aircraftGroupDetails.content.aircraftGroupEnvironment ===
                  GroundEnvironmentTypeEnum.PRODUCTION
                    ? "Production"
                    : "Integration"
                }
                className={getTAClass("aircraftGroupDetails", TA_TYPES.TEXT, "groupEnv")}
              />
            </GridItem>
          </Grid>
        )}

        <SSpacer />

        {/* TABS */}
        <AircraftGroupDetailsTabs
          activeTab={activeTab}
          groupEnv={aircraftGroupDetails.content.aircraftGroupEnvironment}
          onChange={(tab: tabAircraftGroupDetailsEnum) => {
            setActiveTab(tab);
          }}
        />

        <SSpacer />

        {/* TABLE & DATA TAB */}
        {!aircraftGroupDetails.loading && aircraftGroupDetails.content && (
          <>
            {activeTab === tabAircraftGroupDetailsEnum.AIRCRAFTS && (
              <AircraftList
                acgContent={aircraftGroupDetails.content}
                tenant={aircraftGroupDetails.content.tenant}
                noBatch
                noCreate
                noLink
              />
            )}
            {activeTab === tabAircraftGroupDetailsEnum.RACKS && (
              <RackList
                acgContent={aircraftGroupDetails.content}
                tenant={aircraftGroupDetails.content.tenant}
                noBatch
                noCreate
                noLink
              />
            )}
            {activeTab === tabAircraftGroupDetailsEnum.DETAILS && (
              <AircraftGroupDetailsDetailsTab acgContent={aircraftGroupDetails.content} />
            )}
            {activeTab === tabAircraftGroupDetailsEnum.HISTORY && (
              <AircraftGroupChangeHistory
                acgContent={aircraftGroupDetails.content}
                userData={currentUser.data}
              />
            )}
          </>
        )}
      </SPageContainer>
    </>
  );
};
