/* tslint:disable:match-default-export-name */
import Axios from "axios";

import { queryFilterHelper } from "../helper/queryFilterHelper";
import { OrderType } from "../models/ITableConfig";
import { IUserListingTableConfig } from "../models/IUserListingTableConfig";

import { axiosInstance } from "./AxiosInstance";

const getUserList = async (conf: IUserListingTableConfig) => {
  const direction = conf.orderType === OrderType.ASC ? "ASC" : "DESC";
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${direction}`;
  const query = `?${pagination}${queryFilterHelper(conf)}`;
  const axios = await axiosInstance();

  return axios.get(`/users${query}`);
};

const getUserDetails = async (userId: string) => {
  const axios = await axiosInstance();

  return axios.get(`/users/${userId}`);
};

const setUserDetails = async (userData: any) => {
  const postData = { ...userData };
  delete postData.userId;
  const axios = await axiosInstance();

  return axios.put(`/users/${userData.userId}`, postData);
};

const getLoggedinUser = async () => {
  const axios = await axiosInstance();

  return axios.get(`/users/getLoggedInUserDetails`);
};

const refreshToken = async (clientId: string, token: string) => {
  const body = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    AuthParameters: {
      REFRESH_TOKEN: token,
    },
    ClientId: clientId,
  };

  return Axios({
    data: JSON.stringify(body),
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
    },
    method: "POST",
    url: "https://cognito-idp.us-west-2.amazonaws.com/",
  });
};

export const UserService = {
  getLoggedinUser,
  getUserDetails,
  getUserList,
  refreshToken,
  setUserDetails,
};
