import { darken, lighten, desaturate } from "polished";
var defaults = {
  primary: "#2a7fc9",
  secondary: "#d4217a",
  tertiary1: "#eb7a0a",
  tertiary2: "#722bca",
  tertiary3: "#2bcab7",
  notification: "#f5c800",
  error: "#ca2b2b",
  success: "#249446",
  greys: "#adadad",
  white: "#ffffff",
  black: "#000000"
};
var colors = {
  primary: {
    "default": defaults.primary,
    darken10: darken(0.1, defaults.primary),
    darken20: darken(0.2, defaults.primary),
    darken30: darken(0.3, defaults.primary),
    lighten10: lighten(0.1, defaults.primary),
    lighten20: lighten(0.2, defaults.primary),
    lighten30: lighten(0.3, defaults.primary),
    sl1: desaturate(0.4, lighten(0.4, defaults.primary)),
    sl2: desaturate(0.39, lighten(0.45, defaults.primary)),
    sl3: desaturate(0.44, lighten(0.48, defaults.primary))
  },
  secondary: {
    "default": defaults.secondary,
    darken10: darken(0.1, defaults.secondary),
    darken20: darken(0.2, defaults.secondary),
    darken30: darken(0.3, defaults.secondary),
    lighten10: lighten(0.1, defaults.secondary),
    lighten20: lighten(0.2, defaults.secondary),
    lighten30: lighten(0.3, defaults.secondary)
  },
  tertiary1: {
    "default": defaults.tertiary1,
    darken10: darken(0.1, defaults.tertiary1),
    lighten10: lighten(0.1, defaults.tertiary1)
  },
  tertiary2: {
    "default": defaults.tertiary2,
    darken10: darken(0.1, defaults.tertiary2),
    lighten10: lighten(0.1, defaults.tertiary2)
  },
  tertiary3: {
    "default": defaults.tertiary3,
    darken10: darken(0.1, defaults.tertiary3),
    lighten10: lighten(0.1, defaults.tertiary3)
  },
  notification: {
    "default": defaults.notification,
    darken10: darken(0.1, defaults.notification),
    lighten10: lighten(0.1, defaults.notification)
  },
  error: {
    "default": defaults.error,
    darken10: darken(0.1, defaults.error),
    lighten10: lighten(0.1, defaults.error)
  },
  success: {
    "default": defaults.success,
    darken10: darken(0.1, defaults.success),
    lighten10: lighten(0.1, defaults.success)
  },
  greys: {
    "default": defaults.greys,
    darken10: darken(0.1, defaults.greys),
    darken20: darken(0.2, defaults.greys),
    darken30: darken(0.3, defaults.greys),
    lighten20: lighten(0.2, defaults.greys),
    lighten25: lighten(0.25, defaults.greys),
    lighten30: lighten(0.3, defaults.greys)
  },
  general: {
    white: defaults.white,
    black: defaults.black
  }
};
export default colors;