enum AircraftGroupValidationErrorCodeEnum {
  // no Error
  NO_ERROR = 0,

  // length < 3
  LENGTH_LT_THREE = -11,

  // invalid character(s)
  INVALID_CHARACTERS = -12,

  // length too long more than 100 characters
  LENGTH_TOO_LONG = -13,

  // === other error ===
  // TBD
}

/**
 * Aircraft Group validation
 */
// tslint:disable-next-line:no-unnecessary-class
class AircraftGroupValidatorUtil {
  /**
   * Max aircraft group name limit
   */
  public static MAX_CHARACTER_LIMIT: number = 100;

  /**
   * validateAircraftGroupName
   * @param aircraftGroupName string
   * @returns AircraftGroupValidationErrorCodeEnum - error code
   */
  /* tslint:disable:cyclomatic-complexity */
  public static validateAircraftGroupName(
    aircraftGroupName: string
  ): AircraftGroupValidationErrorCodeEnum {
    let errCode: AircraftGroupValidationErrorCodeEnum =
      AircraftGroupValidationErrorCodeEnum.NO_ERROR; // no error

    //
    // 0. aircraft group name must at least 3 chars in length
    //
    // 1. overall whitelist characters allowed
    //      /^[a-zA-Z][a-zA-Z0-9_-]+$/
    //
    // 2. aircraft group name must less than or equal to 100 characters

    // aircraft group name min length 3
    if (!aircraftGroupName || aircraftGroupName.length < 3) {
      errCode = AircraftGroupValidationErrorCodeEnum.LENGTH_LT_THREE;

      return errCode;
    }

    // aircraft group name max length 100
    if (aircraftGroupName.length > AircraftGroupValidatorUtil.MAX_CHARACTER_LIMIT) {
      errCode = AircraftGroupValidationErrorCodeEnum.LENGTH_TOO_LONG;

      return errCode;
    }

    // whitelist chars check
    const nameTest = /^[a-zA-Z][a-zA-Z0-9_-]+$/;
    if (!nameTest.test(aircraftGroupName)) {
      errCode = AircraftGroupValidationErrorCodeEnum.INVALID_CHARACTERS;

      return errCode;
    }

    // no error
    return errCode;
  }
  /* tslint:enable:cyclomatic-complexity */
}

export { AircraftGroupValidationErrorCodeEnum, AircraftGroupValidatorUtil };
