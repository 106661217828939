import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";

import { SSpacer } from "../../styles/helper";
import { StatusIcon } from "../StatusIcon";

export const ModalCommentInputPage = (props: any) => {
  const [changeComment, setChangeComment] = useState("");

  useEffect(() => {
    if (props.config) {
      setChangeComment(props.config.changeComment);
    }
  }, []);

  useEffect(() => {
    if (props.config && props.onChange) {
      props.onChange({ ...props.config, changeComment });
    }
  }, [changeComment]);

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setChangeComment(e.target.value);
    }
  };

  const getCommentHint = () => {
    let hintStr =
      props.commentHint && props.commentHint.length > 0
        ? props.commentHint
        : "Please enter a comment about why you are making this change.";

    hintStr += props.commentRequired ? " (required)" : " (optional)";

    return hintStr;
  };

  return (
    <>
      {props.topInfoStr && props.topInfoStr.length > 0 && (
        <div>
          <Text variant="uiText" className="ta-modal-input-comment-top-info">
            {props.topInfoStr}
          </Text>
          <SSpacer height="8px" />
        </div>
      )}

      <Text variant="captionText" className="ta-modal-input-comment-caption">
        Please enter comment (max 2048 characters){" "}
        <StatusIcon
          className={
            !props.commentRequired || !!changeComment
              ? "ta-status-icon-success"
              : "ta-status-icon-fail"
          }
          check={!props.commentRequired || !!changeComment}
        />
      </Text>
      <Textarea
        className="ta-modal-input-comment"
        placeholder={getCommentHint()}
        value={changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "comment", 2048);
        }}
      />
    </>
  );
};
