import {
  EditApplicationTypes,
  TSetEditApplicationActions,
} from "../../actions/application/edit/types";

interface IApplicationEditState {
  content: any;
  error: boolean;
  loading: boolean;
}

const initialState: IApplicationEditState = {
  content: [],
  error: false,
  loading: false,
};

export const applicationEdit = (
  state: IApplicationEditState = initialState,
  action: TSetEditApplicationActions
): IApplicationEditState => {
  switch (action.type) {
    case EditApplicationTypes.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case EditApplicationTypes.SUCCESS:
      return {
        ...action.payload.data,
        error: false,
        loading: false,
      };
    case EditApplicationTypes.ERROR:
      return {
        ...action.payload.data,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
