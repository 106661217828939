import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  EditAppDestinationTypes,
  IEditAppDestinationErrorAction,
  IEditAppDestinationRequestAction,
  IEditAppDestinationSuccessAction,
  TSetEditAppDestinationActions,
} from "./types";

export const EditAppDestinationRequestAction = (data: any): IEditAppDestinationRequestAction => ({
  payload: data,
  type: EditAppDestinationTypes.REQUEST,
});
export const EditAppDestinationSuccessAction = (data: any): IEditAppDestinationSuccessAction => ({
  payload: data,
  type: EditAppDestinationTypes.SUCCESS,
});
export const EditAppDestinationErrorAction = (error: any): IEditAppDestinationErrorAction => ({
  payload: error,
  type: EditAppDestinationTypes.ERROR,
});

export const editAppDestination = (appId: string, destId: string, config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TSetEditAppDestinationActions | TEditModalActions>
) => {
  dispatch(EditAppDestinationRequestAction(config));
  ApplicationService.updateAppDestination(appId, destId, config).then(
    (data: { data: any }) => {
      dispatch(EditAppDestinationSuccessAction(data && data.data));
      dispatch(
        popupOpenAction({
          content: "Destination updated successfully!",
          title: "Success",
          type: "Info",
        })
      );
      if (cb) {
        cb(data.data.data.content.appClientDestinationId);
      }
    },
    (error: any) => {
      dispatch(EditAppDestinationErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
