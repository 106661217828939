import { ITopologyTableConfig } from "../models/ITopologyConfig";

import { axiosInstance } from "./AxiosInstance";

const getTopologies = async (conf: ITopologyTableConfig) => {
  const pagination = `limit=${conf.limit}&offset=${conf.page}&orderBy=${conf.orderBy}&orderType=${conf.orderType}`;
  const query = `?${pagination}`;
  const axios = await axiosInstance();

  return axios.get(`/topology${query}`);
};

export const TopologyService = {
  getTopologies,
};
