import { TenantFilterSortType, TTenantFilterSortActions } from "./types";

export const tenantFilterSortClearAction = (): TTenantFilterSortActions => ({
  type: TenantFilterSortType.CLEAR,
});

export const tenantFilterSortResetAction = (): TTenantFilterSortActions => ({
  type: TenantFilterSortType.RESET,
});

export const tenantFilterSortApplyAction = (data: any): TTenantFilterSortActions => ({
  payload: data,
  type: TenantFilterSortType.APPLY,
});
