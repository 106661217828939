export interface IChangeHistoryTenant {
  description: string;
  icao: string;
  tenantId: string;
  tenantName: string;
  tenantType: string;
}

export interface IChangeHistoryAppClient {
  appClientId: string;
  appName: string;
  appVersion: string;
  tenant: IChangeHistoryTenant;
}

export interface IChangeHistoryTopic {
  appClient?: IChangeHistoryAppClient;
  status?: string;
  topicId: string;
  topicName: string;
}

export interface IChangeHistoryTopicPayloadDetail {
  payloadMasterId: string;
  payloadSchema: string;
  payloadType: string;
}

export interface IChangeHistoryBrokerType {
  brokerType: string;
  brokerTypeId: string;
  brokerTypeName: string;
}

export interface IChangeHistoryConfigVersion {
  comment: string;
  configVersionId: string;
  isChangelogAvailable: boolean;
  isRelease: boolean;
  version: string;
}

export interface IChangeHistoryUser {
  fullName: string;
  userId: string;
}

export enum HistoryDisplayModeEnum {
  APP_CLIENT_HISTORY = "APP_CLIENT_HISTORY",
  TOPIC_HISTORY = "TOPIC_HISTORY",
  CONFIG_VERSION_HISTORY = "CONFIG_VERSION_HISTORY",
  GLOBAL_HISTORY = "GLOBAL_HISTORY",
  AIRLINE_HISTORY = "AIRLINE_HISTORY",
  AIRCRAFT_HISTORY = "AIRCRAFT_HISTORY",
  AIRCRAFTGROUP_HISTORY = "AIRCRAFTGROUP_HISTORY",
}

export enum HistoryChangeTypeEnum {
  APP_CLIENT_CREATED = "app_client_created",
  APP_CLIENT_MODIFIED = "app_client_modified",
  APP_CLIENT_DELETED = "app_client_deleted",

  TOPIC_CREATED = "topic_created",
  TOPIC_MODIFIED = "topic_modified",
  TOPIC_DELETED = "topic_deleted",
  TOPIC_STATUS_CHANGE_REQUESTED = "topic_status_change_requested",
  TOPIC_STATUS_CHANGE_FINISHED = "topic_status_change_finished",

  PUBLISHER_CREATED = "publisher_created",
  PUBLISHER_DELETED = "publisher_deleted",
  SUBSCRIBER_CREATED = "subscriber_created",
  SUBSCRIBER_DELETED = "subscriber_deleted",

  CONFIG_VERSION_CREATED = "config_version_created",

  TENANT_CREATED = "tenant_created",
  AIRCRAFT_CREATED = "aircraft_created",
  AIRCRAFT_MODIFIED = "aircraft_modified",
  AIRCRAFT_GROUP_CREATED = "aircraft_group_created",
  AIRCRAFT_GROUP_MODIFIED = "aircraft_group_modified",

  /**
   * ground config version deployed
   */
  GROUND_CONFIG_VERSION_DEPLOYED = "ground_config_version_deployed",

  /**
   * app client destination created
   */
  APP_CLIENT_DESTINATION_CREATED = "app_client_destination_created",

  /**
   * app client destination modified
   */
  APP_CLIENT_DESTINATION_MODIFIED = "app_client_destination_modified",

  /**
   * App client destination route added
   */
  APP_CLIENT_DESTINATION_ROUTE_ADDED = "app_client_destination_route_added",

  /**
   * App client destination route deleted
   */
  APP_CLIENT_DESTINATION_ROUTE_DELETED = "app_client_destination_route_deleted",

  /**
   * User modified
   */
  USER_CREATED = "user_created",
  /**
   * User modified
   */
  USER_MODIFIED = "user_modified",
  /**
   * User role change requested
   */
  USER_ROLE_CHANGE_REQUESTED = "user_role_change_requested",
  /**
   * User role change finished
   */
  USER_ROLE_CHANGE_FINISHED = "user_role_change_finished",
}

export enum HistoryItemFieldEnum {
  APP_NAME = "appName",
  APP_VERSION = "appVersion",
  APP_OWNER = "appOwner",
  APP_OWNER_ADDED = "appOwnerAdded",
  APP_OWNER_REMOVED = "appOwnerRemoved",

  USER_NAME = "userName",
  PASSWORD = "password",
  STATUS = "status",
  NUM_CONN_RETRIES = "numConnRetries",
  CONN_RETRY_DELAY_SECS = "connRetryDelaySec",
  // INTERNAL = "internal",
  VISIBILITY = "visibility",
  IS_VISIBLE_FOR_PAC = "isVisibleForPac",
  VISIBILITY_USER_ADDED = "visibilityUserAdded",
  VISIBILITY_USER_REMOVED = "visibilityUserRemoved",
  // GROUND_DESTINATION_TYPE = "groundDestinationType",
  // GROUND_DESTINATION_ENDPOINT = "groundDestinationEndpoint",
  // INT_GROUND_DESTINATION_TYPE = "intGroundDestinationType",
  // INT_GROUND_DESTINATION_ENDPOINT = "intGroundDestinationEndpoint",
  /**
   * App client destination
   */
  DESTINATION_TYPE = "destinationType",
  DESTINATION_ENDPOINT = "destinationEndpoint",

  /**
   * Topic fields
   */
  TOPIC_NAME = "topicName",
  APP_CLIENT = "appClient",
  DESCRIPTION = "description",
  IS_SECURE = "isSecure",
  ACCESS_ANONYMOUS = "accessAnonymous",
  PAYLOAD = "payload",
  QOS_LEVEL = "qosLevel",
  TTL = "ttl",
  OFFLOAD_TYPE = "offloadType",
  PRIORITY = "priority",
  MULTICAST = "multicast",
  MAX_PAYLOAD_SIZE = "maxPayloadSize",
  THROTTLE_THRESHOLD_FACTOR = "throttleThresholdFactor",
  DEFAULT_RETAIN_REQUIRED = "defaultRetainRequired",
  LOCAL_GROUP_BRIDGE = "localGroupBridge",

  /**
   * Topic status change
   */
  TOPIC_STATUS_CHANGE_REQUESTED_STATUS = "requestedStatus",
  TOPIC_STATUS_CHANGE_FINISHED_ACTION = "finishedAction",

  TOPIC_SUB_PERMISSION = "topicSubscribePermission",
  TOPIC_IS_SUBSCRIBABLE_FOR_PAC = "topicIsSubscribableForPac",
  TOPIC_SUB_PERMISSION_APP_ADDED = "topicSubscribePermissionAppClientAdded",
  TOPIC_SUB_PERMISSION_APP_REMOVED = "topicSubscribePermissionAppClientRemoved",
  TOPIC_PUB_PERMISSION = "topicPublishPermission",
  TOPIC_IS_PUBLISHABLE_FOR_PAC = "topicIsPublishableForPac",
  TOPIC_PUB_PERMISSION_APP_ADDED = "topicPublishPermissionAppClientAdded",
  TOPIC_PUB_PERMISSION_APP_REMOVED = "topicPublishPermissionAppClientRemoved",

  RETAIN_REQUIRED = "retainRequired",
  LOCAL_GROUP_DATA = "localGroupData",
  NEED_BRIDGE = "needBridge",

  NONE = "none",

  /**
   * Aircraft and Aircraft Group
   */
  AIRCRAFT_ADDED_TO_GROUP = "aircraft_added_to_group",
  AIRCRAFT_REMOVED_FROM_GROUP = "aircraft_removed_from_group",

  /**
   * Aircraft provision update
   */
  AIRCRAFT_PROVISION_STATUS = "aircraft_provision_status",
  AIRCRAFT_ACTIVE_STATE = "aircraft_active_state",
  AIRCRAFT_IOT_NAME = "aircraft_iot_name",
  AIRCRAFT_IOT_ARN = "aircraft_iot_arn",
  AIRCRAFT_IOT_CERTIFICATE_INFO = "aircraft_iot_certificate_info",

  /**
   * User fields
   */
  USER_ROLE = "user_role",

  USER_TENANT = "user_tenant",

  /**
   * User role change request
   */
  USER_ROLE_CHANGE_REQUESTED_ROLE = "user_role_requested_role",
  USER_ROLE_CHANGE_FINISHED_ACTION = "user_role_finished_action",
}

export interface IChangeHistoryInfo {
  [key: string]: any;
  aircraftType: string;
  appClient?: IChangeHistoryAppClient;
  brokerType?: IChangeHistoryBrokerType;
  changeType: HistoryChangeTypeEnum;
  className?: string;
  columns?: string;
  comment?: string;
  configVersion?: IChangeHistoryConfigVersion;
  displayMode: HistoryDisplayModeEnum;
  items?: IChangeHistoryItem[];
  modifiedAt: string;
  modifiedBy: IChangeHistoryUser;
  tenant?: IChangeHistoryTenant;
  topic?: IChangeHistoryTopic;
  topicStatus?: string;
}

export interface IChangeHistoryItem {
  [key: string]: any;
  appClientAfter?: IChangeHistoryAppClient;
  appClientBefore?: IChangeHistoryAppClient;
  className?: string;
  itemField: string;
  topicPayloadDetailAfter?: IChangeHistoryTopicPayloadDetail;
  topicPayloadDetailBefore?: IChangeHistoryTopicPayloadDetail;
  userAfter?: IChangeHistoryUser;
  userBefore?: IChangeHistoryUser;
  valueAfter?: string;
  valueBefore?: string;
}
