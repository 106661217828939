import deepEqual from "deep-equal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { configBrokerFilterSortApplyAction } from "../../../actions/configfile/broker-filter-sort/actions";
import { getConfigBroker } from "../../../actions/configfile/broker/actions";
import { getConfigVersion } from "../../../actions/configversion/get/actions";
import { editModalOpenAction } from "../../../actions/modal/edit/actions";
import { getFormattedDate } from "../../../helper/util";
import { modalTypeEnum } from "../../../models/IEditModalConfig";
import { FilterType, IFilterConfig } from "../../../models/IFilterConfig";
import {
  ITableConfig,
  ITableHeaderCells,
  ITableRow,
  OrderType,
} from "../../../models/ITableConfig";
import { configFileBrokerFilterSortEmptyFilter as emptyFilterObject } from "../../../reducers/configfile/ConfigFileBrokerFilterSortReducer";
import { ConfigService } from "../../../services/ConfigService";
import { RootState } from "../../../store";
import { SActiveLink, SSpacer } from "../../../styles/styles";
import { Filter } from "../../Filter";
import { ModalDisplayConfigVersion } from "../../modals/ModalDisplayConfigVersion";
import { Table } from "../../Table";

export const ConfigurationBrokerList = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const { configFileGenerate } = useSelector((state: RootState) => ({
    configFileGenerate: state.configFileGenerate,
  }));
  const { config } = useSelector((state: RootState) => ({
    config: state.configFileBrokerFilterSort,
  }));

  const [brokerTypes, setBrokerTypes]: any[] = useState([{}]);

  const { allBrokerType, configFileBroker, editModal } = useSelector((state: RootState) => ({
    allBrokerType: state.allBrokerType,
    configFileBroker: state.configFileBroker,
    editModal: state.editModal,
  }));

  // const emptyFilterObject = {
  //   brokerType: "",
  //   createdDate: { startDate: undefined, endDate: undefined },
  //   fileName: "",
  //   fileVersion: "",
  // };

  // const [config, setConfig] = useState({
  //   filter: { ...emptyFilterObject },
  //   limit: 10,
  //   orderBy: "createdAt",
  //   orderType: OrderType.DESC,
  //   page: 0,
  // });

  const setConfig = (newConfig: any) => {
    dispatch(configBrokerFilterSortApplyAction(newConfig));
  };

  useEffect(() => {
    if (!configFileGenerate.loading && !configFileGenerate.error) {
      dispatch(getConfigBroker(config));
    }
  }, [configFileGenerate]);

  useEffect(() => {
    dispatch(getConfigBroker(config));
  }, [config]);

  const handleChangePage = (page: number): void => {
    setConfig({ ...config, page });
  };

  useEffect(() => {
    setBrokerTypes(
      allBrokerType.content.map((broker: any) => ({
        label: <span className={`ta-dropdown-${broker.brokerType}`}>{broker.brokerType}</span>,
        value: broker.brokerType,
      }))
    );
  }, [allBrokerType]);

  const handleShowConfigVersionDetail = (configVersionId: string) => {
    if (configVersionId) {
      dispatch(
        getConfigVersion(configVersionId, (configVersion: any) => {
          dispatch(
            editModalOpenAction({
              configVersion,
              type: modalTypeEnum.DISPLAY_CONFIG_VERSION,
            })
          );
        })
      );
    }
  };

  const headCells: ITableHeaderCells[] = [
    {
      id: "fileName",
      label: "File name",
      value: (row: ITableRow) => (
        <SActiveLink onClick={() => ConfigService.s3ProxyUrl(row.fileId)}>
          {row.fileName}
        </SActiveLink>
      ),
    },
    {
      id: "secretFileName",
      label: "Broker secrets",
      value: (row: ITableRow) => (
        <SActiveLink onClick={() => ConfigService.s3ProxyUrl(row.secretFileId)}>
          {row.secretFileName}
        </SActiveLink>
      ),
    },
    {
      id: "fileVersion",
      label: "File version",
      value: (row: ITableRow) => (
        <SActiveLink
          title="Click to view version detail"
          onClick={() => {
            handleShowConfigVersionDetail(row.configVersionId);
          }}
        >
          {row.fileVersion}
        </SActiveLink>
      ),
    },
    { id: "brokerType", label: "Broker type", width: "2fr" },
    {
      id: "createdAt",
      label: "Created at",
      value: (row: ITableRow) => getFormattedDate(row.createdAt),
    },
  ];

  const filterConfig: IFilterConfig = {
    emptyFilter: { ...emptyFilterObject },
    initFilter: { ...config.filter },
    items: [
      {
        name: "fileName",
        placeholder: "File name",
        taClass: "fileName",
      },
      {
        name: "fileVersion",
        placeholder: "File version",
        taClass: "fileVersion",
      },
      {
        data: brokerTypes,
        name: "brokerType",
        placeholder: "Broker type",
        taClass: "brokerType",
        type: FilterType.DROPDOWN,
      },
      {
        name: "createdDate",
        placeholder: "Created",
        taClass: "createdDate",
        type: FilterType.DATEPICKER,
      },
    ],
    pageName: "configurationBrokerList",
    returnFilter: (filter: any) => {
      if (!deepEqual(config.filter, filter)) {
        setConfig({ ...config, page: 0, filter: { ...filter } });
      }
    },
  };

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    head: {
      cells: headCells,
    },
    list: {
      ...configFileBroker,
    },
    name: "broker-config-file",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      <SSpacer />
      {/* FILTER */}
      <Filter {...filterConfig} />

      <SSpacer />

      {/* TABLE */}
      <Table {...tableProps}></Table>

      {editModal.open && editModal.type === modalTypeEnum.DISPLAY_CONFIG_VERSION && (
        <ModalDisplayConfigVersion />
      )}
    </>
  );
};
