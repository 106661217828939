import { ButtonPrimary, ProgressIndicatorCircular, Text } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThemeProvider } from "styled-components";

import { createAircraftGroup } from "../../actions/aircraftgroup/create/actions";
import { AircraftGroupTypeEnum } from "../../models/IAircraftGroup";
import { RootState } from "../../store";
import { SFlexContainer, SFlexItem } from "../../styles/styles";
import { AircraftGroupValidationErrorCodeEnum } from "../AircraftGroupValidatorUtil";

import { ModalCommentInputPage } from "./ModalCommentInputPage";
import { ModalCreateAircraftGroupPage0 } from "./ModalCreateAircraftGroupPage0";
import { ModalCreateBase } from "./ModalCreateBase";

export const ModalCreateAircraftGroup = () => {
  const history = useHistory();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { currentUser, editModal } = useSelector((state: any) => ({
    currentUser: state.currentUser,
    editModal: state.editModal,
  }));

  const changeCommentRequired = true; // airline create does require comment
  const [validPage, setValidPage] = useState([false, !changeCommentRequired]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState("New aircraft group");

  const [config, setConfig] = useState({
    aircraftGroupEnvironment: "",
    aircraftGroupName: "",
    aircraftGroupType: "",
    changeComment: "",
    description: "",
    tenantId: "",
  });

  const [aircraftGroupNameValidCode, setAircraftGroupNameValidCode] = useState(
    AircraftGroupValidationErrorCodeEnum.NO_ERROR
  );

  // useEffect(() => {
  //   setConfig({
  //     ...config,
  //     //   fullName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
  //     //   internal: currentUser.data.internal,
  //     //   user: {
  //     //     email: currentUser.data.email,
  //     //     firstName: currentUser.data.firstName,
  //     //     lastName: currentUser.data.lastName,
  //     //     role: currentUser.data.role,
  //     //     userId: currentUser.data.userId,
  //     //   },
  //   });
  // }, [currentUser]);

  useEffect(() => {
    setConfig({
      ...config,
      aircraftGroupEnvironment: editModal.groupEnv,
      aircraftGroupType: editModal.groupType,
      tenantId:
        editModal.groupType === AircraftGroupTypeEnum.TENANT && editModal.tenant
          ? editModal.tenant.tenantId
          : "",
    });

    if (editModal.groupType === AircraftGroupTypeEnum.GLOBAL) {
      setDialogTitle("New global aircraft group");
    }
  }, [editModal]);

  const handleSubmit = () => {
    dispatch(
      createAircraftGroup({ ...config }, (acGroupId: any) => {
        history.push(`/aircraftGroups/${acGroupId}`);
      })
    );
  };

  const handleSetValidPage = (index: number, value: boolean) => {
    const updatedPage = [...validPage];
    updatedPage[Number(index)] = value;
    setValidPage(updatedPage);
  };

  const checkFormFill = () => {
    handleSetValidPage(
      0,
      aircraftGroupNameValidCode === AircraftGroupValidationErrorCodeEnum.NO_ERROR
    );
  };

  const checkCommentInput = () => {
    const isCommentValid = !changeCommentRequired || !!config.changeComment;

    handleSetValidPage(1, isCommentValid);
  };

  const handlePageChange = (updatedConfig: any) => {
    setConfig(updatedConfig);
  };

  useEffect(() => {
    if (currentPage === 0) {
      checkFormFill();
    } else if (currentPage === 1) {
      checkCommentInput();
    }
  }, [config, aircraftGroupNameValidCode]);

  // useEffect(() => {
  //   if (currentPage === 0) {
  //     setDialogTitle("New aircraft / rack");
  //   } else {
  //     const provisionType =
  //       config.provisionEnv === "production" ? "aircraft" : "rack";
  //     setDialogTitle(`New ${provisionType}`);
  //   }
  // }, [currentPage]);

  const modalTheme = {
    modal: {
      width: "650px", // Increase the width of the modal dialog since we increased password label
    },
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <ModalCreateBase title={dialogTitle}>
        {currentPage === 0 && (
          <ModalCreateAircraftGroupPage0
            config={{ ...config }}
            groupEnv={editModal.groupEnv}
            groupType={editModal.groupType}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            tenant={editModal.tenant}
            setAircraftGroupNameValidCode={setAircraftGroupNameValidCode}
            aircraftGroupNameValidCode={aircraftGroupNameValidCode}
          />
        )}

        {currentPage === 1 && (
          <ModalCommentInputPage
            config={{ ...config }}
            onChange={(updatedConfig: any) => {
              handlePageChange(updatedConfig);
            }}
            commentHint={`Please enter a comment about why you are creating this aircraft group.`}
            commentRequired={changeCommentRequired}
          />
        )}

        <SFlexContainer justifyContent="space-between">
          <SFlexContainer justifyContent="flex-start">
            {currentPage > 0 && (
              <ButtonPrimary
                className="ta-modal-previous-button"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
                disabled={currentPage < 1}
              >
                Previous
              </ButtonPrimary>
            )}
          </SFlexContainer>
          <SFlexContainer justifyContent="flex-end">
            {currentPage === 0 ? (
              <ButtonPrimary
                className="ta-modal-next-button"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
                disabled={!validPage[Number(currentPage)]}
              >
                Next
              </ButtonPrimary>
            ) : currentPage === 1 ? (
              <ButtonPrimary
                onClick={handleSubmit}
                disabled={!validPage[Number(currentPage)]}
                className="ta-modal-submit-button"
              >
                Save
              </ButtonPrimary>
            ) : (
              ""
            )}
          </SFlexContainer>
        </SFlexContainer>
      </ModalCreateBase>
    </ThemeProvider>
  );
};
