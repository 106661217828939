import { OrderType } from "./ITableConfig";

/**
 * User Tenants
 */
export enum userTenantEnum {
  /**
   * Panasonic Avionic
   */
  PAC = "iNYhQ8QHJ9PrQjDmmVBAZv",
}

/**
 * Tenant status
 */
export enum TenantStatusEnum {
  /**
   * ACTIVE
   */
  ACTIVE = "ACTIVE",

  /**
   * DELETED
   */
  DELETED = "DELETED",
}

/**
 * Tenant types
 */
export enum TenantTypeEnum {
  /**
   * Airline
   */
  AIRLINE = "airline",

  /**
   * Airline group
   */
  AIRLINE_GROUP = "airline group",

  /**
   * Content distributor
   */
  CONTENT_DISTRIBUTOR = "content distributor",

  /**
   * Contractor
   */
  CONTRACTOR = "contractor",

  /**
   * Non-civilian
   */
  NON_CIVILIAN = "non civilian",

  /**
   * Partner
   */
  PARTNER = "partner",

  /**
   * Provider
   */
  PROVIDER = "provider",
}

export interface ITenant {
  created_at?: string;
  created_by?: string;
  description?: string;
  icao?: string;
  iotThingGroupName?: string;
  modified_at?: string;
  modified_by?: string;
  status?: TenantStatusEnum;
  tenantId?: string;
  tenantName?: string;
  tenantType?: string;
}

interface ITenantListFilters extends ITenant {
  [key: string]: any;
}

export interface ITenantListTableConfig {
  [key: string]: any;
  filter: ITenantListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IGetTenantListData {
  content: any[];
  count: number;
}
