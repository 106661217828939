import { Dispatch } from "redux";

import { MQTTService } from "../../../services/MQTTService";
import { editModalCloseAction } from "../../modal/edit/actions";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  CreateSubscriberTypes,
  ICreateSubscriberErrorAction,
  ICreateSubscriberRequestAction,
  ICreateSubscriberSuccessAction,
  TCreateSubscriberActions,
} from "./types";

export const CreateSubscriberRequestAction = (data: any): ICreateSubscriberRequestAction => ({
  payload: data,
  type: CreateSubscriberTypes.REQUEST,
});
export const CreateSubscriberSuccessAction = (data: any): ICreateSubscriberSuccessAction => ({
  payload: data,
  type: CreateSubscriberTypes.SUCCESS,
});
export const CreateSubscriberErrorAction = (error: any): ICreateSubscriberErrorAction => ({
  payload: error,
  type: CreateSubscriberTypes.ERROR,
});

export const createSubscriber = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TCreateSubscriberActions | TEditModalActions>
) => {
  dispatch(CreateSubscriberRequestAction(config));
  MQTTService.createSubscriber(config).then(
    (data: { config: any; data: any }) => {
      // tslint:disable-next-line: ban-ts-ignore
      // @ts-ignore
      dispatch(CreateSubscriberSuccessAction(data.data));
      dispatch(
        popupOpenAction({
          content: "Subscriber created successfully!",
          title: "Success",
          type: "Info",
        })
      );
      dispatch(editModalCloseAction());
      cb();
    },
    (error: any) => {
      dispatch(CreateSubscriberErrorAction(error));
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
