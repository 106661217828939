import { OrderType } from "./ITableConfig";

/**
 * Ground environment types
 */
export enum GroundEnvironmentTypeEnum {
  /**
   * Production
   */
  PRODUCTION = "prod",

  /**
   * Integration
   */
  INTEGRATION = "int",
}

export enum GroundDestinationTypeEnum {
  /**
   * AWS SQS url
   */
  AWS_SQS_URL = "AWS SQS URL",
}

export enum tabDestinationDetailsEnum {
  SOURCE_GROUPS,
  DETAILS,
  HISTORY,
}

export const newDestinationId = "newdestid";

export interface IDestinationDetailsData {
  appClientDestinationId?: string;
  created_at?: string;
  created_by?: string;
  destinationEndpoint?: string;
  destinationEnvironment?: string;
  destinationType?: string;
  index?: number;
  modified_at?: string;
  modified_by?: string;
}

interface IDestinationListFilters extends IDestinationDetailsData {
  [key: string]: any;
}

export interface IDestinationListTableConfig {
  [key: string]: any;
  filter: IDestinationListFilters;
  limit: number;
  orderBy: string;
  orderType: OrderType;
  page: number;
}

export interface IDestinationListData {
  content: any[];
  count: number;
}
