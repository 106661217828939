import { Dispatch } from "redux";

import { AircraftService } from "../../../services/AircraftService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

import {
  IRegenerateCertErrorAction,
  IRegenerateCertRequestAction,
  IRegenerateCertSuccessAction,
  RegenerateCertTypes,
  TRegenerateCertActions,
} from "./types";

// Action creators
export const RegenerateCertRequestAction = (aircraftId: string): IRegenerateCertRequestAction => ({
  payload: aircraftId,
  type: RegenerateCertTypes.REQUEST,
});

export const RegenerateCertSuccessAction = (): IRegenerateCertSuccessAction => ({
  payload: {},
  type: RegenerateCertTypes.SUCCESS,
});

export const RegenerateCertErrorAction = (error: any): IRegenerateCertErrorAction => ({
  payload: error,
  type: RegenerateCertTypes.ERROR,
});

export const regenerateRackCertLoadable =
  (config: { aircraftId: string; changeComment: string }, cb?: () => void) =>
  (dispatch: Dispatch<TPopupActions | TRegenerateCertActions | TEditModalActions>) => {
    dispatch(RegenerateCertRequestAction(config.aircraftId));
    AircraftService.reGenerateRackCertLoadable(config.aircraftId).then(
      () => {
        dispatch(RegenerateCertSuccessAction());
        dispatch(
          popupOpenAction({
            content: "Cert loadable pipeline triggered successfully!",
            title: "Success",
            type: "Info",
          })
        );
        if (cb) {
          cb();
        }
      },
      (error: any) => {
        dispatch(RegenerateCertErrorAction(error));
        dispatch(
          popupOpenAction({
            content:
              (error && error.message) ||
              "An error occurred while re-generating the cert loadable.",
            title: "Error",
            type: "Info",
          })
        );
      }
    );
  };
