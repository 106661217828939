import { Dispatch } from "redux";

import {
  IApplicationListingTableConfig,
  IGetApplicationListData,
} from "../../../models/IAppListingTableConfig";
import { IUserListingTableConfig } from "../../../models/IUserListingTableConfig";
import { ApplicationService } from "../../../services/ApplicationService";

import { AppVisibilityUsersType, TGetAppVisibilityUsersActions } from "./types";

export const appVisibilityUsersResetAction = (): TGetAppVisibilityUsersActions => ({
  type: AppVisibilityUsersType.RESET,
});

export const appVisibilityUsersRequestAction = (): TGetAppVisibilityUsersActions => ({
  type: AppVisibilityUsersType.REQUEST,
});

export const appVisibilityUsersSuccessAction = (data: any): TGetAppVisibilityUsersActions => ({
  payload: data,
  type: AppVisibilityUsersType.SUCCESS,
});

export const appVisibilityUsersErrorAction = (error: string): TGetAppVisibilityUsersActions => ({
  payload: error,
  type: AppVisibilityUsersType.ERROR,
});

export const getAppVisibilityUsers = (appId: string, conf: IUserListingTableConfig) => (
  dispatch: Dispatch<TGetAppVisibilityUsersActions>
) => {
  dispatch(appVisibilityUsersRequestAction());
  ApplicationService.getAppVisibilityUsers(appId, conf).then(
    (response: { data: { data: IGetApplicationListData } }) => {
      dispatch(appVisibilityUsersSuccessAction(response.data.data));
    },
    (error: any) => dispatch(appVisibilityUsersErrorAction(error))
  );
};
