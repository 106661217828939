import styled, { css } from "styled-components";

interface ISButtonProps {
  isPrimary?: boolean;
}

export const SButton = styled.button<ISButtonProps>`
  outline: 0;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  height: 40px;
  background-color: #ededed;
  border: none;
  box-shadow: inset 0 0 0 2px #ededed;
  font-weight: 400;
  line-height: 24px;
  padding: 0 32px;
  tab-size: 4;
  text-transform: none;
  word-break: break-word;
  margin-right: 1px;
  box-sizing: border-box;

  &:hover {
    background-color: #e0e0e0;
    box-shadow: inset 0 0 0 2px #e0e0e0;
  }

  &:focus {
    background-color: #4c98da;
    box-shadow: inset 0 0 0 2px #184a75;
  }

  &:active {
    background-color: #184a75;
  }

  ${(props) =>
    props.isPrimary &&
    css`
      background-color: #2a7fc9;
      box-shadow: inset 0 0 0 2px #2a7fc9;
      font-weight: 600;
      color: #ffffff;
      &:hover {
        background-color: #2a7fc9;
        box-shadow: inset 0 0 0 2px #2a7fc9;
      }
    `}
`;
