// import { UMBT_IT_SUPPORT_TEAM, UMBT_OPERATIONAL_SUPPORT_TEAM } from "../models/UserTypes";

export const getRedirectUrl = (currentUser: any): string => {
  if (!currentUser.data || !currentUser.data.role) {
    return "/error";
  }

  switch (currentUser.data.role) {
    // case UMBT_IT_SUPPORT_TEAM:
    //   return "/users";
    // case UMBT_OPERATIONAL_SUPPORT_TEAM:
    //   return "/configurationFiles";
    default:
      return "/applicationClients";
  }
};
