import { ADMINISTRATOR, APPLICATION_OWNER, APPROVER } from "../models/UserTypes";

/**
 * User role - permissions
 */
/* tslint:disable */
/* tslint:disable: object-literal-sort-keys */
// tslint:disable-next-line:no-unnecessary-class
class UserRolePermissionsUtil {
  /**
   * groundside configuration - view
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_View(user: any): boolean {
    return user.role === APPLICATION_OWNER || user.role === APPROVER || user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - provision airlines
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ProvisionAirlines(user: any): boolean {
    return user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - provision racks
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ProvisionRacks(user: any): boolean {
    return user.role === APPLICATION_OWNER || user.role === APPROVER || user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - manage integration groups
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ManageIntGroups(user: any): boolean {
    return user.role === APPLICATION_OWNER || user.role === APPROVER || user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - provision aircrafts
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ProvisionAircrafts(user: any): boolean {
    // return user.role === APPROVER || user.role === ADMINISTRATOR;
    // with auto provisioning implemented, only admin can manually provision aircraft now
    return user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - manage production groups
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ManagePrdGroups(user: any): boolean {
    return user.role === APPROVER || user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - manage global integration groups
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ManageGlobalIntGroups(user: any): boolean {
    // return user.role === APPROVER || user.role === ADMINISTRATOR;
    return user.role === ADMINISTRATOR; // limit global group access to admin only for now
  }

  /**
   * groundside configuration - manage global production groups
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_ManageGlobalPrdGroups(user: any): boolean {
    // return user.role === APPROVER || user.role === ADMINISTRATOR;
    return user.role === ADMINISTRATOR; // limit global group access to admin only for now
  }

  /**
   * groundside configuration - deploy integration configurations
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_DeployIntConfig(user: any): boolean {
    return user.role === APPLICATION_OWNER || user.role === APPROVER || user.role === ADMINISTRATOR;
  }

  /**
   * groundside configuration - deploy production configurations
   * @param user user
   * @returns boolean
   */
  public static hasGroundsideConfig_DeployPrdConfig(user: any): boolean {
    return user.role === ADMINISTRATOR;
  }
}
/* tslint:enable: object-literal-sort-keys */
/* tslint:enable */

export { UserRolePermissionsUtil };
