import { Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getFormattedDate } from "../../helper/util";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalDisplayConfigVersion = (props: any) => {
  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const [configVersion, setConfigVersion] = useState({
    comment: "",
    createdAt: "",
    createdBy: {
      fullName: "",
    },
    title: "",
    version: "",
  });

  useEffect(() => {
    const modelConfigVersion = editModal.configVersion;
    setConfigVersion({
      comment: modelConfigVersion.comment,
      createdAt: modelConfigVersion.createdAt,
      createdBy: modelConfigVersion.createdBy,
      title: !!modelConfigVersion.title ? modelConfigVersion.title : "Configuration Version Detail",
      version: modelConfigVersion.version,
    });
  }, [editModal]);

  return (
    <ModalCreateBase title={configVersion.title}>
      <Text>
        Version: <span>{configVersion.version}</span>
      </Text>

      <Text>
        Created by: <span>{configVersion.createdBy && configVersion.createdBy.fullName}</span>
      </Text>

      <Text>
        Created at: <span>{getFormattedDate(configVersion.createdAt)}</span>
      </Text>

      <Textarea placeholder="Comment" value={configVersion.comment || ""} readonly="readonly" />
    </ModalCreateBase>
  );
};
