import { Dispatch } from "redux";

import { IFilterSortData } from "../../../models/IFilterSort";

import { ChangeHistoryFilterSortType, TChangeHistoryFilterSortActions } from "./types";

export const changeHistoryFilterSortClearAction = (): TChangeHistoryFilterSortActions => ({
  type: ChangeHistoryFilterSortType.CLEAR,
});

export const changeHistoryFilterSortResetAction = (): TChangeHistoryFilterSortActions => ({
  type: ChangeHistoryFilterSortType.RESET,
});

export const changeHistoryFilterSortApplyAction = (data: any): TChangeHistoryFilterSortActions => ({
  payload: data,
  type: ChangeHistoryFilterSortType.APPLY,
});
