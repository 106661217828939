/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";
import { IGetTenantListData } from "../../../models/ITenant";

export enum TenantListType {
  RESET = "GET_TENANT_LIST_RESET",
  REQUEST = "GET_TENANT_LIST_REQUEST",
  SUCCESS = "GET_TENANT_LIST_SUCCESS",
  ERROR = "GET_TENANT_LIST_ERROR",
}

export interface ITenantListResetAction extends IReduxBaseAction<TenantListType> {
  type: TenantListType.RESET;
}

export interface ITenantListRequestAction extends IReduxBaseAction<TenantListType> {
  type: TenantListType.REQUEST;
}

export interface ITenantListSuccessAction extends IReduxBaseAction<TenantListType> {
  payload: IGetTenantListData;
  type: TenantListType.SUCCESS;
}

export interface ITenantListErrorAction extends IReduxBaseAction<TenantListType> {
  payload: string;
  type: TenantListType.ERROR;
}

export type TTenantListActions =
  | ITenantListResetAction
  | ITenantListRequestAction
  | ITenantListSuccessAction
  | ITenantListErrorAction;
