import { Dispatch } from "redux";

import { IApplicationListingTableConfig } from "../../../models/IAppListingTableConfig";
import {
  ITopicPermissionAppsListData,
  topicPermissionTypeEnum,
} from "../../../models/ITopicDetailsConfig";
import { TopicService } from "../../../services/TopicService";

import { TGetTopicPermissionAppsActions, TopicPermissionAppsType } from "./types";

export const topicPubPermissionAppsResetAction = (): TGetTopicPermissionAppsActions => ({
  type: TopicPermissionAppsType.RESET_PUB,
});

export const topicPubPermissionAppsRequestAction = (): TGetTopicPermissionAppsActions => ({
  type: TopicPermissionAppsType.REQUEST_PUB,
});

export const topicPubPermissionAppsSuccessAction = (data: any): TGetTopicPermissionAppsActions => ({
  payload: data,
  type: TopicPermissionAppsType.SUCCESS_PUB,
});

export const topicPubPermissionAppsErrorAction = (
  error: string
): TGetTopicPermissionAppsActions => ({
  payload: error,
  type: TopicPermissionAppsType.ERROR_PUB,
});

export const topicSubPermissionAppsResetAction = (): TGetTopicPermissionAppsActions => ({
  type: TopicPermissionAppsType.RESET_SUB,
});

export const topicSubPermissionAppsRequestAction = (): TGetTopicPermissionAppsActions => ({
  type: TopicPermissionAppsType.REQUEST_SUB,
});

export const topicSubPermissionAppsSuccessAction = (data: any): TGetTopicPermissionAppsActions => ({
  payload: data,
  type: TopicPermissionAppsType.SUCCESS_SUB,
});

export const topicSubPermissionAppsErrorAction = (
  error: string
): TGetTopicPermissionAppsActions => ({
  payload: error,
  type: TopicPermissionAppsType.ERROR_SUB,
});

export const getTopicPermissionApps = (
  appId: string,
  conf: IApplicationListingTableConfig,
  permType: topicPermissionTypeEnum
) => (dispatch: Dispatch<TGetTopicPermissionAppsActions>) => {
  dispatch(
    permType === topicPermissionTypeEnum.PUBLISH
      ? topicPubPermissionAppsRequestAction()
      : topicSubPermissionAppsRequestAction()
  );
  TopicService.getTopicPermissionApps(appId, conf, permType).then(
    (response: { data: { data: ITopicPermissionAppsListData } }) => {
      dispatch(
        permType === topicPermissionTypeEnum.PUBLISH
          ? topicPubPermissionAppsSuccessAction(response.data.data)
          : topicSubPermissionAppsSuccessAction(response.data.data)
      );
    },
    (error: any) =>
      dispatch(
        permType === topicPermissionTypeEnum.PUBLISH
          ? topicPubPermissionAppsErrorAction(error)
          : topicSubPermissionAppsErrorAction(error)
      )
  );
};
