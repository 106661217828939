/* tslint:disable:no-null-undefined-union */
import { colors } from "next-components";
import styled, { css } from "styled-components";

import SideBarItem from "./SideBarItem";
import SideBarItemNode from "./SideBarItemNode";

const EllipsizedContent = styled.div<{ isLarge?: boolean; isVisible?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Container = styled.div<{ isLarge: boolean }>`
  overflow: auto;
  height: 100vh;

  ${(props) =>
    props.isLarge
      ? css`
          width: 312px;
        `
      : css`
          width: 64px;
        `}

  background: linear-gradient(
    0deg,
    ${colors.primary.darken30} 0%,
    ${colors.primary.darken20} 100%
  );

  /*
  * Styling of custom scrollbar.
  * Using library:
  * https://github.com/Grsmto/simplebar
  */
  .simplebar-content {
    position: relative;
    display: grid;
    grid-template-rows: 0 auto 1fr auto 0;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
  }

  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    background: rgba(255, 255, 255, 0.2);
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  color: ${colors.general.white};
`;

export const ToolsContainer = styled.div`
  margin-top: 4px;
`;

export const SideBarItems = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-content: start;
  margin-top: 36px;
  margin-bottom: 9px;
`;

export const ToolsHeading = styled(EllipsizedContent)`
  text-transform: uppercase;
  color: white;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  visibility: hidden;
  margin-bottom: 9px;
  margin-left: 16px;
  margin-right: 16px;

  ${(props) =>
    props.isVisible &&
    css`
      visibility: visible;
    `};
`;

export const SideBarUserItem = styled(SideBarItem)`
  font-size: 14px !important;
  height: 40px !important;
`;
export const SideBarUserItemNode = styled(SideBarItemNode)`
  font-size: 14px !important;
  height: 40px !important;
`;

// ------- SIDEBAR CLOUD HOME ITEM -------
export const StyledSideBarItem = styled(SideBarItem)`
  height: fit-content;
  margin: 28px 0;

  ${(props) =>
    props.isLarge &&
    css`
      width: fit-content;
      margin-left: 16px;
    `};
`;

export const CloudLogoTitle = styled.div`
  color: ${colors.general.white};
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: bold;
`;

export const CloudLogoSubtitle = styled.div`
  color: ${colors.general.white};
  font-family: "Inter", sans-serif;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;
