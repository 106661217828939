export enum TA_TYPES {
  BUTTON = "button",
  DROPDOWN = "dropdown",
  ICON = "icon",
  INPUT = "input",
  MODAL = "modal",
  TABLE = "table",
  TITLE = "title",
  TEXT = "text",
  FILTER = "filter",
}

export const getTAClass = (
  page: string,
  type: TA_TYPES,
  container?: string,
  subcontainer?: string,
  specialization?: string,
  index?: string
) => {
  const classNameParts = [];
  classNameParts.push("ta");
  classNameParts.push(page);
  classNameParts.push(type);

  if (container) {
    classNameParts.push(container);
  }

  if (subcontainer) {
    classNameParts.push(subcontainer);
  }

  if (specialization) {
    classNameParts.push(specialization);
  }

  if (index) {
    classNameParts.push(index);
  }

  return classNameParts.join("-");
};
