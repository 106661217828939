import { IconInfo24 } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import {
  clearTopicBridgesAction,
  getTopicBridges,
} from "../../../actions/topic/get-bridges/actions";
import {
  getMqttClients,
  MQTTClientClearAction,
} from "../../../actions/topic/get-mqtt-clients/actions";
import { RootState } from "../../../store";
import { SInfoLabel, SInfoLabelContainer, STopologyContainer } from "../../../styles/styles";
import { TopologyGraphVis } from "../../TopologyGraphVis";

export const TopicBridgesGraph = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();
  const rawData: any[] = [];
  const [data, setData]: any[] = useState([]);

  const { topicBridges, topicMQTTClients } = useSelector((state: RootState) => ({
    topicBridges: state.topicBridges,
    topicMQTTClients: state.topicMQTTClients,
  }));

  const emptyFilterObject = {
    clientType: "SUBSCRIBER",
    topicId: props.data.topicId,
  };

  const [config] = useState({
    filter: { ...emptyFilterObject },
    limit: 25,
    orderBy: "from",
    orderType: "ASC",
    page: 0,
  });

  useEffect(
    () => () => {
      {
        dispatch(clearTopicBridgesAction());
        dispatch(MQTTClientClearAction());
      }
    },
    []
  );

  useEffect(() => {
    if (config.filter.topicId !== "") {
      dispatch(getTopicBridges(config));
    }
  }, [config]);

  useEffect(() => {
    if (!topicBridges.loading && !topicBridges.error) {
      // Get mqtt client
      dispatch(getMqttClients(config));
    }

    if (topicBridges.content.length) {
      topicBridges.content.forEach((row: any) => {
        if (
          !rawData.filter(
            (raw: any) =>
              raw.from === row.brokerTopology.parentBrokerType.brokerType &&
              raw.to === row.brokerTopology.remoteBrokerType.brokerType &&
              raw.label === row.direction
          ).length
        ) {
          rawData.push({
            from: row.brokerTopology.parentBrokerType.brokerType,
            label: row.direction,
            to: row.brokerTopology.remoteBrokerType.brokerType,
          });
        }
      });
      setData(rawData);
    }
  }, [topicBridges.content]);

  useEffect(() => {
    if (topicMQTTClients.content.length) {
      if (!topicBridges.content.length) {
        if (topicMQTTClients.content[0].needBridge === "1") {
          rawData.push({
            // from: topicMQTTClients.content[0].appClient.appBrokerType.brokerType,
            from: topicMQTTClients.content[0].brokerType.brokerType,
            label: "",
            // to: topicMQTTClients.content[0].appClient.appBrokerType.brokerType,
            to: topicMQTTClients.content[0].brokerType.brokerType,
          });
          setData(rawData);
        }
      }
    }
  }, [topicMQTTClients]);

  return (
    <STopologyContainer>
      {topicMQTTClients.content.length &&
      topicMQTTClients.content.filter((client: any) => client.needBridge === "0").length > 0 ? (
        <SInfoLabelContainer className="ta-mqtt-local-sub">
          <SInfoLabel>
            <IconInfo24 />
          </SInfoLabel>{" "}
          There is a local subscription
        </SInfoLabelContainer>
      ) : (
        ""
      )}
      {!topicBridges.loading && <TopologyGraphVis rawData={data} />}
    </STopologyContainer>
  );
};
