import { ProgressIndicatorLinear } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getChangeHistoryList } from "../actions/changehistory/get-list/actions";
import { HistoryDisplayModeEnum } from "../models/IChangeHistories";
import { ITableConfig, ITableHeaderCells, ITableRow, OrderType } from "../models/ITableConfig";
import { RootState } from "../store";
import { SOverlay } from "../styles/styles";

import { HistoryTable } from "./HistoryTable";

/* tslint:disable:cyclomatic-complexity */
export const ChangeHistoryList = (props: any) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { changeHistoryList } = useSelector((state: RootState) => ({
    changeHistoryList: state.changeHistoryList,
  }));

  const [config, setConfig] = useState({
    filter: {
      // for different displayMode: app client history / topic history / config version history...
      ...(props.displayMode === HistoryDisplayModeEnum.APP_CLIENT_HISTORY &&
        props.appClientId && { appClientId: props.appClientId || "" }),
      ...(props.displayMode === HistoryDisplayModeEnum.TOPIC_HISTORY &&
        props.topicId && { topicId: props.topicId || "" }),
      ...(props.displayMode === HistoryDisplayModeEnum.CONFIG_VERSION_HISTORY &&
        props.changeType && { changeType: props.changeType || "" }),
      ...(props.displayMode === HistoryDisplayModeEnum.AIRLINE_HISTORY &&
        props.tenantId && { tenantId: props.tenantId || "" }),
      // ...(props.displayMode === HistoryDisplayModeEnum.AIRCRAFT_HISTORY &&
      //   props.aircraftId && { aircraftId: props.aircraftId || "" }),
      ...(props.displayMode === HistoryDisplayModeEnum.AIRCRAFT_HISTORY &&
        props.tailNumber && { aircraft: props.tailNumber || "" }),
      // ...(props.displayMode === HistoryDisplayModeEnum.AIRCRAFTGROUP_HISTORY &&
      //   props.aircraftGroupId && { aircraftGroupId: props.aircraftGroupId || "" }),
      ...(props.displayMode === HistoryDisplayModeEnum.AIRCRAFTGROUP_HISTORY &&
        props.aircraftGroupName && { aircraftGroup: props.aircraftGroupName || "" }),
    },
    limit: props.pageLimit ? props.pageLimit : 500,
    orderBy: "modifiedAt",
    orderType: OrderType.DESC,
    page: 0,
  });

  useEffect(() => {
    dispatch(getChangeHistoryList(config));
  }, [config, props.refresh]);

  const headCells: ITableHeaderCells[] = [
    { id: "field", label: "Field", width: "2fr" },
    { id: "beforeValue", label: "Previous value" },
    { id: "afterValue", label: "Current value" },
  ];

  const paginationCallback = (page: number) => {
    setConfig({ ...config, page });
  };

  const sortCallback = (column: string, direction: OrderType) => {
    setConfig({ ...config, orderBy: column, orderType: direction });
  };

  const tableProps: ITableConfig = {
    componentName: "changeHistoryList",
    displayMode: props.displayMode,
    head: {
      cells: props.header ? props.header : headCells,
    },
    list: {
      ...changeHistoryList,
    },
    name: "changeHistory",
    paginationConfig: {
      limit: config.limit,
      onPageChange: paginationCallback,
      page: config.page,
    },
    sortConfig: {
      onSort: sortCallback,
      orderBy: config.orderBy,
      orderType: config.orderType,
    },
  };

  return (
    <>
      {/* LOADING */}
      {changeHistoryList.loading && (
        <SOverlay>
          <ProgressIndicatorLinear />
        </SOverlay>
      )}
      {/* TABLE */}
      <HistoryTable {...tableProps} isAdmin={props.isAdmin} curUserId={props.curUserId} />
    </>
  );
};
/* tslint:enable:cyclomatic-complexity */
