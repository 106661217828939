import { ButtonPrimary, Text, Textarea } from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SFlexContainer, SSpacer } from "../../styles/styles";

import { ModalCreateBase } from "./ModalCreateBase";

export const ModalEditComment = () => {
  // const dispatch = useDispatch();

  const { editModal } = useSelector((state: any) => ({
    editModal: state.editModal,
  }));

  const [changeComment, setChangeComment] = useState("");
  const [isCommentValid, setIsCommentValid] = useState(false);

  useEffect(() => {
    if (editModal.popupData.commentRequired) {
      setIsCommentValid(changeComment.length > 0);
    } else {
      setIsCommentValid(true);
    }
  }, [changeComment]);

  const validateTextInput = (e: any, maxChar?: number) => {
    if (e.target.value === " ") {
      return false;
    }
    if (e.target.value.length > (maxChar || 2048)) {
      return false;
    }

    return true;
  };

  const handleTextInput = (e: any, field: string, maxChar?: number) => {
    if (validateTextInput(e, maxChar)) {
      setChangeComment(e.target.value);
    }
  };

  const handleConfirm = () => {
    if (editModal.popupData.onConfirm) {
      editModal.popupData.onConfirm({ changeComment });
    }
  };

  const getCommentHint = () => {
    let hintStr =
      editModal.popupData.commentHint && editModal.popupData.commentHint.length > 0
        ? editModal.popupData.commentHint
        : "Please enter a comment about why you are making this change.";

    hintStr += editModal.popupData.commentRequired ? " (required)" : " (optional)";

    return hintStr;
  };

  return (
    <ModalCreateBase title={editModal.popupData.title}>
      {editModal.popupData.contentText && editModal.popupData.contentText.length > 0 && (
        <Text>{editModal.popupData.contentText}</Text>
      )}
      <SSpacer
        height={
          editModal.popupData.contentText && editModal.popupData.contentText.length > 0
            ? "5px"
            : "0px"
        }
      />
      <Textarea
        className="ta-modal-input-comment"
        placeholder={getCommentHint()}
        value={changeComment}
        onChange={(e: any) => {
          handleTextInput(e, "comment", 2048);
        }}
        handleCloseButton={editModal.popupData.onCancel}
      />
      <SSpacer height="20px" />
      <SFlexContainer justifyContent="flex-end">
        <ButtonPrimary
          className="ta-modal-confirm-comment-button"
          onClick={handleConfirm}
          disabled={!isCommentValid}
        >
          {editModal.popupData.confirmText ? <>{editModal.popupData.confirmText}</> : <>Confirm</>}
        </ButtonPrimary>
      </SFlexContainer>
    </ModalCreateBase>
  );
};
