import { Dispatch } from "redux";

import { IGetTopicDetailsData } from "../../../models/ITopicConfig";
import { TopicService } from "../../../services/TopicService";

import {
  IGetTopicDetailsActionType,
  IGetTopicDetailsErrorAction,
  IGetTopicDetailsRequestAction,
  IGetTopicDetailsSuccessAction,
  TGetTopicDetails,
} from "./types";

export const getTopicDetailsRequestAction = (): IGetTopicDetailsRequestAction => ({
  type: TGetTopicDetails.REQUEST,
});
export const getTopicDetailsSuccessAction = (data: any): IGetTopicDetailsSuccessAction => ({
  payload: data,
  type: TGetTopicDetails.SUCCESS,
});
export const getTopicDetailsErrorAction = (error: any): IGetTopicDetailsErrorAction => ({
  payload: error,
  type: TGetTopicDetails.ERROR,
});

export const getTopicDetails = (topicId: any) => (
  dispatch: Dispatch<IGetTopicDetailsActionType>
) => {
  dispatch(getTopicDetailsRequestAction());
  TopicService.getTopicDetails(topicId).then(
    (data: { data: IGetTopicDetailsData }) => {
      dispatch(getTopicDetailsSuccessAction(data.data));
    },
    (error: any) => dispatch(getTopicDetailsErrorAction(error))
  );
};
