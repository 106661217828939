/* istanbul ignore file */
import { IGetGroundConfigListData } from "../../../models/IGroundsideConfiguration";
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum GroundConfigListType {
  RESET = "GET_GROUND_CONFIG_LIST_RESET",
  REQUEST = "GET_GROUND_CONFIG_LIST_REQUEST",
  SUCCESS = "GET_GROUND_CONFIG_LIST_SUCCESS",
  ERROR = "GET_GROUND_CONFIG_LIST_ERROR",
}

export interface IGroundConfigListResetAction extends IReduxBaseAction<GroundConfigListType> {
  type: GroundConfigListType.RESET;
}

export interface IGroundConfigListRequestAction extends IReduxBaseAction<GroundConfigListType> {
  type: GroundConfigListType.REQUEST;
}

export interface IGroundConfigListSuccessAction extends IReduxBaseAction<GroundConfigListType> {
  payload: IGetGroundConfigListData;
  type: GroundConfigListType.SUCCESS;
}

export interface IGroundConfigListErrorAction extends IReduxBaseAction<GroundConfigListType> {
  payload: string;
  type: GroundConfigListType.ERROR;
}

export type TGroundConfigListActions =
  | IGroundConfigListResetAction
  | IGroundConfigListRequestAction
  | IGroundConfigListSuccessAction
  | IGroundConfigListErrorAction;
