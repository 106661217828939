import {
  AppVisibilityUsersType,
  TGetAppVisibilityUsersActions,
} from "../../actions/application/get-visibility-users/types";

interface IUserListState {
  content: any[];
  count: number;
  error: boolean;
  loading: boolean;
}

// tslint:disable-next-line:no-object-literal-type-assertion
const initialState = {
  content: [],
  count: 0,
  error: false,
  loading: false,
} as IUserListState;

export const appVisibilityUsers = (
  state: IUserListState = initialState,
  action: TGetAppVisibilityUsersActions
): IUserListState => {
  switch (action.type) {
    case AppVisibilityUsersType.RESET:
      return {
        ...state,
        ...initialState,
      };
    case AppVisibilityUsersType.REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case AppVisibilityUsersType.SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
      };
    case AppVisibilityUsersType.ERROR:
      return {
        ...state,
        content: [],
        error: true,
        loading: false,
      };
    default:
      return state;
  }
};
