
enum ClientPasswordValidationErrorCodeEnum {

    ERROR_NONE = 0,

    ERROR_TOO_SHORT = 1,

    ERROR_TOO_LONG = 2,

    ERROR_INVALID_CHARS = 3,
}

/**
 * Client password validation
 */
// tslint:disable-next-line:no-unnecessary-class
class ClientPasswordValidator {

    /**
     * validatePassword
     * @param password string - the password to be validated
     * @returns ClientPasswordValidationErrorCodeEnum - validation error code
     */
    public static validatePassword(password: string): ClientPasswordValidationErrorCodeEnum {

        if (!password || password.length < 8) {
            return ClientPasswordValidationErrorCodeEnum.ERROR_TOO_SHORT;
        }
        if (password.length > 100) {
            return ClientPasswordValidationErrorCodeEnum.ERROR_TOO_LONG;
        }

        const validCharsRe = /^[A-Za-z0-9\-=]+$/;
        if (!validCharsRe.test(password)) {
            return ClientPasswordValidationErrorCodeEnum.ERROR_INVALID_CHARS;
        }

        return ClientPasswordValidationErrorCodeEnum.ERROR_NONE;
    }
}

export {
    ClientPasswordValidator,
    ClientPasswordValidationErrorCodeEnum,
};
