import {
  ButtonIcon,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
  ProgressIndicatorLinear,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getBrokerTypeDetails } from "../../actions/broker-type/get-details/actions";
import { getTAClass, TA_TYPES } from "../../helper/taHelper";
import { tabBrokerTypesDetailsEnum } from "../../models/IBrokerTypesDetailsConfig";
import { RootState } from "../../store";
import {
  SFlexContainer,
  SFlexItem,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
} from "../../styles/styles";
import { PageError, PageLoading } from "../PageState";

import { BrokerTypesDetailsTabAppClient } from "./BrokerTypesDetails/BrokerTypesDetailsTabAppClient";
import { BrokerTypesDetailsTabDetails } from "./BrokerTypesDetails/BrokerTypesDetailsTabDetails";
import { BrokerTypesDetailsTabs } from "./BrokerTypesDetails/BrokerTypesDetailsTabs";
import { BrokerTypesDetailsTabsTopicList } from "./BrokerTypesDetails/BrokerTypesDetailsTabsTopicList";

export const BrokerTypesDetails = ({ match }: { match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { brokerTypeDetails, currentUser } = useSelector((state: RootState) => ({
    brokerTypeDetails: state.brokerTypeDetails,
    currentUser: state.currentUser,
  }));

  const [activeTab, setActiveTab] = useState(tabBrokerTypesDetailsEnum.DETAILS);

  useEffect(() => {
    dispatch(getBrokerTypeDetails(match.params.brokerTypeId));
  }, [match.params.brokerTypeId]);

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link to="/brokerTypes" className="ta-broker-types-details-button-back">
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-broker-types-details-title">
              Broker type details
            </SPageHeading>
          </GridItem>
        </Grid>
        {/* ERROR */}

        {brokerTypeDetails.loading && <PageLoading />}

        {brokerTypeDetails.error && <PageError />}

        {/* APP DETAILS */}
        {!brokerTypeDetails.loading && !brokerTypeDetails.error && (
          <>
            {brokerTypeDetails.content && currentUser.data && (
              <Grid>
                <GridItem>
                  <SFlexContainer justifyContent="flex-start">
                    <SFlexItem>
                      <Heading variant="heading2">
                        <span
                          className={getTAClass(
                            "brokerTypesDetails",
                            TA_TYPES.TITLE,
                            "brokerTypeName"
                          )}
                        >
                          {brokerTypeDetails.content.brokerTypeName}
                        </span>
                      </Heading>
                    </SFlexItem>
                  </SFlexContainer>
                </GridItem>
              </Grid>
            )}

            <SSpacer />

            {/* TABS */}
            <BrokerTypesDetailsTabs
              activeTab={activeTab}
              onChange={(tab: tabBrokerTypesDetailsEnum) => {
                setActiveTab(tab);
              }}
            />

            <SSpacer />

            {/* TABLE */}
            {activeTab === tabBrokerTypesDetailsEnum.APPCLIENTS && brokerTypeDetails.content && (
              <BrokerTypesDetailsTabAppClient content={brokerTypeDetails.content} />
            )}
            {activeTab === tabBrokerTypesDetailsEnum.PUBLISHED && brokerTypeDetails.content && (
              <BrokerTypesDetailsTabsTopicList
                brokerType={brokerTypeDetails.content.brokerType}
                type="PUBLISHER"
              />
            )}
            {activeTab === tabBrokerTypesDetailsEnum.SUBSCRIBED &&
              brokerTypeDetails.content &&
              currentUser.data && (
                <BrokerTypesDetailsTabsTopicList
                  brokerType={brokerTypeDetails.content.brokerType}
                  type="SUBSCRIBER"
                />
              )}
            {activeTab === tabBrokerTypesDetailsEnum.DETAILS && brokerTypeDetails.content && (
              <BrokerTypesDetailsTabDetails content={brokerTypeDetails.content} />
            )}
          </>
        )}
      </SPageContainer>
    </>
  );
};
