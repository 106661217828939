import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum CreateTenantTypes {
  ERROR = "CREATE_TENANT_ERROR",
  REQUEST = "CREATE_TENANT_REQUEST",
  SUCCESS = "CREATE_TENANT_SUCCESS",
}

export interface ICreateTenantRequestAction extends IReduxBaseAction<CreateTenantTypes> {
  payload: any;
  type: CreateTenantTypes.REQUEST;
}

export interface ICreateTenantSuccessAction extends IReduxBaseAction<CreateTenantTypes> {
  payload: any;
  type: CreateTenantTypes.SUCCESS;
}

export interface ICreateTenantErrorAction extends IReduxBaseAction<CreateTenantTypes> {
  payload: any;
  type: CreateTenantTypes.ERROR;
}

export type TCreateTenantActions =
  | ICreateTenantErrorAction
  | ICreateTenantRequestAction
  | ICreateTenantSuccessAction;
