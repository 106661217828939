export const DEFAULT_WIDTH = "1fr";
export const DEFAULT_BATCH_WIDTH = "50px";

export interface ITableHeaderCells {
  id: string;
  label: string;
  width?: string; // Default "1fr"
  onClickCell?(row: ITableRow): void;
  title?(row: ITableRow): string | JSX.Element;
  value?(row: ITableRow): string | JSX.Element;
}

export interface ITableHead {
  cells: ITableHeaderCells[];
}

export interface ITableRow {
  [key: string]: any;
}

/**
 * Example:
 *  - ExampleArray: [{key: '1', value: 'TEST_DATA', purelabel: 'Test data'}]
 *  - If the column key is 'role' (columnName), use ExampleArray (source), do the comparison on key 'value' (from), and use purelabel (to) instead.
 */
interface ICellRule {
  /**
   * Which column to check while replacing the value.
   */
  columnName: string;
  mask: {
    /**
     * The 'key' in the object (in source) to use for comparison.
     */
    from: string;
    /**
     * Use this array of objects to map the value
     */
    source: any;
    /**
     * Use this key instead of the original value
     */
    to: string;
  };
}

export interface ITableBody {
  cellRules?: ICellRule[];
  content: ITableRow;
  count: number;
  error?: boolean;
  loading?: boolean;
  onClickRow?(row: ITableRow): void;
}

export enum OrderType {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ITableSort {
  orderBy: string;
  orderType: OrderType;
  onSort(column: string, direction: OrderType): void;
}

export interface ITablePagination {
  limit: number;
  page: number;
  onPageChange(page: number): void;
}

export interface IDeleteConfig {
  as: string;
  disabled?(row: ITableRow): void;
  onClick?(row: ITableRow): void;
}

export interface IBatchAction {
  icon?: JSX.Element;
  title: string;
  disabled?(selectedRows: any[]): boolean;
  onClick?(selectedRows: any[]): void;
}

export interface IBatchActionConfig {
  actions: IBatchAction[];
  width?: string;
  actionHandler?(row: ITableRow): void;
  label?(selectedRows: ITableRow[]): string;
}

export interface ITableConfig {
  [key: string]: any;
  batchAction?: IBatchActionConfig;
  deleteCell?: IDeleteConfig;
  head: ITableHead;
  list: ITableBody;
  name: string;
  paginationConfig: ITablePagination;
  sortConfig: ITableSort;
}
