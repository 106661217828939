import {
  ButtonIcon,
  ButtonPrimary,
  ContentSwitcher,
  Grid,
  GridItem,
  Heading,
  IconArrowLeft32,
  ProgressIndicatorLinear,
  Tab,
  TabContainer,
} from "next-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { getConfigVersion } from "../../../actions/configversion/get/actions";
import { getTAClass, TA_TYPES } from "../../../helper/taHelper";
import { getFormattedDate } from "../../../helper/util";
import { ConfigVersionDetailsTabEnum } from "../../../models/IConfigVersion";
import { ADMINISTRATOR } from "../../../models/UserTypes";
import { ConfigService } from "../../../services/ConfigService";
import { RootState } from "../../../store";
import {
  SFlexContainer,
  SFlexItem,
  SOverlay,
  SPageContainer,
  SPageHeading,
  SSpacer,
  STallText,
  STextDataTitle,
  SVerticalSpacer,
} from "../../../styles/styles";
import { DataDetailLabel } from "../../DataDetailLabel";
import { PageError, PageLoading } from "../../PageState";

import { ConfigLoadableList } from "./ConfigurationLoadableList";

export const ConfigurationVersionDetails = ({ history, match }: { history: any; match: any }) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, Action>>();

  const { configVersionDetails, currentUser } = useSelector((state: RootState) => ({
    configVersionDetails: state.configVersionDetails,
    currentUser: state.currentUser,
  }));

  const [activeTab, setActiveTab] = useState(ConfigVersionDetailsTabEnum.STANDARD);

  useEffect(() => {
    dispatch(getConfigVersion(match.params.configVersionId));
  }, [match.params.configVersionId]);

  const tabList = [
    {
      class: "ta-config-version-tab-standard",
      id: ConfigVersionDetailsTabEnum.STANDARD,
      title: "Standard",
    },
    {
      class: "ta-config-version-tab-supplement",
      id: ConfigVersionDetailsTabEnum.SUPPLEMENTAL,
      title: "Supplemental",
    },
  ];

  const handleTabChange = (tabId: ConfigVersionDetailsTabEnum) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <SPageContainer>
        {/* HEADER */}
        <Grid columns="auto 1fr" alignItems="center" gap="10px">
          <GridItem>
            <Link to="/configurationFiles" className="ta-config-version-details-button-back">
              <ButtonIcon size="default" icon={<IconArrowLeft32 />} />
            </Link>
          </GridItem>
          <GridItem>
            <SPageHeading className="ta-config-version-details-title">
              Loadables details
            </SPageHeading>
          </GridItem>
        </Grid>

        {/* LOADED */}
        {configVersionDetails.loading && <PageLoading />}

        {/* ERROR */}
        {configVersionDetails.error && <PageError />}

        <Grid>
          <GridItem>
            <SFlexContainer justifyContent="space-between">
              <SFlexItem>
                <Heading variant="heading2">
                  <span
                    className={getTAClass(
                      "config-version-details",
                      TA_TYPES.TITLE,
                      "version-number"
                    )}
                  >
                    {"Version: "}
                    {configVersionDetails.content.version}
                  </span>
                </Heading>
              </SFlexItem>
            </SFlexContainer>
          </GridItem>
        </Grid>

        <SSpacer />

        {/* LOADED */}
        {!configVersionDetails.loading && configVersionDetails.content && (
          <Grid columns="4fr 2fr 1fr">
            <GridItem>
              <DataDetailLabel
                title="Build type"
                value={configVersionDetails.content.isRelease ? "Release" : "Test"}
                className="ta-config-version-details-type"
              ></DataDetailLabel>
              <DataDetailLabel
                title="Comment"
                value={configVersionDetails.content.comment}
                className="ta-config-version-details-type"
              ></DataDetailLabel>
            </GridItem>
            <GridItem>
              <DataDetailLabel
                title="Created by"
                value={configVersionDetails.content.createdBy.fullName}
                className="ta-config-version-details-created-by"
              ></DataDetailLabel>
              <DataDetailLabel
                title="Created at"
                value={getFormattedDate(configVersionDetails.content.createdAt)}
                className="ta-config-version-details-created-at"
              ></DataDetailLabel>
            </GridItem>
            <GridItem>
              <SFlexContainer justifyContent="flex-end">
                <ButtonPrimary
                  className={getTAClass("changelog", TA_TYPES.BUTTON, "edit")}
                  disabled={!configVersionDetails.content.isChangelogAvailable}
                  onClick={() =>
                    ConfigService.s3ChangeLogUrl(configVersionDetails.content.configVersionId)
                  }
                >
                  Change log
                </ButtonPrimary>
              </SFlexContainer>
            </GridItem>
          </Grid>
        )}

        <SSpacer />

        {/* TABS */}
        {!currentUser.loading && (
          <Grid columns={currentUser.data.role === ADMINISTRATOR ? "0.6fr" : "0.2fr"}>
            <GridItem>
              <TabContainer>
                {tabList.map(
                  (tab: any, index: any) =>
                    (tab.id !== ConfigVersionDetailsTabEnum.SUPPLEMENTAL ||
                      currentUser.data.role === ADMINISTRATOR) && (
                      <Tab
                        key={index}
                        className={tab.class}
                        active={activeTab === tab.id}
                        onClick={() => {
                          handleTabChange(tab.id);
                        }}
                      >
                        {tab.title}
                      </Tab>
                    )
                )}
              </TabContainer>
            </GridItem>
          </Grid>
        )}

        <SSpacer />

        {/* TABLE */}
        {!configVersionDetails.loading && configVersionDetails.content && (
          <>
            {activeTab === ConfigVersionDetailsTabEnum.STANDARD && (
              <ConfigLoadableList
                configVersionId={configVersionDetails.content.configVersionId}
                isSupplemental={0}
              />
            )}
            {activeTab === ConfigVersionDetailsTabEnum.SUPPLEMENTAL && (
              <ConfigLoadableList
                configVersionId={configVersionDetails.content.configVersionId}
                isSupplemental={1}
              />
            )}
          </>
        )}
      </SPageContainer>
    </>
  );
};
