import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

/* istanbul ignore file */
export enum GetUserListType {
  REQUEST = "GET_USER_LIST_REQUEST",
  SUCCESS = "GET_USER_LIST_SUCCESS",
  ERROR = "GET_USER_LIST_ERROR",
}

export interface IGetUserListRequestAction extends IReduxBaseAction<GetUserListType> {
  type: GetUserListType.REQUEST;
}
export interface IGetUserListSuccessAction extends IReduxBaseAction<GetUserListType> {
  payload: any;
  type: GetUserListType.SUCCESS;
}

export interface IGetUserListErrorAction extends IReduxBaseAction<GetUserListType> {
  payload: any;
  type: GetUserListType.ERROR;
}

export type TUserListActions =
  | IGetUserListRequestAction
  | IGetUserListSuccessAction
  | IGetUserListErrorAction;
