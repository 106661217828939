import { createApi } from "@reduxjs/toolkit/query/react";

import { ITenant } from "../../../models/ITenant";
import baseQuery from "../../../services/baseQuery";

export const tenantApi = createApi({
  baseQuery,
  reducerPath: "tenantApi",
  endpoints: (builder) => ({
    provision: builder.query<string, string>({
      query: (tenantId) => ({
        url: `/tenants/${tenantId}/provision`,
        method: "put",
        body: JSON.stringify({ tenantId }),
      }),
    }),
    getTenant: builder.query<ITenant, string>({
      query: (tenantId) => `/tenants/${tenantId}`,
      transformResponse(baseQueryReturnValue: any): Promise<ITenant> | ITenant {
        return baseQueryReturnValue.data.content;
      },
    }),
  }),
});

export const { useLazyProvisionQuery, useGetTenantQuery } = tenantApi;
