import {
  AppFilterSortType,
  TAppFilterSortActions,
} from "../../actions/application/filter-sort/types";
import { userListStatusEnum } from "../../models/IAppListingTableConfig";
import { IFilterSortData as IAppFilterSortState } from "../../models/IFilterSort";
import { OrderType } from "../../models/ITableConfig";

// interface IAppFilterSortState {
//     filter: any;
//     limit: number;
//     orderBy: string;
//     orderType: OrderType.DESC,
//     page: number;
// }

export const appFilterSortEmptyFilter: any = {
  appClientId: "",
  appClientLocation: "",
  appName: "",
  appOwner: "",
  appVersion: "",
  createdDate: { startDate: undefined, endDate: undefined },
  modifiedDate: { startDate: undefined, endDate: undefined },
  status: userListStatusEnum.EMPTY,
};

export const appFilterSortInitialState: IAppFilterSortState = {
  filter: appFilterSortEmptyFilter,
  limit: 10,
  orderBy: "modifiedAt",
  orderType: OrderType.DESC,
  page: 0,
};

export const applicationFilterSort = (
  state: IAppFilterSortState = appFilterSortInitialState,
  action: TAppFilterSortActions
): IAppFilterSortState => {
  switch (action.type) {
    case AppFilterSortType.CLEAR: // clear filter and reset page#
      return {
        ...state,
        filter: { ...appFilterSortEmptyFilter },
        page: 0,
      };
    case AppFilterSortType.RESET: // reset filter/sort/page#
      return {
        ...state,
        ...appFilterSortInitialState,
      };
    case AppFilterSortType.APPLY: // apply any changes to filter/sort/page#
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
