import { Dispatch } from "redux";

import { ApplicationService } from "../../../services/ApplicationService";
import { TEditModalActions } from "../../modal/edit/types";
import { popupOpenAction } from "../../popup/actions";
import { TPopupActions } from "../../popup/types";

export const getTopicsForPublisher = (config: any, cb: any) => (
  dispatch: Dispatch<TPopupActions | TEditModalActions>
) => {
  ApplicationService.getApplicationTopicList(config).then(
    (data: { data: any }) => {
      // Is the only publisher for this topicid?
      cb(data.data.data.content.length === 1);
    },
    (error: any) => {
      // Error callback
      dispatch(
        popupOpenAction({
          content: (error && error.message) || "",
          title: "Error",
          type: "Info",
        })
      );
    }
  );
};
