/* istanbul ignore file */
import { IReduxBaseAction } from "../../../models/IReduxBaseAction";

export enum DestinationRouteListType {
  REQUEST = "GET_DESTINATION_ROUTES_REQUEST",
  SUCCESS = "GET_DESTINATION_ROUTES_SUCCESS",
  ERROR = "GET_DESTINATION_ROUTES_ERROR",
  CLEAR = "GET_DESTINATION_ROUTES_CLEAR",
}

export interface IDestinationRouteListRequestAction
  extends IReduxBaseAction<DestinationRouteListType> {
  type: DestinationRouteListType.REQUEST;
}

export interface IDestinationRouteListClearAction
  extends IReduxBaseAction<DestinationRouteListType> {
  type: DestinationRouteListType.CLEAR;
}

export interface IDestinationRouteListSuccessAction
  extends IReduxBaseAction<DestinationRouteListType> {
  payload: any;
  type: DestinationRouteListType.SUCCESS;
}

export interface IDestinationRouteListErrorAction
  extends IReduxBaseAction<DestinationRouteListType> {
  payload: string;
  type: DestinationRouteListType.ERROR;
}

export type TDestinationRouteListActions =
  | IDestinationRouteListRequestAction
  | IDestinationRouteListSuccessAction
  | IDestinationRouteListErrorAction
  | IDestinationRouteListClearAction;
